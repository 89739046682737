<template>
    <div class="citywall-only">
        <svg width="46" height="33" viewBox="0 0 46 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44.5457 16.838L45 16.5422V16V13V12.3963L44.4657 12.1151L25.4657 2.11508L25.0117 1.87611L24.5528 2.10557L2.55279 13.1056L1.90812 13.4279L2.01005 14.1414L2.51005 17.6414L2.5738 18.0877L2.95038 18.3354L21.9504 30.8354L22.4972 31.1952L23.0457 30.838L44.5457 16.838Z" :fill="playerColors[color]" stroke="#383832" stroke-width="2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 14L22.5 26.5V30L44 16V13L25 3L3 14Z" fill="black" fill-opacity="0.1"/>
        </svg>
    </div>
</template>

<script>

import colors from '../../helpers/colors.js'

export default {
    mixins: [
        colors,
    ],
    props: {
        color: String,
    },
    data() {
        return {

        }
    }
}
</script>

<style>

</style>
