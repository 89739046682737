<template>
    <div class="road-3d">
        <svg width="44" height="21" viewBox="0 0 44 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.43152 11.2196L2.49004 11.9803L3.23929 12.1248L38.8107 18.9819L39.3318 19.0824L39.7071 18.7071L42.7071 15.7071L43 15.4142V15V9.85714V9.02964L42.1871 8.8748L6.18711 2.01766L5.7145 1.92764L5.34921 2.24074L2.34921 4.81217L1.96404 5.14232L2.00295 5.64812L2.43152 11.2196Z" :fill="playerColors[color]" stroke="#383832" stroke-width="2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.09265 5.49197L38.9998 12.4286V18L38.9999 18L41.9999 15V9.85714L5.99995 3L3.09265 5.49197Z" fill="black" fill-opacity="0.1"/>
        </svg>
    </div>
</template>

<script>

import colors from '../../helpers/colors.js'

export default {
    mixins: [
        colors,
    ],
    props: {
        color: String,
    },
    data() {
        return {

        }
    }
}
</script>

<style>

</style>
