var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.forceUpdate)?_c('span'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"spot-wrapper"},[(_vm.house && !_vm.house.buildAnimation && _vm.gamePhase != 'initial build')?_c('div',{staticClass:"points-animation"},[(_vm.house.player == _vm.activePlayer && _vm.activePlayer.points > 2)?[_vm._v(_vm._s(_vm.activePlayer.points))]:_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.city && !_vm.city.buildAnimation && _vm.gamePhase != 'initial build')?_c('div',{staticClass:"points-animation"},[(_vm.city.player == _vm.activePlayer && _vm.activePlayer.points > 3)?[_vm._v(_vm._s(_vm.activePlayer.points))]:_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.city && !_vm.city.buildAnimation && _vm.city.metropolis && _vm.gamePhase != 'initial build')?_c('div',{staticClass:"points-animation"},[(_vm.city.player == _vm.activePlayer && _vm.activePlayer.points > 3)?[_vm._v(_vm._s(_vm.activePlayer.points))]:_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.tile.spots[_vm.spotPosition])?_c('div',[(_vm.knight)?_c('Knight',{attrs:{"level":_vm.knight.level,"color":_vm.knight.player.color,"active":_vm.knight.active,"highlighted":_vm.highlightedSpots.includes(_vm.spot.key)}}):_vm._e(),_vm._v(" "),(_vm.house)?_c('div',{staticClass:"house",class:[
                    _vm.house.player.color,
                    { 'highlighted': _vm.highlightedSpots.includes(_vm.spot.key) },
                    { 'build-house-animation': _vm.house.buildAnimation } ]},[(_vm.house.extraIslandPoint)?_c('div',{staticClass:"island-marker"}):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.city)?_c('div',{staticClass:"city",class:[
                    _vm.city.player.color,
                    { 'citywall': _vm.city.citywall },
                    { 'metropolis': _vm.city.metropolis },
                    { 'city_disabled': _vm.city.disabled },
                    { 'highlighted': _vm.highlightedSpots.includes(_vm.spot.key) },
                    { 'build-city-animation': _vm.city.buildAnimation } ]}):(_vm.highlightedSpots.includes(_vm.spot.key))?_c('div',{staticClass:"empty-highlighted-spot"}):_vm._e(),_vm._v(" "),(_vm.merchant)?_c('div',[_c('div',{staticClass:"piece merchant",class:'position-'+_vm.merchant.spotOffset})]):_vm._e()],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }