<template>
<span v-if="flatGraphics">
    <!-- <svg width="69" height="98" viewBox="0 0 69 98" fill="none" xmlns="http://www.w3.org/2000/svg"> -->
    <svg width="66" height="93" viewBox="0 0 69 98" fill="none" xmlns="http://www.w3.org/2000/svg">
        <template v-if="group == 'commodity'">
            <rect x="2" y="2" width="65" height="94" :fill="colors[type].primary"/>
            <path d="M2 2V1H1V2H2ZM67 2H68V1H67V2ZM67 96V97H68V96H67ZM2 96H1V97H2V96ZM2 3H67V1H2V3ZM66 2V96H68V2H66ZM67 95H2V97H67V95ZM3 96V2H1V96H3Z" fill="#20201B"/>
            <path d="M22.5278 49.5L34 33.1741L45.4722 49.5L34 65.8259L22.5278 49.5Z" :stroke="colors[type].secondary" stroke-width="2.5"/>
            <path d="M11 45L8 50L11 55L14 50L11 45Z" :stroke="colors[type].secondary"/>
            <path d="M57 45L54 50L57 55L60 50L57 45Z" :stroke="colors[type].secondary"/>
        </template>
        <template v-else>
            <rect x="2" y="2" width="65" height="94" :fill="colors[type].primary"/>
            <path d="M22.3087 60.75L34 40.5L45.6913 60.75H22.3087Z" :stroke="colors[type].secondary" stroke-width="2.5"/>
            <path d="M12 9L15.4641 15H8.5359L12 9Z" :stroke="colors[type].secondary"/>
            <path d="M57 83L60.4641 89H53.5359L57 83Z" :stroke="colors[type].secondary"/>
        </template>
        <path d="M2 2V0H0V2H2ZM67 2H69V0H67V2ZM67 96V98H69V96H67ZM2 96H0V98H2V96ZM2 4H67V0H2V4ZM65 2V96H69V2H65ZM67 94H2V98H67V94ZM4 96V2H0V96H4Z" fill="#383832"/>
    </svg>
</span>
<span v-else>
    <div class="hand-card-2" :class="type+'-2'"></div>
</span>
</template>

<script>
export default {
    props: ['group', 'type', 'flatGraphics'],
    data() {
        return {
            colors: {
                lumber: { primary: '#386837', secondary: '#28491D' },
                brick: { primary: '#C86126', secondary: '#8A3A0D' },
                wool: { primary: '#95D054', secondary: '#5C7A1A' },
                grain: { primary: '#EED800', secondary: '#7C6925' },
                ore: { primary: '#535249', secondary: '#3B3D31' },
                paper: { primary: '#386837', secondary: '#DAF48F' },
                coin: { primary: '#535249', secondary: '#AC9A76' },
                cloth: { primary: '#95D054', secondary: '#DDFFB2' },
            },
        }
    },
}
</script>

<style>

</style>
