<template>
    <div class="knight-3d">
        <svg :width="size == 'xl' ? 28 : 24" :height="size == 'xl' ? 24 : 20" viewBox="0 0 238 196" fill="none" xmlns="http://www.w3.org/2000/svg">
            <template v-if="level == 1">
                <template v-if="!active">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.085 132.354L230.795 110.133C231.094 92.5758 231.373 74.145 231.292 71.7901C231.26 70.8445 231.145 69.7376 230.984 68.5784C230.958 67.6667 230.9 66.7533 230.809 65.8387C227.223 29.519 174.036 5.04035 112.012 11.1641C51.1853 17.1696 4.44905 50.3766 6.04864 85.9128C5.89206 88.2975 6.03066 90.6954 6.46346 93.0518L15.4555 142.008C16.0914 145.471 17.2612 148.813 18.9229 151.916C31.8008 180.785 77.5272 198.966 129.695 193.815C177.419 189.103 216.039 166.296 227.618 138.84L228.64 136.683C229.296 135.298 229.78 133.845 230.085 132.354Z" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.379 132.277L231.089 110.057C231.388 92.4994 231.667 74.0686 231.586 71.7137C231.553 70.7681 231.439 69.6612 231.278 68.502C231.251 67.5903 231.193 66.6769 231.103 65.7623C227.517 29.4426 174.33 4.96394 112.305 11.0877C56.8562 16.5623 13.116 44.6417 7.02774 76.4839L6.94254 76.3749L6.83865 77.5475C6.51114 79.5398 6.3314 81.546 6.30576 83.5615L6.23769 84.3297C6.00695 86.9337 6.12938 89.557 6.60164 92.1282L15.7492 141.932C16.3852 145.394 17.555 148.737 19.2167 151.84C32.0945 180.708 77.821 198.889 129.988 193.739C177.713 189.027 216.333 166.219 227.912 138.764L228.934 136.606C229.59 135.222 230.074 133.769 230.379 132.277Z" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.379 132.277L231.089 110.057C231.388 92.4994 231.667 74.0686 231.586 71.7137C231.553 70.7681 231.439 69.6612 231.278 68.502C231.251 67.5903 231.193 66.6769 231.103 65.7623C227.517 29.4426 174.33 4.96394 112.305 11.0877C56.8562 16.5623 13.116 44.6417 7.02774 76.4839L6.94254 76.3749L6.83865 77.5475C6.51114 79.5398 6.3314 81.546 6.30576 83.5615L6.23769 84.3297C6.00695 86.9337 6.12938 89.557 6.60164 92.1282L15.7492 141.932C16.3852 145.394 17.555 148.737 19.2167 151.84C32.0945 180.708 77.821 198.889 129.988 193.739C177.713 189.027 216.333 166.219 227.912 138.764L228.934 136.606C229.59 135.222 230.074 133.769 230.379 132.277Z" fill="black" fill-opacity="0.1"/>
                    <ellipse cx="118.798" cy="76.8506" rx="112.851" ry="66.0824" transform="rotate(-5.63863 118.798 76.8506)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <ellipse cx="119.308" cy="74.4141" rx="89.3096" ry="51.6679" transform="rotate(-5.73379 119.308 74.4141)" :fill="color == 'neutral' ? '#374958' : '#383832'"/>
                    <ellipse cx="120.402" cy="69.6156" rx="27.3774" ry="16.8903" transform="rotate(-5.08494 120.402 69.6156)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <ellipse cx="120.411" cy="69.7418" rx="21.5763" ry="12.4638" transform="rotate(-5.3857 120.411 69.7418)" :fill="color == 'neutral' ? '#374958' : '#383832'"/>
                </template>
                <template v-else>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.085 132.353L230.795 110.132C231.094 92.5751 231.373 74.1443 231.292 71.7893C231.26 70.8437 231.145 69.7369 230.984 68.5777C230.958 67.6659 230.9 66.7526 230.809 65.838C227.223 29.5183 174.036 5.03962 112.012 11.1634C51.1853 17.1689 4.44905 50.3759 6.04864 85.9121C5.89206 88.2967 6.03066 90.6946 6.46346 93.051L15.4555 142.007C16.0914 145.47 17.2612 148.812 18.9229 151.916C31.8008 180.784 77.5272 198.965 129.695 193.814C177.419 189.102 216.039 166.295 227.618 138.84L228.64 136.682C229.296 135.297 229.78 133.844 230.085 132.353Z" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.379 132.277L231.089 110.057C231.388 92.4996 231.667 74.0689 231.586 71.7139C231.553 70.7683 231.439 69.6614 231.278 68.5022C231.251 67.5905 231.193 66.6771 231.103 65.7626C227.517 29.4428 174.33 4.96418 112.305 11.0879C56.8562 16.5625 13.116 44.642 7.02775 76.4841L6.94254 76.3752L6.83865 77.5477C6.51114 79.5401 6.3314 81.5463 6.30576 83.5617L6.23769 84.3299C6.00695 86.934 6.12938 89.5572 6.60164 92.1284L15.7492 141.932C16.3852 145.394 17.555 148.737 19.2167 151.84C32.0944 180.709 77.8209 198.89 129.988 193.739C177.713 189.027 216.333 166.219 227.912 138.764L228.934 136.606C229.59 135.222 230.074 133.769 230.379 132.277Z" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.379 132.277L231.089 110.057C231.388 92.4996 231.667 74.0689 231.586 71.7139C231.553 70.7683 231.439 69.6614 231.278 68.5022C231.251 67.5905 231.193 66.6771 231.103 65.7626C227.517 29.4428 174.33 4.96418 112.305 11.0879C56.8562 16.5625 13.116 44.642 7.02775 76.4841L6.94254 76.3752L6.83865 77.5477C6.51114 79.5401 6.3314 81.5463 6.30576 83.5617L6.23769 84.3299C6.00695 86.934 6.12938 89.5572 6.60164 92.1284L15.7492 141.932C16.3852 145.394 17.555 148.737 19.2167 151.84C32.0944 180.709 77.8209 198.89 129.988 193.739C177.713 189.027 216.333 166.219 227.912 138.764L228.934 136.606C229.59 135.222 230.074 133.769 230.379 132.277Z" fill="black" fill-opacity="0.1"/>
                    <ellipse cx="118.798" cy="76.8506" rx="112.851" ry="66.0824" transform="rotate(-5.63863 118.798 76.8506)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <ellipse cx="119.308" cy="74.4146" rx="89.3096" ry="51.6679" transform="rotate(-5.73379 119.308 74.4146)" :fill="color == 'neutral' ? '#374958' : '#383832'"/>
                    <ellipse cx="120.411" cy="69.7418" rx="21.5763" ry="12.4638" transform="rotate(-5.3857 120.411 69.7418)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                </template>
            </template>
            <template v-if="level == 2">
                <template v-if="!active">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.38 133.277L231.09 111.058C231.388 93.5004 231.668 75.0689 231.587 72.7139C231.554 71.7683 231.44 70.6614 231.279 69.5022C231.252 68.5905 231.194 67.6771 231.104 66.7626C227.518 30.4428 174.331 5.96418 112.306 12.0879C56.8571 17.5625 13.117 45.642 7.02872 77.4841L6.94352 77.3752L6.83963 78.5477C6.51211 80.5401 6.33238 82.5463 6.30674 84.5617L6.23867 85.33C6.00793 87.934 6.13036 90.5572 6.60262 93.1284L15.7502 142.932C16.3862 146.395 17.556 149.737 19.2177 152.84C32.0955 181.709 77.822 199.89 129.989 194.739C177.714 190.027 216.334 167.219 227.913 139.764L228.935 137.606C229.591 136.222 230.075 134.769 230.38 133.277Z" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.386 132.911L231.097 110.692C231.395 93.1344 231.675 74.7029 231.593 72.3479C231.561 71.4023 231.447 70.2954 231.285 69.1361C231.259 68.2244 231.201 67.3111 231.111 66.3966C227.525 30.0769 174.337 5.59821 112.313 11.722C51.4865 17.7275 4.75032 50.9345 6.34991 86.4707C6.19333 88.8553 6.33193 91.2532 6.76473 93.6096L15.7567 142.566C16.3942 146.037 17.5681 149.387 19.2362 152.497C32.1277 181.352 77.844 199.522 129.998 194.373C177.745 189.659 216.378 166.832 227.937 139.36L228.941 137.24C229.597 135.856 230.081 134.403 230.386 132.911Z" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.38 133.277L231.09 111.058C231.388 93.5004 231.668 75.0689 231.587 72.7139C231.554 71.7683 231.44 70.6614 231.279 69.5022C231.252 68.5905 231.194 67.6771 231.104 66.7626C227.518 30.4428 174.331 5.96418 112.306 12.0879C56.8571 17.5625 13.117 45.642 7.02872 77.4841L6.94352 77.3752L6.83963 78.5477C6.51211 80.5401 6.33238 82.5463 6.30674 84.5617L6.23867 85.33C6.00793 87.934 6.13036 90.5572 6.60262 93.1284L15.7502 142.932C16.3862 146.395 17.556 149.737 19.2177 152.84C32.0955 181.709 77.822 199.89 129.989 194.739C177.714 190.027 216.334 167.219 227.913 139.764L228.935 137.606C229.591 136.222 230.075 134.769 230.38 133.277Z" fill="black" fill-opacity="0.1"/>
                    <ellipse cx="118.798" cy="77.8506" rx="112.851" ry="66.0824" transform="rotate(-5.63863 118.798 77.8506)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <ellipse cx="119.308" cy="75.4146" rx="89.3096" ry="51.6679" transform="rotate(-5.73379 119.308 75.4146)" :fill="color == 'neutral' ? '#374958' : '#383832'"/>
                    <ellipse cx="90.231" cy="86.2703" rx="26.3312" ry="18.2209" transform="rotate(-7.17171 90.231 86.2703)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <ellipse cx="90.2383" cy="86.3763" rx="21.9988" ry="14.1342" transform="rotate(-7.25894 90.2383 86.3763)" :fill="color == 'neutral' ? '#374958' : '#383832'"/>
                    <ellipse cx="147.629" cy="61.4796" rx="25.7679" ry="17.4735" transform="rotate(-4.34757 147.629 61.4796)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <ellipse cx="147.618" cy="61.3442" rx="21.5475" ry="13.7475" transform="rotate(-4.34757 147.618 61.3442)" :fill="color == 'neutral' ? '#374958' : '#383832'"/>
                </template>
                <template v-else>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.38 132.278L231.09 110.058C231.388 92.5006 231.668 74.0691 231.587 71.7141C231.554 70.7686 231.44 69.6617 231.279 68.5025C231.252 67.5907 231.194 66.6774 231.104 65.7628C227.518 29.4431 174.331 4.96442 112.306 11.0882C56.8571 16.5628 13.117 44.6422 7.02872 76.4844L6.94352 76.3754L6.83963 77.5479C6.51211 79.5403 6.33238 81.5465 6.30674 83.562L6.23867 84.3302C6.00793 86.9342 6.13036 89.5575 6.60262 92.1287L15.7502 141.932C16.3862 145.395 17.556 148.737 19.2177 151.84C32.0955 180.709 77.822 198.89 129.989 193.739C177.714 189.027 216.334 166.22 227.913 138.764L228.935 136.607C229.591 135.222 230.075 133.769 230.38 132.278Z" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.386 131.911L231.097 109.692C231.395 92.1344 231.675 73.7025 231.593 71.3475C231.561 70.4018 231.447 69.2949 231.285 68.1357C231.259 67.224 231.201 66.3106 231.111 65.3961C227.525 29.0764 174.337 4.59772 112.313 10.7215C51.4865 16.727 4.75032 49.934 6.34991 85.4702C6.19333 87.8549 6.33193 90.2528 6.76473 92.6091L15.7567 141.566C16.3944 145.037 17.5688 148.388 19.2376 151.499C32.1309 180.352 77.8458 198.521 129.998 193.372C177.742 188.658 216.374 165.834 227.935 138.364L228.941 136.24C229.597 134.855 230.081 133.402 230.386 131.911Z" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.38 132.278L231.09 110.058C231.388 92.5006 231.668 74.0691 231.587 71.7141C231.554 70.7686 231.44 69.6617 231.279 68.5025C231.252 67.5907 231.194 66.6774 231.104 65.7628C227.518 29.4431 174.331 4.96442 112.306 11.0882C56.8571 16.5628 13.117 44.6422 7.02872 76.4844L6.94352 76.3754L6.83963 77.5479C6.51211 79.5403 6.33238 81.5465 6.30674 83.562L6.23867 84.3302C6.00793 86.9342 6.13036 89.5575 6.60262 92.1287L15.7502 141.932C16.3862 145.395 17.556 148.737 19.2177 151.84C32.0955 180.709 77.822 198.89 129.989 193.739C177.714 189.027 216.334 166.22 227.913 138.764L228.935 136.607C229.591 135.222 230.075 133.769 230.38 132.278Z" fill="black" fill-opacity="0.1"/>
                    <ellipse cx="118.798" cy="76.8506" rx="112.851" ry="66.0824" transform="rotate(-5.63863 118.798 76.8506)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <ellipse cx="119.308" cy="74.4146" rx="89.3096" ry="51.6679" transform="rotate(-5.73379 119.308 74.4146)" :fill="color == 'neutral' ? '#374958' : '#383832'"/>
                    <ellipse cx="90.2383" cy="85.3763" rx="21.9988" ry="14.1342" transform="rotate(-7.25894 90.2383 85.3763)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <ellipse cx="147.618" cy="60.3442" rx="21.5475" ry="13.7475" transform="rotate(-4.34757 147.618 60.3442)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                </template>
            </template>
            <template v-if="level == 3">
                <template v-if="!active">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.386 132.654L231.097 110.433C231.395 92.8759 231.675 74.4451 231.593 72.0901C231.561 71.1445 231.447 70.0375 231.285 68.8783C231.259 67.9666 231.201 67.0533 231.111 66.1388C227.525 29.8191 174.337 5.3404 112.313 11.4642C51.4865 17.4696 4.75032 50.6767 6.34991 86.2129C6.19333 88.5975 6.33193 90.9954 6.76473 93.3518L15.7567 142.308C16.3942 145.779 17.5681 149.129 19.2362 152.239C32.1277 181.094 77.844 199.264 129.998 194.115C177.745 189.401 216.378 166.575 227.937 139.102L228.941 136.983C229.597 135.598 230.081 134.145 230.386 132.654Z" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.377 132.283L231.09 109.991C231.388 92.4534 231.667 74.0659 231.586 71.7139C231.553 70.7683 231.439 69.6614 231.277 68.5022C231.251 67.5905 231.193 66.6771 231.103 65.7626C227.517 29.4428 174.329 4.96418 112.305 11.0879C56.8557 16.5625 13.1155 44.642 7.02726 76.4841L6.94205 76.3752L6.83816 77.5477C6.51065 79.5401 6.33092 81.5463 6.30527 83.5617L6.2372 84.33C6.00647 86.934 6.12889 89.5572 6.60116 92.1284L15.7488 141.932C16.3847 145.395 17.5545 148.737 19.2162 151.84C32.0941 180.709 77.8205 198.89 129.988 193.739C177.713 189.027 216.332 166.219 227.911 138.764L228.933 136.606C229.588 135.224 230.072 133.772 230.377 132.283Z" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.377 132.283L231.09 109.991C231.388 92.4534 231.667 74.0659 231.586 71.7139C231.553 70.7683 231.439 69.6614 231.277 68.5022C231.251 67.5905 231.193 66.6771 231.103 65.7626C227.517 29.4428 174.329 4.96418 112.305 11.0879C56.8557 16.5625 13.1155 44.642 7.02726 76.4841L6.94205 76.3752L6.83816 77.5477C6.51065 79.5401 6.33092 81.5463 6.30527 83.5617L6.2372 84.33C6.00647 86.934 6.12889 89.5572 6.60116 92.1284L15.7488 141.932C16.3847 145.395 17.5545 148.737 19.2162 151.84C32.0941 180.709 77.8205 198.89 129.988 193.739C177.713 189.027 216.332 166.219 227.911 138.764L228.933 136.606C229.588 135.224 230.072 133.772 230.377 132.283Z" fill="black" fill-opacity="0.1"/>
                    <ellipse cx="118.798" cy="76.8506" rx="112.851" ry="66.0824" transform="rotate(-5.63863 118.798 76.8506)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <ellipse cx="119.308" cy="74.4146" rx="89.3096" ry="51.6679" transform="rotate(-5.73379 119.308 74.4146)" :fill="color == 'neutral' ? '#374958' : '#383832'"/>
                    <ellipse cx="112.589" cy="55.4024" rx="26.5391" ry="15.446" transform="rotate(-5.304 112.589 55.4024)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <ellipse cx="112.574" cy="55.2304" rx="21.437" ry="12.2613" transform="rotate(-5.304 112.574 55.2304)" :fill="color == 'neutral' ? '#374958' : '#383832'"/>
                    <ellipse cx="158.555" cy="77.7404" rx="27.0109" ry="16.694" transform="rotate(-4.8594 158.555 77.7404)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <ellipse cx="158.512" cy="77.3563" rx="22.1529" ry="12.6708" transform="rotate(-5.304 158.512 77.3563)" :fill="color == 'neutral' ? '#374958' : '#383832'"/>
                    <ellipse cx="94.2422" cy="92.1166" rx="27.8176" ry="17.1494" transform="rotate(-5.304 94.2422 92.1166)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <ellipse cx="94.2593" cy="91.952" rx="23.0023" ry="13.3371" transform="rotate(-5.304 94.2593 91.952)" :fill="color == 'neutral' ? '#374958' : '#383832'"/>
                </template>
                <template v-else>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.386 132.654L231.097 110.433C231.395 92.8759 231.675 74.4451 231.593 72.0901C231.561 71.1445 231.447 70.0375 231.285 68.8783C231.259 67.9666 231.201 67.0533 231.111 66.1388C227.525 29.8191 174.337 5.3404 112.313 11.4642C51.4865 17.4696 4.75032 50.6767 6.34991 86.2129C6.19333 88.5975 6.33193 90.9954 6.76473 93.3518L15.7567 142.308C16.3942 145.779 17.5681 149.129 19.2362 152.239C32.1277 181.094 77.844 199.264 129.998 194.115C177.745 189.401 216.378 166.575 227.937 139.102L228.941 136.983C229.597 135.598 230.081 134.145 230.386 132.654Z" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.377 132.283L231.09 109.991C231.388 92.4534 231.667 74.0659 231.586 71.7139C231.553 70.7683 231.439 69.6614 231.277 68.5022C231.251 67.5905 231.193 66.6771 231.103 65.7626C227.517 29.4428 174.329 4.96418 112.305 11.0879C56.8557 16.5625 13.1155 44.642 7.02726 76.4841L6.94205 76.3752L6.83816 77.5477C6.51065 79.5401 6.33092 81.5463 6.30527 83.5617L6.2372 84.3299C6.00647 86.934 6.12889 89.5572 6.60116 92.1284L15.7488 141.932C16.3847 145.394 17.5545 148.737 19.2162 151.84C32.094 180.709 77.8204 198.89 129.988 193.739C177.713 189.027 216.332 166.219 227.911 138.764L228.933 136.606C229.588 135.224 230.072 133.772 230.377 132.283Z" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M230.377 132.283L231.09 109.991C231.388 92.4534 231.667 74.0659 231.586 71.7139C231.553 70.7683 231.439 69.6614 231.277 68.5022C231.251 67.5905 231.193 66.6771 231.103 65.7626C227.517 29.4428 174.329 4.96418 112.305 11.0879C56.8557 16.5625 13.1155 44.642 7.02726 76.4841L6.94205 76.3752L6.83816 77.5477C6.51065 79.5401 6.33092 81.5463 6.30527 83.5617L6.2372 84.3299C6.00647 86.934 6.12889 89.5572 6.60116 92.1284L15.7488 141.932C16.3847 145.394 17.5545 148.737 19.2162 151.84C32.094 180.709 77.8204 198.89 129.988 193.739C177.713 189.027 216.332 166.219 227.911 138.764L228.933 136.606C229.588 135.224 230.072 133.772 230.377 132.283Z" fill="black" fill-opacity="0.1"/>
                    <ellipse cx="118.798" cy="76.8506" rx="112.851" ry="66.0824" transform="rotate(-5.63863 118.798 76.8506)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <ellipse cx="119.308" cy="74.4146" rx="89.3096" ry="51.6679" transform="rotate(-5.73379 119.308 74.4146)" :fill="color == 'neutral' ? '#374958' : '#383832'"/>
                    <ellipse cx="112.574" cy="55.2304" rx="21.437" ry="12.2613" transform="rotate(-5.304 112.574 55.2304)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <ellipse cx="158.512" cy="77.3563" rx="22.1529" ry="12.6708" transform="rotate(-5.304 158.512 77.3563)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <ellipse cx="94.2593" cy="91.952" rx="23.0023" ry="13.3371" transform="rotate(-5.304 94.2593 91.952)" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                </template>
            </template>
        </svg>
    </div>
</template>

<script>

import colors from '../../helpers/colors.js'

export default {
    mixins: [
        colors, 
    ],
    props: {
        color: String,
        level: Number,
        active: Boolean,
        highlighted: Boolean,
        size: String,
    },
    data() {
        return {
 
        }
    }
}
</script>

<style>

</style>