<template>
    <div class="opponent-wrapper" :class="player.color" :ref="player.color">
        <PlayerInfo class="player-info opponent-player-info" :seafaresExpansion="seafaresExpansion" :player="player" v-if="showGuides" />
        <span class="opponent-points text" :class="player.color">
            <p :class="{ 'highlighted': player.highlightMyPoints }" @click="playerPointsOrCardsClicked(player)">
                {{ player.points }}
            </p>
        </span>
        <Avatar :player="player" style="position: relative; float: left; margin-left: 5px; top: 3px;" />
        <div class="opponent-dice-wrapper" v-if="activePlayer.color == player.color && !['decide starting player', 'initial build'].includes(gamePhase)">
            <div class="dice white" :class="{'rolling upsideDown': dices.whiteDiceRolling}" :style="{'backgroundPosition': '0px ' + -(dices.whiteDiceRoll-1)*28 +'px'}"></div>
            <div class="dice red" :class="{'rolling upsideDown': dices.redDiceRolling}" :style="{'backgroundPosition': '0px ' + -(dices.redDiceRoll-1)*28 +'px'}"></div>
            <div
                class="dice"
                v-if="gamePhase != 'decide starting player'"
                :class="[
                    { 'rolling upsideDown': dices.boatDiceRolling },
                    { 'dice-boat': flatGraphics },
                    { 'dice-boat-2': ! flatGraphics },
                    !dices.boatDiceRolling ? dices.boatDiceRoll.type+'-dice-bg' : '',
                ]"
                :style="{'backgroundPosition': dices.boatDiceBackgroundOffset2+'px ' + -(dices.boatDiceRoll.number-1)*28 +'px'}"
            ></div>
            <!-- {{ dices.boatDiceRoll.number }},
            {{ -(dices.boatDiceRoll.number-1)*28 }} -->
        </div>
        <div class="incoming-outgoing-wrapper">
            <div v-for="(cardObj, index) in player.handCards.slice().reverse()" :key="index" class="opponent-incoming-hand-cards" v-show="player.showIncomingHandCards">
                <template v-if="cardObj.slideIn > 0">
                    <div v-for="(slideIn, index) in cardObj.slideIn" :key="index" v-show="cardObj.slideIn">
                        <div class="hand-card-xs float-left incoming-animation" :class="[cardObj.type, { 'hand-card-xs-2': ! flatGraphics }]"></div>
                    </div>
                </template>
            </div>
            <template v-if="focusType != 'steal 2 hand cards'">
                <div v-for="(cardObj, index) in player.handCards.slice().reverse()" :key="index+99" class="opponent-outgoing-hand-cards" v-show="player.showOutgoingHandCards">
                    <template v-if="cardObj.slideOut > 0" v-show="!player.ai || !player.showIncomingHandCards">
                        <div v-for="(slideOut, index) in cardObj.slideOut" :key="index+99" v-show="cardObj.slideOut">
                            <div class="hand-card-xs float-left outgoing-animation" :class="[cardObj.type, { 'hand-card-xs-2': ! flatGraphics }]"></div>
                        </div>
                    </template>
                </div>
            </template>
        </div>

        <!-- <div class="opponent-dice-wrapper" v-if="['decide starting player', 'initial build'].includes(gamePhase) && players.filter(player => !player.ai).length == 1" :class="{ 'opacity-30': player.dices.fadeAway }">
            <div class="dice white" :class="{'rolling upsideDown': player.dices.whiteDiceRolling}" :style="{'backgroundPosition': '0px ' + -(player.dices.whiteDiceRoll-1)*28 +'px'}"></div>
            <div class="dice red" :class="{'rolling upsideDown': player.dices.redDiceRolling}" :style="{'backgroundPosition': '0px ' + -(player.dices.redDiceRoll-1)*28 +'px'}"></div>
        </div> -->
        <!-- <br><br>
        <div @click="clickOwnDices(5, 5)">10</div>
        <div @click="clickOwnDices(1, 2)">3</div> -->
        <!-- 167px -->
        <div class="relative" :style="{ 'left': activePlayer.color == player.color && gamePhase != 'initial build' ? '207px' : '86px', 'margin-left': gamePhase == 'initial build' ? '0px' : '0px' }">
            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'select aqueduct resource' && waitingForPlayer}" style="margin-left: 20px">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Picking aqueduct card
                </div>
            </div>

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'select goldmine resources' && waitingForPlayer}" v-if="!player.ai">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Selecting goldmine
                </div>
            </div>

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'throw away cards' && waitingForPlayer}" v-if="!player.showOutgoingHandCards">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Throwing cards
                </div>
            </div>

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'build initial house' && waitingForPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Building house
                </div>
            </div>

             <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'select city to destroy' && waitingForPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Removing city
                </div>
            </div>

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'place stolen knight' && waitingForPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Placing knight
                </div>
            </div>

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'build 1 boat' && waitingForPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Placing boat
                </div>
            </div>

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'move robber' && waitingForPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Moving robber
                </div>
            </div>

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'commercial harbor select commodity' && waitingForPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Selecting commodity
                </div>
            </div>

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'build initial city' && waitingForPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Building city
                </div>
            </div>

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'build initial road' && waitingForPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Building road
                </div>
            </div>
        </div>

        <div class="clear-both"></div>
        <!-- | {{ player.fightFor }} | {{ player.giveUp }} -->
        <!-- {{ player.waitingForMe }} -->
        <small class="opponent-name">{{ player.name }}<span v-if="showPotentialPoints">- {{ player.potential }}</span></small>
        <div class="clear-both"></div>
        <div class="opponent-cards-wrapper" @click="playerPointsOrCardsClicked(player)">
            <div class="opponent-cards-hover">
                <div v-for="(card, index) in handCardsCount" :key="index">
                    <div class="hand-card-opponent back opponent-hand-animation" :class="{ 'highlighted-cards': player.highlightMyHandCards }" :style="{ 'right': index*10 + 'px' }" v-if="index <= renderHandCards"></div>
                </div>
                <!-- class="opponent-stop-rendering-cards" -->
                <div v-if="handCardsCount > renderHandCards"></div>
                <div class="card-count" :class="{ 'safe': allowedHandCardsCount >= handCardsCount }">{{ handCardsCount }}</div>
            </div>
        </div>
        <div class="clear-both"></div>
        <div class="opponent-progress-cards-wrapper" @click="playerPointsOrCardsClicked(player)">
            <div class="opponent-progress-cards-container point-cards-container-opponent">
                <div
                    v-for="(card, index) in player.pointCards"
                    :key="index"
                    class="point-card-opponent"
                    :class="card.label"
                ></div>
            </div>
            <div class="opponent-progress-cards-container">
                <div class="point-card-opponent merchant" v-if="merchant.player == player"></div>
            </div>
            <div class="opponent-progress-cards-container">
                <div class="point-card-opponent metropolis-cloth" v-if="player.progress.find(obj => obj.type == 'cloth').metropolis"></div>
                <div class="point-card-opponent metropolis-coin" v-if="player.progress.find(obj => obj.type == 'coin').metropolis"></div>
                <div class="point-card-opponent metropolis-paper" v-if="player.progress.find(obj => obj.type == 'paper').metropolis"></div>
            </div>

            <div class="opponent-progress-cards-container" v-if="longestRoad.length && longestRoad[0].player.color == player.color">
                <div class="point-card-opponent longest-road"></div>
            </div>
            <div class="opponent-progress-cards-container">
                <div
                    v-for="(card, index) in player.progressCards"
                    :key="index"
                    class="progress-card-opponent back"
                    :class="[
                        card.deck,
                        { 'highlighted-cards': player.highlightMyProgressCards },
                    ]"
                ></div>
            </div>
        </div>
        <div class="clear-both"></div>
        <CommodityProgress :player="player" :metropolisTopLevel="metropolisTopLevel" :isOpponent="true"  :flatGraphics="flatGraphics" class="progress-wrapper-opponent"></CommodityProgress>
        <div class="clear-both mb-3"></div>
    </div>
</template>

<script>
import CommodityProgress from './CommodityProgress';
import PlayerInfo from './PlayerInfo';
import Avatar from './Avatar';
export default {
    props: ['player', 'players', 'waitingForPlayer', 'activePlayer', 'focusType', 'metropolisTopLevel', 'longestRoad', 'merchant', 'dices', 'gamePhase', 'showPotentialPoints', 'cities', 'showGuides', 'seafaresExpansion', 'scrollTo', 'flatGraphics'],
    data() {
        return {
            renderHandCards: 20,
        }
    },
    methods: {
        clickDices() {
            this.$emit('clickDices');

            this.$forceUpdate();
        },
        clickOwnDices(whiteNumber, redNumber) {
            this.$emit('clickOwnDices', this.player, whiteNumber, redNumber);

            this.$forceUpdate();
        },
        playerPointsOrCardsClicked(player) {
            this.$emit('playerPointsOrCardsClicked', player);

            this.$forceUpdate();
        },
        becomePlayer(color) {
            this.$emit('becomePlayer', color);

            this.$forceUpdate();
        },
        commodityUpgradeClicked(player, type) {
            this.$emit('commodityUpgradeClicked', player, type);

            this.$forceUpdate();
        },
    },
    components: {
        CommodityProgress,
        PlayerInfo,
        Avatar,
    },
    watch: {
        scrollTo(newValue) {
            if(newValue == 'white') {
                if(this.$refs.white) this.$refs.white.scrollIntoView({ behavior: "smooth" });
            } else if(newValue == 'red') {
                if(this.$refs.red) this.$refs.red.scrollIntoView({ behavior: "smooth" });
            } else if(newValue == 'blue') {
                if(this.$refs.blue) this.$refs.blue.scrollIntoView({ behavior: "smooth" });
            } else if(newValue == 'orange') {
                if(this.$refs.orange) this.$refs.orange.scrollIntoView({ behavior: "smooth" });
            } else if(newValue == 'green') {
                if(this.$refs.green) this.$refs.green.scrollIntoView({ behavior: "smooth" });
            } else if(newValue == 'brown') {
                if(this.$refs.brown) this.$refs.brown.scrollIntoView({ behavior: "smooth" });
            } else if(newValue == 'pink') {
                if(this.$refs.pink) this.$refs.pink.scrollIntoView({ behavior: "smooth" });
            }
        }
    },
    computed: {
        handCardsCount() {
            return this.player.handCards.reduce((numberOfCards, card) => numberOfCards + card.amount, 0);
        },
        allowedHandCardsCount() {
            return 7 + this.cities.filter(city => city.player == this.player && city.citywall == true).length * 2;
        },
    },
}
</script>

<style>

</style>
