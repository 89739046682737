export default {
    methods: {
        async aiMoveBoat() {
            // if uselsess/blocked boat found ai will move it
            this.roads
                .filter(road => road.player == this.activePlayer && road.boat)
                .forEach(boat => {
                    // if(this.roadIsUseless(boat)) this.roadSpotClicked(boat.tile, boat.roadSpot);
                    this.roadSpotClicked(boat.tile, boat.roadSpot);
                });
        },
        aiAboutToGetBlocked(player) {
            let buildRoad = false;

            this.roads.filter(road => road.player == player).forEach(road => {
                this.roadSpots(road.tile, road.roadSpot).forEach(spot => {
                    if(!spot) return;

                    if(spot.content.type == undefined) {
                        if(this.spotRoads(spot.tile, spot.position).find(road => road && road.player.color != player.color)) {
                            this.spotRoadsAll(spot.tile, spot.position).forEach(obj => {
                                if(!this.roads.find(road => road.key == obj.roadSpot.key)) {
                                    this.roadSpots(obj.tile, obj.roadSpot.position).forEach(spot_2 => {
                                        if(!spot_2) return;
                                        if(spot_2.key != spot.key) {
                                            // make sure next spot is available for house
                                            if(this.buildingDistance(spot_2.tile, spot_2.position)) buildRoad = true;
                                        }
                                    })
                                }
                            })
                        }
                    }
                });
            });

            return buildRoad;
        },
        getAlchemistCardNeed() {
            let choise = null;

            if(this.firstBoatRound && !this.knights.find(knight => knight.player == this.activePlayer)) {
                if(this.activePlayer.handCards.find(obj => obj.type == 'ore').amount == 0) choise = 'ore';
                if(this.activePlayer.handCards.find(obj => obj.type == 'wool').amount == 0) choise = 'wool';
                if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0) choise = 'grain';
            } else if(this.firstBoatRound && this.knights.find(knight => knight.player == this.activePlayer && knight.active)) {
                if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0) choise = 'grain';
            }

            return choise;
        },
        aiGetOptimalRedDice() {

            let scoreBoard = [
                { number: 2, score: 0, opponentScores: [] },
                { number: 3, score: 0, opponentScores: [] },
                { number: 4, score: 0, opponentScores: [] },
                { number: 5, score: 0, opponentScores: [] },
                { number: 6, score: 0, opponentScores: [] },
            ];

            this.players
                .forEach(player => {
                    for(let i = 0; i < 5; i++) {
                        let score = 0;
                        player.progress.forEach(progress => {
                            if(progress.level >= i+1) {
                                score++;
                            }
                        });

                        if(player == this.activePlayer) {
                            scoreBoard.find(obj => obj.number == i+2).score = score;
                        } else {
                            scoreBoard.find(obj => obj.number == i+2).opponentScores.push(score)
                        }

                    }

                })

            scoreBoard.forEach(obj => {
                obj.opponentsAverage = obj.opponentScores.reduce((total, value) => total + value, 0) / obj.opponentScores.length;
            });

            scoreBoard.sort((a, b) => b.number - a.number)

            scoreBoard = scoreBoard.filter(obj => obj.opponentsAverage <= obj.score);

            // just in case
            if(scoreBoard.length == 0) return 6;

            scoreBoard.sort((a, b) => (b.score-b.opponentsAverage) - (a.score-a.opponentsAverage))

            return scoreBoard[0].number;

        },
        aiPlayAlchemist(tryToGet = null) {
            // amount of cards, type of cards
            let options = [
                { number: 2, amount: 0, types: [], dots: 1 },
                { number: 3, amount: 0, types: [], dots: 2 },
                { number: 4, amount: 0, types: [], dots: 3 },
                { number: 5, amount: 0, types: [], dots: 4 },
                { number: 6, amount: 0, types: [], dots: 5 },
                { number: 8, amount: 0, types: [], dots: 5 },
                { number: 9, amount: 0, types: [], dots: 4 },
                { number: 10, amount: 0, types: [], dots: 3 },
                { number: 11, amount: 0, types: [], dots: 2 },
                { number: 12, amount: 0, types: [], dots: 1 },
            ];

            if(this.leader.points >= this.playToPoints-3) {
                // activePlayer is not winning
                if(this.activePlayer.points <= this.playToPoints-5) {
                    // leader has too many handCards, roll 7
                    if((7 + this.cities.filter(city => city.player.color == this.leader.color && city.citywall == true).length * 2) < (this.leader.handCards.reduce((numberOfCards, card) => numberOfCards + card.amount, 0))) {
                        this.selectAlchemist(6, 'red');
                        this.selectAlchemist(1, 'white');
                        return;
                    }
                }
            }

            this.buildings
                .filter(building => building.player == this.activePlayer)
                .forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {
                    if(tile.type == 'desert') return;

                    if(this.robber.tile == tile) return;

                    let target = options.find(obj => obj.number == tile.number.value);

                    if(!target) return;
                    target.amount += 1;
                    target.types.push(tile.type);

                    if(building.type == 'city' && building.disabled == false) {
                        target.amount += 1;

                        if(tile.type == 'wool') {
                            target.types.push('cloth');
                        } else if(tile.type == 'ore') {
                            target.types.push('coin');
                        } else if(tile.type == 'lumber') {
                            target.types.push('paper');
                        } else {
                            target.types.push(tile.type);
                        }
                    }

                });
            });

            // prefer numbers which suck if amout is the same
            options.sort((a,b) => a.dots - b.dots);
            options.sort((a,b) => b.amount - a.amount);

            if(tryToGet) {
                if(options.filter(obj => obj.types.includes(tryToGet)).length) {
                    options = options.filter(obj => obj.types.includes(tryToGet));
                }
            }

            let choise = options[0].number;


            if(choise > 7) {
                // couldn't pick optimal red dice, set 6 on red
                if(choise-6 > this.aiGetOptimalRedDice()) {
                    this.selectAlchemist(6, 'red');
                    this.selectAlchemist(choise-6, 'white');
                } else {
                    this.selectAlchemist(this.aiGetOptimalRedDice(), 'red');
                    this.selectAlchemist(choise-this.aiGetOptimalRedDice(), 'white');
                }
            } else {
                this.aiGetOptimalRedDice()

                if(choise > this.aiGetOptimalRedDice()) {
                    this.selectAlchemist(this.aiGetOptimalRedDice(), 'red');
                    this.selectAlchemist(choise-this.aiGetOptimalRedDice(), 'white');
                // couldn't pick optimal red dice, set as high as possible on red
                } else {
                    this.selectAlchemist(choise-1, 'red');
                    this.selectAlchemist(1, 'white');
                }
            }
        },
        aiEnableCity() {
            if(! this.activePlayer.ai) return;

            // don't enable cities in end game if one enabled exists
            let citiesCanTakeMetropolis = this.cities.filter(city => city.player == this.activePlayer && city.disabled == false && city.metropolis == false).length;
            if(citiesCanTakeMetropolis >= 1 && this.lateGame) return;

            let disabledCities = this.cities.filter(city => city.player == this.activePlayer && city.disabled);

            if(disabledCities.length == 0) return;

            if(!this.canPay(this.activePlayer, [ { type: 'lumber', amount: 1 }, { type: 'ore', amount: 1 } ])) return false;

            this.pay(this.activePlayer, [ { type: 'lumber', amount: 1 }, { type: 'ore', amount: 1 } ])

            disabledCities.sort((a, b) => this.spotDots(b.tile, b.position) - this.spotDots(a.tile, a.position));

            disabledCities[0].disabled = false;

        },
        aiCloseModal() {
            this.closeModal(this.activePlayer, true);
            return this.focusModeOff(this.activePlayer);
        },
        async aiMakeTrade(get, player, giveTwoCards = false) {

            if(this.multiplayer) return;

            if(['Forest escape'].includes(this.aiTactic)) {
                if(!this.lateGame) return;
            }

            if(this.resourceCount(player) == 0) return this.aiCloseModal();

            if(giveTwoCards && this.resourceCount(player) < 2) return this.aiCloseModal();

            if(this.playerHandCardsCount(player) <= 5) return this.aiCloseModal();

            if(player.points >= this.playToPoints-3) return this.aiCloseModal();

            if(this.rejectedTradeTypes.find(obj => obj.type == get).rejected == this.players.length-1) return this.aiCloseModal();

            this.aiGetHandCardsToThrow(player);

            if(player.handCards.filter(obj => !['cloth', 'coin', 'paper'].includes(obj.type)).reduce((getTotal, obj) => getTotal + obj.throw, 0) == 0) return this.aiCloseModal();

            this.quickTradeGet(get, player);

            let handCardsCopy = JSON.parse(JSON.stringify(player.handCards));

            let options;
            let give;

            options = handCardsCopy
                .filter(obj => obj.type != get && obj.throw > 0 && !['cloth', 'coin', 'paper'].includes(obj.type))
                .sort((a, b) => b.throw - a.throw);

            if(!options.length) return this.aiCloseModal();

            give = options[0].type;
            options[0].throw--;

            this.quickTradeGive(give, player);

            if(giveTwoCards) {
                options = handCardsCopy
                    .filter(obj => obj.type != get && obj.throw > 0 && !['cloth', 'coin', 'paper'].includes(obj.type))
                    .sort((a, b) => b.throw - a.throw);

                if(!options.length) return this.aiCloseModal();

                give = options[0].type;
                options[0].throw--;

                this.quickTradeGive(give, player);
            }

            this.makeOffer(player);

            await this.waitForTradeResponses();

            let opponents = this.players.filter(player => player != this.activePlayer);

            opponents = opponents
                .filter(opponent => opponent.offerAnswer == 'accepted' && opponent.points < this.playToPoints-4)
                .sort((a, b) => a.potential - b.potential);

            if(opponents.length == 0) {
                this.closeModal(this.activePlayer, true);
                this.focusModeOff(this.activePlayer);
                return false;
            }


            opponents.forEach(opponent => {
                let tradeMatches = true;

                // get
                player.tradeGet
                    .filter(obj => obj.amount > 0)
                    .forEach(obj => {
                        let target = opponent.handCards.find(tradeObj => tradeObj.type == obj.type);
                        if(target.selected < obj.amount) tradeMatches = false;
                })

                // give
                opponent.tradeGet
                    .filter(obj => obj.amount > 0)
                    .forEach(obj => {
                        let target = this.activePlayer.handCards.find(tradeObj => tradeObj.type == obj.type);
                        // have to give 2 cards for one
                        // turn > to accept these trades
                        if(target.selected < obj.amount) tradeMatches = false;
                })

                if(tradeMatches == false) {
                    opponents = opponents.filter(obj => obj != opponent);
                }
            })

            if(opponents.length == 0) {
                this.closeModal(this.activePlayer, true);
                this.focusModeOff(this.activePlayer);
                return false;
            }

            opponents.sort((a, b) => b.handCards.reduce((getTotal, obj) => getTotal + obj.selected, 0) - a.handCards.reduce((getTotal, obj) => getTotal + obj.selected, 0))

            if(opponents.length > 1) opponents = opponents.filter(opponent => opponent != this.leader);


            setTimeout(() => {
                this.makeTrade(opponents[0]);
                return true;
            }, this.randomNumber(250, 250+this.aiActionSpeed*3));

        },
        aiGetHandCardsToThrow(player) {
            this.aiGetHandCardsToSave(player);

            player.handCards.forEach(obj => {
                obj.throw = 0;
                if(obj.amount > obj.save) {
                    obj.throw += obj.amount-obj.save;
                }
            });
        },
        aiDiscardHalfHand(player) {
            if(! player.ai) return;
            if(this.playerHandCardsCount(player) <= 7 + this.cities.filter(city => city.player == player && city.citywall == true).length * 2) return;

            this.aiGetHandCardsToSave(player);

            player.showOutgoingHandCards = true;

            player.handCards.forEach(obj => {
                if(obj.amount > obj.save) {
                    obj.slideOut += obj.amount-obj.save;
                    obj.selected += obj.amount-obj.save;
                }
            })

            let selectedTotal = player.handCards.reduce((total, obj) => total + obj.selected, 0);

            setTimeout(() => {
                if(selectedTotal != this.playerHandCardsCount(player)) {
                    // this.log(':playerName threw away ' + selectedTotal + ' cards', player);
                }

                let throwAwayString = '';
                let handCardsArr = [];

                player.handCards.forEach(cardObj => {
                    for(let i = 0; i < cardObj.selected; i++) handCardsArr.push(cardObj.type);

                    cardObj.amount = cardObj.save;
                    cardObj.selected = 0;
                });

                handCardsArr.reverse();

                handCardsArr.forEach(item => {
                    throwAwayString += ':' + item + ' ';
                })

                this.log('<div>:playerName threw away</div>' + throwAwayString, player);

                this.focusModeOff(player);

                player.handCards.forEach(cardObj => {
                    cardObj.slideOut = 0;
                    cardObj.save = 0;
                    player.showOutgoingHandCards = false;
                });

                this.saveGame();
            }, 700);

        },
        aiGetHandCardsToSave(player, basic = false) {
            let keepCards = Math.ceil(this.playerHandCardsCount(player) / 2);

            player.handCards.forEach(obj => obj.save = 0);

            if(keepCards == 0) return;

            // wedding
            if(this.playerHandCardsCount(player) < 4) keepCards = 0;

            let target;
            let tryToKeep;
            let importantCardsSaved = 0;

            let cheapestTrades = JSON.parse(JSON.stringify(player.handCards));
            cheapestTrades.sort((a,b) => a.tradeCost - b.tradeCost);
            let mostCards = JSON.parse(JSON.stringify(player.handCards));
            // when trying to save knight wildCard and has 4 of both, prioritize other types
            ['ore', 'grain', 'wool'].forEach(type => {
                mostCards.find(obj => obj.type == type).amount -= 1;
            })
            mostCards.sort((a,b) => b.amount - a.amount);

            let loopIndex = 0;
            while(keepCards > 0) {
                loopIndex++;

                // player fighting for metropolis
                player.progress.forEach(progress => {
                    if(player.fightFor.includes(progress.type)) {
                        target = player.handCards.find(obj => obj.type == progress.type);
                        tryToKeep = 6;

                        for(let i = 0; i < tryToKeep; i++) {
                            if(target.save < target.amount && keepCards > 0) {
                                target.save++;
                                keepCards--;
                            }
                        }
                    }
                });

                // ai fighting for longest road
                if(player.fightFor.includes('longest')) {
                    target = player.handCards.find(obj => obj.type == 'lumber');
                    tryToKeep = 6;

                    for(let i = 0; i < tryToKeep; i++) {
                        if(target.save < target.amount && keepCards > 0) {
                            target.save++;
                            keepCards--;
                        }
                    }
                    target = player.handCards.find(obj => obj.type == 'brick');
                    tryToKeep = 6;

                    for(let i = 0; i < tryToKeep; i++) {
                        if(target.save < target.amount && keepCards > 0) {
                            target.save++;
                            keepCards--;
                        }
                    }
                }

                if(this.cities.find(city => city.player == player) && !this.firstBoatRound) {
                    player.progress.forEach(progress => {
                        // try to save paper to third
                        if(progress.type == 'paper' && (progress.level == 1 || progress.level == 2)) {
                            target = player.handCards.find(obj => obj.type == 'paper');
                            tryToKeep = 3;

                            for(let i = 0; i < tryToKeep; i++) {
                                if(target.save < target.amount && keepCards > 0) {
                                    target.save++;
                                    keepCards--;
                                }
                            }
                        }
                    });
                }


                // if cloth on thrid and not first boat-round, try to keep commodities in pairs, (keep 2, 4, 6 etc)
                if(!this.firstBoatRound) {
                    if(this.activePlayer.progress.find(progress => progress.type == 'cloth').level >= 3) {
                        ['cloth', 'paper', 'coin'].forEach(type => {
                            target = player.handCards.find(obj => obj.type == type);

                            tryToKeep = 2 * Math.floor(target.amount/2);

                            for(let i = 0; i < tryToKeep; i++) {
                                if(target.save < target.amount && keepCards > 0) {
                                    target.save++;
                                    keepCards--;
                                }
                            }
                        });
                    }
                }

                if(this.aiSaveCardsFor(player, basic) == 'city') {

                    target = player.handCards.find(obj => obj.type == 'ore');
                    tryToKeep = 3;

                    for(let i = 0; i < tryToKeep; i++) {
                        if(target.save < target.amount && keepCards > 0) {
                            target.save++;
                            keepCards--;
                        }
                    }

                    target = player.handCards.find(obj => obj.type == 'grain');
                    tryToKeep = 2;

                    for(let i = 0; i < tryToKeep; i++) {
                        if(target.save < target.amount && keepCards > 0) {
                            target.save++;
                            keepCards--;
                        }
                    }

                    if(loopIndex == 1) {
                        player.progress.forEach(progress => {
                            if(progress.level == 0) {
                                target = player.handCards.find(obj => obj.type == progress.type);
                                tryToKeep = 1;

                                for(let i = 0; i < tryToKeep; i++) {
                                    if(target.save < target.amount && keepCards > 0) {
                                        target.save++;
                                        keepCards--;
                                    }
                                }
                            }
                        })
                    }

                    if(this.cities.find(city => city.player == player && city.disabled == true)) {
                        ['lumber', 'ore'].forEach(type => {
                            target = player.handCards.find(obj => obj.type == type);
                            tryToKeep = 1;

                            for(let i = 0; i < tryToKeep; i++) {
                                if(target.save < target.amount && keepCards > 0) {
                                    target.save++;
                                    keepCards--;
                                }
                            }
                        })
                    }

                    ['lumber', 'brick', 'wool'].forEach(type => {
                        target = player.handCards.find(obj => obj.type == type);
                        tryToKeep = 1;

                        for(let i = 0; i < tryToKeep; i++) {
                            if(target.save < target.amount && keepCards > 0) {
                                target.save++;
                                keepCards--;
                            }
                        }
                    })

                    // this is just when player only has 10 paper cards and the loop would get stuck otherwise
                    if(loopIndex == 5) {
                        player.progress.forEach(progress => {
                            target = player.handCards.find(obj => obj.type == progress.type);
                            tryToKeep = 1;

                            for(let i = 0; i < tryToKeep; i++) {
                                if(target.save < target.amount && keepCards > 0) {
                                    target.save++;
                                    keepCards--;
                                }
                            }
                        });
                    }
                } else if(this.aiSaveCardsFor(player, basic) == 'activate knight') {
                    ['grain'].forEach(type => {
                        target = player.handCards.find(obj => obj.type == type);
                        tryToKeep = 2;

                        for(let i = 0; i < tryToKeep; i++) {
                            if(target.save < target.amount && keepCards > 0) {
                                target.save++;
                                importantCardsSaved++;
                                keepCards--;
                            }
                        }
                    })

                    if(importantCardsSaved < 2) {
                        // if something is cheap to trade 3:1/2:1, keep some of those
                        if(cheapestTrades[0].tradeCost < 4) {
                            target = player.handCards.find(obj => obj.type == cheapestTrades[0].type);
                            tryToKeep = (cheapestTrades[0].tradeCost)*2;

                            if(target.amount >= cheapestTrades[0].tradeCost && keepCards >= cheapestTrades[0].tradeCost) {
                                for(let i = 0; i < tryToKeep; i++) {
                                    if(target.save < target.amount && keepCards > 0) {
                                        target.save++;
                                        keepCards--;
                                    }
                                }
                            }
                        }

                        // try to keep something you have many of so you can trade 4:1 later
                        target = player.handCards.find(obj => obj.type == mostCards[0].type);
                        tryToKeep = mostCards[0].tradeCost;

                        if(target.amount >= mostCards[0].tradeCost && keepCards >= mostCards[0].tradeCost) {
                            for(let i = 0; i < tryToKeep; i++) {
                                if(target.save < target.amount && keepCards > 0) {
                                    target.save++;
                                    keepCards--;
                                }
                            }
                        }
                    }

                    if(this.cities.find(city => city.player == player && city.disabled == true)) {
                        ['lumber', 'ore'].forEach(type => {
                            target = player.handCards.find(obj => obj.type == type);
                            tryToKeep = 1;

                            for(let i = 0; i < tryToKeep; i++) {
                                if(target.save < target.amount && keepCards > 0) {
                                    target.save++;
                                    keepCards--;
                                }
                            }
                        })
                    }

                    ['grain', 'ore', 'lumber', 'brick', 'wool'].forEach(type => {
                        target = player.handCards.find(obj => obj.type == type);
                        tryToKeep = 1;
                        if(['grain', 'ore'].includes(type)) tryToKeep = 2;
                        if(player.pieces.cities == 0 && type == 'ore') tryToKeep = 0;

                        for(let i = 0; i < tryToKeep; i++) {
                            if(target.save < target.amount && keepCards > 0) {
                                target.save++;
                                keepCards--;
                            }
                        }
                    });

                    if(loopIndex == 1) {
                        player.progress.forEach(progress => {
                            if(progress.level == 0) {
                                target = player.handCards.find(obj => obj.type == progress.type);
                                tryToKeep = 1;

                                for(let i = 0; i < tryToKeep; i++) {
                                    if(target.save < target.amount && keepCards > 0) {
                                        target.save++;
                                        keepCards--;
                                    }
                                }
                            }

                            // try to save paper to third
                            if(progress.type == 'paper' && progress.level == 2) {
                                target = player.handCards.find(obj => obj.type == 'paper');
                                tryToKeep = 3;

                                for(let i = 0; i < tryToKeep; i++) {
                                    if(target.save < target.amount && keepCards > 0) {
                                        target.save++;
                                        keepCards--;
                                    }
                                }
                            }
                        })
                    }


                } else if(this.aiSaveCardsFor(player, basic) == 'knight') {
                    // try to keep one of each knight card to begin with
                    ['ore', 'wool', 'grain'].forEach(type => {
                        target = player.handCards.find(obj => obj.type == type);
                        tryToKeep = 1;

                        for(let i = 0; i < tryToKeep; i++) {
                            if(target.save < target.amount && keepCards > 0) {
                                target.save++;
                                importantCardsSaved++;
                                keepCards--;
                            }
                        }
                    })

                    if(importantCardsSaved < 3) {
                        let extra = 0;
                        // if something is cheap to trade 3:1/2:1, keep some of those
                        if(cheapestTrades[0].tradeCost < 4) {
                            target = player.handCards.find(obj => obj.type == cheapestTrades[0].type);
                            if(['ore', 'wool', 'grain'].includes(cheapestTrades[0].type)) extra = 1;
                            tryToKeep = (cheapestTrades[0].tradeCost)*2+extra;

                            if(target.amount >= cheapestTrades[0].tradeCost+extra && keepCards >= cheapestTrades[0].tradeCost+extra) {
                                for(let i = 0; i < tryToKeep; i++) {
                                    if(target.save < target.amount && keepCards > 0) {
                                        target.save++;
                                        keepCards--;
                                    }
                                }
                            }
                        }

                        // try to keep something you have many of so you can trade 4:1 later
                        extra = 0;
                        target = player.handCards.find(obj => obj.type == mostCards[0].type);
                        if(['ore', 'wool', 'grain'].includes(mostCards[0].type)) extra = 1;
                        tryToKeep = mostCards[0].tradeCost+extra;

                        if(target.amount >= mostCards[0].tradeCost+extra && keepCards >= mostCards[0].tradeCost+extra) {
                            for(let i = 0; i < tryToKeep; i++) {
                                if(target.save < target.amount && keepCards > 0) {
                                    target.save++;
                                    keepCards--;
                                }
                            }
                        }
                    }

                    if(loopIndex == 1) {
                        player.progress.forEach(progress => {
                            if(progress.level == 0) {
                                target = player.handCards.find(obj => obj.type == progress.type);
                                tryToKeep = 1;

                                for(let i = 0; i < tryToKeep; i++) {
                                    if(target.save < target.amount && keepCards > 0) {
                                        target.save++;
                                        keepCards--;
                                    }
                                }
                            }

                            // try to save paper to third
                            if(progress.type == 'paper' && progress.level == 2) {
                                target = player.handCards.find(obj => obj.type == 'paper');
                                tryToKeep = 3;

                                for(let i = 0; i < tryToKeep; i++) {
                                    if(target.save < target.amount && keepCards > 0) {
                                        target.save++;
                                        keepCards--;
                                    }
                                }
                            }
                        })
                    }

                    // save towards wildcard even if you may not get it
                    if(importantCardsSaved < 3) {
                        let extra = 0;

                        // if something is cheap to trade 3:1/2:1, keep some of those
                        if(cheapestTrades[0].tradeCost < 4) {
                            target = player.handCards.find(obj => obj.type == cheapestTrades[0].type);
                            if(['ore', 'wool', 'grain'].includes(cheapestTrades[0].type)) extra = 1;
                            tryToKeep = (cheapestTrades[0].tradeCost)*2+extra;

                            for(let i = 0; i < tryToKeep; i++) {
                                if(target.save < target.amount && keepCards > 0) {
                                    target.save++;
                                    keepCards--;
                                }
                            }
                        }

                        // try to keep something you have many of so you can trade 4:1 later
                        extra = 0;
                        target = player.handCards.find(obj => obj.type == mostCards[0].type);
                        if(['ore', 'wool', 'grain'].includes(mostCards[0].type)) extra = 1;
                        tryToKeep = mostCards[0].tradeCost+extra;

                        for(let i = 0; i < tryToKeep; i++) {
                            if(target.save < target.amount && keepCards > 0) {
                                target.save++;
                                keepCards--;
                            }
                        }
                    }

                    if(this.cities.find(city => city.player == player && city.disabled == true)) {
                        ['lumber', 'ore'].forEach(type => {
                            target = player.handCards.find(obj => obj.type == type);
                            tryToKeep = 1;

                            for(let i = 0; i < tryToKeep; i++) {
                                if(target.save < target.amount && keepCards > 0) {
                                    target.save++;
                                    keepCards--;
                                }
                            }
                        })
                    }


                    ['grain', 'ore', 'lumber', 'brick', 'wool'].forEach(type => {
                        target = player.handCards.find(obj => obj.type == type);
                        tryToKeep = 1;
                        if(['grain', 'ore'].includes(type)) tryToKeep = 2;
                        if(player.pieces.cities == 0 && type == 'ore') tryToKeep = 0;

                        for(let i = 0; i < tryToKeep; i++) {
                            if(target.save < target.amount && keepCards > 0) {
                                target.save++;
                                keepCards--;
                            }
                        }
                    });

                    ['grain', 'ore', 'lumber', 'brick', 'wool'].forEach(type => {
                        target = player.handCards.find(obj => obj.type == type);
                        tryToKeep = 1;
                        if(['grain', 'ore'].includes(type)) tryToKeep = 0;

                        for(let i = 0; i < tryToKeep; i++) {
                            if(target.save < target.amount && keepCards > 0) {
                                target.save++;
                                keepCards--;
                            }
                        }
                    });

                    // this is just when player only has 10 paper cards and the loop would get stuck otherwise
                    if(loopIndex == 5) {
                        player.progress.forEach(progress => {
                            target = player.handCards.find(obj => obj.type == progress.type);
                            tryToKeep = 1;

                            for(let i = 0; i < tryToKeep; i++) {
                                if(target.save < target.amount && keepCards > 0) {
                                    target.save++;
                                    keepCards--;
                                }
                            }
                        });
                    }
                } else if(['house', 'boat and house'].includes(this.aiSaveCardsFor(player, basic))) {

                    ['grain', 'lumber', 'wool', 'brick'].forEach(type => {
                        target = player.handCards.find(obj => obj.type == type);
                        tryToKeep = 1;

                        for(let i = 0; i < tryToKeep; i++) {
                            if(target.save < target.amount && keepCards > 0) {
                                target.save++;
                                keepCards--;
                            }
                        }
                    });

                    if(['boat and house'].includes(this.aiSaveCardsFor(player, basic))) {

                        ['lumber', 'wool'].forEach(type => {
                            target = player.handCards.find(obj => obj.type == type);
                            tryToKeep = 1;

                            for(let i = 0; i < tryToKeep; i++) {
                                if(target.save < target.amount && keepCards > 0) {
                                    target.save++;
                                    keepCards--;
                                }
                            }
                        });
                    }


                    if(loopIndex == 1) {
                        player.progress.forEach(progress => {
                            if(progress.level == 0) {
                                target = player.handCards.find(obj => obj.type == progress.type);
                                tryToKeep = 1;

                                for(let i = 0; i < tryToKeep; i++) {
                                    if(target.save < target.amount && keepCards > 0) {
                                        target.save++;
                                        keepCards--;
                                    }
                                }
                            }

                            // try to save paper to third
                            if(progress.type == 'paper' && progress.level == 2) {
                                target = player.handCards.find(obj => obj.type == 'paper');
                                tryToKeep = 3;

                                for(let i = 0; i < tryToKeep; i++) {
                                    if(target.save < target.amount && keepCards > 0) {
                                        target.save++;
                                        keepCards--;
                                    }
                                }
                            }
                        })
                    }


                    let extra = 0;

                    // if something is cheap to trade 3:1/2:1, keep some of those
                    if(cheapestTrades[0].tradeCost < 4) {
                        target = player.handCards.find(obj => obj.type == cheapestTrades[0].type);
                        tryToKeep = (cheapestTrades[0].tradeCost)*2;

                        for(let i = 0; i < tryToKeep; i++) {
                            if(target.save < target.amount && keepCards > 0) {
                                target.save++;
                                keepCards--;
                            }
                        }
                        target = player.handCards.find(obj => obj.type == cheapestTrades[1].type);
                        tryToKeep = (cheapestTrades[1].tradeCost)*2;

                        for(let i = 0; i < tryToKeep; i++) {
                            if(target.save < target.amount && keepCards > 0) {
                                target.save++;
                                keepCards--;
                            }
                        }
                        target = player.handCards.find(obj => obj.type == cheapestTrades[0].type);
                        tryToKeep = (cheapestTrades[0].tradeCost)*2;

                        for(let i = 0; i < tryToKeep; i++) {
                            if(target.save < target.amount && keepCards > 0) {
                                target.save++;
                                keepCards--;
                            }
                        }
                    }

                    if(this.cities.find(city => city.player == player && city.disabled == true)) {
                        ['lumber', 'ore'].forEach(type => {
                            target = player.handCards.find(obj => obj.type == type);
                            tryToKeep = 1;

                            for(let i = 0; i < tryToKeep; i++) {
                                if(target.save < target.amount && keepCards > 0) {
                                    target.save++;
                                    keepCards--;
                                }
                            }
                        })
                    }

                    ['grain', 'ore', 'lumber', 'brick', 'wool'].forEach(type => {
                        target = player.handCards.find(obj => obj.type == type);
                        tryToKeep = 1;
                        if(['grain', 'ore'].includes(type)) tryToKeep = 2;
                        if(player.pieces.cities == 0 && type == 'ore') tryToKeep = 0;

                        for(let i = 0; i < tryToKeep; i++) {
                            if(target.save < target.amount && keepCards > 0) {
                                target.save++;
                                keepCards--;
                            }
                        }
                    });

                    // this is just when player only has 10 paper cards and the loop would get stuck otherwise
                    if(loopIndex == 5) {
                        player.progress.forEach(progress => {
                            target = player.handCards.find(obj => obj.type == progress.type);
                            tryToKeep = 1;

                            for(let i = 0; i < tryToKeep; i++) {
                                if(target.save < target.amount && keepCards > 0) {
                                    target.save++;
                                    keepCards--;
                                }
                            }
                        });
                    }
                }

                // backup to not get stuck in the while loop
                if(loopIndex > 50) {
                    ['grain', 'ore', 'lumber', 'brick', 'wool', 'cloth', 'paper', 'coin'].forEach(type => {
                        target = player.handCards.find(obj => obj.type == type);
                        tryToKeep = 1;
                        if(['grain', 'ore'].includes(type)) tryToKeep = 2;
                        if(player.pieces.cities == 0 && type == 'ore') tryToKeep = 1;

                        for(let i = 0; i < tryToKeep; i++) {
                            if(target.save < target.amount && keepCards > 0) {
                                target.save++;
                                keepCards--;
                            }
                        }
                    });
                }
            }
        },
        aiSaveCardsFor(player, basic = false) {

            // if player has no cities, try to rebuild a city
            if(!this.cities.find(city => city.player == player)) return 'city';

            let activeKnightsNeeded = this.cities.length - this.allActiveKnightsCount;

            let activePlayerActiveKnights = this.knights
                .filter(knight => knight.player == player && knight.active)
                .reduce((activeKnightsTotal, knight) => activeKnightsTotal + knight.level, 0);

            let activePlayerInactiveKnights = this.knights
                .filter(knight => knight.player == player && !knight.active)
                .reduce((activeKnightsTotal, knight) => activeKnightsTotal + knight.level, 0);



            // player has least knights and there is not enough
            // if player has 0 knights, always try to build/activate at least one even if there is enough
            if(
                (this.playersWithLeastActiveKnights.find(obj => obj.player == player) &&
                activeKnightsNeeded > 0) ||
                activePlayerActiveKnights == 0
            ) {
                if(
                    activePlayerInactiveKnights != 0 &&
                    player.handCards.find(obj => obj.type == 'grain').amount == 0 &&
                    !this.aiHasProgressCard('warlord')
                ) {
                    return 'activate knight';
                } else if(activePlayerInactiveKnights == 0) {
                    return 'knight'
                }
            }

            let options = [];

            this.roads.filter(road => road.player == player).forEach(road => {
                this.roadSpots(road.tile, road.roadSpot).forEach(spot => {

                    if(options.find(houseSpot => houseSpot.key == spot.key)) return;

                    if(!this.buildingDistance(spot.tile, spot.position)) return;

                    options.push(spot);
                });
            })

            if(basic) {
                // knight == same cards as progress card
                if(player.pieces.houses == 0 && player.pieces.cities == 0) return 'knight';
            }

            if(options.length && player.pieces.houses != 0) return 'house';

            if(this.houses.find(house => house.player == player) && player.pieces.cities != 0) return 'city';

            if(this.seafaresExpansion) {
                // spot for boat & house available
                if(this.aiGetBuildBoatAndHouseOptions().length) {
                    return 'boat and house';
                }
            }
            // default
            return 'house';
        },
        async aiMoveRobber(bishop = false) {
            if(! this.activePlayer.ai) return;

            let leaderActiveKnightsCount = this.knights
                .filter(knight => knight.player == this.leader && knight.active)
                .reduce((activeKnightsTotal, knight) => activeKnightsTotal + knight.level, 0);

            if(this.seafaresExpansion && this.focusType != 'move robber only' && bishop == false) {

                let movePirateInstead = false;

                // loop leader buildings
                this.buildings
                    .filter(building => building.player == this.leader)
                    .forEach(building => {
                        // robber is on leader
                        if(this.spotTiles(building.tile, building.position).find(spotTile => spotTile == this.robber.tile)) {
                            if(this.leader.pieces.boats != 15) movePirateInstead = true;
                        }
                    })

                // don't move pirate if it is on the leader already
                this.roads
                    .filter(road => road.player == this.leader && road.boat)
                    .forEach(boat => {
                        this.roadTiles(boat.tile, boat.roadSpot).forEach(boatTile => {
                            if(this.boatTile == this.pirate.tile) movePirateInstead = false;
                        });
                    })

                let robberIsOnActivePlayer = false;

                // loop activePlayer buildings
                this.buildings
                .filter(building => building.player == this.activePlayer)
                .forEach(building => {
                    // robber is on activePlayer
                    if(this.spotTiles(building.tile, building.position).find(spotTile => spotTile == this.robber.tile)) {
                        robberIsOnActivePlayer = true;
                    }
                })

                // robber is not on active player
                if(!robberIsOnActivePlayer) {
                    // pirate is on active player
                    this.roads
                        .filter(road => road.player == this.activePlayer && road.boat)
                        .forEach(boat => {
                            this.roadTiles(boat.tile, boat.roadSpot).forEach(boatTile => {
                                if(this.boatTile == this.pirate.tile) movePirateInstead = true;
                            });
                        })
                }

                // if opponents active knight next to robber, don't move pirate
                this.knights
                    .filter(knight => knight.player != this.activePlayer && knight.active == true && knight.activatedThisTurn == false)
                    .forEach(knight => {
                        this.spotTiles(knight.tile, knight.position).forEach(tile => {
                            if(this.robber.tile == tile) movePirateInstead = false;
                        })
                    })

                if(movePirateInstead) return this.aiMovePirate();
            }

            let options = [];

            this.activePlayer.showIncomingHandCards = false;
            this.buildings
                .forEach(building => {
                    this.spotTiles(building.tile, building.position).forEach(tile => {

                        if(this.robber.tile == tile) return;
                        if(tile.group != 'land') return;

                        let target = options.find(obj => obj.tile == tile);
                        let buildingScore = 1;

                        // prefer goldmines
                        let goldmineFound = false;
                        if(tile.type == 'goldmine' && tile.number.dots >= 3) {
                            buildingScore += 1;
                            goldmineFound = true;
                        }

                        // if leader has only 1 grain spot and no active knights
                        if(this.leaderGrainTilesCount == 1 && leaderActiveKnightsCount == 0) {
                            if(tile.type == 'grain' && tile.number.dots >= 2) {
                                console.log('true')
                                buildingScore += 1;
                            }
                        }

                        if(building.type == 'city' && building.disabled == false) buildingScore = 2;

                        if(building.player == this.activePlayer) {
                            // ai was putting robber on itself
                            if(['Spiral', 'Fifth element'].includes(this.aiTactic)) {
                                building.score = -6;
                            } else {
                                building.score = -3;
                            }
                        }

                        if(target) {
                            if(!target.players.includes(building.player)) {
                                target.players.push(building.player);
                                target.buildingScore += buildingScore;
                            }
                        } else {
                            options.push({ tile: tile, players: [building.player], buildingScore: buildingScore, activeKnights: [], inactiveKnights: [], goldmineFound: goldmineFound, });
                        }
                    });
                });

            this.knights
                .forEach(knight => {
                    this.spotTiles(knight.tile, knight.position).forEach(tile => {
                        if(this.robber.tile == tile) return;
                        if(tile.group != 'land') return;
                        let target = options.find(obj => obj.tile == tile);

                        if(target) {
                            if(!target.activeKnights.includes(knight.player) && knight.active && !knight.activatedThisTurn) {
                                target.activeKnights.push(knight.player);
                            }
                            if(!target.activeKnights.includes(knight.player) && !knight.active) {
                                target.inactiveKnights.push(knight.player);
                            }
                        }
                    });
            });

            // filter tiles where player has built
            options = options.filter(obj => obj.players.includes(this.leader));

            options = options.filter(obj => obj.tile != this.robber.tile);


            if(!options.length) options = this.tiles.filter(tile => tile.group == 'land');

            if(this.aiHasProgressCard('bishop') && options.length >= 2) {
                this.moveRobber(options[1].tile, this.activePlayer);
                this.focusModeOff(this.activePlayer)
                return await this.waitForAllAnimations();
            }

            // activePlayer has min 3 grain and can jump robber on leader
            // leader has min 2 cards in hand (steal + chase away)
            if(
                (this.activePlayer.handCards.find(obj => obj.type == 'grain').amount >= 3 || this.aiHasProgressCard('warlord')) &&
                options.filter(obj => obj.activeKnights.includes(this.activePlayer)).length &&
                this.playerHandCardsCount(this.leader) > 1
            ) {
                options = options.filter(obj => obj.activeKnights.includes(this.activePlayer));
            } else {
                options = options.filter(obj => !obj.players.includes(this.activePlayer));
                let optionsWithoutKnights = options.filter(obj => !obj.activeKnights.length);
                if(optionsWithoutKnights.length) {
                    options = optionsWithoutKnights;
                }
            }


            // try to put robber on best tile but also avoid opponent knights
            options.sort((a, b) => b.tile.number.dots * b.buildingScore - a.tile.number.dots * a.buildingScore);
            // if leader has 1 grain tile and no active knights dont care about active/inactive knights
            if((this.leaderGrainTilesCount == 1 && leaderActiveKnightsCount == 0) == false) {
                options.sort((a, b) => a.activeKnights.filter(player => player != this.activePlayer).length - b.activeKnights.filter(player => player != this.activePlayer).length);
                options.sort(a => { if(a.tile.type == 'desert') return 1; });
                options.sort((a, b) => a.inactiveKnights.filter(player => player != this.activePlayer).length - b.inactiveKnights.filter(player => player != this.activePlayer).length);
            }

            if(['Spiral', 'Fifth element'].includes(this.aiTactic)) {
                options.sort((a, b) => b.goldmineFound - a.goldmineFound);
            }

            options = options.filter(obj => obj.tile != this.robber.tile);

            if(options.length == 0) {
                this.tiles
                    .filter(tile => ['land'].includes(tile.group))
                    .forEach(tile => {
                        options.push({ tile: tile, players: [] });
                    });
            }

            this.moveRobber(options[0].tile, this.activePlayer);

            this.focusModeOff(this.activePlayer)

            await this.waitForAllAnimations();
        },
        async aiMoveRobberBasic(willUseKnight = false) {
            if(! this.activePlayer.ai) return;

            if(this.isHumanBeforeAi() == false) return console.log('prevent moveRobber notHumanBefore');

            console.log('ai move robber ' + this.activePlayer.color)

            if(this.seafaresExpansion && this.focusType != 'move robber only' && willUseKnight == false) {

                let movePirateInstead = false;

                // loop leader buildings
                this.buildings
                    .filter(building => building.player == this.leader)
                    .forEach(building => {
                        // robber is on leader
                        if(this.spotTiles(building.tile, building.position).find(spotTile => spotTile == this.robber.tile)) {
                            if(this.leader.pieces.boats != 15) movePirateInstead = true;
                        }
                    })

                // don't move pirate if it is on the leader already
                this.roads
                    .filter(road => road.player == this.leader && road.boat)
                    .forEach(boat => {
                        this.roadTiles(boat.tile, boat.roadSpot).forEach(boatTile => {
                            if(this.boatTile == this.pirate.tile) movePirateInstead = false;
                        });
                    })

                let robberIsOnActivePlayer = false;

                // loop activePlayer buildings
                this.buildings
                .filter(building => building.player == this.activePlayer)
                .forEach(building => {
                    // robber is on activePlayer
                    if(this.spotTiles(building.tile, building.position).find(spotTile => spotTile == this.robber.tile)) {
                        robberIsOnActivePlayer = true;
                    }
                })

                // robber is not on active player
                if(!robberIsOnActivePlayer) {
                    // pirate is on active player
                    this.roads
                        .filter(road => road.player == this.activePlayer && road.boat)
                        .forEach(boat => {
                            this.roadTiles(boat.tile, boat.roadSpot).forEach(boatTile => {
                                if(this.boatTile == this.pirate.tile) movePirateInstead = true;
                            });
                        })
                }

                // if opponents active knight next to robber, don't move pirate
                this.knights
                    .filter(knight => knight.player != this.activePlayer && knight.active == true && knight.activatedThisTurn == false)
                    .forEach(knight => {
                        this.spotTiles(knight.tile, knight.position).forEach(tile => {
                            if(this.robber.tile == tile) movePirateInstead = false;
                        })
                    })

                if(movePirateInstead) return this.aiMovePirate();
            }

            let options = [];

            this.activePlayer.showIncomingHandCards = false;
            this.buildings
                .forEach(building => {
                    this.spotTiles(building.tile, building.position).forEach(tile => {

                        if(this.robber.tile == tile) return;
                        if(tile.group != 'land') return;

                        let target = options.find(obj => obj.tile == tile);
                        let buildingScore = 1;

                        // prefer goldmines
                        let goldmineFound = false;
                        if(tile.type == 'goldmine' && tile.number.dots >= 3) {
                            buildingScore += 1;
                            goldmineFound = true;
                        }

                        if(building.type == 'city' && building.disabled == false) buildingScore = 2;
                        if(building.player == this.leader) buildingScore += 1;
                        if(building.player == this.activePlayer) {
                            // ai was putting robber on itself
                            if(['Spiral', 'Fifth element'].includes(this.aiTactic)) {
                                building.score = -6;
                            } else {
                                building.score = -3;
                            }
                        }

                        if(target) {
                            if(!target.players.includes(building.player)) {
                                target.players.push(building.player);
                                target.buildingScore += buildingScore;
                            }
                        } else {
                            options.push({ tile: tile, players: [building.player], buildingScore: buildingScore, activeKnights: [], inactiveKnights: [], goldmineFound: goldmineFound, });
                        }
                    });
                });

            this.knights
                .forEach(knight => {
                    this.spotTiles(knight.tile, knight.position).forEach(tile => {
                        if(this.robber.tile == tile) return;
                        if(tile.group != 'land') return;
                        let target = options.find(obj => obj.tile == tile);

                        if(target) {
                            if(!target.activeKnights.includes(knight.player) && knight.active && !knight.activatedThisTurn) {
                                target.activeKnights.push(knight.player);
                            }
                            if(!target.activeKnights.includes(knight.player) && !knight.active) {
                                target.inactiveKnights.push(knight.player);
                            }
                        }
                    });
            });

            let gameJustStarted = true;
            this.players.forEach(player => {
                if(player.points > 2) {
                    gameJustStarted = false;
                }
            });

            let robberIsOnLeader = false;

            this.houses
                .filter(house => house.player == this.leader)
                .forEach(house => {
                    if(this.spotTiles(house.tile, house.position).find(houseTile => houseTile == this.robber.tile)) robberIsOnLeader = true;
                })

            if(gameJustStarted && robberIsOnLeader && willUseKnight == false) {
                options = options.filter(obj => !obj.players.includes(this.leader));
            } else {
                options = options.filter(obj => obj.players.includes(this.leader));
            }

            options = options.filter(obj => obj.tile != this.robber.tile);


            if(!options.length) options = this.tiles.filter(tile => tile.group == 'land');

            if(willUseKnight && options.length >= 2) {
                this.moveRobber(options[1].tile, this.activePlayer);
                this.focusModeOff(this.activePlayer)
                return await this.waitForAllAnimations();
            }

            // activePlayer has min 3 grain and can jump robber on leader
            // leader has min 2 cards in hand (steal + chase away)
            if(
                (this.activePlayer.handCards.find(obj => obj.type == 'grain').amount >= 3 || this.aiHasProgressCard('warlord')) &&
                options.filter(obj => obj.activeKnights.includes(this.activePlayer)).length &&
                this.playerHandCardsCount(this.leader) > 1
            ) {
                options = options.filter(obj => obj.activeKnights.includes(this.activePlayer));
            } else {
                options = options.filter(obj => !obj.players.includes(this.activePlayer));
                let optionsWithoutKnights = options.filter(obj => !obj.activeKnights.length);
                if(optionsWithoutKnights.length) {
                    options = optionsWithoutKnights;
                }
            }


            // try to put robber on best tile but also avoid opponent knights
            options.sort((a, b) => b.tile.number.dots * b.buildingScore - a.tile.number.dots * a.buildingScore);
            options.sort((a, b) => a.activeKnights.filter(player => player != this.activePlayer).length - b.activeKnights.filter(player => player != this.activePlayer).length);
            options.sort(a => { if(a.tile.type == 'desert') return 1; });
            options.sort((a, b) => a.inactiveKnights.filter(player => player != this.activePlayer).length - b.inactiveKnights.filter(player => player != this.activePlayer).length);

            if(['Spiral', 'Fifth element'].includes(this.aiTactic)) {
                options.sort((a, b) => b.goldmineFound - a.goldmineFound);
            }

            options = options.filter(obj => obj.tile != this.robber.tile);

            if(options.length == 0) {
                this.tiles
                    .filter(tile => ['land'].includes(tile.group))
                    .forEach(tile => {
                        options.push({ tile: tile, players: [] });
                    });
            }

            this.moveRobber(options[0].tile, this.activePlayer);

            this.focusModeOff(this.activePlayer)

            await this.waitForAllAnimations();
        },
        async aiMovePirate() {
            if(!this.seafaresExpansion) return;

            if(! this.activePlayer.ai) return;

            let options = [];

            this.activePlayer.showIncomingHandCards = false;

            this.roads
                .filter(road => road.boat)
                .forEach(boat => {
                    this.roadTiles(boat.tile, boat.roadSpot)
                        .filter(tile => ['water', 'harbor'].includes(tile.group))
                        .forEach(tile => {
                            if(this.pirate.tile == tile) return;

                            let target = options.find(obj => obj.tile == tile);

                            if(target) {
                                if(!target.players.includes(boat.player)) {
                                    target.players.push(boat.player);
                                }
                            } else {
                                options.push({ tile: tile, players: [boat.player] });
                            }
                    });
            })

            options = options.filter(obj => obj.players.includes(this.leader));

            options = options.filter(obj => obj.tile != this.pirate.tile);

            // sort by boat-ends instead of number of opponents
            options.sort((a, b) => b.players.length - a.players.length);

            options = options.filter(obj => obj.tile != this.pirate.tile);

            // any water tile if options is empty
            if(options.length == 0) {
                this.tiles
                    .filter(tile => ['water', 'harbor'].includes(tile.group))
                    .forEach(tile => {
                        let players = [];

                        this.buildings
                            .filter(building => building.player != this.activePlayer)
                            .forEach(building => {
                                if(this.spotTiles(building.tile, building.position).find(buildingTile => buildingTile == tile)) {
                                    players.push(building.player);
                                }
                        });

                        options.push({ tile: tile, players: players });
                    });

                    options = options.sort((a, b) => b.players.length - a.players.length);
            }

            this.moveRobber(options[0].tile, this.activePlayer);

            this.focusModeOff(this.activePlayer)

            await this.waitForAllAnimations();
        },
        aiChaseAwayRobber() {
            if(this.firstBoatRound) return;

            if(! this.activePlayer.ai) return;

            let activeKnights = this.knights.filter(knight => knight.player == this.activePlayer && knight.active).length;

            if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0 && !this.aiHasProgressCard('warlord') && activeKnights == 1) return;

            let knightFound = false;
            let targetKnight;

            this.knights
                .filter(knight => knight.player == this.activePlayer && knight.active == true && knight.activatedThisTurn == false)
                .forEach(knight => {
                    this.spotTiles(knight.tile, knight.position).forEach(tile => {
                        if(this.robber.tile == tile) {
                            knightFound = true;
                            targetKnight = knight;
                        }
                    })
                })

            if(knightFound == false) return;

            if(knightFound == true) targetKnight.active = false;

            return this.focusModeOn('move robber', this.activePlayer);
        },
        aiChaseAwayPirate() {
            if(!this.seafaresExpansion) return;

            if(this.firstBoatRound) return;

            if(! this.activePlayer.ai) return;

            let activeKnights = this.knights.filter(knight => knight.player == this.activePlayer && knight.active).length;

            if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0 && !this.aiHasProgressCard('warlord') && activeKnights == 1) return;

            let knightFound = false;
            let targetKnight;

            this.knights
                .filter(knight => knight.player == this.activePlayer && knight.active == true && knight.activatedThisTurn == false)
                .forEach(knight => {
                    this.spotTiles(knight.tile, knight.position).forEach(tile => {
                        if(this.pirate.tile == tile) {
                            knightFound = true;
                            targetKnight = knight;
                        }
                    })
                })

            if(knightFound == false) return;

            if(knightFound == true) targetKnight.active = false;

            return this.focusModeOn('move pirate', this.activePlayer);
        },
        async aiMoveRobberBishop() {
            if(! this.activePlayer.ai) return;

            let options = [];

            this.buildings
                .forEach(building => {
                    this.spotTiles(building.tile, building.position).forEach(tile => {
                        if(this.robber.tile == tile) return;
                        if(tile.group != 'land') return;
                        let buildingScore = 1;
                        if(building.type == 'city') buildingScore = 2;
                        if(building.player == this.activePlayer) {
                            // ai was putting robber on itself
                            if(['Spiral', 'Fifth element'].includes(this.aiTactic)) {
                                building.score = -6;
                            } else {
                                building.score = -3;
                            }
                        }

                        let target = options.find(obj => obj.tile == tile);
                        if(target) {
                            if(!target.players.includes(building.player)) {
                                target.players.push(building.player);
                                target.buildingScore += buildingScore;
                            }
                        } else {
                            options.push({ tile: tile, players: [building.player], buildingScore: buildingScore });
                        }
                    });
                });

            // filter tiles where player has built
            options = options.filter(obj => !obj.players.includes(this.activePlayer));
            options = options.filter(obj => obj.players.includes(this.leader));

            // sort by most players on tile
            options.sort((a, b) => b.players.length - a.players.length);
            options.sort((a, b) => b.tile.number.dots * b.buildingScore - a.tile.number.dots * a.buildingScore);

            options = options.filter(obj => obj.tile != this.robber.tile);

            if(options.length == 0) {
                this.tiles
                    .filter(tile => ['land'].includes(tile.group))
                    .forEach(tile => {
                        options.push({ tile: tile, players: [] });
                    });
            }

            // could maybe also check for active knights and try to avoid them
            // could also move to a spot where own knights and then jump the robber from there
            if(options[0].players.length == 1) {
                setTimeout(() => {
                    this.aiMoveRobber(true);
                }, this.randomNumber(250, 250+this.aiActionSpeed*3));
            } else {
                this.moveRobber(options[0].tile, this.activePlayer, true);
            }
            this.focusModeOff(this.activePlayer);

            await this.waitForAllAnimations();
        },
        async aiThrowAwayProgressCard(player) {

            let rankedLabels = ['intrigue', 'commercial harbor', 'engineer', 'saboteur', 'warlord', 'crane', 'trade monopoly', 'diplomat', 'wedding', 'master merchant', 'smith', 'bishop', 'merchant fleet', 'resource monopoly', 'deseter', 'medecine', 'road building', 'mining', 'irrigation', 'inventor', 'alchemist', 'spy', 'merchant'];
            let cardFound = false;

            // if player has 3 merchants or more, delete one of them
            if(player.progressCards.filter(obj => obj.label == 'merchant').length >= 3) {
                rankedLabels = ['intrigue', 'commercial harbor', 'merchant', 'engineer', 'saboteur', 'warlord', 'crane', 'trade monopoly', 'diplomat', 'wedding', 'master merchant', 'smith', 'bishop', 'merchant fleet', 'resource monopoly', 'deseter', 'medecine', 'road building', 'mining', 'irrigation', 'inventor', 'alchemist', 'spy'];
            }

            // can't use mining
            if(this.miningIrrigationCount.mining == 0) {
                rankedLabels = rankedLabels.filter(item => !['intrigue', 'commercial harbor', 'mining'].includes(item));

                rankedLabels.unshift('mining');
                rankedLabels.unshift('commercial harbor');
                rankedLabels.unshift('intrigue');
            }

            // can't use irrigation
            if(this.miningIrrigationCount.irrigation == 0) {
                rankedLabels = rankedLabels.filter(item => !['intrigue', 'commercial harbor', 'irrigation'].includes(item));

                rankedLabels.unshift('irrigation');
                rankedLabels.unshift('commercial harbor');
                rankedLabels.unshift('intrigue');
            }

            rankedLabels.forEach(label => {
                let card = player.progressCards.find(obj => obj.label == label);

                if(card && cardFound == false) {
                    let cardIndex = player.progressCards.findIndex(progressCard => progressCard.label == label);

                    this.playProgressCard(player, card, cardIndex);
                    this.playProgressCard(player, card, cardIndex);
                    cardFound = true;
                }
            });

            return await this.waitForAllAnimations();
        },
        aiNeededCards(player) {
            let neededCards = [];

            let handCardsCopy = JSON.parse(JSON.stringify(player.handCards));

            let needOre;
            let needGrain;
            let checkTypes = [];
            if(this.aiSaveCardsFor(player) == 'activate knight') {
                neededCards.push('grain');
            } else if(this.aiSaveCardsFor(player) == 'knight') {
                needGrain = 1;

                if(this.aiHasProgressCard('irrigation')) needGrain -= this.miningIrrigationCount.irrigation;

                if(needGrain < 0) needGrain = 0;

                // missing knight cards
                checkTypes = [
                    { type: 'ore', needAmount: 1 },
                    { type: 'wool', needAmount: 1 },
                    { type: 'grain', needAmount: 1 },
                ];

                checkTypes.sort((a, b) => this.activePlayerDots.find(obj => obj.type == a.type).dots - this.activePlayerDots.find(obj => obj.type == b.type).dots);

                checkTypes.forEach(obj => {
                    let target = handCardsCopy.find(cardObj => cardObj.type == obj.type);

                    obj.needAmount -= target.amount;

                    if(obj.needAmount < 0) obj.needAmount = 0;

                    for(let i = 0; i < obj.needAmount; i++) {
                        neededCards.push(obj.type);
                        target.amount++;
                    }
                })
            } else if(this.aiSaveCardsFor(player) == 'city') {
                // missing city cards
                needOre = 3;
                needGrain = 2;

                if(this.aiHasProgressCard('mining')) needOre -= this.miningIrrigationCount.mining;
                if(this.aiHasProgressCard('irrigation')) needGrain -= this.miningIrrigationCount.irrigation;
                if(this.aiHasProgressCard('medecine')) {
                    needOre -= 1;
                    needGrain -= 1;
                }

                if(needOre < 0) needOre = 0;
                if(needGrain < 0) needGrain = 0;

                checkTypes = [
                    { type: 'ore', needAmount: needOre },
                    { type: 'grain', needAmount: needGrain },
                ];

                checkTypes.sort((a, b) => this.activePlayerDots.find(obj => obj.type == a.type).dots - this.activePlayerDots.find(obj => obj.type == b.type).dots);

                checkTypes.forEach(obj => {
                    let target = handCardsCopy.find(cardObj => cardObj.type == obj.type);

                    obj.needAmount -= target.amount;

                    if(obj.needAmount < 0) obj.needAmount = 0;

                    for(let i = 0; i < obj.needAmount; i++) {
                        neededCards.push(obj.type)
                        target.amount++;
                    }
                })

                // missing house cards
                needGrain += 1;

                if(this.aiHasProgressCard('irrigation')) needGrain -= this.miningIrrigationCount.irrigation;

                if(needGrain < 0) needGrain = 0;

                checkTypes = [
                    { type: 'lumber', needAmount: 1 },
                    { type: 'brick', needAmount: 1 },
                    { type: 'wool', needAmount: 1 },
                    { type: 'grain', needAmount: needGrain },
                ];

                checkTypes.sort((a, b) => this.activePlayerDots.find(obj => obj.type == a.type).dots - this.activePlayerDots.find(obj => obj.type == b.type).dots);

                checkTypes.forEach(obj => {
                    let target = handCardsCopy.find(cardObj => cardObj.type == obj.type);

                    obj.needAmount -= target.amount;

                    if(obj.needAmount < 0) obj.needAmount = 0;

                    for(let i = 0; i < obj.needAmount; i++) {
                        neededCards.push(obj.type);
                        target.amount++;
                    }
                })

                if(this.aiSaveCardsFor(player) == 'boat and house') {
                    // missing boat cards
                    checkTypes = [
                        { type: 'wool', needAmount: 2 },
                        { type: 'lumber', needAmount: 2 },
                    ];
                } else {
                    // missing road cards
                    checkTypes = [
                        { type: 'lumber', needAmount: 2 },
                        { type: 'brick', needAmount: 2 },
                    ];
                }

                checkTypes.sort((a, b) => this.activePlayerDots.find(obj => obj.type == a.type).dots - this.activePlayerDots.find(obj => obj.type == b.type).dots);

                checkTypes.forEach(obj => {
                    let target = handCardsCopy.find(cardObj => cardObj.type == obj.type);

                    obj.needAmount -= target.amount;

                    if(obj.needAmount < 0) obj.needAmount = 0;

                    for(let i = 0; i < obj.needAmount; i++) {
                        neededCards.push(obj.type)
                        target.amount++;
                    }
                })
            }

            // house, default
            // missing house cards
            needGrain = 1;

            if(this.aiHasProgressCard('irrigation')) needGrain -= this.miningIrrigationCount.irrigation;

            if(needGrain < 0) needGrain = 0;

            checkTypes = [
                { type: 'lumber', needAmount: 1 },
                { type: 'brick', needAmount: 1 },
                { type: 'wool', needAmount: 1 },
                { type: 'grain', needAmount: needGrain },
            ];

            checkTypes.sort((a, b) => this.activePlayerDots.find(obj => obj.type == a.type).dots - this.activePlayerDots.find(obj => obj.type == b.type).dots);

            checkTypes.forEach(obj => {
                let target = handCardsCopy.find(cardObj => cardObj.type == obj.type);

                obj.needAmount -= target.amount;

                if(obj.needAmount < 0) obj.needAmount = 0;

                for(let i = 0; i < obj.needAmount; i++) {
                    neededCards.push(obj.type);
                    target.amount++;
                }
            })

            // missing road cards
            if(player.pieces.roads > 0 && !this.aiHasProgressCard('road building')) {
                checkTypes = [
                    { type: 'lumber', needAmount: 3 },
                    { type: 'brick', needAmount: 3 },
                ];

                checkTypes.sort((a, b) => this.activePlayerDots.find(obj => obj.type == a.type).dots - this.activePlayerDots.find(obj => obj.type == b.type).dots);

                checkTypes.forEach(obj => {
                    let target = handCardsCopy.find(cardObj => cardObj.type == obj.type);

                    obj.needAmount -= target.amount;

                    if(obj.needAmount < 0) obj.needAmount = 0;

                    for(let i = 0; i < obj.needAmount; i++) {
                        neededCards.push(obj.type)
                        target.amount++;
                    }
                })
            }

            if(player.pieces.cities != 0) {
                // missing city cards
                needOre = 3;
                needGrain = 3;

                if(this.aiHasProgressCard('mining')) needOre -= this.miningIrrigationCount.mining;
                if(this.aiHasProgressCard('irrigation')) needGrain -= this.miningIrrigationCount.irrigation;
                if(this.aiHasProgressCard('medecine')) {
                    needOre -= 1;
                    needGrain -= 1;
                }

                if(needOre < 0) needOre = 0;
                if(needGrain < 0) needGrain = 0;

                checkTypes = [
                    { type: 'ore', needAmount: needOre },
                    { type: 'grain', needAmount: needGrain },
                ];

                checkTypes.sort((a, b) => this.activePlayerDots.find(obj => obj.type == a.type).dots - this.activePlayerDots.find(obj => obj.type == b.type).dots);

                checkTypes.forEach(obj => {
                    let target = handCardsCopy.find(cardObj => cardObj.type == obj.type);

                    obj.needAmount -= target.amount;

                    if(obj.needAmount < 0) obj.needAmount = 0;

                    for(let i = 0; i < obj.needAmount; i++) {
                        neededCards.push(obj.type)
                        target.amount++;
                    }
                })
            }

            // default so neededCards has a length
            checkTypes = [
                { type: 'lumber', needAmount: 1 },
                { type: 'brick', needAmount: 1 },
                { type: 'wool', needAmount: 1 },
                { type: 'grain', needAmount: 1 },
                { type: 'ore', needAmount: 1 },
            ];

            checkTypes.sort((a, b) => this.activePlayerDots.find(obj => obj.type == a.type).dots - this.activePlayerDots.find(obj => obj.type == b.type).dots);

            checkTypes.forEach(obj => {
                neededCards.push(obj.type)
            })

            if(neededCards.length == 0) return 'grain';

            return neededCards;
        },
        // shitty code, works pretty well though
        // not shared code so can be fine like this
        async aiSelectAqueductResource(player, goldAmount = 1) {
            await this.chooseAqueductResource(player, this.aiNeededCards(player)[0], goldAmount, false);
            return await this.waitForAllAnimations();

        },
        async aiSelectAqueductResource2(player) {

            // player has 13 cards or more, choose something which can be traded 2:1
            if(this.playerHandCardsCount(player) >= 13) {
                let missingHandCard = this.activePlayer.handCards.filter(obj => !['cloth', 'coin', 'paper'].includes(obj.type)).sort((a, b) => a.amount - b.amount);
                let cheapestTrade = player.handCards.filter(obj => !['cloth', 'coin', 'paper'].includes(obj.type)).sort((a, b) => a.tradeCost - b.tradeCost);

                if(missingHandCard.amount <= 1) {
                    await this.chooseAqueductResource(player, missingHandCard[0].type);
                } else {
                    await this.chooseAqueductResource(player, cheapestTrade[0].type);
                }

                return await this.waitForAllAnimations();
            }

            let tileTypeDots = [
                { type: 'lumber', dots: 0, },
                { type: 'brick', dots: 0, },
                { type: 'wool', dots: 0, },
                { type: 'grain', dots: 0, },
                { type: 'ore', dots: 0, },
                { type: 'goldmine', dots: 0, },
            ];

            // check which one of the missing cards is hardest for player to get
            this.buildings.filter(building => building.player == player).forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {
                    if(tile.type == 'desert' || tile.group != 'land') return;

                    tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.exponentialDots;
                    if(building.type == 'city' && ['brick', 'grain'].includes(tile.type)) {
                        tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.exponentialDots;
                    }
                });
            });

            tileTypeDots.sort((a, b) => a.dots - b.dots);

            if(this.aiSaveCardsFor(player) == 'activate knight') {
                await this.chooseAqueductResource(player, 'grain');

                return await this.waitForAllAnimations();
            } else if(this.aiSaveCardsFor(player) == 'knight') {

                let neededCards = player.handCards.filter(obj => ['wool', 'grain', 'ore'].includes(obj.type));
                neededCards = neededCards.sort((a,b) => a.amount - b.amount);
                neededCards = neededCards.filter(obj => obj.amount == neededCards[0].amount);

                if(neededCards[0].amount == 0) {
                    let hardestToGet = tileTypeDots.filter(obj => neededCards.find(needObj => needObj.type == obj.type));
                    await this.chooseAqueductResource(player, hardestToGet[0].type);
                } else {
                    if(player.handCards.find(obj => obj.type == tileTypeDots[0].type).amount <= player.handCards.find(obj => obj.type == tileTypeDots[1].type).amount) {
                        await this.chooseAqueductResource(player, tileTypeDots[0].type);
                    } else {
                        await this.chooseAqueductResource(player, tileTypeDots[1].type);
                    }
                }

                return await this.waitForAllAnimations();
            } else if(this.aiSaveCardsFor(player) == 'city') {
                let neededCards = player.handCards.filter(obj => ['grain', 'ore'].includes(obj.type));
                neededCards = neededCards.sort((a,b) => a.amount - b.amount);
                neededCards = neededCards.filter(obj => obj.amount == neededCards[0].amount);
                let hardestToGet = tileTypeDots.filter(obj => neededCards.find(needObj => needObj.type == obj.type));
                await this.chooseAqueductResource(player, hardestToGet[0].type);

                return await this.waitForAllAnimations();
            } else if(['house', 'boat and house'].includes(this.aiSaveCardsFor(player))) {
                let neededCards = player.handCards.filter(obj => ['grain', 'lumber', 'brick', 'wool'].includes(obj.type));
                neededCards = neededCards.sort((a,b) => a.amount - b.amount);
                neededCards = neededCards.filter(obj => obj.amount == neededCards[0].amount);

                if(neededCards[0].amount == 0) {
                    let hardestToGet = tileTypeDots.filter(obj => neededCards.find(needObj => needObj.type == obj.type));
                    await this.chooseAqueductResource(player, hardestToGet[0].type);
                } else {
                    if(player.handCards.find(obj => obj.type == tileTypeDots[0].type).amount <= player.handCards.find(obj => obj.type == tileTypeDots[1].type).amount) {
                        await this.chooseAqueductResource(player, tileTypeDots[0].type);
                    } else {
                        await this.chooseAqueductResource(player, tileTypeDots[1].type);
                    }
                }
                return await this.waitForAllAnimations();
            } else {
                if(player.handCards.find(obj => obj.type == tileTypeDots[0].type).amount <= player.handCards.find(obj => obj.type == tileTypeDots[1].type).amount) {
                    await this.chooseAqueductResource(player, tileTypeDots[0].type);
                } else {
                    await this.chooseAqueductResource(player, tileTypeDots[1].type);
                }
                return await this.waitForAllAnimations();
            }

        },
        hasProgressCard(cardLabel, player = this.activePlayer) {
            if(player.progressCards.find(obj => obj.label == cardLabel)) {
                return true;
            } else {
                return false;
            }
        },
        aiHasProgressCard(cardLabel, player = this.activePlayer) {
            if(player.progressCards.find(obj => obj.label == cardLabel)) {
                return true;
            } else {
                return false;
            }
        },
        async aiPlaySmith() {
            if(! this.activePlayer.ai) return;

            if(!this.knights.find(knight => knight.player == this.activePlayer)) return;

            if(this.aiHasProgressCard('smith') == false) return;

            this.aiPlayProgressCard('smith');

            await this.waitForAllAnimations();
        },
        roadIsUseless(road) {
            let useless = false;
            let blockedSpots = 0;
            let canBuild = false;
            this.roadSpots(road.tile, road.roadSpot).forEach(spot => {
                if(!spot) return;
                // own content
                if(this.spotRoads(spot.tile, spot.position).filter(spotRoad => spotRoad && spotRoad.player == road.player).length > 1 || spot.content.player == road.player) return;

                this.spotSpots(spot.tile, spot.position).forEach(spot2 => {
                    if(!spot2) return;
                    // own content
                    if(this.spotRoads(spot2.tile, spot2.position).filter(spotRoad => spotRoad && spotRoad.player == road.player).length > 1 || spot2.content.player == road.player) return;
                    if(!spot2.buildingDistance) blockedSpots++;
                });
                if(spot.content.type != undefined) {
                    if(spot.content.player != road.player) useless = true;
                } else {
                    if(this.spotRoads(spot.tile, spot.position).filter(spotRoad => spotRoad && spotRoad.player != road.player).length == 2) useless = true;
                }

                if(this.buildingDistance(spot.tile, spot.position)) canBuild = true;
            })

            if(blockedSpots == 2) useless = true;

            if(canBuild == true) useless = false;

            return useless;
        },
        aiGetDiplomatOptions() {

            let options = [];

            this.players.forEach(player => {
                    this.roads.forEach(road => {
                        this.roadSpots(road.tile, road.roadSpot).forEach(spot => {
                            if(!spot) return;

                            if(spot.content.type == undefined || spot.content.player.color != player.color) {
                                let playerRoads = this.spotRoads(spot.tile, spot.position).filter(spotRoad => spotRoad && spotRoad.player.color == player.color);

                                if(playerRoads.length == 1) {
                                    options.push(playerRoads[0]);
                                }
                            }
                        })
                    });
                });

            if(options.length == 0) return false;

            let opponentRoads = options.filter(option => option.player != this.activePlayer);

            let ownRoads = options.filter(option => option.player == this.activePlayer && option.boat == false);

            ownRoads.forEach(road => {
                road.score = 0;
                if(this.roadIsUseless(road)) road.score += 999;
            });

            ownRoads = ownRoads.filter(road => road.score > 500);


            opponentRoads.forEach(opponentRoad => {
                opponentRoad.score = 0;
                this.roadSpots(opponentRoad.tile, opponentRoad.roadSpot).forEach(spot => {
                    if(this.buildingDistance(spot.tile, spot.position)) opponentRoad.score += 1;
                });

                if(this.roadIsUseless(opponentRoad)) opponentRoad.score -= 999;

                // only play diplomat against leader or player 4 points from winning
                // or if blocking you house-spot
                if(opponentRoad.player == this.leader || opponentRoad.player.points >= this.playToPoints-4) opponentRoad.score += 50;
            })

            opponentRoads.sort((a, b) => b.player.potential - a.player.potential);

            // road potentially blocking your house-spot
            this.roads
                .filter(road => road.player == this.activePlayer)
                .forEach(road => {
                    this.roadSpots(road.tile, road.roadSpot).forEach(spot => {
                        if(this.buildingDistance(spot.tile, spot.position)) {
                            this.spotSpots(spot.tile, spot.position).forEach(spot2 => {
                                let target = this.spotRoads(spot2.tile, spot2.position).find(road2 => road2 && opponentRoads.includes(road2));
                                if(target) target.score += 100;
                            });
                        }
                    });
                });

            // road is blocking you and there is house-spot available
            opponentRoads.forEach(opponentRoad => {
                if(opponentRoad.score > 0) {
                    this.roadSpots(opponentRoad.tile, opponentRoad.roadSpot).forEach(spot => {
                        if(this.spotRoads(spot.tile, spot.position).find(road => road && road.player == this.activePlayer)) opponentRoad.score += 300;
                    });
                }

                // good to pick roads which is part of the longest road
                if(this.longestRoad.length) {
                    if(this.longestRoad[0].player == this.leader || this.longestRoad[0].player.points >= this.playToPoints-6) {
                        if(this.longestRoad.find(longestRoad => longestRoad.key == opponentRoad.key)) opponentRoad.score += 50;
                    }
                }
            });

            opponentRoads.sort((a, b) => b.score - a.score);

            if(!opponentRoads.length) return false;

            if(opponentRoads[0].score < 50) return false;

            if(ownRoads.length && this.leader.points <= this.playToPoints-7) {
                return ownRoads;
            } else {
                return opponentRoads;
            }
        },
        async aiPlayDiplomat() {
            if(! this.activePlayer.ai) return;

            if(this.aiHasProgressCard('diplomat') == false) return;

            if(this.aiGetDiplomatOptions()) {
                this.aiPlayProgressCard('diplomat');

                await this.waitForAllAnimations();
            }
        },
        async aiPlaySaboteur() {
            if(! this.activePlayer.ai) return;

            if(this.aiHasProgressCard('saboteur') == false) return;

            let playersCopy = JSON.parse(JSON.stringify(this.players));

            let opponentCities = this.cities.filter(city => city.player != this.activePlayer && city.metropolis == false && city.disabled == false);

            if(opponentCities.length == 0) return;

            opponentCities.sort((a, b) => b.player.potential - a.player.potential);

            opponentCities = opponentCities.filter(obj => obj.player == opponentCities[0].player);

            opponentCities.sort((a, b) => this.spotDots(b.tile, b.position) - this.spotDots(a.tile, a.position));

            if(opponentCities[0].player.points < this.leader.points) return;

            this.aiPlayProgressCard('saboteur');

            setTimeout(() => {
                this.clickHighlighted(opponentCities[0].tile, opponentCities[0].position, this.activePlayer);
            }, this.randomNumber(250, 250+this.aiActionSpeed*3));

            await this.waitForAllAnimations();

        },
        async aiPlaySpy() {
            if(! this.activePlayer.ai) return;

            if(this.aiHasProgressCard('spy') == false) return;

            let opponents = this.players
                .filter(player => player != this.activePlayer && player.progressCards.length > 0);

            if(opponents.length == 0) return;

            opponents.sort((a, b) => b.points - a.points);

            opponents = opponents
                .filter(opponent => opponent.points == opponents[0].points)
                .sort((a, b) => b.potential - a.potential)
                .sort((a, b) => b.progressCards.length - a.progressCards.length)

            this.log(':activePlayer played spy against :playerName', opponents[0]);

            let cardIndex = this.activePlayer.progressCards.findIndex(progressCard => progressCard.label == 'spy');
            this.playProgressCard(this.activePlayer, 'spy', cardIndex);
            this.playProgressCard(this.activePlayer, 'spy', cardIndex);

            this.showModal('steal a progress card', this.activePlayer, opponents[0]);

            this.focusModeOn('steal progress card', this.activePlayer);

            await this.waitForAllAnimations();
        },
        async aiStealProgressCard() {
            let defaultOrder = [
                { score: 1000,  label: 'spy', },
                { score: 220,   label: 'inventor', },
                { score: 210,   label: 'alchemist', },
                { score: 200,   label: 'merchant', },
                { score: 190,   label: 'road building', },
                { score: 180,   label: 'medecine', },
                { score: 170,   label: 'resource monopoly', },
                { score: 160,   label: 'irrigation', },
                { score: 150,   label: 'mining', },
                { score: 140,   label: 'deseter', },
                { score: 130,   label: 'merchant fleet', },
                { score: 120,   label: 'bishop', },
                { score: 110,   label: 'master merchant', },
                { score: 100,   label: 'trade monopoly', },
                { score: 90,    label: 'crane', },
                { score: 80,    label: 'warlord', },
                { score: 70,    label: 'diplomat', },
                { score: 60,    label: 'saboteur', },
                { score: 50,    label: 'engineer', },
                { score: 40,    label: 'wedding', },
                { score: 30,    label: 'smith', },
                { score: 20,    label: 'commercial harbor', },
                { score: 10,    label: 'intrigue', },
            ];

            let playersCopy = JSON.parse(JSON.stringify(this.players));

            // leader close to winning
            if(this.lateGame) {
                // inventor not as good anymore
                defaultOrder.find(obj => obj.label == 'inventor').score = 105;
                defaultOrder.find(obj => obj.label == 'deseter').score = 65;

                // if player has one merchant, steal it
                if(this.modalPlayer.progressCards.filter(obj => obj.label == 'merchant').length == 1) {
                    defaultOrder.find(obj => obj.label == 'merchant').score = 225;
                }
            }

            if(this.miningIrrigationCount.mining >= 6) defaultOrder.find(obj => obj.label == 'mining').score = 185;

            if(this.miningIrrigationCount.irrigation >= 6) defaultOrder.find(obj => obj.label == 'irrigation').score = 185;

            if(this.activePlayer.pieces.cities == 0) defaultOrder.find(obj => obj.label == 'medecine').score = 25;

            if(this.activePlayer.pieces.roads < 2) defaultOrder.find(obj => obj.label == 'road building').score = 55;

            if(this.modalPlayer.points > this.activePlayer.points) defaultOrder.find(obj => obj.label == 'master merchant').score = 145;

            defaultOrder.sort((a, b) => b.score - a.score);


            // animate stolen card before stealing it
            let cardFound = false;
            defaultOrder.forEach(obj => {
                let card = this.modalPlayer.progressCards.find(progressCard => progressCard.label == obj.label);

                if(card && cardFound == false) {
                    let cardIndex = this.modalPlayer.progressCards.findIndex(progressCard => progressCard.label == obj.label);
                    this.spyIndex = cardIndex;
                    cardFound = true;
                }
            });

            setTimeout(() => {
                let cardFound = false;
                defaultOrder.forEach(obj => {
                    let card = this.modalPlayer.progressCards.find(progressCard => progressCard.label == obj.label);

                    if(card && cardFound == false) {
                        let cardIndex = this.modalPlayer.progressCards.findIndex(progressCard => progressCard.label == obj.label);

                        // ai steal progress card
                        this.playProgressCard(this.modalPlayer, card, cardIndex);
                        this.playProgressCard(this.modalPlayer, card, cardIndex);
                        cardFound = true;
                        this.spyIndex = null;
                    }
                });
                this.focusModeOff(this.activePlayer);
            }, 1200);

            await this.waitForAllAnimations();
        },
        async aiPlayCommercialHarbor() {
            // ai don't play commercial harbor if multiplayer game
            if(this.joinedPlayers != null) return;

            if(! this.activePlayer.ai) return;

            if(!this.aiHasProgressCard('commercial harbor')) return;

            if(this.resourceCount(this.activePlayer) <= 2) return;

            this.aiPlayProgressCard('commercial harbor');
            await this.waitForAllAnimations();
        },
        async aiPlayRoadBuilding(basic = false) {
            if(! this.activePlayer.ai) return;

            if(!this.canPay(this.activePlayer, [ { type: 'lumber', amount: 1 }, { type: 'brick', amount: 1 }, { type: 'wool', amount: 1 }, { type: 'grain', amount: 1 } ])) {
                await this.aiTradeForHouse();
                await this.waitForAllAnimations();
            }

            if(this.canPay(this.activePlayer, [ { type: 'lumber', amount: 1 }, { type: 'brick', amount: 1 }, { type: 'wool', amount: 1 }, { type: 'grain', amount: 1 } ])) {
                this.log(':playerName played road building', this.activePlayer);
                let cardIndex = this.activePlayer.progressCards.findIndex(obj => obj.label == 'road building');
                this.activePlayer.progressCards.splice(cardIndex, 1);
                this.progressCardsPaper.push('road building');

                return this.aiBuildTwoRoadsAndHouse(true);
            }

            let options = this.aiGetBuildRoadOptions();
            if(!options.length) return;
            if(basic) {
                this.aiPlayProgressCard('road building', null, true);
            } else {
                this.aiPlayProgressCard('road building');
            }
            this.focusModeOff(this.activePlayer);

            let risky = false;
            let forFree = true;
            let haveLongest = false;

            if(this.longestRoad.length) {
                if(this.longestRoad[0].player == this.activePlayer) {
                    haveLongest = true;
                }
            }

            // builds 2 roads and house
            if(this.canPay(this.activePlayer, [ { type: 'lumber', amount: 1 }, { type: 'brick', amount: 1 }, { type: 'grain', amount: 1 }, { type: 'wool', amount: 1 } ]) && await this.aiBuildTwoRoadsAndHouse(true) != false) {

            // builds 2 roads and house
            } else if(await this.aiTradeBankForHouse(true) && await this.aiBuildTwoRoadsAndHouse(true) != false) {

            } else {

                let animationSpeed1 = this.randomNumber(500, this.aiActionSpeed*3);

                await setTimeout(async () => {
                    if(haveLongest) {
                        await this.aiBuildLongestRoad(forFree);
                    } else {
                        if(this.seafaresExpansion && this.activePlayer.pieces.roads == 0) {
                            await this.aiBuildBoat(risky, forFree, true);
                        } else {
                            await this.aiBuildRoad(risky, forFree, true);
                        }
                    }
                    await this.waitForAllAnimations();
                }, animationSpeed1);

                // await this.waitForAllAnimations();

                await setTimeout(async () => {
                    if(haveLongest) {
                        await this.aiBuildLongestRoad(forFree);
                    } else {
                        if(this.seafaresExpansion && this.activePlayer.pieces.roads == 0) {
                            await this.aiBuildBoat(risky, forFree, true);
                        } else {
                            await this.aiBuildRoad(risky, forFree, true);
                        }
                    }
                    await this.waitForAllAnimations();
                }, this.randomNumber(500, this.aiActionSpeed*3)+animationSpeed1);
            }

            await this.waitForAllAnimations();
        },
        async aiPlayWedding() {
            if(! this.activePlayer.ai) return;

            if(this.aiHasProgressCard('wedding') == false) return;

            let opponentsWithMorePoints = this.players
                .filter(player => player != this.activePlayer && player.points > this.activePlayer.points);

            if(!opponentsWithMorePoints.length) return;

            let averageHandCardsCount = opponentsWithMorePoints.reduce((total, player) => total + this.playerHandCardsCount(player), 0) / opponentsWithMorePoints.length;

            if(averageHandCardsCount >= 2) {
                this.aiPlayProgressCard('wedding');
            }

            await this.waitForAllAnimations();
        },
        async aiPlayMasterMerchant() {
            if(! this.activePlayer.ai) return;

            if(this.aiHasProgressCard('master merchant') == false) return;

            let playersCopy = JSON.parse(JSON.stringify(this.players));
            let opponents = playersCopy
                .filter(player => player != this.activePlayer && player.points > this.activePlayer.points)
                .sort((a, b) => b.potential - a.potential);

            opponents = opponents
                .filter(opponent => opponent.points >= opponents[0].points && this.playerHandCardsCount(opponent) >= 2)
                .sort((a, b) => this.playerHandCardsCount(b) - this.playerHandCardsCount(a))
                .sort((a, b) => b.potential - a.potential)

            if(opponents.length == 0) return;

            this.log(':activePlayer played master merchant against :playerName', opponents[0]);

            let cardIndex = this.activePlayer.progressCards.findIndex(progressCard => progressCard.label == 'master merchant');
            this.playProgressCard(this.activePlayer, 'master merchant', cardIndex);
            this.playProgressCard(this.activePlayer, 'master merchant', cardIndex);

            this.showModal('select 2 hand cards', this.activePlayer, opponents[0]);

            opponents[0].showOutgoingHandCards = false;

            this.focusModeOn('steal 2 hand cards', this.activePlayer);

            await this.waitForAllAnimations();
        },
        async aiPlayDeseter() {
            if(! this.activePlayer.ai) return;

            if(this.aiHasProgressCard('deseter') == false) return;

            let knightsToSteal = [];

            this.knights
                .filter(knight => knight.player != this.activePlayer)
                .forEach(knight => {
                    let target = knightsToSteal.find(obj => obj.player == knight.player);
                    if(target) {
                        if(knight.level < target.knight.level) target.knight = knight;
                    } else {
                        knightsToSteal.push({ player: knight.player, knight: knight });
                    }
                })

            knightsToSteal.sort((a, b) => b.knight.level - a.knight.level);

            knightsToSteal = knightsToSteal.filter(obj => obj.knight.level == knightsToSteal[0].knight.level);

            knightsToSteal.sort((a, b) => b.player.potential - a.player.potential);

            if(this.firstBoatRound) knightsToSteal.sort((a, b) => b.knight.active - a.knight.active);

            if(knightsToSteal.length == 0) return;

            // no spot to place knight
            if(this.aiGetBuildKnightSpots() == false) {
                // if first boat round, steal anyway, otherwise, wait for road to be built
                if(!this.firstBoatRound && this.activePlayer.progressCards.length <= 3) return;
                // would place it on house-spot
                if(this.aiHasAvailableHouseSpot() == true) return;
            }

            this.aiPlayProgressCard('deseter');

            this.log(':activePlayer played deseter against :playerName', knightsToSteal[0].player);

            this.playerPointsOrCardsClicked(knightsToSteal[0].player);

            await this.waitForAllAnimations();
        },
        async aiPlayResourceMonopoly(goForMissingCard = false) {
            if(! this.activePlayer.ai) return;

            if(this.aiHasProgressCard('resource monopoly') == false) return;

            let playersShouldHave = [
                { type: 'grain', amount: 0 },
                { type: 'ore', amount: 0 },
                { type: 'wool', amount: 0 },
                { type: 'brick', amount: 0 },
                { type: 'lumber', amount: 0 },
            ];

            let reversedStatuses = JSON.parse(JSON.stringify(this.statuses)).reverse();
            let activePlayerIndex = this.players.findIndex(player => player == this.activePlayer);
            let targetOpponent;

            if(activePlayerIndex+1 == this.players.length) {
                targetOpponent = this.players[0];
            } else {
                targetOpponent = this.players[activePlayerIndex+1];
            }

            reversedStatuses.forEach((obj, index) => {
                if(obj.roll != null) {
                    // check everybody on current roll
                    if(index == 0) {
                        this.buildings.filter(building => building.player != this.activePlayer).forEach(building => {
                            this.spotTiles(building.tile, building.position)
                                .filter(tile => tile.type != 'goldmine')
                                .forEach(tile => {
                                if(tile.number.value == obj.roll) {
                                    playersShouldHave.find(obj => obj.type == tile.type).amount++;

                                    if(building.type == 'city' && !building.disabled && ['brick', 'grain'].includes(tile.type)) playersShouldHave.find(obj => obj.type == tile.type).amount++;
                                }
                            })
                        })
                    // check player after your turn for rolls after that player ended turn
                    } else if(index < this.players.length-1) {
                        this.buildings.filter(building => building.player == targetOpponent).forEach(building => {
                            this.spotTiles(building.tile, building.position)
                                .filter(tile => tile.type != 'goldmine')
                                .forEach(tile => {
                                if(tile.number.value == obj.roll) {
                                    playersShouldHave.find(obj => obj.type == tile.type).amount++;

                                    if(building.type == 'city' && !building.disabled && ['brick', 'grain'].includes(tile.type)) playersShouldHave.find(obj => obj.type == tile.type).amount++;
                                }
                            })
                        })
                    }
                }
            });

            playersShouldHave = [
                { type: 'grain', amount: 0 },
                { type: 'ore', amount: 0 },
                { type: 'wool', amount: 0 },
                { type: 'brick', amount: 0 },
                { type: 'lumber', amount: 0 },
            ];

            // if active player doesn't have paper on third, add picked resources from players-1 turns back
            if(this.activePlayer.progress.find(obj => obj.type == 'paper').level < 3) {
                playersShouldHave.forEach(shouldHave => {
                    this.pickedAqueductCards.forEach(pickedCards => {
                        shouldHave.amount += pickedCards.find(obj => obj.type == shouldHave.type).amount;
                    });
                })
            }

            playersShouldHave.sort((a, b) => b.amount - a.amount);

            let handCardsAverage = this.players.filter(player => player != this.activePlayer).reduce((total, player) => total + this.playerHandCardsCount(player), 0) / (this.players.length-1);

            let missingHandCard = this.activePlayer.handCards.filter(obj => !['cloth', 'coin', 'paper'].includes(obj.type)).sort((a, b) => a.amount - b.amount);

            if(['Forest escape'].includes(this.aiTactic)) {
                let tileCounts = [
                    { type: 'lumber', count: this.tiles.filter(tile => !tile.hidden && tile.type == 'lumber').length },
                    { type: 'brick', count: this.tiles.filter(tile => !tile.hidden && tile.type == 'brick').length },
                    { type: 'wool', count: this.tiles.filter(tile => !tile.hidden && tile.type == 'wool').length },
                    { type: 'grain', count: this.tiles.filter(tile => !tile.hidden && tile.type == 'grain').length },
                    { type: 'ore', count: this.tiles.filter(tile => !tile.hidden && tile.type == 'ore').length },
                ];

                tileCounts.sort((a, b) => b.count - a.count);

                return this.aiPlayProgressCard('resource monopoly', tileCounts[0].type);
            }

            if(goForMissingCard == true) {
                return this.aiPlayProgressCard('resource monopoly', missingHandCard[0].type);
            }

            // pick what you need
            if(handCardsAverage > 7 || !this.cities.find(city => city.player == this.activePlayer)) {
                if(this.aiSaveCardsFor(this.activePlayer) == 'city' || !this.cities.find(city => city.player == this.activePlayer)) {
                    if(this.activePlayer.handCards.find(obj => obj.type == 'ore').amount > this.activePlayer.handCards.find(obj => obj.type == 'grain').amount) {
                        return this.aiPlayProgressCard('resource monopoly', 'grain');
                    } else {
                        return this.aiPlayProgressCard('resource monopoly', 'ore');
                    }
                } else {
                    return this.aiPlayProgressCard('resource monopoly', missingHandCard[0].type);
                }
            // pick what opponents have
            } else {
                if(playersShouldHave[0].amount > 3) {
                    return this.aiPlayProgressCard('resource monopoly', playersShouldHave[0].type);
                }
            }

            if(this.firstBoatRound) {
                playersShouldHave = playersShouldHave.filter(obj => ['ore', 'wool', 'grain'].includes(obj.type));
                return this.aiPlayProgressCard('resource monopoly', playersShouldHave[0].type);
            }

            return await this.waitForAllAnimations();
        },
        async aiPlayMonopoly(goForMissingCard = false) {
            if(! this.activePlayer.ai) return;

            if(this.aiHasProgressCard('monopoly') == false) return;

            let playersShouldHave = [
                { type: 'grain', amount: 0 },
                { type: 'ore', amount: 0 },
                { type: 'wool', amount: 0 },
                { type: 'brick', amount: 0 },
                { type: 'lumber', amount: 0 },
            ];

            let reversedStatuses = JSON.parse(JSON.stringify(this.statuses)).reverse();
            let activePlayerIndex = this.players.findIndex(player => player == this.activePlayer);
            let targetOpponent;

            if(activePlayerIndex+1 == this.players.length) {
                targetOpponent = this.players[0];
            } else {
                targetOpponent = this.players[activePlayerIndex+1];
            }

            reversedStatuses.forEach((obj, index) => {
                if(obj.roll != null) {
                    // check everybody on current roll
                    if(index == 0) {
                        this.buildings.filter(building => building.player != this.activePlayer).forEach(building => {
                            this.spotTiles(building.tile, building.position)
                                .filter(tile => tile.type != 'goldmine')
                                .forEach(tile => {
                                if(tile.number.value == obj.roll) {
                                    playersShouldHave.find(obj => obj.type == tile.type).amount++;

                                    if(building.type == 'city' && !building.disabled && ['brick', 'grain'].includes(tile.type)) playersShouldHave.find(obj => obj.type == tile.type).amount++;
                                }
                            })
                        })
                    // check player after your turn for rolls after that player ended turn
                    } else if(index < this.players.length-1) {
                        this.buildings.filter(building => building.player == targetOpponent).forEach(building => {
                            this.spotTiles(building.tile, building.position)
                                .filter(tile => tile.type != 'goldmine')
                                .forEach(tile => {
                                if(tile.number.value == obj.roll) {
                                    playersShouldHave.find(obj => obj.type == tile.type).amount++;

                                    if(building.type == 'city' && !building.disabled && ['brick', 'grain'].includes(tile.type)) playersShouldHave.find(obj => obj.type == tile.type).amount++;
                                }
                            })
                        })
                    }
                }
            });

            playersShouldHave = [
                { type: 'grain', amount: 0 },
                { type: 'ore', amount: 0 },
                { type: 'wool', amount: 0 },
                { type: 'brick', amount: 0 },
                { type: 'lumber', amount: 0 },
            ];

            // if active player doesn't have paper on third, add picked resources from players-1 turns back
            if(this.activePlayer.progress.find(obj => obj.type == 'paper').level < 3) {
                playersShouldHave.forEach(shouldHave => {
                    this.pickedAqueductCards.forEach(pickedCards => {
                        shouldHave.amount += pickedCards.find(obj => obj.type == shouldHave.type).amount;
                    });
                })
            }

            playersShouldHave.sort((a, b) => b.amount - a.amount);

            let handCardsAverage = this.players.filter(player => player != this.activePlayer).reduce((total, player) => total + this.playerHandCardsCount(player), 0) / (this.players.length-1);

            // basic, don't play monopoly if opponents dont have more than 7 cards on average
            if(handCardsAverage < 7) return;

            let missingHandCard = this.activePlayer.handCards.filter(obj => !['cloth', 'coin', 'paper'].includes(obj.type)).sort((a, b) => a.amount - b.amount);

            if(['Forest escape'].includes(this.aiTactic)) {
                let tileCounts = [
                    { type: 'lumber', count: this.tiles.filter(tile => !tile.hidden && tile.type == 'lumber').length },
                    { type: 'brick', count: this.tiles.filter(tile => !tile.hidden && tile.type == 'brick').length },
                    { type: 'wool', count: this.tiles.filter(tile => !tile.hidden && tile.type == 'wool').length },
                    { type: 'grain', count: this.tiles.filter(tile => !tile.hidden && tile.type == 'grain').length },
                    { type: 'ore', count: this.tiles.filter(tile => !tile.hidden && tile.type == 'ore').length },
                ];

                tileCounts.sort((a, b) => b.count - a.count);

                return this.aiPlayProgressCard('monopoly', tileCounts[0].type, true);
            }

            if(goForMissingCard == true) {
                return this.aiPlayProgressCard('monopoly', missingHandCard[0].type, true);
            }

            // pick what you need
            if(handCardsAverage > 7 || !this.cities.find(city => city.player == this.activePlayer)) {
                if(this.aiSaveCardsFor(this.activePlayer) == 'city' || !this.cities.find(city => city.player == this.activePlayer)) {
                    if(this.activePlayer.handCards.find(obj => obj.type == 'ore').amount > this.activePlayer.handCards.find(obj => obj.type == 'grain').amount) {
                        return this.aiPlayProgressCard('monopoly', 'grain', true);
                    } else {
                        return this.aiPlayProgressCard('monopoly', 'ore', true);
                    }
                } else {
                    return this.aiPlayProgressCard('monopoly', missingHandCard[0].type, true);
                }
            // pick what opponents have
            } else {
                if(playersShouldHave[0].amount > 3) {
                    return this.aiPlayProgressCard('monopoly', playersShouldHave[0].type, true);
                }
            }

            if(this.firstBoatRound) {
                playersShouldHave = playersShouldHave.filter(obj => ['ore', 'wool', 'grain'].includes(obj.type));
                return this.aiPlayProgressCard('monopoly', playersShouldHave[0].type, true);
            }

            return await this.waitForAllAnimations();
        },
        async aiPlayTradeMonopoly(goForMissingUpgrade = false) {
            if(! this.activePlayer.ai) return;

            if(this.aiHasProgressCard('trade monopoly') == false) return;
            if(this.cities.find(city => city.player == this.activePlayer) == false) return;

            let playersShouldHave = [
                { type: 'paper', amount: 0 },
                { type: 'cloth', amount: 0 },
                { type: 'coin', amount: 0 },
            ];

            let reversedStatuses = JSON.parse(JSON.stringify(this.statuses)).reverse();
            let activePlayerIndex = this.players.findIndex(player => player == this.activePlayer);
            let targetOpponent;

            if(activePlayerIndex+1 == this.players.length) {
                targetOpponent = this.players[0];
            } else {
                targetOpponent = this.players[activePlayerIndex+1];
            }

            reversedStatuses.forEach((obj, index) => {
                if(obj.roll != null) {
                    // check everybody on current roll
                    if(index == 0) {
                        this.buildings.filter(building => building.player != this.activePlayer).forEach(building => {
                            this.spotTiles(building.tile, building.position)
                                .filter(tile => tile.type != 'goldmine')
                                .forEach(tile => {
                                if(tile.number.value == obj.roll) {
                                    if(building.type == 'city' && !building.disabled) {
                                        if(tile.type == 'wool') {
                                            playersShouldHave.find(obj => obj.type == 'cloth').amount++;
                                        } else if(tile.type == 'ore') {
                                            playersShouldHave.find(obj => obj.type == 'coin').amount++;
                                        } else if(tile.type == 'lumber') {
                                            playersShouldHave.find(obj => obj.type == 'paper').amount++;
                                        }
                                    }
                                }
                            })
                        })
                    // check player after your turn for rolls after that player ended turn
                    } else if(index < this.players.length-1) {
                        this.buildings.filter(building => building.player == targetOpponent).forEach(building => {
                            this.spotTiles(building.tile, building.position).forEach(tile => {
                                if(tile.number.value == obj.roll) {
                                    if(building.type == 'city' && !building.disabled) {
                                        if(tile.type == 'wool') {
                                            playersShouldHave.find(obj => obj.type == 'cloth').amount++;
                                        } else if(tile.type == 'ore') {
                                            playersShouldHave.find(obj => obj.type == 'coin').amount++;
                                        } else if(tile.type == 'lumber') {
                                            playersShouldHave.find(obj => obj.type == 'paper').amount++;
                                        }
                                    }
                                }
                            })
                        })
                    }
                }
            });

            // higher prio if fighting for metropolis
            this.activePlayer.fightFor.forEach(type => {
                let target = playersShouldHave.find(obj => obj.type == type);

                if(target) target.amount += 3;
            })

            playersShouldHave.sort((a, b) => b.amount - a.amount);

            let handCardsAverage = this.players.filter(player => player != this.activePlayer).reduce((total, player) => total + this.playerHandCardsCount(player), 0) / (this.players.length-1);

            let missingUpgrade = this.activePlayer.progress.filter(progress => progress.level == 0);

            if(playersShouldHave.length == 0) return;

            if(playersShouldHave[0].amount == 0) return;

            if(['Forest escape'].includes(this.aiTactic)) {
                let tileCounts = [
                    { type: 'paper', count: this.tiles.filter(tile => !tile.hidden && tile.type == 'lumber').length },
                    { type: 'cloth', count: this.tiles.filter(tile => !tile.hidden && tile.type == 'wool').length },
                    { type: 'coin', count: this.tiles.filter(tile => !tile.hidden && tile.type == 'ore').length },
                ];

                tileCounts.sort((a, b) => b.count - a.count);

                return this.aiPlayProgressCard('trade monopoly', tileCounts[0].type);
            }

            if(goForMissingUpgrade == true) {
                this.aiPlayProgressCard('trade monopoly', missingUpgrade[0].type);
            } else {
                this.aiPlayProgressCard('trade monopoly', playersShouldHave[0].type);
            }

            await this.waitForAllAnimations();
        },
        async aiPlayMerchantFleet() {
            if(! this.activePlayer.ai) return;

            if(this.aiHasProgressCard('merchant fleet') == false) return;

            if(['Forest escape'].includes(this.aiTactic)) {
                if(!this.lateGame) return;
            }

            let defaultOrder = [
                { type: 'wool', order: 0, },
                { type: 'brick', order: 1, },
                { type: 'lumber', order: 2, },
                { type: 'grain', order: 3, },
                { type: 'ore', order: 4, },
                { type: 'coin', order: 5, },
                { type: 'paper', order: 6, },
                { type: 'cloth', order: 7, },
            ];

            let handCardsCopy = JSON.parse(JSON.stringify(this.activePlayer.handCards));

            let options = handCardsCopy
                .filter(obj => obj.tradeCost > 3)
                .sort((a, b) => defaultOrder.find(obj => obj.type == a.type).order - defaultOrder.find(obj => obj.type == b.type).order)
                .sort((a, b) => b.amount - a.amount)

            // options.forEach(option => {
            //     console.log(option.type);
            // })

            if(options.length == 0) return;

            // maybe change 4 to 6?
            let extra = 0;
            if(['ore', 'grain', 'wool'].includes(options[0].type)) extra = 1;
            // if player has knight on first boat round, don't waste merchant fleet
            if(this.knights.find(knight => knight.player == this.activePlayer)) extra += 2;

            if(
                (this.firstBoatRound == true && options[0].amount >= (2+extra)) ||
                (this.firstBoatRound == false && options[0].amount >= 6 && this.playerHandCardsCount(this.activePlayer) >= 10) ||
                (this.boat.includes('deadly') && options[0].amount >= 2) ||
                this.lateGame
            ) {
                this.aiPlayProgressCard('merchant fleet', options[0].type);
            }
        },
        async aiPlayMerchant() {
            if(! this.activePlayer.ai) return;

            if(this.aiHasProgressCard('merchant') == false) return;
            if(['Forest escape'].includes(this.aiTactic)) {
                if(!this.lateGame) return;
            }

            let resourceTileFound = false;

            this.buildings
                .filter(building => building.player == this.activePlayer)
                .forEach(building => {
                    if(this.spotTiles(building.tile, building.position).find(tile => ['lumber', 'brick', 'wool', 'grain', 'ore'].includes(tile.type))) resourceTileFound = true;
            });

            if(resourceTileFound == false) return;

            let playerTileTypes = [];
            let tiles = [];

            this.buildings
                .filter(building => building.player == this.activePlayer)
                .forEach(building => {
                    this.spotTiles(building.tile, building.position)
                        .filter(tile => tile && tile.type != 'goldmine')
                        .forEach(tile => {
                        if(!tile) return;

                        if(!playerTileTypes.includes(tile.type) && tile.type != 'desert' && tile.group == 'land') {
                            playerTileTypes.push(tile.type);
                            tiles.push({ type: tile.type, tile: tile });
                        }
                    })
                })

            // if same amount of handCards, place merchant on the one with lowest position first
            let sortBy = [
                { type: 'brick', position: 0 },
                { type: 'lumber', position: 1 },
                { type: 'wool', position: 2 },
                { type: 'grain', position: 3 },
                { type: 'ore', position: 4 },
            ]

            let cards = this.activePlayer.handCards.filter(obj => obj.tradeCost != 2 && !['cloth', 'coin', 'paper'].includes(obj.type) && playerTileTypes.includes(obj.type));

            // if no cards in hand, push players available tile-type
            if(cards.length == 0) {
                cards.push(this.activePlayer.handCards.find(handObj => handObj.type == playerTileTypes[0]));
            } else {
                cards.sort((a, b) => sortBy.find(obj => obj.type == a.type).position - sortBy.find(obj => obj.type == b.type).position)
                .sort((a, b) => b.amount - a.amount);
            }

            let target = tiles.find(obj => obj.type == cards[0].type);
            let extra = 0;
            if(['ore', 'grain', 'wool'].includes(cards[0].type)) extra = 1;

            if(
                (this.firstBoatRound == true && cards[0].amount >= (2+extra)) ||
                (this.firstBoatRound == false && cards[0].amount >= 4) ||
                (this.activePlayerTooManyHandCards && cards[0].amount >= 2) ||
                (this.merchant.player == this.leader && this.lateGame) ||
                this.activePlayer.points == this.playToPoints-1
            ) {
                // don't move merchant to same type if player already has it
                if(this.merchant.player == this.activePlayer && this.merchant.tile.type == target.tile.type) return;
                // if you have merchant, don't move it to another tile if you have less cards of that type
                if(this.merchant.player == this.activePlayer && cards[0].amount <= this.activePlayer.handCards.find(obj => obj.type == this.merchant.tile.type).amount) return;

                this.aiPlayProgressCard('merchant');
                this.moveMerchant(target.tile, this.activePlayer);
            }
        },
        async aiPlayProgressCard(cardLabel, choise = null, basic = false) {
            if(basic == true) {
                if(!['point'].includes(cardLabel)) {
                    if(this.activePlayer.basicProgressCardPlayedThisTurn) return;
                    // card needs to exist, also it can't be bought this turn
                    if(!this.activePlayer.progressCards.find(progressCard => progressCard.label == cardLabel && !progressCard.boughtThisTurn)) return;
                }
            }

            let card = this.activePlayer.progressCards.find(progressCard => progressCard.label == cardLabel);

            if(card) {
                let cardIndex = this.activePlayer.progressCards.findIndex(progressCard => progressCard.label == cardLabel);

                this.playProgressCard(this.activePlayer, card, cardIndex)
                if(choise) {
                    this.playProgressCard(this.activePlayer, card, cardIndex, choise)
                } else {
                    this.playProgressCard(this.activePlayer, card, cardIndex)
                }

                if(cardLabel == 'alchemist') {
                    let delay = this.randomNumber(600, 600+this.aiActionSpeed*6);

                    if(this.multiplayer) delay = 0;
                    setTimeout(() => {
                        this.aiPlayAlchemist(choise);
                    }, delay);
                }
            }

            await this.waitForAllAnimations();
        },
        aiGetCommodityIncome() {
            let playerCities = this.cities.filter(city => city.player == this.activePlayer);

            let commodityIncome = [
                { type: 'paper', dots: 0 },
                { type: 'cloth', dots: 0 },
                { type: 'coin', dots: 0 },
            ];

            playerCities.forEach(city => {
                this.spotTiles(city.tile, city.position).forEach(tile => {
                    // ignore 2, 3, 11, 12
                    if(tile.number.dots > 2) {
                        if(tile.type == 'wool') {
                            commodityIncome.find(obj => obj.type == 'cloth').dots += tile.number.dots;
                        } else if(tile.type == 'ore') {
                            commodityIncome.find(obj => obj.type == 'coin').dots += tile.number.dots;
                        } else if(tile.type == 'lumber') {
                            commodityIncome.find(obj => obj.type == 'paper').dots += tile.number.dots;
                        }
                    }
                })
            });

            commodityIncome.sort((a, b) => a.dots - b.dots);

            return commodityIncome;
        },
        async aiUpgradeAllCommodities() {
            if(! this.activePlayer.ai) return;

            if(this.activePlayerCommodityCardsCount == 0) return;
            await this.aiUpgradeAllCommoditiesAction();
            await this.waitForAllAnimations();
            await this.aiUpgradeAllCommoditiesAction();
            await this.waitForAllAnimations();
            await this.aiUpgradeAllCommoditiesAction();
            await this.waitForAllAnimations();
        },
        async aiUpgradeAllCommoditiesAction() {
            if(! this.activePlayer.ai) return;

            let types = ['cloth', 'coin', 'paper'];
            if(this.activePlayerCommodityCardsCount == 0) return;

            // try to upgrade 3 times without any trading
            // avoid upgrading to more than lvl1 if player has given up the type
            // for(let x = 0; x < 3; x++) {
                for(let i = 0; i < types.length; i++) {
                    let target = this.activePlayer.progress.find(progress => progress.type == types[i]);
                    let clothLevel = this.activePlayer.progress.find(progress => progress.type == 'cloth').level;

                    if(this.activePlayer.handCards.find(obj => obj.type == types[i]).amount >= target.level) {
                        if(!this.activePlayer.giveUp.includes(types[i]) || target.level == 0 || target.level == 3 || clothLevel <= 2) {
                            if(this.metropolisTopLevel[types[i]] == 5 && target.level != 3) return;

                            if(this.commodityUpgrade(this.activePlayer, types[i]) != false) {
                                // don't upgrade to lvl5 if opponent has lvl5 already

                                this.commodityUpgrade(this.activePlayer, types[i]);
                                await this.waitForAllAnimations();
                            }
                        }
                    }
                }
            // }
        },
        async aiUpgradeToCity() {
            if(! this.activePlayer.ai) return;

            if(this.activePlayer.pieces.cities == 0) return;

            if(!this.canPay(this.activePlayer, [ { type: 'grain', amount: 2 }, { type: 'ore', amount: 3 } ])) return;

            let playerHouses = this.houses.filter(house => house.player == this.activePlayer);

            if(playerHouses.length == 0) return;

            playerHouses.forEach(house => {
                house.dots = this.spot(house.tile, house.position).dots;
                house.exponentialDots = this.spot(house.tile, house.position).exponentialDots;

                this.spotTiles(house.tile, house.position).forEach(tile => {
                    // possibility to get dev-cards / grain = better city spot
                    if(['wool', 'ore', 'lumber', 'grain'].includes(tile.type)) {
                        house.exponentialDots += (tile.number.dots/5);
                    // goldmine & 4, 10 as lowest number
                    } else if(['goldmine'].includes(tile.type) && tile.number.dots >= 3) {
                        house.exponentialDots += 1;
                    }
                })
            });

            playerHouses.sort((a, b) => b.exponentialDots - a.exponentialDots);

            this.upgradeToCity(playerHouses[0].tile, playerHouses[0].position, false, true);

            this.updatePlayersPoints();
            await this.waitForAllAnimations();
        },
        async aiBuildCitywall() {
            if(! this.activePlayer.ai) return;

            if(this.lateGame) return;

            let options = this.cities.filter(city => city.player == this.activePlayer && city.citywall == false).sort((a, b) => b.exponentialDots - a.exponentialDots);

            if(options.length == 0) return false;

            this.buildCitywall(options[0].tile, options[0].position);

            await this.waitForAllAnimations();
        },
        async aiBuildKnight() {
            if(! this.activePlayer.ai) return;

            if(!this.canPay(this.activePlayer, [ { type: 'wool', amount: 1 }, { type: 'ore', amount: 1 } ]) && !this.buildEverythingForFree) return false;

            if(this.aiGetBuildKnightSpots() == false) return false;

            let buildKnightSpot = this.aiGetBuildKnightSpots();

            this.buildKnight(buildKnightSpot.tile, buildKnightSpot.position, this.activePlayer);

            await this.waitForAllAnimations();
        },
        async aiBuildBackupKnight() {
            if(! this.activePlayer.ai) return;

            let knights = this.knights
                .filter(knight => knight.player == this.activePlayer)
                .sort((a, b) => a.level - b.level);

            if(!knights.length) return false;

            // if weakest knight level is 1
            if(knights[0].level == 1) return;

            await this.aiBuildKnight();

            await this.waitForAllAnimations();
        },
        async aiUpgradeKnight() {
            if(! this.activePlayer.ai) return;

            if(!this.canPay(this.activePlayer, [ { type: 'wool', amount: 1 }, { type: 'ore', amount: 1 } ])) return false;

            let canUpgrade = this.aiGetCanUpgradeKnights(this.activePlayer);

            if(!canUpgrade.length) return false;

            this.upgradeKnight(canUpgrade[0].tile, canUpgrade[0].position, this.activePlayer);

            await this.waitForAllAnimations();
        },
        aiGetBuildKnightSpots() {
            let playerRoads = this.roads.filter(road => road.player == this.activePlayer);

            let knightSpotOptions = [];
            let playerTiles = [];

            playerRoads.forEach(road => {
                this.roadSpots(road.tile, road.roadSpot).forEach(spot => {
                    if(!spot) return;
                    // knight distance could be 3 from house too, maybe need to be fixed
                    if(this.buildingDistance(spot.tile, spot.position) || spot.content.type != undefined) return;
                    knightSpotOptions.push(spot);

                    this.buildings
                    .filter(building => building.player == this.activePlayer)
                    .forEach(building => {
                        this.spotTiles(building.tile, building.position).forEach(tile => {
                            if(playerTiles.find(playerTile => playerTile.tile == tile)) return;

                            playerTiles.push({ tile: tile, buildingType: building.type });
                        });
                    });
                });
            });

            knightSpotOptions.forEach(spotOption => {
                spotOption.score = 0;
                this.spotTiles(spotOption.tile, spotOption.position).forEach(tile => {
                    let protectedTile = playerTiles.find(playerTile => playerTile.tile == tile);
                    if(protectedTile) {
                        let cityValue = 1.00;
                        if(protectedTile.buildingType == 'city') cityValue = 1.30;

                        let grainValue = 1.10;
                        if(playerTiles.filter(playerTile => playerTile.tile.type == 'grain').length == 1) grainValue = 2.00;

                        if(tile.type == 'grain') {
                            spotOption.score += tile.number.dots * cityValue * grainValue;
                        } else {
                            spotOption.score += tile.number.dots * cityValue;
                        }
                    }

                    // build close to robber
                    if(this.robber.tile == tile) spotOption.score += 5;
                })

                let longestFound = 0;
                let opponentRoadFound = 0;
                let emptyRoadSpot;

                let opponentRoads = this.spotRoadsAll(spotOption.tile, spotOption.position).forEach(roadSpot => {
                    if(!roadSpot) return;

                    let content = this.roads.find(road => road.key == roadSpot.roadSpot.key)
                    if(content && content.player != this.activePlayer) {
                        opponentRoadFound++;
                        if(this.longestRoad.find(longestRoad => longestRoad.key == content.key)) {
                            longestFound++;
                        }
                    }

                    if(!content) {
                        emptyRoadSpot = roadSpot;
                    }
                })

                // break longest
                // this one could get more points if the leader of the game has the longest road and more than x points
                if(longestFound == 2) {
                    spotOption.score += 10;
                }

                if(opponentRoadFound == 1 && emptyRoadSpot) {
                    // next spot empty for a house or not?
                    let targetSpot = this.roadSpots(emptyRoadSpot.tile, emptyRoadSpot.roadSpot.position).find(spot => spot.key != spotOption.key);
                    if(targetSpot) {
                        // about to get blocked by opponent
                        if(this.buildingDistance(targetSpot.tile, targetSpot.position)) {
                            spotOption.score += 20;
                        // next spot not buildable, maybe not interesting to block if opponent chases away your knight etc
                        // could be good in some cases to protect this spot if you are interested in connecting your houses to get the longest road
                        } else {
                            spotOption.score -= 20;
                        }
                    }
                }
            });

            knightSpotOptions = knightSpotOptions.sort((a,b) => b.score - a.score);

            // this.highlightSpot(knightSpotOptions[0].key, this.activePlayer);

            if(!knightSpotOptions.length) return false;

            return knightSpotOptions[0];
        },
        async aiBuildHouse() {
            if(! this.activePlayer.ai) return;

            if(!this.canPay(this.activePlayer, [ { type: 'lumber', amount: 1 }, { type: 'brick', amount: 1 }, { type: 'wool', amount: 1 }, { type: 'grain', amount: 1 } ])) return false;

            if(this.activePlayer.pieces.houses == 0) return false;

            let options = [];

            this.roads.filter(road => road.player == this.activePlayer).forEach(road => {
                this.roadSpots(road.tile, road.roadSpot).forEach(spot => {

                    if(options.find(houseSpot => houseSpot.key == spot.key)) return;

                    if(!this.buildingDistance(spot.tile, spot.position)) return;

                    let harbors = this.harborsBySpotPosition(spot.tile, spot.position);
                    spot.exponentialDots += harbors.length*5;

                    // goldmine with 4, 5, 6 found
                    if(this.spotTiles(spot.tile, spot.position).find(spotTile => spotTile && spotTile.type == 'goldmine' && spotTile.number.dots >= 3)) spot.exponentialDots += 20;

                    // extra island point
                    if(this.leader.points >= this.playToPoints-9) {
                        if(!this.buildingFoundOnNewIsland(spot.tile, spot.position)) spot.exponentialDots += 50;
                    }

                    // opponent wants to build here too
                    if(this.spotRoads(spot.tile, spot.position).find(road => road && road.player != this.activePlayer)) spot.exponentialDots += 60;

                    options.push(spot);
                });
            })

            if(!options.length) return false;

            options.sort((a, b) => b.exponentialDots - a.exponentialDots);

            this.buildHouse(options[0].tile, options[0].position, this.activePlayer);

            this.updatePlayersPoints();

            await this.waitForAllAnimations();
        },
        async aiTradeAndUpgradeAllToLevel1() {
            if(! this.activePlayer.ai) return;

            await this.aiTradeAndUpgradeLevel1();
            await this.aiUpgradeAllCommodities()
            await this.aiTradeAndUpgradeLevel1();
            await this.aiUpgradeAllCommodities()
            await this.aiTradeAndUpgradeLevel1();
            await this.aiUpgradeAllCommodities()
        },
        async aiTradeAndUpgradeLevel1() {
            if(! this.activePlayer.ai) return;

            let progressLevel0 = this.activePlayer.progress
                .filter(progress => progress.level == 0)
                .map(progress => {
                    return { type: progress.type, dots: 0 }
                });

            // don't trade & upgrade coin to lvl1 after first boat round
            if(!this.firstBoatRound) progressLevel0 = progressLevel0.filter(progress => progress.type != 'coin');

            progressLevel0.forEach(obj => {
                this.cities.filter(city => city.player == this.activePlayer).forEach(city => {
                    this.spotTiles(city.tile, city.position).forEach(tile => {
                        if(tile.type == 'wool') {
                            if(progressLevel0.find(progress => progress.type == 'cloth')) {
                                if(tile.number.dots > 2) {
                                    progressLevel0.find(progress => progress.type == 'cloth').dots += tile.number.dots;
                                }
                            }
                        } else if(tile.type == 'ore') {
                            if(progressLevel0.find(progress => progress.type == 'coin')) {
                                if(tile.number.dots > 2) {
                                    progressLevel0.find(progress => progress.type == 'coin').dots += tile.number.dots;
                                }
                            }
                        } else if(tile.type == 'lumber') {
                            if(progressLevel0.find(progress => progress.type == 'paper')) {
                                if(tile.number.dots > 2) {
                                    progressLevel0.find(progress => progress.type == 'paper').dots += tile.number.dots;
                                }
                            }
                        }
                    })
                })
            })

            progressLevel0.sort(obj => {
                if(obj.type == 'cloth') return -1;
                if(obj.type == 'paper') return -1;
                if(obj.type == 'coin') return 1;
            });

            progressLevel0.sort((a, b) => a.dots - b.dots);

            if(!progressLevel0.length) return;

            this.aiTradeWithBank(progressLevel0[0].type);

            this.commodityUpgrade(this.activePlayer, progressLevel0[0].type);

            await this.waitForAllAnimations();
        },
        async aiTradeBankForPaperToThird() {
            if(! this.activePlayer.ai) return;

            // don't try to upgrade paper to third if many points
            if(this.activePlayer.points >= this.playToPoints-5) return;
            // no paper trade for player without cities
            if(!this.cities.find(city => city.player == this.activePlayer)) return false;
            // paper level not 2
            if(this.activePlayer.progress.find(obj => obj.type == 'paper').level != 2) return false;

            let wildCards = 0;

            // check if player can afford to trade and get all needed cards for a activated knight or not
            this.activePlayer.handCards.filter(obj => obj.type != 'paper').forEach(obj => {
                if(obj.amount >= obj.tradeCost) {
                    wildCards += Math.floor((obj.amount)/obj.tradeCost);
                }
            });

            let paperAmount = this.activePlayer.handCards.find(obj => obj.type == 'paper').amount;

            // cant afford paper trade
            if(paperAmount + wildCards < 3) return false;

            for(let i = 0; i < 3-paperAmount; i++) {
                this.aiTradeWithBank('paper');
            }

            this.commodityUpgrade(this.activePlayer, 'paper');

            await this.waitForAllAnimations();
        },
        async aiTradeBankForMetropolis(requireFullTrade = false) {
            if(! this.activePlayer.ai) return;

            // no trade for player without cities
            if(!this.cities.find(city => city.player == this.activePlayer)) return false;

            let fightingFor = this.activePlayer.progress
                .filter(obj => this.activePlayer.fightFor.includes(obj.type))
                .sort((a, b) => b.level - a.level);

            // don't upgrade to level5 if close to winning, try to upgrade to lvl4 instead
            if(this.activePlayer.points >= this.playToPoints - 2) {
                fightingFor.sort((a, b) => a.level - b.level);
            }

            if(!fightingFor.length) return;

            let extra = 1;
            if(this.aiHasProgressCard('crane')) extra = 0;

            let selectedType = fightingFor[0].type;
            let selectedCost = fightingFor[0].level+extra;

            if(fightingFor[0].metropolis == false) {
                if(!this.cities.find(city => city.player == this.activePlayer && city.disabled == false && city.metropolis == false)) {
                    if(this.cities.find(city => city.player == this.activePlayer && city.disabled == true)) {
                        if(await this.aiEnableCity() == false) {
                            await this.aiTradeBankForEnableCity(true);
                            await this.waitForAllAnimations();
                            await this.aiEnableCity();
                            await this.waitForAllAnimations();
                        }
                    }
                }

                if(!this.cities.find(city => city.player == this.activePlayer && city.disabled == false && city.metropolis == false)) return;
            }

            let wildCards = 0;

            // check if player can afford to trade and get all needed cards for a activated knight or not
            this.activePlayer.handCards.filter(obj => obj.type != selectedType).forEach(obj => {
                if(obj.amount >= obj.tradeCost) {
                    wildCards += Math.floor((obj.amount)/obj.tradeCost);
                }
            });

            let typeAmount = this.activePlayer.handCards.find(obj => obj.type == selectedType).amount;

            if(typeAmount + wildCards < selectedCost) {
                if(requireFullTrade) return false;
            }

            for(let i = 0; i < selectedCost-typeAmount; i++) {
                this.aiTradeWithBank(selectedType);
            }

            this.commodityUpgrade(this.activePlayer, selectedType);

            await this.waitForAllAnimations();
        },
        async aiTradeBankForKnight(requireFullTrade = false) {
            if(! this.activePlayer.ai) return;

            let cardsNeeded = [];
            let wildCards = 0;
            let checkCards = ['wool', 'grain', 'ore'];

            if(this.aiHasProgressCard('warlord')) checkCards = ['wool', 'ore'];

            let playerInactiveKnights = this.knights.filter(knight => knight.player == this.activePlayer && !knight.active);
            // player has built knight already, only grain needed
            if(playerInactiveKnights.length != 0 && this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0) checkCards = ['grain'];

            // check if player can afford to trade and get all needed cards for a activated knight or not
            this.activePlayer.handCards.forEach(obj => {

                if(checkCards.includes(obj.type)) {
                    if(obj.amount == 0) {
                        cardsNeeded.push(obj.type);
                    }

                    if(obj.amount+1 >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount-1)/obj.tradeCost);
                    }
                } else {
                    if(obj.amount >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount)/obj.tradeCost);
                    }
                }
            });

            let tileTypeDots = [
                { type: 'wool', dots: 0, },
                { type: 'grain', dots: 0, },
                { type: 'ore', dots: 0, },
            ];

            // check which one of the missing cards is hardest for player to get
            this.buildings.filter(building => building.player == this.activePlayer).forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {
                    if(checkCards.includes(tile.type)) {
                        tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.dots;
                    }
                });
            });

            tileTypeDots.sort((a, b) => a.dots - b.dots);

            tileTypeDots = tileTypeDots.filter(obj => cardsNeeded.includes(obj.type));

            if(wildCards == 0) return;

            if(cardsNeeded.length > wildCards) {
                if(requireFullTrade) return 'full trade failed';
                // can only affor one card
                this.aiTradeWithBank(tileTypeDots[0].type, 'knight')
            } else {
                // full trade possible
                // tileTypeDots.forEach(obj => {
                for(let i = 0; i < tileTypeDots.length; i++) {
                    await this.aiTradeWithBank(tileTypeDots[i].type, 'knight')
                    await this.waitForAllAnimations();
                }
                // })
            }
        },
        async aiTradeBankForCheapCity(requireFullTrade = false) {
            if(! this.activePlayer.ai) return;

            if(this.activePlayer.pieces.cities == 0) return;

            let cardsNeeded = [];
            let wildCards = 0;
            let checkCards = ['grain', 'ore'];

            // check if player can afford to trade and get all needed cards for a activated knight or not
            this.activePlayer.handCards.forEach(obj => {

                if(checkCards.includes(obj.type)) {
                    let needAmount = 0;
                    if(obj.type == 'grain') {
                        needAmount = 1;
                        if(obj.amount < needAmount) {
                            cardsNeeded.push(obj.type);
                        }
                    }

                    if(obj.type == 'ore') {
                        needAmount = 2;
                        if(obj.amount < needAmount) {
                            cardsNeeded.push(obj.type);
                        }
                    }

                    if(obj.amount+1 >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount-needAmount)/obj.tradeCost);
                    }
                } else {
                    if(obj.amount >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount)/obj.tradeCost);
                    }
                }
            });

            let tileTypeDots = [
                { type: 'grain', dots: 0, },
                { type: 'ore', dots: 0, },
            ];

            // check which one of the missing cards is hardest for player to get
            this.buildings.filter(building => building.player == this.activePlayer).forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {
                    if(checkCards.includes(tile.type)) {
                        tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.dots;
                    }
                });
            });

            tileTypeDots.sort((a, b) => a.dots - b.dots);

            tileTypeDots = tileTypeDots.filter(obj => cardsNeeded.includes(obj.type));

            if(wildCards == 0) return;

            if(cardsNeeded.length > wildCards) {
                if(requireFullTrade) return 'full trade failed';
            }

            // full trade possible
            // tileTypeDots.forEach(obj => {
            for(let i = 0; i < tileTypeDots.length; i++) {
                let needs = 0;
                if(tileTypeDots[i].type == 'ore') {
                    needs = 2-this.activePlayer.handCards.find(obj => obj.type == 'ore').amount;
                } else if(tileTypeDots[i].type == 'grain') {
                    needs = 1-this.activePlayer.handCards.find(obj => obj.type == 'grain').amount;
                }

                if(needs > 0) {
                    for(let x = 0; x < needs; x++) {
                        this.aiTradeWithBank(tileTypeDots[i].type, 'cheap city')
                    }
                }
            }
            // })
        },
        async aiTradeBankForEnableCity(requireFullTrade = false) {
            if(! this.activePlayer.ai) return;

            let cardsNeeded = [];
            let wildCards = 0;
            let checkCards = ['lumber', 'ore'];

            // don't trade for enable city in end game if has 1 enabled city
            let citiesCanTakeMetropolis = this.cities.filter(city => city.player == this.activePlayer && city.disabled == false && city.metropolis == false).length;
            if(citiesCanTakeMetropolis >= 1 && this.lateGame) return;

            // check if player can afford to trade and get all needed cards for a activated knight or not
            this.activePlayer.handCards.forEach(obj => {

                if(checkCards.includes(obj.type)) {
                    let needAmount = 0;
                    if(obj.type == 'lumber') {
                        needAmount = 1;
                        if(obj.amount < needAmount) {
                            cardsNeeded.push(obj.type);
                        }
                    }

                    if(obj.type == 'ore') {
                        needAmount = 1;
                        if(obj.amount < needAmount) {
                            cardsNeeded.push(obj.type);
                        }
                    }

                    if(obj.amount+1 >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount-needAmount)/obj.tradeCost);
                    }
                } else {
                    if(obj.amount >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount)/obj.tradeCost);
                    }
                }
            });

            let tileTypeDots = [
                { type: 'lumber', dots: 0, },
                { type: 'ore', dots: 0, },
            ];

            // check which one of the missing cards is hardest for player to get
            this.buildings.filter(building => building.player == this.activePlayer).forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {
                    if(checkCards.includes(tile.type)) {
                        tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.dots;
                    }
                });
            });

            tileTypeDots.sort((a, b) => a.dots - b.dots);

            tileTypeDots = tileTypeDots.filter(obj => cardsNeeded.includes(obj.type));

            if(wildCards == 0) return;

            if(cardsNeeded.length > wildCards) {
                if(requireFullTrade) return 'full trade failed';
            }

            // full trade possible
            // tileTypeDots.forEach(obj => {
            for(let i = 0; i < tileTypeDots.length; i++) {

                let needs = 0;
                if(tileTypeDots[i].type == 'ore') {
                    needs = 1-this.activePlayer.handCards.find(obj => obj.type == 'ore').amount;
                } else if(tileTypeDots[i].type == 'lumber') {
                    needs = 1-this.activePlayer.handCards.find(obj => obj.type == 'lumber').amount;
                }

                if(needs > 0) {
                    for(let x = 0; x < needs; x++) {
                        this.aiTradeWithBank(tileTypeDots[i].type, 'enable city')
                    }
                }
            }
            // })
        },
        async aiTradeForCity(requireFullTrade = false) {
            if(! this.activePlayer.ai) return;

            if(this.tradedOnce == true) return;

            if(this.activePlayer.pieces.cities == 0) return;
            if(!this.houses.find(house => house.player == this.activePlayer)) return;

            let cardsNeeded = [];
            let wildCards = 0;
            let checkCards = ['grain', 'ore'];

            // check if player can afford to trade and get all needed cards for a activated knight or not
            this.activePlayer.handCards.forEach(obj => {

                if(checkCards.includes(obj.type)) {
                    let needAmount = 0;
                    if(obj.type == 'grain') {
                        needAmount = 2;
                        if(obj.amount < needAmount) {
                            cardsNeeded.push(obj.type);
                        }
                    }

                    if(obj.type == 'ore') {
                        needAmount = 3;
                        if(obj.amount < needAmount) {
                            cardsNeeded.push(obj.type);
                        }
                    }

                    if(obj.amount+1 >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount-needAmount)/obj.tradeCost);
                    }
                } else {
                    if(obj.amount >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount)/obj.tradeCost);
                    }
                }
            });

            let tileTypeDots = [
                { type: 'grain', dots: 0, },
                { type: 'ore', dots: 0, },
            ];

            // check which one of the missing cards is hardest for player to get
            this.buildings.filter(building => building.player == this.activePlayer).forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {
                    if(checkCards.includes(tile.type)) {
                        tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.dots;
                    }
                });
            });

            tileTypeDots.sort((a, b) => a.dots - b.dots);

            tileTypeDots = tileTypeDots.filter(obj => cardsNeeded.includes(obj.type));

            if(wildCards == 0) return;

            if(cardsNeeded.length > wildCards) {
                if(requireFullTrade) return 'full trade failed';
            }

            // full trade possible
            // tileTypeDots.forEach(obj => {
            for(let i = 0; i < tileTypeDots.length; i++) {
                let needs = 0;
                if(tileTypeDots[i].type == 'ore') {
                    needs = 3-this.activePlayer.handCards.find(obj => obj.type == 'ore').amount;
                } else if(tileTypeDots[i].type == 'grain') {
                    needs = 2-this.activePlayer.handCards.find(obj => obj.type == 'grain').amount;
                }

                if(needs > 0) {
                    for(let x = 0; x < needs; x++) {
                        await this.aiMakeTrade(tileTypeDots[i].type, this.activePlayer);
                        await this.waitForAllAnimations();
                    }
                }
            // })
            }

            this.tradedOnce = true;
        },
        async aiTradeBankForCity(requireFullTrade = false) {
            if(! this.activePlayer.ai) return;

            if(this.activePlayer.pieces.cities == 0) return;
            if(!this.houses.find(house => house.player == this.activePlayer)) return;

            let cardsNeeded = [];
            let wildCards = 0;
            let checkCards = ['grain', 'ore'];

            // check if player can afford to trade and get all needed cards for a activated knight or not
            this.activePlayer.handCards.forEach(obj => {

                if(checkCards.includes(obj.type)) {
                    let needAmount = 0;
                    if(obj.type == 'grain') {
                        needAmount = 2;
                        if(obj.amount < needAmount) {
                            cardsNeeded.push(obj.type);
                        }
                    }

                    if(obj.type == 'ore') {
                        needAmount = 3;
                        if(obj.amount < needAmount) {
                            cardsNeeded.push(obj.type);
                        }
                    }

                    if(obj.amount+1 >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount-needAmount)/obj.tradeCost);
                    }
                } else {
                    if(obj.amount >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount)/obj.tradeCost);
                    }
                }
            });

            let tileTypeDots = [
                { type: 'grain', dots: 0, },
                { type: 'ore', dots: 0, },
            ];

            // check which one of the missing cards is hardest for player to get
            this.buildings.filter(building => building.player == this.activePlayer).forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {
                    if(checkCards.includes(tile.type)) {
                        tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.dots;
                    }
                });
            });

            tileTypeDots.sort((a, b) => a.dots - b.dots);

            tileTypeDots = tileTypeDots.filter(obj => cardsNeeded.includes(obj.type));

            if(wildCards == 0) return;

            if(cardsNeeded.length > wildCards) {
                if(requireFullTrade) return 'full trade failed';
            }

            // full trade possible
            // tileTypeDots.forEach(obj => {
            for(let i = 0; i < tileTypeDots.length; i++) {
                let needs = 0;
                if(tileTypeDots[i].type == 'ore') {
                    needs = 3-this.activePlayer.handCards.find(obj => obj.type == 'ore').amount;
                } else if(tileTypeDots[i].type == 'grain') {
                    needs = 2-this.activePlayer.handCards.find(obj => obj.type == 'grain').amount;
                }

                if(needs > 0) {
                    for(let x = 0; x < needs; x++) {
                        await this.aiTradeWithBank(tileTypeDots[i].type, 'city')
                        await this.waitForAllAnimations();
                    }
                }
            // })
            }
        },
        async aiTradeBankForHouseAndRoad(requireFullTrade = false) {
            if(! this.activePlayer.ai) return;

            if(this.activePlayer.pieces.houses == 0) return;
            if(this.activePlayer.pieces.roads == 0) return;

            let cardsNeeded = [];
            let wildCards = 0;
            let checkCards = ['lumber', 'wool', 'brick', 'grain'];

            // check if player can afford to trade and get all needed cards for a activated knight or not
            this.activePlayer.handCards.forEach(obj => {

                if(checkCards.includes(obj.type)) {
                    if(obj.amount == 0) {
                        cardsNeeded.push(obj.type);
                    }
                    let extra = 0;
                    if(['lumber', 'brick'].includes(obj.type)) extra = 1;
                    if(obj.amount+1+extra >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount-(1+extra))/obj.tradeCost);
                    }
                } else {
                    if(obj.amount >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount)/obj.tradeCost);
                    }
                }
            });

            let tileTypeDots = [
                { type: 'grain', dots: 0, },
                { type: 'brick', dots: 0, },
                { type: 'wool', dots: 0, },
                { type: 'lumber', dots: 0, },
            ];

            // check which one of the missing cards is hardest for player to get
            this.buildings.filter(building => building.player == this.activePlayer).forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {
                    if(checkCards.includes(tile.type)) {
                        tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.dots;
                    }
                });
            });

            tileTypeDots.sort((a, b) => a.dots - b.dots);

            tileTypeDots = tileTypeDots.filter(obj => cardsNeeded.includes(obj.type));

            if(wildCards == 0) return;

            if(cardsNeeded.length > wildCards) {
                if(requireFullTrade) return 'full trade failed';
            }

            // full trade possible
            // tileTypeDots.forEach(obj => {
            for(let i = 0; i < tileTypeDots.length; i++) {
                this.aiTradeWithBank(tileTypeDots[i].type, 'house and road')
            }
            // })
        },
        async aiTradeBankForHouseAndBoat(requireFullTrade = false) {
            if(! this.activePlayer.ai) return;

            if(this.activePlayer.pieces.houses == 0) return;
            if(this.activePlayer.pieces.boats == 0) return;

            let options = this.aiGetBuildBoatAndHouseOptions();

            if(!options.length) return;

            let cardsNeeded = [];
            let wildCards = 0;
            let checkCards = ['lumber', 'wool', 'brick', 'grain'];

            // check if player can afford to trade and get all needed cards for a activated knight or not
            this.activePlayer.handCards.forEach(obj => {

                if(checkCards.includes(obj.type)) {
                    if(obj.amount == 0) {
                        cardsNeeded.push(obj.type);
                    }
                    let extra = 0;
                    if(['lumber', 'wool'].includes(obj.type)) extra = 1;
                    if(obj.amount+1+extra >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount-(1+extra))/obj.tradeCost);
                    }
                } else {
                    if(obj.amount >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount)/obj.tradeCost);
                    }
                }
            });

            let tileTypeDots = [
                { type: 'grain', dots: 0, },
                { type: 'brick', dots: 0, },
                { type: 'wool', dots: 0, },
                { type: 'lumber', dots: 0, },
            ];

            // check which one of the missing cards is hardest for player to get
            this.buildings.filter(building => building.player == this.activePlayer).forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {
                    if(checkCards.includes(tile.type)) {
                        tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.dots;
                    }
                });
            });

            tileTypeDots.sort((a, b) => a.dots - b.dots);

            tileTypeDots = tileTypeDots.filter(obj => cardsNeeded.includes(obj.type));

            if(wildCards == 0) return;

            if(cardsNeeded.length > wildCards) {
                if(requireFullTrade) return 'full trade failed';
            }

            // full trade possible
            // tileTypeDots.forEach(obj => {
            for(let i = 0; i < tileTypeDots.length; i++) {
                this.aiTradeWithBank(tileTypeDots[i].type, 'house and boat')
            }
            // })
        },
        async aiTradeBankForRoad(requireFullTrade = false) {
            if(! this.activePlayer.ai) return;

            if(this.activePlayer.pieces.roads == 0) return;

            let cardsNeeded = [];
            let wildCards = 0;
            let checkCards = ['lumber', 'brick'];

            // check if player can afford to trade and get all needed cards for a activated knight or not
            this.activePlayer.handCards.forEach(obj => {

                if(checkCards.includes(obj.type)) {
                    if(obj.amount == 0) {
                        cardsNeeded.push(obj.type);
                    }

                    if(obj.amount+1 >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount-1)/obj.tradeCost);
                    }
                } else {
                    if(obj.amount >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount)/obj.tradeCost);
                    }
                }
            });

            let tileTypeDots = [
                { type: 'brick', dots: 0, },
                { type: 'lumber', dots: 0, },
            ];

            // check which one of the missing cards is hardest for player to get
            this.buildings.filter(building => building.player == this.activePlayer).forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {
                    if(checkCards.includes(tile.type)) {
                        tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.dots;
                    }
                });
            });

            tileTypeDots.sort((a, b) => a.dots - b.dots);

            tileTypeDots = tileTypeDots.filter(obj => cardsNeeded.includes(obj.type));

            if(wildCards == 0) return;

            if(cardsNeeded.length > wildCards) {
                if(requireFullTrade) return 'full trade failed';
            }

            // full trade possible
            // tileTypeDots.forEach(obj => {
            for(let i = 0; i < tileTypeDots.length; i++) {
                this.aiTradeWithBank(tileTypeDots[i].type, 'road')
            }
            // })
        },
        async aiTradeBankForBoat(requireFullTrade = false) {
            if(! this.activePlayer.ai) return;

            if(!this.seafaresExpansion) return;
            if(this.activePlayer.pieces.boats == 0) return;
            if(!this.aiGetBuildBoatOptions().length) return;

            let cardsNeeded = [];
            let wildCards = 0;
            let checkCards = ['lumber', 'wool'];

            // check if player can afford to trade and get all needed cards for a activated knight or not
            this.activePlayer.handCards.forEach(obj => {

                if(checkCards.includes(obj.type)) {
                    if(obj.amount == 0) {
                        cardsNeeded.push(obj.type);
                    }

                    if(obj.amount+1 >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount-1)/obj.tradeCost);
                    }
                } else {
                    if(obj.amount >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount)/obj.tradeCost);
                    }
                }
            });

            let tileTypeDots = [
                { type: 'wool', dots: 0, },
                { type: 'lumber', dots: 0, },
            ];

            // check which one of the missing cards is hardest for player to get
            this.buildings.filter(building => building.player == this.activePlayer).forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {
                    if(checkCards.includes(tile.type)) {
                        tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.dots;
                    }
                });
            });

            tileTypeDots.sort((a, b) => a.dots - b.dots);

            tileTypeDots = tileTypeDots.filter(obj => cardsNeeded.includes(obj.type));

            if(wildCards == 0) return;

            if(cardsNeeded.length > wildCards) {
                if(requireFullTrade) return 'full trade failed';
            }

            // full trade possible
            // tileTypeDots.forEach(obj => {
            for(let i = 0; i < tileTypeDots.length; i++) {
                this.aiTradeWithBank(tileTypeDots[i].type, 'boat')
            }
            // })
        },
        async aiTradeForHouse(requireFullTrade = false) {
            if(! this.activePlayer.ai) return;

            if(this.tradedOnce == true) return;

            if(this.activePlayer.pieces.houses == 0) return;

            let cardsNeeded = [];
            let wildCards = 0;
            let checkCards = ['lumber', 'wool', 'brick', 'grain'];

            // check if player can afford to trade and get all needed cards for a activated knight or not
            this.activePlayer.handCards.forEach(obj => {

                if(checkCards.includes(obj.type)) {
                    if(obj.amount == 0) {
                        cardsNeeded.push(obj.type);
                    }

                    if(obj.amount+1 >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount-1)/obj.tradeCost);
                    }
                } else {
                    if(obj.amount >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount)/obj.tradeCost);
                    }
                }
            });

            let tileTypeDots = [
                { type: 'grain', dots: 0, },
                { type: 'brick', dots: 0, },
                { type: 'wool', dots: 0, },
                { type: 'lumber', dots: 0, },
            ];

            // check which one of the missing cards is hardest for player to get
            this.buildings.filter(building => building.player == this.activePlayer).forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {
                    if(checkCards.includes(tile.type)) {
                        tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.dots;
                    }
                });
            });

            tileTypeDots.sort((a, b) => a.dots - b.dots);

            tileTypeDots = tileTypeDots.filter(obj => cardsNeeded.includes(obj.type));

            if(wildCards == 0) return;

            if(cardsNeeded.length > wildCards) {
                if(requireFullTrade) return 'full trade failed';
            }

            // full trade possible
            for(let i = 0; i < tileTypeDots.length; i++) {
                await this.aiMakeTrade(tileTypeDots[i].type, this.activePlayer);
                await this.waitForAllAnimations();
            }
            this.tradedOnce = true;
        },
        async aiTradeBankForHouse(requireFullTrade = false) {
            if(! this.activePlayer.ai) return;

            if(this.activePlayer.pieces.houses == 0) return;

            let cardsNeeded = [];
            let wildCards = 0;
            let checkCards = ['lumber', 'wool', 'brick', 'grain'];

            // check if player can afford to trade and get all needed cards for a activated knight or not
            this.activePlayer.handCards.forEach(obj => {

                if(checkCards.includes(obj.type)) {
                    if(obj.amount == 0) {
                        cardsNeeded.push(obj.type);
                    }

                    if(obj.amount+1 >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount-1)/obj.tradeCost);
                    }
                } else {
                    if(obj.amount >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount)/obj.tradeCost);
                    }
                }
            });

            let tileTypeDots = [
                { type: 'grain', dots: 0, },
                { type: 'brick', dots: 0, },
                { type: 'wool', dots: 0, },
                { type: 'lumber', dots: 0, },
            ];

            // check which one of the missing cards is hardest for player to get
            this.buildings.filter(building => building.player == this.activePlayer).forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {
                    if(checkCards.includes(tile.type)) {
                        tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.dots;
                    }
                });
            });

            tileTypeDots.sort((a, b) => a.dots - b.dots);

            tileTypeDots = tileTypeDots.filter(obj => cardsNeeded.includes(obj.type));

            if(wildCards == 0) return;

            if(cardsNeeded.length > wildCards) {
                if(requireFullTrade) return 'full trade failed';
            }

            // full trade possible
            for(let i = 0; i < tileTypeDots.length; i++) {
                await this.aiTradeWithBank(tileTypeDots[i].type, 'house');
                await this.waitForAllAnimations();
            }
        },
        async aiTradeForProgressCard(requireFullTrade = false) {
            if(! this.activePlayer.ai) return;

            if(this.tradedOnce == true) return;

            if(this.activePlayer.pieces.houses == 0) return;

            let cardsNeeded = [];
            let wildCards = 0;
            let checkCards = ['ore', 'grain', 'wool'];

            // check if player can afford to trade and get all needed cards for a activated knight or not
            this.activePlayer.handCards.forEach(obj => {

                if(checkCards.includes(obj.type)) {
                    if(obj.amount == 0) {
                        cardsNeeded.push(obj.type);
                    }

                    if(obj.amount+1 >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount-1)/obj.tradeCost);
                    }
                } else {
                    if(obj.amount >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount)/obj.tradeCost);
                    }
                }
            });

            let tileTypeDots = [
                { type: 'ore', dots: 0, },
                { type: 'grain', dots: 0, },
                { type: 'wool', dots: 0, },
            ];

            // check which one of the missing cards is hardest for player to get
            this.buildings.filter(building => building.player == this.activePlayer).forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {
                    if(checkCards.includes(tile.type)) {
                        tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.dots;
                    }
                });
            });

            tileTypeDots.sort((a, b) => a.dots - b.dots);

            tileTypeDots = tileTypeDots.filter(obj => cardsNeeded.includes(obj.type));

            if(wildCards == 0) return;

            if(cardsNeeded.length > wildCards) {
                if(requireFullTrade) return 'full trade failed';
            }

            // full trade possible
            for(let i = 0; i < tileTypeDots.length; i++) {
                await this.aiMakeTrade(tileTypeDots[i].type, this.activePlayer);
                await this.waitForAllAnimations();
            }
            this.tradedOnce = true;
        },
        async aiTradeBankForProgressCard(requireFullTrade = false) {
            if(! this.activePlayer.ai) return;

            if(this.activePlayer.pieces.houses == 0) return;

            let cardsNeeded = [];
            let wildCards = 0;
            let checkCards = ['ore', 'grain', 'wool'];

            // check if player can afford to trade and get all needed cards for a activated knight or not
            this.activePlayer.handCards.forEach(obj => {

                if(checkCards.includes(obj.type)) {
                    if(obj.amount == 0) {
                        cardsNeeded.push(obj.type);
                    }

                    if(obj.amount+1 >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount-1)/obj.tradeCost);
                    }
                } else {
                    if(obj.amount >= obj.tradeCost) {
                        wildCards += Math.floor((obj.amount)/obj.tradeCost);
                    }
                }
            });

            let tileTypeDots = [
                { type: 'ore', dots: 0, },
                { type: 'grain', dots: 0, },
                { type: 'wool', dots: 0, },
            ];

            // check which one of the missing cards is hardest for player to get
            this.buildings.filter(building => building.player == this.activePlayer).forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {
                    if(checkCards.includes(tile.type)) {
                        tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.dots;
                    }
                });
            });

            tileTypeDots.sort((a, b) => a.dots - b.dots);

            tileTypeDots = tileTypeDots.filter(obj => cardsNeeded.includes(obj.type));

            if(wildCards == 0) return;

            if(cardsNeeded.length > wildCards) {
                if(requireFullTrade) return 'full trade failed';
            }

            // full trade possible
            for(let i = 0; i < tileTypeDots.length; i++) {
                await this.aiTradeWithBank(tileTypeDots[i].type, 'progress card');
                await this.waitForAllAnimations();
            }
        },
        async aiTradeWithBank(get, goal = null, give = this.activePlayerCheapestTradeTypes, alternativeBankTrade = 0) {
            if(! this.activePlayer.ai) return;

            if(this.incoming == true && this.activePlayer.ai == false) return;

            if(this.activePlayer.ai == false) return;

            let canPay = true;

            if(give.length) give = give.filter(give => give != get);

            this.quickTradeGet(get, this.activePlayer);
            if(give.length) this.quickTradeGive(give[0]);

            // first give didn't work, try with the other one
            if(this.bankTradeOffer.length && this.bankTradeOffer[0].type != give[0]) {
                this.closeModal(this.activePlayer, true);
                this.quickTradeGet(get, this.activePlayer);
                if(give.length) this.quickTradeGive(give[1]);
            }

            // no trades available
            if(!this.bankTradeOffer.length) {
                this.closeModal(this.activePlayer, true);
                this.focusModeOff(this.activePlayer);
                return false;
            }

            // don't trade away cards you need for the build
            let keepCards = [];
            if(goal == 'road') keepCards = [{ type: 'lumber', keepAmont: 1 }, { type: 'brick', keepAmont: 1 }];
            if(goal == 'boat') keepCards = [{ type: 'lumber', keepAmont: 1 }, { type: 'wool', keepAmont: 1 }];
            if(goal == 'knight') keepCards = [{ type: 'wool', keepAmont: 1 }, { type: 'grain', keepAmont: 1 }, { type: 'ore', keepAmont: 1 },];
            if(goal == 'house') keepCards = [{ type: 'lumber', keepAmont: 1 }, { type: 'brick', keepAmont: 1 }, { type: 'wool', keepAmont: 1 }, { type: 'grain', keepAmont: 1 }];
            if(goal == 'house and road') keepCards = [{ type: 'lumber', keepAmont: 2 }, { type: 'brick', keepAmont: 2 }, { type: 'wool', keepAmont: 1 }, { type: 'grain', keepAmont: 1 }];
            if(goal == 'house and boat') keepCards = [{ type: 'lumber', keepAmont: 2 }, { type: 'brick', keepAmont: 1 }, { type: 'wool', keepAmont: 2 }, { type: 'grain', keepAmont: 1 }];
            if(goal == 'city') keepCards = [{ type: 'grain', keepAmont: 2 }, { type: 'ore', keepAmont: 3 }];
            if(goal == 'cheap city') keepCards = [{ type: 'grain', keepAmont: 1 }, { type: 'ore', keepAmont: 2 }];
            if(goal == 'enable city') keepCards = [{ type: 'lumber', keepAmont: 1 }, { type: 'ore', keepAmont: 1 }];
            if(goal == 'progress card') keepCards = [{ type: 'ore', keepAmont: 1 }, { type: 'grain', keepAmont: 1 }, { type: 'wool', keepAmont: 1 }];

            let offer;
            if(alternativeBankTrade == 2) {
                if(!this.bankTradeOffer2.length) return;
                offer = this.bankTradeOffer2;
            } else {
                offer = this.bankTradeOffer;
            }

            offer.forEach(bankObj => {
                let extraCost = 0;
                let target = keepCards.find(obj => obj.type == bankObj.type);
                if(target) extraCost = target.keepAmont;

                if(!this.canPay(this.activePlayer, [ { type: bankObj.type, amount: bankObj.tradeCost + extraCost } ])) canPay = false;
            })

            if(!canPay) {
                this.closeModal(this.activePlayer, true);
                this.aiTradeWithBank(get, goal, give, 2);
                this.focusModeOff(this.activePlayer); return false;
            }

            await this.acceptBankTrade(alternativeBankTrade);

            await this.waitForAllAnimations();
        },
        async aiBuildRoad(risky = false, free = false, forceBuild = false) {
            if(! this.activePlayer.ai) return;

            if(!free) {
                if(!this.canPay(this.activePlayer, [ { type: 'lumber', amount: 1 }, { type: 'brick', amount: 1 } ])) return;
            }

            if(this.activePlayer.pieces.roads == 0) return;

            let options = this.aiGetBuildRoadOptions(risky);

            if(!forceBuild) options = options.filter(obj => obj.score > -250);

            if(!options.length) return;
            this.buildRoad(options[0].tile, options[0].roadSpot.position, this.activePlayer, free, true);

            await this.waitForAllAnimations();
        },
        async aiBuildBoat(risky = false, free = false, forceBuild = false) {
            if(! this.activePlayer.ai) return;

            if(!this.seafaresExpansion) return;

            if(['Forest escape'].includes(this.aiTactic)) {
                if(this.activePlayer.pieces.roads >= 5) return;
            }

            if(!free) {
                if(!this.canPay(this.activePlayer, [ { type: 'lumber', amount: 1 }, { type: 'wool', amount: 1 } ])) return false;
            }

            if(this.activePlayer.pieces.boats == 0) return;

            let options = this.aiGetBuildBoatOptions(risky);

            if(!forceBuild) options = options.filter(obj => obj.score > -250);

            if(!options.length) return;

            this.buildBoat(options[0].tile, options[0].roadSpot.position, this.activePlayer, free, true);

            await this.waitForAllAnimations();
        },
        async aiBuildLongestRoad(free = false) {
            if(! this.activePlayer.ai) return;

            if(!free) {
                if(!this.canPay(this.activePlayer, [ { type: 'lumber', amount: 1 }, { type: 'brick', amount: 1 } ])) return false;
            }

            if(this.activePlayer.pieces.roads == 0) return;

            let options = this.aiGetBuildLongestRoadOptions();

            if(!options.length) return;

            this.buildRoad(options[0].tile, options[0].roadSpot.position, this.activePlayer, free, true);

            await this.waitForAllAnimations();
        },
        async aiBuildLongestBoat(free = false) {
            if(! this.activePlayer.ai) return;

            if(!this.seafaresExpansion) return;
            if(!free) {
                if(!this.canPay(this.activePlayer, [ { type: 'lumber', amount: 1 }, { type: 'wool', amount: 1 } ])) return false;
            }

            if(this.activePlayer.pieces.boats == 0) return;

            let options = this.aiGetBuildLongestBoatOptions();

            if(!options.length) return;

            this.buildBoat(options[0].tile, options[0].roadSpot.position, this.activePlayer, free, true);

            await this.waitForAllAnimations();
        },
        async aiBuildRoadAndHouse() {
            if(! this.activePlayer.ai) return;

            if(!this.canPay(this.activePlayer, [ { type: 'lumber', amount: 2 }, { type: 'brick', amount: 2 }, { type: 'wool', amount: 1 }, { type: 'grain', amount: 1 } ])) return;

            if(this.activePlayer.pieces.houses == 0) return;

            if(this.activePlayer.pieces.roads == 0) return;

            let options = this.aiGetBuildRoadAndHouseOptions();

            if(!options.length) return;

            this.buildRoad(options[0].roadSpot.tile, options[0].roadSpot.roadSpot.position, this.activePlayer, false, true);

            await this.waitForAllAnimations();

            // this.buildHouse(options[0].spot.tile, options[0].spot.position, this.activePlayer);
            this.aiBuildHouse();

            await this.waitForAllAnimations();
        },
        async aiBuildBoatAndHouse() {
            if(! this.activePlayer.ai) return;

            if(!this.seafaresExpansion) return;

            if(['Forest escape'].includes(this.aiTactic)) {
                if(this.activePlayer.pieces.roads >= 5) return;
            }

            if(!this.canPay(this.activePlayer, [ { type: 'lumber', amount: 2 }, { type: 'brick', amount: 1 }, { type: 'wool', amount: 2 }, { type: 'grain', amount: 1 } ])) return;

            if(this.activePlayer.pieces.houses == 0) return;

            if(this.activePlayer.pieces.boats == 0) return;

            let options = this.aiGetBuildBoatAndHouseOptions();

            if(!options.length) return;

            this.buildBoat(options[0].roadSpot.tile, options[0].roadSpot.roadSpot.position, this.activePlayer, false, true);

            await this.waitForAllAnimations();

            this.aiBuildHouse();

            await this.waitForAllAnimations();
        },
        aiGetBuildTwoRoadsAndHouseSpot() {
            if(! this.activePlayer.ai) return;

            let checkedSpots = [];
            let buildSpots = [];
            let options = [];

            this.roads.filter(road => road.player == this.activePlayer).forEach(road => {
                this.roadSpots(road.tile, road.roadSpot).forEach(spot => {

                    if(buildSpots.find(houseSpot => houseSpot.key == spot.key)) return;

                    if(!this.buildingDistance(spot.tile, spot.position)) return;

                    buildSpots.push(spot);
                });
            })

            let buildings = this.buildings.filter(building => building.player == this.activePlayer);

            let startSpots = [...buildings, ...buildSpots];

            // seafares, only build from buildings (2 roads or 2 boats)
            if(this.seafaresExpansion) startSpots = [...buildings];

            startSpots.forEach(startSpot => {
                let ownRoadsInStartSpot = this.spotRoads(startSpot.tile, startSpot.position).filter(road => road && road.player == this.activePlayer).length;
                checkedSpots.push(startSpot.key);
                let startSpotRoads = this.spotRoadsAll(startSpot.tile, startSpot.position);
                this.spotSpots(startSpot.tile, startSpot.position).forEach(spot => {
                    if(!spot) return;
                    if(!spot.land) return;
                    if(spot.ownContent) return;
                    if(spot.opponentContent) return;
                    if(spot.roadBlocking) return;
                    if(this.spotTiles(startSpot.tile, startSpot.position).find(startSpotTile => startSpotTile == this.pirate.tile)) return;
                    checkedSpots.push(spot.key);

                    let firstRoadSpot;
                    let spotRoads = this.spotRoadsAll(spot.tile, spot.position)

                    startSpotRoads.forEach(road => {
                        if(spotRoads.find(road2 => road && road2 && road2.roadSpot == road.roadSpot) != undefined) {
                            firstRoadSpot = spotRoads.find(road2 => road && road2 && road2.roadSpot == road.roadSpot);
                        }
                    })

                    this.spotSpots(spot.tile, spot.position).forEach(spot2 => {
                        if(!spot2) return;
                        if(!spot2.land) return;
                        if(spot2.roadBlocking) return;
                        if(spot2.opponentContent) return;
                        if(spot2.ownContent) return;
                        if(!spot2.buildingDistance) return;
                        if(this.spotTiles(spot.tile, spot.position).find(spot2Tile => spot2Tile == this.pirate.tile)) return;

                        let secondRoadSpot;
                        let spot2roads = this.spotRoadsAll(spot2.tile, spot2.position)
                        // if(!spotRoads.length) return;
                        spotRoads.forEach(road => {
                            if(spot2roads.find(road2 => road && road2 && road2.roadSpot == road.roadSpot) != undefined) {
                                secondRoadSpot = spot2roads.find(road2 => road && road2 && road2.roadSpot == road.roadSpot);
                            }
                        })

                        if(!checkedSpots.includes(spot2.key)) {
                            // this.highlightSpot(spot2.key, this.activePlayer);
                            let harbors = this.harborsBySpotPosition(spot2.tile, spot2.position).length;
                            if(!firstRoadSpot) return;
                            if(!secondRoadSpot) return;
                            let score = 0;

                            // try to build boats to new island if possible!
                            if(this.seafaresExpansion) {
                                if(!this.buildingFoundOnNewIsland(spot2.tile, spot2.position)) score = 100;
                            }

                            options.push({ firstRoadSpot: firstRoadSpot, secondRoadSpot: secondRoadSpot, spot: spot2, ownRoadsInStartSpot: ownRoadsInStartSpot, score: score, harbors: harbors });
                        }

                    })
                })
            })

            if(!options.length) return false;

            options.forEach(option => {
                if(option.spot.opponentRoad) option.score += 50;
                option.score += option.spot.dots;
                option.score += option.harbors*5;
            })


            // ownRoadsInStartSpot will make longer longest even if ai can reach same house spot from 2 directions
            options
                .sort((a, b) => a.ownRoadsInStartSpot - b.ownRoadsInStartSpot)
                .sort((a, b) => b.score - a.score)

            if(!options[0].firstRoadSpot) return false;
            if(!options[0].secondRoadSpot) return false;
            return options[0];
        },
        async aiBuildTwoRoads() {
            if(! this.activePlayer.ai) return;

            if(!this.canPay(this.activePlayer, [ { type: 'lumber', amount: 2 }, { type: 'brick', amount: 2 } ])) return false;
            if(this.activePlayer.points > 7) {
                await this.aiBuildLongestRoad();
                await this.waitForAllAnimations();
                await this.aiBuildLongestRoad();
                await this.waitForAllAnimations();
            } else {
                await this.aiBuildRoad();
                await this.waitForAllAnimations();
                await this.aiBuildRoad();
                await this.waitForAllAnimations();
            }
        },
        async aiBuildTwoBoats() {
            if(! this.activePlayer.ai) return;

            if(!this.seafaresExpansion) return;
            if(!this.canPay(this.activePlayer, [ { type: 'lumber', amount: 2 }, { type: 'wool', amount: 2 } ])) return false;
            if(this.activePlayer.points > 7) {
                await this.aiBuildLongestBoat();
                await this.waitForAllAnimations();
                await this.aiBuildLongestBoat();
                await this.waitForAllAnimations();
            } else {
                await this.aiBuildBoat();
                await this.waitForAllAnimations();
                await this.aiBuildBoat();
                await this.waitForAllAnimations();
            }
        },
        async aiBuildTwoRoadsAndHouse(roadBuilding = false) {
            if(! this.activePlayer.ai) return;

            if(this.activePlayer.pieces.houses == 0) return false;
            if(this.activePlayer.pieces.roads < 2) return false;

            if(roadBuilding) {
                if(!this.canPay(this.activePlayer, [ { type: 'lumber', amount: 1 }, { type: 'brick', amount: 1 }, { type: 'wool', amount: 1 }, { type: 'grain', amount: 1 } ])) return false;
            } else {
                if(!this.canPay(this.activePlayer, [ { type: 'lumber', amount: 3 }, { type: 'brick', amount: 3 }, { type: 'wool', amount: 1 }, { type: 'grain', amount: 1 } ])) return false;
            }

            let buildHere = this.aiGetBuildTwoRoadsAndHouseSpot();

            if(buildHere == false) return false;

            let animationSpeed = 700;
            if(roadBuilding) animationSpeed = 700;

            this.delay = true;

            let buildBoats = false;

            if(this.seafaresExpansion) {
                // found water on both tiles
                if(
                    this.roadTiles(buildHere.firstRoadSpot.tile, buildHere.firstRoadSpot.roadSpot.position).find(roadTile => ['water', 'harbor'].includes(roadTile.group)) &&
                    this.roadTiles(buildHere.secondRoadSpot.tile, buildHere.secondRoadSpot.roadSpot.position).find(roadTile => ['water', 'harbor'].includes(roadTile.group))
                ) {
                    buildBoats = true;
                }

                if(buildBoats && this.activePlayer.pieces.boats < 2) return false;
            }


            if(buildHere) {
                setTimeout(() => {
                    if(buildBoats) {
                        this.buildBoat(buildHere.firstRoadSpot.tile, buildHere.firstRoadSpot.roadSpot.position, this.activePlayer, roadBuilding);
                    } else {
                        this.buildRoad(buildHere.firstRoadSpot.tile, buildHere.firstRoadSpot.roadSpot.position, this.activePlayer, roadBuilding);
                    }
                }, animationSpeed);

                setTimeout(() => {
                    if(buildBoats) {
                        this.buildBoat(buildHere.secondRoadSpot.tile, buildHere.secondRoadSpot.roadSpot.position, this.activePlayer, roadBuilding);
                    } else {
                        this.buildRoad(buildHere.secondRoadSpot.tile, buildHere.secondRoadSpot.roadSpot.position, this.activePlayer, roadBuilding);
                    }
                }, animationSpeed*2);

                setTimeout(() => {
                    this.buildHouse(buildHere.spot.tile, buildHere.spot.position, this.activePlayer);
                }, animationSpeed*5);

            } else {
                setTimeout(() => {
                    this.aiBuildRoad(false, roadBuilding);
                }, animationSpeed);

                setTimeout(() => {
                    this.aiBuildRoad(false, roadBuilding);
                }, animationSpeed*2);

                setTimeout(() => {
                    this.aiBuildHouse();
                }, animationSpeed*5);
            }

            setTimeout(() => {
                this.delay = false;
            }, animationSpeed*5);
            await this.waitForAllAnimations();

        },
        aiHasAvailableHouseSpot() {
            let options = [];

            this.roads.filter(road => road.player == this.activePlayer).forEach(road => {
                this.roadSpots(road.tile, road.roadSpot).forEach(spot => {

                    if(options.find(houseSpot => houseSpot.key == spot.key)) return;

                    if(!this.buildingDistance(spot.tile, spot.position)) return;

                    options.push(spot);
                });
            })

            if(!options.length) return false;

            return true;
        },
        aiHouseSpotAboutToGetBlocked() {
            let options = [];

            this.roads.filter(road => road.player == this.activePlayer).forEach(road => {
                this.roadSpots(road.tile, road.roadSpot).forEach(spot => {

                    if(options.find(houseSpot => houseSpot.key == spot.key)) return;

                    if(!this.buildingDistance(spot.tile, spot.position)) return;

                    options.push(spot);
                });
            })

            if(!options.length) return false;

            let spotWithOpponentRoad = false;

            options.forEach(spot => {
                if(this.spotRoads(spot.tile, spot.position).find(road => road && road.player != this.activePlayer)) {
                    spotWithOpponentRoad = true;
                }
            })

            return spotWithOpponentRoad;
        },
        aiGetBuildLongestRoadOptions() {

            let buildRoadSpots = this.getBuildRoadSpots(this.activePlayer);
            let checkedSpots = [];
            // this.activePlayer.highlightedSpots = [];

            buildRoadSpots.forEach(roadSpot => {
                if(!roadSpot) return;
                roadSpot.score = 0;
                let ownContent = 0;
                let startSpotRoads = 0;

                this.roadSpots(roadSpot.tile, roadSpot.roadSpot.position).forEach(spot => {
                    if(!spot) return;

                    if(this.buildingDistance(spot.tile, spot.position)) roadSpot.score += 50;

                    startSpotRoads = this.spotRoads(spot.tile, spot.position).filter(road => road && road.player == this.activePlayer).length;

                    let longestFound = 0;
                    let ownLongestFound = 0;

                    this.spotRoads(spot.tile, spot.position).forEach(road => {
                        if(!road) return;

                        if(this.playersLongest(this.activePlayer).find(obj => obj.key == road.key)) ownLongestFound++;

                        if(!this.longestRoad.length) return;

                        if(this.longestRoad[0].player != this.activePlayer) return;

                        if(this.longestRoad.find(obj => obj.key == road.key)) {
                            longestFound++
                        }

                    })

                    if(ownLongestFound == 1) roadSpot.score += 2000;
                    if(longestFound == 1) roadSpot.score += 2000;

                    let longestFoundInOwnSpot = false;
                    this.spotRoads(spot.tile, spot.position).forEach(spotRoad => {
                        if(!this.longestRoad.length) return;
                        if(this.longestRoad.find(obj => spotRoad && spotRoad.key == obj.key)) longestFoundInOwnSpot = true;
                    })

                    if(this.spotRoads(spot.tile, spot.position).find(road => road && road.player == this.activePlayer) || spot.content.player == this.activePlayer) {
                        return ownContent++;
                        // if(longestFoundInOwnSpot == false) { }
                    }

                    this.spotSpots(spot.tile, spot.position).forEach(spot2 => {
                        if(!spot2) return;
                        if(!spot2.land) return;
                        if(spot2.ownContent) roadSpot.score += 200;
                        if(spot2.content.player != this.activePlayer && spot2.content.type != undefined) roadSpot.score -= 500;

                        if(spot2.roadBlocking) roadSpot.score -= 500;

                        // avoid building into own longest again
                        this.spotRoads(spot2.tile, spot2.position).forEach(spotRoad => {
                            if(!this.longestRoad.length) return;
                            if(this.longestRoad.find(obj => spotRoad && spotRoad.key == obj.key)) roadSpot.score -= 300;
                        })

                            // this.spotSpots(spot2.tile, spot2.position).forEach(spot3 => {
                            //     if(!spot3) return;
                            //     if(!spot3.land) return;
                            //     if(spot3.ownContent) roadSpot.score += 10;
                            // })
                    });
                });

                // connect road with own stuff, good for longest, not good if you want to create house-spots
                // these spots are filtered out here
                if(ownContent == 2) {
                    roadSpot.score += 3000;
                    // roadSpot.score += 1000;
                }

                roadSpot.startSpotRoads = startSpotRoads;
                // don't build from house/city with 0 roads
                if(startSpotRoads == 0) roadSpot.startSpotRoads = 99;
            });


            // this.focusModeOn('temp', this.activePlayer);

            buildRoadSpots = buildRoadSpots
                .filter(obj => obj.score > -250)
                .sort((a, b) => a.startSpotRoads - b.startSpotRoads)
                .sort((a, b) => b.score - a.score);

            return buildRoadSpots;
        },
        aiGetBuildLongestBoatOptions() {

            let buildBoatSpots = this.getBuildBoatSpots(this.activePlayer);
            let checkedSpots = [];
            // this.activePlayer.highlightedSpots = [];

            buildBoatSpots.forEach(roadSpot => {
                if(!roadSpot) return;
                roadSpot.score = 0;
                let ownContent = 0;
                let startSpotRoads = 0;

                this.roadSpots(roadSpot.tile, roadSpot.roadSpot.position).forEach(spot => {
                    if(!spot) return;

                    if(this.buildingDistance(spot.tile, spot.position)) roadSpot.score += 50;

                    startSpotRoads = this.spotRoads(spot.tile, spot.position).filter(road => road && road.player == this.activePlayer).length;

                    let longestFound = 0;
                    let ownLongestFound = 0;

                    this.spotRoads(spot.tile, spot.position).forEach(road => {
                        if(!road) return;

                        if(this.playersLongest(this.activePlayer).find(obj => obj.key == road.key)) ownLongestFound++;

                        if(!this.longestRoad.length) return;

                        if(this.longestRoad[0].player != this.activePlayer) return;

                        if(this.longestRoad.find(obj => obj.key == road.key)) {
                            longestFound++
                        }

                    })

                    if(ownLongestFound == 1) roadSpot.score += 2000;
                    if(longestFound == 1) roadSpot.score += 2000;

                    let longestFoundInOwnSpot = false;
                    this.spotRoads(spot.tile, spot.position).forEach(spotRoad => {
                        if(!this.longestRoad.length) return;
                        if(this.longestRoad.find(obj => spotRoad && spotRoad.key == obj.key)) longestFoundInOwnSpot = true;
                    })

                    if(this.spotRoads(spot.tile, spot.position).find(road => road && road.player == this.activePlayer) || spot.content.player == this.activePlayer) {
                        return ownContent++;
                        // if(longestFoundInOwnSpot == false) { }
                    }

                    this.spotSpots(spot.tile, spot.position).forEach(spot2 => {
                        if(!spot2) return;
                        if(!spot2.land) return;
                        if(spot2.ownContent) roadSpot.score += 200;
                        if(spot2.content.player != this.activePlayer && spot2.content.type != undefined) roadSpot.score -= 500;

                        if(spot2.roadBlocking) roadSpot.score -= 500;

                        // avoid building into own longest again
                        this.spotRoads(spot2.tile, spot2.position).forEach(spotRoad => {
                            if(!this.longestRoad.length) return;
                            if(this.longestRoad.find(obj => spotRoad && spotRoad.key == obj.key)) roadSpot.score -= 300;
                        })

                            // this.spotSpots(spot2.tile, spot2.position).forEach(spot3 => {
                            //     if(!spot3) return;
                            //     if(!spot3.land) return;
                            //     if(spot3.ownContent) roadSpot.score += 10;
                            // })
                    });
                });

                // connect road with own stuff, good for longest, not good if you want to create house-spots
                // these spots are filtered out here
                if(ownContent == 2) {
                    roadSpot.score += 3000;
                    // roadSpot.score += 1000;
                }

                roadSpot.startSpotRoads = startSpotRoads;
                // don't build from house/city with 0 roads
                if(startSpotRoads == 0) roadSpot.startSpotRoads = 99;
            });


            // this.focusModeOn('temp', this.activePlayer);

            buildBoatSpots = buildBoatSpots
                .filter(obj => obj.score > -250)
                .sort((a, b) => a.startSpotRoads - b.startSpotRoads)
                .sort((a, b) => b.score - a.score);

            return buildBoatSpots;
        },
        aiGetBuildRoadOptions(risky) {

            let buildRoadSpots = this.getBuildRoadSpots(this.activePlayer);
            let checkedSpots = [];
            // this.activePlayer.highlightedSpots = [];

            // don't give alternaties from spot which player can build house on
            buildRoadSpots.forEach(roadSpot => {
                if(!roadSpot) return;
                this.roadSpots(roadSpot.tile, roadSpot.roadSpot.position).forEach(spot => {
                    if(!spot) return;
                    if(this.spotRoads(spot.tile, spot.position).find(road => road && road.player == this.activePlayer)) {
                        if(this.buildingDistance(spot.tile, spot.position)) {
                            buildRoadSpots = buildRoadSpots.filter(obj => obj != roadSpot);
                        }
                    }
                })
            });

            buildRoadSpots.forEach(roadSpot => {
                if(!roadSpot) return;
                roadSpot.score = 0;
                let ownContent = 0;
                this.roadSpots(roadSpot.tile, roadSpot.roadSpot.position).forEach(spot => {
                    if(!spot) return;

                    if(this.spotRoads(spot.tile, spot.position).find(road => road && road.player == this.activePlayer) || spot.content.player == this.activePlayer) return ownContent++;

                    if(this.spotTiles(spot.tile, spot.position).find(spotTile => spotTile && spotTile.hidden)) roadSpot.score += 100;

                    // spot is not empty
                    if(spot.content.type != undefined) roadSpot.score -= 100;

                    if(this.buildingDistance(spot.tile, spot.position)) {
                        if(!this.spotRoads(spot.tile, spot.position).find(road => road && road.player != this.activePlayer)) {
                            if(!risky) {
                                roadSpot.score += 150;
                            } else {
                                roadSpot.score += 200;
                            }
                        } else {
                            if(!risky) {
                                roadSpot.score += 100;
                            } else {
                                roadSpot.score += 150;
                            }
                        }

                        roadSpot.score += (spot.exponentialDots / 10)

                        // some value to harbors
                        let harbors = this.harborsBySpotPosition(spot.tile, spot.position);
                        roadSpot.score += harbors.length*0.5;
                    } else {
                        roadSpot.score -= 200;
                    }

                    this.spotSpots(spot.tile, spot.position).forEach(spot2 => {
                        if(!spot2) return;
                        if(!spot2.land) return;
                        if(spot2.ownContent) {
                            roadSpot.score -= 50;
                            return;
                        }
                        if(spot2.roadBlocking) {
                            roadSpot.score -= 50;
                            return;
                        }
                        if(spot2.content.type != null) return;
                        if(!spot2.buildingDistance) {
                            roadSpot.score -= 50;
                            return;
                        }

                        if(!spot2.opponentRoad) {
                            if(!risky) {
                                roadSpot.score += 20;
                            }
                        }

                        if(spot2.hiddenTiles) {
                            roadSpot.score += 50;
                            return;
                        }

                        // not sure about this one
                        if(roadSpot.score > 100) roadSpot.score += (spot2.exponentialDots / 10)
                        // roadSpot.score += (spot2.exponentialDots / 10)

                        // this.highlightSpot(spot2.key, this.activePlayer);

                        if(roadSpot.score < 100) {

                            this.spotSpots(spot2.tile, spot2.position).forEach(spot3 => {
                                if(!spot3) return;
                                if(!spot3.land) return;
                                if(spot3.ownContent) return;
                                if(spot3.content.type == 'knight') roadSpot.score += 2;

                                if(spot3.opponentRoad) {
                                    if(!risky) {
                                        roadSpot.score -= 2;
                                    } else {
                                        roadSpot.score += 2;
                                    }
                                } else {
                                    if(!risky) {
                                        roadSpot.score += 4;
                                    } else {
                                        roadSpot.score -= 4;
                                    }
                                }

                                // this.highlightSpot(spot3.key, this.activePlayer);
                            })
                        } else {
                            if(spot2.opponentRoad) {
                                if(!risky) {
                                    roadSpot.score -= 10;
                                } else {
                                    roadSpot.score += 10;
                                }
                            }
                        }

                    })
                });
                // connect road with own stuff, good for longest, not good if you want to create house-spots
                // these spots are filtered out here
                if(ownContent == 2) roadSpot.score -= 300;
            });

            // this.focusModeOn('temp', this.activePlayer);

            buildRoadSpots = buildRoadSpots
                // .filter(obj => obj.score > -250)
                .sort((a, b) => b.score - a.score);

            return buildRoadSpots;
        },
        aiGetBuildBoatOptions(risky, allOptions = false) {

            let buildBoatSpots = this.getBuildBoatSpots(this.activePlayer);
            let checkedSpots = [];
            // this.activePlayer.highlightedSpots = [];
            if(!allOptions) {
                // don't give alternaties from spot which player can build house on
                buildBoatSpots.forEach(roadSpot => {
                    if(!roadSpot) return;
                    this.roadSpots(roadSpot.tile, roadSpot.roadSpot.position).forEach(spot => {
                        if(!spot) return;
                        if(this.spotRoads(spot.tile, spot.position).find(road => road && road.player == this.activePlayer)) {
                            if(this.buildingDistance(spot.tile, spot.position) && this.spotTiles(spot.tile, spot.position).find(spotTile => spotTile.group == 'land')) {
                                buildBoatSpots = buildBoatSpots.filter(obj => obj != roadSpot);
                            }
                        }
                    })
                });
            }

            buildBoatSpots.forEach(roadSpot => {
                if(!roadSpot) return;
                roadSpot.score = 0;
                let ownContent = 0;

                // if player has longest, build longer with boat if possible
                if(this.longestRoad.player == this.activePlayer) {
                    if(this.playersLongest(this.activePlayer).find(obj => obj.key == roadSpot.key)) roadSpot.score += 1000;
                }

                this.roadSpots(roadSpot.tile, roadSpot.roadSpot.position).forEach(spot => {
                    if(!spot) return;

                    // not sure about this one
                    // lower score when water is not found on second build boat spot
                    this.spotRoads(spot.tile, spot.position).forEach(roadSpot => {
                        if(!roadSpot) return;

                        if(!this.roadTiles(roadSpot.tile, roadSpot.roadSpot.position).find(roadTile => roadTile && ['water', 'harbor'].includes(roadTile.group))) roadSpot.score -= 50;
                    });

                    if(this.spotRoads(spot.tile, spot.position).find(road => road && road.player == this.activePlayer) || spot.content.player == this.activePlayer) return ownContent++;

                    if(this.spotTiles(spot.tile, spot.position).find(spotTile => spotTile && spotTile.hidden)) roadSpot.score += 100;

                    // spot is not empty
                    if(spot.content.type != undefined) roadSpot.score -= 100;

                    if(this.buildingDistance(spot.tile, spot.position) && this.spotTiles(spot.tile, spot.position).find(spotTile => spotTile.group == 'land')) {
                        if(!this.spotRoads(spot.tile, spot.position).find(road => road && road.player != this.activePlayer)) {
                            // extra points if ai can build house on new island with 1 boat instead of 2
                            if(!this.buildingFoundOnNewIsland(spot.tile, spot.position)) roadSpot.score += 900;

                            if(!risky) {
                                roadSpot.score += 150;
                            } else {
                                roadSpot.score += 200;
                            }
                        } else {
                            if(!risky) {
                                roadSpot.score += 100;
                            } else {
                                roadSpot.score += 150;
                            }
                        }

                        roadSpot.score += (spot.exponentialDots / 10)

                        // some value to harbors
                        let harbors = this.harborsBySpotPosition(spot.tile, spot.position);
                        roadSpot.score += harbors.length*0.5;
                    } else {
                        roadSpot.score -= 200;
                    }

                    this.spotSpots(spot.tile, spot.position).forEach(spot2 => {
                        if(!spot2) return;
                        if(!spot2.land) return;
                        if(spot2.ownContent) {
                            roadSpot.score -= 50;
                            return;
                        }
                        if(spot2.roadBlocking) {
                            roadSpot.score -= 50;
                            return;
                        }
                        if(spot2.content.type != null) return;

                        if(!spot2.buildingDistanceBoats) {
                            roadSpot.score -= 50;
                            return;
                        }

                        if(!spot2.opponentRoad) {
                            if(!risky) {
                                roadSpot.score += 20;
                            }
                        }

                        // not sure about this one
                        if(roadSpot.score > 100) roadSpot.score += (spot2.exponentialDots / 10)
                        // roadSpot.score += (spot2.exponentialDots / 10)

                        // this.highlightSpot(spot2.key, this.activePlayer);

                        if(!this.buildingFoundOnNewIsland(spot2.tile, spot2.position)) roadSpot.score += 300;

                        if(roadSpot.score < 100) {

                            this.spotSpots(spot2.tile, spot2.position).forEach(spot3 => {
                                if(!spot3) return;
                                if(!spot3.land) return;
                                if(spot3.ownContent) return;
                                if(spot3.content.type == 'knight') roadSpot.score += 2;

                                if(!this.buildingFoundOnNewIsland(spot3.tile, spot3.position)) roadSpot.score += 100;

                                if(spot3.opponentRoad) {
                                    if(!risky) {
                                        roadSpot.score -= 2;
                                    } else {
                                        roadSpot.score += 2;
                                    }
                                } else {
                                    if(!risky) {
                                        roadSpot.score += 4;
                                    } else {
                                        roadSpot.score -= 4;
                                    }
                                }

                                // this.highlightSpot(spot3.key, this.activePlayer);
                            })
                        } else {
                            if(spot2.opponentRoad) {
                                if(!risky) {
                                    roadSpot.score -= 10;
                                } else {
                                    roadSpot.score += 10;
                                }
                            }
                        }

                    })
                });
                // connect road with own stuff, good for longest, not good if you want to create house-spots
                // these spots are filtered out here
                if(ownContent == 2) roadSpot.score -= 300;
            });

            // this.focusModeOn('temp', this.activePlayer);
            if(!allOptions) {
                // only build boats towars new islands if player has more than 5 roads left
                if(this.activePlayer.pieces.roads > 5) buildBoatSpots = buildBoatSpots.filter(boatSpot => boatSpot.score >= 150);
            }

            buildBoatSpots = buildBoatSpots
                // .filter(obj => obj.score > -250)
                .sort((a, b) => b.score - a.score);

            return buildBoatSpots;
        },
        aiGetBuildRoadAndHouseOptions() {
            let buildRoadSpots = this.getBuildRoadSpots(this.activePlayer);

            let buildRoadAndHouseOptions = [];

            // don't give alternaties from spot which player can build house on
            buildRoadSpots.forEach(roadSpot => {
                if(!roadSpot) return;
                this.roadSpots(roadSpot.tile, roadSpot.roadSpot.position).forEach(spot => {
                    if(!spot) return;
                    if(this.spotRoads(spot.tile, spot.position).find(road => road && road.player == this.activePlayer)) {
                        if(this.buildingDistance(spot.tile, spot.position)) {
                            buildRoadSpots = buildRoadSpots.filter(obj => obj != roadSpot);
                        }
                    }
                })
            });

            buildRoadSpots.forEach(roadSpot => {
                if(!roadSpot) return;

                this.roadSpots(roadSpot.tile, roadSpot.roadSpot.position)
                    .filter(spot =>
                        spot &&
                        // empty spot
                        spot.content.type == undefined &&
                        // player has no roads at the empty spot
                        !this.spotRoads(spot.tile, spot.position).find(road => road && road.player == this.activePlayer) &&
                        // buildingDistance is ok, player can build there
                        this.buildingDistance(spot.tile, spot.position)
                    )
                    .forEach(spot => {
                        if(!spot) return;
                        buildRoadAndHouseOptions.push({ spot: spot, roadSpot: roadSpot });
                    })
            })

            let opponentBuildRoadAndHouseOptions = [];

            this.players.filter(player => player != this.activePlayer).forEach(player => {
                let opponentBuildRoadSpots = this.getBuildRoadSpots(player);

                opponentBuildRoadSpots.forEach(roadSpot => {
                    if(!roadSpot) return;

                    this.roadSpots(roadSpot.tile, roadSpot.roadSpot.position)
                        .filter(spot =>
                            spot &&
                            // empty spot
                            spot.content.type == undefined &&
                            // player has no roads at the empty spot
                            !this.spotRoads(spot.tile, spot.position).find(road => road && road.player == player) &&
                            // buildingDistance is ok, player can build there
                            this.buildingDistance(spot.tile, spot.position)
                        )
                        .forEach(spot => {
                            if(!spot) return;
                            opponentBuildRoadAndHouseOptions.push({ spot: spot, roadSpot: roadSpot });
                        })
                });
            });

            buildRoadAndHouseOptions.forEach(obj => {
                if(opponentBuildRoadAndHouseOptions.length) {
                    if(opponentBuildRoadAndHouseOptions.find(opponentObj => opponentObj.spot.key == obj.spot.key)) {
                        // opponent could build to the same spot
                        obj.spot.exponentialDots *= 1.60;
                    }
                }

                this.spotRoadsAll(obj.spot.tile, obj.spot.position).forEach(roadSpot => {
                    if(!roadSpot) return;

                    let content = this.roads.find(road => road.key == roadSpot.roadSpot.key)
                    if(content && content.player != this.activePlayer) {
                        // opponent has road connected to spot
                        obj.spot.exponentialDots *= 1.70;
                    }
                });

                let harbors = this.harborsBySpotPosition(obj.spot.tile, obj.spot.position);
                obj.spot.exponentialDots += harbors.length*3;
            });

            buildRoadAndHouseOptions.sort((a,b) => b.spot.exponentialDots - a.spot.exponentialDots);

            // value harbors?
            return buildRoadAndHouseOptions;
        },
        aiGetBuildBoatAndHouseOptions() {
            let buildBoatSpots = this.getBuildBoatSpots(this.activePlayer);

            let buildBoatAndHouseOptions = [];

            // don't give alternaties from spot which player can build house on
            buildBoatSpots.forEach(roadSpot => {
                if(!roadSpot) return;
                this.roadSpots(roadSpot.tile, roadSpot.roadSpot.position).forEach(spot => {
                    if(!spot) return;
                    if(this.spotRoads(spot.tile, spot.position).find(road => road && road.player == this.activePlayer)) {
                        if(this.buildingDistance(spot.tile, spot.position) && this.spotTiles(spot.tile, spot.position).find(spotTile => spotTile.group == 'land')) {
                            buildBoatSpots = buildBoatSpots.filter(obj => obj != roadSpot);
                        }
                    }
                })
            });

            buildBoatSpots.forEach(roadSpot => {
                if(!roadSpot) return;

                this.roadSpots(roadSpot.tile, roadSpot.roadSpot.position)
                    .filter(spot =>
                        spot &&
                        // empty spot
                        spot.content.type == undefined &&
                        // player has no roads at the empty spot
                        !this.spotRoads(spot.tile, spot.position).find(road => road && road.player == this.activePlayer) &&
                        // buildingDistance is ok, player can build there
                        (this.buildingDistance(spot.tile, spot.position) && this.spotTiles(spot.tile, spot.position).find(spotTile => spotTile.group == 'land'))
                    )
                    .forEach(spot => {
                        if(!spot) return;

                        // prefer to build on new island if possible
                        if(!this.buildingFoundOnNewIsland(spot.tile, spot.position)) spot.exponentialDots *= 10;
                        buildBoatAndHouseOptions.push({ spot: spot, roadSpot: roadSpot });
                    })
            })

            let opponentBuildBoatAndHouseOptions = [];

            this.players.filter(player => player != this.activePlayer).forEach(player => {
                let opponentBuildBoatSpots = this.getBuildBoatSpots(player);

                opponentBuildBoatSpots.forEach(roadSpot => {
                    if(!roadSpot) return;

                    this.roadSpots(roadSpot.tile, roadSpot.roadSpot.position)
                        .filter(spot =>
                            spot &&
                            // empty spot
                            spot.content.type == undefined &&
                            // player has no roads at the empty spot
                            !this.spotRoads(spot.tile, spot.position).find(road => road && road.player == player) &&
                            // buildingDistance is ok, player can build there
                            (this.buildingDistance(spot.tile, spot.position) && this.spotTiles(spot.tile, spot.position).find(spotTile => spotTile.group == 'land'))
                        )
                        .forEach(spot => {
                            if(!spot) return;
                            opponentBuildBoatAndHouseOptions.push({ spot: spot, roadSpot: roadSpot });
                        })
                });
            });

            buildBoatAndHouseOptions.forEach(obj => {
                if(opponentBuildBoatAndHouseOptions.length) {
                    if(opponentBuildBoatAndHouseOptions.find(opponentObj => opponentObj.spot.key == obj.spot.key)) {
                        // opponent could build to the same spot
                        obj.spot.exponentialDots *= 1.60;
                    }
                }

                this.spotRoadsAll(obj.spot.tile, obj.spot.position).forEach(roadSpot => {
                    if(!roadSpot) return;

                    let content = this.roads.find(road => road.key == roadSpot.roadSpot.key)
                    if(content && content.player != this.activePlayer) {
                        // opponent has road connected to spot
                        obj.spot.exponentialDots *= 1.70;
                    }
                });

                let harbors = this.harborsBySpotPosition(obj.spot.tile, obj.spot.position);
                obj.spot.exponentialDots += harbors.length*3;
            });

            buildBoatAndHouseOptions.sort((a,b) => b.spot.exponentialDots - a.spot.exponentialDots);

            // value harbors?
            return buildBoatAndHouseOptions;
        },
        aiGetBuildTwoRoadsAndHouseOptions() {
            let options = [];

            return options;
        },
        async aiTryToBreakLongestRoad() {

            if(! this.activePlayer.ai) return;

            let roads = this.roads.filter(road => road.player == this.activePlayer);

            let options = [];

            if(!this.longestRoad.length) return;

            if(this.longestRoad[0].player == this.activePlayer) return;

            if(this.aiHasProgressCard('intrigue')) {
                await this.aiPlayProgressCard('intrigue');
            }

            roads.forEach(road => {
                let spotRoadKeys = [];
                this.roadSpots(road.tile, road.roadSpot).forEach(spot => {
                    if(!spot) return;
                    if(spot.content.type != undefined) return;
                    this.spotRoads(spot.tile, spot.position).forEach(spotRoad => {
                        if(!spotRoad) return;
                        spotRoadKeys.push(spotRoad.key)
                    })

                    if(this.longestRoad.find(longestRoad => spotRoadKeys.includes(longestRoad.key))) options.push(spot);
                })
            })

            // road connected to break longest
            if(options.length) {
                let choise = options[0];
                // can build house to break longest
                if(this.buildingDistance(choise.tile, choise.position) && this.activePlayer.pieces.houses != 0) {
                    // try to get house-cards
                    if(!this.canPay(this.activePlayer, [ { type: 'lumber', amount: 1 }, { type: 'brick', amount: 1 }, { type: 'wool', amount: 1 }, { type: 'grain', amount: 1 } ])) {
                        await this.aiTradeForHouse();
                        await this.waitForAllAnimations();
                        await this.aiTradeBankForHouse(true);
                        await this.waitForAllAnimations();
                    }

                    // try to build house
                    if(await this.aiBuildHouse() != false) {
                        await this.waitForAllAnimations();
                    // try to build knight or move knight
                    } else {
                        if(
                            this.canPay(this.activePlayer, [ { type: 'wool', amount: 1 }, { type: 'ore', amount: 1 } ])
                            && this.activePlayer.pieces.knights.includes(1)
                        ) {
                            this.buildKnight(choise.tile, choise.position, this.activePlayer);
                        } else {
                            await this.aiMoveKnight(true, true);
                            await this.waitForAllAnimations();
                            await this.aiMoveKnight(true, true);
                            await this.waitForAllAnimations();
                            await this.aiMoveKnight(true, true);
                            await this.waitForAllAnimations();
                        }
                        await this.waitForAllAnimations();
                    }
                } else {
                    if(
                        this.canPay(this.activePlayer, [ { type: 'wool', amount: 1 }, { type: 'ore', amount: 1 } ])
                        && this.activePlayer.pieces.knights.includes(1)
                    ) {
                        this.buildKnight(choise.tile, choise.position, this.activePlayer);
                    } else {
                        await this.aiMoveKnight(false, true);
                    }
                    await this.waitForAllAnimations();
                }
            // try to build road towards longest
            } else {
                let roadOptions = this.getBuildRoadSpots(this.activePlayer);

                let buildRoadOptions = [];

                roadOptions.forEach(roadOption => {
                    let spotRoadKeys = [];
                    this.roadSpots(roadOption.tile, roadOption.roadSpot.position).forEach(spot => {
                        if(!spot) return;
                        if(spot.content.type != undefined) return;

                        this.spotRoads(spot.tile, spot.position).forEach(spotRoad => {
                            if(!spotRoad) return;
                            spotRoadKeys.push(spotRoad.key)
                        })

                        if(this.longestRoad.find(longestRoad => spotRoadKeys.includes(longestRoad.key))) buildRoadOptions.push(roadOption);
                    })
                })

                if(!buildRoadOptions.length) return;

                let choise = buildRoadOptions[0];

                if(!this.canPay(this.activePlayer, [ { type: 'lumber', amount: 1 }, { type: 'brick', amount: 1 } ])) {
                    await this.aiTradeBankForRoad();
                    await this.waitForAllAnimations();
                }

                if(
                    this.canPay(this.activePlayer, [ { type: 'lumber', amount: 1 }, { type: 'brick', amount: 1 } ]) &&
                    this.activePlayer.pieces.roads != 0
                ) {
                    this.buildRoad(choise.tile, choise.roadSpot.position, this.activePlayer);
                    await this.waitForAllAnimations();
                    if(this.aiHasProgressCard('intrigue')) {
                        await this.aiPlayProgressCard('intrigue');
                    }
                    await this.waitForAllAnimations();
                    await this.aiTryToBreakLongestRoad();
                }
            }
        },
        async aiTryToCloseKnights() {
            if(! this.activePlayer.ai) return;

            // this.knights.forEach(knight => knight.activatedThisTurn = false);

            await this.aiMoveKnight(true);
            await this.waitForAllAnimations();
            await this.aiMoveKnight(true);
            await this.waitForAllAnimations();
            await this.aiMoveKnight(true);
            await this.waitForAllAnimations();
        },
        async aiMoveKnightFromHouseSpot() {
            await this.aiMoveKnight(false, false, true);
            await this.waitForAllAnimations();
        },
        async aiMoveKnight(random = false, moveToBreakLongest = false, moveFromHouseSpot = false) {
            // this.knights.forEach(knight => knight.activatedThisTurn = false);
            let options = this.knights
                .filter(knight => knight.player == this.activePlayer && knight.activatedThisTurn == false && knight.active == true)
                .sort((a, b) => b.level - a.level)
                .sort((a, b) => this.buildingDistance(b.tile, b.position, true) - this.buildingDistance(a.tile, a.position, true))

            if(moveFromHouseSpot) {
                options = options.filter(knight => this.buildingDistance(knight.tile, knight.position, true));
            }

            if(options.length == 0) return this.focusModeOff(this.activePlayer)

            let i = 0;
            if(random) i = this.randomNumber(0, options.length-1);

            if(moveToBreakLongest == true) {
                this.build(options[i].tile, options[i].position, 'move knight to break longest', this.activePlayer);
            } else {
                this.build(options[i].tile, options[i].position, 'try to move knight', this.activePlayer);
            }

            await this.waitForAllAnimations();

            // not sure about this
            if(! this.longestRoad.length) return;

            if(! moveToBreakLongest) return;

            if(this.longestRoad[0].player.color == this.activePlayer.color) return;

            this.scanLongestRoad(moveToBreakLongest, this.longestRoad[0].player);
        },
        async aiActivateRobberKnight(tradeForIt = false) {
            let playerKnights = this.knights.filter(knight => knight.player == this.activePlayer && knight.active == false);

            let knightFound = false;

            playerKnights.forEach(knight => {
                if(this.spotTiles(knight.tile, knight.position).find(tile => this.robber.tile == tile) && knightFound == false) {

                    if(tradeForIt) {
                        if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0) this.aiTradeWithBank('grain');
                    }

                    if(this.activateKnight(knight.tile, knight.position) == false) return 'failed to activate';

                    knightFound = true;
                }
            })

            await this.waitForAllAnimations();
        },
        async aiActivateStrongestKnight() {

            // just activate one knight at the end of the game
            let activeKnights = this.knights.filter(knight => knight.player == this.activePlayer && knight.active == true);
            if(activeKnights.length >= 1 && this.activePlayer.points >= this.playToPoints-3 && !this.boat.includes('deadly')) return 'just activate one knight in end game';

            let playerKnights = this.knights.filter(knight => knight.player == this.activePlayer && knight.active == false);

            if(!playerKnights.length) return 'knight needed';

            if(this.aiHasProgressCard('warlord')) return 'has warlord';

            playerKnights.forEach(knight => {
                knight.score = 0;
                this.spotTiles(knight.tile, knight.position).forEach(tile => {
                    if(this.robber.tile == tile) knight.score += 10;
                });
            });

            playerKnights
                .sort((a, b) => b.level - a.level)
                .sort((a, b) => b.score - a.score);

            if(!this.canPay(this.activePlayer, [ { type: 'grain', amount: 1 } ])) return 'failed to activate';

            if(!this.cities.find(city => city.player == this.activePlayer && city.metropolis == false)) return 'Player has no city to protect';

            if(this.activateKnight(playerKnights[0].tile, playerKnights[0].position) == false) return 'failed to activate';

            await this.waitForAllAnimations();
        },
        async aiPlayCrane() {
            if(! this.activePlayer.ai) return;

            if(!this.cities.find(city => city.player == this.activePlayer)) return;

            let craneOptions = this.getCraneOptions(this.activePlayer);

            // if lvl 4 or 5 is possible do it
            for(let i = 0; i < craneOptions.length; i++) {
                if([3, 4].includes(this.activePlayer.progress.find(progress => progress.type == craneOptions[i]).level)) {
                    this.aiPlayProgressCard('crane', craneOptions[i]);

                    await this.waitForAllAnimations();
                }
            }

            if(craneOptions.length) {
                // will crane the commodity which is hardest for ai to get
                let commodityIncome = this.aiGetCommodityIncome();

                for(let i = 0; i < commodityIncome.length; i++) {
                    let choise = commodityIncome[i].type;
                    if(craneOptions.includes(choise)) {
                        // use crane to come to lvl 1 first
                        if(this.activePlayer.progress.find(progress => progress.type == choise).level == 0) {
                            this.aiPlayProgressCard('crane', choise);
                        }

                        await this.waitForAllAnimations();
                    }
                }
            }

            // no lvl 0 progress found, use crane if possible
            craneOptions = this.getCraneOptions(this.activePlayer);
            if(craneOptions.length) {
                this.aiPlayProgressCard('crane', craneOptions[0]);
                await this.waitForAllAnimations();
            }
        },
        async aiSelectHouseForStartingResourceCards() {

            let options = this.houses.filter(house => house.player == this.activePlayer);

            options.forEach(house => {
                    house.score = 0;
                    this.spotTiles(house.tile, house.position).forEach(spotTile => {
                        if(!spotTile) return;

                        // aim to select the house with most tiles
                        if(spotTile.group == 'land' && spotTile.type != 'desert') {
                            house.score -= 100;
                        }

                        // but select the spot with bad numbers
                        house.score += spotTile.number.dots;
                    })
                })

            options.sort((a, b) => a.score - b.score);

            this.clickHighlighted(options[0].tile, options[0].position, this.activePlayer);
        },
    }
 }
