export default {
    methods: {
        updateThrowAwayCount(throwAwayCount) {
            this.throwAwayCount = throwAwayCount;
        },
        playerHandCardsCount(player) {
            let playerHandCardsCount = player.handCards.reduce((numberOfCards, card) => {
                return numberOfCards + card.amount;
            }, 0);

            return playerHandCardsCount;
        },
        selectedHandCardsCount(player) {
            let selectedHandCards = player.handCards.reduce((numberOfCards, card) => {
                return numberOfCards + card.selected;
            }, 0);

            return selectedHandCards;
        },
        resourceCount(player) {
            let resourceCount = player.handCards.reduce((numberOfCards, card) => {
                if(card.group != 'commodity') {
                    return numberOfCards + card.amount;
                } else {
                    return numberOfCards;
                }
            }, 0);

            return resourceCount;
        },
        commodityCount(player) {
            let commodityCount = player.handCards.reduce((numberOfCards, card) => {
                if(card.group == 'commodity') {
                    return numberOfCards + card.amount;
                } else {
                    return numberOfCards;
                }
            }, 0);

            return commodityCount;
        },
        deselectPlayerHandCards(player) {
            player.handCards.forEach(cardObj => {
                cardObj.selected = 0;
                cardObj.slideOut = 0;

                setTimeout(() => {
                    cardObj.slideIn = 0;
                }, 1000);
            });
        },
        stealHandCard(color) {
            let player = this.selectPlayer(color);

            let playerHandCards = [];

            this.players.forEach(player => {
                player.showOutgoingHandCards = false;
                player.showIncomingHandCards = false;
            });

            this.activePlayer.showIncomingHandCards = false;
            player.showOutgoingHandCards = false;

            // generate array of handCards
            player.handCards.forEach(cardObj => {
                for(let i = 0; i < cardObj.amount; i++) {
                    playerHandCards.push(cardObj.type);
                }
            });

            if(playerHandCards.length) {
                let randomIndex = this.randomNumber(0, playerHandCards.length-1);

                let pickedCard = playerHandCards[randomIndex];

                this.activePlayer.handCards.find(card => card.type == pickedCard).amount++;
                this.activePlayer.handCards.find(card => card.type == pickedCard).slideIn++;
                this.incoming = true;

                player.handCards.find(card => card.type == pickedCard).slideOut++;

                setTimeout(() => {
                    player.handCards.find(card => card.type == pickedCard).amount--;
                    player.handCards.find(card => card.type == pickedCard).slideOut--;

                    if(!player.ai) this.log('<div>:playerName robbed you</div> :' + pickedCard + ' <div>(only visible for you)</div>', this.activePlayer, false, null, player.color);
                    if(!this.activePlayer.ai) this.log('<div>You robbed :playerName</div> :' + pickedCard + ' <div>(only visible for you)</div>', player, false, null, this.activePlayer.color);

                    if(player != this.leader && player.ai) {
                        this.chat(["argh!", "why robbing me " + this.activePlayer.color + "?", "I will destroy you " + this.activePlayer.name + "..."], player, true, 10);
                    }
                    console.log('SAVE stealHandCard');
                    if(!this.activePlayer.ai) this.saveGame();
                    // this.saveGame();
                }, 700);

                setTimeout(() => {
                    this.activePlayer.handCards.find(card => card.type == pickedCard).slideIn--;
                    this.incoming = false;

                    // player.handCards.find(card => card.type == pickedCard).slideOut--;
                    // player.showOutgoingHandCards = true;
                    // this.activePlayer.showIncomingHandCards = false;
                }, 1000);
            }
        },
        async diceGeneratedHandCards(whiteDice, redDice, boatDice, basic = false) {
            let rolledNumber = whiteDice+redDice;

            this.players.forEach(player => player.getAqueductCard = true);

            this.buildings.forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {

                    if(rolledNumber == tile.number.value && tile.type != 'goldmine') {
                        if(this.robber.tile == tile) return;

                        building.player.handCards.find(cardObj => cardObj.type == tile.type).amount++;
                        building.player.handCards.find(cardObj => cardObj.type == tile.type).slideIn++;
                        this.incoming = true;

                        building.player.getAqueductCard = false;

                        setTimeout(() => {
                            building.player.handCards.find(cardObj => cardObj.type == tile.type).slideIn--;
                            this.incoming = false;
                        }, 1000)

                        if(building.type == 'city' && building.disabled == false) {
                            let cardType = tile.type;

                            if(basic == false) {
                                if(tile.type == 'wool') {
                                    cardType = 'cloth';
                                } else if(tile.type == 'ore') {
                                    cardType = 'coin';
                                } else if(tile.type == 'lumber') {
                                    cardType = 'paper';
                                }
                            }

                            building.player.handCards.find(cardObj => cardObj.type == cardType).amount++;
                            building.player.handCards.find(cardObj => cardObj.type == cardType).slideIn++;
                            this.incoming = true;

                            setTimeout(() => {
                                building.player.handCards.find(cardObj => cardObj.type == cardType).slideIn--;
                                this.incoming = false;
                            }, 1000)
                        }
                    } else if(rolledNumber == tile.number.value && tile.type == 'goldmine') {
                        if(this.robber.tile == tile) return;

                        building.player.getAqueductCard = false;

                        building.player.goldmineAmount++;

                        if(building.type == 'city' && building.disabled == false) {

                            building.player.goldmineAmount++;

                        }
                    }
                });
            });

            let aqueductPlayers = this.players.filter(player => player.getAqueductCard == true);

            aqueductPlayers.forEach(player => {
                if(player.progress.find(progress => progress.type == 'paper').level < 3) return;
                this.log('Waiting for :playerName to select a aqueduct resource', player, true);
                this.focusModeOn('select aqueduct resource', player);
                this.showModal('select aqueduct resource', player);
            });

            if(aqueductPlayers.length) {
                this.saveGame(false, true);
            }

            await this.waitForAllPlayers();
        },
        // this because I don't want to save on aqueduct
        async diceGeneratedHandCardsBasic(whiteDice, redDice, boatDice, basic = false) {
            let rolledNumber = whiteDice+redDice;

            this.players.forEach(player => player.getAqueductCard = true);

            this.buildings.forEach(building => {
                this.spotTiles(building.tile, building.position).forEach(tile => {

                    if(rolledNumber == tile.number.value && tile.type != 'goldmine') {
                        if(this.robber.tile == tile) return;

                        building.player.handCards.find(cardObj => cardObj.type == tile.type).amount++;
                        building.player.handCards.find(cardObj => cardObj.type == tile.type).slideIn++;
                        this.incoming = true;

                        building.player.getAqueductCard = false;

                        setTimeout(() => {
                            building.player.handCards.find(cardObj => cardObj.type == tile.type).slideIn--;
                            this.incoming = false;
                        }, 1000)

                        if(building.type == 'city' && building.disabled == false) {
                            let cardType = tile.type;

                            if(basic == false) {
                                if(tile.type == 'wool') {
                                    cardType = 'cloth';
                                } else if(tile.type == 'ore') {
                                    cardType = 'coin';
                                } else if(tile.type == 'lumber') {
                                    cardType = 'paper';
                                }
                            }

                            building.player.handCards.find(cardObj => cardObj.type == cardType).amount++;
                            building.player.handCards.find(cardObj => cardObj.type == cardType).slideIn++;
                            this.incoming = true;

                            setTimeout(() => {
                                building.player.handCards.find(cardObj => cardObj.type == cardType).slideIn--;
                                this.incoming = false;
                            }, 1000)
                        }
                    } else if(rolledNumber == tile.number.value && tile.type == 'goldmine') {
                        if(this.robber.tile == tile) return;

                        building.player.getAqueductCard = false;

                        building.player.goldmineAmount++;

                        if(building.type == 'city' && building.disabled == false) {

                            building.player.goldmineAmount++;

                        }
                    }
                });
            });

            let aqueductPlayers = this.players.filter(player => player.getAqueductCard == true);

            aqueductPlayers.forEach(player => {
                    if(player.progress.find(progress => progress.type == 'paper').level < 3) return;
                    this.log('Waiting for :playerName to select a aqueduct resource', player, true);
                    this.focusModeOn('select aqueduct resource', player);
                    this.showModal('select aqueduct resource', player);
            });

            await this.waitForAllPlayers();
        },
        async goldmineGeneratedHandCards() {

            this.players.forEach(player => {
                if(player.goldmineAmount > 0) {
                    this.log('Waiting for :playerName to select any resources', player, true);
                    this.focusModeOn('select goldmine resources', player);
                    this.showModal('select goldmine resources', player);
                }
            })

            this.saveGame(false, true);
            await this.waitForAllPlayers();
        }
    }
 }
