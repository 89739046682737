<template>
    <div>
        <span v-if="forceUpdate"></span>

        <div class="action-spot-wrapper">
            <div v-if="tile.spots[spotPosition].actions.length" class="actions-wrapper">
                <!-- <div @click="tile.spots[spotPosition].actions = []" class="close-action-menu">X</div> -->
                <div v-for="action in tile.spots[spotPosition].actions" :key="action" @click="spotActionClicked(tile, spotPosition, action)">

                    <div class="action" v-if="action == 'build house'" :class="[{ 'action-confirmed': actionConfirmed == 'build house' }]">
                        <div class="item">
                            <!-- <House
                                :color="activePlayer.color"
                                :class="[
                                    activePlayer.color,
                                    { 'opacity-30': activePlayer.pieces.houses == 0 },
                                    { 'opacity-30': buildingDistanceActions == false },
                                ]"
                            /> -->
                            <div
                                class="house"
                                :class="[
                                    activePlayer.color,
                                    { 'opacity-30': activePlayer.pieces.houses == 0 },
                                    { 'opacity-30': buildingDistanceActions == false },
                                ]"
                            >
                            </div>
                            <div class="items-left" style="left: 30px; top: -1px;">{{ activePlayer.pieces.houses }}</div>
                        </div>
                        <div class="action-cost" v-if="flatGraphics">
                            <div class="symbol lumber"></div>
                            <div class="symbol brick"></div>
                            <div class="symbol grain"></div>
                            <div class="symbol wool"></div>
                        </div>
                        <div class="action-cost-2" v-else>
                            <div class="symbol-2 lumber-2"></div>
                            <div class="symbol-2 brick-2"></div>
                            <div class="symbol-2 grain-2"></div>
                            <div class="symbol-2 wool-2"></div>
                        </div>
                    </div>
                    <div class="action" v-else-if="action == 'build knight'" :class="[{ 'action-confirmed': actionConfirmed == 'build knight' }]">
                        <div class="item">
                            <Knight3D :color="activePlayer.color" :level="1" :active="false" class="knight-item" :class="[
                                { 'opacity-30': activePlayer.pieces.knights.filter(knight => knight == 1).length == 0 },
                            ]"/>
                            <div class="items-left" style="left: 30px; top: -1px;">{{ activePlayer.pieces.knights.filter(knight => knight == 1).length }}</div>
                        </div>
                        <div class="action-cost" v-if="flatGraphics">
                            <div class="symbol wool"></div>
                            <div class="symbol ore"></div>
                        </div>
                        <div class="action-cost-2" v-else>
                            <div class="symbol-2 wool-2"></div>
                            <div class="symbol-2 ore-2"></div>
                        </div>
                    </div>

                    <div class="action" v-else-if="action == 'upgrade to city'" :class="[{ 'action-confirmed': actionConfirmed == 'upgrade to city' }]">
                        <div class="item">
                            <!-- <City :color="house.player.color" :class="{ 'opacity-30': activePlayer.pieces.cities == 0 }" /> -->
                            <div
                                class="city"
                                :class="[
                                    house.player.color,
                                ]"
                            ></div>
                        </div>
                        <div class="items-left" style="left: 38px; top: 2px;">{{ activePlayer.pieces.cities }}</div>
                        <div class="action-cost" v-if="flatGraphics">
                            <div class="symbol grain"></div>
                            <div class="symbol grain"></div>
                            <div class="symbol ore"></div>
                            <div class="symbol ore"></div>
                            <div class="symbol ore"></div>
                        </div>
                        <div class="action-cost-2" v-else>
                            <div class="symbol-2 grain-2"></div>
                            <div class="symbol-2 grain-2"></div>
                            <div class="symbol-2 ore-2"></div>
                            <div class="symbol-2 ore-2"></div>
                            <div class="symbol-2 ore-2"></div>
                        </div>
                    </div>

                    <div class="action" v-else-if="action == 'activate knight'" :class="[{ 'action-confirmed': actionConfirmed == 'activate knight' }]">
                        <div class="item">
                            <Knight3D :color="activePlayer.color" :level="knight.level" class="knight-item" :active="true" />
                        </div>
                        <div class="action-cost" v-if="flatGraphics">
                            <div class="symbol grain"></div>
                        </div>
                        <div class="action-cost-2" v-else>
                            <div class="symbol-2 grain-2"></div>
                        </div>
                    </div>

                    <div class="action" v-else-if="action == 'upgrade knight'" :class="[{ 'action-confirmed': actionConfirmed == 'upgrade knight' }]">
                        <div class="item">
                            <Knight3D :color="knight.player.color" :level="knight.level+1" :active="knight.active" class="knight-item" :class="[
                                { 'opacity-30': knight.upgradedThisTurn },
                                { 'opacity-30': knight.level == 2 && knight.player.progress.find(prgr => prgr.type == 'coin').level < 3 },
                                { 'opacity-30': activePlayer.pieces.knights.filter(playerKnight => playerKnight == knight.level+1).length == 0 },
                            ]"/>
                        </div>
                        <div class="items-left" style="left: 36px; top: 6px;">{{ activePlayer.pieces.knights.filter(playerKnight => playerKnight == knight.level+1).length }}</div>
                        <div class="action-cost" v-if="flatGraphics">
                            <div class="symbol wool"></div>
                            <div class="symbol ore"></div>
                        </div>
                        <div class="action-cost-2" v-else>
                            <div class="symbol-2 wool-2"></div>
                            <div class="symbol-2 ore-2"></div>
                        </div>
                    </div>

                    <div class="action" v-else-if="action == 'chase away robber'" :class="[{ 'action-confirmed': actionConfirmed == 'chase away robber' }]">
                        <div class="item item-right">
                            <Knight3D :color="knight.player.color" :level="knight.level" :active="!knight.active" class="knight-item" :class="[
                                { 'opacity-30': knight.activatedThisTurn },
                            ]" />
                            <p :class="{ 'opacity-30': knight.activatedThisTurn }">chase away robber</p>
                        </div>
                    </div>

                    <div class="action" v-else-if="action == 'chase away pirate'" :class="[{ 'action-confirmed': actionConfirmed == 'chase away pirate' }]">
                        <div class="item item-right">
                            <Knight3D :color="knight.player.color" :level="knight.level" :active="!knight.active" class="knight-item" :class="[
                                { 'opacity-30': knight.activatedThisTurn },
                            ]" />
                            <p :class="{ 'opacity-30': knight.activatedThisTurn }">chase away pirate</p>
                        </div>
                    </div>

                    <div class="action" v-else-if="action == 'move knight'" :class="[{ 'action-confirmed': actionConfirmed == 'move knight' }]">
                        <div class="item item-right">
                            <Knight3D :color="knight.player.color" :level="knight.level" :active="!knight.active" class="knight-item" :class="[
                                { 'opacity-30': knight.activatedThisTurn },
                            ]" />
                            <p :class="{ 'opacity-30': knight.activatedThisTurn }">Move knight</p>
                        </div>
                    </div>

                    <div class="action" v-else-if="action == 'enable city'" :class="[{ 'action-confirmed': actionConfirmed == 'enable city' }]">
                        <div class="item">
                            <!-- <City :color="city.player.color" :citywall="city.citywall" /> -->
                            <div
                                class="city"
                                :class="[
                                    city.player.color,
                                    { 'citywall': city.citywall },
                                    { 'metropolis': city.metropolis },
                                    { 'city_disabled': false },
                                ]"
                            ></div>
                        </div>
                        <div>
                            <div class="action-cost" v-if="flatGraphics">
                                <div class="symbol lumber"></div>
                                <div class="symbol ore"></div>
                            </div>
                            <div class="action-cost-2" v-else>
                                <div class="symbol-2 lumber-2"></div>
                                <div class="symbol-2 ore-2"></div>
                            </div>
                        </div>
                    </div>

                    <div class="action" v-else-if="action == 'build citywall'" :class="[{ 'action-confirmed': actionConfirmed == 'build citywall' }]">
                        <div class="item">
                            <!-- <City :color="city.player.color" :citywall="true" :disabled="city.disabled" :metropolis="city.metropolis" /> -->
                            <div
                                class="city"
                                :class="[
                                    city.player.color,
                                    { 'citywall': city.citywall },
                                    { 'metropolis': city.metropolis },
                                    { 'city_disabled': city.disabled },
                                ]"
                            ></div>
                        </div>
                        <div class="items-left" style="left: 42px; top: 2px;">{{ activePlayer.pieces.citywalls }}</div>
                        <div class="action-cost" v-if="flatGraphics">
                            <div class="symbol brick"></div>
                            <div class="symbol brick"></div>
                        </div>
                        <div class="action-cost-2" v-else>
                            <div class="symbol-2 brick-2"></div>
                            <div class="symbol-2 brick-2"></div>
                        </div>
                    </div>

                    <div v-else-if="action == 'disable city'">
                        <div class="item">
                            <!-- <City :color="city.player.color" :citywall="city.citywall" :disabled="true" /> -->
                            <div
                                class="city"
                                :class="[
                                    city.player.color,
                                    { 'citywall': city.citywall },
                                    { 'metropolis': city.metropolis },
                                    { 'city_disabled': city.disabled },
                                ]"
                            ></div>
                        </div>
                        <div class="action-cost">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import House from '../pieces/House';
    import City from '../pieces/City';
    import Knight3D from '../pieces/Knight3D';

    export default {
        props: ['tile', 'actionConfirmed', 'spotPosition', 'knight', 'house', 'city', 'activePlayer', 'forceUpdate', 'buildingDistanceActions', 'flatGraphics'],
        data() {
            return {
                showActionMenu: false,
            }
        },
        methods: {
            spotActionClicked(tile, spotPosition, action) {
                this.$emit('spotActionClicked', tile, spotPosition, action);

                this.$forceUpdate();
            },
        },
        components: {
            House,
            City,
            Knight3D,
        },
    }
</script>

<style>

</style>
