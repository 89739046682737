/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue/dist/vue';

require('./bootstrap');

window.Vue = Vue;

import VueRouter from 'vue-router';
Vue.use(VueRouter)

import router from './router'


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('gotland', require('./components/Gotland.vue').default);
Vue.component('basic', require('./components/Basic.vue').default);
Vue.component('new-game', require('./components/NewGame.vue').default);
Vue.component('tile', require('./components/map/Tile.vue').default);
Vue.component('spot', require('./components/map/Spot.vue').default);
Vue.component('road-spot', require('./components/map/RoadSpot.vue').default);
Vue.component('spot-actions', require('./components/map/SpotActions.vue').default);
Vue.component('road-spot-actions', require('./components/map/RoadSpotActions.vue').default);

Vue.component('main-player', require('./components/MainPlayer.vue').default);
Vue.component('opponent', require('./components/Opponent.vue').default);
Vue.component('commodity-progress', require('./components/CommodityProgress.vue').default);
Vue.component('progress-card', require('./components/ProgressCard.vue').default);
Vue.component('hand-cards', require('./components/HandCards.vue').default);

Vue.component('map-editor', require('./components/map/editor.vue').default);

const app = new Vue({
    el: '#app',
    router
});
