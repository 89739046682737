var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.player.progress),function(progress,index){return _c('div',{key:index,staticClass:"progress-row"},[_c('div',{staticClass:"progress-row",on:{"click":function($event){return _vm.commodityUpgradeClicked(_vm.player, progress.type)}}},[(!_vm.isOpponent && progress.level != 5)?_c('div',{staticClass:"upgrade-cost",class:'upgrade-cost-' + progress.type},[_vm._v("x"+_vm._s(progress.level+1)+"\n                "),(_vm.flatGraphics)?_c('div',{staticClass:"symbol",class:progress.type}):_c('div',{staticClass:"symbol symbol-progress",class:progress.type+'-2'})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"small-dice dice-boat",class:{'dice-boat-2': ! _vm.flatGraphics},style:({'background-position': '0px ' + -(index)*44 + 'px'})}),_vm._v(" "),_vm._l((6),function(level,index2){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index2 <= progress.level && progress.level != 0),expression:"index2 <= progress.level && progress.level != 0"}],key:index2,staticClass:"small-dice red",style:({'background-position': '0px -' + index2*22 + 'px'})})}),_vm._v(" "),(progress.level != 5 && progress.level == 0)?_c('div',{staticClass:"small-dice empty"}):_vm._e(),_vm._v(" "),(progress.level != 5)?_c('div',{staticClass:"small-dice empty"}):_vm._e(),_vm._v(" "),(_vm.metropolisTopLevel[progress.type] < 4)?_c('div',{staticClass:"symbol metropolis metropolis-level-4",class:'metropolis-' + progress.type}):_vm._e(),_vm._v(" "),(_vm.metropolisTopLevel[progress.type] == 4)?_c('div',{staticClass:"symbol metropolis metropolis-level-5",class:[
                    'metropolis-' + progress.type,
                    { 'opacity-30': _vm.metropolisTopLevel[progress.type] >= 4 }
                ]}):_vm._e(),_vm._v(" "),(progress.level >= 4 && progress.metropolis)?_c('div',{staticClass:"symbol metropolis",class:[
                'metropolis-' + progress.type,
                'metropolis-owner-level-' + progress.level,
                {'metropolis-owner-level-4-unflat': !_vm.flatGraphics},
                {'opponent': _vm.isOpponent}
                ]}):_vm._e()],2)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }