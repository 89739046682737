<template>
    <div class="opponent-wrapper" :class="player.color" :ref="player.color">
        <PlayerInfoBasic class="player-info opponent-player-info" :seafaresExpansion="seafaresExpansion" :player="player" v-if="showGuides" />
        <span class="opponent-points text" :class="player.color">
            <p :class="{ 'highlighted': player.highlightMyPoints }" @click="playerPointsOrCardsClicked(player)">
                {{ player.points }}
            </p>
        </span>
        <Avatar :player="player" style="position: relative; float: left; margin-left: 5px; top: 3px;" />
        <div class="opponent-dice-wrapper" v-if="activePlayer.color == player.color && !['decide starting player', 'initial build'].includes(gamePhase)">
            <div class="dice white" :class="{'rolling upsideDown': dices.whiteDiceRolling}" :style="{'backgroundPosition': '0px ' + -(dices.whiteDiceRoll-1)*28 +'px'}"></div>
            <div class="dice red" :class="{'rolling upsideDown': dices.redDiceRolling}" :style="{'backgroundPosition': '0px ' + -(dices.redDiceRoll-1)*28 +'px'}"></div>
        </div>
        <div class="incoming-outgoing-wrapper">
            <div v-for="(cardObj, index) in player.handCards.slice().reverse()" :key="index" class="opponent-incoming-hand-cards" v-show="player.showIncomingHandCards">
                <template v-if="cardObj.slideIn > 0">
                    <div v-for="(slideIn, index) in cardObj.slideIn" :key="index" v-show="cardObj.slideIn">
                        <div class="hand-card-xs float-left incoming-animation" :class="[cardObj.type, { 'hand-card-xs-2': ! flatGraphics }]"></div>
                    </div>
                </template>
            </div>
            <div v-for="(cardObj, index) in player.handCards.slice().reverse()" :key="index+99" class="opponent-outgoing-hand-cards" v-show="player.showOutgoingHandCards">
                <template v-if="cardObj.slideOut > 0" v-show="!player.ai || !player.showIncomingHandCards">
                    <div v-for="(slideOut, index) in cardObj.slideOut" :key="index+99" v-show="cardObj.slideOut">
                        <div class="hand-card-xs float-left outgoing-animation" :class="[cardObj.type, { 'hand-card-xs-2': ! flatGraphics }]"></div>
                    </div>
                </template>
            </div>
        </div>

        <!-- <div class="opponent-dice-wrapper" v-if="['decide starting player', 'initial build'].includes(gamePhase)" :class="{ 'opacity-30': player.dices.fadeAway }">
            <div class="dice white" :class="{'rolling upsideDown': player.dices.whiteDiceRolling}" :style="{'backgroundPosition': '0px ' + -(player.dices.whiteDiceRoll-1)*28 +'px'}"></div>
            <div class="dice red" :class="{'rolling upsideDown': player.dices.redDiceRolling}" :style="{'backgroundPosition': '0px ' + -(player.dices.redDiceRoll-1)*28 +'px'}"></div>
        </div> -->
        <!-- <br><br>
        <div @click="clickOwnDices(5, 5)">10</div>
        <div @click="clickOwnDices(1, 2)">3</div> -->

        <div class="relative" :style="{ 'left': activePlayer.color == player.color && gamePhase == 'regular' ? '170px' : '86px' }">

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': player.basicProgressCardPlayedThisTurn == false && focusType == 'select goldmine resources' && waitingForPlayer}" v-if="!player.ai">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Selecting goldmine
                </div>
            </div>

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'throw away cards' && waitingForPlayer}" v-if="!player.showOutgoingHandCards">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Throwing cards
                </div>
            </div>

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse':  ['build initial house', 'build second house'].includes(focusType) && waitingForPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Building house
                </div>
            </div>

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'build initial road' && waitingForPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Building road
                </div>
            </div>

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'build 1 boat' && waitingForPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Placing boat
                </div>
            </div>

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'build 1 road' && waitingForPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Placing road
                </div>
            </div>

            <div class="opponent-attention-wrapper" :class="{'opacity-1 pulse': focusType == 'last player select last house' && waitingForPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Selecting last house
                </div>
            </div>

        </div>

        <div class="clear-both"></div>
        <!-- | {{ player.fightFor }} | {{ player.giveUp }} -->
        <small class="opponent-name">{{ player.name }} <span v-if="showPotentialPoints">- {{ player.potential }}</span></small>
        <div class="clear-both"></div>
        <div class="opponent-cards-wrapper" @click="playerPointsOrCardsClicked(player)">
            <div class="opponent-cards-hover">
                <div v-for="(card, index) in handCardsCount" :key="index">
                    <div class="hand-card-opponent back opponent-hand-animation" :class="{ 'highlighted-cards': player.highlightMyHandCards }" :style="{ 'right': index*10 + 'px' }" v-if="index <= renderHandCards"></div>
                </div>
                <!-- class="opponent-stop-rendering-cards" -->
                <div v-if="handCardsCount > renderHandCards"></div>
                <div class="card-count" :class="{ 'safe': allowedHandCardsCount >= handCardsCount }">{{ handCardsCount }}</div>
            </div>
        </div>
        <div class="clear-both"></div>
        <div class="opponent-progress-cards-wrapper" @click="playerPointsOrCardsClicked(player)">
            <div class="opponent-progress-cards-container point-cards-container-opponent">
                <div
                    v-for="(card, index) in player.pointCards"
                    :key="index"
                    class="point-card-opponent"
                    :class="card.label"
                >
                <span v-if="card.label == 'played-knight' && playedKnightsCount >= 3" class="knights-count mono text-gray">{{ playedKnightsCount }}</span>
                </div>
            </div>
            <div class="opponent-progress-cards-container">

            </div>
            <div class="opponent-progress-cards-container">
                <div class="point-card-opponent metropolis-cloth" v-if="player.progress.find(obj => obj.type == 'cloth').metropolis"></div>
                <div class="point-card-opponent metropolis-coin" v-if="player.progress.find(obj => obj.type == 'coin').metropolis"></div>
                <div class="point-card-opponent metropolis-paper" v-if="player.progress.find(obj => obj.type == 'paper').metropolis"></div>
            </div>

            <div class="opponent-progress-cards-container" v-if="longestRoad.length && longestRoad[0].player.color == player.color">
                <div class="point-card-opponent longest-road"></div>
            </div>

            <div class="opponent-progress-cards-container" v-if="largestArmy.player && largestArmy.player.color == player.color">
                <div class="point-card-opponent largest-army"></div>
            </div>

            <div class="opponent-progress-cards-container">
                <div
                    v-for="(card, index) in player.progressCards"
                    :key="index"
                    class="progress-card-opponent back text-center"
                    :class="[
                        card.deck,
                        { 'highlighted-cards': player.highlightMyProgressCards },
                        { 'mr-0': gamePhase == 'game over' },
                    ]"
                    style="line-height: 4px;"
                >
                    <span class="text-gray" style="font-size: 7px; position: relative;" v-if="gamePhase == 'game over'">{{ card.label }}</span>
                </div>
            </div>
        </div>
        <div class="clear-both"></div>
        <div class="clear-both mb-3"></div>
    </div>
</template>

<script>
import CommodityProgress from './CommodityProgress';
import PlayerInfoBasic from './PlayerInfoBasic';
import Avatar from './Avatar';
export default {
    props: ['player', 'waitingForPlayer', 'activePlayer', 'focusType', 'metropolisTopLevel', 'longestRoad', 'largestArmy', 'dices', 'gamePhase', 'showPotentialPoints', 'cities', 'showGuides', 'seafaresExpansion', 'scrollTo', 'flatGraphics'],
    data() {
        return {
            renderHandCards: 24,
        }
    },
    methods: {
        clickDices() {
            this.$emit('clickDices');

            this.$forceUpdate();
        },
        clickOwnDices(whiteNumber, redNumber) {
            this.$emit('clickOwnDices', this.player, whiteNumber, redNumber);

            this.$forceUpdate();
        },
        playerPointsOrCardsClicked(player) {
            this.$emit('playerPointsOrCardsClicked', player);

            this.$forceUpdate();
        },
        becomePlayer(color) {
            this.$emit('becomePlayer', color);

            this.$forceUpdate();
        },
        commodityUpgradeClicked(player, type) {
            this.$emit('commodityUpgradeClicked', player, type);

            this.$forceUpdate();
        },
    },
    components: {
        CommodityProgress,
        PlayerInfoBasic,
        Avatar,
    },
    watch: {
        scrollTo(newValue) {
            if(newValue == 'white') {
                if(this.$refs.white) this.$refs.white.scrollIntoView({ behavior: "smooth" });
            } else if(newValue == 'red') {
                if(this.$refs.red) this.$refs.red.scrollIntoView({ behavior: "smooth" });
            } else if(newValue == 'blue') {
                if(this.$refs.blue) this.$refs.blue.scrollIntoView({ behavior: "smooth" });
            } else if(newValue == 'orange') {
                if(this.$refs.orange) this.$refs.orange.scrollIntoView({ behavior: "smooth" });
            } else if(newValue == 'green') {
                if(this.$refs.green) this.$refs.green.scrollIntoView({ behavior: "smooth" });
            } else if(newValue == 'brown') {
                if(this.$refs.brown) this.$refs.brown.scrollIntoView({ behavior: "smooth" });
            } else if(newValue == 'pink') {
                if(this.$refs.pink) this.$refs.pink.scrollIntoView({ behavior: "smooth" });
            }
        }
    },
    computed: {
        playedKnightsCount() {
            return this.player.pointCards.filter(pointCard => pointCard.label == 'played-knight').length;
        },
        handCardsCount() {
            return this.player.handCards.reduce((numberOfCards, card) => numberOfCards + card.amount, 0);
        },
        allowedHandCardsCount() {
            return 7 + this.cities.filter(city => city.player == this.player && city.citywall == true).length * 2;
        },
    },
}
</script>

<style>

</style>
