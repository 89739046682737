<template>
    <div>
        <div class="container mt-5 select-none">
            <div class="row">
                <!-- <div class="col col-12 col-lg-6 mt-3"> -->
                    <!-- <img src="images/illustrations/city-in-forest.png" width="540" class="mobile-hide"> -->
                <!-- </div> -->
                <div class="col col-12 col-lg-4 mx-auto">
                    <div style="width: 100%; text-align: center;">
                        <span class="quick-game" @click="toggleQuickGame()">
                            <span v-if="!quickGame">Quick game</span>
                            <span v-else>Custom game</span>
                        </span>
                    </div>
                    <!-- checkbox here -->
                    <div v-if="quickGame" class="mb-3">
                        <label class="checkbox-container" style="margin-top: 18px;">Build for me (first 2 houses & roads)
                            <input type="checkbox" v-model="buildForMe">
                            <span class="checkbox-checkmark"></span>
                        </label>
                        <button class="btn btn-lg w-100 mt-3" @click="quickGameBasic()" :disabled="disableBtns">
                            <span>Basic to 10 points</span>
                        </button>
                        <small class="text-coin float-right mt-2 cursor-pointer hover-underline click-green" @click="copyBasicUrl()"><i class="fa fa-link"></i> Copy url</small>
                        <button class="btn btn-lg w-100 mt-3" @click="quickGameCK()" :disabled="disableBtns">
                            <span>C&K to 15 points</span>
                        </button>
                        <small class="text-coin float-right mt-2 cursor-pointer hover-underline click-green" @click="copyCkUrl()"><i class="fa fa-link"></i> Copy url</small>
                        <button class="btn btn-lg w-100 mt-3" @click="quickGameRandom()" :disabled="disableBtns">
                            <span>Random game</span>
                        </button>
                        <small class="text-coin float-right mt-2 cursor-pointer hover-underline click-green" @click="copyRandomUrl()"><i class="fa fa-link"></i> Copy url</small>
                    </div>
                    <form @submit.prevent="startGame()" v-if="!quickGame">
                        <div style="width: 100%; overflow: hidden; height: 220px; margin-bottom: -34px; margin-top: 12px;">
                        <div class="map-preview" @click="updateGames()">
                            <div v-if="form.selectedMapId && mapsList.find(map => map.id == form.selectedMapId)">
                                <PreviewTile
                                    v-for="(tile, key) in JSON.parse(mapsList.find(map => map.id == form.selectedMapId).data)"
                                    :tile="tile"
                                    :key="key"
                                ></PreviewTile>
                            </div>
                        </div>
                        </div>
                        <br>
                        <!-- Joined game table -->
                        <div v-if="userData && joinedGameData != ''  && !joinedGameData.is_host">
                            <table class="joined-game-table">
                                <tr>
                                    <!-- <td>Created by</td>
                                    <td>{{ joinedGameData.created_by }}</td> -->
                                    <td colspan="2" v-if="joinedGameData.created_by">{{ joinedGameData.created_by }}{{ ['s', 'S', 'z', 'Z'].includes(joinedGameData.created_by.slice(-1)) ? '' : 's' }} game</td>
                                </tr>
                                <tr>
                                    <td>Map</td>
                                    <td>{{ joinedGameData.map_name }}</td>
                                </tr>
                                <tr>
                                    <td>Game type</td>
                                    <td>{{ form.gameType == 'ck' ? 'C&K' : 'Basic' }}</td>
                                </tr>
                                <tr>
                                    <td>Points to win</td>
                                    <td><span class="points">{{ form.playToPoints }}</span></td>
                                </tr>
                            </table>
                        </div>
                        <!-- else: game config, dropdowns etc -->
                        <div v-else>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <label class="text-coin p-0">Map</label>
                                </div>
                            </div>
                            <select class="form-control custom-select select-text-coin cursor-pointer" v-model="form.selectedMapId" @change="updateGameConfig()">
                                <!-- <option value="select-map" label="Select map">
                                    Select map
                                </option> -->
                                <option v-for="map in mapsList.filter(map => map.is_official == 1 || (userData && map.user_id == userData.id))" :key="map.id" :value="map.id" :label="map.name + (map.name == recommendedMapName ? ' (recommended)' : '')">
                                    {{ map.name + (map.name == recommendedMapName ? ' (recommended)' : '') }}
                                </option>
                            </select>
                            <i class="fa fa-chevron-down text-coin select-caret"></i>
                            <br>

                            <!-- <label class="text-coin p-0">Game type</label>
                            <select class="form-control custom-select select-text-coin cursor-pointer" v-model="form.gameType" @change="updateGameConfig()">
                                <option value="basic">Basic game</option>
                                <option value="ck">C&K</option>
                            </select>
                            <i class="fa fa-chevron-down text-coin select-caret"></i> -->

                            <label class="text-coin mt-3 p-0">Game type</label><br>

                            <label class="radio-container">Basic game
                                <input type="radio" name="radio-game-type" value="basic" v-model="form.gameType" @change="updateGameConfig()">
                                <span class="checkmark"></span>
                            </label>
                            <label class="radio-container">C&K Expansion
                                <input type="radio" name="radio-game-type" value="ck" v-model="form.gameType" @change="updateGameConfig()">
                                <span class="checkmark"></span>
                            </label>

                            <br>

                            <label class="text-coin mt-3 p-0">Points to win</label><br>

                            <label class="radio-container" v-if="form.gameType == 'basic'">10
                                <input type="radio" name="radio" value="10" v-model="form.playToPoints" @change="updateGameConfig()">
                                <span class="checkmark"></span>
                            </label>
                            <label class="radio-container">13
                                <input type="radio" name="radio" value="13" v-model="form.playToPoints" @change="updateGameConfig()">
                                <span class="checkmark"></span>
                            </label>
                            <label class="radio-container">15
                                <input type="radio" name="radio" value="15" v-model="form.playToPoints" @change="updateGameConfig()">
                                <span class="checkmark"></span>
                            </label>
                            <label class="radio-container" v-if="form.gameType != 'basic'">17
                                <input type="radio" name="radio" value="17" v-model="form.playToPoints" @change="updateGameConfig()">
                                <span class="checkmark"></span>
                            </label>
                        </div>

                        <div v-for="(player, index) in players" :key="index" class="new-game-player-wrapper">
                            <label class="text-coin mt-3 p-0 select-none">Player {{ index+1 }}<small v-if="(userData && player.user_id == userData.id) || (userData == null && index == 0) || (joinedGameData == null && index == 0)">, You</small></label>

                            <div v-if="(userData && player.user_id == userData.id) || (userData == null && index == 0) || (joinedGameData == null && index == 0)">
                                <input type="text" class="form-control" placeholder="Your name" spellcheck="false" autocomplete="off" v-model="player.name" @blur="updateName(player.name)" required>
                            </div>
                            <div v-else class="fake-dropdown" @click="toggleDropdown(index)" :class="{'cursor-pointer': ! userData || joinedGameData == null || (joinedGameData != null && joinedGameData.is_host)}">
                                <div class="fake-dropdown-content">
                                    <span class="text-coin" v-if="player.is_open == false">{{ player.name }}</span><small v-if="player.is_ai" class="text-coin">, bot</small>
                                    <span class="text-coin" :class="{ 'loading-dots': joinedGameData.game && joinedGameData.game[0].id }" v-if="player.is_open">Waiting for player</span>
                                    <i v-if="! userData || joinedGameData == null || (joinedGameData != null && joinedGameData.is_host)" class="fa text-coin select-caret-inside fa-chevron-down rotate-0" :class="{'rotate-180': openPlayerDropdown == index}"></i>
                                </div>
                            </div>
                            <div @click="toggleColorDropdown(index)">
                                <Avatar :player="player" class="new-game-avatar" :class="[{'hover-scale cursor-pointer': player.is_open == false}]" :forceUpdate="forceUpdate" />
                            </div>
                            <div class="change-color-dropdown" v-if="!player.is_ai && player.is_open == false && openPlayerColorDropdown == index">
                                <div
                                    v-for="(color, indexB) in availableColors.filter(color => !players.map(player => player.color).includes(color))"
                                    :key="indexB"
                                    @click="changePlayerColor(index, color, player.id)"
                                >
                                    <Avatar :player="{ color: color, name: player.name, is_open: 0, is_ai: 0 }" class="new-game-select-avatar hover-scale cursor-pointer" :forceUpdate="forceUpdate" />
                                </div>
                            </div>

                            <div class="change-color-dropdown" v-if="player.is_ai && player.name != null && openPlayerColorDropdown == index">
                                <div
                                    v-for="(bot, indexB) in availableBots.filter(bot => bot.name != null).filter(bot => !players.map(player => player.name).includes(bot.name)).filter(bot => !players.map(player => player.color).includes(bot.color))"
                                    :key="indexB"
                                    @click="changeBot(index, bot, player.id)"
                                >
                                    <Avatar :player="bot" class="new-game-select-avatar hover-scale cursor-pointer" :forceUpdate="forceUpdate" />
                                </div>
                            </div>

                            <div class="fake-dropdown-options" v-if="openPlayerDropdown == index">
                                <template v-if="player.user_id == null">
                                    <div @click="openPlayerSlot(index, player.id)" v-if="userData != null && player.is_ai && gameType == 'basic'">Open</div>
                                    <div @click="botOpponent(index, player.id)" v-if="!player.is_ai">Bot opponent</div>
                                    <div @click="deletePlayer(index, player.id)" v-if="players.length > 1">Delete bot</div>
                                </template>
                                <template v-else>
                                    <div @click="kickPlayer(player.id, player.user_id)" v-if="players.length > 1">Kick player</div>
                                </template>
                            </div>
                        </div>

                        <!-- <label class="text-coin mt-3 p-0">Player 2</label>
                        <select class="form-control custom-select text text-bold">
                            <option>Open</option>
                            <option>Bot opponent</option>
                            <option>Delete player</option>
                        </select>
                        <i class="fa fa-chevron-down text-coin select-caret"></i> -->
                        <div class="text-center mt-3">
                            <p class="text-coin uppercase cursor-pointer select-none hover-underline" style="font-weight: 500; display: inline" @click="addPlayer()" v-if="(! userData || joinedGameData == '' || (joinedGameData != '' && joinedGameData.is_host)) && players.length < 6">+ Add bot</p>
                        </div>

                        <div class="text-center mt-3">
                            <p class="text-coin uppercase cursor-pointer select-none hover-underline" style="font-weight: 500; display: inline" @click="leaveGame()" v-if="userData && joinedGameData != ''  && !joinedGameData.is_host">Leave game</p>
                        </div>

                        <!--
                        <br>
                        <label class="text-coin mt-3">Your name</label>
                        <input type="text" class="form-control" placeholder="Your name" spellcheck="false" autocomplete="off" v-model="form.playerName" required>

                        <label class="text-coin mt-3">Your color</label>
                        <select class="form-control custom-select text text-bold" v-model="form.playerColor" :class="[form.playerColor]">
                            <option value="orange">Orange</option>
                            <option value="white">White</option>
                            <option value="red">Red</option>
                            <option value="blue">Blue</option>
                            <option value="green">Green</option>
                            <option value="brown">Brown</option>
                            <option value="pink">Pink</option>
                        </select>
                        <i class="fa fa-chevron-down text-coin select-caret"></i>

                        <label class="text-coin mt-3">Number of AI opponents</label>
                        <select class="form-control custom-select select-text-coin" v-model="form.is_aiOpponents">
                            <option value="2">2 <span v-if="recommendedAiOpponents == 2">(recommended)</span></option>
                            <option value="3">3 <span v-if="recommendedAiOpponents == 3">(recommended)</span></option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                        <i class="fa fa-chevron-down text-coin select-caret"></i>
                        -->

                        <!-- this.players.find(player => player.name == null || player.color == null) || -->
                        <!-- Start game -->

                        <template v-if="(! userData || joinedGameData == '' || (joinedGameData != '' && joinedGameData.is_host))">
                            <!-- is not connected to game -->
                            <template v-if="joinedGameData == ''">
                                <button type="submit" class="btn btn-lg w-100 mt-3"
                                    :disabled="players.length < 3 || disableBtns">
                                    <span v-if="players.filter(player => player.is_open == true).length">Create game</span>
                                    <span v-else>Start game</span>
                                </button>
                            </template>
                            <!-- is connected to game -->
                            <template v-else>
                                <button type="submit" class="btn btn-lg w-100 mt-3"
                                    :disabled="players.filter(player => player.is_open == true).length || players.length < 3 || disableBtns">
                                    <span>Start game</span>
                                </button>
                            </template>
                        </template>

                        <div class="text-center mt-3" v-if="joinedGameData != '' && joinedGameData.is_host">
                            <p
                                class="text-coin uppercase cursor-pointer select-none hover-underline"
                                style="font-weight: 500; display: inline"
                                @click="cancelGame()"
                                v-if="players.length < 6"
                            >
                            Remove game
                            </p>
                        </div>
                    </form>
                    <br>

                    <a v-if="this.userData" href="#" @click.prevent="logout()" class="mono"><u>Sign out</u></a>
                    <a v-if="!this.userData" href="/login" class="mono"><u>Sign in</u></a>
                    <span v-if="this.userData" class="text-coin mx-2">•</span>

                    <span v-if="!this.userData" class="text-coin mx-2">•</span>
                    <a href="/map-editor" target="blank" class="mono"><u>Map editor</u></a>
                    <span class="text-coin mx-2">•</span>
                    <a :href="tutorialUrl" target="blank" class="mono"><u><span v-if="form.gameType == 'ck'">C&K tutorial</span><span v-else>Video tutorial</span></u></a>
                    <br>
                    <div class="my-4"></div>
                    <!-- <br> -->

                    <!-- <div v-if="savedGames.length">
                    <label class="text-coin">Resume saved game</label>
                    <label class="text-coin float-right select-none cursor-pointer spin-on-hover" @click="editMode = !editMode"><i class="fa fa-cog"></i></label>
                    </div>

                    <button
                        v-for="(savedGame, index) in savedGames"
                        :key="index"
                        class="saved-game-wrapper"
                        @click="loadGame(savedGame.id, savedGame.game_config)"
                    >
                        <span class="float-left">{{ savedGame.map_name }}</span>
                        <span class="float-right saved-game-point" v-if="! editMode">{{ JSON.parse(savedGame.game_config).playToPoints }}</span>
                        <span class="float-right" @click="deleteGame(savedGame.id, savedGame.map_name)" v-else><i class="fa fa-times" style="margin-right: 2px"></i></span>
                    </button> -->
                </div>

                <div class="col col-12 col-lg-8 game-tables-container" v-if="userData">

                    <div v-if="userData && openGames.length">
                        <h4 class="mobile-margin">Not started</h4>
                        <div class="hr-gray"></div>
                        <table class="games-table mobile-hide">
                            <thead>
                                <th>created by</th>
                                <th>map</th>
                                <th>game type</th>
                                <th class="text-right">players</th>
                                <th class="text-right">points</th>
                                <th></th>
                            </thead>
                            <tbody>
                                <!-- {{ openGames }} -->
                                <tr v-for="game in openGames" :key="game.id">
                                    <td style="position: relative">
                                        <div v-if="game.user_id == userData.id || userData.access_level == 9" @click="deleteGame(game.id, game.map_name + ' ' + JSON.parse(game.game_config).playToPoints + 'p')" style="position: absolute; left: -20px; cursor: pointer;">
                                            <i class="fa fa-times" style="color: #302E28;"></i>
                                        </div>
                                        {{ game.created_by }}
                                    </td>
                                    <td>{{ game.map_name }}</td>
                                    <td>{{ JSON.parse(game.game_config).gameType == 'ck' ? 'C&K' : 'Basic' }}
                                    </td>
                                    <td class="text-right mono">
                                        <span v-if="game.users.length">
                                            {{ game.users.filter(user => !user.is_open).length }} / {{ game.users.length }}
                                        </span>
                                    </td>
                                    <td class="text-right mono"><div class="points">{{ JSON.parse(game.game_config).playToPoints }}</div></td>
                                    <td>
                                        <div v-if="game.users.length">
                                            <div v-if="game.users.find(user => userData && user.user_id == userData.id)" style="color: #B4F22F;">Joined</div>
                                            <div v-else-if="game.users.length != game.open_spots" class="games-table-btn" @click="joinGame(game.id, game.game_config)">Join</div>
                                            <div v-else class="games-table-btn btn-dark">Full</div>
                                        </div>
                                        <div class="mono" style="width: 100px;" v-else>
                                            <span style="color: #B4F22F;">Starting<div class="animated-dots-wrapper">...</div></span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="row lobby-container-mobile mobile-show" v-for="game in openGames" :key="game.id">
                            <div class="col col-6">Created by</div>
                            <div class="col col-6">{{ game.created_by }}</div>
                            <div class="col col-6">Map</div>
                            <div class="col col-6">{{ game.map_name }}</div>
                            <div class="col col-6">Game type</div>
                            <div class="col col-6">{{ JSON.parse(game.game_config).gameType == 'ck' ? 'C&K' : 'Basic' }}</div>
                            <div class="col col-6">Players</div>
                            <div class="col col-6 mono">
                                <span v-if="game.users.length">
                                    {{ game.users.filter(user => !user.is_open).length }}/{{ game.users.length }}
                                </span>
                            </div>
                            <div class="col col-6">Points</div>
                            <div class="col col-6"><div class="points">{{ JSON.parse(game.game_config).playToPoints }}</div></div>
                            <div class="mt-3 w-100" style="margin: 0px 12px; font-weight: bold;">
                                <div v-if="game.users.length">
                                    <div v-if="game.users.find(user => userData && user.user_id == userData.id)" class="games-table-btn" style="width: 100%;" @click="leaveGame()">Leave game</div>
                                    <div v-else-if="game.users.length != game.open_spots" class="games-table-btn" style="width: 100%;" @click="joinGame(game.id, game.game_config)">Join game</div>
                                    <div v-else class="games-table-btn btn-dark" style="width: 100%;">Game is full</div>
                                </div>
                                <div class="mono text-center" v-else>
                                    <span style="color: #B4F22F;">Game starting<div class="animated-dots-wrapper">...</div></span>
                                </div>
                            </div>
                        </div>
                        <div class="hr-gray mt-2"></div>
                    </div>

                    <div v-if="usersGames.length">
                        <div class="my-4 py-1"></div>
                        <h4 class="mobile-margin mobile-margin">Your games</h4>
                        <div class="hr-gray"></div>
                        <table class="games-table mobile-hide">
                            <thead>
                                <!-- <th>created by</th> -->
                                <th>map</th>
                                <th>game type</th>
                                <th class="text-right">players</th>
                                <th class="text-right">points</th>
                                <th></th>
                            </thead>
                            <tbody>
                                <tr v-for="game in usersGames" :key="game.id">
                                    <td style="position: relative" v-if="userData">
                                        <div v-if="game.user_id == userData.id || userData.access_level == 9" @click="deleteGame(game.id, game.map_name + ' ' + JSON.parse(game.game_config).playToPoints + 'p')" style="position: absolute; left: -20px; cursor: pointer;">
                                            <i class="fa fa-times" style="color: #302E28;"></i>
                                        </div>
                                        {{ game.created_by }}
                                    </td>
                                    <td>{{ game.map_name }}</td>
                                    <td>{{ JSON.parse(game.game_config).gameType == 'ck' ? 'C&K' : 'Basic' }}</td>
                                    <!-- <td class="text-right mono highlight-text">3/4</td> -->
                                    <td class="text-right mono">
                                        {{ JSON.parse(game.game_config).players.length }}
                                    </td>
                                    <td class="text-right mono"><div class="points">{{ JSON.parse(game.game_config).playToPoints }}</div></td>
                                    <td><div class="games-table-btn" @click="loadGame(game.id, game.game_config)">Continue</div></td>
                                    <!-- <td><div class="games-table-btn highlight">Rejoin</div></td> -->
                                </tr>
                            </tbody>
                        </table>

                        <div class="row lobby-container-mobile mobile-show" v-for="game in usersGames" :key="game.id">
                            <div class="col col-6">Created by</div>
                            <div class="col col-6">{{ game.created_by }}</div>
                            <div class="col col-6">Map</div>
                            <div class="col col-6">{{ game.map_name }}</div>
                            <div class="col col-6">Game type</div>
                            <div class="col col-6">{{ JSON.parse(game.game_config).gameType == 'ck' ? 'C&K' : 'Basic' }}</div>
                            <div class="col col-6">Players</div>
                            <div class="col col-6 mono">{{ JSON.parse(game.game_config).players.length }}</div>
                            <div class="col col-6">Points</div>
                            <div class="col col-6"><div class="points">{{ JSON.parse(game.game_config).playToPoints }}</div></div>
                            <div class="mt-3 w-100" style="margin: 0px 12px; font-weight: bold;">
                                <div class="games-table-btn w-100" @click="loadGame(game.id, game.game_config)">Continue</div>
                            </div>
                        </div>
                        <div class="hr-gray mt-2"></div>
                    </div>

                    <div v-if="othersGames.length">
                        <div class="my-4 py-1"></div>
                        <h4 class="live mobile-margin">Live</h4>
                        <div class="hr-gray"></div>
                        <table class="games-table mobile-hide">
                            <thead>
                                <th>created by</th>
                                <th>map</th>
                                <th>game type</th>
                                <th class="text-right">players</th>
                                <th class="text-right">points</th>
                                <th></th>
                            </thead>
                            <tbody>
                                <tr v-for="game in othersGames" :key="game.id">
                                    <td style="position: relative">
                                        <div v-if="game.user_id == userData.id || userData.access_level == 9" @click="deleteGame(game.id, game.map_name + ' ' + JSON.parse(game.game_config).playToPoints + 'p')" style="position: absolute; left: -20px; cursor: pointer;">
                                            <i class="fa fa-times" style="color: #302E28;"></i>
                                        </div>
                                        {{ game.created_by }}
                                    </td>
                                    <td>{{ game.map_name }}</td>
                                    <td>{{ JSON.parse(game.game_config).gameType == 'ck' ? 'C&K' : 'Basic' }}</td>
                                    <!-- <td class="text-right mono highlight-text">3/4</td> -->
                                    <td class="text-right mono">
                                        {{ JSON.parse(game.game_config).players.length }}
                                    </td>
                                    <td class="text-right mono"><div class="points">{{ JSON.parse(game.game_config).playToPoints }}</div></td>
                                    <td>
                                        <div class="games-table-btn" @click="loadGame(game.id, game.game_config)">Watch</div>
                                    </td>
                                    <!-- <td><div class="games-table-btn highlight">Rejoin</div></td> -->
                                </tr>
                            </tbody>
                        </table>
                        <div class="row lobby-container-mobile mobile-show" v-for="game in othersGames" :key="game.id">
                            <div class="col col-6">Created by</div>
                            <div class="col col-6">{{ game.created_by }}</div>
                            <div class="col col-6">Map</div>
                            <div class="col col-6">{{ game.map_name }}</div>
                            <div class="col col-6">Game type</div>
                            <div class="col col-6">{{ JSON.parse(game.game_config).gameType == 'ck' ? 'C&K' : 'Basic' }}</div>
                            <div class="col col-6">Players</div>
                            <div class="col col-6 mono">{{ JSON.parse(game.game_config).players.length }}</div>
                            <div class="col col-6">Points</div>
                            <div class="col col-6"><div class="points">{{ JSON.parse(game.game_config).playToPoints }}</div></div>
                            <div class="mt-3 w-100" style="margin: 0px 12px; font-weight: bold;">
                                <div class="games-table-btn w-100" @click="loadGame(game.id, game.game_config)">Watch</div>
                            </div>
                        </div>
                        <div class="hr-gray mt-2"></div>
                    </div>

                    <div v-if="usersOnline.length">
                        <h4 class="text-coin mt-4">Players online</h4>
                        <span v-for="(user, index) in usersOnline" :key="index" class="text-coin ml-2 mr-1"><span style="color: #B4F22F">• {{ user.username }}</span></span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <img src="images/illustrations/city-in-forest.png" width="100%" class="mobile-show"> -->
    </div>
</template>

<script>
import PreviewTile from './map/PreviewTile';
import router from '../router'

import Avatar from './Avatar';

export default {
    data() {
        return {
            players: [
                { color: 'orange', name: 'Guest', is_ai: 0, user_id: null, is_open: 0 },
                { color: 'red', name: 'Otis', is_ai: 1, user_id: null, is_open: 0  },
                { color: 'blue', name: 'Mio', is_ai: 1, user_id: null, is_open: 0  },
                { color: 'white', name: 'Oliver', is_ai: 1, user_id: null, is_open: 0  },
            ],
            availableColors: ['orange', 'red', 'blue', 'white', 'green', 'brown', 'pink'],
            availableBots: [
                { color: 'red', name: 'Otis', is_ai: 1, user_id: null, is_open: 0 },
                { color: 'blue', name: 'Mio', is_ai: 1, user_id: null, is_open: 0 },
                { color: 'white', name: 'Oliver', is_ai: 1, user_id: null, is_open: 0 },
                { color: 'green', name: 'Eevee', is_ai: 1, user_id: null, is_open: 0 },
                { color: 'orange', name: 'Iimu', is_ai: 1, user_id: null, is_open: 0 },
                { color: 'brown', name: 'Jasu', is_ai: 1, user_id: null, is_open: 0 },
            ],
            openPlayerDropdown: null,
            openPlayerColorDropdown: null,
            mapsList: [],
            openGames: [],
            usersGames: [],
            othersGames: [],
            savedGames: [],
            joinedGameData: [],
            userData: null,
            usersOnline: [],
            editMode: false,
            form: {
                userIds: null,
                // gameConfig: this.players,
                gameType: 'basic',
                selectedMapId: 1,
                // playerName: 'Guest',
                playToPoints: 10,
                // playerColor: 'orange',
                // aiOpponents: 3,
            },
            recommendedMapName: 'Default expansion',
            recommendedAiOpponents: 3,
            tutorialUrl: 'https://youtu.be/NtZREMB8Zts',
            forceUpdate: 0,
            quickGame: false,
            buildForMe: false,
            disableBtns: false,
        }
    },
    beforeMount() {
        axios.get('/v1/maps')
            .then((response) => {
                this.mapsList = response.data;
            }).catch((error) => {
                console.log(error);
            })

        this.getSavedGames();
    },
    async mounted() {
        await this.getUserData();
        await this.getUsersOnline();
        this.showJoinedGame();
        this.getOpenGames();
        this.getUsersGames();
        this.getOthersGames();
        let routeParam = this.$route.query.startGame;

        // http://gotland.test/?startGame=basic
        if(routeParam == 'basic') {
            this.quickGameBasic();

        // http://gotland.test/?startGame=basicAuto
        } else if(routeParam == 'basicAuto') {

            this.buildForMe = 1;
            this.quickGameBasic();
        // http://gotland.test/?startGame=ck
        } else if(routeParam == 'ck') {

            this.quickGameCK();
        // http://gotland.test/?startGame=ckAuto
        } else if(routeParam == 'ckAuto') {

            this.buildForMe = 1;
            this.quickGameCK();
        // http://gotland.test/?startGame=random
        } else if(routeParam == 'ckEndless') {

        this.buildForMe = 1;
        this.quickGameEndless();
        // http://gotland.test/?startGame=random
        } else if(routeParam == 'random') {

            this.quickGameRandom();
        // http://gotland.test/?startGame=quickGameTab
        } else if(routeParam == 'quickGameTab') {

            this.quickGame = 1;
        }
    },
    created() {
        if(window.history && window.history.pushState) {
            $(window).on('popstate', function() {
                router.go()
            });
        }

        window.Echo.channel('update-lobby')
            .listen('UpdateLobby', (e) => {
                let lobbyId = e.gameData;

                this.updateGames();

                if(this.joinedGameData.game && this.joinedGameData.game.length) {
                    if(this.joinedGameData.game[0].id == lobbyId) {
                        this.showJoinedGame();
                    }
                }
            });
        window.Echo.channel('update-users-online')
            .listen('UpdateUsersOnline', (e) => {
                this.getUsersOnline();
            });
        window.Echo.channel('kick-player')
            .listen('KickPlayer', (e) => {

                let kickedUserId = e.userId;
                let gameId = e.gameId;

                if(this.userData != null) {
                    if(this.userData.id == kickedUserId) {
                        if(this.joinedGameData.game[0].id == gameId) {
                            this.leaveGame();
                        }
                    }
                }

                this.updateGames();

                // if(this.joinedGameData.game && this.joinedGameData.game.length) {
                //     if(this.joinedGameData.game[0].id == lobbyId) {
                //         this.showJoinedGame();
                //     }
                // }
            });
        window.addEventListener('beforeunload', function (e) {
            axios.post('/v1/leave-game/')
            .then((response) => {

                axios.post('/v1/reset-activity/')
                .then((response) => {

                }).catch(error => {

                });

            }).catch(error => {

            });

            // e.preventDefault();
            // e.returnValue = '';
        });
        window.Echo.channel('start-game')
            .listen('StartGame', (e) => {

                let gameId = e.gameId;
                let gameType = e.gameType;
                let userIds = e.userIds;

                if(this.userData == null) return;

                if(userIds.indexOf(this.userData.id) !== -1) {
                    // setTimeout(() => {
                        if(gameType == 'ck') {
                            router.push({ name: 'games', params: { gameId: gameId } })
                        } else {
                            router.push({ name: 'basic games', params: { gameId: gameId } })
                        }

                        router.go()
                    // }, 4000);
                }
            });
    },
    methods: {
        toggleQuickGame() {
            this.quickGame = !this.quickGame;
            if(this.quickGame) {
                history.pushState(
                    {},
                    null,
                    this.$route.path + '?startGame=quickGameTab'
                )
            } else {
                history.pushState(
                    {},
                    null,
                    this.$route.path + ''
                )
            }
        },
        async copyBasicUrl() {
            let ext = '?startGame=basic';
            if(this.buildForMe) ext = '?startGame=basicAuto';

            await navigator.clipboard.writeText('https://gotland.ninjami.fi/' + ext);
        },
        async copyCkUrl() {
            let ext = '?startGame=ck';
            if(this.buildForMe) ext = '?startGame=ckAuto';

            await navigator.clipboard.writeText('https://gotland.ninjami.fi/' + ext);
        },
        async copyRandomUrl() {
            let ext = '?startGame=random';

            await navigator.clipboard.writeText('https://gotland.ninjami.fi/' + ext);
        },
        joinGame(gameId, gameConfig) {
            axios.post('/v1/join-game/' + gameId)
                .then((response) => {
                    this.showJoinedGame();
                }).catch((error) => {
                    console.log(error);
                })
        },
        leaveGame() {
            axios.post('/v1/leave-game/')
                .then((response) => {

                    if(this.userData.last_played_map_id != null) this.form.selectedMapId = this.userData.last_played_map_id;
                    if(this.userData.last_played_game_type != null) this.form.gameType = this.userData.last_played_game_type;

                    let user_name = "Guest";
                    let user_id = null;

                    if(this.userData != null) {
                        user_name = this.userData.username;
                        user_id = this.userData.id;
                    }

                    if(this.userData != null) {
                        user_name = this.userData.username;
                    }

                    this.players = [
                        { color: 'orange', name: user_name, is_ai: 0, user_id: user_id, is_open: 0 },
                        { color: 'red', name: 'Otis', is_ai: 1, user_id: null, is_open: 0  },
                        { color: 'blue', name: 'Mio', is_ai: 1, user_id: null, is_open: 0  },
                        { color: 'white', name: 'Oliver', is_ai: 1, user_id: null, is_open: 0  },
                    ];

                    // this.joinedGameData = [];
                    if(this.joinedGameData != '' && this.joinedGameData.game.length) {
                        this.joinedGameData.is_host = 1;
                    }

                }).catch((error) => {
                    console.log(error);
                })
        },
        showJoinedGame() {
            if(! this.userData) return;

            axios.get('/v1/show-joined-game')
                .then((response) => {
                    if(response.data == '') return this.joinedGameData = '';

                    this.joinedGameData = response.data;

                    this.players = response.data.joined_players;

                    // set game data to loaded values
                    if(this.joinedGameData.game.length) {

                        this.form.gameType = JSON.parse(this.joinedGameData.game[0].game_config).gameType;

                        this.form.selectedMapId = JSON.parse(this.joinedGameData.game[0].game_config).selectedMapId;

                        this.form.playToPoints = JSON.parse(this.joinedGameData.game[0].game_config).playToPoints;

                    }
                }).catch((error) => {
                    this.leaveGame();
                    // console.log(error);
                })
        },
        cancelGame() {
            if(confirm('Delete your game and disconnect all the connected players?')) {
                axios.post('/v1/cancel-game/')
                    .then((response) => {
                        let user_name = "Guest";
                        let user_id = null;

                        if(this.userData != null) {
                            user_name = this.userData.username;
                            user_id = this.userData.id;
                        }

                        this.players = [
                            { color: 'orange', name: user_name, is_ai: 0, user_id: user_id, is_open: 0 },
                            { color: 'red', name: 'Otis', is_ai: 1, user_id: null, is_open: 0  },
                            { color: 'blue', name: 'Mio', is_ai: 1, user_id: null, is_open: 0  },
                            { color: 'white', name: 'Oliver', is_ai: 1, user_id: null, is_open: 0  },
                        ];

                        this.joinedGameData = '';
                    }).catch((error) => {
                        console.log(error);
                    })
            }
        },
        addPlayer() {
            let availableBotsCopy = [
                { color: 'red', name: 'Otis', is_ai: 1, user_id: null, is_open: 0 },
                { color: 'blue', name: 'Mio', is_ai: 1, user_id: null, is_open: 0 },
                { color: 'white', name: 'Oliver', is_ai: 1, user_id: null, is_open: 0 },
                { color: 'green', name: 'Eevee', is_ai: 1, user_id: null, is_open: 0 },
                { color: 'orange', name: 'Iimu', is_ai: 1, user_id: null, is_open: 0 },
                { color: 'brown', name: 'Jasu', is_ai: 1, user_id: null, is_open: 0 },
            ];

            if(this.players.length >= 6) return;

            let bots = availableBotsCopy.filter(bot => !this.players.map(player => player.color).includes(bot.color) && !this.players.map(player => player.name).includes(bot.name));
            if(! bots.length) return;

            let playerData = null;
            if(this.players.length > 1 && this.players[this.players.length-1].name == null) {
                playerData = { user_id: null, color: null, name: null, is_ai: 0, is_open: 1 };
                this.players.push(playerData);
            } else {
                playerData = bots[0];
                this.players.push(playerData);
            }

            if(this.joinedGameData.game && this.joinedGameData.game[0].id) {
                axios.post('/v1/add-player/' + this.joinedGameData.game[0].id, {player_data: playerData})
                .then((response) => {
                    this.players[this.players.length-1].id = response.data;
                    }).catch((error) => {
                        console.log(error);
                })
            }
        },
        toggleDropdown(index) {
            if(this.userData) {
                if(this.joinedGameData != '' && !this.joinedGameData.is_host) return;
            }
            this.openPlayerColorDropdown = null;

            if(index == this.openPlayerDropdown) return this.closeMenus();

            this.openPlayerDropdown = index;
        },
        toggleColorDropdown(index) {
            if(this.userData) {
                if(this.joinedGameData != '' && !this.joinedGameData.is_host) {
                    if(this.players[index].user_id != this.userData.id) return;
                }
            }
            this.openPlayerDropdown = null;

            if(index == this.openPlayerColorDropdown) return this.closeMenus();

            this.openPlayerColorDropdown = index;
        },
        changePlayerColor(index, color, rowId) {
            this.players[index].color = color;

            this.closeMenus();

            if(this.joinedGameData.game && this.joinedGameData.game[0].id) {
                axios.post('/v1/update-player/' + rowId, {player_data: this.players[index]})
                .then((response) => {

                }).catch((error) => {
                    console.log(error);
                })
            }
        },

        changeBot(index, bot, rowId) {
            this.players[index].name = bot.name;
            this.players[index].color = bot.color;

            this.closeMenus();

            if(this.joinedGameData.game && this.joinedGameData.game[0].id) {
                axios.post('/v1/update-player/' + rowId, {player_data: this.players[index]})
                .then((response) => {

                }).catch((error) => {
                    console.log(error);
                })
            }
        },
        deletePlayer(index, row_id) {
            this.players.splice(index, 1);

            this.closeMenus();

            if(! this.userData) return;

            if(! row_id) return;

            axios.post(`/v1/delete-player/${row_id}`)
                .then((response) => {

                }).catch((error) => {
                    // console.log(error);
                })

        },
        kickPlayer(row_id, user_id) {
            axios.post(`/v1/kick-player/${row_id}/${user_id}`)
                .then((response) => {
                    this.closeMenus();
                }).catch((error) => {
                    console.log(error);
                })
        },
        updateGameConfig() {
            // console.log(this.form.selectedMapId);
            if(this.joinedGameData.game && this.joinedGameData.game[0].id) {
                if(this.joinedGameData.is_host) {
                    axios.post(`/v1/update-game-config/${this.joinedGameData.game[0].id}`, { form: this.form })
                        .then((response) => {

                        }).catch((error) => {
                            console.log(error);
                        })
                }
            }
        },
        updateName(name) {
            if(this.userData == null) return;
            axios.post(`/v1/update-name/`, {name: name})
                .then((response) => {

                }).catch((error) => {
                    console.log(error);
                })
        },
        openPlayerSlot(index, rowId) {
            let playerData = { user_id: null, name: null, color: null, is_ai: 0, is_open: 1 };
            this.players[index].name = playerData.name;
            this.players[index].color = playerData.color;
            this.players[index].is_open = playerData.is_open;
            this.players[index].is_ai = playerData.is_ai;
            this.players[index].user_id = playerData.user_id;

            this.closeMenus();

            this.forceUpdate++;

            if(this.joinedGameData.game && this.joinedGameData.game[0].id) {
                axios.post('/v1/update-player/' + rowId, {player_data: playerData})
                .then((response) => {

                }).catch((error) => {
                    console.log(error);
                })
            }
        },
        closeMenus() {
            this.openPlayerDropdown = null;
            this.openPlayerColorDropdown = null;
        },
        botOpponent(index, rowId) {
            let availableBotsCopy = [
                { color: 'red', name: 'Otis', is_ai: 1, user_id: null, is_open: 0 },
                { color: 'blue', name: 'Mio', is_ai: 1, user_id: null, is_open: 0 },
                { color: 'white', name: 'Oliver', is_ai: 1, user_id: null, is_open: 0 },
                { color: 'green', name: 'Eevee', is_ai: 1, user_id: null, is_open: 0 },
                { color: 'orange', name: 'Iimu', is_ai: 1, user_id: null, is_open: 0 },
                { color: 'brown', name: 'Jasu', is_ai: 1, user_id: null, is_open: 0 },
            ];

            let bots = availableBotsCopy.filter(bot => !this.players.map(player => player.color).includes(bot.color) && !this.players.map(player => player.name).includes(bot.name));
            if(! bots.length) return;

            this.players[index].name = bots[0].name;
            this.players[index].color = bots[0].color;
            this.players[index].is_open = bots[0].is_open;
            this.players[index].is_ai = bots[0].is_ai;
            this.players[index].user_id = bots[0].user_id;

            this.closeMenus();

            if(this.joinedGameData.game && this.joinedGameData.game[0].id) {
                axios.post('/v1/update-player/' + rowId, {player_data: bots[0]})
                .then((response) => {

                }).catch((error) => {
                    console.log(error);
                })
            }
        },
        getOpenGames() {
            axios.get('/v1/open-games')
            .then((response) => {
                this.openGames = response.data;
            }).catch((error) => {
                console.log(error);
            })
        },
        getUsersGames() {
            axios.get('/v1/users-games')
            .then((response) => {
                this.usersGames = response.data;
            }).catch((error) => {
                console.log(error);
            })
        },
        getOthersGames() {
            axios.get('/v1/others-games')
            .then((response) => {
                this.othersGames = response.data;
            }).catch((error) => {
                console.log(error);
            })
        },
        getSavedGames() {
            axios.get('/v1/saved-games')
            .then((response) => {
                this.savedGames = response.data;
            }).catch((error) => {
                console.log(error);
            })
        },
        updateGames() {
            this.getOpenGames();
            this.getUsersGames();
            this.getOthersGames();
        },
        logout() {
            axios.post('/v1/leave-game/')
            .then((response) => {

                axios.post('/v1/reset-activity/')
                .then((response) => {

                    axios.post('/logout')
                    .then((response) => {
                        window.location.href = "/login"
                    }).catch(error => {

                    });

                }).catch(error => {

                });

            }).catch(error => {

            });
        },
        async getUsersOnline() {
            await axios.get('/v1/users-online')
            .then((response) => {
                this.usersOnline = response.data;
            }).catch((error) => {
                // console.log(error);
            })
        },
        async getUserData() {
            await axios.get('/v1/user')
            .then((response) => {
                this.userData = response.data[0];

                if(this.joinedGameData == '') {
                    this.players[0].name = this.userData.username;
                    this.players[0].user_id = this.userData.id;
                }
            }).catch((error) => {
                // console.log(error);
            })

            if(this.userData != null) {
                // if(this.userData.username != null) this.form.playerName = this.userData.username;

                // if(this.userData.last_game_config != null) this.players = JSON.parse(this.userData.last_game_config);
                if(this.userData.last_played_map_id != null) this.form.selectedMapId = this.userData.last_played_map_id;
                if(this.userData.last_played_game_type != null) this.form.gameType = this.userData.last_played_game_type;

                // if(this.userData.color != null) this.form.playerColor = this.userData.color;
            }
        },
        disableAllBtns() {
            this.disableBtns = true;

            setTimeout(() => {
                this.disableBtns = false;
            }, 500);
        },
        quickGameBasic() {
            this.disableAllBtns();

            let players = [
                { color: 'orange', name: 'Guest', is_ai: 0, ai: 0, user_id: null, is_open: 0 },
                { color: 'red', name: 'Otis', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                { color: 'white', name: 'Oliver', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
            ];

            axios.post('/v1/start-game/', {form: {
                autoBuild: this.buildForMe,
                userIds: null,
                gameType: 'basic',
                players: players,
                seafaresExpansion: 0,
                selectedMapId: 3,
                playToPoints: 10,
                userIds: '',
            }, players: players
        })
                .then((response) => {
                    router.push({ name: 'basic games', params: { gameId: response.data.game_id } })

                    router.go()
                }).catch((error) => {
                    console.log(error);
                })
        },
        quickGameCK() {
            this.disableAllBtns();

            let players = [
                { color: 'orange', name: 'Guest', is_ai: 0, ai: 0, user_id: null, is_open: 0 },
                { color: 'red', name: 'Otis', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                { color: 'blue', name: 'Mio', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                { color: 'white', name: 'Oliver', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
            ];

            axios.post('/v1/start-game/', {form: {
                autoBuild: this.buildForMe,
                userIds: null,
                gameType: 'ck',
                players: players,
                seafaresExpansion: 0,
                selectedMapId: 1,
                playToPoints: 15,
                userIds: '',
            }, players: players
        })
                .then((response) => {
                    router.push({ name: 'games', params: { gameId: response.data.game_id } })

                    router.go()
                }).catch((error) => {
                    console.log(error);
                })
        },
        quickGameEndless() {
            this.disableAllBtns();

            let players = [
                { color: 'orange', name: 'Guest', is_ai: 0, ai: 0, user_id: null, is_open: 0 },
                { color: 'red', name: 'Otis', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                { color: 'blue', name: 'Mio', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                { color: 'white', name: 'Oliver', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
            ];

            axios.post('/v1/start-game/', {form: {
                autoBuild: 0,
                userIds: null,
                gameType: 'ck',
                players: players,
                seafaresExpansion: 0,
                selectedMapId: 1,
                playToPoints: 999,
                userIds: '',
            }, players: players
        })
                .then((response) => {
                    router.push({ name: 'games', params: { gameId: response.data.game_id } })

                    router.go()
                }).catch((error) => {
                    console.log(error);
                })
        },
        quickGameRandom() {
            this.disableAllBtns();

            let players = [
                { color: 'orange', name: 'Guest', is_ai: 0, ai: 0, user_id: null, is_open: 0 },
                { color: 'red', name: 'Otis', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                { color: 'blue', name: 'Mio', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
            ];

            let extraPlayersOptions = [0, 1, 2, 3];
            let extraPlayers = extraPlayersOptions[Math.floor((Math.random()*extraPlayersOptions.length))];

            if(extraPlayers == 3) {
                players = [
                    { color: 'orange', name: 'Guest', is_ai: 0, ai: 0, user_id: null, is_open: 0 },
                    { color: 'red', name: 'Otis', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                    { color: 'blue', name: 'Mio', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                    { color: 'white', name: 'Oliver', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                    { color: 'green', name: 'Eevee', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                    { color: 'pink', name: 'Iimu', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                ];
            } else if(extraPlayers == 2) {
                players = [
                    { color: 'orange', name: 'Guest', is_ai: 0, ai: 0, user_id: null, is_open: 0 },
                    { color: 'red', name: 'Otis', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                    { color: 'blue', name: 'Mio', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                    { color: 'white', name: 'Oliver', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                    { color: 'green', name: 'Eevee', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                ];
            } else if(extraPlayers == 1) {
                players = [
                    { color: 'orange', name: 'Guest', is_ai: 0, ai: 0, user_id: null, is_open: 0 },
                    { color: 'red', name: 'Otis', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                    { color: 'blue', name: 'Mio', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                    { color: 'white', name: 'Oliver', is_ai: 1, ai: 1, user_id: null, is_open: 0 },
                ];
            }

            let randomMap = this.mapsList[Math.floor((Math.random()*this.mapsList.length))];
            let gameType = Math.random() > 0.5 ? 'basic' : 'ck';

            let playToPoints = 10;

            if(gameType == 'basic') {
                let pointOptions = [10, 13, 15];
                playToPoints = pointOptions[Math.floor((Math.random()*pointOptions.length))];
            } else {
                let pointOptions = [13, 15, 17];
                playToPoints = pointOptions[Math.floor((Math.random()*pointOptions.length))];
            }

            axios.post('/v1/start-game/', {form: {
                autoBuild: 0,
                userIds: null,
                gameType: gameType,
                players: players,
                seafaresExpansion: randomMap.seafares_expansion,
                selectedMapId: randomMap.id,
                playToPoints: playToPoints,
                userIds: '',
            }, players: players
        })
                .then((response) => {
                    router.push({ name: 'games', params: { gameId: response.data.game_id } })

                    router.go()
                }).catch((error) => {
                    console.log(error);
                })
        },

        startGame() {
            if(this.players.length < 3) return;

            if(this.userData) {
                if(this.joinedGameData != '' && !this.joinedGameData.is_host) return;
            }

            this.disableAllBtns();

            this.form.seafaresExpansion = this.seafaresExpansion;

            this.form.players = this.players;

            this.form.userIds = this.players.filter(player => player.user_id).map(player => player.user_id).toString();

            this.players.forEach(player => {
                player.ai = player.is_ai;
            });

            // create game but don't start it, waiting for players to join
            if(this.players.find(player => player.name == null || player.color == null)) {
                axios.post('/v1/create-game/', { form: this.form, players: this.players })
                    .then((response) => {
                        this.showJoinedGame();
                    }).catch((error) => {
                        console.log(error);
                    })
                return;
            }

            // return console.log(this.players);
            // return console.log(this.form);

            axios.post('/v1/start-game/', {form: this.form, players: this.players})
                .then((response) => {
                    if(this.form.gameType == 'ck') {
                        router.push({ name: 'games', params: { gameId: response.data.game_id } })
                    } else {
                        router.push({ name: 'basic games', params: { gameId: response.data.game_id } })
                    }

                    router.go()
                }).catch((error) => {
                    console.log(error);
                })
        },
        loadGame(gameId, gameConfig) {
            let gameType = JSON.parse(gameConfig).gameType;
            if(this.editMode) return;

            axios.get(`/v1/load-game/${gameId}`)
            .then((response) => {
                if(gameType == 'ck') {
                    router.push({ name: 'games', params: { gameId: gameId } })
                } else {
                    router.push({ name: 'basic games', params: { gameId: gameId } })
                }
                router.go()
            }).catch((error) => {
                console.log(error);
            })
        },
        deleteGame(gameId, gameName) {
            if(! confirm(`Delete game ${gameName}?`)) return;

            axios.post(`/v1/delete-game/${gameId}`)
            .then((response) => {
                this.getSavedGames();
            }).catch((error) => {
                console.log(error);
            })
        },
    },
    computed: {
        seafaresExpansion() {
            return this.mapsList.find(obj => obj.id == this.form.selectedMapId).seafares_expansion;
        },
        gameType() {
            return this.form.gameType;
        },
    },
    watch: {
        gameType() {
            if(this.gameType == 'ck') {

                let user_name = "Guest";
                    let user_id = null;

                if(this.userData != null) {
                    user_name = this.userData.username;
                    user_id = this.userData.id;
                }

                if(this.players.find(player => player.is_open)) {
                    this.players = [
                        { color: 'orange', name: user_name, is_ai: 0, user_id: user_id, is_open: 0 },
                        { color: 'red', name: 'Otis', is_ai: 1, user_id: null, is_open: 0  },
                        { color: 'blue', name: 'Mio', is_ai: 1, user_id: null, is_open: 0  },
                        { color: 'white', name: 'Oliver', is_ai: 1, user_id: null, is_open: 0  },
                    ];
                }

                if(this.form.playToPoints == "10") {
                    this.form.playToPoints = "15";
                    setTimeout(() => {
                        this.updateGameConfig();
                    }, 100);
                }
                this.recommendedMapName = 'Default expansion';
                this.recommendedAiOpponents = 3;
                this.tutorialUrl = 'https://youtu.be/I6CktzIdErU';
            } else {
                if(this.form.playToPoints == "17") {
                    this.form.playToPoints = "10";
                    setTimeout(() => {
                        this.updateGameConfig();
                    }, 100);
                }
                this.recommendedMapName = 'Default expansion';
                this.recommendedAiOpponents = 3;
                this.tutorialUrl = 'https://youtu.be/NtZREMB8Zts';
                // this.recommendedMapName = 'Default island';
            }
        }
    },
    components: {
        PreviewTile,
        Avatar,
    }
}
</script>

<style>

</style>
