<template>
    <div>
        <div :class="player.color" class="road" style="top: 20px; left: 30px;"></div>
        <span class="total" style="top: 6px; right: 185px; text-align: right;" v-if="seafaresExpansion">boats {{ player.pieces.boats }}<br>roads {{ player.pieces.roads }}</span>
        <span class="total" style="top: 20px; right: 185px;" v-if="!seafaresExpansion">{{ player.pieces.roads }}</span>
        <House :color="player.color" style="top: 26px; left: 80px;" />
        <span class="total" style="top: 20px; right: 139px;">{{ player.pieces.houses }}</span>
        <City :color="player.color" style="top: 14px; left: 124px;" />
        <span class="total" style="top: 20px; right: 77px;">{{ player.pieces.cities }}</span>
        <div :class="player.color" class="road longest" style="top: 20px; left: 205px;"></div>
        <span class="total" style="top: 20px; right: 10px;">{{ player.roadLength }}</span>
    </div>
</template>

<script>
import City from './pieces/City';
import House from './pieces/House';

export default {
    props: ['player', 'seafaresExpansion'],
    data() {
        return {

        }
    },
    methods: {
        
    },
    components: {
        City,
        House,
    },
    computed: {
        
    },
}
</script>
