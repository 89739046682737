<template>
    <div>
        <div class="editor-tiles-wrapper">
            <EditorTile
                v-for="(tile, key) in tiles"
                :tile="tile"
                :activeTile="activeTile"
                :key="key"
                :showCoordinates="0"
                @tileClicked="tileClicked"
            ></EditorTile>
        </div>
        <div class="map-editor-nav-wrapper">
            <div class="editor-numbers-wrapper">
                <div v-for="(number, key) in ['?', 2, 3, 4, 5, 6, 8, 9, 10, 11, 12]"
                    :class="[
                        {'red-number': [6, 8].includes(number)},
                        {'text-small': [2, 12].includes(number)},
                        {'text-bold': ['?'].includes(number)},
                        {'active-map-editor-number': number == activeNumber}
                    ]"
                    :key="key+200" class="map-editor-number"
                    @click="setActiveNumber(number)">
                    {{ number }}
                </div>
            </div>
            <div v-for="(tile, key) in navTiles" :key="key+100">
                <div class="tile map-editor-nav-tile" :class="[tile.type, tile.type+'-rotated', { 'nav-tile-x-offset-left': key%3 == 1 }, { 'nav-tile-x-offset-right': key%3 == 2 }]" @click="setActiveTile(tile)">
                    <div v-if="tile.type == 'random-harbor'">
                        <div class="symbol harbor-lumber" style="width: 14px; height: 14px; top: 6px; left: 34px; position: absolute; transform: rotate(-30deg)"></div>
                        <div class="symbol harbor-brick" style="width: 14px; height: 14px; top: 15px; left: 41px; position: absolute; transform: rotate(-30deg)"></div>
                        <div class="symbol harbor-wool" style="width: 14px; height: 14px; top: 21px; left: 49px; position: absolute; transform: rotate(-30deg)"></div>
                        <div class="symbol harbor-grain" style="width: 14px; height: 14px; top: 25px; left: 60px; position: absolute; transform: rotate(-30deg)"></div>
                        <div class="symbol harbor-ore" style="width: 14px; height: 14px; top: 26px; left: 72px; position: absolute; transform: rotate(-30deg)"></div>
                    </div>
                    <div v-if="tile.group == 'harbor' && tile.type != 'random-harbor'" style="position: absolute; z-index: 999999; transform: rotate(-30deg) scale(0.7); top: 29px; left: -11px;">
                        <!-- {{ tile.direction }} -->
                        <!-- :fill="harborColors[tile.type]" :stroke="harborThirdnaryColors[tile.type]" -->

                        <div v-if="! ['harbor-all', 'harbor-ore'].includes(tile.type)" style="width: 33px; height: 33px; left: 73px; top: 31px; position: absolute; border-radius: 50%;" :style="{transform: 'rotate('+ -(tile.direction*60)+'deg)', 'background-color': '#314150'}">
                            <div class="symbol" :class="tile.type" style="width: 26px; height: 26px; margin-top: 3px;"></div>
                        </div>
                        <div v-else-if="tile.type == 'harbor-ore'" style="width: 33px; height: 33px; left: 73px; top: 31px; position: absolute; border-radius: 50%;" :style="{transform: 'rotate('+ -(tile.direction*60)+'deg)', 'background-color': '#314150'}">
                            <div class="symbol" :class="tile.type" style="width: 26px; height: 26px; margin-top: 3px;"></div>
                        </div>
                        <div v-else style="width: 33px; height: 33px; left: 73px; top: 31px; position: absolute; border-radius: 50%;" :style="{transform: 'rotate('+ -(tile.direction*60)+'deg)', 'background-color': '#314150'}">
                            <small style="color: #AC9A76; font-weight: 500; position: absolute; top: 10px; left: 11px; font-size: 9px; letter-spacing: 0.5px;">3:1</small>
                        </div>
                    </div>

                    <!-- 148x166 -->
                    <svg v-if="tile.svg" width="100" height="112" class="editor-tile-svg" viewBox="0 0 148 166" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path v-if="tile.type" d="M77.5 21.1754L133.586 53.5566C135.752 54.8071 137.086 57.1179 137.086 59.6188V124.381C137.086 126.882 135.752 129.193 133.586 130.443L77.5 162.825C75.3342 164.075 72.6658 164.075 70.5 162.825L14.4141 130.443C12.2483 129.193 10.9141 126.882 10.9141 124.381V59.6188C10.9141 57.1179 12.2483 54.8071 14.4141 53.5566L70.5 21.1754C72.6658 19.925 75.3342 19.925 77.5 21.1754Z" :fill="tileColors[tile.type] || '#374958'" stroke="#383832" stroke-width="2"/>
                        <path v-if="tile.type == 'goldmine'" d="M77.5 21.1754L133.586 53.5566C135.752 54.8071 137.086 57.1179 137.086 59.6188V124.381C137.086 126.882 135.752 129.193 133.586 130.443L77.5 162.825C75.3342 164.075 72.6658 164.075 70.5 162.825L14.4141 130.443C12.2483 129.193 10.9141 126.882 10.9141 124.381V59.6188C10.9141 57.1179 12.2483 54.8071 14.4141 53.5566L70.5 21.1754C72.6658 19.925 75.3342 19.925 77.5 21.1754Z" fill="#FFF" stroke="#383832" stroke-width="2"/>
                        <path v-if="tile.type == 'goldmine'" d="M77.5 21.1754L133.586 53.5566C135.752 54.8071 137.086 57.1179 137.086 59.6188V124.381C137.086 126.882 135.752 129.193 133.586 130.443L77.5 162.825C75.3342 164.075 72.6658 164.075 70.5 162.825L14.4141 130.443C12.2483 129.193 10.9141 126.882 10.9141 124.381V59.6188C10.9141 57.1179 12.2483 54.8071 14.4141 53.5566L70.5 21.1754C72.6658 19.925 75.3342 19.925 77.5 21.1754Z" fill="url(#paint0_linear)" stroke="#383832" stroke-width="2"/>
                        <!-- <template v-if="tile.group == 'harbor' && tile.type != 'harbor-all'">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M73.5599 20.3985C75.128 20.2973 76.7189 20.658 78.1215 21.4802L133.36 53.8594C134.28 54.3991 135.068 55.1071 135.692 55.9317L125.773 61.6583L73.6088 30.9828L73.5599 20.3985Z" :fill="harborColors[tile.type]"/>
                            <path d="M73.5599 20.3985L73.4955 19.4006L72.5555 19.4612L72.5599 20.4031L73.5599 20.3985ZM78.1215 21.4802L77.6158 22.3429L77.6158 22.3429L78.1215 21.4802ZM133.36 53.8594L132.854 54.7221L132.854 54.7221L133.36 53.8594ZM135.692 55.9317L136.192 56.7977L137.173 56.2313L136.489 55.3281L135.692 55.9317ZM125.773 61.6583L125.266 62.5203L125.769 62.8157L126.273 62.5244L125.773 61.6583ZM73.6088 30.9828L72.6088 30.9874L72.6115 31.5563L73.1019 31.8448L73.6088 30.9828ZM73.6243 21.3964C74.9967 21.3078 76.3886 21.6235 77.6158 22.3429L78.6272 20.6175C77.0493 19.6925 75.2593 19.2867 73.4955 19.4006L73.6243 21.3964ZM77.6158 22.3429L132.854 54.7221L133.865 52.9966L78.6272 20.6175L77.6158 22.3429ZM132.854 54.7221C133.66 55.1944 134.349 55.8138 134.895 56.5353L136.489 55.3281C135.787 54.4004 134.901 53.6037 133.865 52.9966L132.854 54.7221ZM126.273 62.5244L136.192 56.7977L135.192 55.0657L125.273 60.7923L126.273 62.5244ZM73.1019 31.8448L125.266 62.5203L126.28 60.7963L74.1157 30.1207L73.1019 31.8448ZM72.5599 20.4031L72.6088 30.9874L74.6088 30.9781L74.5599 20.3939L72.5599 20.4031Z" fill="#383832"/>

                            <path fill-rule="evenodd" clip-rule="evenodd" d="M104.633 40.4592L104.633 46.2208L109.622 43.34L104.633 40.4592ZM98.2949 36.8L98.2949 42.5616L103.285 39.6808L98.2949 36.8Z" :fill="harborSecondaryColors[tile.type]" />
                        </template>
                        <template v-if="tile.type == 'harbor-all'">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M73.5599 20.3985C75.128 20.2973 76.7189 20.658 78.1215 21.4802L133.36 53.8594C134.28 54.3991 135.068 55.1071 135.692 55.9317L125.773 61.6583L73.6088 30.9828L73.5599 20.3985Z" fill="#FFF"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M73.5599 20.3985C75.128 20.2973 76.7189 20.658 78.1215 21.4802L133.36 53.8594C134.28 54.3991 135.068 55.1071 135.692 55.9317L125.773 61.6583L73.6088 30.9828L73.5599 20.3985Z" fill="url(#paint0_linear)"/>
                            <path d="M73.5599 20.3985L73.4955 19.4006L72.5555 19.4612L72.5599 20.4031L73.5599 20.3985ZM78.1215 21.4802L77.6158 22.3429L77.6158 22.3429L78.1215 21.4802ZM133.36 53.8594L132.854 54.7221L132.854 54.7221L133.36 53.8594ZM135.692 55.9317L136.192 56.7977L137.173 56.2313L136.489 55.3281L135.692 55.9317ZM125.773 61.6583L125.266 62.5203L125.769 62.8157L126.273 62.5244L125.773 61.6583ZM73.6088 30.9828L72.6088 30.9874L72.6115 31.5563L73.1019 31.8448L73.6088 30.9828ZM73.6243 21.3964C74.9967 21.3078 76.3886 21.6235 77.6158 22.3429L78.6272 20.6175C77.0493 19.6925 75.2593 19.2867 73.4955 19.4006L73.6243 21.3964ZM77.6158 22.3429L132.854 54.7221L133.865 52.9966L78.6272 20.6175L77.6158 22.3429ZM132.854 54.7221C133.66 55.1944 134.349 55.8138 134.895 56.5353L136.489 55.3281C135.787 54.4004 134.901 53.6037 133.865 52.9966L132.854 54.7221ZM126.273 62.5244L136.192 56.7977L135.192 55.0657L125.273 60.7923L126.273 62.5244ZM73.1019 31.8448L125.266 62.5203L126.28 60.7963L74.1157 30.1207L73.1019 31.8448ZM72.5599 20.4031L72.6088 30.9874L74.6088 30.9781L74.5599 20.3939L72.5599 20.4031Z" fill="#383832"/>

                            <path fill-rule="evenodd" clip-rule="evenodd" d="M106.648 41.5143L101.658 44.3951L101.658 38.6335L106.648 41.5143ZM95.3206 40.736L95.3206 34.9744L100.31 37.8552L95.3206 40.736ZM113 45.144L108.01 42.2632L108.01 48.0248L113 45.144Z" :fill="harborSecondaryColors[tile.type]"/>
                        </template> -->
                        <defs>
                        <linearGradient id="paint0_linear" x1="110.5" y1="153.5" x2="36.5" y2="32.5" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#B09844"/>
                        <stop offset="1" stop-color="#F5E9BB"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear" x1="73" y1="14.5" x2="128.5" y2="68.5" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#B69F4F"/>
                        <stop offset="1" stop-color="#F1E4B3"/>
                        </linearGradient>
                        </defs>
                    </svg>

                    <div class="nav-tile-label" :class="{'bg-gray': ['random-resource', 'random-resource-no-desert', 'random-resource-gold'].includes(tile.type)}" :style="{ color: tile.labelColor }">{{ tile.label }}</div>

                    <i v-if="tile.type == 'hidden-tile' && activeTile.type != 'hidden-tile'" class="fa fa-eye-slash" :style="{ color: tile.labelColor }" style="transform: rotate(-30deg); margin-top: 43px;"></i>
                    <i v-if="tile.type == 'artist' && activeTile.type != 'artist'" class="fa fa-paint-brush" :style="{ color: tile.labelColor }" style="transform: rotate(-30deg); margin-top: 43px;"></i>
                    <img v-if="tile.type == 'build-blocked' && activeTile.type != 'build-blocked'" src="/images/symbols/build-blocked.png" width="28" style="transform: rotate(-30deg); margin-top: 38px;">
                    <div class="map-editor-active-tile" v-if="tile.type == activeTile.type" :style="{ borderColor: tile.labelColor }"></div>
                </div>
            </div>

            <div class="save-map-wrapper">
                <div v-if="userData">
                    <div v-if="mapsList.filter(map => !map.is_official).length">
                        <select class="form-control custom-select select-text-coin" v-model="loadedMapId" @change="loadMap()">
                            <option value="" label="Load map" />
                            <option
                                v-for="map in mapsList.filter(map => (map.is_official == 0 && (userData && map.user_id == userData.id)) || userData.id == 1)" :key="map.id"
                                :value="map.id"
                                :label="map.name + (map.name == 'Default expansion' ? ' (recommended)' : '')"
                            />
                        </select>
                        <i class="fa fa-chevron-down text-coin select-caret"></i>
                        <div class="mt-4"></div>
                    </div>

                    <div v-if="!this.saveSuccess && this.loadedMapId == ''">
                        <form @submit.prevent="saveMap()">
                            <label class="checkbox-container">Map uses boats?
                                <input type="checkbox" v-model="seafaresExpansion">
                                <span class="checkbox-checkmark"></span>
                            </label>
                            <br>
                            <b class="text-coin">Ai tactic</b>
                            <select class="form-control custom-select select-text-coin" v-model="aiTactic">
                                <option value="Default expansion" label="Default expansion" />
                                <option value="Fifth element" label="Fifth element" />
                                <option value="Spiral" label="Spiral" />
                                <option value="Forest escape" label="Forest escape" />
                            </select>
                            <i class="fa fa-chevron-down text-coin select-caret"></i>
                            <div class="mt-4"></div>

                            <input type="text" class="form-control" placeholder="Map name" v-model="mapName">

                            <button class="btn btn-default w-100 mt-2" type="submit">Save map</button>
                        </form>
                    </div>
                    <div v-else-if="this.loadedMapId != ''">
                        <form @submit.prevent="updateMap()">
                            <label class="checkbox-container">Map uses boats?
                                <input type="checkbox" v-model="seafaresExpansion">
                                <span class="checkbox-checkmark"></span>
                            </label>

                            <br>
                            <b class="text-coin">Ai tactic</b>
                            <select class="form-control custom-select select-text-coin" v-model="aiTactic">
                                <option value="Default expansion" label="Default expansion" />
                                <option value="Fifth element" label="Fifth element" />
                                <option value="Spiral" label="Spiral" />
                                <option value="Forest escape" label="Forest escape" />
                            </select>
                            <i class="fa fa-chevron-down text-coin select-caret"></i>

                            <input type="text" class="form-control" placeholder="Map name" v-model="mapName">
                            <button class="btn btn-default w-100 mt-2" type="submit">Update map</button>
                        </form>
                            <div class="text-center mt-3">
                                <a href="#" class="mono text-coin" @click.prevent="deleteMap()"><small>DELETE MAP</small></a>
                            </div>
                    </div>
                    <div v-else-if="this.saveSuccess">
                        <a href="/" class="mono text-paper"><u>Map saved! Play it here</u></a>
                    </div>
                </div>
                <div v-else>
                    <i class="fa fa-exclamation-triangle text-coin mr-1"></i> <a v-if="!this.userData" href="/login" class="mono"><u>Sign in to save maps</u></a>
                </div>
                <div v-if="userData && userData.id == 1" style="margin-top: 20px; clear: both;" class="text-coin hover-underline cursor-pointer">
                    <a @click="toggleVisibility()">Toggle visibility</a>
                </div>

            </div>
        </div>


    </div>
</template>

<script>
import EditorTile from './EditorTile';
import colors from '../../helpers/colors.js'

export default {
    mixins: [
        colors,
    ],
    data() {
        return {
            mapName: '',
            seafaresExpansion: false,
            aiTactic: 'Default expansion',
            tiles: [],
            navTiles: [
                { svg: true, label: 'lumber 2:1', type: 'harbor-lumber', group: 'harbor', labelColor: '#FFFFFF' },
                { svg: false, label: 'empty', type: 'empty', group: 'other', labelColor: '#AC9A76' },
                { svg: true, label: 'lumber', type: 'lumber', group: 'land', labelColor: '#FFFFFF' },
                { svg: true, label: 'brick 2:1', type: 'harbor-brick', group: 'harbor', labelColor: '#FFFFFF' },
                { svg: false, label: 'hidden', type: 'hidden-tile', group: 'other', labelColor: '#AC9A76' },
                { svg: true, label: 'brick', type: 'brick', group: 'land', labelColor: '#FFFFFF' },
                { svg: true, label: 'wool 2:1', type: 'harbor-wool', group: 'harbor', labelColor: '#FFFFFF' },
                { svg: false, label: 'no initial build', type: 'build-blocked', group: 'other', labelColor: '#AC9A76' },
                { svg: true, label: 'wool', type: 'wool', group: 'land', labelColor: '#383832' },
                { svg: true, label: 'grain 2:1', type: 'harbor-grain', group: 'harbor', labelColor: '#FFFFFF' },
                { svg: false, label: 'random resource', type: 'random-resource', group: 'land', labelColor: '#AC9A76' },
                { svg: true, label: 'grain', type: 'grain', group: 'land', labelColor: '#383832' },
                { svg: true, label: 'ore 2:1', type: 'harbor-ore', group: 'harbor', labelColor: '#FFFFFF' },
                { svg: false, label: 'without desert', type: 'random-resource-no-desert', group: 'land', labelColor: '#AC9A76' },
                { svg: true, label: 'ore', type: 'ore', group: 'land', labelColor: '#FFFFFF' },
                { svg: true, label: 'all 3:1', type: 'harbor-all', group: 'harbor', labelColor: '#FFFFFF' },
                { svg: false, label: 'with gold', type: 'random-resource-gold', group: 'land', labelColor: '#AC9A76' },
                { svg: true, label: 'desert', type: 'desert', group: 'land', labelColor: '#383832' },
                { svg: false, label: 'random 2:1', type: 'random-harbor', group: 'harbor', labelColor: '#FFFFFF' },
                { svg: false, label: 'surprise me', type: 'surprise-me', group: 'land', labelColor: '#AC9A76' },
                { svg: true, label: 'goldmine', type: 'goldmine', group: 'land', labelColor: '#383832' },
                { svg: true, label: 'water', type: 'water', group: 'water', labelColor: '#FFFFFF' },
                { svg: false, label: 'artist', type: 'artist', group: 'land', labelColor: '#AC9A76' },
            ],
            activeTile: { type: 'artist', group: 'land' },
            activeNumber: '?',
            userData: null,
            saveSuccess: false,
            mapsList: [],
            loadedMapId: '',
        }
    },
    beforeMount() {
        this.generateTileGrid();
        this.getUserMaps();
    },
    mounted() {
        this.getUserData();
    },
    methods: {
        loadMap() {
            if(this.loadedMapId != '') {
                let map = this.mapsList.find(map => map.id == this.loadedMapId);
                this.tiles = JSON.parse(map.data);
                this.seafaresExpansion = map.seafares_expansion;
                this.aiTactic = map.ai_tactic;
                this.mapName = map.name;
            } else {
                this.mapName = '';
                this.seafaresExpansion = false;
                this.generateTileGrid();
            }
        },
        async getUserData() {
            await axios.get('/v1/user')
            .then((response) => {
                this.userData = response.data[0];
            }).catch((error) => {
                console.log(error);
            })
        },
        saveMap() {
            // don't hide water/harbors
            this.tiles.forEach(tile => {
                if(['water', 'harbor'].includes(tile.group)) {
                    tile.hidden = false;
                }
            })
            axios.post('/v1/save-map/', {
                mapName: this.mapName,
                seafaresExpansion: this.seafaresExpansion,
                aiTactic: this.aiTactic,
                data: JSON.stringify(this.tiles),
            })
            .then((response) => {
                this.saveSuccess = true;
            }).catch((error) => {
                console.log(error);
            })
        },
        updateMap() {
            // don't hide water/harbors
            this.tiles.forEach(tile => {
                if(['water', 'harbor'].includes(tile.group)) {
                    tile.hidden = false;
                }
            })
            axios.post('/v1/update-map/' + this.loadedMapId, {
                mapName: this.mapName,
                seafaresExpansion: this.seafaresExpansion,
                data: JSON.stringify(this.tiles),
                aiTactic: this.aiTactic,
            })
            .then((response) => {
                this.loadedMapId = '';
                this.saveSuccess = true;
            }).catch((error) => {
                console.log(error);
            })
        },
        deleteMap() {
            if(confirm('Delete map ' + this.mapName + '?'))

            axios.post('/v1/delete-map/' + this.loadedMapId)
                .then((response) => {
                    this.loadedMapId = '';
                    this.saveSuccess = false;
                    this.mapName = '';
                    this.seafaresExpansion = false;
                    this.generateTileGrid();
                    this.getUserMaps();
                }).catch((error) => {
                    console.log(error);
                });
        },
        toggleVisibility() {
            axios.post('/v1/toggle-visibility/' + this.loadedMapId)
                .then((response) => {

                }).catch((error) => {
                    console.log(error);
                });
        },
        tileClicked(tile) {
            if(this.activeTile.type == 'hidden-tile') {
                tile.hidden = !tile.hidden;
            } else if(this.activeTile.type == 'build-blocked') {
                tile.noInitialBuild = !tile.noInitialBuild;
            } else {
                if(this.activeTile.type != null) {
                    tile.group = this.activeTile.group;
                }

                if(tile.type == this.activeTile.type && tile.group == 'harbor') {
                    if(tile.direction == 5) {
                        tile.direction = 0
                    } else {
                        tile.direction += 1;
                    }
                } else if(tile.group != 'harbor') {
                    tile.direction = 0;
                }

                if(this.activeTile.type != null && tile.type != 'artist') {
                    tile.type = this.activeTile.type;
                }

                if(this.activeNumber != null && tile.group == 'land') {
                    tile.number = { value: this.activeNumber };
                }

                // if no number on resource tile, assign '?'
                if(!tile.number && tile.group == 'land' && tile.type != 'desert') {
                    tile.number = { value: '?' };
                }

                if(this.activeTile.type == 'empty') {
                    tile.hidden = false;
                    tile.noInitialBuild = false;
                }


                if(this.activeTile.type != null) {
                    if(this.activeTile.type == 'artist') {

                        tile.type = this.getRandom([
                            'lumber', 'wool', 'grain', 'brick', 'ore', 'desert',
                            'lumber', 'wool', 'grain', 'brick', 'ore',
                            'lumber', 'wool', 'grain', 'brick', 'ore',
                            'lumber', 'wool', 'grain',
                            'lumber', 'wool', 'grain', 'brick', 'ore', 'goldmine',
                            'lumber', 'wool', 'grain', 'brick', 'ore',
                            'lumber', 'wool', 'grain', 'brick', 'ore',
                            'lumber', 'wool', 'grain',
                            'water', 'water', 'water', 'water', 'water', 'water',
                            'harbor-wool', 'harbor-lumber', 'harbor-grain', 'harbor-brick', 'harbor-ore', 'harbor-all',
                        ]);

                        if(['harbor-all', 'harbor-lumber', 'harbor-brick', 'harbor-wool', 'harbor-grain', 'harbor-ore'].includes(tile.type)) {
                            tile.group = 'harbor';
                        } else if(tile.type == 'water') {
                            tile.group = 'water';
                        } else {
                            if(Math.random() > 0.97) {
                                tile.hidden = true;
                            }
                            if(Math.random() > 0.99) {
                                tile.noInitialBuild = true;
                            }
                        }

                        if(tile.group == 'harbor') {
                            tile.direction = this.getRandom([0,1,2,3,4,5]);
                        } else if(tile.group == 'land' && tile.type != 'desert') {
                            tile.number = { value: this.getRandom([
                                9, 8, 11, 10, 5, 6, 3, 4,
                                9, 8, 11, 10, 12, 5, 6, 3, 4, 2,
                                9, 10, 11, 5, 4, 3,
                                10, 4,
                                12, 2,
                                '?', '?',
                                '?', '?',
                                '?', '?',
                            ]) }
                        }


                    }
                }
            }
        },
        getRandom(array) {
            return array[Math.floor(Math.random() * array.length)];
        },
        setActiveTile(tile) {
            if(this.activeTile.type == tile.type) {
                this.activeTile.type = null;
            } else {
                this.activeTile.type = tile.type;
                this.activeTile.group = tile.group;
            }
        },
        setActiveNumber(number) {
            if(this.activeNumber == number) {
                this.activeNumber = null;
            } else {
                this.activeNumber = number;
            }
        },
        generateTileGrid() {
            this.tiles = [];

            for(let x = 0; x < 11; x++) {
                for(let y = 0; y < 11; y++) {
                    let type = 'empty';

                    ([0, 10].includes(x) || [0, 10].includes(y)) ? type = 'invisible' : type = 'empty';

                    this.tiles.push({
                        row: y,
                        position: x,
                        key: 't' + y + x,
                        group: 'other',
                        type: type,
                        number: 0,
                        direction: 0,
                        hidden: false,
                        noInitialBuild: false,
                    })
                }
            }
        },
        getUserMaps() {
            axios.get('/v1/maps')
                .then((response) => {
                    this.mapsList = response.data;
                }).catch((error) => {
                    console.log(error);
                })
        }
    },
    components: {
        EditorTile,
    }
}
</script>

<style>

</style>
