export default {
    methods: {
        async diceGeneratedProgressCards(whiteDice, redDice, boatDice) {

            let rewardPlayers = [];

            this.players.forEach(player => {
                let target = player.progress.find(obj => obj.type == boatDice);

                if(target && target.level != 0 && target.level >= redDice-1) {
                    rewardPlayers.push(player);
                    if(! this.multiplayer) {
                        this.givePlayerProgressCard(player, boatDice);
                    }
                }
            });
            if(this.multiplayer) {
                if(rewardPlayers.length) {
                    await this.givePlayersProgressCards(rewardPlayers, boatDice);
                }
            }

            this.updatePlayersPoints();
        },
        hasTooManyProgressCards(player, endTurn) {
            let progressCardsLimit = 4;

            if(player == this.activePlayer && !endTurn) progressCardsLimit = 5;

            if(player.progressCards.length > progressCardsLimit) {
                this.log('Waiting for :playerName to throw away a progress card', player, true);
                this.showModal('throw away a progress card', player);
                this.focusModeOn('throw away a progress card', player);

                if(this.logToConsole) console.log('SAVE throwAwayPrgrCard');
                this.savePlayer(player);
            }
        },
        progressCardBack(label, type) {
            if(label == null || type == null) return;

            if(this.multiplayer) {
                axios.post('/v1/progress-card-back/' + this.$route.params.gameId, {label: label, type: type})
                .then((response) => {

                }).catch((error) => {
                    console.log(error);
                })
            } else {
                if(type == 'cloth') {
                    this.progressCardsCloth.push(label);
                } else if(type == 'coin') {
                    this.progressCardsCoin.push(label);
                } else if(type == 'paper') {
                    this.progressCardsPaper.push(label);
                }
            }
        },
        givePlayerProgressCard2(player, type, load = false) {
            if(!player.ai) {
                if(load) {
                    if(this.loadingPrgrCard == true) return;
                }
            }

            this.loadingPrgrCard = true;

            if(this.focusType == 'select a progress card') {
                this.closeModal(player);
            }

            axios.post('/v1/give-progress-card/' + this.$route.params.gameId, {type: type})
                .then((response) => {
                    // console.log(response.data);

                    let label = response.data.card;
                    let type = response.data.deck;

                    if(label != 'point') {
                        player.progressCards.unshift({ deck: type, label: label, active: false, });

                        this.hasTooManyProgressCards(player);
                    } else {
                        player.pointCards.push({ label: type, points: 1 });
                        this.updatePlayersPoints();
                    }

                    if(this.multiplayer) {
                        if(player.ai) {
                            this.savePlayer(player);
                        }
                    }

                    this.loadingPrgrCard = false;

                    if(load || player.ai) {
                        if(this.focusType == 'select a progress card') this.focusModeOff(player);
                    }

                    if(!this.multiplayer) {
                        this.saveGame(false, true);
                    }

                }).catch((error) => {
                    console.log(error);
                })

            if(load || player.ai) {
                if(this.focusType == 'select a progress card') this.focusModeOff(player);
            }
        },
        async givePlayersProgressCards(players, progressType) {

            axios.post('/v1/give-players-progress-cards/' + this.$route.params.gameId, {playersCount: players.length, progressType: progressType})
                .then((response) => {
                    let givenCards = response.data.givenCards;
                    let type = response.data.deck;

                    // console.log(givenCards);

                    givenCards.forEach((card, index) => {
                        if(card != 'point') {
                            players[index].progressCards.unshift({ deck: type, label: card, active: false, });

                            // this.hasTooManyProgressCards(player);
                        } else {
                            players[index].pointCards.push({ label: type, points: 1 });
                        }
                    })

                    this.saveGame(false, true);

                    players.forEach(player => {
                        this.hasTooManyProgressCards(player);
                    });

                }).catch((error) => {
                    console.log(error);
                })
        },
        // abcd
        async givePlayerProgressCard(player, type, close = false, playerPick = false) {
            // if(this.userData != null) {
            //     if(!player.ai) {
            //         if(this.gamePhase == 'regular' && !this.loadGameDataCompleted) return;
            //     }
            // }

            let progressDeck;

            if(type == 'cloth') {
                progressDeck = this.progressCardsCloth;
            } else if(type == 'coin') {
                progressDeck = this.progressCardsCoin;
            } else if(type == 'paper') {
                progressDeck = this.progressCardsPaper;
            }

            if(progressDeck.length) {
                if(progressDeck[0] != 'point') {

                    // some chatting
                    if(this.firstBoatRound && type == 'coin') {
                        this.players
                            .filter(opponent => opponent.ai && opponent != player)
                            .forEach(opponent => {
                                this.aiChat(["hope you didn't get deseter...", "plz no deseter!", "no deseter!"], opponent, true, 40);
                        });
                    }

                    if(player.ai) {
                        if(type == 'cloth') {
                            this.aiChat(["my favortie card is " + this.getRandom(['resource monopoly', 'merchant', 'master merchant'])], player, true, 3);
                        } else if(type == 'coin') {
                            this.aiChat(["my favortie card is " + this.getRandom(['deseter', 'spy!!', 'intrigue...', 'from the paper deck!', 'warlord, I love it!'])], player, true, 3);
                        } else if(type == 'paper') {
                            this.aiChat(["my favortie card is " + this.getRandom(['alchemist, it makes me feel like god', 'road building', 'medecine', 'mining'])], player, true, 3);
                        }
                    }

                    player.progressCards.unshift({ deck: type, label: progressDeck[0], active: false, });
                    this.focusModeOff(player);
                    this.hasTooManyProgressCards(player);
                } else {
                    if(player != this.activePlayer && this.activePlayer.ai) {
                        this.aiChat([player.name + " got a point card!!"], this.activePlayer, true, 50);
                    }
                    player.pointCards.push({ label: type, points: 1 });
                }
                progressDeck.shift();

                if(playerPick) {
                    console.log('SAVE give prgrCard!')
                    if(this.multiplayer) {
                        this.savePlayer(player);
                    }
                }
            }

            if(close) {
                this.focusModeOff(player);
            }
        },
    }
 }
