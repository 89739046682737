<template>
    <div class="hand-cards-container" @mouseleave="showCardCount(null)">
        <div class="hand-cards-numbers" v-if="handCardsCount && showNumbers">
            <div v-if="currentAmount >= 4" class="current">{{ currentAmount }}</div>
            <div class="total" :class="{ 'safe': allowedHandCards >= handCardsCount }">{{ handCardsCount }}</div>
        </div>

        <span v-for="(cardObj, index) in player.handCards" :key="index" v-show="cardObj.amount > 0 && cardObj.group == 'commodity'" class="hand-card-wrapper">
            <template v-if="cardObj.amount > 0 && !Number.isNaN(parseInt(cardObj.amount))">
                <span v-for="(card, pos) in cardObj.amount"
                    :key="pos"
                    @click="handCardClicked(cardObj, cardObj.selected > pos)"
                    @mouseover="showCardCount(cardObj.amount)"
                >
                    <HandCard
                        :group="cardObj.group"
                        :type="cardObj.type"
                        class="hand-card"
                        :class="[
                            {'slide-in': cardObj.slideIn > pos },
                            {'slide-out': cardObj.slideOut > pos },
                            {'selected': cardObj.selected > pos },
                        ]"
                        :flatGraphics="flatGraphics"
                    />
                </span>
                <!-- @click="handCardClicked(cardObj, cardObj.selected > pos)" -->

                <!-- <div v-for="(card, pos) in cardObj.amount"
                    :key="pos"
                    class="hand-card"
                    :class="[
                        cardObj.type,
                        {'commodity': cardObj.group == 'commodity'},
                        {'slide-in': cardObj.slideIn > pos },
                        {'slide-out': cardObj.slideOut > pos },
                        {'selected': cardObj.selected > pos },
                    ]"
                    @click="handCardClicked(cardObj, cardObj.selected > pos)"
                    @mouseover="showCardCount(cardObj.amount)"
                ></div> -->
            </template>
        </span>


        <div
            v-if="
                player.handCards.filter(cardObj => cardObj.group == 'commodity').reduce((numberOfCards, card) => numberOfCards + card.amount, 0) != 0 &&
                player.handCards.filter(cardObj => cardObj.group == 'resource').reduce((numberOfCards, card) => numberOfCards + card.amount, 0) != 0"
            class="resource-commodity-gap"
        ></div>

        <span v-for="(cardObj, index) in player.handCards" :key="index+99" v-show="cardObj.amount > 0 && cardObj.group != 'commodity'" class="hand-card-wrapper">
            <template v-if="cardObj.amount > 0 && !Number.isNaN(parseInt(cardObj.amount))">
                <span v-for="(card, pos) in cardObj.amount"
                    :key="pos"
                    @click="handCardClicked(cardObj, cardObj.selected > pos)"
                    @mouseover="showCardCount(cardObj.amount)"
                >
                <HandCard
                    :group="cardObj.group"
                    :type="cardObj.type"
                    class="hand-card"
                    :class="[
                        {'slide-in': cardObj.slideIn > pos },
                        {'slide-out': cardObj.slideOut > pos },
                        {'selected': cardObj.selected > pos },
                    ]"
                    :cardObj="cardObj"
                    :flatGraphics="flatGraphics"
                />
                </span>
                <!-- @click="handCardClicked(cardObj, cardObj.selected > pos)" -->
                <!-- <div v-for="(card, pos) in cardObj.amount"
                    :key="pos"
                    class="hand-card"
                    :class="[
                        cardObj.type,
                        {'commodity': cardObj.group == 'commodity'},
                        {'slide-in': cardObj.slideIn > pos },
                        {'slide-out': cardObj.slideOut > pos },
                        {'selected': cardObj.selected > pos },
                    ]"
                    @click="handCardClicked(cardObj, cardObj.selected > pos)"
                    @mouseover="showCardCount(cardObj.amount)"
                ></div> -->
            </template>
        </span>
    </div>
</template>

<script>
import HandCard from './cards/HandCard';

export default {
    props: ['player', 'focusType', 'focusMode', 'waitingForMainPlayer', 'forceUpdate', 'activePlayer', 'allowedHandCards', 'disabledOnMasterMerchant', 'showNumbers', 'flatGraphics'],
    created() {
        this.updateThrowAwayCount();
    },
    data() {
        return {
            currentAmount: null,
            disabled: false,
        };
    },
    methods: {
        showCardCount(amount) {
            this.currentAmount = amount;
        },
        updateThrowAwayCount() {
            this.$emit('updateThrowAwayCount', this.throwAwayCount);

            this.$emit('updateLocalStorage');
        },
        handCardClicked(cardObj, selected) {
            if(this.throwAwayCount == 0 && this.focusType == 'throw away cards' && !selected) return;

            // master merchant / wedding
            if(['steal 2 hand cards', 'give 2 hand cards'].includes(this.$props.focusType)) {
                if((this.selectedHandCardsCount == 2 || this.handCardsCount-this.selectedHandCardsCount == 0) && !selected) {
                    return;
                }
            }

            if(this.disabled) return;
            // don't let user click own cards when stealing opponents cards with master merchant
            if(this.focusType == 'steal 2 hand cards' && this.disabledOnMasterMerchant == true) return;
            if([
                'steal 2 hand cards',
                'throw away cards',
                'give 2 hand cards',
                'commercial harbor select resource',
                'commercial harbor select commodity',
                'trade cards',
            ].includes(this.focusType) || this.player.modalType == 'make counter offer') {

                // don't let user do the action again while waiting for other players, discard half hand/wedding
                if(!this.waitingForMainPlayer && this.player.modalType != 'make counter offer' && !['accepted'].includes(this.player.offerAnswer)) return;

                if(this.focusType == 'commercial harbor select resource') {
                    if(cardObj.group != 'commodity') {
                        if(this.selectedHandCardsCount == 1 && !selected) return;
                        if(selected) {
                            cardObj.selected--;
                        } else {
                            cardObj.selected++;
                        }
                    }
                    return;
                }

                if(this.focusType == 'commercial harbor select commodity') {
                    if(cardObj.group == 'commodity') {
                        if(this.selectedHandCardsCount == 1 && !selected) return;
                        if(selected) {
                            cardObj.selected--;
                        } else {
                            cardObj.selected++;
                        }
                    }
                    return;
                }

                if(selected) {
                    cardObj.slideOut--;
                    cardObj.selected--;
                    if(['accepted'].includes(this.player.offerAnswer)) {
                        this.$emit('cancelAcceptedOffer', this.player);
                    }
                    this.$emit('updateBankOffers');
                    this.$emit('resetOffer');
                } else {
                    if(['accepted'].includes(this.player.offerAnswer)) return;
                    // when trading, selected limit is 8 cards
                    if(this.focusType == 'trade cards' || this.player.modalType == 'make counter offer') {
                        if(this.player.handCards.reduce((numberOfCards, cardObj) => numberOfCards + cardObj.selected, 0) == 8) return;
                    }

                    if(this.throwAwayCount > 0) cardObj.slideOut++;
                    cardObj.selected++;
                    this.$emit('updateBankOffers');
                    this.$emit('resetOffer');
                }

                if(this.player.modalType != 'make counter offer' && this.player.offerAnswer == 'waiting') {
                    this.$emit('makeCounterOffer', this.player);

                    this.$forceUpdate();

                }

                // this.updateThrowAwayCount();
            }
        },
        confirmStealHandCards() {
            if(['steal 2 hand cards', 'give 2 hand cards'].includes(this.$props.focusType)) {
                this.player.showOutgoingHandCards = false;
                if(this.selectedHandCardsCount == 2 || this.handCardsCount-this.selectedHandCardsCount == 0) {
                    this.stealSelectedCards();

                    // master merchant
                    if(this.$props.focusType == 'steal 2 hand cards') {
                        this.$emit('focusModeOff', this.$props.activePlayer);
                    // wedding
                    } else {
                        this.$emit('focusModeOff', this.player);
                    }
                } else {
                    return;
                }
            }
        },
        confirmGiveResource() {
            let target = this.player.handCards.find(cardObj => cardObj.selected > 0);

            if(! target) return;

            this.selectedHarborResource(target.type);
        },

        confirmGiveCommodity() {
            let target = this.player.handCards.find(cardObj => cardObj.selected > 0);

            if(! target) return;

            this.selectedHarborCommodity(this.player, target.type);
        },
        confirmThrowAwayCards() {
            // discard half of your cards (7)
            if(this.throwAwayCount == 0 && this.focusType == 'throw away cards') {
                this.player.showOutgoingHandCards = true;
                this.player.hideModal = true;
                this.disabled = true;
                setTimeout(() => {
                    // not sure about this
                    if(this.player.ai) return;

                    this.deleteSelectedCards(this.player);

                    this.$emit('focusModeOff', this.player);

                    this.$forceUpdate();
                }, 700);
            }
        },
        selectedHarborResource(type) {
            this.$emit('selectedHarborResource', type);
        },
        selectedHarborCommodity(player, type) {
            this.$emit('selectedHarborCommodity', this.player, type);
        },
        deleteSelectedCards(player) {

            // this.$emit('log', ':playerName threw away ' + this.selectedHandCardsCount + ' cards', this.player);

            let throwAwayString = '';
            let handCardsArr = [];

            this.player.handCards.forEach(cardObj => {
                cardObj.amount = cardObj.amount - cardObj.selected;

                for(let i = 0; i < cardObj.selected; i++) handCardsArr.push(cardObj.type);

                cardObj.selected = 0;
            });

            handCardsArr.reverse();

            handCardsArr.forEach(item => {
                throwAwayString += ':' + item + ' ';
            })

            this.$emit('log', '<div>:playerName threw away</div>' + throwAwayString, this.player);

            this.$emit('focusModeOff', this.player);

            this.$forceUpdate();

            setTimeout(() => {
                this.player.handCards.forEach(cardObj => {
                    cardObj.slideOut = 0;
                    cardObj.selected = 0;
                });
                this.disabled = false;

                this.$emit('updateLocalStorage');
            }, 700)
        },
        async stealSelectedCards() {
            this.player.showIncomingHandCards = false;
            this.player.showOutgoingHandCards = false;
            this.activePlayer.showIncomingHandCards = false;
            this.activePlayer.showOutgoingHandCards = false;

            let logString = '';

            this.player.handCards.forEach(cardObj => {
                let activePlayerCardObj = this.$props.activePlayer.handCards.find(activeCardObj => activeCardObj.type == cardObj.type);


                for(let i = 0; i < cardObj.selected; i++) logString += ':' + cardObj.type + ' ';

                // remove from victim player

                cardObj.amount -= cardObj.selected;

                // give active player cards, animate them in
                activePlayerCardObj.amount += cardObj.selected;
                activePlayerCardObj.slideIn += cardObj.selected;

                cardObj.selected = 0;
                cardObj.slideOut = 0;


                // clean up the animations
                setTimeout(() => {
                    activePlayerCardObj.slideIn = 0;
                }, 700)
            });

            this.$emit('log', '<div>:activePlayer played master merchant against you</div>' + logString + ':break<div>(only visible for you)</div>', this.activePlayer, false, null, this.player.color);

            setTimeout(() => {
                console.log('emit SAVE master merchant')
                this.$emit('saveGame');
            }, 700)
        },
    },
    watch: {
        throwAwayCount(newValue) {
            this.$emit('updateThrowAwayCount', newValue);

            this.$emit('updateLocalStorage');
        }
    },
    computed: {
        handCardsCount() {
            return this.player.handCards.reduce((numberOfCards, card) => numberOfCards + card.amount, 0);
        },
        selectedHandCardsCount() {
            return this.player.handCards.reduce((numberOfCards, card) => numberOfCards + card.selected, 0);
        },
        throwAwayCount() {
            return Math.floor(this.handCardsCount/2) - this.selectedHandCardsCount;
        }
    },
    components: {
        HandCard,
    }
}
</script>

<style>

</style>
