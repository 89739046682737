export default {
    methods: {
       async generateLoadedMap(basic = false) {
            // // these needed?
            // this.dices.whiteDiceRoll = this.randomNumber(1, 6);
            // this.dices.redDiceRoll = this.randomNumber(1, 6);
            // this.dices.boatDiceRoll = this.getRandom(this.boatDice);

            // console.log(this.loadedMapData)
            this.tiles = [];

            this.loadedMapData.forEach(tile => {
                // if(tile.type == 'empty') tile.type = 'invisible';

                // if(tile.type == 'empty') tile = this.noTile;
                // if(tile.type == 'invisible') tile = this.noTile;
                if(['empty', 'invisible'].includes(tile.type)) tile.type = undefined;

                this.tiles.push({
                    'row': tile.row, 
                    'position': tile.position, 
                    'key': this.generateKey(tile),
                    'group': tile.group,
                    'type': tile.type, 
                    'number': tile.number,
                    'direction': tile.direction,
                    'actionTile': false,
                    'hidden': tile.hidden,
                    'noInitialBuild': tile.noInitialBuild,
                });
            });

            this.tiles.forEach(tile => {
                let wasSurpriseMe = false;
                if(tile.type == 'surprise-me') {
                    tile.type = this.getRandom([
                        'lumber', 'wool', 'grain', 'brick', 'ore', 'desert',
                        'lumber', 'wool', 'grain', 'brick', 'ore',
                        'lumber', 'wool', 'grain', 'brick', 'ore',
                        'lumber', 'wool', 'grain',
                        'water', 'water', 'water', 'water', 'water', 'water',
                    ]);
                    wasSurpriseMe = true;
                }

                if(wasSurpriseMe) {
                    if(tile.type == 'water') {
                        tile.group = 'water';

                        tile.number = { value: null, dots: null, exponentialDots: null };
                        if(Math.random() > 0.5) {
                            tile.type = this.getRandom([
                                'harbor-wool', 'harbor-lumber', 'harbor-grain', 'harbor-brick', 'harbor-ore', 'harbor-all',
                            ]);
                            tile.group = 'harbor';
                            tile.direction = Math.floor(Math.random()*6);
                        }
                    } else {
                        if(Math.random() > 0.97) {
                            tile.hidden = true;
                        }
                        if(Math.random() > 0.99) {
                            tile.noInitialBuild = true;
                        }
                    }
                }

                if(tile.group != 'land' || tile.type == 'desert') {
                    tile.number = { value: null, dots: null, exponentialDots: null };
                }

                if(tile.number.value == '?') {
                    tile.number.wasRandom = true;
                } else {
                    tile.number.wasRandom = false;
                }

                if(tile.hidden) tile.noInitialBuild = true;
            })
            
            // tiles with random resource
            let randomResourceTiles = this.tiles.filter(tile => tile.type == 'random-resource');
            let randomResourceTilesNoDesert = this.tiles.filter(tile => tile.type == 'random-resource-no-desert');
            let randomResourceTilesGold = this.tiles.filter(tile => tile.type == 'random-resource-gold');
            // get types for random tiles with Viljamis beautiful function
            let randomResourceTypes = this.replaceRandomResourceTiles(randomResourceTiles.length);
            let randomResourceNoDesertTypes = this.replaceRandomResourceTiles(randomResourceTilesNoDesert.length, true);
            let randomResourceGoldTypes = this.replaceRandomResourceTiles(randomResourceTilesGold.length, false, true);

            // replace type with resource type
            randomResourceTiles.forEach((tile, index) => { 
                tile.type = randomResourceTypes[index];
                if(tile.type == 'desert') tile.number.value = null;
            });

            randomResourceTilesNoDesert.forEach((tile, index) => { 
                tile.type = randomResourceNoDesertTypes[index];
            });

            randomResourceTilesGold.forEach((tile, index) => { 
                tile.type = randomResourceGoldTypes[index];
            });

            // same as above, replace ? with numbers
            let randomNumberTiles = this.tiles.filter(tile => tile.number.value == '?' && tile.type != 'desert');
            let randomTileNumbers = this.replaceRandomNumberTiles(randomNumberTiles.length);
            randomNumberTiles.forEach((tile, index) => tile.number.value = randomTileNumbers[index]);

            // same thing with harbors
            let randomHarborTiles = this.tiles.filter(tile => tile.type == 'random-harbor');
            let randomHarborTypes = this.replaceRandomHarborTiles(randomHarborTiles.length);
            randomHarborTiles.forEach((tile, index) => tile.type = randomHarborTypes[index]);

            if(basic == false) {
                this.robberToDesert();
            } else {
                this.robberToDesert(true);
            }
            
            // actionTile - grid
            this.tiles
            // don't create action-tiles if all surrounding tiles are empty
            .filter(tile => this.surroundingTiles(tile).filter(tile => tile.type == null).length != 6)
            .forEach(tile => {
                // every second row
                if(tile.row % 2 == 0) {
                    // every third tile with offset of 2
                    // change to +1?
                    if((tile.position+2) % 3 == 0) {
                        tile.actionTile = true;
                        // tile.type = 'desert';
                        tile.spots = this.generateSpots(tile.row, tile.position);
                        tile.roadSpots = this.generateRoadSpots(tile.row, tile.position);
                        this.actionTiles.push(tile);
                    }
                } else {
                    // every third tile
                    // change to +2?
                    if((tile.position+0) % 3 == 0) {
                        tile.actionTile = true;
                        // tile.type = 'desert';
                        tile.spots = this.generateSpots(tile.row, tile.position);
                        tile.roadSpots = this.generateRoadSpots(tile.row, tile.position);
                        this.actionTiles.push(tile);
                    }
                }
            });

            // add dots & exponentialDots to numbers
            this.tiles.forEach(tile => {
                // value unknown numbers a bit higher than 9 & 5, set to correct number when tile is revealed
                if([2, 12].includes(tile.number.value)) {
                    tile.number.dots = 1;
                    tile.number.exponentialDots = 0.01;
                } else if([3, 11].includes(tile.number.value)) {
                    tile.number.dots = 2;
                    tile.number.exponentialDots = 1.30;
                } else if([4, 10].includes(tile.number.value)) {
                    tile.number.dots = 3;
                    tile.number.exponentialDots = 3.70;
                } else if([5, 9].includes(tile.number.value)) {
                    tile.number.dots = 4;
                    tile.number.exponentialDots = 5.10;
                } else if([6, 8].includes(tile.number.value)) {
                    tile.number.dots = 5;
                    tile.number.exponentialDots = 7.20;
                } else {
                    tile.number = { value: null, dots: null, exponentialDots: null };
                }
            });

            // Now the map is generated, time to make sure red numbers(6 and 8) is not next to each other
            let redNumbersWithRedNeighbours = this.redNumbersWithRedNeighbours(true);
            while(redNumbersWithRedNeighbours.length) {
                let notRedNumbersWithNoRedNeighbours = this.tiles.filter(tile => tile.number.wasRandom && (tile.number.value != 6 && tile.number.value != 8 && tile.number.value != null))
                    .filter(tile => this.surroundingTilesNumbers(tile, true).indexOf(6) == -1 && this.surroundingTilesNumbers(tile, true).indexOf(8) == -1);                    
                this.shuffle(notRedNumbersWithNoRedNeighbours);

                let redNumberWithRedNeigbours = redNumbersWithRedNeighbours[0];
                let numberWithoutRedNeighbours = notRedNumbersWithNoRedNeighbours[0];

                let redNumber = redNumbersWithRedNeighbours[0].number;
                this.selectTile(redNumberWithRedNeigbours.row, redNumberWithRedNeigbours.position).number = numberWithoutRedNeighbours.number;
                this.selectTile(numberWithoutRedNeighbours.row, numberWithoutRedNeighbours.position).number = redNumber;
                
                // check how many red numbers with red neighbours left now after moving the first number
                redNumbersWithRedNeighbours = this.redNumbersWithRedNeighbours(true);
            }

            // do the same thing with small numbers(2 and 12)
            let smallNumbersWithSmallNeighbours = this.smallNumbersWithSmallNeighbours(true);
        
            while(smallNumbersWithSmallNeighbours.length) {
                // avoid switching with red numbers, we just fixed them so would be stupid to move them around now
                let notSmallNumbersWithNoSmallNeighbours = this.tiles.filter(tile => tile.number.wasRandom && (tile.number.value != 2 && tile.number.value != 12 && tile.number.value != 6 && tile.number.value != 8 && tile.number.value != null))
                    .filter(tile => this.surroundingTilesNumbers(tile, true).indexOf(2) == -1 && this.surroundingTilesNumbers(tile, true).indexOf(12) == -1);                    
                this.shuffle(notSmallNumbersWithNoSmallNeighbours);

                let smallNumberWithSmallNeighbours = smallNumbersWithSmallNeighbours[0];
                let numberWithoutSmallNeighbours = notSmallNumbersWithNoSmallNeighbours[0];

                let smallNumber = smallNumbersWithSmallNeighbours[0].number;
                this.selectTile(smallNumberWithSmallNeighbours.row, smallNumberWithSmallNeighbours.position).number = numberWithoutSmallNeighbours.number;
                this.selectTile(numberWithoutSmallNeighbours.row, numberWithoutSmallNeighbours.position).number = smallNumber;
                
                // check how many red numbers with red neighbours left now after moving the first number
                smallNumbersWithSmallNeighbours = this.smallNumbersWithSmallNeighbours(true);
            }
            
            // hide dots/exponentialDots for ai
            this.tiles.forEach(tile => {
                if(tile.hidden == true) {
                    // tile.number.dots = 4.20;
                    tile.number.dots = 5.20;
                    // tile.number.exponentialDots = 5.20;
                    tile.number.exponentialDots = 6.20;
                } 
            });

            this.updateMetropolisTopLevel();

            this.getTenBestSpotsDotAverage();

            if(this.buildStuff) this.buildSomeStuff();

            if(this.handCardsToPlayers != 0) {
                this.givePlayersSomeHandCards(this.handCardsToPlayers);
            }

            if(this.progressCardsToPlayers != 0) {
                this.givePlayersSomeProgressCards(this.progressCardsToPlayers);
            }

            this.updateMetropolisTopLevel();

            this.updatePlayersPoints();
        },
        generateMap() {
            this.dices.whiteDiceRoll = this.randomNumber(1, 6);
            this.dices.redDiceRoll = this.randomNumber(1, 6);
            this.dices.boatDiceRoll = this.getRandom(this.boatDice);

            let middleRow = this.numberOfRows - Math.floor(this.numberOfRows/2);
            let tilesInRow = this.numberOfTilesInFirstRow - 1;

            // fill the tiles array
            // 2 tiles
            for(let i = 0; i < 2; i++) {
                this.tiles.push('desert');
            }

            // 5 tiles
            for(let i = 0; i < 5; i++) {
                this.tiles.push('ore');
                this.tiles.push('brick');
            
                this.tiles.push('grain');
                this.tiles.push('wool');
                this.tiles.push('lumber');
            }

            // +1 (6 tiles)
            this.tiles.push('grain');
            this.tiles.push('wool');
            this.tiles.push('lumber');

            this.shuffle(this.tiles);

            // fill the numbers array
            for(let i = 0; i < 2; i++) {
                this.numbers.push({ value: 2, dots: 1, exponentialDots: 0.01, });
                this.numbers.push({ value: 6, dots: 5, exponentialDots: 7.20, });
                this.numbers.push({ value: 8, dots: 5, exponentialDots: 7.20, });
                this.numbers.push({ value: 12, dots: 1, exponentialDots: 0.01, });
            }

            for(let i = 0; i < 3; i++) {
                this.numbers.push({ value: 3, dots: 2, exponentialDots: 1.30, });
                this.numbers.push({ value: 5, dots: 4, exponentialDots: 5.10, });
                this.numbers.push({ value: 9, dots: 4, exponentialDots: 5.10, });
                this.numbers.push({ value: 11, dots: 2, exponentialDots: 1.30, });
            }

            for(let i = 0; i < 4; i++) {
                this.numbers.push({ value: 4, dots: 3, exponentialDots: 3.70, });
                this.numbers.push({ value: 10, dots: 3, exponentialDots: 3.70, });
            }

            this.shuffle(this.numbers);

            // generate the map
            for (let tileRow = 0; tileRow < this.numberOfRows; tileRow++) {
                if(tileRow < middleRow) {
                    tilesInRow++;
                } else {
                    tilesInRow--;
                }
            
                for (let tilePosition = 0; tilePosition < tilesInRow; tilePosition++) {
                    let tileNumber = { value: null, dots: null };
                    let tileGroup = null;
                    let tileType = null;

                    if(
                        tileRow == 0 || 
                        tileRow+1 == this.numberOfRows || 
                        tilePosition == 0 || 
                        tilePosition+1 == tilesInRow
                    ) {
                        tileGroup = 'water';
                        tileType = 'water';
                    } else {
                        tileGroup = 'land';
                        tileType = this.tiles[0];
                        this.tiles.shift();
                        
                        if(tileType != 'desert') {
                            tileNumber = this.numbers[0];
                            this.numbers.shift();
                        }
                    }

                    this.tiles.push({
                        'row': tileRow, 
                        'position': tilePosition, 
                        'key': this.generateKey(tile),
                        'group': tileGroup,
                        'type': tileType, 
                        'number': tileNumber,
                        'direction': 0,
                        'actionTile': false,
                        'spots': this.generateSpots(tileRow, tilePosition),
                        'roadSpots': this.generateRoadSpots(tileRow, tilePosition),
                    });
                }
            }
            // Now the map is generated, time to make sure red numbers(6 and 8) is not next to each other
            let redNumbersWithRedNeighbours = this.redNumbersWithRedNeighbours();
            while(redNumbersWithRedNeighbours.length) {
                let notRedNumbersWithNoRedNeighbours = this.tiles.filter(tile => tile.number.value != 6 && tile.number.value != 8 && tile.number.value != null)
                    .filter(tile => this.surroundingTilesNumbers(tile).indexOf(6) == -1 && this.surroundingTilesNumbers(tile).indexOf(8) == -1);                    
                this.shuffle(notRedNumbersWithNoRedNeighbours);

                let redNumberWithRedNeigbours = redNumbersWithRedNeighbours[0];
                let numberWithoutRedNeighbours = notRedNumbersWithNoRedNeighbours[0];

                let redNumber = redNumbersWithRedNeighbours[0].number;
                this.selectTile(redNumberWithRedNeigbours.row, redNumberWithRedNeigbours.position).number = numberWithoutRedNeighbours.number;
                this.selectTile(numberWithoutRedNeighbours.row, numberWithoutRedNeighbours.position).number = redNumber;
                
                // check how many red numbers with red neighbours left now after moving the first number
                redNumbersWithRedNeighbours = this.redNumbersWithRedNeighbours();
            }

            // do the same thing with small numbers(2 and 12)
            let smallNumbersWithSmallNeighbours = this.smallNumbersWithSmallNeighbours();
        
            while(smallNumbersWithSmallNeighbours.length) {
                // avoid switching with red numbers, we just fixed them so would be stupid to move them around now
                let notSmallNumbersWithNoSmallNeighbours = this.tiles.filter(tile => tile.number.value != 2 && tile.number.value != 12 && tile.number.value != 6 && tile.number.value != 8 && tile.number.value != null)
                    .filter(tile => this.surroundingTilesNumbers(tile).indexOf(2) == -1 && this.surroundingTilesNumbers(tile).indexOf(12) == -1);                    
                this.shuffle(notSmallNumbersWithNoSmallNeighbours);

                let smallNumberWithSmallNeighbours = smallNumbersWithSmallNeighbours[0];
                let numberWithoutSmallNeighbours = notSmallNumbersWithNoSmallNeighbours[0];

                let smallNumber = smallNumbersWithSmallNeighbours[0].number;
                this.selectTile(smallNumberWithSmallNeighbours.row, smallNumberWithSmallNeighbours.position).number = numberWithoutSmallNeighbours.number;
                this.selectTile(numberWithoutSmallNeighbours.row, numberWithoutSmallNeighbours.position).number = smallNumber;
                
                // check how many red numbers with red neighbours left now after moving the first number
                smallNumbersWithSmallNeighbours = this.smallNumbersWithSmallNeighbours();
            }

            
            this.selectTile(0,0).group = 'harbor';
            this.selectTile(0,0).type = 'harbor-all';
            this.selectTile(0,0).direction = 2;
            this.selectTile(1,4).group = 'harbor';
            this.selectTile(1,4).type = 'harbor-all';
            this.selectTile(1,4).direction = 3;
            this.selectTile(5,6).group = 'harbor';
            this.selectTile(5,6).type = 'harbor-all';
            this.selectTile(5,6).direction = 4;
            this.selectTile(8,2).group = 'harbor';
            this.selectTile(8,2).type = 'harbor-all';
            this.selectTile(8,2).direction = 5;
            this.selectTile(6,0).group = 'harbor';
            this.selectTile(6,0).type = 'harbor-all';
            this.selectTile(6,0).direction = 0;

            this.selectTile(3,6).group = 'harbor';
            this.selectTile(3,6).type = 'harbor-wool';
            this.selectTile(3,6).direction = 4;

            this.selectTile(2,0).group = 'harbor';
            this.selectTile(2,0).type = 'harbor-wool';
            this.selectTile(2,0).direction = 2;

            this.shuffle(this.harbors);

            this.selectTile(0,2).group = 'harbor';
            this.selectTile(0,2).type = this.harbors[0];
            this.selectTile(0,2).direction = 3;
            this.harbors.shift();
            this.selectTile(7,4).group = 'harbor';
            this.selectTile(7,4).type = this.harbors[0];
            this.selectTile(7,4).direction = 5;
            this.harbors.shift();
            this.selectTile(8,0).group = 'harbor';
            this.selectTile(8,0).type = this.harbors[0];
            this.selectTile(8,0).direction = 0;
            this.harbors.shift();
            this.selectTile(4,0).group = 'harbor';
            this.selectTile(4,0).type = this.harbors[0];
            this.selectTile(4,0).direction = 1;
            this.harbors.shift();

            // actionTile grid
            this.tiles.forEach((tile, position) => {
                let offset = 1;
                if(position >= 9 && position <= 14) {
                    offset = 0;
                }

                if(position >= 37 && position <= 42) {
                    offset = -1;
                }

                if((position%3)+offset == 1) {
                    tile.actionTile = true;
                    this.actionTiles.push(tile);
                } 
            });

            if(this.buildStuff) this.buildSomeStuff();

            if(this.handCardsToPlayers != 0) {
                this.givePlayersSomeHandCards(this.handCardsToPlayers);
            }

            if(this.progressCardsToPlayers != 0) {
                this.givePlayersSomeProgressCards(this.progressCardsToPlayers);
            }

            this.updateMetropolisTopLevel();

            this.updatePlayersPoints();
        },
        generateSpots(tileRow, tilePosition) {
            let spots = [];
            
            for(let position = 0; position < 6; position++) {
                spots.push({ 
                    'actions': [],
                    'position': position,
                    'tileKey': 'k'+ tileRow + '' + tilePosition,
                    'key': 'k'+ tileRow + '' + tilePosition + '' + position,
                });
            }

            return spots;
        },
        generateRoadSpots(tileRow, tilePosition) {
            let roadSpots = [];
            
            for(let i = 0; i < 9; i++) {
                roadSpots.push({ 
                    'showMenu': false,
                    'position': i,
                    'tileKey': 'k'+tileRow + '' + tilePosition,
                    'key': 'k'+tileRow + '' + tilePosition + '' + i,
                });
            }

            return roadSpots;
        },
        robberToDesert(basic = false) {
            if(!this.tiles.filter(tile => tile.type == 'desert' && !tile.hidden).length) return;

            this.robber.tile = this.tiles.find(tile => tile.type == 'desert' && !tile.hidden);
            if(basic == false) {
                this.merchant.tile = this.tiles.reverse().find(tile => tile.type == 'desert' && !tile.hidden);
            }
        },
        // red numbers is 6 and 8
        redNumbersWithRedNeighbours(numberWasRandom = false) {
            if(numberWasRandom) {
                return this.tiles.filter(tile => tile.number.wasRandom && (tile.number.value == 6 || tile.number.value == 8))
                   .filter(tile => this.surroundingTilesNumbers(tile, true).indexOf(6) != -1 || this.surroundingTilesNumbers(tile).indexOf(8) != -1);
            } else {
                return this.tiles.filter(tile => tile.number.value == 6 || tile.number.value == 8)
                   .filter(tile => this.surroundingTilesNumbers(tile, true).indexOf(6) != -1 || this.surroundingTilesNumbers(tile).indexOf(8) != -1);
            }
        },
        // small numbers is 2 and 12
        smallNumbersWithSmallNeighbours(numberWasRandom = false) {
            if(numberWasRandom) {
                return this.tiles.filter(tile => tile.number.wasRandom && (tile.number.value == 2 || tile.number.value == 12))
                    .filter(tile => this.surroundingTilesNumbers(tile).indexOf(2) != -1 || this.surroundingTilesNumbers(tile).indexOf(12) != -1);
            } else {
                return this.tiles.filter(tile => tile.number.value == 2 || tile.number.value == 12)
                    .filter(tile => this.surroundingTilesNumbers(tile).indexOf(2) != -1 || this.surroundingTilesNumbers(tile).indexOf(12) != -1);
            }
        },
    }
 }