export default {
    data() {
        return {
            // 'brown': '#6D3E13',
            // 'orange': '#F27401',
            playerColors: {
                'orange': '#F17400',
                // 'orange': '#21C9EE',
                'white': '#FFFFFF',
                'red': '#DF1716',
                'blue': '#315EFF',
                'green': '#0A7B15',
                'brown': '#704722',
                'pink': '#FF11BC',
                'neutral': '#374958',
            },
            tileColors: {
                'lumber': '#386837',
                'brick': '#C86126',
                'wool': '#95D054',
                'grain': '#EED800',
                'ore': '#535248',
                'desert': '#C6C193',
                'water': '#374958'
            },
            tileSecondaryColors: {
                'lumber': '#28491D',
                'brick': '#8A3A0D',
                'wool': '#5C7A1A',
                'grain': '#7C6925',
                'ore': '#3B3D31',
                'goldmine': '#7C6925',
            },
            tileCircleTextColors: {
                'lumber': '#264D19',
                'brick': '#8A3A0D',
                'wool': '#7D9F34',
                'grain': '#C79617',
                'ore': '#3B3D31',
                'goldmine': '#7C6925',
            },
            harborColors: {
                'harbor-lumber': '#386837',
                'harbor-brick': '#C86126',
                'harbor-wool': '#94D054',
                'harbor-grain': '#EED800',
                'harbor-ore': '#535248',
                'harbor-all': '#535248',
            },
            harborSecondaryColors: {
                'harbor-lumber': '#28491D',
                'harbor-brick': '#8A3A0D',
                'harbor-wool': '#5C7A1A',
                'harbor-grain': '#7C6925',
                'harbor-ore': '#3B3D31',
                'harbor-all': '#383832',
            },
            harborThirdnaryColors: {
                'harbor-lumber': '#28491D',
                'harbor-brick': '#72450C',
                'harbor-wool': '#7DAD4A',
                'harbor-grain': '#C89F11',
                'harbor-ore': '#474436',
                'harbor-all': '#383832',
            },
            commodityColors: {
                'cloth': '#94D054',
                'coin': '#535248',
                'paper': '#386837',
                'textCloth': '#DDFFB2',
                'textCoin': '#AC9A76',
                'textPaper': '#DAF48F',
            },
        }
    }
 }
