<template>
    <div class="knight" :class="[{ 'highlighted': highlighted }]">
        <svg width="23" height="23" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <template v-if="level == 1">
                <template v-if="active">
                    <path d="M1 10C1 5.02944 5.02944 0.999999 10 0.999999C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10Z" :fill="color == 'neutral' ? '#374958' : '#383832'"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 3.91405e-07 15.5228 8.74228e-07 10C1.35705e-06 4.47715 4.47715 -1.35705e-06 10 -8.74228e-07C15.5228 -3.91405e-07 20 4.47715 20 10Z" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                    <path d="M8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10Z" :fill="color == 'neutral' ? '#AC9A76' : playerColors[color]"/>
                </template>
                <template v-else>
                    <path d="M1 10C1 5.02944 5.02944 0.999999 10 0.999999C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10Z" fill="#383832"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 3.91405e-07 15.5228 8.74228e-07 10C1.35705e-06 4.47715 4.47715 -1.35705e-06 10 -8.74228e-07C15.5228 -3.91405e-07 20 4.47715 20 10Z" :fill="playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 11.75C10.9665 11.75 11.75 10.9665 11.75 10C11.75 9.0335 10.9665 8.25 10 8.25C9.0335 8.25 8.25 9.0335 8.25 10C8.25 10.9665 9.0335 11.75 10 11.75ZM12.25 10C12.25 11.2426 11.2426 12.25 10 12.25C8.75736 12.25 7.75 11.2426 7.75 10C7.75 8.75736 8.75736 7.75 10 7.75C11.2426 7.75 12.25 8.75736 12.25 10Z" :fill="playerColors[color]"/>
                </template>
            </template>

            <template v-else-if="level == 2">
                <template v-if="active">
                    <path d="M1 10C1 5.02944 5.02944 0.999999 10 0.999999C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10Z" fill="#383832"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 3.91405e-07 15.5228 8.74228e-07 10C1.35705e-06 4.47715 4.47715 -1.35705e-06 10 -8.74228e-07C15.5228 -3.91405e-07 20 4.47715 20 10Z" :fill="playerColors[color]"/>
                    <path d="M11 10C11 8.89543 11.8954 8 13 8C14.1046 8 15 8.89543 15 10C15 11.1046 14.1046 12 13 12C11.8954 12 11 11.1046 11 10Z" :fill="playerColors[color]"/>
                    <path d="M5 10C5 8.89543 5.89543 8 7 8C8.10457 8 9 8.89543 9 10C9 11.1046 8.10457 12 7 12C5.89543 12 5 11.1046 5 10Z" :fill="playerColors[color]"/>
                </template>
                <template v-else>
                    <path d="M1 10C1 5.02944 5.02944 0.999999 10 0.999999C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10Z" fill="#383832"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 3.91405e-07 15.5228 8.74228e-07 10C1.35705e-06 4.47715 4.47715 -1.35705e-06 10 -8.74228e-07C15.5228 -3.91405e-07 20 4.47715 20 10Z" :fill="playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 11.75C13.9665 11.75 14.75 10.9665 14.75 10C14.75 9.0335 13.9665 8.25 13 8.25C12.0335 8.25 11.25 9.0335 11.25 10C11.25 10.9665 12.0335 11.75 13 11.75ZM15.25 10C15.25 11.2426 14.2426 12.25 13 12.25C11.7574 12.25 10.75 11.2426 10.75 10C10.75 8.75736 11.7574 7.75 13 7.75C14.2426 7.75 15.25 8.75736 15.25 10Z" :fill="playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 11.75C7.9665 11.75 8.75 10.9665 8.75 10C8.75 9.0335 7.9665 8.25 7 8.25C6.0335 8.25 5.25 9.0335 5.25 10C5.25 10.9665 6.0335 11.75 7 11.75ZM9.25 10C9.25 11.2426 8.24264 12.25 7 12.25C5.75736 12.25 4.75 11.2426 4.75 10C4.75 8.75736 5.75736 7.75 7 7.75C8.24264 7.75 9.25 8.75736 9.25 10Z" :fill="playerColors[color]"/>
                </template>
            </template>

            <template v-else-if="level == 3">
                <template v-if="active">
                    <path d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" fill="#383832"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" :fill="playerColors[color]"/>
                    <path d="M9 12C9 13.1046 8.10457 14 7 14C5.89543 14 5 13.1046 5 12C5 10.8954 5.89543 10 7 10C8.10457 10 9 10.8954 9 12Z" :fill="playerColors[color]"/>
                    <path d="M15 12C15 13.1046 14.1046 14 13 14C11.8954 14 11 13.1046 11 12C11 10.8954 11.8954 10 13 10C14.1046 10 15 10.8954 15 12Z" :fill="playerColors[color]"/>
                    <path d="M12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7Z" :fill="playerColors[color]"/>
                </template>
                <template v-else>
                    <path d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" fill="#383832"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" :fill="playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 10.25C6.0335 10.25 5.25 11.0335 5.25 12C5.25 12.9665 6.0335 13.75 7 13.75C7.9665 13.75 8.75 12.9665 8.75 12C8.75 11.0335 7.9665 10.25 7 10.25ZM4.75 12C4.75 10.7574 5.75736 9.75 7 9.75C8.24264 9.75 9.25 10.7574 9.25 12C9.25 13.2426 8.24264 14.25 7 14.25C5.75736 14.25 4.75 13.2426 4.75 12Z" :fill="playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 10.25C12.0335 10.25 11.25 11.0335 11.25 12C11.25 12.9665 12.0335 13.75 13 13.75C13.9665 13.75 14.75 12.9665 14.75 12C14.75 11.0335 13.9665 10.25 13 10.25ZM10.75 12C10.75 10.7574 11.7574 9.75 13 9.75C14.2426 9.75 15.25 10.7574 15.25 12C15.25 13.2426 14.2426 14.25 13 14.25C11.7574 14.25 10.75 13.2426 10.75 12Z" :fill="playerColors[color]"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 5.25C9.0335 5.25 8.25 6.0335 8.25 7C8.25 7.9665 9.0335 8.75 10 8.75C10.9665 8.75 11.75 7.9665 11.75 7C11.75 6.0335 10.9665 5.25 10 5.25ZM7.75 7C7.75 5.75736 8.75736 4.75 10 4.75C11.2426 4.75 12.25 5.75736 12.25 7C12.25 8.24264 11.2426 9.25 10 9.25C8.75736 9.25 7.75 8.24264 7.75 7Z" :fill="playerColors[color]"/>
                </template>
            </template>
        </svg>
    </div>
</template>

<script>

import colors from '../../helpers/colors.js'

export default {
    mixins: [
        colors, 
    ],
    props: {
        color: String,
        level: Number,
        active: Boolean,
        highlighted: Boolean,
    },
    data() {
        return {
 
        }
    }
}
</script>

<style>

</style>