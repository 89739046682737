export default {
    methods: {
        buildSomeStuff() {
            let boats = true;
            if(this.buildStuff == 1) {
                if(this.addedPlayers.find(player => player.color == 'orange')) {
                    this.buildHouse(this.selectTile(5,6), 5, this.selectPlayer('orange'), true);
                    this.buildHouse(this.selectTile(4,4), 0, this.selectPlayer('orange'), true);
                    this.buildHouse(this.selectTile(7,6), 3, this.selectPlayer('orange'), true);
                    this.upgradeToCity(this.selectTile(7,6), 3, this.selectPlayer('orange'), true);
                    this.upgradeToCity(this.selectTile(4,4), 0, this.selectPlayer('orange'), true);
                    // this.buildRoad(this.selectTile(4,4), 8, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(5,6), 4, this.selectPlayer('orange'), true);
                    this.buildBoat(this.selectTile(4,4), 0, this.selectPlayer('orange'), true);
                    this.buildBoat(this.selectTile(4,4), 5, this.selectPlayer('orange'), true);
                    // this.buildRoad(this.selectTile(4,4), 1, this.selectPlayer('orange'), true);

                    // this.buildHouse(this.selectTile(6,4), 0, this.selectPlayer('orange'), true);

                    // this.buildRoad(this.selectTile(6,4), 5, this.selectPlayer('orange'), true);
                    // this.buildRoad(this.selectTile(5,6), 8, this.selectPlayer('orange'), true);
                    // this.buildRoad(this.selectTile(6,4), 0, this.selectPlayer('orange'), true);
                    // this.buildRoad(this.selectTile(5,6), 1, this.selectPlayer('orange'), true);
                    // this.buildRoad(this.selectTile(6,4), 7, this.selectPlayer('orange'), true);
                    // this.buildRoad(this.selectTile(6,4), 4, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(7,6), 2, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(7,6), 1, this.selectPlayer('orange'), true);
                    this.buildKnight(this.selectTile(7,6), 2, this.selectPlayer('orange'), true);
                    // this.buildKnight(this.selectTile(5,6), 2, this.selectPlayer('orange'), true);
                    // this.activateKnight(this.selectTile(5,6), 2, true);
                    // this.upgradeKnight(this.selectTile(6,4), 1, this.selectPlayer('orange'), true);
                    // this.activateKnight(this.selectTile(6,4), 1, true);
                    // this.buildRoad(this.selectTile(5,6), 5, this.selectPlayer('orange'), true);
                    // this.buildRoad(this.selectTile(7,3), 7, this.selectPlayer('orange'), true);
                    // this.buildHouse(this.selectTile(7,3), 1, this.selectPlayer('orange'), true);
                    // this.buildRoad(this.selectTile(5,6), 3, this.selectPlayer('orange'), true);

                    this.buildKnight(this.selectTile(6,4), 5, this.selectPlayer('orange'), true);
                    this.upgradeKnight(this.selectTile(6,4), 5, this.selectPlayer('orange'), true);

                    this.buildRoad(this.selectTile(5,6), 5, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 7, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 0, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 5, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 4, this.selectPlayer('orange'), true);
                    // this.buildRoad(this.selectTile(6,4), 3, this.selectPlayer('orange'), true);
                    this.buildKnight(this.selectTile(5,6), 4, this.selectPlayer('orange'), true);
                    this.upgradeKnight(this.selectTile(5,6), 4, this.selectPlayer('orange'), true);
                    // this.activateKnight(this.selectTile(5,6), 4);


                    // this.buildRoad(this.selectTile(5,6), 1, this.selectPlayer('orange'), true);
                    // this.buildRoad(this.selectTile(6,4), 4, this.selectPlayer('orange'), true);
                    // this.buildRoad(this.selectTile(7,6), 7, this.selectPlayer('orange'), true);
                    // this.buildKnight(this.selectTile(5,6), 2, this.selectPlayer('orange'), true);
                    // this.activateKnight(this.selectTile(5,6), 2);
                    // this.buildKnight(this.selectTile(4,4), 2, this.selectPlayer('orange'), true);

                    if(boats) {
                        // this.buildBoat(this.selectTile(7,3), 1, this.selectPlayer('orange'), true);
                        // this.buildKnight(this.selectTile(7,3), 2, this.selectPlayer('orange'), true);
                        // this.activateKnight(this.selectTile(7,3), 2, true);
                    }
                }

                if(this.addedPlayers.find(player => player.color == 'red')) {
                    this.buildCity(this.selectTile(4,4), 4, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(5,6), 7, this.selectPlayer('red'), true);
                    this.buildHouse(this.selectTile(7,6), 2, this.selectPlayer('red'), true);
                    this.buildHouse(this.selectTile(7,6), 0, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(7,6), 1, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(7,6), 0, this.selectPlayer('red'), true);
                    // this.buildRoad(this.selectTile(7,6), 6, this.selectPlayer('red'), true);
                    // this.buildRoad(this.selectTile(5,6), 0, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(5,6), 5, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(5,6), 4, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(5,0), 7, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(5,0), 0, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(5,6), 6, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(2,1), 2, this.selectPlayer('red'), true);
                    // this.buildRoad(this.selectTile(2,1), 1, this.selectPlayer('red'), true);
                    // this.buildRoad(this.selectTile(7,6), 5, this.selectPlayer('red'), true);
                    // this.buildKnight(this.selectTile(5,6), 5, this.selectPlayer('red'), true);
                    // this.upgradeKnight(this.selectTile(5,6), 5, this.selectPlayer('red'), true);
                    this.buildKnight(this.selectTile(7,6), 1, this.selectPlayer('red'), true);
                    // this.activateKnight(this.selectTile(7,6), 1, true);
                    this.buildKnight(this.selectTile(5,6), 1, this.selectPlayer('red'), true);
                    // this.activateKnight(this.selectTile(5,6), 1, true);
                    // this.upgradeKnight(this.selectTile(5,6), 1, this.selectPlayer('red'), true);

                    this.buildHouse(this.selectTile(8,4), 4, this.selectPlayer('red'), true);

                    if(boats) {
                        this.buildBoat(this.selectTile(8,4), 4, this.selectPlayer('red'), true);
                        this.buildBoat(this.selectTile(8,4), 5, this.selectPlayer('red'), true);
                    }
                }

                if(this.addedPlayers.find(player => player.color == 'blue')) {
                    this.buildHouse(this.selectTile(6,4), 3, this.selectPlayer('blue'), true);
                    // this.buildHouse(this.selectTile(6,4), 0, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(6,4), 2, this.selectPlayer('blue'), true);
                    // this.buildKnight(this.selectTile(6,4), 2, this.selectPlayer('blue'), true);
                    // this.upgradeKnight(this.selectTile(6,4), 2, this.selectPlayer('blue'), true);
                    // this.activateKnight(this.selectTile(6,4), 2);

                    this.buildCity(this.selectTile(5,6), 1, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(5,6), 1, this.selectPlayer('blue'), true);

                    this.buildRoad(this.selectTile(6,4), 1, this.selectPlayer('blue'), true);
                    // this.buildKnight(this.selectTile(6,4), 1, this.selectPlayer('blue'), true);
                    // this.upgradeKnight(this.selectTile(6,4), 1, this.selectPlayer('blue'), true);
                    // this.upgradeKnight(this.selectTile(6,4), 1, this.selectPlayer('blue'), true);
                    // this.activateKnight(this.selectTile(6,4), 2);
                    // this.buildRoad(this.selectTile(5,6), 2, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(6,4), 6, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(6,4), 5, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(6,4), 4, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(5,6), 0, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(5,6), 6, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(2,4), 3, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(2,4), 4, this.selectPlayer('blue'), true);

                    this.buildRoad(this.selectTile(3,6), 2, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(3,6), 8, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(4,7), 4, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(5,6), 7, this.selectPlayer('blue'), true);

                    this.buildKnight(this.selectTile(5,6), 0, this.selectPlayer('blue'), true);
                    this.upgradeKnight(this.selectTile(5,6), 0, this.selectPlayer('blue'), true);
                    // this.activateKnight(this.selectTile(5,6), 0);



                    if(boats) {
                        this.buildBoat(this.selectTile(8,4), 7, this.selectPlayer('blue'), true);
                        this.buildBoat(this.selectTile(8,4), 6, this.selectPlayer('blue'), true);
                        this.buildBoat(this.selectTile(8,4), 8, this.selectPlayer('blue'), true);
                        this.buildBoat(this.selectTile(8,4), 0, this.selectPlayer('blue'), true);
                        this.buildBoat(this.selectTile(8,4), 1, this.selectPlayer('blue'), true);
                    }

                    // this.buildKnight(this.selectTile(5,6), 0, this.selectPlayer('blue'), true);

                    // this.buildKnight(this.selectTile(6,4), 4, this.selectPlayer('blue'), true);
                    // this.buildRoad(this.selectTile(6,4), 3, this.selectPlayer('blue'), true);
                }

                if(this.addedPlayers.find(player => player.color == 'white')) {
                    this.buildHouse(this.selectTile(4,4), 1, this.selectPlayer('white'), true);

                    this.buildCity(this.selectTile(1,2), 3, this.selectPlayer('white'), true);
                    this.buildHouse(this.selectTile(2,4), 4, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(2,4), 3, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(2,4), 2, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(2,4), 1, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(2,4), 0, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(2,4), 5, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(2,4), 4, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(2,4), 6, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(2,4), 7, this.selectPlayer('white'), true);
                    // this.buildRoad(this.selectTile(4,4), 6, this.selectPlayer('white'), true);
                    // this.buildRoad(this.selectTile(4,4), 0, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(4,4), 7, this.selectPlayer('white'), true);
                    this.buildKnight(this.selectTile(4,4), 1, this.selectPlayer('white'), true);
                    this.upgradeKnight(this.selectTile(4,4), 1, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(1,2), 3, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(1,2), 4, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(2,1), 7, this.selectPlayer('white'), true);
                    // this.buildRoad(this.selectTile(2,1), 1, this.selectPlayer('white'), true);
                    // this.buildKnight(this.selectTile(2,1), 2, this.selectPlayer('white'), true);
                    // this.buildRoad(this.selectTile(1,2), 4, this.selectPlayer('white'), true);
                    // this.buildRoad(this.selectTile(1,2), 5, this.selectPlayer('white'), true);
                }

            } else if(this.buildStuff == 2) {
                if(this.addedPlayers.find(player => player.color == 'orange')) {
                    this.buildCity(this.selectTile(5,6), 4, this.selectPlayer('orange'), true);

                    this.buildHouse(this.selectTile(6,4), 0, this.selectPlayer('orange'), true);

                    this.buildRoad(this.selectTile(6,4), 5, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(5,6), 8, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 0, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 7, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(5,6), 3, this.selectPlayer('orange'), true);
                    this.buildHouse(this.selectTile(6,4), 5, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 6, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(5,6), 4, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 6, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 5, this.selectPlayer('orange'), true);

                    this.buildRoad(this.selectTile(4,4), 2, this.selectPlayer('orange'), true);
                    // this.buildRoad(this.selectTile(4,4), 8, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 4, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(7,6), 7, this.selectPlayer('orange'), true);


                    this.buildRoad(this.selectTile(4,4), 1, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(4,4), 0, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(4,4), 7, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(4,4), 6, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(2,4), 4, this.selectPlayer('orange'), true);

                    this.buildRoad(this.selectTile(5,6), 1, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 4, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(7,6), 7, this.selectPlayer('orange'), true);
                    this.buildHouse(this.selectTile(2,4), 5, this.selectPlayer('orange'), true);

                    this.buildRoad(this.selectTile(5,6), 5, this.selectPlayer('orange'), true);
                }

                if(this.addedPlayers.find(player => player.color == 'white')) {
                    this.buildHouse(this.selectTile(1,2), 3, this.selectPlayer('white'), true);

                    this.buildRoad(this.selectTile(4,4), 6, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(4,4), 0, this.selectPlayer('white'), true);
                    this.buildKnight(this.selectTile(4,4), 1, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(4,4), 7, this.selectPlayer('white'), true);
                    this.buildCity(this.selectTile(7,2), 1, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(2,4), 4, this.selectPlayer('white'), true);

                    this.buildHouse(this.selectTile(4,4), 5, this.selectPlayer('white'), true);
                    this.buildHouse(this.selectTile(2,4), 5, this.selectPlayer('white'), true);
                }

                if(this.addedPlayers.find(player => player.color == 'red')) {
                    this.buildCity(this.selectTile(4,4), 4, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(5,6), 7, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(5,6), 0, this.selectPlayer('red'), true);
                    this.buildHouse(this.selectTile(7,6), 2, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(7,6), 1, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(7,6), 0, this.selectPlayer('red'), true);
                    this.buildKnight(this.selectTile(5,6), 1, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(7,6), 6, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(7,6), 5, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(7,6), 4, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(7,6), 8, this.selectPlayer('red'), true);
                }

                if(this.addedPlayers.find(player => player.color == 'blue')) {
                    this.buildCity(this.selectTile(6,4), 3, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(6,4), 2, this.selectPlayer('blue'), true);

                    this.buildCity(this.selectTile(5,6), 1, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(5,6), 1, this.selectPlayer('blue'), true);
                    this.buildKnight(this.selectTile(5,6), 2, this.selectPlayer('blue'), true);


                    this.buildRoad(this.selectTile(6,4), 1, this.selectPlayer('blue'), true);

                    // this.buildKnight(this.selectTile(6,4), 1, this.selectPlayer('blue'), true);
                    // this.upgradeKnight(this.selectTile(6,4), 1, this.selectPlayer('blue'), true);
                    // this.activateKnight(this.selectTile(6,4), 1);
                }

            } else if(this.buildStuff == 3) {
                if(this.addedPlayers.find(player => player.color == 'orange')) {
                    this.buildCity(this.selectTile(5,6), 4, this.selectPlayer('orange'), true);
                    // this.buildRoad(this.selectTile(4,4), 8, this.selectPlayer('orange'), true);
                    // this.buildRoad(this.selectTile(4,4), 1, this.selectPlayer('orange'), true);

                    this.buildHouse(this.selectTile(6,4), 0, this.selectPlayer('orange'), true);

                    this.buildRoad(this.selectTile(6,4), 5, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(5,6), 8, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 0, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 7, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(5,6), 3, this.selectPlayer('orange'), true);
                    this.buildHouse(this.selectTile(6,4), 5, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 6, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(5,6), 4, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 6, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 5, this.selectPlayer('orange'), true);

                    this.buildRoad(this.selectTile(4,4), 2, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(4,4), 8, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(6,4), 4, this.selectPlayer('orange'), true);
                    this.buildRoad(this.selectTile(7,6), 7, this.selectPlayer('orange'), true);
                }

                if(this.addedPlayers.find(player => player.color == 'white')) {
                    this.buildHouse(this.selectTile(1,2), 3, this.selectPlayer('white'), true);

                    this.buildRoad(this.selectTile(4,4), 6, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(4,4), 0, this.selectPlayer('white'), true);
                    this.buildKnight(this.selectTile(4,4), 1, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(4,4), 7, this.selectPlayer('white'), true);
                    this.buildCity(this.selectTile(7,2), 1, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(2,4), 4, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(1,2), 2, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(1,2), 1, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(1,2), 0, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(1,2), 5, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(1,2), 4, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(1,2), 3, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(1,2), 8, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(2,1), 7, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(2,1), 1, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(2,1), 8, this.selectPlayer('white'), true);
                    this.buildRoad(this.selectTile(4,4), 5, this.selectPlayer('white'), true);

                    this.buildHouse(this.selectTile(4,4), 5, this.selectPlayer('white'), true);
                    this.buildHouse(this.selectTile(2,4), 5, this.selectPlayer('white'), true);
                }

                if(this.addedPlayers.find(player => player.color == 'red')) {
                    this.buildCity(this.selectTile(4,4), 4, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(5,6), 7, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(5,6), 0, this.selectPlayer('red'), true);
                    this.buildHouse(this.selectTile(7,6), 2, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(7,6), 1, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(7,6), 0, this.selectPlayer('red'), true);
                    this.buildKnight(this.selectTile(5,6), 1, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(7,6), 6, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(7,6), 5, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(7,6), 4, this.selectPlayer('red'), true);
                    this.buildRoad(this.selectTile(7,6), 8, this.selectPlayer('red'), true);
                }

                if(this.addedPlayers.find(player => player.color == 'blue')) {
                    this.buildCity(this.selectTile(6,4), 3, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(6,4), 2, this.selectPlayer('blue'), true);

                    this.buildCity(this.selectTile(5,6), 1, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(5,6), 1, this.selectPlayer('blue'), true);

                    this.buildRoad(this.selectTile(6,4), 1, this.selectPlayer('blue'), true);

                    this.buildRoad(this.selectTile(3,6), 2, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(3,6), 3, this.selectPlayer('blue'), true);
                    this.buildRoad(this.selectTile(3,6), 8, this.selectPlayer('blue'), true);

                    // this.upgradeKnight(this.selectTile(6,4), 1, this.selectPlayer('blue'), true);
                    // this.activateKnight(this.selectTile(6,4), 1);
                }
            }

            // disable all cities
            // this.cities.forEach(city => city.disabled = true);
        },
        givePlayersSomeHandCards(extra = 0) {

            let player;

            let moreCards = 0;

            if(this.addedPlayers.find(player => player.color == 'orange')) {
                player = this.selectPlayer('orange');

                player.handCards.find(card => card.type == 'lumber').amount = 1 + moreCards;
                player.handCards.find(card => card.type == 'brick').amount = 1 + moreCards;
                player.handCards.find(card => card.type == 'wool').amount = 1 + moreCards;
                player.handCards.find(card => card.type == 'grain').amount = 1 + moreCards;
                player.handCards.find(card => card.type == 'ore').amount = 1 + moreCards;
                player.handCards.find(card => card.type == 'cloth').amount = 15 + moreCards;
                player.handCards.find(card => card.type == 'coin').amount = 3 + moreCards;
                player.handCards.find(card => card.type == 'paper').amount = 1 + moreCards;
            }

            if(this.addedPlayers.find(player => player.color == 'blue')) {
                player = this.selectPlayer('blue');

                player.handCards.find(card => card.type == 'lumber').amount = 2 + moreCards;
                player.handCards.find(card => card.type == 'brick').amount = 2 + moreCards;
                player.handCards.find(card => card.type == 'wool').amount = 2 + moreCards;
                player.handCards.find(card => card.type == 'grain').amount = 0 + moreCards;
                player.handCards.find(card => card.type == 'ore').amount = 2 + moreCards;
                player.handCards.find(card => card.type == 'cloth').amount = 0 + moreCards;
                player.handCards.find(card => card.type == 'coin').amount = 0 + moreCards;
                player.handCards.find(card => card.type == 'paper').amount = 0 + moreCards;
            }

            if(this.addedPlayers.find(player => player.color == 'red')) {
                player = this.selectPlayer('red');

                player.handCards.find(card => card.type == 'lumber').amount = 2 + moreCards;
                player.handCards.find(card => card.type == 'brick').amount = 35 + moreCards;
                player.handCards.find(card => card.type == 'wool').amount = 1 + moreCards;
                player.handCards.find(card => card.type == 'grain').amount = 1 + moreCards;
                player.handCards.find(card => card.type == 'ore').amount = 0 + moreCards;
                player.handCards.find(card => card.type == 'cloth').amount = 0 + moreCards;
                player.handCards.find(card => card.type == 'coin').amount = 0 + moreCards;
                player.handCards.find(card => card.type == 'paper').amount = 0 + moreCards;
            }

            if(this.addedPlayers.find(player => player.color == 'white')) {
                player = this.selectPlayer('white');

                player.handCards.find(card => card.type == 'lumber').amount = 2 + moreCards;
                player.handCards.find(card => card.type == 'brick').amount = 2 + moreCards;
                player.handCards.find(card => card.type == 'wool').amount = 2 + moreCards;
                player.handCards.find(card => card.type == 'grain').amount = 2 + moreCards;
                player.handCards.find(card => card.type == 'ore').amount = 0 + moreCards;
                player.handCards.find(card => card.type == 'cloth').amount = 0 + moreCards;
                // player.handCards.find(card => card.type == 'coin').amount = 3 + extra + moreCards;
                player.handCards.find(card => card.type == 'paper').amount = 0 + extra + moreCards;

                // player.handCards.find(card => card.type == 'cloth').amount = 0 + extra + moreCards;
                // player.handCards.find(card => card.type == 'coin').amount = 1 + extra + moreCards;
                // player.handCards.find(card => card.type == 'paper').amount = 1 + extra + moreCards;
            }

        },
        moveBoatToLastSpot() {
            if(!this.devMode) return;

            this.boatPosition = 6;
        },
        givePlayerPoint(player) {
            if(!this.devMode) return;

            if(this.gamePhase == 'game over') return;

            player.pointCards.push({ label: 'war', points: 1 });
            this.updatePlayersPoints();
        },
        givePlayersSomeProgressCards(extra = 0) {
            let player;

            if(this.addedPlayers.find(player => player.color == 'white')) {
                player = this.selectPlayer('white');

                for(let i = 0; i < extra; i++) {
                    this.givePlayerProgressCard(player, 'paper');
                }
            }

            if(this.addedPlayers.find(player => player.color == 'orange')) {
                player = this.selectPlayer('orange');

                for(let i = 0; i < extra; i++) {
                    this.givePlayerProgressCard(player, 'cloth');
                    this.givePlayerProgressCard(player, 'cloth');
                    this.givePlayerProgressCard(player, 'coin');
                    this.givePlayerProgressCard(player, 'paper');
                }
            }
            if(this.addedPlayers.find(player => player.color == 'blue')) {
                player = this.selectPlayer('blue');

                for(let i = 0; i < extra; i++) {
                    this.givePlayerProgressCard(player, 'cloth');
                    this.givePlayerProgressCard(player, 'cloth');
                    this.givePlayerProgressCard(player, 'coin');
                    this.givePlayerProgressCard(player, 'paper');
                }
            }

            if(this.addedPlayers.find(player => player.color == 'red')) {
                player = this.selectPlayer('red');

                for(let i = 0; i < extra; i++) {
                    this.givePlayerProgressCard(player, 'coin');
                }
            }
        },
        rotateTile(tile) {
            tile.direction = this.nextTileDirection(tile.direction);
        },
    }
 }
