import VueRouter from 'vue-router'
import routes from './routes'

const Router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active',
    routes
})

export default Router