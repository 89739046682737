var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.forceUpdate)?_c('span'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"road-action-spot-wrapper"},[(_vm.tile.roadSpots[_vm.roadSpotPosition].showMenu)?_c('div',{staticClass:"road-actions-wrapper"},[(
                    _vm.lastClickedRoadSpotTiles.find(function (tile) { return ['land'].includes(tile.group); }) &&
                    (_vm.lastClickedRoadSpotConnectingRoads.find(function (road) { return road && !road.boat; }) ||
                    _vm.lastClickedRoadSpotConnectingSpots.find(function (spot) { return spot && spot.content.group == 'building' && spot.content.player == this$1.activePlayer; }))
                )?_c('div',{staticClass:"action",class:[{ 'action-confirmed': _vm.actionConfirmed == 'road' }],on:{"click":function($event){return _vm.roadSpotActionClicked(_vm.tile, _vm.roadSpotPosition)}}},[_c('div',{staticClass:"item road-item"},[_c('Road3D',{staticClass:"road-menu-item",class:[
                        { 'opacity-30': _vm.activePlayer.pieces.roads == 0 } ],attrs:{"color":_vm.activePlayer.color}}),_vm._v(" "),_c('div',{staticClass:"items-left",staticStyle:{"left":"33px","top":"0px"}},[_vm._v(_vm._s(_vm.activePlayer.pieces.roads))])],1),_vm._v(" "),(_vm.flatGraphics)?_c('div',{staticClass:"action-cost"},[_c('div',{staticClass:"symbol lumber"}),_vm._v(" "),_c('div',{staticClass:"symbol brick"})]):_c('div',{staticClass:"action-cost-2"},[_c('div',{staticClass:"symbol-2 lumber-2"}),_vm._v(" "),_c('div',{staticClass:"symbol-2 brick-2"})])]):_vm._e(),_vm._v(" "),(
                _vm.seafaresExpansion &&
                _vm.lastClickedRoadSpotTiles.find(function (tile) { return ['water', 'harbor'].includes(tile.group); }) &&
                (_vm.lastClickedRoadSpotConnectingRoads.find(function (road) { return road && road.boat; }) ||
                _vm.lastClickedRoadSpotConnectingSpots.find(function (spot) { return spot && spot.content.group == 'building' && spot.content.player == this$1.activePlayer; }))
                )?_c('div',{staticClass:"action",on:{"click":function($event){return _vm.boatSpotActionClicked(_vm.tile, _vm.roadSpotPosition)}}},[_c('div',{staticClass:"item boat-item"},[_c('Boat3D',{staticClass:"boat-menu-item",class:[
                        { 'opacity-30': _vm.activePlayer.pieces.boats == 0 || _vm.lastClickedRoadSpotTiles.find(function (tile) { return tile == _vm.pirate.tile; }) } ],attrs:{"color":_vm.activePlayer.color}}),_vm._v(" "),_c('div',{staticClass:"items-left",staticStyle:{"left":"33px","top":"0px"}},[_vm._v(_vm._s(_vm.activePlayer.pieces.boats))])],1),_vm._v(" "),(_vm.flatGraphics)?_c('div',{staticClass:"action-cost"},[_c('div',{staticClass:"symbol lumber"}),_vm._v(" "),_c('div',{staticClass:"symbol wool"})]):_c('div',{staticClass:"action-cost-2"},[_c('div',{staticClass:"symbol-2 lumber-2"}),_vm._v(" "),_c('div',{staticClass:"symbol-2 wool-2"})])]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }