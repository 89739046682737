export default {
    methods: {
        spotTiles(tile, spotPosition) {
            if(!tile) return;
            let rowAbove = tile.row-1;
            let rowBelow = tile.row+1;
            let nextTile = tile.position+1;
            let previousTile = tile.position-1;

            let neighbour0;
            let neighbour1;

            if(tile.row % 2 == 0) {
                if(spotPosition == 0) {
                    neighbour0 = this.selectTile(rowAbove, tile.position);
                    neighbour1 = this.selectTile(rowAbove, tile.position+1);
                } else if(spotPosition == 1) {
                    neighbour0 = this.selectTile(rowAbove, tile.position+1);
                    neighbour1 = this.selectTile(tile.row, nextTile);
                } else if(spotPosition == 2) {
                    neighbour0 = this.selectTile(tile.row, nextTile);
                    neighbour1 = this.selectTile(rowBelow, nextTile);
                } else if(spotPosition == 3) {
                    neighbour0 = this.selectTile(rowBelow, tile.position+1);
                    neighbour1 = this.selectTile(rowBelow, tile.position);
                } else if(spotPosition == 4) {
                    neighbour0 = this.selectTile(rowBelow, tile.position);
                    neighbour1 = this.selectTile(tile.row, previousTile);
                } else if(spotPosition == 5) {
                    neighbour0 = this.selectTile(tile.row, previousTile);
                    neighbour1 = this.selectTile(rowAbove, tile.position);
                }
            } else {
                if(spotPosition == 0) {
                    neighbour0 = this.selectTile(rowAbove, tile.position-1);
                    neighbour1 = this.selectTile(rowAbove, tile.position);
                } else if(spotPosition == 1) {
                    neighbour0 = this.selectTile(rowAbove, tile.position);
                    neighbour1 = this.selectTile(tile.row, nextTile);
                } else if(spotPosition == 2) {
                    neighbour0 = this.selectTile(tile.row, nextTile);
                    neighbour1 = this.selectTile(rowBelow, tile.position);
                } else if(spotPosition == 3) {
                    neighbour0 = this.selectTile(rowBelow, tile.position);
                    neighbour1 = this.selectTile(rowBelow, tile.position-1);
                } else if(spotPosition == 4) {
                    neighbour0 = this.selectTile(rowBelow, tile.position-1);
                    neighbour1 = this.selectTile(tile.row, previousTile);
                } else if(spotPosition == 5) {
                    neighbour0 = this.selectTile(tile.row, previousTile);
                    neighbour1 = this.selectTile(rowAbove, tile.position-1);
                }
            }

            return [tile, neighbour0, neighbour1];
        },
        roadTiles(tile, roadSpotPosition) {
            let tile0;
            let tile1;

            let rowAbove = tile.row-1;
            let rowBelow = tile.row+1;
            let nextTile = tile.position+1;
            let previousTile = tile.position-1;

            if(tile.row % 2 == 0) {
                // roads around actionTile
                if(roadSpotPosition <= 5) {
                    tile0 = tile;

                    if(roadSpotPosition == 0) {
                        tile1 = this.selectTile(rowAbove, tile.position+1);
                    } else if(roadSpotPosition == 1) {
                        tile1 = this.selectTile(tile.row, nextTile);
                    } else if(roadSpotPosition == 2) {
                        tile1 = this.selectTile(rowBelow, tile.position+1);
                    } else if(roadSpotPosition == 3) {
                        tile1 = this.selectTile(rowBelow, tile.position);
                    } else if(roadSpotPosition == 4) {
                        tile1 = this.selectTile(tile.row, previousTile);
                    } else if(roadSpotPosition == 5) {
                        tile1 = this.selectTile(rowAbove, tile.position);
                    }
                // roads pointing away from actionTile
                } else {
                    if(roadSpotPosition == 6) {
                        tile0 = this.selectTile(rowAbove, tile.position);
                        tile1 = this.selectTile(rowAbove, tile.position+1);
                    } else if(roadSpotPosition == 7) {
                        tile0 = this.selectTile(rowAbove, tile.position+1);
                        tile1 = this.selectTile(tile.row, nextTile);
                    } else if(roadSpotPosition == 8) {
                        tile0 = this.selectTile(tile.row, nextTile);
                        tile1 = this.selectTile(rowBelow, tile.position+1);
                    }
                }
            } else {
                // roads around actionTile
                if(roadSpotPosition <= 5) {
                    tile0 = tile;

                    if(roadSpotPosition == 0) {
                        tile1 = this.selectTile(rowAbove, tile.position);
                    } else if(roadSpotPosition == 1) {
                        tile1 = this.selectTile(tile.row, nextTile);
                    } else if(roadSpotPosition == 2) {
                        tile1 = this.selectTile(rowBelow, tile.position);
                    } else if(roadSpotPosition == 3) {
                        tile1 = this.selectTile(rowBelow, tile.position-1);
                    } else if(roadSpotPosition == 4) {
                        tile1 = this.selectTile(tile.row, previousTile);
                    } else if(roadSpotPosition == 5) {
                        tile1 = this.selectTile(rowAbove, previousTile);
                    }
                // roads pointing away from actionTile
                } else {
                    if(roadSpotPosition == 6) {
                        tile0 = this.selectTile(rowAbove, tile.position);
                        tile1 = this.selectTile(rowAbove, tile.position-1);
                    } else if(roadSpotPosition == 7) {
                        tile0 = this.selectTile(rowAbove, tile.position);
                        tile1 = this.selectTile(tile.row, nextTile);
                    } else if(roadSpotPosition == 8) {
                        tile0 = this.selectTile(tile.row, nextTile);
                        tile1 = this.selectTile(rowBelow, tile.position);
                    }
                }
            }

            return [tile0, tile1];
        },
        spotRoadsAll(tile, spotPosition) {

            let road0;
            let road1;
            let road2;

            let nextActionTile;

            // road0, road1 and road2 are picked in different orders sometimes so they always follow the clock (when you look at the map and the 3 roads)
            if(spotPosition == 0) {
                road0 = { tile: tile, roadSpot: tile.roadSpots[this.previousRoadSpot(spotPosition, 3)] };
                road1 = { tile: tile, roadSpot: tile.roadSpots[spotPosition] };
                road2 = { tile: tile, roadSpot: tile.roadSpots[this.previousRoadSpot(spotPosition, 4)] };

            } else if(spotPosition == 1) {

                road0 = { tile: tile, roadSpot: tile.roadSpots[this.previousRoadSpot(spotPosition, 3)] };
                road1 = { tile: tile, roadSpot: tile.roadSpots[spotPosition] };
                road2 = { tile: tile, roadSpot: tile.roadSpots[this.previousRoadSpot(spotPosition)] };

            } else if(spotPosition == 2) {

                road0 = { tile: tile, roadSpot: tile.roadSpots[this.previousRoadSpot(spotPosition)] };
                road1 = { tile: tile, roadSpot: tile.roadSpots[this.previousRoadSpot(spotPosition, 3)] };
                road2 = { tile: tile, roadSpot: tile.roadSpots[spotPosition] };

            } else if(spotPosition == 3) {

                road0 = { tile: tile, roadSpot: tile.roadSpots[this.previousRoadSpot(spotPosition)] };

                // get the next actionTile
                nextActionTile = this.actionTileBySpotPosition(tile, spotPosition);

                // check if actionTile exists
                if(nextActionTile.actionTile) {
                    // pick the closest road from the next actionTile
                    // road1 = this.roads.find(road => road.tile == nextActionTile && road.roadSpot == nextActionTile.connectingRoadSpotPosition);
                    road1 = { tile: nextActionTile, roadSpot: nextActionTile.roadSpots[nextActionTile.connectingRoadSpotPosition] };
                }

                road2 = { tile: tile, roadSpot: tile.roadSpots[spotPosition] };

            } else if(spotPosition == 4 || spotPosition == 5) {

                road0 = { tile: tile, roadSpot: tile.roadSpots[spotPosition] };
                road1 = { tile: tile, roadSpot: tile.roadSpots[this.previousRoadSpot(spotPosition)] };

                nextActionTile = this.actionTileBySpotPosition(tile, spotPosition);

                if(nextActionTile.actionTile) {
                    // road2 = this.roads.find(road => road.tile == nextActionTile && road.roadSpot == nextActionTile.connectingRoadSpotPosition);
                    road2 = { tile: nextActionTile, roadSpot: nextActionTile.roadSpots[nextActionTile.connectingRoadSpotPosition] };
                }
            }
            return [road0, road1, road2];
        },
        spotSpots(tile, spotPosition) {
            let checkedSpots = [];
            let spotSpots = [];

            checkedSpots.push(this.spot(tile, spotPosition).key);

            this.spotRoadsAll(tile, spotPosition).forEach(roadSpot => {
                if(!roadSpot) return;

                this.roadSpots(roadSpot.tile, roadSpot.roadSpot.position).forEach(spot => {
                    if(!spot) return;
                    if(!checkedSpots.includes(spot.key)) {

                        spot.land = false;
                        if(this.spotTiles(spot.tile, spot.position).filter(tile => tile.group == 'land').length) spot.land = true;

                        spot.water = false;
                        if(this.spotTiles(spot.tile, spot.position).filter(tile => ['water', 'harbor'].includes(tile.group)).length) spot.water = true;

                        spot.roadBlocking = false;
                        if(this.roads.find(road => road.key == roadSpot.roadSpot.key)) spot.roadBlocking = true;

                        spot.ownContent = false;
                        if(this.spotRoads(spot.tile, spot.position).find(road => road && road.player == this.activePlayer) || spot.content.player == this.activePlayer) spot.ownContent = true;

                        spot.buildingDistance = false;
                        if(this.buildingDistance(spot.tile, spot.position)) spot.buildingDistance = true;

                        spot.buildingDistanceBoats = false;
                        if(this.buildingDistance(spot.tile, spot.position) && this.spotTiles(spot.tile, spot.position).find(spotTile => spotTile.group == 'land')) spot.buildingDistanceBoats = true;

                        spot.opponentRoad = false;
                        if(this.spotRoads(spot.tile, spot.position).find(road => road && road.player != this.activePlayer)) spot.opponentRoad = true;

                        spot.opponentContent = false;
                        if(spot.content.player != this.activePlayer && spot.content.type != undefined) spot.opponentContent = true;

                        spot.hiddenTiles = false;
                        if(this.spotTiles(spot.tile, spot.position).filter(tile => tile.group == 'land' && tile.hidden).length) spot.hiddenTiles = true;

                        spotSpots.push(spot);

                        // this.highlightSpot(spot.key, this.activePlayer);
                    }
                })
            })

            // this.focusModeOn('temp', this.activePlayer);
            return spotSpots;
        },
        spotRoads(tile, spotPosition) {
            let road0;
            let road1;
            let road2;

            let nextActionTile;

            // road0, road1 and road2 are picked in different orders sometimes so they always follow the clock (when you look at the map and the 3 roads)
            if(spotPosition == 0) {
                road0 = this.roads.find(road => road.tile == tile && road.roadSpot == this.previousRoadSpot(spotPosition, 3));
                road1 = this.roads.find(road => road.tile == tile && road.roadSpot == spotPosition);
                road2 = this.roads.find(road => road.tile == tile && road.roadSpot == this.previousRoadSpot(spotPosition, 4));

            } else if(spotPosition == 1) {

                road0 = this.roads.find(road => road.tile == tile && road.roadSpot == this.previousRoadSpot(spotPosition, 3));
                road1 = this.roads.find(road => road.tile == tile && road.roadSpot == spotPosition);
                road2 = this.roads.find(road => road.tile == tile && road.roadSpot == this.previousRoadSpot(spotPosition));

            } else if(spotPosition == 2) {

                road0 = this.roads.find(road => road.tile == tile && road.roadSpot == this.previousRoadSpot(spotPosition));
                road1 = this.roads.find(road => road.tile == tile && road.roadSpot == this.previousRoadSpot(spotPosition, 3));
                road2 = this.roads.find(road => road.tile == tile && road.roadSpot == spotPosition);

            } else if(spotPosition == 3) {

                road0 = this.roads.find(road => road.tile == tile && road.roadSpot == this.previousRoadSpot(spotPosition));

                // get the next actionTile
                nextActionTile = this.actionTileBySpotPosition(tile, spotPosition);

                // check if actionTile exists
                if(nextActionTile.actionTile) {
                    // pick the closest road from the next actionTile
                    road1 = this.roads.find(road => road.tile == nextActionTile && road.roadSpot == nextActionTile.connectingRoadSpotPosition);
                }

                road2 = this.roads.find(road => road.tile == tile && road.roadSpot == spotPosition);

            } else if(spotPosition == 4 || spotPosition == 5) {

                road0 = this.roads.find(road => road.tile == tile && road.roadSpot == spotPosition);
                road1 = this.roads.find(road => road.tile == tile && road.roadSpot == this.previousRoadSpot(spotPosition));

                nextActionTile = this.actionTileBySpotPosition(tile, spotPosition);

                if(nextActionTile.actionTile) {
                    road2 = this.roads.find(road => road.tile == nextActionTile && road.roadSpot == nextActionTile.connectingRoadSpotPosition);
                }
            }

            return [road0, road1, road2];
        },
        harborsBySpotPosition(tile, spotPosition) {
            let rowAbove = tile.row-1;
            let rowBelow = tile.row+1;
            let nextTile = tile.position+1;
            let previousTile = tile.position-1;

            let neighbour0;
            let neighbour1;

            if(tile.row % 2 == 0) {
                if(spotPosition == 0) {
                    neighbour0 = this.selectTile(rowAbove, tile.position);
                    neighbour1 = this.selectTile(rowAbove, tile.position+1);
                } else if(spotPosition == 1) {
                    neighbour0 = this.selectTile(rowAbove, tile.position+1);
                    neighbour1 = this.selectTile(tile.row, nextTile);
                } else if(spotPosition == 2) {
                    neighbour0 = this.selectTile(tile.row, nextTile);
                    neighbour1 = this.selectTile(rowBelow, nextTile);
                } else if(spotPosition == 3) {
                    neighbour0 = this.selectTile(rowBelow, tile.position+1);
                    neighbour1 = this.selectTile(rowBelow, tile.position);
                } else if(spotPosition == 4) {
                    neighbour0 = this.selectTile(rowBelow, tile.position);
                    neighbour1 = this.selectTile(tile.row, previousTile);
                } else if(spotPosition == 5) {
                    neighbour0 = this.selectTile(tile.row, previousTile);
                    neighbour1 = this.selectTile(rowAbove, tile.position);
                }
            } else {
                if(spotPosition == 0) {
                    neighbour0 = this.selectTile(rowAbove, tile.position-1);
                    neighbour1 = this.selectTile(rowAbove, tile.position);
                } else if(spotPosition == 1) {
                    neighbour0 = this.selectTile(rowAbove, tile.position);
                    neighbour1 = this.selectTile(tile.row, nextTile);
                } else if(spotPosition == 2) {
                    neighbour0 = this.selectTile(tile.row, nextTile);
                    neighbour1 = this.selectTile(rowBelow, tile.position);
                } else if(spotPosition == 3) {
                    neighbour0 = this.selectTile(rowBelow, tile.position);
                    neighbour1 = this.selectTile(rowBelow, tile.position-1);
                } else if(spotPosition == 4) {
                    neighbour0 = this.selectTile(rowBelow, tile.position-1);
                    neighbour1 = this.selectTile(tile.row, previousTile);
                } else if(spotPosition == 5) {
                    neighbour0 = this.selectTile(tile.row, previousTile);
                    neighbour1 = this.selectTile(rowAbove, tile.position-1);
                }
            }

            let harbors = [];
            if(tile.group == 'harbor') {
                if(tile.direction == spotPosition || tile.direction == this.previousSpot(spotPosition)) {
                    harbors.push(tile);
                }
            }

            // direction can be 0-5
            // spot can be 0-5
            // this is why we can calculate what direction harbor should have based on what spot we are on
            // basically these offsets is looping between 0-5 (position, steps)
            let offset1 = this.nextSpot(spotPosition);
            let offset2 = this.nextSpot(spotPosition, 2);
            let offset3 = this.nextSpot(spotPosition, 3);
            let offset4 = this.nextSpot(spotPosition, 4);

            if(neighbour0.group == 'harbor') {
                if(neighbour0.direction == offset1 || neighbour0.direction == offset2) {
                    harbors.push(neighbour0);
                }
            }

            if(neighbour1.group == 'harbor') {
                if(neighbour1.direction == offset3 || neighbour1.direction == offset4) {
                    harbors.push(neighbour1);
                }
            }

            return harbors;
        },
        nextSpot(spotPosition, steps = 1) {
            return this.crawl(spotPosition, steps);
        },
        previousSpot(spotPosition, steps = 1) {
            return this.crawl(spotPosition, steps, 'previous');
        },
        nextRoadSpot(roadPosition, steps = 1) {
            return this.crawl(roadPosition, steps, 'next', 8);
        },
        previousRoadSpot(roadPosition, steps = 1) {
            return this.crawl(roadPosition, steps, 'previous', 8);
        },
        nextTileDirection(direction, steps = 1) {
            return this.crawl(direction, steps);
        },
        previousTileDirection(direction, steps = 1) {
            return this.crawl(direction, steps, 'previous');
        },
        // with this function you can jump between spots, roadSpots and tileDirections
        // you can also get offsets with steps, for example, you are on spot 3 and want 4 steps forward, 4 -> 5 -> 0 -> 1 and result will be 1
        crawl(itemPosition, steps = 1, direction = 'next', itemsTotal = 5) {
            for(let i = 0; i < steps; i++) {
                if(direction == 'previous') {
                    if(itemPosition == 0) {
                        itemPosition = itemsTotal;
                    } else {
                        itemPosition--;
                    }
                } else {
                    if(itemPosition == itemsTotal) {
                        itemPosition = 0;
                    } else {
                        itemPosition++;
                    }
                }
            }
            return itemPosition;
        },
        actionTileBySpotPosition(tile, spotPosition) {

            let tilesInCurrentRow = this.countTilesInRow(tile.row);

            let actionTile = null;
            let selectedRow = null;
            let selectedPosition = null;

            if(tile.row % 2 == 0) {

                if(spotPosition == 0) {

                    selectedRow = tile.row-2;
                    selectedPosition = tile.position;

                } else if(spotPosition == 1) {

                    selectedRow = tile.row-1;
                    selectedPosition = tile.position+2;

                } else if(spotPosition == 2) {

                    selectedRow = tile.row+1;
                    selectedPosition = tile.position+2;

                } else if(spotPosition == 3) {

                    selectedRow = tile.row+2;
                    selectedPosition = tile.position;

                } else if(spotPosition == 4) {

                    selectedRow = tile.row+1;
                    selectedPosition = tile.position-1;

                } else if(spotPosition == 5) {

                    selectedRow = tile.row-1;
                    selectedPosition = tile.position-1;

                }
            } else {

                if(spotPosition == 0) {

                    selectedRow = tile.row-2;
                    selectedPosition = tile.position;

                } else if(spotPosition == 1) {

                    selectedRow = tile.row-1;
                    selectedPosition = tile.position+1;

                } else if(spotPosition == 2) {

                    selectedRow = tile.row+1;
                    selectedPosition = tile.position+1;

                } else if(spotPosition == 3) {

                    selectedRow = tile.row+2;
                    selectedPosition = tile.position;

                } else if(spotPosition == 4) {

                    selectedRow = tile.row+1;
                    selectedPosition = tile.position-2;

                } else if(spotPosition == 5) {

                    selectedRow = tile.row-1;
                    selectedPosition = tile.position-2;

                }
            }

            actionTile = this.selectTile(selectedRow, selectedPosition);

            // give the connecting spot from the actionTile we just got
            let connectingSpotPosition;
            let connectingRoadSpotPosition;

            if(spotPosition >= 3) {
                connectingSpotPosition = spotPosition - 3;
                connectingRoadSpotPosition = spotPosition + 3;

            } else {
                connectingSpotPosition = spotPosition + 3;

                // these 3 roads are already on the actionTile where the question is coming from
                connectingRoadSpotPosition = null;
            }

            if(actionTile) {
                actionTile.connectingSpotPosition = connectingSpotPosition;
                actionTile.connectingRoadSpotPosition = connectingRoadSpotPosition;
            }

            return actionTile;
        },
        // this gives the spot content, if content is undefined it's not helping us a lot
        roadSpots(tile, roadSpotPosition) {
            let spot0;
            let spot1;

            let nextActionTile;

            // roads around actionTile
            if(roadSpotPosition <= 5) {
                spot0 = this.spot(tile, roadSpotPosition);
                spot1 = this.spot(tile, this.nextSpot(roadSpotPosition));

            // roads 6, 7, 8 (pointing out from actionTile)
            } else {
                spot0 = this.spot(tile, roadSpotPosition-6);

                // -6,
                // 6 - 6 = 0
                // 7 - 6 = 1
                // 8 - 6 = 2
                // jumping from road to own spot
                // then finding nextActionTile from that spot
                nextActionTile = this.actionTileBySpotPosition(tile, roadSpotPosition-6);
                if(nextActionTile.actionTile) {
                    spot1 = this.spot(nextActionTile, nextActionTile.connectingSpotPosition);
                }
            }

            return [spot0, spot1];
        },
        spot(tile, spotPosition) {

            let spotContent;

            let knightFound = this.knights.find(knight => knight.tile == tile && knight.position == spotPosition);
            let houseFound = this.houses.find(house => house.tile == tile && house.position == spotPosition);
            let cityFound = this.cities.find(city => city.tile == tile && city.position == spotPosition);

            if(knightFound) {
                spotContent = knightFound;
            } else if(houseFound) {
                spotContent = houseFound;
            } else if(cityFound) {
                spotContent = cityFound;
            } else {
                spotContent = { type: null };
            }

            let dots = this.spotTiles(tile, spotPosition)
                .filter(tile => tile && tile.group == 'land' && tile.type != 'desert')
                .reduce((dotsTotal, tile) => dotsTotal + tile.number.dots, 0);

            let exponentialDots = this.spotTiles(tile, spotPosition)
                .filter(tile => tile && tile.group == 'land' && tile.type != 'desert')
                .reduce((dotsTotal, tile) => dotsTotal + tile.number.exponentialDots, 0);

            return {
                tile: tile,
                position: spotPosition,
                content: spotContent,
                dots: dots,
                exponentialDots: exponentialDots,
                key: this.generateKey(tile, spotPosition),
            };
        },
        generateKey(tile, position = null) {
            if(position != null) {
                return 'k'+tile.row + '' + tile.position + '' + position;
            } else {
                return 'k'+tile.row + '' + tile.position;
            }
        },
        logSpot(tile, spotPosition) {
            console.log('Tile row: ' + tile.row + ' Tile position: ' + tile.position);
            console.log('Spot position: ' + spotPosition);

            let spotContent = this.spot(tile, spotPosition).content;
            if(spotContent.type != null) {
                console.log('Spot content: ' + spotContent.player.color + ' ' + spotContent.type);
            }
        },
        roadSpot(tile, roadSpotPosition) {

            return { tile: tile, position: roadSpotPosition, key: this.generateKey(tile, roadSpotPosition) };

        },
        tileOffset(currentRow, selectedRow) {

            let tilesInCurrentRow = this.countTilesInRow(currentRow);
            let tilesInSelectedRow = this.countTilesInRow(selectedRow);

            let offset;
            if(tilesInCurrentRow < tilesInSelectedRow) {
                offset = 1;
            } else {
                offset = 0;
            }
            return offset;
        },
        doubleTileOffset(currentRow, selectedRow) {

            let tilesInCurrentRow = this.countTilesInRow(currentRow);
            let tilesInSelectedRow = this.countTilesInRow(selectedRow);

            let offset;
            if(tilesInCurrentRow < tilesInSelectedRow) {
                offset = 1;
            } else if(tilesInCurrentRow > tilesInSelectedRow) {
                offset = -1;
            } else {
                offset = 0;
            }
            return offset;
        },
        consoleLog(tile, spotPosition) {

            // spotTiles
            console.log('Tiles: ');
            let spots = this.spotTiles(tile, spotPosition);

            this.spotTiles(tile, spotPosition).forEach(tile => console.log(tile.type + ' ' + (tile.number.value || '')));

            // Harbors
            let harbors = this.harborsBySpotPosition(tile, spotPosition);

            if(harbors.length) {
                console.log(' ');
                console.log('Harbors: ');
                harbors.forEach(tile => console.log(tile.type));
            }

            // next actionTile
            let nextActionTile = this.actionTileBySpotPosition(tile, spotPosition);
        },
        highlightSpot(spotKey, player) {
            if(!player.highlightedSpots.includes(spotKey)) {
                player.highlightedSpots.push(spotKey);
            }
        },
        tileByKey(tileKey) {
            return this.tiles.find(tile => tile.key == tileKey);
        },
        spotByKey(spotKey) {

            let foundSpot = null;
            this.actionTiles.forEach(tile => {
                tile.spots.forEach((spot, index) => {
                    if(spot.key == spotKey) {
                        foundSpot = this.spot(tile, index);
                    }
                });
            });

            return foundSpot;
        },
        roadSpotByKey(roadSpotKey) {
            let foundRoadSpot = null;
            this.actionTiles.forEach(tile => {
                tile.roadSpots.forEach((roadSpot, index) => {
                    if(roadSpot.key == roadSpotKey) {
                        foundRoadSpot = this.spot(tile, index);
                    }
                });
            });
            return foundRoadSpot;
        },
        surroundingTiles(tile) {
            let tiles = [];

            let rowAbove = tile.row-1;
            let rowBelow = tile.row+1;
            let nextTile = tile.position+1;
            let previousTile = tile.position-1;

            if(tile.row % 2 == 0) {

                tiles.push(this.selectTile(rowAbove, tile.position+1));
                tiles.push(this.selectTile(tile.row, nextTile));
                tiles.push(this.selectTile(rowBelow, tile.position+1));
                tiles.push(this.selectTile(rowBelow, tile.position));
                tiles.push(this.selectTile(tile.row, previousTile));
                tiles.push(this.selectTile(rowAbove, tile.position));
            } else {

                tiles.push(this.selectTile(rowAbove, tile.position));
                tiles.push(this.selectTile(tile.row, nextTile));
                tiles.push(this.selectTile(rowBelow, tile.position));
                tiles.push(this.selectTile(rowBelow, tile.position-1));
                tiles.push(this.selectTile(tile.row, previousTile));
                tiles.push(this.selectTile(rowAbove, tile.position-1));
            }

            return tiles;
        },
        countTilesInRow(tileRow) {
            let countTiles = 0;

            for (var key in this.tiles) {
                var tile = this.tiles[key];

                if(tile.row == tileRow) {
                    countTiles++;
                }
            }

            return countTiles;
        },
        selectTile(tileRow, tilePosition) {
            return this.tiles.find(tile => tile.row == tileRow && tile.position == tilePosition) || this.noTile;
        },
        surroundingTilesNumbers(tile, wasRandomBefore) {
            let numbers = [];
            if(wasRandomBefore == true) {
                this.surroundingTiles(tile)
                    .filter(neighbour => neighbour.number.wasRandom == true)
                    .forEach(neighbour => {
                        numbers.push(neighbour.number.value);
                });
            } else {
                this.surroundingTiles(tile).forEach(neighbour => numbers.push(neighbour.number.value));
            }
            return numbers;
        },
    }
 }
