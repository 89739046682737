<template>
    <div class="boat-3d">
        <svg width="34" height="24" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.68818 15.6651L2.74543 16.4094L3.47532 16.5661L22.4187 20.6333L22.9509 20.7476L23.3357 20.3627L28.7071 14.9914L29 14.6985V14.2843V6.06145V5.21136L28.161 5.0745L9.39442 2.01305L9.03692 1.95473L8.725 2.13889L2.49158 5.81926L1.95517 6.13597L2.00295 6.75707L2.68818 15.6651Z" :fill="playerColors[color]" stroke="#383832" stroke-width="2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.02149 6.9598L22.6285 10.7475V19.6556L22.6286 19.6556L28 14.2843V6.06145L9.23342 3L3 6.68037L3.02149 6.9598Z" fill="black" fill-opacity="0.1"/>
        </svg>
    </div>
</template>

<script>

import colors from '../../helpers/colors.js'

export default {
    mixins: [
        colors,
    ],
    props: {
        color: String,
    },
    data() {
        return {

        }
    }
}
</script>

<style>

</style>
