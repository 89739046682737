<template>
    <div>
        <div class="preview-tile" :class="[{'opacity-30': tile.hidden },{ 'preview-x-offset': tile.row%2 == 0 },tile.type, 'row'+tile.row, 'position'+tile.position]" :style="{ top: tile.row*39+'px', left: (tile.position*46) +'px', transform: 'rotate('+ tile.direction*60+'deg)' }"></div>
    </div>
</template>

<script>
    export default {
        props: ['tile', 'activeTile', 'countTilesInRow', 'activePlayer', 'bePlayer', 'robber', 'merchant', 'roads', 'knights', 'houses', 'cities', 'highlightedSpots', 'longestRoad', 'highlightedRoadSpots', 'showCoordinates', 'focusMode', 'focusType', 'forceUpdate', 'inventorTiles', 'buildingDistanceActions'],
        data() {
            return {
                
            }
        },
        mounted() {

        },
        methods: {
            rotateTile(tileRow, tilePosition) {
                if(this.$props.direction == 5) {
                    this.$props.direction = 0
                } else {
                    this.$props.direction += 1;
                }
                this.$emit('rotateTile', tileRow, tilePosition);
            },
            tileClicked(tile) {
                this.$emit('tileClicked', tile);
            },
            paint(event, tile) {
                if(event.buttons > 0) {
                    this.$emit('tileClicked', tile);
                }
            }
        },
        components: {

        }
    }
</script>

<style>

</style>
