var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hand-cards-container",on:{"mouseleave":function($event){return _vm.showCardCount(null)}}},[(_vm.handCardsCount && _vm.showNumbers)?_c('div',{staticClass:"hand-cards-numbers"},[(_vm.currentAmount >= 4)?_c('div',{staticClass:"current"},[_vm._v(_vm._s(_vm.currentAmount))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"total",class:{ 'safe': _vm.allowedHandCards >= _vm.handCardsCount }},[_vm._v(_vm._s(_vm.handCardsCount))])]):_vm._e(),_vm._v(" "),_vm._l((_vm.player.handCards),function(cardObj,index){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(cardObj.amount > 0 && cardObj.group == 'commodity'),expression:"cardObj.amount > 0 && cardObj.group == 'commodity'"}],key:index,staticClass:"hand-card-wrapper"},[(cardObj.amount > 0 && !Number.isNaN(parseInt(cardObj.amount)))?_vm._l((cardObj.amount),function(card,pos){return _c('span',{key:pos,on:{"click":function($event){return _vm.handCardClicked(cardObj, cardObj.selected > pos)},"mouseover":function($event){return _vm.showCardCount(cardObj.amount)}}},[_c('HandCard',{staticClass:"hand-card",class:[
                        {'slide-in': cardObj.slideIn > pos },
                        {'slide-out': cardObj.slideOut > pos },
                        {'selected': cardObj.selected > pos } ],attrs:{"group":cardObj.group,"type":cardObj.type,"flatGraphics":_vm.flatGraphics}})],1)}):_vm._e()],2)}),_vm._v(" "),(
            _vm.player.handCards.filter(function (cardObj) { return cardObj.group == 'commodity'; }).reduce(function (numberOfCards, card) { return numberOfCards + card.amount; }, 0) != 0 &&
            _vm.player.handCards.filter(function (cardObj) { return cardObj.group == 'resource'; }).reduce(function (numberOfCards, card) { return numberOfCards + card.amount; }, 0) != 0)?_c('div',{staticClass:"resource-commodity-gap"}):_vm._e(),_vm._v(" "),_vm._l((_vm.player.handCards),function(cardObj,index){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(cardObj.amount > 0 && cardObj.group != 'commodity'),expression:"cardObj.amount > 0 && cardObj.group != 'commodity'"}],key:index+99,staticClass:"hand-card-wrapper"},[(cardObj.amount > 0 && !Number.isNaN(parseInt(cardObj.amount)))?_vm._l((cardObj.amount),function(card,pos){return _c('span',{key:pos,on:{"click":function($event){return _vm.handCardClicked(cardObj, cardObj.selected > pos)},"mouseover":function($event){return _vm.showCardCount(cardObj.amount)}}},[_c('HandCard',{staticClass:"hand-card",class:[
                    {'slide-in': cardObj.slideIn > pos },
                    {'slide-out': cardObj.slideOut > pos },
                    {'selected': cardObj.selected > pos } ],attrs:{"group":cardObj.group,"type":cardObj.type,"cardObj":cardObj,"flatGraphics":_vm.flatGraphics}})],1)}):_vm._e()],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }