<template>
    <div>
        <span v-if="forceUpdate"></span>

        <div class="road-spot-wrapper">
            <RoadBoat 
                v-if="road"
                :color="road.player.color"
                :boat="road.boat"
                :longest="longest"
                :highlighted="highlightedRoadSpots.includes(road.key)"
                :animation="road.buildAnimation"
            />
            <!-- <div 
                v-if="road && !road.boat" 
                class="road" 
                :class="[
                    road.player.color, 
                    { 'highlighted-road-spot': highlightedRoadSpots.includes(road.key) },
                    { 'longest': longest },
                    { 'build-road-animation': road.buildAnimation },
                ]"
            ></div>
            <div 
                v-if="road && road.boat" 
                class="boat" 
                :class="[
                    road.player.color, 
                    { 'highlighted-boat-spot': highlightedRoadSpots.includes(road.key) },
                    { 'longest': longest },
                    { 'build-road-animation': road.buildAnimation },
                ]"
            ></div> -->
        </div>
    </div>
</template>

<script>
    import RoadBoat from '../pieces/RoadBoat';

    export default {
        props: ['tile', 'roadSpotPosition', 'road', 'longest', 'highlightedRoadSpots', 'forceUpdate'],
        data() {
            return {
                showMenu: false,
            }
        },
        methods: {

        },
        components: {
            RoadBoat,
        },
    }
</script>

<style>

</style>
