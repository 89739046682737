<template>
    <div>
        <span v-if="forceUpdate"></span>

        <div class="spot-wrapper">
            <!-- <template v-if="hiddenTiles == 0 || multiplayer == false"> -->
                <div class="points-animation" v-if="house && !house.buildAnimation && gamePhase != 'initial build'">
                    <template v-if="house.player == activePlayer && activePlayer.points > 2">{{ activePlayer.points }}</template>
                </div>
                <div class="points-animation" v-if="city && !city.buildAnimation && gamePhase != 'initial build'">
                    <template v-if="city.player == activePlayer && activePlayer.points > 3">{{ activePlayer.points }}</template>
                </div>
                <div class="points-animation" v-if="city && !city.buildAnimation && city.metropolis && gamePhase != 'initial build'">
                    <template v-if="city.player == activePlayer && activePlayer.points > 3">{{ activePlayer.points }}</template>
                </div>
            <!-- </template> -->
            <div v-if="tile.spots[spotPosition]">
                <Knight v-if="knight" :level="knight.level" :color="knight.player.color" :active="knight.active" :highlighted="highlightedSpots.includes(spot.key)" />
                <!-- <div
                    v-if="knight"
                    class="knight"
                    :class="[
                        knight.player.color,
                        'level-'+knight.level,
                        { 'active': knight.active },
                        { 'highlighted': highlightedSpots.includes(spot.key) },
                    ]"
                ></div> -->
                <!-- <House v-if="house" :color="house.player.color" :animate="house.buildAnimation" :highlighted="highlightedSpots.includes(spot.key)" :extraIslandPoint="house.extraIslandPoint"/> -->
                <div
                    v-if="house"
                    class="house"
                    :class="[
                        house.player.color,
                        { 'highlighted': highlightedSpots.includes(spot.key) },
                        { 'build-house-animation': house.buildAnimation },
                    ]"
                >
                    <div class="island-marker" v-if="house.extraIslandPoint"></div>
                </div>

                <!-- <City
                    v-if="city"
                    :color="city.player.color"
                    :metropolis="city.metropolis"
                    :citywall="city.citywall"
                    :disabled="city.disabled"
                    :highlighted="highlightedSpots.includes(spot.key)"
                    :gamePhase="gamePhase"
                    :animate="city.buildAnimation"
                /> -->
                <div
                    v-if="city"
                    class="city"
                    :class="[
                        city.player.color,
                        { 'citywall': city.citywall },
                        { 'metropolis': city.metropolis },
                        { 'city_disabled': city.disabled },
                        { 'highlighted': highlightedSpots.includes(spot.key) },
                        { 'build-city-animation': city.buildAnimation },
                    ]"
                ></div>

                <div class="empty-highlighted-spot" v-else-if="highlightedSpots.includes(spot.key)"></div>
                <div v-if="merchant">
                    <div
                        class="piece merchant"
                        :class="'position-'+merchant.spotOffset"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import House from '../pieces/House';
import City from '../pieces/City';
import Knight from '../pieces/Knight';

export default {
    props: ['tile', 'spot', 'spotPosition', 'merchant', 'knight', 'house', 'city', 'highlightedSpots', 'activePlayer', 'gamePhase', 'forceUpdate', 'hiddenTiles', 'multiplayer'],
    data() {
        return {
            showActionMenu: false,
        }
    },
    methods: {

    },
    components: {
        House,
        City,
        Knight,
    }
}
</script>

<style>

</style>
