<template>
    <div>
        <span v-if="forceUpdate"></span>

        <div class="road-action-spot-wrapper">
            <div v-if="tile.roadSpots[roadSpotPosition].showMenu" class="road-actions-wrapper">
                <!-- <div @click="tile.roadSpots[roadSpotPosition].showMenu = false" class="close-action-menu">X</div> -->

                <div v-if="
                        lastClickedRoadSpotTiles.find(tile => ['land'].includes(tile.group)) &&
                        (lastClickedRoadSpotConnectingRoads.find(road => road && !road.boat) ||
                        lastClickedRoadSpotConnectingSpots.find(spot => spot && spot.content.group == 'building' && spot.content.player == this.activePlayer))
                    "
                    @click="roadSpotActionClicked(tile, roadSpotPosition)"
                    class="action"
                    :class="[{ 'action-confirmed': actionConfirmed == 'road' }]"
                >
                    <div class="item road-item">
                        <Road3D :color="activePlayer.color" class="road-menu-item" :class="[
                            { 'opacity-30': activePlayer.pieces.roads == 0 },
                        ]" />
                        <div class="items-left" style="left: 33px; top: 0px;">{{ activePlayer.pieces.roads }}</div>
                    </div>
                    <div class="action-cost" v-if="flatGraphics">
                        <div class="symbol lumber"></div>
                        <div class="symbol brick"></div>
                    </div>
                    <div class="action-cost-2" v-else>
                        <div class="symbol-2 lumber-2"></div>
                        <div class="symbol-2 brick-2"></div>
                    </div>
                </div>

                <div v-if="
                    seafaresExpansion &&
                    lastClickedRoadSpotTiles.find(tile => ['water', 'harbor'].includes(tile.group)) &&
                    (lastClickedRoadSpotConnectingRoads.find(road => road && road.boat) ||
                    lastClickedRoadSpotConnectingSpots.find(spot => spot && spot.content.group == 'building' && spot.content.player == this.activePlayer))
                    " @click="boatSpotActionClicked(tile, roadSpotPosition)" class="action">
                    <div class="item boat-item">
                        <Boat3D :color="activePlayer.color" class="boat-menu-item" :class="[
                            { 'opacity-30': activePlayer.pieces.boats == 0 || lastClickedRoadSpotTiles.find(tile => tile == pirate.tile) },
                        ]" />
                        <div class="items-left" style="left: 33px; top: 0px;">{{ activePlayer.pieces.boats }}</div>
                    </div>
                    <div class="action-cost" v-if="flatGraphics">
                        <div class="symbol lumber"></div>
                        <div class="symbol wool"></div>
                    </div>
                    <div class="action-cost-2" v-else>
                        <div class="symbol-2 lumber-2"></div>
                        <div class="symbol-2 wool-2"></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import Road3D from '../pieces/Road3D';
    import Boat3D from '../pieces/Boat3D';

    export default {
        props: ['tile', 'actionConfirmed', 'roadSpotPosition', 'activePlayer', 'forceUpdate', 'seafaresExpansion', 'pirate', 'lastClickedRoadSpotTiles', 'lastClickedRoadSpotConnectingRoads', 'lastClickedRoadSpotConnectingSpots', 'flatGraphics'],
        data() {
            return {
                showMenu: false,
            }
        },
        methods: {
            roadSpotActionClicked(tile, spotPosition) {
                this.$emit('roadSpotActionClicked', tile, spotPosition);

                this.$forceUpdate();
            },
            boatSpotActionClicked(tile, spotPosition) {
                this.$emit('boatSpotActionClicked', tile, spotPosition);

                this.$forceUpdate();
            },
        },
        components: {
            Road3D,
            Boat3D,
        }
    }
</script>

<style>

</style>
