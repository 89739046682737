<template>
    <div>
        <div class="progress-row" v-for="(progress, index) in player.progress" :key="index">
            <div class="progress-row" @click="commodityUpgradeClicked(player, progress.type)">
                <div class="upgrade-cost" :class="'upgrade-cost-' + progress.type" v-if="!isOpponent && progress.level != 5">x{{ progress.level+1 }}
                    <div class="symbol" :class="progress.type" v-if="flatGraphics"></div>
                    <div class="symbol symbol-progress" :class="progress.type+'-2'" v-else></div>
                </div>
                <div class="small-dice dice-boat" :class="{'dice-boat-2': ! flatGraphics}" :style="{'background-position': '0px ' + -(index)*44 + 'px'}"></div>
                <div v-for="(level, index2) in 6" :key="index2" v-show="index2 <= progress.level && progress.level != 0" class="small-dice red" :style="{'background-position': '0px -' + index2*22 + 'px'}"></div>

                <div class="small-dice empty" v-if="progress.level != 5 && progress.level == 0"></div>
                <div class="small-dice empty" v-if="progress.level != 5"></div>
                <div :class="'metropolis-' + progress.type" class="symbol metropolis metropolis-level-4" v-if="metropolisTopLevel[progress.type] < 4"></div>
                <div
                    :class="[
                        'metropolis-' + progress.type,
                        { 'opacity-30': metropolisTopLevel[progress.type] >= 4 }
                    ]"
                    class="symbol metropolis metropolis-level-5"
                    v-if="metropolisTopLevel[progress.type] == 4"
                ></div>

                <div :class="[
                    'metropolis-' + progress.type,
                    'metropolis-owner-level-' + progress.level,
                    {'metropolis-owner-level-4-unflat': !flatGraphics},
                    {'opponent': isOpponent}
                    ]"
                    class="symbol metropolis"
                    v-if="progress.level >= 4 && progress.metropolis"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['player', 'metropolisTopLevel', 'isOpponent', 'flatGraphics'],
        data() {
            return {

            }
        },
        beforeMount() {

        },
        methods: {
            commodityUpgradeClicked(player, type) {
                this.$emit('commodityUpgradeClicked', player, type);

                this.$forceUpdate();
            },
        },
        components: {

        },
        watch: {

        }
    }
</script>

<style>

</style>
