<template>
    <div>
        <div :class="player.color" class="road" style="top: 20px; left: 30px;"></div>
        <span class="total" style="top: 6px; right: 185px; text-align: right;" v-if="seafaresExpansion">boats {{ player.pieces.boats }}<br>roads {{ player.pieces.roads }}</span>
        <span class="total" style="top: 20px; right: 185px;" v-if="!seafaresExpansion">{{ player.pieces.roads }}</span>
        <div :class="player.color" class="house" style="top: 26px; left: 80px;"></div>
        <House :color="player.color" style="top: 26px; left: 80px;" />
        <span class="total" style="top: 20px; right: 139px;">{{ player.pieces.houses }}</span>
        <City :color="player.color" style="top: 14px; left: 124px;" />
        <span class="total" style="top: 20px; right: 77px;">{{ player.pieces.cities }}</span>
        <Citywall3D :color="player.color" style="width: 48px; top: 30px; left: 194px;" />
        <!-- <div :class="player.color" class="citywall-only" style="width: 48px; top: 28px; left: 195px;"></div> -->
        <span class="total" style="top: 20px; right: 9px;">{{ player.pieces.citywalls }}</span>
        <div :class="player.color" class="knight-3d level-1 active" style="top: 78px; left: 22px;"></div>
        <span class="total" style="top: 68px; right: 198px;">{{ player.pieces.knights.filter(knight => knight == 1).length }}</span>
        <div :class="player.color" class="knight-3d level-2 active" style="top: 78px; left: 80px;"></div>
        <span class="total" style="top: 68px; right: 139px;">{{ player.pieces.knights.filter(knight => knight == 2).length }}</span>
        <div :class="player.color" class="knight-3d level-3 active" style="top: 78px; left: 138px;"></div>
        <span class="total" style="top: 68px; right: 81px;">{{ player.pieces.knights.filter(knight => knight == 3).length }}</span>
        <div :class="player.color" class="road longest" style="top: 68px; left: 205px;"></div>
        <span class="total" style="top: 68px; right: 10px;">{{ player.roadLength }}</span>
    </div>
</template>

<script>
import City from './pieces/City';
import House from './pieces/House';
import Citywall3D from './pieces/Citywall3D';

export default {
    props: ['player', 'seafaresExpansion'],
    data() {
        return {

        }
    },
    methods: {

    },
    components: {
        City,
        House,
        Citywall3D,
    },
    computed: {

    },
}
</script>
