<template>
    <div class="main-player-wrapper">
        <PlayerInfo class="player-info main-player-info" :seafaresExpansion="seafaresExpansion" :player="player" v-if="showGuides" />
        <span v-if="forceUpdate"></span>

        <div v-if="activePlayer.offerOpponents == true && activePlayer != player && player.offerAnswer == 'waiting'" class="opponent-trade-offer-wrapper">
            <div class="trade-actions">
                <div class="icon-wrapper" @click="rejectTradeOffer()"><i class="fa fa-times"></i></div>
                <div class="icon-wrapper" @click="makeCounterOffer(player)"><i class="fa fa-cog"></i></div>
                <div class="icon-wrapper" v-if="canTrade" @click="acceptTradeOffer()"><i class="fa fa-check"></i></div>
            </div>
            <div class="get-trade-selected-wrapper text-right">
                <div class="icon-wrapper icon-wrapper-right">
                    <div class="animated-chevrons-wrapper">
                        <i class="fa fa-chevron-down animated-down"></i>
                    </div>
                </div>
                <div class="player-trade-color player-trade-color-right background" :class="activePlayer.color"></div>
                <span v-for="(cardObj, index) in activePlayer.handCards.slice().reverse()" :key="index+40">
                    <div v-for="(card, index) in cardObj['selected']"
                        :key="index+50"
                        class="hand-card-small"
                        :class="[
                            cardObj.type,
                            {'commodity': cardObj.group == 'commodity'},
                            {'hand-card-small-2': ! flatGraphics },
                        ]"
                    ></div>
                </span>
            </div>
            <div class="clear-both"></div>
            <div class="give-trade-selected-wrapper text-right">
                <div class="icon-wrapper icon-wrapper-right">
                    <div class="animated-chevrons-wrapper">
                        <i class="fa fa-chevron-up animated-up"></i>
                    </div>
                </div>
                <span v-for="(cardObj, index) in activePlayer.tradeGet" :key="index+20">
                    <div v-for="(card, index) in cardObj['amount']"
                        :key="index+30"
                        class="hand-card-small"
                        :class="[
                            cardObj.type,
                            {'commodity': cardObj.group == 'commodity'},
                            {'hand-card-small-2': ! flatGraphics },
                        ]"
                    ></div>
                </span>
            </div>
        </div>

        <!-- && showGuides == false -->
        <!-- mobile-hide -->
        <div class="dice-tracker-wrapper mobile-hide" v-if="diceTrackerMax.count != 0 && (activePlayer.offerOpponents == true && activePlayer != player && player.offerAnswer == 'waiting') == false"
            :class="[{'opacity-1':
                    (dicesRolling && activePlayer.color == player.color) == false
                && (focusType == 'select city to destroy' && waitingForMainPlayer) == false
                && (focusType == 'select knight to give away' && waitingForMainPlayer) == false
                && (focusType == 'move chased knight' && waitingForMainPlayer) == false
                && (focusType == 'steal a handcard' && waitingForMainPlayer) == false
                && (focusType == 'move robber' && waitingForMainPlayer) == false
                && (focusType == 'build initial house' && waitingForMainPlayer) == false
                && (focusType == 'build initial city' && waitingForMainPlayer) == false
                && (focusType == 'build initial road' && waitingForMainPlayer) == false
                && (focusType == 'steal progress card' && !player.modalMode && waitingForMainPlayer) == false
                && ((dicesRolling && !dicesRollAnimation && activePlayer.color == player.color && ['regular'].includes(gamePhase)) == false && player.ai != autopilot) == false
            }, {'hidden': !diceStats}]"
        >
            <div class="dice-tracker">
                <div
                    v-for="(staple, index) in diceTracker"
                    :key="index"
                    :style="{ backgroundImage: `linear-gradient(0deg, ${staple.number == 7 ? '#AC9A76' : '#45596C'} 0%,  ${staple.number == 7 ? '#AC9A76' : '#425E78'} ${((staple.count/diceTrackerMax.count)*100)}%, #383832 ${((staple.count/diceTrackerMax.count)*100)}%)` }"
                    class="staple"
                    @mouseenter="
                        activeStapleNumber = staple.number;
                        activeStapleCount = staple.count;
                    "
                    @mouseleave="
                        activeStapleNumber = null;
                        activeStapleCount = null;
                    "
                ></div>
            </div>
            <div class="dice-tracker-numbers" style="margin-top: 4px;">
                <div
                    v-for="(staple, index) in diceTracker"
                    :key="index"
                >
                    <div style="font-size: 10px; width: 15px;" class="text-center">{{ staple.count }}</div>
                </div>
            </div>

            <div class="dice-tracker-numbers">
                <div
                    v-for="(staple, index) in diceTracker"
                    :key="index"
                    class="flex-container"
                >
                    <!-- text-coin -->
                    <!-- color: #435B72; -->
                    <div style="font-size: 8px; width: 15px;" class="text-center text-coin" :class="{'text-coin': index+2 == 7}"><span v-if="index+2 < 10">0</span>{{ index+2 }}</div>
                </div>
            </div>
        </div>

        <template v-if="mounted">
            <div class="attention-wrapper" :class="{'opacity-1 pulse': dicesRolling && !dicesRollAnimation && activePlayer.color == player.color && ['regular'].includes(gamePhase)}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]" @click="clickDices(500)">
                    It's your turn!
                </div>
            </div>
            <!-- <div class="attention-wrapper" :class="{'opacity-1 pulse': player.dices.whiteDiceRolling && ['decide starting player'].includes(gamePhase)}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]" @click="clickOwnDices(500)">
                    Highest roll builds first
                </div>
            </div> -->

            <div class="attention-wrapper" :class="{'opacity-1 pulse': focusType == 'select city to destroy' && waitingForMainPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Select a city
                </div>
            </div>

            <div class="attention-wrapper" :class="{'opacity-1 pulse': focusType == 'steal a handcard' && waitingForMainPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Steal a handcard
                </div>
            </div>

            <div class="attention-wrapper" :class="{'opacity-1 pulse': focusType == 'move robber' && waitingForMainPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Move robber
                </div>
            </div>

            <div class="attention-wrapper attention-wrapper-lower" :class="{'opacity-1 pulse': focusType == 'build initial house' && waitingForMainPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]" @click="aiBuildInitialHouse(player)">
                    Build house
                </div>
            </div>

            <div class="attention-wrapper attention-wrapper-lower" :class="{'opacity-1 pulse': focusType == 'build initial road' && waitingForMainPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Build road
                </div>
            </div>

            <div class="attention-wrapper attention-wrapper-lower" :class="{'opacity-1 pulse': focusType == 'build initial city' && waitingForMainPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]" @click="aiBuildInitialCity(player)">
                    Build city
                </div>
            </div>

            <div class="attention-wrapper" :class="{'opacity-1 pulse': focusType == 'select knight to give away' && waitingForMainPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Select a knight
                </div>
            </div>

            <div class="attention-wrapper" :class="{'opacity-1 pulse': focusType == 'move chased knight' && waitingForMainPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Move your knight
                </div>
            </div>

            <div class="attention-wrapper" :class="{'opacity-1 pulse': focusType == 'steal progress card' && !player.modalMode && waitingForMainPlayer}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Select opponent
                </div>
            </div>

            <!-- <div class="attention-wrapper" :class="{'opacity-1 pulse': (dicesRolling && !dicesRollAnimation && activePlayer.color == player.color && ['regular'].includes(gamePhase)) == false && player.ai != autopilot}">
                <div class="attention-badge background" :class="[player.color, {'text-gray': player.color == 'white'}]">
                    Autopilot activates on end turn
                </div>
            </div> -->
        </template>

        <div class="dice-wrapper" :class="{ 'rolling-dices-boat': dicesRolling && !dicesRollAnimation }" v-if="activePlayer.color == player.color && !['decide starting player', 'initial build'].includes(gamePhase)">
            <div class="btn float-left" @click="endTurn()" v-if="!dicesRolling && !dicesRollAnimation && gamePhase != 'game over'">
                <span v-if="player.points < playToPoints">End turn</span>
                <span v-else>Claim victory</span>
            </div>
            <a href="/" v-if="gamePhase == 'game over'">
                <div class="btn float-left">New game</div>
            </a>
            <div class="btn float-left mobile-show" @click="quickTradeGet(null, player)" v-if="!dicesRolling && !dicesRollAnimation">Trade</div>
            <div class="float-left click-dices" @click="clickDices(500)">
                <div class="dice white" :class="{'rolling upsideDown': dices.whiteDiceRolling}" :style="{'backgroundPosition': '0px ' + -(dices.whiteDiceRoll-1)*36 +'px'}"></div>
                <div class="dice red" :class="{'rolling upsideDown': dices.redDiceRolling}" :style="{'backgroundPosition': '0px ' + -(dices.redDiceRoll-1)*36 +'px'}"></div>
                <div class="dice dice-boat"
                    :class="[
                        { 'rolling upsideDown': dices.boatDiceRolling },
                        { 'dice-boat-2': ! flatGraphics },
                    ]"
                    :style="{'backgroundPosition': dices.boatDiceBackgroundOffset+'px ' + -(dices.boatDiceRoll.number-1)*36 +'px'}"
                ></div>
            </div>
        </div>

        <!-- <div class="dice-wrapper" v-if="['decide starting player', 'initial build'].includes(gamePhase) && players.filter(player => !player.ai).length == 1" :class="[{ 'opacity-30': player.dices.fadeAway }, { 'rolling-dices': player.dices.whiteDiceRolling }]" @click="clickOwnDices(500)">
            <div class="click-dices">
                <div class="dice white" :class="{'rolling upsideDown': player.dices.whiteDiceRolling}" :style="{'backgroundPosition': '0px ' + -(player.dices.whiteDiceRoll-1)*36 +'px'}"></div>
                <div class="dice red" :class="{'rolling upsideDown': player.dices.redDiceRolling}" :style="{'backgroundPosition': '0px ' + -(player.dices.redDiceRoll-1)*36 +'px'}"></div>
            </div>
        </div> -->

        <div class="main-player-points-wrapper">
            <img v-if="waitingForMainPlayer && gamePhase != 'game over'" src="/images/symbols/waiting.png" width="11" class="waiting-for-main-player">
            <div class="toggle-guides-trigger cursor-pointer hover-scale mobile-show" @click="toggleGuides()"><i class="fa fa-info"></i></div>
            <!-- <img v-if="activePlayer == player" src="/images/symbols/active.png" width="20" class="active-main-player"> -->
            <span class="main-player-points text" :class="player.color" @click="playerPointsOrCardsClicked(player)">{{ player.points }}<div class="play-to-points" :class="{'play-to-999': playToPoints == 999}">/ {{ playToPoints }}</div></span>
        </div>

        <div class="hand-cards-wrapper">
            <HandCards
                :player="player"
                :activePlayer="activePlayer"
                :focusType="focusType"
                :focusMode="focusMode"
                :waitingForMainPlayer="waitingForMainPlayer"
                :forceUpdate="forceUpdate"
                :allowedHandCards="allowedHandCards"
                :disabledOnMasterMerchant="true"
                :showNumbers="true"
                :flatGraphics="flatGraphics"
                @focusModeOff="focusModeOff"
                @updateThrowAwayCount="updateThrowAwayCount"
                @selectedHarborResource="selectedHarborResource"
                @selectedHarborCommodity="selectedHarborCommodity"
                @cancelAcceptedOffer="cancelAcceptedOffer"
                @makeCounterOffer="makeCounterOffer"
                @updateBankOffers="updateBankOffers"
                @log="log"
                @resetOffer="resetOffer"
                @updateLocalStorage="updateLocalStorage"
            />
        </div>

        <div class="progress-cards-wrapper" :class="{'progress-cards-wrapper-unflat': !flatGraphics}">
            <div class="progress-cards-container point-cards-container">
                <div
                    v-for="(card, index) in player.pointCards"
                    :key="index"
                    class="point-card"
                    :class="card.label"
                ></div>

                <div class="point-card metropolis-cloth" v-if="player.progress.find(obj => obj.type == 'cloth').metropolis"></div>
                <div class="point-card metropolis-coin" v-if="player.progress.find(obj => obj.type == 'coin').metropolis"></div>
                <div class="point-card metropolis-paper" v-if="player.progress.find(obj => obj.type == 'paper').metropolis"></div>
                <div class="point-card merchant" v-if="merchant.player && merchant.player.color == player.color"></div>
                <div class="point-card longest-road" v-if="longestRoad.length && longestRoad[0].player.color == player.color"></div>
            </div>
            <ProgressCard
                v-for="(card, index) in player.progressCards"
                :card="card"
                :index="index"
                :key="index"
                :activeCard="activeCard"
                :showInfo="showInfo"
                :player="player"
                :miningIrrigationCount="miningIrrigationCount"
                :focusType="focusType"
                :modalPlayer="modalPlayer"
                :spyIndex="spyIndex"
                :flatGraphics="flatGraphics"
                @playProgressCard="playProgressCard"
                @toggleShowInfo="toggleShowInfo"
                @activeCardOff="activeCardOff"
            />
        </div>

        <CommodityProgress :player="player" :metropolisTopLevel="metropolisTopLevel" :isOpponent="false" :flatGraphics="flatGraphics" @commodityUpgradeClicked="commodityUpgradeClicked" class="progress-wrapper"></CommodityProgress>
    </div>
</template>

<script>
    import CommodityProgress from './CommodityProgress';
    import ProgressCard from './ProgressCard';
    import HandCards from './HandCards';
    import PlayerInfo from './PlayerInfo';
    export default {
        props: ['player', 'diceStats', 'players', 'waitingForMainPlayer', 'activePlayer', 'longestRoad', 'merchant', 'metropolisTopLevel', 'focusMode', 'focusType', 'spyIndex', 'modalPlayer', 'activeCard', 'showInfo', 'forceUpdate', 'dices', 'gamePhase', 'allowedHandCards', 'playToPoints', 'miningIrrigationCount', 'showGuides', 'seafaresExpansion', 'diceTracker', 'flatGraphics', 'autopilot', 'dicesRollAnimation', 'dicesRolling'],
        data() {
            return {
                activeStapleNumber: null,
                activeStapleCount: null,
                mounted: false,
            }
        },
        beforeMount() {

        },
        mounted() {
            // fixes ugly load
            setTimeout(() => {
                this.mounted = true;
            }, 500);
        },
        created() {
            window.addEventListener('keydown', (e) => {
                // if (e.keyCode == 82) {
                //     if(this.focusType == 'build initial road') {
                //         this.aiBuildInitialRoad(this.player);
                //     }
                // }

                if (e.key == 'Enter') {
                    if(this.player != this.activePlayer) return;

                    if(this.gamePhase == 'decide starting player') {
                        this.clickOwnDices(this.player);
                    } else if(this.focusType == 'build initial house') {
                        this.aiBuildInitialHouse(this.player);
                    } else if(this.focusType == 'build initial city') {
                        this.aiBuildInitialCity(this.player);
                    } else if(this.gamePhase == 'regular') {
                        this.clickDices();
                    }
                }
            });
        },
        methods: {
            aiBuildInitialHouse(player) {
                this.$emit('aiBuildInitialHouse', player);
            },
            aiBuildInitialCity(player) {
                this.$emit('aiBuildInitialCity', player);
            },
            aiBuildInitialRoad(player) {
                this.$emit('aiBuildInitialRoad', player);
            },
            quickTradeGet(type, player) {
                this.$emit('quickTradeGet', type, player);

                this.$forceUpdate();
            },
            updateBankOffers() {
                this.$emit('updateBankOffers');

                this.$forceUpdate();
            },
            updateLocalStorage() {
                this.$emit('updateLocalStorage');

                this.$forceUpdate();
            },
            acceptTradeOffer() {
                this.$emit('acceptTradeOffer', this.player);

                this.$forceUpdate();
            },
            rejectTradeOffer() {
                this.$emit('rejectTradeOffer', this.player);

                this.$forceUpdate();
            },
            makeCounterOffer(player) {
                this.$emit('makeCounterOffer', player);

                this.$forceUpdate();
            },
            clickDices() {
                this.$emit('clickDices');

                this.$forceUpdate();
            },
            log(message, player) {
                this.$emit('log', message, player);

                this.$forceUpdate();
            },
            resetOffer() {
                this.$emit('resetOffer');

                this.$forceUpdate();
            },
            endTurn() {
                this.$emit('endTurnClicked');

                this.$forceUpdate();
            },
            clickOwnDices() {
                this.$emit('clickOwnDices', this.player);

                this.$forceUpdate();
            },
            selectedHarborResource(type) {
                this.$emit('selectedHarborResource', type);
            },
            selectedHarborCommodity(player, type) {
                this.$emit('selectedHarborCommodity', this.player, type);
            },
            cancelAcceptedOffer(player) {
                this.$emit('cancelAcceptedOffer', this.player);
            },
            focusModeOff(player) {
                this.$emit('focusModeOff', this.player);

                this.$forceUpdate();
            },
            updateThrowAwayCount(throwAwayCount) {
                this.$emit('updateThrowAwayCount', throwAwayCount);

                this.$forceUpdate();
            },
            playerPointsOrCardsClicked(player) {
                this.$emit('playerPointsOrCardsClicked', player);

                this.$forceUpdate();
            },
            commodityUpgradeClicked(player, type) {
                this.$emit('commodityUpgradeClicked', player, type);

                this.$forceUpdate();
            },
            playProgressCard(player, card, index) {
                this.$emit('playProgressCard', player, card, index);

                this.$forceUpdate();
            },
            toggleShowInfo(card) {
                this.$emit('toggleShowInfo', card);

                this.$forceUpdate();
            },
            activeCardOff() {
                this.$emit('activeCardOff');

                this.$forceUpdate();
            },
            toggleGuides() {
                this.$emit('toggleGuides');

                this.$forceUpdate();
            },
        },
        computed: {
            // check so player can give what asked for
            canTrade() {
                let canTrade = true;

                this.activePlayer.tradeGet.forEach(tradeObj => {
                    let target = this.player.handCards.find(cardObj => cardObj.type == tradeObj.type);

                    if(target.amount < tradeObj.amount) canTrade = false;
                });

                this.$emit('canTrade', canTrade);

                return canTrade;
            },
            diceTrackerMax() {
                return this.diceTracker.filter(() => true).sort(function(a, b) {
                    return b.count - a.count;
                })[0];
            },
            diceTrackerPopular() {
                let expected = [
                    { number: 2, probability: 0.0278},
                    { number: 12, probability: 0.0278},
                    { number: 3, probability: 0.0556},
                    { number: 11, probability: 0.0556},
                    { number: 4, probability: 0.0833},
                    { number: 10, probability: 0.0833},
                    { number: 5, probability: 0.111},
                    { number: 9, probability: 0.111},
                    { number: 6, probability: 0.139},
                    { number: 8, probability: 0.139},
                    { number: 8, probability: 0.167},
                ]
                return this.diceTracker.filter(obj => obj.number != 7).sort(function(a, b) {
                    return b.count/expected.find(obj => obj.number == b.number).probability - a.count/expected.find(obj => obj.number == a.number).probability;
                });
            }
        },
        components: {
            CommodityProgress,
            ProgressCard,
            HandCards,
            PlayerInfo,
        },
    }
</script>

<style>

</style>
