export default {
    methods: {
        aiBuildInitialHouse(player) {
            let availableSpots = this.aiGetBuildHouseSpots(player);

            this.clickHighlighted(this.tileByKey(availableSpots[availableSpots.length-1].tileKey), availableSpots[availableSpots.length-1].position, player)
        },
        aiBuildInitialCity(player) {
            let availableSpots = this.aiGetBuildCitySpots(player);

            this.clickHighlighted(this.tileByKey(availableSpots[availableSpots.length-1].tileKey), availableSpots[availableSpots.length-1].position, player);
        },
        aiBuildInitialHouseBasic(player) {
            let availableSpots = this.aiGetBuildHouseSpotsBasic(player);

            this.clickHighlighted(this.tileByKey(availableSpots[availableSpots.length-1].tileKey), availableSpots[availableSpots.length-1].position, player)
        },
        aiBuildSecondHouseBasic(player) {
            let availableSpots = this.aiGetBuildSecondHouseSpotsBasic(player);

            this.clickHighlighted(this.tileByKey(availableSpots[availableSpots.length-1].tileKey), availableSpots[availableSpots.length-1].position, player);
        },
        aiBuildInitialRoad(player) {
            let playerRoads = this.roads.filter(road => road.player == player);
            let accessToHouseSpots = [];

            if(playerRoads && playerRoads.length) {
                let builtRoad = playerRoads[0];

                this.roadSpots(builtRoad.tile, builtRoad.roadSpot).forEach(spot => {
                    if(spot.content.type != undefined) return;
                    
                    this.spotSpots(spot.tile, spot.position).forEach(spot2 => {
                        if(spot2.content.type != undefined) return;
                        
                        accessToHouseSpots.push(spot2.key);
                    })
                })
            }

            player.highlightedSpots.forEach(spotKey => {
                let roadAlternatives = [];

                let buildingSpot = this.spotByKey(spotKey);

                    this.spotRoadsAll(buildingSpot.tile, buildingSpot.position).forEach(roadSpot => {
                        if(!roadSpot) return;
                        let score = 0;
                        let harbors = 0;
                        
                        // ai should place road along goldmine
                        if(this.roadTiles(roadSpot.tile, roadSpot.roadSpot.position).find(roadTile => roadTile.number && roadTile.number.dots > 2 && roadTile.type == 'goldmine')) {
                            score += 15;
                        }

                        this.roadSpots(roadSpot.tile, roadSpot.roadSpot.position).forEach(spot => {
                            if(!spot) return;

                            // build roads towards hidden tiles
                            if(this.spotTiles(spot.tile, spot.position).find(tile => tile && tile.hidden)) {
                                score += 14;
                            }
                            if(spot && spot.key != spotKey) {
                                this.spotRoadsAll(spot.tile, spot.position).forEach(roadSpot_2 => {
                                    if(!roadSpot_2) return;
                                    this.roadSpots(roadSpot_2.tile, roadSpot_2.roadSpot.position).forEach(spot_2 => {
                                        if(spot_2 && spot_2.key != spot.key && spot_2.key != spotKey) {
                                            // can build house in spot 2 spots away from current building
                                            if(this.buildingDistance(spot_2.tile, spot_2.position)) {
                                                let spotTiles = this.spotTiles(spot_2.tile, spot_2.position);

                                                // spot is in water
                                                if(!spotTiles.filter(tile => tile.group == 'land').length) return;

                                                // give some points for trying to build towards land
                                                score += spotTiles.filter(tile => tile.group == 'land' && tile.type != 'desert').length * 2;

                                                score += spotTiles
                                                    .filter(tile => tile.group == 'land' && tile.type != 'desert')
                                                    .reduce((dots, tile) => dots + tile.number.dots, 0);

                                                harbors = this.harborsBySpotPosition(spot_2.tile, spot_2.position);
                                                score += harbors.length*3;
                                                
                                                // avoid pointing your road towards house-spots your other road has access to already
                                                if(accessToHouseSpots.includes(spot_2.key)) score -= 15;
                                            }
                                        }
                                    });
                                });
                            }
                        });


                    roadAlternatives.push({ key: roadSpot.roadSpot.key, score: score, harbors: harbors, tile: roadSpot.tile, position: roadSpot.roadSpot.position })

                    })

                roadAlternatives = roadAlternatives.sort((a,b) => b.score - a.score);

                // console.log(roadAlternatives);

                let landTiles = this.spotTiles(buildingSpot.tile, buildingSpot.position).filter(tile => tile.group == 'land').length;
                if(landTiles == 3 && buildingSpot.content.type == 'house' && player.buildOrder < this.players.length-2 && !roadAlternatives[0].harbors.length) {
                    this.clickHighlightedRoad(roadAlternatives[1].tile, roadAlternatives[1].position);
                } else {
                    this.clickHighlightedRoad(roadAlternatives[0].tile, roadAlternatives[0].position);
                }
            })
        },
        aiGetBuildHouseSpots(player) {
            let goldmineValue = 1.80;

            if(['Spiral'].includes(this.aiTactic)) {
                goldmineValue = 10.00;
            } else if(['Fifth element'].includes(this.aiTactic)) {
                goldmineValue = 4.00;
            }

            let tileTypeValues = {
                lumber:     1.00,
                brick:      1.06,
                wool:       1.00,
                grain:      1.10,
                ore:        1.05,
                goldmine:   goldmineValue,
            };
            
            // the hardest resource to get, first player house build tries to get that
            if(player.buildOrder == 0) {
                tileTypeValues[this.tileTypeDotsAverage[0].type] += 0.3;
            } 

            // if city is already built, try to get resources which city is not getting
            let city = this.cities.find(city => city.player == player);
            if(city) { 
                this.spotTiles(city.tile, city.position).forEach(spotTile => {
                    tileTypeValues[spotTile.type] -= 0.8;
                });
            }

            if(['Forest escape'].includes(this.aiTactic)) {
                return this.getAvailableBuildingSpots(tileTypeValues, false, false, 100);
            } else if(['Spiral', 'Fifth element'].includes(this.aiTactic)) {
                return this.getAvailableBuildingSpots(tileTypeValues, false, false);
            } else {
                return this.getAvailableBuildingSpots(tileTypeValues);
            }


        },
        aiGetBuildCitySpots(player) {

            let goldmineValue = 2.00;

            if(['Spiral'].includes(this.aiTactic)) {
                goldmineValue = 10.00;
            } else if(['Fifth element'].includes(this.aiTactic)) {
                goldmineValue = 4.00;
            }

            let tileTypeValues = {
                lumber:     1.10,
                brick:      1.00,
                wool:       1.10,
                grain:      1.09,
                ore:        1.10,
                goldmine:   goldmineValue,
            };

            let uniqueTiles = false;
            let uniqueNumbers = false;
            let harborValue = 0;

            let house = this.houses.find(house => house.player == player);
            if(house) { 
                let houseTiles = this.spotTiles(house.tile, house.position);
                houseTiles.forEach(spotTile => {
                    if(spotTile.group == 'land' && spotTile.type != 'desert') {
                        tileTypeValues[spotTile.type] -= 0.8;
                    }
                });

                // not on these maps
                if(!['Spiral', 'Fifth element'].includes(this.aiTactic)) {
                    // player building city and has no grain from house, try to get grain, hard to play without it
                    if(!houseTiles.find(tile => tile.type == 'grain')) {
                        // tileTypeValues['grain'] += 1.0;
                        tileTypeValues['grain'] += 0.8;
                    }
                    
                    // hard to get ore on map
                    if(this.tileTypeDots['ore'] <= 13 && this.tiles.filter(tile => tile.type == 'ore').length >= 5) {
                        // player has no ore from house
                        if(!houseTiles.find(tile => tile.type == 'ore')) {
                            // play without ore
                            tileTypeValues['ore'] -= 0.15;
                        }
                    }
                }
                    
                uniqueTiles = true;

                let harbors = this.harborsBySpotPosition(house.tile, house.position);

                if(harbors.length) {
                    harbors.forEach(harbor => {
                        if(harbor.type == 'harbor-lumber') {
                            tileTypeValues['lumber'] += 1.2;
                        } else if(harbor.type == 'harbor-brick') {
                            tileTypeValues['brick'] += 1.4;
                        } else if(harbor.type == 'harbor-wool') {
                            tileTypeValues['wool'] += 1.2;
                        } else if(harbor.type == 'harbor-grain') {
                            tileTypeValues['grain'] += 1.2;
                        } else if(harbor.type == 'harbor-ore') {
                            tileTypeValues['ore'] += 1.2;
                        }

                        // if player has a harbor with a specific type, variation of city tiles is not as important anymore
                        if(harbor.type != 'harbor-all') {
                            uniqueTiles = false;
                        }
                    });
                }

            }
            if(['Forest escape', 'Spiral', 'Fifth element'].includes(this.aiTactic)) {
                return this.getAvailableBuildingSpots(tileTypeValues, false, false);
            } else {
                return this.getAvailableBuildingSpots(tileTypeValues, uniqueTiles, uniqueNumbers, harborValue);
            }

        },
        aiGetBuildHouseSpotsBasic(player) {
            let goldmineValue = 1.80;

            if(['Spiral'].includes(this.aiTactic)) {
                goldmineValue = 10.00;
            } else if(['Fifth element'].includes(this.aiTactic)) {
                goldmineValue = 4.00;
            }

            let tileTypeValues = {
                lumber:     1.06,
                brick:      1.06,
                wool:       1.06,
                grain:      1.08,
                ore:        1.08,
                goldmine:   goldmineValue,
            };
            
            // the hardest resource to get, first player house build tries to get that
            if(player.buildOrder == 0) {
                tileTypeValues[this.tileTypeDotsAverage[0].type] += 0.3;
            } 

            // if city is already built, try to get resources which city is not getting
            let city = this.cities.find(city => city.player == player);
            if(city) { 
                this.spotTiles(city.tile, city.position).forEach(spotTile => {
                    tileTypeValues[spotTile.type] -= 0.8;
                });
            }

            if(['Forest escape'].includes(this.aiTactic)) {
                return this.getAvailableBuildingSpotsBasic(tileTypeValues, false, false, 100);
            } else if(['Spiral', 'Fifth element'].includes(this.aiTactic)) {
                return this.getAvailableBuildingSpotsBasic(tileTypeValues, false, false);
            } else {
                return this.getAvailableBuildingSpotsBasic(tileTypeValues);
            }


        },
        aiGetBuildSecondHouseSpotsBasic(player) {

            let goldmineValue = 2.00;

            if(['Spiral'].includes(this.aiTactic)) {
                goldmineValue = 10.00;
            } else if(['Fifth element'].includes(this.aiTactic)) {
                goldmineValue = 4.00;
            }

            let tileTypeValues = {
                lumber:     1.09,
                brick:      1.09,
                wool:       1.06,
                grain:      1.08,
                ore:        1.08,
                goldmine:   goldmineValue,
            };

            let uniqueTiles = false;
            let uniqueNumbers = false;
            let harborValue = 0;

            let house = this.houses.find(house => house.player == player);
            if(house) { 
                let houseTiles = this.spotTiles(house.tile, house.position);
                houseTiles.forEach(spotTile => {
                    if(spotTile.group == 'land' && spotTile.type != 'desert' && spotTile.type != 'wool') {
                        tileTypeValues[spotTile.type] += 0.2;
                    }
                });

                // not on these maps
                if(!['Spiral', 'Fifth element'].includes(this.aiTactic)) {
                    // player building city and has no grain from house, try to get grain, hard to play without it
                    // can play without grain on basic?
                    if(!houseTiles.find(tile => tile.type == 'grain') && houseTiles.find(tile => tile.type == 'ore')) {
                        tileTypeValues['ore'] -= 0.2;
                        tileTypeValues['grain'] += 0.8;
                    }

                    if(!houseTiles.find(tile => tile.type == 'brick') && houseTiles.find(tile => tile.type == 'lumber')) {
                        tileTypeValues['lumber'] -= 0.2;
                        tileTypeValues['brick'] += 0.8;
                    }

                    if(!houseTiles.find(tile => tile.type == 'ore') && houseTiles.find(tile => tile.type == 'grain')) {
                        tileTypeValues['grain'] -= 0.2;
                        tileTypeValues['ore'] += 0.8;
                    }

                    if(!houseTiles.find(tile => tile.type == 'lumber') && houseTiles.find(tile => tile.type == 'brick')) {
                        tileTypeValues['brick'] -= 0.2;
                        tileTypeValues['lumber'] += 0.8;
                    }
                }
                    
                uniqueTiles = true;

                let harbors = this.harborsBySpotPosition(house.tile, house.position);

                if(harbors.length) {
                    harbors.forEach(harbor => {
                        if(harbor.type == 'harbor-lumber') {
                            tileTypeValues['lumber'] += 0.8;
                        } else if(harbor.type == 'harbor-brick') {
                            tileTypeValues['brick'] += 0.8;
                        } else if(harbor.type == 'harbor-wool') {
                            tileTypeValues['wool'] += 0.8;
                        } else if(harbor.type == 'harbor-grain') {
                            tileTypeValues['grain'] += 0.8;
                        } else if(harbor.type == 'harbor-ore') {
                            tileTypeValues['ore'] += 0.8;
                        }

                        // if player has a harbor with a specific type, variation of city tiles is not as important anymore
                        if(harbor.type != 'harbor-all') {
                            uniqueTiles = false;
                        }
                    });
                }

            }
            if(['Forest escape', 'Spiral', 'Fifth element'].includes(this.aiTactic)) {
                return this.getAvailableBuildingSpotsBasic(tileTypeValues, false, false);
            } else {
                return this.getAvailableBuildingSpotsBasic(tileTypeValues, uniqueTiles, uniqueNumbers, harborValue);
            }

        },
        getAvailableBuildingSpots(tileTypeValues, uniqueTiles = true, uniqueNumbers = true, harborValue = 3) {
            let availableSpots = [];

            this.actionTiles.forEach(tile => {
                tile.spots.forEach(spot => {
                    
                    // at least one of the tiles must be land
                    if(!this.spotTiles(tile, spot.position).find(spotTile => spotTile && spotTile.group == 'land')) return;
                    
                    // can't build on hidden tiles
                    if(this.spotTiles(tile, spot.position).find(spotTile => spotTile && (spotTile.hidden || spotTile.noInitialBuild))) return;

                    if(!this.buildingDistance(tile, spot.position)) return;

                    spot.dotsTotal = 0;
                    spot.dotsPotential = 0;
                    spot.tileKey = tile.key;

                    let resourceTiles = [];
                    let uniqueTileTypes = [];
                    let uniqueTileNumbers = [];
                    
                    this.spotTiles(tile, spot.position).forEach(spotTile => {
                        if(spotTile && spotTile.group == 'land' && spotTile.type != 'desert') {
                            resourceTiles.push(tile.type);
                            if(!uniqueTileTypes.includes(spotTile.type)) {
                                uniqueTileTypes.push(spotTile.type);
                            }

                            if(!uniqueTileTypes.includes(spotTile.type)) {
                                uniqueTileTypes.push(spotTile.number.value);
                            }

                            let dots = spotTile.number.exponentialDots;

                            spot.uniqueTileTypes = uniqueTileTypes.length;
                            spot.dotsTotal += spotTile.number.dots;
                            spot.dotsPotential += dots*tileTypeValues[spotTile.type];
                        }
                    })

                    if(uniqueTiles) {
                        if(uniqueTileTypes.length == 3 || uniqueTileTypes.includes('grain') && uniqueTileTypes.length != 1) {
                            if(uniqueTileTypes.length == 3) {
                                spot.dotsPotential = spot.dotsPotential * 1.20;
                            } else {
                                spot.dotsPotential = spot.dotsPotential * 1.14;
                            }
                        } else if(uniqueTileTypes.length == 2) {
                            spot.dotsPotential = spot.dotsPotential * 1.0;
                        }
                    }

                    if(uniqueTileTypes.length == 1) {
                        spot.dotsPotential = spot.dotsPotential * 0.93;
                    }

                    if(uniqueNumbers) {
                        if(uniqueTileNumbers.length == 1) {
                            spot.dotsPotential = spot.dotsPotential * 0.98;
                        } else if(uniqueTileNumbers.length == 2) {
                            spot.dotsPotential = spot.dotsPotential * 1.0;
                        } else if(uniqueTileNumbers.length == 3) {
                            spot.dotsPotential = spot.dotsPotential * 1.02;
                        }
                    }

                    let harbors = this.harborsBySpotPosition(this.tileByKey(spot.tileKey), spot.position);

                    if(harborValue) {
                        harbors.forEach(harbor => {
                            if(harbor) {
                                // map sucks a bit, more interesting to build house on a harbor
                                // default expansion specific code
                                if(this.tenSpotsAverage < 10.80 || this.tileTypeDots['grain'] <= 16 || this.tileTypeDots['ore'] <= 13 || harborValue > 9) {
                                    spot.dotsPotential = spot.dotsPotential + harborValue + 3;
                                } else {
                                    spot.dotsPotential = spot.dotsPotential + harborValue;
                                }
                            }
                        })
                    }
                    
                    if(resourceTiles.length == 3) {
                        
                        // last & second last city down, try to get 3 tile types
                        if([0, 1].includes(this.activePlayer.buildOrder) && this.houses.find(house => house.player == this.activePlayer)) {
                            spot.dotsPotential = spot.dotsPotential * 1.80;
                        } else {
                            spot.dotsPotential = spot.dotsPotential * 1.35;
                        }
                    } else if(resourceTiles.length == 2) {

                        if(harbors.length) {
                            spot.dotsPotential = spot.dotsPotential * 1.25;
                        } else {
                            // spot.dotsPotential = spot.dotsPotential * 1.10;
                            spot.dotsPotential = spot.dotsPotential * 1.05;
                        }

                    } else {
                        spot.dotsPotential = spot.dotsPotential * 0.8;
                    }

                    availableSpots.push(spot);
                });
            });

            // availableSpots = availableSpots.sort((a,b) => a.dotsTotal - b.dotsTotal);
            availableSpots = availableSpots.sort((a,b) => a.dotsPotential - b.dotsPotential);
        
            return availableSpots;
        },
        getAvailableBuildingSpotsBasic(tileTypeValues, uniqueTiles = true, uniqueNumbers = true, harborValue = 3) {
            let availableSpots = [];

            this.actionTiles.forEach(tile => {
                tile.spots.forEach(spot => {
                    
                    // at least one of the tiles must be land
                    if(!this.spotTiles(tile, spot.position).find(spotTile => spotTile && spotTile.group == 'land')) return;
                    
                    // can't build on hidden tiles
                    if(this.spotTiles(tile, spot.position).find(spotTile => spotTile && (spotTile.hidden || spotTile.noInitialBuild))) return;

                    if(!this.buildingDistance(tile, spot.position)) return;

                    spot.dotsTotal = 0;
                    spot.dotsPotential = 0;
                    spot.tileKey = tile.key;

                    let resourceTiles = [];
                    let uniqueTileTypes = [];
                    let uniqueTileNumbers = [];
                    
                    this.spotTiles(tile, spot.position).forEach(spotTile => {
                        if(spotTile && spotTile.group == 'land' && spotTile.type != 'desert') {
                            resourceTiles.push(tile.type);
                            if(!uniqueTileTypes.includes(spotTile.type)) {
                                uniqueTileTypes.push(spotTile.type);
                            }

                            if(!uniqueTileTypes.includes(spotTile.type)) {
                                uniqueTileTypes.push(spotTile.number.value);
                            }

                            let dots = spotTile.number.exponentialDots;

                            spot.uniqueTileTypes = uniqueTileTypes.length;
                            spot.dotsTotal += spotTile.number.dots;
                            spot.dotsPotential += dots*tileTypeValues[spotTile.type];
                        }
                    })

                    if(uniqueTiles) {
                        if(uniqueTileTypes.length == 3 || uniqueTileTypes.includes('grain') && uniqueTileTypes.length != 1) {
                            if(uniqueTileTypes.length == 3) {
                                spot.dotsPotential = spot.dotsPotential * 1.20;
                            } else {
                                spot.dotsPotential = spot.dotsPotential * 1.14;
                            }
                        } else if(uniqueTileTypes.length == 2) {
                            spot.dotsPotential = spot.dotsPotential * 1.0;
                        }
                    }

                    if(uniqueTileTypes.length == 1) {
                        spot.dotsPotential = spot.dotsPotential * 0.93;
                    }

                    if(uniqueNumbers) {
                        if(uniqueTileNumbers.length == 1) {
                            spot.dotsPotential = spot.dotsPotential * 0.98;
                        } else if(uniqueTileNumbers.length == 2) {
                            spot.dotsPotential = spot.dotsPotential * 1.0;
                        } else if(uniqueTileNumbers.length == 3) {
                            spot.dotsPotential = spot.dotsPotential * 1.02;
                        }
                    }

                    let harbors = this.harborsBySpotPosition(this.tileByKey(spot.tileKey), spot.position);

                    // if(harborValue) {
                    //     harbors.forEach(harbor => {
                    //         if(harbor) {
                    //             // map sucks a bit, more interesting to build house on a harbor
                    //             // default expansion specific code
                    //             if(this.tenSpotsAverage < 10.80 || harborValue > 9) {
                    //                 spot.dotsPotential = spot.dotsPotential + harborValue + 3;
                    //             } else {
                    //                 spot.dotsPotential = spot.dotsPotential + harborValue;
                    //             }
                    //         }
                    //     })
                    // }
                    
                    if(resourceTiles.length == 3) {
                        
                        // last & second last city down, try to get 3 tile types
                        if([0, 1].includes(this.activePlayer.buildOrder) && this.houses.find(house => house.player == this.activePlayer)) {
                            spot.dotsPotential = spot.dotsPotential * 1.80;
                        } else {
                            spot.dotsPotential = spot.dotsPotential * 1.35;
                        }
                    } else if(resourceTiles.length == 2) {

                        if(harbors.length) {
                            spot.dotsPotential = spot.dotsPotential * 1.25;
                        } else {
                            // spot.dotsPotential = spot.dotsPotential * 1.10;
                            spot.dotsPotential = spot.dotsPotential * 1.05;
                        }

                    } else {
                        spot.dotsPotential = spot.dotsPotential * 0.8;
                    }

                    availableSpots.push(spot);
                });
            });

            // availableSpots = availableSpots.sort((a,b) => a.dotsTotal - b.dotsTotal);
            availableSpots = availableSpots.sort((a,b) => a.dotsPotential - b.dotsPotential);
        
            return availableSpots;
        },
        getTenBestSpotsDotAverage() {
            let availableSpots = [];
            this.actionTiles.forEach(tile => {
                tile.spots.forEach(spot => {

                    spot.dotsTotal = 0;
                    
                    // at least one of the tiles must be land
                    if(!this.spotTiles(tile, spot.position).find(spotTile => spotTile && spotTile.group == 'land')) return;

                    this.spotTiles(tile, spot.position).forEach(spotTile => {
                        if(spotTile && spotTile.group == 'land' && spotTile.type != 'desert') {
                            spot.dotsTotal += spotTile.number.dots;
                        }
                    })

                    availableSpots.push(spot);
                });
            });

            availableSpots = availableSpots.sort((a,b) => b.dotsTotal - a.dotsTotal);

            let totalDots = 0;
            let checkSpots = 10;

            for(let i = 0; i < checkSpots; i++) {
                totalDots += availableSpots[i].dotsTotal;
            }

            let average = totalDots / checkSpots;

            this.tenSpotsAverage = average;
        },
    }
 }