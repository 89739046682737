<template>
    <!-- activePlayer.color, -->
    <div class="container-fluid mt-5 disable-dbl-tap-zoom"
        v-if="activePlayer"
        :class="[
            {'robber-cursor': activePlayer && bePlayer && focusMode == true && ['move robber', 'move robber bishop', 'move robber only'].includes(focusType) && activePlayer == bePlayer},
            {'pirate-cursor': activePlayer && bePlayer && focusMode == true && ['move pirate'].includes(focusType) && activePlayer == bePlayer},
            {'merchant-cursor': activePlayer && bePlayer && focusMode == true && ['move merchant'].includes(focusType) && activePlayer == bePlayer},
            {'house-cursor': activePlayer && bePlayer && focusMode == true && ['build initial house'].includes(focusType) && activePlayer == bePlayer},
            {'city-cursor': activePlayer && bePlayer && focusMode == true && ['build initial city'].includes(focusType) && activePlayer == bePlayer},
            activePlayer.color,
        ]"
    >

        <div style="opacity: 0; position: absolute;">
            <div v-for="(player, index) in players" :key="index+1000">
                <div
                    class="city"
                    :class="[
                        player.color,
                        { 'citywall': false },
                        { 'metropolis': false },
                        { 'city_disabled': false },
                    ]"
                ></div>

                <div
                    class="city"
                    :class="[
                        player.color,
                        { 'citywall': true },
                        { 'metropolis': false },
                        { 'city_disabled': false },
                    ]"
                ></div>

                <div
                    class="city"
                    :class="[
                        player.color,
                        { 'citywall': true },
                        { 'metropolis': false },
                        { 'city_disabled': true },
                    ]"
                ></div>

                <div
                    class="city"
                    :class="[
                        player.color,
                        { 'citywall': false },
                        { 'metropolis': false },
                        { 'city_disabled': false },
                    ]"
                ></div>

                <div
                    class="city"
                    :class="[
                        player.color,
                        { 'citywall': false },
                        { 'metropolis': true },
                        { 'city_disabled': false },
                    ]"
                ></div>

                <div
                    class="city"
                    :class="[
                        player.color,
                        { 'citywall': true },
                        { 'metropolis': true },
                        { 'city_disabled': false },
                    ]"
                ></div>

            </div>

            <div v-for="(choise, index) in ['lumber', 'brick', 'wool', 'grain', 'ore', 'cloth', 'coin', 'paper']"
                    class="card-small"
                    :key="index"
                >
                    <HandCard
                        :group="index > 4 ? 'commodity' : 'resource'"
                        :type="choise"
                        :flatGraphics="flatGraphics"
                    />
            </div>

            <div v-for="(choise, index) in ['lumber', 'brick', 'wool', 'grain', 'ore', 'cloth', 'coin', 'paper']"
                :key="index+30"
                class="hand-card-small basic"
                :class="[
                    choise,
                    {'hand-card-small-2': ! flatGraphics },
                ]"
            ></div>
        </div>

        <div class="cursor-pointer hover-scale text-coin z-top mobile-hide" @click="toggleGuides()" style="position: fixed; right: 35px; top: 42px; font-size: 18px;"><i class="fa" :class="[showGuides ? 'fa-times-circle' : 'fa-bars']"></i></div>
<div v-if="showGuides" class="settings-menu">
            <div class="row mb-2 cursor-pointer select-none" @click="toggleTileHelpers()">
                <div class="col col-4">
                    <div class="slide-toggle"><div class="slide-ball" :class="{ 'on': tileDots }"></div></div>
                </div>
                <div class="col col-8">
                    Tile helpers
                </div>
            </div>
            <div class="row mb-2 cursor-pointer select-none mobile-hide" @click="toggleDiceStats()">
                <div class="col col-4">
                    <div class="slide-toggle"><div class="slide-ball" :class="{ 'on': diceStats }"></div></div>
                </div>
                <div class="col col-8">
                    Dice stats
                </div>
            </div>
            <a href="/">
            <div class="row mb-2 cursor-pointer select-none">
                <div class="col col-4">
                    <div style="float: right; position: relative; right: -10px;">
                    <svg width="30" height="12" viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.73079 4.34027 7.73079 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976309 4.7308 0.659727 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646447 3.64645ZM19 3.5L1 3.5L1 4.5L19 4.5L19 3.5Z" fill="#AA9A7A"/>
                    </svg>
                    </div>
                </div>
                <div class="col col-8">
                    Leave game
                </div>
            </div>
            </a>
        </div>
        <div class="statuses" v-if="statuses.length > 0 && showLog">
            <i class="fa position-absolute caret" :class="[showAllStatuses ? 'fa-caret-up' : 'fa-caret-down', {'caret-unflat': !flatGraphics}]" v-if="statuses.length > 1" @click="toggleStatuses()"></i>
            <div v-for="(status, index) in statuses" :key="index" class="status" @click="toggleStatuses()">
                <div v-if="index+1 == statuses.length" :class="{'status-row': !flatGraphics}">
                    <span v-html="status.label"></span>
                </div>
                <div v-else-if="showAllStatuses" :class="{'status-row': !flatGraphics}">
                    <span v-html="status.label"></span>
                </div>
            </div>
            <div ref="chats" class="chats" v-if="showAllStatuses && gamePhase != 'decide starting player'">
                <div v-if="chats.length">
                    <div v-for="(chat, index) in chats" :key="index" class="chat">
                        <div style="width: 50px; margin-right: 10px;">
                            <Avatar :player="players.find(player => player.color == chat.player.color)" />
                        </div>
                        <div style="flex-grow: 1; margin-top: 14px;" :class="{ 'last-chat': index == chats.length-1 }">
                            <span class="text mono" :class="chat.player.color">{{ chat.player.name }}: </span>
                            {{ chat.text }}
                        </div>
                    </div>
                </div>
                <div class="chat" style="margin-top: 12px;">
                    <div style="width: 50px; margin-right: 10px;"></div>
                    <div style="flex-grow: 1; width: 100%;">
                        <form @submit.prevent="submitChat()">
                            <input type="text" style="width: 74%; height: 38px;" v-model="chatInput">
                            <button class="btn" type="submit" style="width: 23%; float: right;">Send</button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="chats" v-if="!showAllStatuses && chats.length && showLastChat && chats[chats.length-1].player.color != bePlayer.color">
                <div class="chat">
                    <div style="width: 50px; margin-right: 10px;">
                        <Avatar :player="players.find(player => player.color == chats[chats.length-1].player.color)" />
                    </div>
                    <div style="flex-grow: 1; margin-top: 14px;">
                        <span class="text mono" :class="chats[chats.length-1].player.color">{{ chats[chats.length-1].player.name }}: </span>
                        {{ chats[chats.length-1].text }}
                    </div>
                </div>
            </div>
        </div>
        <div class="feedback" v-if="feedback">
            {{ feedback }}
        </div>
        <div
            class="show-modal-again"
            v-show="bePlayer && bePlayer.hideModal && bePlayer.modalMode"
            @click="bePlayer.hideModal = !bePlayer.hideModal"
        >
            <i class="fa fa-eye"></i>
        </div>

        <div class="all-progress-cards" v-if="showGuides">
            <!-- <div class="head select-none">
                <p>All progress cards</p>
                <div class="close-modal" @click="showGuides = !showGuides">
                    <div class="line line-1"></div>
                    <div class="line line-2"></div>
                </div>
            </div> -->
            <div class="all-progress-cards-inner">
                <div class="all-progress-cards-wide">
                    <ProgressCardInfo
                        v-for="(card, index) in allProgressCards"
                        :card="card"
                        :index="index"
                        :key="index"
                        :allShowInfo="allShowInfo"
                        :miningIrrigationCount="miningIrrigationCount"
                        :flatGraphics="flatGraphics"
                        :player="bePlayer"
                        :basic="false"
                        :players="players"
                        @allToggleShowInfo="allToggleShowInfo"
                    />
                    <img src="/images/symbols/cloth-2.png" width="32" height="32" class="mr-1" v-if="! flatGraphics"><img src="/images/symbols/cloth.png" width="18" height="26" class="mr-2" v-if="flatGraphics"><small class="mono text-coin">When upgrading cloth to level 3 you will be able to trade all commodity cards 2:1 for the rest of the game.</small>
                    <img src="/images/symbols/coin-2.png" width="32" height="32" class="mr-1 ml-3" v-if="! flatGraphics"><img src="/images/symbols/coin.png" width="18" height="26" class="mr-2 ml-3" v-if="flatGraphics"><small class="mono text-coin">When upgrading coin to level 3 you will be able to make level 3 knights.</small>
                    <img src="/images/symbols/paper-2.png" width="32" height="32" class="mr-1 ml-3" v-if="! flatGraphics"><img src="/images/symbols/paper.png" width="18" height="26" class="mr-2 ml-3" v-if="flatGraphics"><small class="mono text-coin">When upgrading paper to level 3 you will select a free resource when dice doesn't generate you any cards.</small>
                </div>
            </div>
        </div>

        <div class="costs-and-points costs-and-points-ck" :class="bePlayer.color" v-if="showGuides">
            <div class="head select-none">
                <!-- <small style="position: absolute; top: -43px; padding: 4px 14px; border-radius: 35px; background: rgb(40 40 35)"><a href="/">‹ Leave game</a></small> -->
                <p @click="showHiddenFeatures = !showHiddenFeatures">Costs & Points</p>
                <div class="close-modal" @click="showGuides = !showGuides">
                    <div class="line line-1"></div>
                    <div class="line line-2"></div>
                </div>
            </div>
            <div class="ck-cover" v-if="! flatGraphics"></div>
            <small class="piece-labels" style="top: 98px; left: 288px;">road<span v-if="seafaresExpansion">/boat</span>
                <!-- <div class="boat" :class="bePlayer.color" style="position: absolute; top: -26px; left: -40px;" v-if="seafaresExpansion"></div> -->
                <div class="action-cost" style="position: absolute; top: -15px; left: -176px;" v-if="seafaresExpansion">
                    <span class="text-coin" style="font-size: 20px; position: absolute; left: -10px; top: -9px;">/</span>
                    <div class="symbol lumber" style="width: 19px; height: 19px;"></div>
                    <div class="symbol wool" style="width: 19px; height: 19px;"></div>
                </div>
            </small>
            <small class="piece-labels" style="top: 140px; left: 288px;">house</small>
            <small class="piece-labels" style="top: 187px; left: 288px;" @click="dices.dicesRollAnimation = false">city</small>
            <small class="piece-labels" style="top: 239px; left: 288px;">knight</small>
            <small class="piece-labels" style="top: 282px; left: 288px;">activate knight</small>
            <small class="piece-labels" style="top: 327px; left: 288px;">citywall/ringmjura</small>
            <small class="piece-labels" style="top: 375px; left: 288px;">enable city</small>

        </div>

        <div class="counterOfferModal" v-if="showCounterOfferModal && bePlayer.color != activePlayer.color">
            <div class="head">
                <p>Make a counter offer</p>

                <div class="close-modal" @click="showCounterOfferModal = false">
                    <div class="line line-1"></div>
                    <div class="line line-2"></div>
                </div>
            </div>
            <div class="content mt-3">
                <div>
                    <div class="get-trade-wrapper mt-3">
                        <div class="get-trade-trigger">GET</div>

                        <div class="get-trade-cards">
                            <div
                                class="hand-card-small"
                                :key="index"
                                :class="[card, {'hand-card-small-2': ! flatGraphics }]"
                                v-for="(card, index) in ['lumber', 'brick', 'wool', 'grain', 'ore', 'cloth', 'coin', 'paper']"
                                @click="counterTradeGet(card, bePlayer)"
                            ></div>
                        </div>
                    </div>
                    <div class="clear-both"></div>

                    <TradeOffer :player="bePlayer" :activePlayer="activePlayer" :bePlayer="bePlayer" :reversed="false" :counterTrade="true" :flatGraphics="flatGraphics" />

                    <div class="clear-both"></div>
                    <div class="give-trade-wrapper">
                        <div class="give-trade-trigger">GIVE</div>

                        <div class="give-trade-cards">
                            <div
                                class="hand-card-small"
                                :key="index+8"
                                :class="[card, {'hand-card-small-2': ! flatGraphics }]"
                                v-for="(card, index) in ['lumber', 'brick', 'wool', 'grain', 'ore', 'cloth', 'coin', 'paper']"
                                @click="counterTradeGive(card, bePlayer)"
                            ></div>
                        </div>
                    </div>
                    <div class="clear-both"></div>
                    <div class="btn" @click="submitCounterOffer(bePlayer)">Make counter offer</div>
                </div>
            </div>
        </div>

        <div
            id="modal"
            v-show="!bePlayer.hideModal"
            :class="[{'transparent-modal': [
                'upgrade second knight',
                'build road or boat',
            ].includes(bePlayer.modalType)}, {'score-board-padding': bePlayer.modalType == 'scoreboard'}]" v-if="bePlayer && bePlayer.modalMode">
            <div class="head">
                <p v-if="bePlayer.modalType == 'trade cards'">Trade</p>
                <!-- <p v-else-if="bePlayer.modalType == 'make counter offer'">Make a counter offer</p> -->
                <p v-else-if="bePlayer.modalType == 'select a commodity'">Select a commodity</p>
                <p v-else-if="bePlayer.modalType == 'select a resource'">Select a resource</p>
                <p v-else-if="bePlayer.modalType == 'steal a progress card'">Steal a progress card from {{ modalPlayer.name }}</p>
                <p v-else-if="bePlayer.modalType == 'select 2 hand cards'">Steal 2 hand cards from {{ modalPlayer.name }}</p>
                <p v-else-if="bePlayer.modalType == 'throw away cards'">Throw away {{ Math.floor(playerHandCardsCount(bePlayer)/2) }} cards <span class="mono">({{ Math.floor(playerHandCardsCount(bePlayer)/2) - throwAwayCount }}/{{ Math.floor(playerHandCardsCount(bePlayer)/2) }})</span></p>
                <p v-else-if="bePlayer.modalType == 'give 2 hand cards'">Give 2 hand cards to {{ activePlayer.name }}</p>
                <p v-else-if="bePlayer.modalType == 'upgrade second knight'">Upgrade second knight?</p>
                <p v-else-if="bePlayer.modalType == 'build road or boat'">Road or boat?</p>
                <p v-else-if="bePlayer.modalType == 'select dice number'">Select dice numbers</p>
                <p v-else-if="bePlayer.modalType == 'select commodity upgrade'">Select a commodity to upgrade</p>
                <p v-else-if="bePlayer.modalType == 'commercial harbor select resource'">Select a resource card</p>
                <p v-else-if="bePlayer.modalType == 'commercial harbor select commodity'">Select a commodity card</p>
                <p v-else-if="bePlayer.modalType == 'select merchant fleet'">Select what to trade 2:1</p>
                <p v-else-if="bePlayer.modalType == 'select a progress card'">Select a progress card</p>
                <p v-else-if="bePlayer.modalType == 'throw away a progress card'">Throw away one of your progress cards</p>
                <p v-else-if="bePlayer.modalType == 'select aqueduct resource'">Aqueduct: Select 1 resource</p>
                <p v-else-if="bePlayer.modalType == 'select goldmine resources'">Goldmine: Select {{ bePlayer.goldmineAmount }} resource<span v-if="bePlayer.goldmineAmount > 1">s</span></p>
                <p v-else-if="bePlayer.modalType == 'scoreboard'">Scoreboard</p>
                <div class="hide-modal"
                    v-if="[
                        'throw away cards',
                        'upgrade second knight',
                        'build road or boat',
                        'select dice number',
                        'select aqueduct resource',
                        'select goldmine resources',
                        'steal a progress card',
                        'give 2 hand cards',
                        'select 2 hand cards',
                    ].includes(bePlayer.modalType)"
                    @click="bePlayer.hideModal = !bePlayer.hideModal"
                >
                    <i class="fa fa-eye"></i>
                </div>

                <!-- close modal -->
                <div class="close-modal"
                    v-if="[
                        'make counter offer',
                        'scoreboard',
                    ].includes(bePlayer.modalType)"
                    @click="closeModal(bePlayer)"
                >
                    <div class="line line-1"></div>
                    <div class="line line-2"></div>
                </div>

                <!-- focus mode off -->
                <div class="close-modal"
                    v-if="[
                        'commercial harbor select resource',
                        'trade cards',
                    ].includes(bePlayer.modalType)"
                    @click="focusModeOff(bePlayer)"
                >
                    <div class="line line-1"></div>
                    <div class="line line-2"></div>
                </div>
            </div>
            <div class="content mt-3" :class="{ 'mt-4': bePlayer.modalType == 'steal a progress card' }">

                <div v-if="bePlayer.modalType == 'trade cards'">
                    <div class="row trade-wrapper">
                        <div class="col col-6">

                            <small class="text coin mobile-hide">Trade</small>
                            <small class="text coin mobile-show">Get</small>
                            <div class="clear-both"></div>
                            <div class="get-trade-wrapper mt-3-desktop">
                                <div class="get-trade-trigger">GET</div>

                                <div class="get-trade-cards">
                                    <div
                                        class="hand-card-small"
                                        :key="index"
                                        :class="[card, {'hand-card-small-2': ! flatGraphics }]"
                                        v-for="(card, index) in ['lumber', 'brick', 'wool', 'grain', 'ore', 'cloth', 'coin', 'paper']"
                                        @click="quickTradeGet(card, bePlayer)"
                                    ></div>
                                </div>
                            </div>
                            <div class="clear-both"></div>

                            <TradeOffer :player="bePlayer" :activePlayer="activePlayer" :flatGraphics="flatGraphics" @updateBankOffers="updateBankOffers" @resetOffer="resetOffer" />

                            <div class="clear-both"></div>
                            <div class="give-trade-wrapper">
                                <small class="text coin mobile-show">Give</small>
                                <div class="give-trade-trigger">GIVE</div>

                                <div class="give-trade-cards">
                                    <div
                                        class="hand-card-small text-coin mono relative"
                                        :key="index+8"
                                        :class="[card, {'hand-card-small-2': ! flatGraphics }]"
                                        v-for="(card, index) in ['lumber', 'brick', 'wool', 'grain', 'ore', 'cloth', 'coin', 'paper']"
                                        @click="quickTradeGive(card)"
                                    >
                                        <div class="hand-cards-count mobile-show">{{ bePlayer.handCards.find(obj => obj.type == card).amount - bePlayer.handCards.find(obj => obj.type == card).selected }}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col col-3">
                            <small class="text coin">Opponents</small>
                            <div class="btn mt-3" @click="makeOffer(bePlayer)" v-if="!bePlayer.offerOpponents">Offer opponents</div>
                            <div v-else>
                                <div v-for="(opponent, index) in players" :key="index" v-show="opponent != bePlayer">
                                    <TradeOffer
                                        :player="opponent"
                                        :activePlayer="activePlayer"
                                        :reversed="true"
                                        :flatGraphics="flatGraphics"
                                        class="opponent-trade-wrapper"
                                        v-if="opponent.offerAnswer != 'rejected'"
                                        :canClickCards="false"
                                        @makeTrade="makeTrade"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col col-3">
                            <small class="text coin">Bank</small>
                            <div class="bank-trade-wrapper" v-if="bankTradeOffer.length" @click="acceptBankTrade()">
                                <div v-for="(bankTrade, index) in bankTradeOffer" :key="index" class="bank-trade-row" :class="{'commodity-margin': ['cloth', 'coin', 'paper'].includes(bankTrade.type) && flatGraphics }">
                                    <span v-if="flatGraphics">
                                        <div v-for="(symbol, index) in bankTrade.tradeCost" :key="index" class="symbol" :class="bankTrade.type"></div>
                                    </span>
                                    <span v-else>
                                        <div v-for="(symbol, index) in bankTrade.tradeCost" :key="index" class="symbol symbol-bank-trade" :class="bankTrade.type+'-2'"></div>
                                    </span>
                                </div>
                            </div>
                            <div class="bank-trade-wrapper" v-if="bankTradeOffer2.length" @click="acceptBankTrade(2)">
                                <div v-for="(bankTrade, index) in bankTradeOffer2" :key="index" class="bank-trade-row" :class="{'commodity-margin': ['cloth', 'coin', 'paper'].includes(bankTrade.type) && flatGraphics }">
                                    <span v-if="flatGraphics">
                                        <div v-for="(symbol, index) in bankTrade.tradeCost" :key="index" class="symbol" :class="bankTrade.type"></div>
                                    </span>
                                    <span v-else>
                                        <div v-for="(symbol, index) in bankTrade.tradeCost" :key="index" class="symbol symbol-bank-trade" :class="bankTrade.type+'-2'"></div>
                                    </span>
                                </div>
                            </div>
                            <div class="bank-trade-wrapper" v-if="bankTradeOffer3.length" @click="acceptBankTrade(3)">
                                <div v-for="(bankTrade, index) in bankTradeOffer3" :key="index" class="bank-trade-row" :class="{'commodity-margin': ['cloth', 'coin', 'paper'].includes(bankTrade.type) && flatGraphics }">
                                    <span v-if="flatGraphics">
                                        <div v-for="(symbol, index) in bankTrade.tradeCost" :key="index" class="symbol" :class="bankTrade.type"></div>
                                    </span>
                                    <span v-else>
                                        <div v-for="(symbol, index) in bankTrade.tradeCost" :key="index" class="symbol symbol-bank-trade" :class="bankTrade.type+'-2'"></div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div v-else-if="bePlayer.modalType == 'make counter offer'">
                    <div class="get-trade-wrapper mt-3">
                        <div class="get-trade-trigger">GET</div>

                        <div class="get-trade-cards">
                            <div
                                class="hand-card-small"
                                :key="index"
                                :class="[card, {'hand-card-small-2': ! flatGraphics }]"
                                v-for="(card, index) in ['lumber', 'brick', 'wool', 'grain', 'ore', 'cloth', 'coin', 'paper']"
                                @click="counterTradeGet(card, bePlayer)"
                            ></div>
                        </div>
                    </div>
                    <div class="clear-both"></div>

                    <TradeOffer :player="bePlayer" :activePlayer="activePlayer" :bePlayer="bePlayer" :reversed="false" :counterTrade="true" :flatGraphics="flatGraphics" />

                    <div class="clear-both"></div>
                    <div class="give-trade-wrapper">
                        <div class="give-trade-trigger">GIVE</div>

                        <div class="give-trade-cards">
                            <div
                                class="hand-card-small"
                                :key="index+8"
                                :class="[card, {'hand-card-small-2': ! flatGraphics }]"
                                v-for="(card, index) in ['lumber', 'brick', 'wool', 'grain', 'ore', 'cloth', 'coin', 'paper']"
                                @click="counterTradeGive(card, bePlayer)"
                            ></div>
                        </div>
                    </div>
                    <div class="clear-both"></div>
                    <div class="btn" @click="submitCounterOffer(bePlayer)">Make counter offer</div>
                </div> -->
                <div v-else-if="bePlayer.modalType == 'select a commodity'">
                    <div v-for="(choise, index) in ['cloth', 'coin', 'paper']"
                        class="card-small"
                        :key="index"
                        @click="playProgressCard(activePlayer, activeCard, null, choise)"
                    >
                        <HandCard
                            :group="'commodity'"
                            :type="choise"
                            :flatGraphics="flatGraphics"
                        />
                    </div>
                </div>
                <div v-else-if="bePlayer.modalType == 'select a resource'">
                    <div v-for="(choise, index) in ['lumber', 'brick', 'wool', 'grain', 'ore']"
                        class="card-small"
                        :key="index"
                        @click="playProgressCard(activePlayer, activeCard, null, choise)"
                    >
                        <HandCard
                            :group="'resource'"
                            :type="choise"
                            :flatGraphics="flatGraphics"
                        />
                    </div>
                </div>
                <div v-else-if="bePlayer.modalType == 'steal a progress card'">
                    <div style="float: left">

                    <ProgressCard
                        v-for="(card, index) in modalPlayer.progressCards"
                        :card="card"
                        :index="index"
                        :key="index"
                        :activeCard="activeCard"
                        :showInfo="showInfo"
                        :player="modalPlayer"
                        :miningIrrigationCount="miningIrrigationCount"
                        :flatGraphics="flatGraphics"
                        @activeCardOff="activeCardOff"
                        @toggleShowInfo="toggleShowInfo"
                        @playProgressCard="playProgressCard"
                    />
                    </div>
                </div>
                <div v-else-if="bePlayer.modalType == 'select 2 hand cards'">
                    <HandCards v-if="this.bePlayer" ref="handCards" :player="modalPlayer" :focusType="focusType" :activePlayer="activePlayer" :focusMode="focusMode" :waitingForMainPlayer="this.bePlayer.waitingForMe" :flatGraphics="flatGraphics" @focusModeOff="focusModeOff" @saveGame="saveGame" @log="log" :forceUpdate="forceUpdate" class="modal-handcards" />
                    <div class="clear-both"></div>
                    <div class="btn float-right" style="margin-right: 0px; margin-top: 16px;" :class="{ 'opacity-30': false }" @click="confirmStealCards()">Steal 2 cards</div>
                </div>
                <div v-else-if="bePlayer.modalType == 'throw away cards'">
                    <HandCards v-if="this.bePlayer" ref="handCards" :player="bePlayer" :focusType="focusType" :activePlayer="activePlayer" :focusMode="focusMode" :waitingForMainPlayer="this.bePlayer.waitingForMe" :flatGraphics="flatGraphics" @focusModeOff="focusModeOff" @updateThrowAwayCount="updateThrowAwayCount" @updateLocalStorage="updateLocalStorage" :forceUpdate="forceUpdate" class="modal-handcards" @log="log" />
                    <div class="clear-both"></div>
                    <div class="btn float-right" style="margin-right: 0px; margin-top: 16px;" :class="{ 'opacity-30': throwAwayCount != 0 }" @click="confirmThrowCards()">Throw away {{ Math.floor(playerHandCardsCount(bePlayer)/2) }} cards</div>
                </div>
                <div v-else-if="bePlayer.modalType == 'give 2 hand cards'">
                    <HandCards v-if="this.bePlayer" ref="handCards" :player="bePlayer" :focusType="focusType" :activePlayer="activePlayer" :focusMode="focusMode" :waitingForMainPlayer="this.bePlayer.waitingForMe" :flatGraphics="flatGraphics" @focusModeOff="focusModeOff" :forceUpdate="forceUpdate" class="modal-handcards" />
                    <div class="clear-both"></div>
                    <div class="btn float-right" style="margin-right: 0px; margin-top: 16px;" :class="{ 'opacity-30': false }" @click="confirmStealCards()">Give 2 cards</div>
                </div>
                <div v-else-if="bePlayer.modalType == 'upgrade second knight'">
                    <div class="btn" @click="closeModal(activePlayer)">Yes</div>
                    <div class="btn" @click="focusModeOff(activePlayer)">No</div>
                </div>
                <div v-else-if="bePlayer.modalType == 'build road or boat'">
                    <div class="btn" @click="focusChoise = 'road', closeModal(activePlayer)">Road</div>
                    <div class="btn" @click="focusChoise = 'boat', closeModal(activePlayer)">Boat</div>
                </div>
                <div v-else-if="bePlayer.modalType == 'select dice number'">
                    <div>
                        <div v-for="number in 6" :key="number" :class="{'alchemist-not-selected': alchemistWhite != number}" class="alchemist-scale small-dice white" style="margin-right: 3px;" :style="{'background-position': '0px -' + (number-1)*22 + 'px'}" @click="selectAlchemist(number, 'white')"></div>
                    </div>
                    <div class="clear-both"></div>
                    <div class="mt-3">
                        <div v-for="number in 6" :key="number" :class="{'alchemist-not-selected': alchemistRed != number}" class="alchemist-scale small-dice red" style="margin-right: 3px;" :style="{'background-position': '0px -' + (number-1)*22 + 'px'}" @click="selectAlchemist(number, 'red')"></div>
                    </div>
                </div>

                <div v-if="bePlayer.modalType == 'select commodity upgrade'">
                    <div v-for="(choise, index) in ['cloth', 'coin', 'paper']"
                        class="card-small"
                        :key="index"
                        @click="playProgressCard(activePlayer, activeCard, null, choise)"
                    >
                        <HandCard
                            :group="'commodity'"
                            :type="choise"
                            :flatGraphics="flatGraphics"
                        />
                    </div>
                </div>
                <div v-if="bePlayer.modalType == 'commercial harbor select resource'">
                    <HandCards ref="handCards" :player="bePlayer" :focusType="focusType" :activePlayer="activePlayer" :focusMode="focusMode" :waitingForMainPlayer="this.bePlayer.waitingForMe" :flatGraphics="flatGraphics" @focusModeOff="focusModeOff" @selectedHarborResource="selectedHarborResource" :forceUpdate="forceUpdate" class="modal-handcards" />
                    <div class="clear-both"></div>
                    <div class="btn float-right" style="margin-right: 0px; margin-top: 16px;" :class="{ 'opacity-30': false }" @click="confirmGiveResource()">Give resource</div>
                </div>
                <div v-if="bePlayer.modalType == 'commercial harbor select commodity'">
                    <HandCards ref="handCards" :player="bePlayer" :focusType="focusType" :activePlayer="activePlayer" :focusMode="focusMode" :waitingForMainPlayer="this.bePlayer.waitingForMe" :flatGraphics="flatGraphics" @focusModeOff="focusModeOff" @selectedHarborResource="selectedHarborResource" @selectedHarborCommodity="selectedHarborCommodity" :forceUpdate="forceUpdate" class="modal-handcards" />
                    <div class="clear-both"></div>
                    <div class="btn float-right" style="margin-right: 0px; margin-top: 16px;" :class="{ 'opacity-30': false }" @click="confirmGiveCommodity()">Give commodity</div>
                </div>
                <div v-if="bePlayer.modalType == 'select merchant fleet'">
                    <div v-for="(choise, index) in ['lumber', 'brick', 'wool', 'grain', 'ore', 'cloth', 'coin', 'paper']"
                        class="card-small"
                        :key="index"
                        @click="playProgressCard(activePlayer, activeCard, null, choise)"
                    >
                        <HandCard
                            :group="index > 4 ? 'commodity' : 'resource'"
                            :type="choise"
                            :flatGraphics="flatGraphics"
                        />
                    </div>
                </div>
                <div v-if="bePlayer.modalType == 'select a progress card'">
                    <div
                        v-for="(choise, index) in ['cloth', 'coin', 'paper']"
                        :key="index" class="progress-card back"
                        :class="choise"
                        @click="playerSelectedProgressCard(bePlayer, choise)"
                    ></div>
                </div>
                <div v-else-if="bePlayer.modalType == 'throw away a progress card'">
                    <div style="float: left">
                    <ProgressCard
                        v-for="(card, index) in bePlayer.progressCards"
                        :card="card"
                        :index="index"
                        :key="index"
                        :activeCard="activeCard"
                        :showInfo="showInfo"
                        :player="bePlayer"
                        :miningIrrigationCount="miningIrrigationCount"
                        :flatGraphics="flatGraphics"
                        @activeCardOff="activeCardOff"
                        @toggleShowInfo="toggleShowInfo"
                        @playProgressCard="playProgressCard"
                    />
                    </div>
                </div>
                <div v-else-if="bePlayer.modalType == 'select aqueduct resource'">
                    <div v-for="(choise, index) in ['lumber', 'brick', 'wool', 'grain', 'ore']"
                        class="card-small"
                        :key="index"
                        @click="chooseAqueductResource(bePlayer, choise)"
                    >
                        <HandCard
                            :group="'resource'"
                            :type="choise"
                            :flatGraphics="flatGraphics"
                        />
                    </div>
                </div>
                <div v-else-if="bePlayer.modalType == 'select goldmine resources'">
                    <div v-for="(choise, index) in ['lumber', 'brick', 'wool', 'grain', 'ore']"
                        class="card-small"
                        :key="index"
                        @click="chooseGoldmineResource(bePlayer, choise)"
                    >
                        <HandCard
                            :group="'resource'"
                            :type="choise"
                            :flatGraphics="flatGraphics"
                        />
                    </div>

                    <div class="clear-both"></div>

                    <!-- dont show if getting only 1 resource from goldmine, but show when selecting last goldmine resource -->
                    <div v-if="bePlayer.goldmineAmount != 1 || !(bePlayer.goldmineChoises.filter(obj => obj.amount == 0).length == 5)">
                        <br>
                        <p class="text-coin mb-1 select-none mono">Selected cards</p>
                        <div class="float-left" style="min-height: 50px;">
                            <div
                                v-for="(cardObj, index) in bePlayer.goldmineChoises"
                                :key="index+999"
                                class="float-right"
                            >
                                <div v-for="(card, pos) in cardObj.amount"
                                    :key="pos"
                                    class="hand-card-small mb-0 mt-0"
                                    :class="[
                                        cardObj.type,
                                        {'hand-card-small-2': ! flatGraphics }
                                    ]"
                                    @click="cardObj.amount--, bePlayer.goldmineAmount++"
                                ></div>
                            </div>
                        </div>
                    </div>

                </div>
                <div v-else-if="bePlayer.modalType == 'scoreboard'" class="score-board">
                    <div v-for="(player, index) in playersByPoints" :key="index">
                        <p class="text coin float-left uppercase letter-spacing" style="margin-top: 18px;">{{ player.name }}</p>
                        <div class="score-board-points text float-right" :class="player.color">{{ player.points }}</div>
                        <div class="clear-both"></div>
                    </div>
                    <br>
                    <a href="/"><div class="btn w-100">New game</div></a>
                    <br>

                    <div class="dice-tracker-wrapper-2">
                        <div class="dice-tracker">
                            <div
                                v-for="(staple, index) in diceTracker"
                                :key="index"
                                :style="{ backgroundImage: `linear-gradient(0deg, ${staple.number == 7 ? '#AC9A76' : '#45596C'} 0%,  ${staple.number == 7 ? '#AC9A76' : '#425E78'} ${((staple.count/diceTrackerMax.count)*100)}%, #383832 ${((staple.count/diceTrackerMax.count)*100)}%)` }"
                                class="staple"
                            ></div>
                        </div>
                        <div class="dice-tracker-numbers" style="margin-top: 4px;">
                            <div
                                v-for="(staple, index) in diceTracker"
                                :key="index"
                            >
                                <div style="font-size: 12px; width: 20px;" class="text-center">{{ staple.count }}</div>
                            </div>
                        </div>

                        <div class="dice-tracker-numbers">
                            <div
                                v-for="(staple, index) in diceTracker"
                                :key="index"
                                class="flex-container"
                            >
                                <!-- text-coin -->
                                <!-- color: #435B72; -->
                                <div style="font-size: 9px; width: 20px;" class="text-center text-coin" :class="{'text-coin': index+2 == 7}"><span v-if="index+2 < 10">0</span>{{ index+2 }}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="map-wrapper">
            <tile
                v-for="(tile, key) in tiles"
                :tile="tile"
                :actionConfirmed="actionConfirmed"
                :key="key"
                :countTilesInRow="countTilesInRow(tile.row)"
                :activePlayer="activePlayer"
                :bePlayer="bePlayer"
                :forceUpdate="forceUpdate"
                :focusMode="focusMode"
                :focusType="focusType"
                :robber="robber"
                :pirate="pirate"
                :merchant="merchant"
                :roads="roads.filter(road => road.tile == tile)"
                :knights="knights.filter(knight => knight.tile == tile)"
                :houses="houses.filter(house => house.tile == tile)"
                :cities="cities.filter(city => city.tile == tile)"
                :highlightedSpots="bePlayer.highlightedSpots"
                :highlightedRoadSpots="getHighlightedRoadSpots"
                :longestRoad="longestRoad.filter(road => road.tile == tile)"
                :inventorTiles="inventorTiles"
                :buildingDistanceActions="buildingDistanceActions"
                :gamePhase="gamePhase"
                :seafaresExpansion="seafaresExpansion"
                :lastClickedRoadSpotTiles="lastClickedRoadSpotTiles"
                :lastClickedRoadSpotConnectingRoads="lastClickedRoadSpotConnectingRoads"
                :lastClickedRoadSpotConnectingSpots="lastClickedRoadSpotConnectingSpots"
                :flatGraphics="flatGraphics"
                :allHarborKeys="allHarborKeys"
                :tileDots="tileDots"
                :hiddenTiles="tiles.filter(tile => tile.hidden).length"
                :multiplayer="multiplayer"
                @spotClicked="spotClicked"
                @spotActionClicked="spotActionClicked"
                @roadSpotClicked="roadSpotClicked"
                @roadSpotActionClicked="roadSpotActionClicked"
                @boatSpotActionClicked="boatSpotActionClicked"
                @tileClicked="tileClicked"

                :showCoordinates="showCoordinates"
            ></tile>
        </div>
        <div class="war-boat-tile-mobile mobile-show">
            <div class="cities-knights-count-mobile">
                <div>
                    {{ boatPosition+1 }}/8<span class="symbol war float-right" style="position: relative; top: 3px;"></span>
                </div>
                <div style="padding-left: 2px;">{{ cities.length }}</div>
                <div>{{ allActiveKnightsCount }}</div>
            </div>

            <div class="war-boat-mobile" style="position: absolute; top: 15px; left: 15px;"></div>
            <City
                :color="'neutral'"
                style="position: absolute; top: 6px !important; left: 91px !important;"
            />
            <Knight
                :color="'neutral'"
                :level="1"
                :active="true"
                style="position: absolute; top: 26px; left: 161px;"
            />
        </div>

        <div class="war-boat-wrapper">
            <div class="war-boat-tile mobile-hide">
                <div class="war-boat" :class="[
                    'position-'+ boatPosition,
                    {'mirrored': [3, 4, 5].indexOf(boatPosition) != -1},
                ]"
                ></div>
                <div class="cities-knights-count">
                    <div>
                        <City
                            :color="'neutral'"
                            style="position: relative; top: -16px; left: -16px;"
                        />
                    </div>
                    <div style="padding-left: 2px;" class="mono text-coin">{{ cities.length }}</div>
                    <div>
                        <Knight
                            :color="'neutral'"
                            :level="1"
                            :active="true"
                            style="position: relative; top: -1px; left: 2px;"
                        />
                        <!-- <div class="knight level-1 active neutral"></div> -->
                    </div>
                    <div class="mono text-coin">{{ allActiveKnightsCount }}</div>
                </div>
            </div>
        </div>

        <div class="quick-trade-wrapper mobile-hide" v-if="bePlayer && showMainPlayer">
            <div class="quick-trade-trigger">Trade cards</div>

            <div class="quick-trade-cards">
                <div class="hand-card-small basic offer-opponents" @click="makeOffer(bePlayer)" v-if="!bePlayer.offerOpponents"></div>
                <div
                    class="hand-card-small"
                    :class="[card, {'hand-card-small-2': ! flatGraphics }]"
                    :key="card"
                    v-for="card in ['lumber', 'brick', 'wool', 'grain', 'ore', 'cloth', 'coin', 'paper']"
                    @click="quickTradeGet(card, bePlayer)"
                ></div>
            </div>
        </div>

        <div v-if="showHiddenFeatures">
            <div class="btn" @click="focusModeOn('move robber', activePlayer)" style="position: absolute; left: 700px; top: 50px; z-index: 9999;">move robber</div>
            <div class="btn" @click="boatPosition = 0" style="position: absolute; left: 900px; top: 50px; z-index: 9999;">Reset boat</div>
            <div class="btn" @click="boatPosition++" style="position: absolute; left: 1100px; top: 50px; z-index: 9999;">Boat forward</div>
            <div class="btn" @click="closeAllKnights()" style="position: absolute; left: 1300px; top: 50px; z-index: 9999;">Close all knights</div>
            <div class="btn" @click="focusModeOn('select aqueduct resource', bePlayer); showModal('select aqueduct resource', bePlayer)" style="position: absolute; left: 1500px; top: 50px; z-index: 9999;">pick aqueduct</div>
        </div>

        <!-- <div class="btn" @click="aiMoveKnight(false, true);" style="position: absolute; left: 1800px; top: 100px; z-index: 9999;">moveKnight</div>
        <div class="btn" @click="aiPlayProgressCard('intrigue');" style="position: absolute; left: 1800px; top: 50px; z-index: 9999;">aiPlayIntigue</div>
        <div class="btn" @click="scanLongestRoad();" style="position: absolute; left: 1600px; top: 50px; z-index: 9999;">scanLongest</div>
        <div class="btn" @click="activateBlue()" style="position: absolute; left: 1200px; top: 50px; z-index: 9999;">activateKight</div>
        <div class="btn" @click="activePlayer = selectPlayer('blue')" style="position: absolute; left: 1400px; top: 50px; z-index: 9999;">activate Blue</div>
        <div class="btn" @click="copyGame()" style="position: absolute; left: 1000px; z-index: 9999;">Save scenario</div>
        <div class="btn" @click="useScenario()" style="position: absolute; left: 1200px; z-index: 9999;">Use scenario</div> -->
        <div v-if="multiplayer && false">
        <!-- <div v-if="multiplayer"> -->
            <!-- <div class="btn" @click="focusModeOn('select goldmine resources', bePlayer); showModal('select goldmine resources', bePlayer)" style="position: absolute; left: 400px; top: 0px; z-index: 9999;">pick goldmine</div> -->

            <div class="btn" @click="boatPosition = 0" style="position: absolute; left: 600px; top: 0px; z-index: 9999;">Reset boat</div>
            <div class="btn" @click="bePlayer.waitingForMe = false" style="position: absolute; left: 600px; top: 50px; z-index: 9999;">waitingForMe</div>
            <div class="btn" @click="boatPosition++" style="position: absolute; left: 800px; top: 0px; z-index: 9999;">Boat forward</div>
            <div class="btn" @click="focusMode = false; focusType = null;" style="position: absolute; left: 800px; top: 50px; z-index: 9999;">focusOff</div>

            <div class="btn" @click="copyGame()" style="position: absolute; left: 1000px; z-index: 9999;">Save scenario</div>
            <div class="btn" @click="activePlayer.progressCards.unshift({ deck: 'cloth', label: 'master merchant', active: false, });" style="position: absolute; left: 1000px; top: 50px; z-index: 9999;">give prgrCard</div>
            <div class="btn" @click="useScenario()" style="position: absolute; left: 1200px; z-index: 9999;">Use scenario</div>
            <div class="btn" @click="focusModeOn('move robber', activePlayer)" style="position: absolute; left: 1200px; top: 50px; z-index: 9999;">move robber</div>

            <div class="btn" @click="giveCards()" style="position: absolute; left: 1400px; top: 0px; z-index: 9999;">give cards</div>
            <div class="btn" @click="saveHand(bePlayer)" style="position: absolute; left: 1400px; top: 50px; z-index: 9999;">save hand</div>
            <div class="btn" @click="deletePrgrCards()" style="position: absolute; left: 1600px; top: 0px; z-index: 9999;">delete prgr</div>
            <div class="btn" @click="giveCards()" style="position: absolute; left: 1600px; top: 50px; z-index: 9999;">give cards</div>


            <div style="position: absolute; left: 350px;">
                focusMode: {{ focusMode }}<br>
                focusType: {{ focusType }}<br>
                boat: {{ boatPosition }}<br>
                <!-- dices: {{ dices }}<br> -->
                <!-- waitingForPlayers: {{ waitingForPlayers }}<br> -->
                waiting: {{ bePlayer.waitingForMe }}<br>
                loadingPrgrCard: {{ loadingPrgrCard }}<br>
                loadGameDataCompleted: {{ loadGameDataCompleted }}<br>
                selectingGoldMine: {{ selectingGoldMine }}<br>
                dicesRolling: {{ dicesRolling }}<br>
            </div>
        </div>
        <!-- <div class="btn" @click="activePlayer.progressCards.unshift({ deck: 'paper', label: 'alchemist', active: false, });" style="position: absolute; left: 600px; top: 50px; z-index: 9999;">give prgrCard</div> -->

        <!-- <div class="btn" @click="housesToCities()" style="position: absolute; left: 800px; top: 50px; z-index: 9999;">housesToCities</div> -->
        <!-- <div class="btn" @click="aiMoveKnight(false, false, true)" style="position: absolute; left: 800px; top: 50px; z-index: 9999;">moveKnights</div> -->

        <!-- <div class="btn" @click="focusModeOn('select a progress card', selectPlayer('white'))" style="position: absolute; left: 1400px; top: 50px; z-index: 9999;">focusOn</div> -->

        <!-- <div class="btn" @click="givePlayersProgressCards(players.filter(player => player.color != 'orange'), 'cloth')" style="position: absolute; left: 1400px; top: 50px; z-index: 9999;">dice prgr</div> -->

        <!-- <div class="btn" @click="log(':progress_paper :playerName threw away a progress card', activePlayer)" style="position: absolute; left: 600px; top: 0px; z-index: 9999;">Log</div> -->
        <!-- <div class="btn" @click="log(':paper :playerName upgraded paper to level 3.<br>' + ':offset When any dice roll but 7 doesnt generate cards,<br>:offset :playerName will select a free resource.', activePlayer)" style="position: absolute; left: 600px; top: 0px; z-index: 9999;">Log</div> -->

        <!-- <div class="btn" @click="giveCards()" style="position: absolute; left: 1400px; top: 50px; z-index: 9999;">give cards</div> -->
        <!-- <div class="btn" @click="activePlayer.progressCards.unshift({ deck: 'cloth', label: 'master merchant', active: false, });" style="position: absolute; left: 800px; top: 50px; z-index: 9999;">give prgrCard</div> -->

        <!-- <div class="btn" @click="selectPrgrCards()" style="position: absolute; left: 1000px; top: 50px; z-index: 9999;">sel prgr</div>
        <div class="btn" @click="saveGame(false, true, false)" style="position: absolute; left: 1000px; top: 50px; z-index: 9999;">updatePlayerOnly</div>
        <div class="btn" @click="saveGame()" style="position: absolute; left: 800px; top: 50px; z-index: 9999;">updateAllPlayers</div>

        <div class="btn" @click="focusModeOn('move robber', activePlayer)" style="position: absolute; left: 800px; top: 50px; z-index: 9999;">move robber</div>

        <div class="btn" @click="loadOtis()" style="position: absolute; left: 800px; top: 50px; z-index: 9999;">load Otis</div>
        <div class="btn" @click="progressCardBack('spy', 'coin')" style="position: absolute; left: 600px; top: 50px; z-index: 9999;">inventor to db</div>
        <div class="btn" @click="saveYou()" style="position: absolute; left: 600px; top: 50px; z-index: 9999;">save you</div>
        <div class="btn" @click="activePlayer.progressCards.unshift({ deck: 'cloth', label: 'master merchant', active: false, })" style="position: absolute; left: 1000px; top: 50px; z-index: 9999;">prgr to activePlayer</div>
        -->

        <!-- <div class="btn" @click="givePlayerProgressCard2(activePlayer, 'cloth')" style="position: absolute; left: 800px; top: 50px; z-index: 9999;">pick cloth from db</div> -->


        <!-- <div class="btn" @click="papersToThird()" style="position: absolute; left: 1000px; top: 50px; z-index: 9999;">papers to third</div> -->

        <div v-if="logToConsole && false">
            <div class="btn" @click="activePlayer.progressCards.unshift({ deck: 'coin', label: 'spy', active: false, });" style="position: absolute; left: 800px; top: 100px; z-index: 9999;">give prgrCard</div>
            <!-- <div class="btn" @click="flatGraphics = !flatGraphics" style="position: absolute; left: 400px; z-index: 9999;">flatGraphics</div> -->
            <div class="btn" @click="loadGameData()" style="position: absolute; left: 600px; z-index: 9999;">Load gameData</div>
            <div class="btn" @click="papersToThird()" style="position: absolute; left: 1000px; top: 50px; z-index: 9999;">papers to third</div>
            <div class="btn" @click="rollDices(100, 1, 6, 3, true)" style="position: absolute; left: 1200px; top: 50px; z-index: 9999;">roll 7</div>
            <div class="btn" @click="giveCards()" style="position: absolute; left: 1400px; top: 50px; z-index: 9999;">give cards</div>
            <div class="btn" @click="boatPosition++" style="position: absolute; left: 1600px; top: 50px; z-index: 9999;">boatForward</div>
            <!-- <div class="btn" @click="allToSecond()" style="position: absolute; left: 1000px; top: 50px; z-index: 9999;">all to second</div> -->
            <!-- <div class="btn" @click="boatPosition = 6" style="position: absolute; left: 1000px; top: 50px; z-index: 9999;">boatToEnd</div> -->
            <div class="btn" @click="housesToCities()" style="position: absolute; left: 800px; top: 50px; z-index: 9999;">housesToCities</div>

            <div class="btn" @click="restartGame()" style="position: absolute; left: 800px; z-index: 9999;">Restart game</div>
            <div class="btn" @click="logData()" style="position: absolute; left: 1400px; z-index: 9999;">Log data</div>
            <div style="position: absolute; left: 350px;">
                gamePhase: {{ gamePhase }}<br>
                activePlayer: {{ activePlayer.color }}<br>
                focusMode: {{ focusMode }}<br>
                focusType: {{ focusType }}<br>
                highlightedSpots: {{ activePlayer.highlightedSpots }}<br>
                inventorTiles: {{ inventorTiles }}<br>
                highlightedRoadSpots: {{ highlightedRoadSpots }}<br>
                activePlayer.highlightedSpots: {{ activePlayer.highlightedSpots }}<br>
                commercialHarborOpponents: {{ commercialHarborOpponents }}
                <!-- merchant.tile:
                <div v-if="merchant">
                    <div v-if="merchant">
                        {{ merchant.tile }}
                    </div>
                </div> -->
            </div>
        </div>

        <MainPlayer
            class="main-player"
            :player="bePlayer"
            :diceStats="diceStats"
            :players="players"
            :metropolisTopLevel="metropolisTopLevel"
            :waitingForMainPlayer="bePlayer.waitingForMe"
            :activePlayer="activePlayer"
            :focusMode="focusMode"
            :focusType="focusType"
            :spyIndex="spyIndex"
            :modalPlayer="modalPlayer"
            :longestRoad="longestRoad"
            :merchant="merchant"
            :activeCard="activeCard"
            :showInfo="showInfo"
            :forceUpdate="forceUpdate"
            :dices="dices"
            :hideSelected="hideSelected"
            :gamePhase="gamePhase"
            :allowedHandCards="allowedHandCardsBePlayer"
            :playToPoints="playToPoints"
            :miningIrrigationCount="miningIrrigationCount"
            :showGuides="showGuides"
            :seafaresExpansion="seafaresExpansion"
            :diceTracker="diceTracker"
            :flatGraphics="flatGraphics"
            :autopilot="autopilot"
            :dicesRollAnimation="dicesRollAnimation"
            :dicesRolling="dicesRolling"
            @toggleShowInfo="toggleShowInfo"
            @commodityUpgradeClicked="commodityUpgradeClicked"
            @focusModeOff="focusModeOff"
            @playerPointsOrCardsClicked="playerPointsOrCardsClicked"
            @playProgressCard="playProgressCard"
            @activeCardOff="activeCardOff"
            @selectedHarborResource="selectedHarborResource"
            @selectedHarborCommodity="selectedHarborCommodity"
            @clickDices="clickDices"
            @endTurnClicked="endTurnClicked"
            @clickOwnDices="clickOwnDices"
            @acceptTradeOffer="acceptTradeOffer"
            @rejectTradeOffer="rejectTradeOffer"
            @makeCounterOffer="makeCounterOffer"
            @cancelAcceptedOffer="cancelAcceptedOffer"
            @updateBankOffers="updateBankOffers"
            @toggleGuides="toggleGuides"
            @resetOffer="resetOffer"
            @quickTradeGet="quickTradeGet"
            @canTrade="canTrade"
            @updateLocalStorage="updateLocalStorage"
            @aiBuildInitialHouse="aiBuildInitialHouse"
            @aiBuildInitialCity="aiBuildInitialCity"
            @aiBuildInitialRoad="aiBuildInitialRoad"
            v-if="bePlayer && showMainPlayer"
        />

        <!-- v-show="player != bePlayer" -->
        <div v-if="showOpponents" class="opponents-wrapper">
            <Opponent
                v-for="player in orderedOpponents"
                v-show="bePlayer && player.color != bePlayer.color"
                :players="players"
                :key="player.color"
                :player="player"
                :waitingForPlayer="player.waitingForMe"
                :metropolisTopLevel="metropolisTopLevel"
                :focusType="focusType"
                :activePlayer="activePlayer"
                :longestRoad="longestRoad"
                :merchant="merchant"
                :dices="dices"
                :gamePhase="gamePhase"
                :showPotentialPoints="showPotentialPoints"
                :cities="cities"
                :showGuides="showGuides"
                :seafaresExpansion="seafaresExpansion"
                :scrollTo="scrollTo"
                :flatGraphics="flatGraphics"
                @becomePlayer="becomePlayer"
                @playerPointsOrCardsClicked="playerPointsOrCardsClicked"
                @clickDices="clickDices"
                @clickOwnDices="clickOwnDices"
            />
            <div class="clear-both"></div>
        </div>
    </div>
</template>

<script>
    import Knight from './pieces/Knight';
    import City from './pieces/City';

    import Avatar from './Avatar';

    import Tile from './map/Tile';
    import MainPlayer from './MainPlayer';
    import Opponent from './Opponent';
    import ProgressCard from './ProgressCard';
    import ProgressCardInfo from './ProgressCardInfo';
    import HandCards from './HandCards';
    import HandCard from './cards/HandCard';
    import TradeOffer from './TradeOffer';

    import generateMap from '../helpers/generateMap.js'
    import generateProgressCards from '../helpers/generateProgressCards.js'
    import generatePlayers from '../helpers/generatePlayers.js'

    import mapLogic from '../helpers/mapLogic.js'
    import handCardsLogic from '../helpers/handCardsLogic.js'
    import progressCardsLogic from '../helpers/progressCardsLogic.js'
    import longestRoadLogic from '../helpers/longestRoadLogic.js'
    import dev from '../helpers/dev.js'

    import aiInitialBuild from '../helpers/aiInitialBuild.js'
    import ai from '../helpers/ai.js'

    import router from '../router'

    export default {
        mixins: [
            generateMap,
            generateProgressCards,
            generatePlayers,
            mapLogic,
            handCardsLogic,
            progressCardsLogic,
            longestRoadLogic,
            dev,
            aiInitialBuild,
            ai,
        ],
        /**
         * Todo:
         *
         */
        data() {
            return {
                // config
                gamePhase: 'decide starting player'
                // gamePhase: 'initial build',
                // gamePhase: 'regular'

                , flatGraphics:             0
                , tileDots:                 1
                , diceStats:                1

                , devMode:                  0

                , playerAi:                 0
                , bePlayerOnEndTurn:        0

                , aiBuildSpeed:             1
                , aiActionSpeed:            1

                , logToConsole:             0
                , showHiddenFeatures:       0
                , ignoreColors:             0
                , showCoordinates:          0
                , buildEverythingForFree:   1
                , ignoreThrowAwayCards:     1

                , handCardsToPlayers:       1
                , progressCardsToPlayers:   0
                , buildStuff:               1
                , rollDicesOnTileClick:     0
                , showScoreboard:           0

                , showPotentialPoints:      0
                , showOpponents:            1
                , showMainPlayer:           1
                , showLog:                  1

                , boatPosition:             0
                , firstBoatRound:           true

                , playToPoints: 15
                , autoBuild: 0
                , actionConfirmed: 0
                ,

                startingPlayer: 'orange',

                addedPlayers: [
                    { color: 'orange',      name: 'You',        user_id: null,      ai: 0,
                 }, { color: 'red',         name: 'Otis',       user_id: null,      ai: 1,
                 }, { color: 'blue',        name: 'Mio',        user_id: null,      ai: 1,
                //  }, { color: 'white',       name: 'Oliver',  user_id: null,      ai: 1,
                //  }, { color: 'green',    name: 'Aale',       user_id: null,      ai: 1,
                //  }, { color: 'brown',    name: 'Leevi',      user_id: null,      ai: 1,
                    },
                ],

                aiNames: ['Otis', 'Mio', 'Oliver', 'Eevee', 'Iimu', 'Jasu',],
                availableColors: ['red', 'blue', 'white', 'orange', 'green', 'brown', 'pink'],

                mapsList: [],

                tenSpotsAverage: null,
                // tenSpotsAverage: 10,

                diceRollEventsCompleted: false,
                loadGameDataCompleted: true,

                activeCard: null,
                showInfo: null,
                allShowInfo: null,

                // just asign it a value so games gets saved in the initial load
                joinedPlayers: '1, 2, 3',

                reverseBuildOrder: false,

                statuses: [],
                chats: [],
                showLastChat: false,
                hideLastChat: null,
                chatInput: null,
                hideFeedback: null,
                showAllStatuses: false,

                feedback: null,

                tiles: [],
                tilesCopy: [],
                actionTiles: [],

                numbers: [],

                buildingDistanceActions: true,

                harbors: ['harbor-ore', 'harbor-lumber', 'harbor-grain', 'harbor-brick'],

                numberOfRows: 9,
                numberOfTilesInFirstRow: 4,

                opponents: [],

                noTile: {
                    row: null,
                    position: null,
                    number: null,
                    type: null,
                    direction: null,
                    actionTile: null,

                    // this is added later, you might remove it
                    number: { value: null, dots: null, exponentialDots: null },
                },

                forceUpdate: 1,

                bePlayer: null,
                activePlayer: null,
                actions: [],

                waitingForPlayers: [],

                boatDice: [
                    { number: 1, type: 'cloth' },
                    { number: 2, type: 'boat' },
                    { number: 3, type: 'coin' },
                    { number: 4, type: 'boat' },
                    { number: 5, type: 'paper' },
                    { number: 6, type: 'boat' },
                ],

                diceTracker: [
                    { number: 2, count: 0, },
                    { number: 3, count: 0, },
                    { number: 4, count: 0, },
                    { number: 5, count: 0, },
                    { number: 6, count: 0, },
                    { number: 7, count: 0, },
                    { number: 8, count: 0, },
                    { number: 9, count: 0, },
                    { number: 10, count: 0, },
                    { number: 11, count: 0, },
                    { number: 12, count: 0, },
                ],

                progressCardsBasic: [],

                progressCardsCloth: [],
                progressCardsCoin: [],
                progressCardsPaper: [],
                progressCardsBasic: [],

                robber: { tile: null },
                pirate: { tile: null },
                merchant: { tile: null, player: null, spot: null, spotOffset: null },
                roads: [],
                knights: [],
                houses: [],
                cities: [],
                highlightedRoadSpots: [],
                islandTiles: [],

                dices: {
                    whiteDiceRoll: null,
                    redDiceRoll: null,
                    boatDiceRoll: { number: 2, type: 'boat' },

                    dicesRolling: true,
                    dicesRollAnimation: false,
                    whiteDiceRolling: true,
                    redDiceRolling: true,
                    boatDiceRolling: true,

                    boatDiceBackgroundOffset: 0,
                    boatDiceBackgroundOffset2: 0,
                },

                mainPlayer: 'orange',
                players: [],

                oldLongest: [],
                longestRoad: [],

                longestRoadsFound: [],
                playersLongestRoad: [],

                playersRoadsResult: [],

                checkedSpots: [],
                checkedRoads: [],
                longestRoadCheckResult: [],
                lastIntersection: null,

                // store intersections where check should turn the other way next time
                changePath: [],
                // road to turn to from intersection
                turnToRoad: null,

                metropolisTopLevel: {},
                opponentProgress: [
                    { type: 'cloth', level: 0 },
                    { type: 'coin', level: 0 },
                    { type: 'paper', level: 0 },
                ],

                focusPiece: null,
                json_temp_1: null,
                json_temp_2: null,
                string_temp_1: null,
                string_temp_2: null,
                focusMode: false,
                focusType: null,
                focusChoise: null,

                // modalMode: false,
                // modalType: null,
                modalPlayer: null,

                animatingHandCards: false,

                inventorTiles: [],

                throwAwayCount: null,

                alchemistWhite: null,
                alchemistRed: null,

                commercialHarborOpponents: [],

                trade: [],
                tradeCost: [],

                hideSelected: false,

                bankTradeOffer: [],
                bankTradeOffer2: [],
                bankTradeOffer3: [],

                paying: false,
                incoming: false,
                buildingAnimation: false,
                delay: false,

                spyIndex: null,

                timer: 0,
                aiTradeWaitMs: 0,
                tradedOnce: false,
                rejectedTradeTypes: [
                    { type: 'lumber', rejected: 0 },
                    { type: 'brick', rejected: 0 },
                    { type: 'wool', rejected: 0 },
                    { type: 'grain', rejected: 0 },
                    { type: 'ore', rejected: 0 },
                    { type: 'cloth', rejected: 0 },
                    { type: 'coin', rejected: 0 },
                    { type: 'paper', rejected: 0 },
                ],
                pickedAqueductCards: [],
                endingTurn: false,
                showGuides: 0,

                scrollTo: null,

                selectedMapId: null,
                loadedMapData: [],
                loadedMapName: '',
                seafaresExpansion: false,
                aiTactic: '',

                // This is when to show road/boat in build menu
                lastClickedRoadSpotTiles: [],
                lastClickedRoadSpotConnectingRoads: [],
                lastClickedRoadSpotConnectingSpots: [],

                userData: null,
                allHarborKeys: [],
                savingGame: false,
                multiplayer: false,

                autopilot: false,

                craneOptionsLength: 0,

                scanningLongest: false,

                showCounterOfferModal: false,

                tempPlayers: [],

                loadingPrgrCard: false,
                selectingGoldMine: false,
                dicesRollAnimation: false,
                dicesRolling: false,

                allProgressCards: [
                    { deck: 'cloth', label: 'merchant', amount: 6 },
                    { deck: 'cloth', label: 'resource monopoly', amount: 4 },
                    { deck: 'cloth', label: 'trade monopoly', amount: 2 },
                    { deck: 'cloth', label: 'commercial harbor', amount: 2 },
                    { deck: 'cloth', label: 'master merchant', amount: 2 },
                    { deck: 'cloth', label: 'merchant fleet', amount: 2 },
                    { deck: 'coin', label: 'spy', amount: 3 },
                    { deck: 'coin', label: 'deseter', amount: 2 },
                    { deck: 'coin', label: 'bishop', amount: 2 },
                    { deck: 'coin', label: 'diplomat', amount: 2 },
                    { deck: 'coin', label: 'wedding', amount: 2 },
                    { deck: 'coin', label: 'saboteur', amount: 2 },
                    { deck: 'coin', label: 'warlord', amount: 2 },
                    { deck: 'coin', label: 'intrigue', amount: 2 },
                    { deck: 'coin', label: 'point', amount: 1 },
                    { deck: 'paper', label: 'alchemist', amount: 2 },
                    { deck: 'paper', label: 'smith', amount: 2 },
                    { deck: 'paper', label: 'crane', amount: 2 },
                    { deck: 'paper', label: 'irrigation', amount: 2 },
                    { deck: 'paper', label: 'mining', amount: 2 },
                    { deck: 'paper', label: 'inventor', amount: 2 },
                    { deck: 'paper', label: 'road building', amount: 2 },
                    { deck: 'paper', label: 'medecine', amount: 2 },
                    { deck: 'paper', label: 'engineer', amount: 1 },
                    { deck: 'paper', label: 'point', amount: 1 },
                ],
            }
        },
        beforeMount() {
            setInterval(() => {
                this.timer += 0.1;

            }, 100);

            setInterval(() => {
                this.players.forEach(player => {
                    player.idleTimer += 1;
                });
            }, 1000);
        },
        async mounted() {
            await this.getUserData();
            this.getMapsList();

            if(this.$route.params.gameId != null) {
                await this.startGame();
                // await this.loadGame();
            } else {
                this.selectedMapId = 2;
            }


            // this.getMap();

            if(this.devMode == false) {
                this.ignoreColors = 0;
                this.showCoordinates = 0;
                this.buildEverythingForFree = 0;
                this.showOpponents = 1;
                this.showMainPlayer = 1;
                this.ignoreThrowAwayCards = 0;
                this.handCardsToPlayers = 0;
                this.progressCardsToPlayers = 0;
                this.buildStuff = 0;
                this.rollDicesOnTileClick = 0;
                // this.boatPosition = 0;
            }

            if(this.aiActionSpeed) this.aiActionSpeed = 500;
            if(this.aiBuildSpeed) this.aiBuildSpeed = 1000;

            if(this.$route.params.gameId == null) {
                // this.createGame();
            }

            if(this.$route.params.gameId == null || true) {

                this.generatePlayers(this.addedPlayers);

                this.bePlayer = this.selectPlayer(this.startingPlayer);
                this.activePlayer = this.selectPlayer(this.startingPlayer);
                this.generateProgressCards();

                // this.generateMap();
                // this.generateLoadedMap();
                // this.getTenBestSpotsDotAverage();
                // this.robberToDesert();

                this.opponents = [...this.players];

                this.updateTradeCosts();
                // console.log(this.gamePhase);
                if(['decide starting player', 'initial build'].includes(this.gamePhase)) {
                    // this.showAllStatuses = true;

                    this.players.forEach(player => {
                        player.dices.whiteDiceRolling = true;
                        player.dices.redDiceRolling = true;

                        player.dices.dicesRolling = true;

                        if(player.ai || true) {
                            this.clickOwnDices(player);
                        }
                    })

                    // this.log('Highest roll starts to build!');
                    this.log('Random player will start to build!');
                    // this.generateDiceRolls();
                }

                if(['initial build'].includes(this.gamePhase)) {
                    setTimeout(() => {
                        this.players.forEach(player => {
                            this.clickOwnDices(player);
                        })
                    }, 200);
                }

                if(this.showScoreboard) {
                    this.players.forEach(player => {
                        this.showModal('scoreboard', player);
                        this.focusModeOn('game over', player);
                    })

                    this.gamePhase = 'game over';
                }
            }

            this.becomePlayer(this.startingPlayer);
        },
        created() {
            if(window.history && window.history.pushState) {
                $(window).on('popstate', function() {
                    router.go()
                });
            }

            window.Echo.channel('update-game')
            .listen('UpdateGame', (e) => {
                let gameId = e.gameId;
                let userId = e.userId;
                let savePlayerColor = e.savePlayerColor;
                let saveHand = e.saveHand;

                // console.log('saveHand');
                // console.log(saveHand);

                // console.log('gameId: ' + gameId);
                // console.log('userId: ' + userId);

                // has to be matching game-id
                if(gameId != this.$route.params.gameId) return;

                // has to be from other user
                if(this.userData && userId == this.userData.id) return;

                if(saveHand) return this.loadHand(savePlayerColor);

                if(this.logToConsole) console.log('wanna load gameData');
                this.loadGameData(savePlayerColor);
            });
            window.Echo.channel('send-message')
            .listen('SendMessage', async (e) => {
                let gameId = e.gameId;

                // has to be matching game-id
                if(gameId != this.$route.params.gameId) return;

                if(this.logToConsole) console.log('WANNA LOAD CHAT');

                this.updateMessages();



                // this.showAllStatuses = true;
            });
            window.Echo.channel('send-status')
            .listen('SendStatus', (e) => {
                let gameId = e.gameId;

                // has to be matching game-id
                if(gameId != this.$route.params.gameId) return;

                if(this.logToConsole) console.log('WANNA LOAD STATUSES');

                this.updateStatuses();

                // this.showAllStatuses = true;
            });
            window.addEventListener('keydown', (e) => {
                if (e.key == 'Escape') {
                    this.rejectTradeOffer(this.bePlayer);

                    if(this.focusType == 'trade cards' && this.bePlayer == this.activePlayer) {
                        this.closeModal(this.bePlayer, true);
                        this.focusModeOff(this.bePlayer);
                    }
                }
            });
        },
        methods: {
            activateBlue() {
                this.knights.forEach(knight => {
                    if(knight.player.color == 'blue') {
                        knight.active = true;
                        knight.activatedThisTurn = false;
                    }
                });
            },
            confirmGiveResource() {
                if(this.loadGameDataCompleted == false) return;

                this.$refs.handCards.confirmGiveResource();
            },
            confirmGiveCommodity() {
                if(this.loadGameDataCompleted == false) return;

                this.$refs.handCards.confirmGiveCommodity();
            },
            confirmGiveCards() {
                if(this.loadGameDataCompleted == false) return;

                this.$refs.handCards.confirmGiveHandCards();
            },
            confirmStealCards() {
                if(this.modalPlayer) this.modalPlayer.showOutgoingHandCards = false;

                if(this.loadGameDataCompleted == false) return;

                this.$refs.handCards.confirmStealHandCards();
            },
            confirmThrowCards() {
                if(this.loadGameDataCompleted == false) return;

                this.$refs.handCards.confirmThrowAwayCards();
            },
            toggleFlatGraphics() {
                this.flatGraphics = !this.flatGraphics;

                if(this.userData == null) return;
                axios.post(`/v1/update-flat-graphics/`, {flatGraphics: this.flatGraphics})
                    .then((response) => {

                    }).catch((error) => {
                        console.log(error);
                    })
            },
            toggleTileHelpers() {
                this.tileDots = !this.tileDots;

                if(this.userData == null) return;
                axios.post(`/v1/update-tile-helpers/`, {tileHelpers: this.tileDots})
                    .then((response) => {

                    }).catch((error) => {
                        console.log(error);
                    })
            },
            toggleDiceStats() {
                this.diceStats = !this.diceStats;
            },
            async playerSelectedProgressCard(player, type, close = false) {
                if(this.multiplayer) {
                    if(this.loadGameDataCompleted == false) return;
                    if(this.savingGame) return;

                    this.givePlayerProgressCard2(player, type, true);
                } else {
                    this.givePlayerProgressCard(player, type, true);
                }
            },
            isHumanBeforeAi() {
                if(!this.multiplayer) return true;

                let filteredPlayers = this.players
                    .filter(player => player.color == this.activePlayer.color || player.ai == 0);

                let current = filteredPlayers.indexOf(this.activePlayer);
                let previous = current -= 1;
                if(previous < 0) previous += filteredPlayers.length;
                let previousPlayer = filteredPlayers[previous];

                // the player trying to save for ai should be the player just before the ai
                return previousPlayer.color == this.bePlayer.color;
            },
            updateLocalStorage() {
                this.bePlayerToLocalStorage();
            },
            bePlayerToLocalStorage() {
                localStorage.setItem('bePlayer', JSON.stringify(this.bePlayer));

                this.bePlayerLocalStorage = JSON.parse(localStorage.getItem("bePlayer"));
            },
            async getUserData() {
                await axios.get('/v1/user')
                .then((response) => {
                    this.userData = response.data[0];

                    if(this.userData != null) {
                        this.flatGraphics = this.userData.flat_graphics;
                        this.tileDots = this.userData.tile_helpers;
                    }
                }).catch((error) => {
                    // console.log(error);
                })
            },
            updateMessages() {
                axios.get('/v1/messages/' + this.$route.params.gameId)
                    .then((response) => {

                        let messages = [];

                        response.data.forEach(obj => {

                            let player = this.players.find(player => player.color == obj.color);
                            let message = obj.message;

                            if(! player) return;

                            messages.unshift({ text: message, player: player });
                        })

                        this.chats = messages;

                        // show last message
                        if(this.showLastChat == true) clearTimeout(this.hideLastChat);

                        this.showLastChat = true;

                        // how long the last chat-msg will be visible
                        this.hideLastChat = setTimeout(() => {
                            this.showLastChat = false;
                        }, 2500);

                        if(this.showAllStatuses == true) {
                            this.$nextTick(() => {
                                const container = this.$refs.chats;
                                container.scrollTop = container.scrollHeight;
                            });
                        }
                    }).catch((error) => {
                        if(this.logToConsole) console.log(error);
                    })
            },
            async updateStatuses() {

                if(this.userData != null) {
                    if(this.gamePhase == 'regular' && !this.diceRollEventsCompleted) await this.waitForDiceRollEvents();
                    if(this.gamePhase == 'regular' && !this.loadGameDataCompleted) await this.waitForLoadGameData();
                }

                let delay = 0;
                if(this.activePlayer && this.bePlayer) {
                    if(this.multiplayer) {
                        if(this.activePlayer.color != this.bePlayer.color) delay = 800;
                    }
                }

                setTimeout(() => {
                    axios.get('/v1/statuses/' + this.$route.params.gameId)
                        .then((response) => {

                            let statuses = [];

                            response.data.statuses.forEach(obj => {

                                let player = this.players.find(player => player.color == obj.color);
                                let status = obj.text;
                                let roll = obj.roll;
                                let visibleFor = obj.visible_for;

                                if(! status) return;

                                if(! this.flatGraphics) {
                                    status = status.replaceAll('<span class="symbol lumber"></span>', '<span class="symbol symbol-statuses lumber-2"></span>');
                                    status = status.replaceAll('<span class="symbol brick"></span>', '<span class="symbol symbol-statuses brick-2"></span>');
                                    status = status.replaceAll('<span class="symbol wool"></span>', '<span class="symbol symbol-statuses wool-2"></span>');
                                    status = status.replaceAll('<span class="symbol grain"></span>', '<span class="symbol symbol-statuses grain-2"></span>');
                                    status = status.replaceAll('<span class="symbol ore"></span>', '<span class="symbol symbol-statuses ore-2"></span>');
                                    status = status.replaceAll('<span class="symbol cloth"></span>', '<span class="symbol symbol-statuses cloth-2"></span>');
                                    status = status.replaceAll('<span class="symbol coin"></span>', '<span class="symbol symbol-statuses coin-2"></span>');
                                    status = status.replaceAll('<span class="symbol paper"></span>', '<span class="symbol symbol-statuses paper-2"></span>');
                                    status = status.replaceAll('<span class="symbol none"></span>', '<span class="symbol-offset-unflat"></span>');
                                }
                                if(visibleFor == null || visibleFor == this.bePlayer.color) {
                                    statuses.unshift({ label: status, player: player, roll: roll, visibleFor: visibleFor });
                                }
                            })

                            this.statuses = statuses;

                            // this.$nextTick(() => {
                            //     const container = this.$refs.chats;
                            //     container.scrollTop = container.scrollHeight;
                            // });

                            // this.chats.push({ text: text, player: player });
                        }).catch((error) => {
                            if(this.logToConsole) console.log(error);
                        })
                }, delay);
            },
            canTrade(canTrade) {
                if(canTrade == false && this.activePlayer.ai == 1) {
                    // don't show trades if player can't trade
                    // setTimeout(() => {
                    this.rejectTradeOffer(this.bePlayer);
                    // }, 2000);
                }
            },
            leaderGrainTilesCount() {

                let uniqueGrainTiles = [];
                let uniqueGoldTiles = [];

                this.buildings
                    .filter(building => building.player.color == this.leader.color)
                    .forEach(building => {
                        this.spotTiles(building.tile, building.position).forEach(tile => {

                        if(tile.type == 'grain') {
                            let grainTarget = uniqueGrainTiles.find(grainTile => grainTile == tile)

                            if(!grainTarget) {
                                uniqueGrainTiles.push(tile);
                            }
                        }

                        if(tile.type == 'goldmine') {
                            let goldTarget = uniqueGoldTiles.find(goldTile => goldTile == tile)

                            if(!goldTarget) {
                                uniqueGoldTiles.push(tile);
                            }
                        }
                    })
                })

                // if leader has goldmines, don't put robber on grain anyway
                if(uniqueGoldTiles.length > 0 && uniqueGrainTiles.length == 1) return 0;

                return uniqueGrainTiles.length;
            },
            playerTileTypes(player) {
                let tileTypes = [];

                this.buildings
                    .filter(building => building.player == player)
                    .forEach(building => {
                        this.spotTiles(building.tile, building.position)
                            .filter(tile => tile.group == 'land' && tile.type != 'desert')
                            .forEach(tile => {
                                if(! tileTypes.includes(tile.type)) tileTypes.push(tile.type);
                            })
                    })

                return tileTypes;
            },
            generateDiceRolls() {
                let diceInterval = setInterval(() => {
                    let roll = Math.ceil(Math.random()*6)+Math.ceil(Math.random()*6)
                    this.diceTracker.find(obj => obj.number == roll).count++;
                }, 5);

                setTimeout(() => {
                    clearInterval(diceInterval);
                }, 3000);
            },
            submitChat() {
                this.chat([this.chatInput], this.bePlayer);
                this.chatInput = null;

                this.$nextTick(() => {
                    const container = this.$refs.chats;
                    container.scrollTop = container.scrollHeight;
                });
            },
            toggleStatuses() {
                this.showAllStatuses = !this.showAllStatuses;

                this.$nextTick(() => {
                    if(this.showAllStatuses && this.chats.length) {
                        const container = this.$refs.chats;
                        container.scrollTop = container.scrollHeight;
                    }
                });
            },
            getMapsList() {
                axios.get('/v1/maps')
                    .then((response) => {
                        this.mapsList = response.data;
                    }).catch((error) => {
                        if(this.logToConsole) console.log(error);
                    })
            },
            getMap() {
                axios.get('/v1/maps/' + this.selectedMapId)
                    .then((response) => {
                        this.loadedMapData = JSON.parse(response.data.data);
                        // this.loadedMapData = response.data.data;
                        this.generateLoadedMap();

                        this.loadedMapName = response.data.name;
                        this.seafaresExpansion = response.data.seafares_expansion;
                        this.aiTactic = response.data.ai_tactic;

                        document.title =  this.playToPoints + ' points - ' + this.loadedMapName + ' - Gotland';
                    }).catch((error) => {
                        if(this.logToConsole) console.log(error);
                    })
            },
            async startGame() {

                await axios.get('/v1/games/' + this.$route.params.gameId)
                .then(async (response) => {
                    this.selectedMapId = response.data.map_id;

                    if(response.data.started_at == null) {
                        // console.log('new game')
                        // console.log(response.data);
                        this.playToPoints = JSON.parse(response.data.game_config).playToPoints;
                        this.autoBuild = JSON.parse(response.data.game_config).autoBuild;

                        // let aiOpponents = JSON.parse(response.data.game_config).aiOpponents;
                        let addedPlayers = JSON.parse(response.data.game_config).players;

                        this.addedPlayers = addedPlayers;

                        let playerName = null;
                        let playerColor = null;

                        if(this.userData == null) {
                            playerName = addedPlayers[0].name;
                            playerColor = addedPlayers[0].color;
                        } else {
                            let target = addedPlayers.find(player => player.user_id == this.userData.id);

                            if(target) {
                                playerName = target.name;
                                playerColor = target.color;
                            } else {
                                playerName = addedPlayers[0].name;
                                playerColor = addedPlayers[0].color;
                            }
                        }

                        // this.availableColors = this.availableColors.filter(color => color != playerColor);
                        // this.aiNames = this.aiNames.filter(name => name != playerName);

                        this.startingPlayer = playerColor;

                        await this.getMap();
                        setTimeout(() => {
                            this.saveGame();
                        }, 300);
                    } else {
                        // console.log('load game')

                        this.loadGame(this.$route.params.gameId);
                    }

                }).catch((error) => {
                    if(this.logToConsole) console.log(error);
                })

            },
            createGame() {
                if(this.logToConsole) console.log('creating game');
                axios.post('/v1/create-game', {
                    data: {
                        gamePhase: this.gamePhase,
                        activePlayer: this.activePlayer,
                        bePlayer: this.bePlayer,
                        players: this.players,
                        houses: this.houses,
                        cities: this.cities,
                        roads: this.roads,
                        knights: this.knights,
                        tiles: this.tiles,
                        boatPosition: this.boatPosition,
                        firstBoatRound: this.firstBoatRound,
                        playToPoints: this.playToPoints,
                        robber: this.robber,
                        merchant: this.merchant,
                        longestRoad: this.longestRoad,
                        focusType: this.focusType,
                        focusMode: this.focusMode,
                        focusPiece: this.focusPiece,
                    },
                })
                .then((response) => {
                    router.push({ name: 'games', params: { gameId: response.data.game_id } })
                }).catch((error) => {
                    if(this.logToConsole) console.log(error);
                })
            },
            loadMap() {
                axios.get('./tiles.json').then((response) => {
                    this.tiles = response.data;
                })
            },
            loadRobber() {
                axios.get('./robber.json').then((response) => {
                    this.robber = response.data;
                })
            },
            loadMerchant() {
                axios.get('./merchant.json').then((response) => {
                    this.merchant = response.data;
                })
            },
            housesToCities() {
                this.houses.forEach(house => {
                    if(house.player != this.activePlayer) return;
                    this.upgradeToCity(house.tile, house.position, true);
                });
            },
            async restartGame() {

                await axios.post('/v1/restart-game/' + this.$route.params.gameId)
                    .then(async (response) => {

                    }).catch((error) => {
                        if(this.logToConsole) console.log(error);
                    })
            },
            async logData() {
                await axios.get('/v1/games/' + this.$route.params.gameId)
                    .then(async (response) => {
                        if(this.logToConsole) console.log('---')
                        if(this.logToConsole) console.log(JSON.parse(response.data.data).focusType + ' for ' + JSON.parse(response.data.data).activePlayer.color);
                    }).catch((error) => {
                        if(this.logToConsole) console.log(error);
                    })
            },
            selectPrgrCards() {
                this.players
                .filter(player => player.ai == false)
                .forEach(player => {
                    this.focusModeOn('select a progress card', player);
                    this.showModal('select a progress card', player);
                })
            },
            loadOtis() {
                let otis = this.players.find(player => player.color == 'red');
                let tempOtis = this.tempPlayers.find(player => player.color == 'red');

                // otis.name = tempOtis.name;
                // otis.name = tempOtis.name;
                console.log('loadOtis...');
                this.players.push(JSON.parse(JSON.stringify(tempOtis)));
                this.opponents = [...this.players];
                console.log(this.players);
            },
            papersToThird() {
                this.players.forEach(player => {
                    if(player.ai) return;
                    player.progress.find(prgr => prgr.type == 'paper').level = 3;
                });
            },
            deletePrgrCards() {
                this.players.forEach(player => {
                    player.progressCards = [];
                });
            },
            giveCards() {
                this.players.forEach(player => {
                    player.handCards[3].amount++;
                    player.handCards[4].amount++;
                    player.handCards[5].amount++;
                    player.handCards[6].amount++;
                    player.handCards[7].amount++;
                })
            },
            closeAllKnights() {
                this.knights.forEach(knight => {
                    knight.active = false;
                });
            },
            allToSecond() {
                this.players.forEach(player => {
                    player.progress.find(prgr => prgr.type == 'cloth').level = 2;
                    player.progress.find(prgr => prgr.type == 'coin').level = 2;
                    player.progress.find(prgr => prgr.type == 'paper').level = 2;
                });
            },
            async copyGame() {

                await axios.post('/v1/copy-game/' + this.$route.params.gameId)
                    .then(async (response) => {
                        if(this.logToConsole) console.log('Save scenario')
                    }).catch((error) => {
                        if(this.logToConsole) console.log(error);
                    })
            },
            async useScenario() {

                await axios.post('/v1/use-scenario/' + this.$route.params.gameId)
                    .then(async (response) => {
                        if(this.logToConsole) console.log('Use scenario')
                    }).catch((error) => {
                        if(this.logToConsole) console.log(error);
                    })
            },
            async loadHand(playerColor = null) {

                if(this.bePlayer) {
                    if(playerColor == this.bePlayer.color) return;
                }

                this.loadGameDataCompleted = false;

                await axios.get('/v1/games/' + this.$route.params.gameId)
                .then(async (response) => {


                    // fix here
                    let tempPlayers = [];

                    response.data.players.forEach(item => {
                        tempPlayers.push(JSON.parse(item));
                    })

                    tempPlayers.forEach(item => {
                        if(item.color == playerColor) {
                            // console.log(item.color + ' found!!')
                            let targetPlayer = this.players.find(player => player.color == playerColor);

                            if(targetPlayer) {
                                this.focusType = JSON.parse(response.data.data).focusType;
                                this.focusMode = JSON.parse(response.data.data).focusMode;

                                // console.log(this.focusType);
                                // console.log(this.focusMode);

                                targetPlayer.handCards = item.handCards;
                                targetPlayer.goldmineAmount = item.goldmineAmount;
                                targetPlayer.waitingForMe = item.waitingForMe;

                                targetPlayer.modalMode = item.modalMode;
                                targetPlayer.modalType = item.modalType;

                                targetPlayer.offerAnswer = item.offerAnswer;
                                targetPlayer.tradeGet = item.tradeGet;
                            }
                        }
                        // this.players.push(JSON.parse(JSON.stringify(item)));
                    })

                    let waitingForSomeone = false;
                    tempPlayers.forEach(item => {
                        // console.log(item.color)
                        // console.log(item.waitingForMe)
                        // console.log('---')
                        if(item.waitingForMe == true) waitingForSomeone = true;
                    });

                    if(waitingForSomeone == false) {
                        // console.log('focusOff!');
                        this.focusMode = false;
                        this.focusType = null;
                    }

                    this.opponents = [...this.players];

                    setTimeout(() => {
                        this.players.forEach(player => {
                            player.showOutgoingHandCards = false;
                        });
                    }, 100);

                    setTimeout(() => {
                        this.players.forEach(player => {
                            player.showIncomingHandCards = false;
                        });
                    }, 1000);

                    this.loadGameDataCompleted = true;
                }).catch((error) => {
                    if(this.logToConsole) console.log(error);
                })
            },
            async loadGameData(playerColor = null) {

                let resetGoldmineChoises = true;

                // await this.waitForPickProgressCard();

                if(this.loadingPrgrCard == true) return;

                if(this.bePlayer) {
                    if(playerColor == this.bePlayer.color) return;
                }

                // console.log('PlayerColor: ' + playerColor);

                // if(this.logToConsole) console.log('load game data...');

                this.loadGameDataCompleted = false;

                await axios.get('/v1/games/' + this.$route.params.gameId)
                .then(async (response) => {

                    this.joinedPlayers = response.data.user_ids;

                    this.multiplayer = response.data.multiplayer;

                    this.activeCard = JSON.parse(response.data.data).activeCard;

                    this.focusCard = JSON.parse(response.data.data).focusCard;

                    this.commercialHarborOpponents = JSON.parse(response.data.data).commercialHarborOpponents;

                    this.waitingForPlayers = JSON.parse(response.data.data).waitingForPlayers;

                    if(JSON.parse(response.data.data).rejectedTradeTypes != null) {
                       this.rejectedTradeTypes = JSON.parse(response.data.data).rejectedTradeTypes;
                    }

                    if(JSON.parse(response.data.data).pickedAqueductCards != null) {
                       this.pickedAqueductCards = JSON.parse(response.data.data).pickedAqueductCards;
                    }

                    if(JSON.parse(response.data.data).aiTactic != null) {
                       this.aiTactic = JSON.parse(response.data.data).aiTactic;
                    }

                    // load hidden tiles
                    this.loadedMapData = JSON.parse(response.data.data).tiles;
                    if(this.loadedMapData.filter(tile => tile.hidden).length) {
                        this.tiles.forEach(tile => {
                            let target = this.loadedMapData.find(loadedTile => loadedTile.key == tile.key);
                            tile.hidden = target.hidden;
                            tile.type = target.type;
                            tile.number = target.number;
                        });
                        // await this.generateLoadedMap();
                    }

                    let addedPlayers = JSON.parse(response.data.game_config).players;

                    this.addedPlayers = [];

                    this.addedPlayers = addedPlayers;

                    if(playerColor != null) {
                        this.addedPlayers = addedPlayers.filter(addedPlayer => addedPlayer.color == playerColor);
                    }

                    this.generatePlayers(this.addedPlayers);

                    this.dices = JSON.parse(response.data.data).dices;


                    // fix here
                    let tempPlayers = [];

                    response.data.players.forEach(item => {
                        tempPlayers.push(JSON.parse(item));
                    })

                    this.players = [];

                    tempPlayers.forEach(item => {
                        this.players.push(JSON.parse(JSON.stringify(item)));
                    })

                    this.progressCardsCloth = JSON.parse(response.data.data).progressCardsCloth;
                    this.progressCardsCoin = JSON.parse(response.data.data).progressCardsCoin;
                    this.progressCardsPaper = JSON.parse(response.data.data).progressCardsPaper;

                    this.opponents = [...this.players];

                    let activePlayerColor = JSON.parse(response.data.data).activePlayer.color;
                    this.activePlayer = this.players.find(player => player.color == activePlayerColor);

                    this.houses = JSON.parse(response.data.data).houses;

                    this.houses.forEach(house => {
                        house.tile = this.tiles.find(tile => tile.key == house.tile.key);
                        house.player = this.selectPlayer(house.player.color);
                    })

                    this.cities = JSON.parse(response.data.data).cities;

                    this.cities.forEach(city => {
                        city.tile = this.tiles.find(tile => tile.key == city.tile.key);
                        city.player = this.selectPlayer(city.player.color);
                    })

                    this.roads = JSON.parse(response.data.data).roads;
                    this.roads.forEach(road => {
                        road.tile = this.tiles.find(tile => tile.key == road.tile.key);
                        road.player = this.selectPlayer(road.player.color);
                    })

                    this.knights = JSON.parse(response.data.data).knights;
                    this.knights.forEach(knight => {
                        knight.tile = this.tiles.find(tile => tile.key == knight.tile.key);
                        knight.player = this.selectPlayer(knight.player.color);
                    })

                    this.progressCardsBasic = JSON.parse(response.data.data).progressCardsBasic;
                    if(JSON.parse(response.data.data).diceTracker) {
                        this.diceTracker = JSON.parse(response.data.data).diceTracker;
                    }

                    this.boatPosition = JSON.parse(response.data.data).boatPosition;
                    if(this.boatPosition >= 7) this.boatPosition = 0;
                    // this.boatPosition = response.data.boatPosition[0];

                    this.firstBoatRound = JSON.parse(response.data.data).firstBoatRound;

                    this.playToPoints = JSON.parse(response.data.data).playToPoints;

                    let robber = JSON.parse(response.data.data).robber;
                    if(robber.tile) {
                        this.robber.tile = this.tiles.find(tile => tile.key == robber.tile.key);
                    }

                    let pirate = JSON.parse(response.data.data).pirate;
                    if(pirate.tile) {
                        this.pirate.tile = this.tiles.find(tile => tile.key == pirate.tile.key);
                    }

                    this.focusType = JSON.parse(response.data.data).focusType;
                    this.focusMode = JSON.parse(response.data.data).focusMode;
                    this.focusPiece = JSON.parse(response.data.data).focusPiece;

                    this.longestRoad = JSON.parse(response.data.data).longestRoad;
                    if(this.longestRoad.length) {
                        this.longestRoad.forEach(obj => {
                            obj.player = this.selectPlayer(obj.player.color);
                            obj.tile = this.tiles.find(tile => tile.key == obj.key.slice(0, -1));
                        });
                    }

                    let largestArmy = JSON.parse(response.data.data).largestArmy;
                    if(largestArmy && largestArmy.player) {
                        this.largestArmy.player = this.selectPlayer(largestArmy.player.color);
                    }

                    // merchant issue
                    let merchant = JSON.parse(response.data.data).merchant;
                    if(merchant.tile) {
                        this.merchant.tile = this.tiles.find(tile => tile.key == merchant.tile.key);

                        if(merchant.player) {
                            this.merchant.player = this.selectPlayer(merchant.player.color);
                            this.moveMerchant(this.merchant.tile, this.merchant.player, false);
                        } else {
                            this.moveMerchant(this.merchant.tile, null, false);
                        }
                    }

                    document.title =  this.playToPoints + ' points - ' + response.data.map_name + ' - Gotland';

                    this.updateTradeCosts();

                    this.gamePhase = JSON.parse(response.data.data).gamePhase;

                    this.reverseBuildOrder = JSON.parse(response.data.data).reverseBuildOrder;

                    if(this.userData != null) {
                        let target = addedPlayers.find(player => player.user_id == this.userData.id);
                        this.becomePlayer(target.color);
                    }

                    // if inventor
                    let loadedTiles = JSON.parse(response.data.data).tiles;

                    let inventorTiles = JSON.parse(response.data.data).inventorTiles;

                    if(inventorTiles.length) {
                        inventorTiles.forEach(inventorTile => {
                            let target = this.tiles.find(tile => tile.key == inventorTile.key);

                            if(! this.inventorTiles.includes(target)) {
                                this.inventorTiles.push(target);
                            }
                        });
                    } else {
                        this.inventorTiles = [];
                    }
                    // if(inventorTiles.length) {
                    //     inventorTiles.forEach(inventorTile => {
                    //         if(this.tiles.find(tile => tile.key == inventorTile.key)) {
                    //             this.inventorTiles.push(inventorTile);
                    //         }
                    //     });
                    // }

                    this.tiles.forEach(tile => {
                        let target = loadedTiles.find(loadedTile => loadedTile.key == tile.key);

                        tile.number = target.number;
                    });

                    setTimeout(() => {
                        this.players.forEach(player => {
                            player.showOutgoingHandCards = false;
                        });
                    }, 100);

                    setTimeout(() => {
                        this.players.forEach(player => {
                            player.showIncomingHandCards = false;
                        });
                    }, 1000);

                    // commercial harbor fix here?
                    if(this.focusMode) {
                        if(! [
                            'throw away cards',
                            'select aqueduct resource',
                            'select a progress card',
                            'select city to destroy',
                            'give 2 hand cards',
                            'commercial harbor select commodity',
                            'move chased knight',
                            'throw away a progress card',
                            'select goldmine resources'
                        ].includes(this.focusType)) {
                            let focus = true;

                            if(this.multiplayer) {
                                // activePlayer is ai
                                if(this.activePlayer.ai) {
                                    let filteredPlayers = this.players
                                        .filter(player => player.color == this.activePlayer.color || player.ai == 0);

                                    let current = filteredPlayers.indexOf(this.activePlayer);
                                    let previous = current -= 1;
                                    if(previous < 0) previous += filteredPlayers.length;
                                    let previousPlayer = filteredPlayers[previous];

                                    // the player trying to save for ai should be the player just before the ai
                                    if(previousPlayer.color != this.bePlayer.color) focus = false;
                                }
                            }

                            if(focus) {
                                this.focusModeOn(this.focusType, this.activePlayer);
                            }
                        } else {

                            resetGoldmineChoises = false;

                            // ai discard half hand
                            let aiOpponents = this.players.filter(player => player.ai);

                            if('throw away cards' == this.focusType) {
                                aiOpponents.forEach(aiOpponent => {
                                    if(aiOpponent.waitingForMe) {
                                        this.aiDiscardHalfHand(aiOpponent);
                                    }
                                })
                            }

                            // not sure about this
                            if('select a progress card' == this.focusType) {
                                aiOpponents.forEach(aiOpponent => {
                                    if(aiOpponent.waitingForMe) {
                                        setTimeout(() => {
                                            // select cloth at end of game if no merchant in hand
                                            if(this.lateGame && !this.aiHasProgressCard('merchant', player)) {
                                                if(this.multiplayer) {
                                                    this.givePlayerProgressCard2(player, 'cloth');
                                                } else {
                                                    this.givePlayerProgressCard(player, 'cloth');
                                                }
                                            } else {
                                                if(this.multiplayer) {
                                                    this.givePlayerProgressCard2(player, 'paper');
                                                } else {
                                                    this.givePlayerProgressCard(player, 'paper');
                                                }
                                            }
                                            this.focusModeOff(player);
                                        }, this.randomNumber(0, 750));
                                    }
                                })
                            }

                            if(this.bePlayer.waitingForMe) {
                                if(this.logToConsole) console.log('player included in watingFor, so focusModeOn!')
                                this.focusModeOn(this.focusType, this.bePlayer);

                                if(this.focusType == 'throw away cards' && this.bePlayerLocalStorage != null) {
                                    // this.bePlayer.handCards = this.bePlayerLocalStorage.handCards;
                                    this.bePlayerLocalStorage.handCards.forEach(cardObj => {
                                        this.bePlayer.handCards.find(handObj => handObj.type == cardObj.type).selected = cardObj.selected;
                                    });
                                }

                                console.log('a');
                                if(this.focusType == 'select goldmine resources' && this.bePlayerLocalStorage != null) {

                                    resetGoldmineChoises = false;

                                    // gold fix
                                    this.bePlayerLocalStorage.goldmineChoises.forEach(cardObj => {
                                        if(this.bePlayerLocalStorage.goldmineAmount > 0) {
                                            this.bePlayer.goldmineChoises.find(goldObj => goldObj.type == cardObj.type).amount = cardObj.amount;
                                            this.bePlayer.goldmineAmount = this.bePlayerLocalStorage.goldmineAmount;
                                        }
                                    });
                                }
                            }

                            // if(this.focusType == 'throw away a progress card') {
                            //     if(this.bePlayer.progressCards.length > 4 && this.bePlayer != this.activePlayer) {
                            //         this.focusModeOn(this.focusType, this.activePlayer);
                            //     }
                            // }
                        }
                    }

                    if(response.data.completed_at != null) {
                        this.showModal('scoreboard', this.bePlayer);
                        this.focusModeOn('game over', this.bePlayer);
                    }

                    // let player move robber
                    if(this.activePlayer.color == this.bePlayer.color) {
                        if(['move robber', 'move robber only', 'move robber bishop'].includes(this.focusType)) {
                            this.focusModeOn(this.focusType, this.activePlayer);
                        }
                    }

                    this.updateMetropolisTopLevel();
                    this.updatePlayersPoints();

                    if(this.dicesRolling) {
                        this.showCounterOfferModal = false;
                    }

                    this.loadGameDataCompleted = true;
                }).catch((error) => {
                    if(this.logToConsole) console.log(error);
                })

                if(resetGoldmineChoises == true) {
                    this.players.forEach(player => {
                        player.goldmineChoises.forEach(obj => {
                            obj.amount = 0;
                        });
                    });
                }
            },
            async loadGame(id) {

                let resetGoldmineChoises = true;

                this.aiNames = ['Otis', 'Mio', 'Oliver', 'Eevee', 'Iimu', 'Jasu'];
                this.availableColors = ['red', 'blue', 'white', 'orange', 'green', 'brown', 'pink'];
                // console.log('loading...');

                this.gamePhase = null;

                if(this.players.length) {
                    this.players.forEach(player => {
                        this.focusModeOff(player);
                    })
                }
                this.players = [];

                await axios.get('/v1/games/' + id)
                .then(async (response) => {

                    this.joinedPlayers = response.data.user_ids;

                    this.multiplayer = response.data.multiplayer;

                    // this.commercialHarborOpponents = response.data.commercialHarborOpponents;

                    this.activeCard = JSON.parse(response.data.data).activeCard;

                    this.focusCard = JSON.parse(response.data.data).focusCard;

                    this.commercialHarborOpponents = JSON.parse(response.data.data).commercialHarborOpponents;

                    this.waitingForPlayers = JSON.parse(response.data.data).waitingForPlayers;

                    if(JSON.parse(response.data.data).rejectedTradeTypes != null) {
                       this.rejectedTradeTypes = JSON.parse(response.data.data).rejectedTradeTypes;
                    }

                    if(JSON.parse(response.data.data).pickedAqueductCards != null) {
                       this.pickedAqueductCards = JSON.parse(response.data.data).pickedAqueductCards;
                    }

                    if(JSON.parse(response.data.data).aiTactic != null) {
                       this.aiTactic = JSON.parse(response.data.data).aiTactic;
                    }

                    // this.loadedMapData = JSON.parse(response.data.stored_map);
                    this.loadedMapData = JSON.parse(response.data.data).tiles;

                    await this.generateLoadedMap();


                    // let dices = JSON.parse(response.data.data).dices;

                    // if(dices.whiteDiceRoll != null && dices.redDiceRoll != null) {
                    //     this.rollDices(100, dices.whiteDiceRoll, dices.redDiceRoll, dices.boatDiceRoll.type);
                    // } else {
                    // }

                    this.dices = JSON.parse(response.data.data).dices;

                    if(this.dices.whiteDiceRolling == true) this.dicesRolling = true;

                    // setTimeout(() => {
                    //     this.dices.whiteDiceRoll = dices.whiteDiceRoll;
                    //     this.dices.whiteDiceRolling = dices.whiteDiceRolling;
                    //     setTimeout(() => {
                    //         this.dices.redDiceRoll = dices.redDiceRoll;
                    //         this.dices.redDiceRolling = dices.redDiceRolling;
                    //         setTimeout(() => {
                    //             this.dices = dices;
                    //         }, 600);
                    //     }, 600);
                    // }, 600);

                    // this.dices.whiteDiceRoll = JSON.parse(response.data.data).dices.whiteDiceRoll;
                    // this.dices.redDiceRoll = JSON.parse(response.data.data).dices.redDiceRoll;
                    // this.dices.boatDiceRoll = JSON.parse(response.data.data).dices.boatDiceRoll;
                    // boatDicen = JSON.parse(response.data.data).dices.boatDiceRoll;

                    let playerName = null;
                    let playerColor = null;

                    let aiOpponents = JSON.parse(response.data.game_config).aiOpponents;
                    let addedPlayers = JSON.parse(response.data.game_config).players;

                    this.seafaresExpansion = JSON.parse(response.data.game_config).seafaresExpansion;

                    this.addedPlayers = addedPlayers;

                    // this.addedPlayers.push({ color: playerColor, name: playerName, ai: this.playerAi });

                    this.availableColors = this.availableColors.filter(color => color != playerColor);
                    this.aiNames = this.aiNames.filter(name => name != playerName);

                    if(this.userData == null) {
                        playerName = addedPlayers[0].name;
                        playerColor = addedPlayers[0].color;
                    } else {
                        let target = addedPlayers.find(player => player.user_id == this.userData.id);

                        if(target) {
                            playerName = target.name;
                            playerColor = target.color;
                        } else {
                            playerName = addedPlayers[0].name;
                            playerColor = addedPlayers[0].color;
                        }
                    }

                    // for(let i = 0; i < aiOpponents; i++) {
                    //     this.addedPlayers.push({ color: this.availableColors[0], name: this.aiNames[0], ai: 1});
                    //     this.availableColors.shift();
                    //     this.aiNames.shift();
                    // }


                    // this.tiles = JSON.parse(response.data.data).tiles;
                    // console.log(response.data.stored_map)

                    this.gamePhase = JSON.parse(response.data.data).gamePhase;

                    this.generatePlayers(addedPlayers);

                    // this.players = JSON.parse(response.data.data).players;

                    let tempPlayers = [];

                    response.data.players.forEach(item => {
                        tempPlayers.push(JSON.parse(item));
                    })

                    this.players = [];

                    tempPlayers.forEach(item => {
                        this.players.push(JSON.parse(JSON.stringify(item)));
                    })



                    // this.players = this.tempPlayers;

                    // console.log(this.players);

                    // console.log(this.players[0])
                    // console.log(this.players[0].color)
                    // console.log(this.players[1].color)
                    // console.log('woah')


                    // this.progressCardsCloth = JSON.parse(response.data.progressCardsCloth);
                    // this.progressCardsCoin = JSON.parse(response.data.progressCardsCoin);
                    // this.progressCardsPaper = JSON.parse(response.data.progressCardsPaper);
                    this.progressCardsCloth = JSON.parse(response.data.data).progressCardsCloth;
                    this.progressCardsCoin = JSON.parse(response.data.data).progressCardsCoin;
                    this.progressCardsPaper = JSON.parse(response.data.data).progressCardsPaper;

                    this.opponents = [...this.players];

                    this.startingPlayer = playerColor;

                    let activePlayerColor = JSON.parse(response.data.data).activePlayer.color;
                    this.activePlayer = this.players.find(player => player.color == activePlayerColor);

                    let bePlayerColor = JSON.parse(response.data.data).bePlayer.color;
                    this.bePlayer = this.players.find(player => player.color == bePlayerColor);

                    // this.autopilot = this.players.find(player => player.color == this.bePlayer.color).ai;

                    // real player but not host
                    if(this.userData != null) this.becomePlayer(playerColor);

                    this.houses = JSON.parse(response.data.data).houses;

                    this.houses.forEach(house => {
                        // house.tile = this.tiles.find(tile => tile.row == house.tile.row && tile.position == house.tile.position);
                        house.tile = this.tiles.find(tile => tile.key == house.tile.key);
                        house.player = this.selectPlayer(house.player.color);
                    })

                    this.cities = JSON.parse(response.data.data).cities;

                    this.cities.forEach(city => {
                        city.tile = this.tiles.find(tile => tile.key == city.tile.key);
                        city.player = this.selectPlayer(city.player.color);
                    })

                    this.roads = JSON.parse(response.data.data).roads;
                    this.roads.forEach(road => {
                        road.tile = this.tiles.find(tile => tile.key == road.tile.key);
                        road.player = this.selectPlayer(road.player.color);
                    })

                    this.knights = JSON.parse(response.data.data).knights;
                    this.knights.forEach(knight => {
                        knight.tile = this.tiles.find(tile => tile.key == knight.tile.key);
                        knight.player = this.selectPlayer(knight.player.color);
                    })

                    if(JSON.parse(response.data.data).diceTracker) {
                        this.diceTracker = JSON.parse(response.data.data).diceTracker;
                    }

                    this.boatPosition = JSON.parse(response.data.data).boatPosition;
                    if(this.boatPosition >= 7) this.boatPosition = 0;
                    // this.boatPosition = response.data.boatPosition[0];

                    this.firstBoatRound = JSON.parse(response.data.data).firstBoatRound;
                    this.playToPoints = JSON.parse(response.data.data).playToPoints;

                    let robber = JSON.parse(response.data.data).robber;
                    if(robber.tile) {
                        this.robber.tile = this.tiles.find(tile => tile.key == robber.tile.key);
                    }

                    let pirate = JSON.parse(response.data.data).pirate;
                    if(pirate.tile) {
                        this.pirate.tile = this.tiles.find(tile => tile.key == pirate.tile.key);
                    }

                    let merchant = JSON.parse(response.data.data).merchant;
                    if(merchant.tile) {
                        this.merchant.tile = this.tiles.find(tile => tile.key == merchant.tile.key);
                        if(merchant.player) {
                            this.merchant.player = this.selectPlayer(merchant.player.color);
                            this.moveMerchant(this.merchant.tile, this.merchant.player, false);
                        } else {
                            this.moveMerchant(this.merchant.tile, null, false);
                        }
                    }

                    // this.longestRoad = JSON.parse(response.data.data).longestRoad;
                    this.focusType = JSON.parse(response.data.data).focusType;
                    this.focusMode = JSON.parse(response.data.data).focusMode;
                    this.focusPiece = JSON.parse(response.data.data).focusPiece;

                    // this.forceUpdate++;
                    // this.$forceUpdate();

                    // this.players.forEach(player => {
                    //     this.focusModeOff(player);
                    // })

                    this.longestRoad = JSON.parse(response.data.data).longestRoad;
                    if(this.longestRoad.length) {
                        this.longestRoad.forEach(obj => {
                            obj.player = this.selectPlayer(obj.player.color);
                            obj.tile = this.tiles.find(tile => tile.key == obj.key.slice(0, -1));
                        });
                    }

                    document.title =  this.playToPoints + ' points - ' + response.data.map_name + ' - Gotland';

                    this.updateTradeCosts();

                    this.gamePhase = JSON.parse(response.data.data).gamePhase;

                    this.reverseBuildOrder = JSON.parse(response.data.data).reverseBuildOrder;

                    setTimeout(() => {
                        this.players.forEach(player => {
                            player.showOutgoingHandCards = false;
                        });
                    }, 700);

                    setTimeout(() => {
                        this.players.forEach(player => {
                            if(player.ai) {
                                player.showIncomingHandCards = false;
                            }
                        });
                    }, 1000);

                    if(this.gamePhase == 'regular') {

                        if(this.focusMode) {
                            if(! ['throw away cards', 'select aqueduct resource', 'select a progress card', 'select city to destroy', 'throw away a progress card'].includes(this.focusType)) {
                                let focus = true;

                                if(this.multiplayer) {
                                    // activePlayer is ai
                                    if(this.activePlayer.ai) {
                                        let filteredPlayers = this.players
                                            .filter(player => player.color == this.activePlayer.color || player.ai == 0);

                                        let current = filteredPlayers.indexOf(this.activePlayer);
                                        let previous = current -= 1;
                                        if(previous < 0) previous += filteredPlayers.length;
                                        let previousPlayer = filteredPlayers[previous];

                                        // the player trying to save for ai should be the player just before the ai
                                        if(previousPlayer.color != this.bePlayer.color) focus = false;
                                    }
                                }

                                if(focus) {
                                    this.focusModeOn(this.focusType, this.activePlayer);
                                }
                            } else {
                                // ai discard half hand
                                let aiOpponents = this.players.filter(player => player.ai);

                                    if('throw away cards' == this.focusType) {
                                        aiOpponents.forEach(aiOpponent => {
                                            if(aiOpponent.waitingForMe) {
                                                this.aiDiscardHalfHand(aiOpponent);
                                            }
                                        })
                                    }


                                    // not sure about this
                                    if('select a progress card' == this.focusType) {
                                        aiOpponents.forEach(aiOpponent => {
                                            if(aiOpponent.waitingForMe) {
                                                setTimeout(() => {
                                                    // select cloth at end of game if no merchant in hand
                                                    if(this.lateGame && !this.aiHasProgressCard('merchant', player)) {
                                                        if(this.multiplayer) {
                                                            this.givePlayerProgressCard2(player, 'cloth');
                                                        } else {
                                                            this.givePlayerProgressCard(player, 'cloth');
                                                        }
                                                    } else {
                                                        if(this.multiplayer) {
                                                            this.givePlayerProgressCard2(player, 'paper');
                                                        } else {
                                                            this.givePlayerProgressCard(player, 'paper');
                                                        }
                                                    }
                                                    this.focusModeOff(player);
                                                }, this.randomNumber(0, 750));
                                            }
                                        })
                                    }

                                if(this.bePlayer.waitingForMe) {
                                    if(this.logToConsole) console.log('player included in watingFor, so focusModeOn!')
                                    this.focusModeOn(this.focusType, this.bePlayer);
                                }

                                // if(this.focusType == 'throw away a progress card') {
                                //     if(this.bePlayer.progressCards.length > 4 && this.bePlayer != this.activePlayer) {
                                //         this.focusModeOn(this.focusType, this.activePlayer);
                                //     }
                                // }
                            }
                        }

                        // herre
                        setTimeout(async () => {
                            if(this.activePlayer.ai) {
                                if(this.isHumanBeforeAi()) {
                                    await this.aiPlayTurn(true);
                                }
                            }
                        }, 250);
                    } else if(this.gamePhase == 'decide starting player') {
                        this.players.forEach(player => {
                            if(player.ai) {
                                setTimeout(() => {
                                    this.clickOwnDices(player);
                                }, this.randomNumber(0, this.aiActionSpeed));
                            }
                        })
                    } else if(this.gamePhase == 'initial build') {

                        if(this.logToConsole) console.log('this.activePlayer.highlightedSpots');
                        if(this.logToConsole) console.log(this.activePlayer.highlightedSpots);

                        this.houses
                            .filter(house => house.player == this.activePlayer)
                            .forEach(house => {
                                house.buildAnimation = false;
                        });

                        this.cities
                            .filter(city => city.player == this.activePlayer)
                            .forEach(city => {
                                city.buildAnimation = false;
                        });

                        // highlight active players spots
                        this.activePlayer.highlightedSpots.forEach(key => {
                            this.highlightSpot(key, this.activePlayer);

                            // highlight active players roadSpots
                            let spot = this.spotByKey(key);

                            this.spotRoadsAll(spot.tile, spot.position).forEach(roadSpot => {
                                if(!roadSpot) return;

                                if(!this.seafaresExpansion) {
                                    // can't build road in water
                                    if(!this.roadTiles(roadSpot.tile, roadSpot.roadSpot.position).find(roadTile => roadTile.group == 'land')) return;
                                }
                                this.highlightedRoadSpots.push(roadSpot.roadSpot.key);
                            });
                        });

                        this.focusModeOn(this.focusType, this.activePlayer);
                    }

                    if(this.dicesRolling) {
                        this.showCounterOfferModal = false;
                    }

                }).catch((error) => {
                    if(this.logToConsole) console.log(error);
                })

                if(resetGoldmineChoises == true) {
                    this.players.forEach(player => {
                        player.goldmineChoises.forEach(obj => {
                            obj.amount = 0;
                        });
                    });
                }

                // let player move robber
                if(this.activePlayer.color == this.bePlayer.color) {
                    if(['move robber', 'move robber only', 'move robber bishop'].includes(this.focusType)) {
                        this.focusModeOn(this.focusType, this.activePlayer);
                    }
                }

                this.updateMetropolisTopLevel();
                this.updatePlayersPoints();

                // this.scanLongestRoad();

            },
            async updatePlayerOnly(player) {

                let index = this.players.indexOf(player);

                axios.post('/v1/update-player-only/' + this.$route.params.gameId, {
                    data: {
                        // dices: this.dices,
                        player: player,
                        index: index
                    },
                })
                .then((response) => {
                    // this.savingGame = false;
                }).catch((error) => {
                    if(this.logToConsole) console.log(error);
                })

                console.log('tezt');
            },
            saveHand(player = this.bePlayer) {
                return;

                let waitingForSomeone = false;
                this.players.forEach(item => {
                    if(item.waitingForMe == true) waitingForSomeone = true;
                });

                if(waitingForSomeone == false) {
                    this.focusMode = false;
                    this.focusType = null;
                }

                this.saveGame(false, true, false, player, true);
            },
            savePlayer(player = this.bePlayer) {
                this.saveGame(false, true, false, player);
            },
            gameReady() {
                axios.post(`/v1/game-ready/${this.$route.params.gameId}`)
                    .then((response) => {

                    }).catch((error) => {
                        console.log(error);
                    })
            },
            async saveGame(claimVictory = false, forceSave = false, saveAllPlayers = true, savePlayer = this.bePlayer, saveHand = false) {
                // await this.waitForScanLongest();

                this.players.forEach(player => {
                    player.idleTimer = 0;
                });

                if(this.multiplayer) {
                    if(this.players[0].name == 'You') return;
                }

                // if(saveAllPlayers == false) {
                //     console.log('wanna save myself only');
                // } else {
                //     console.log('wanna save all')
                // }

                await this.waitForLoadGameData();

                // multiplayer game
                if(this.multiplayer && this.gamePhase == 'initial build') {
                    // activePlayer is ai
                    if(this.activePlayer.ai) {

                        let filteredPlayers = this.players
                            .filter(player => player.color == this.activePlayer.color || player.ai == 0);

                        let current = filteredPlayers.indexOf(this.activePlayer);
                        let previous = current -= 1;
                        if(previous < 0) previous += filteredPlayers.length;
                        let previousPlayer = filteredPlayers[previous];

                        // console.log('previousPlayer')
                        // console.log(previousPlayer.ai)
                        // console.log(previousPlayer.color)
                        // console.log('*****')
                        // console.log(previousPlayer.color + ' ' + this.bePlayer.color)

                        // the player trying to save for ai should be the player just before the ai
                        if(previousPlayer.color != this.bePlayer.color) return;

                        // console.log('- - -')
                        // console.log('SAVING FOR: ' + this.activePlayer.color);
                        // console.log('BEPLAYER: ' + this.bePlayer.color);
                        // console.log('- - -')

                    }
                }

                // if(! this.bePlayer.ai) this.becomePlayer(this.bePlayer.color);

                if(this.joinedPlayers == null && forceSave == false) return;

                if(this.activePlayer.ai && this.gamePhase == 'initial build') return;

                if(this.logToConsole) console.log('saving! ' + this.activePlayer.color);

                let activePlayerColor = null;
                if(this.activePlayer) activePlayerColor = this.activePlayer.color;

                // not sure about this
                if(forceSave == false) {
                    if(this.activePlayer.ai && this.savingGame == true) return;
                }

                this.savingGame = true;

                // let playersWithKeys = [];

                // this.players.forEach(player => {
                //     playersWithKeys[player.color] = player;
                // })

                // console.log(playersWithKeys);

                axios.post('/v1/save-game/' + this.$route.params.gameId, {
                    saveAllPlayers: saveAllPlayers,
                    bePlayerColor: this.bePlayer.color,
                    savePlayerColor: savePlayer.color,
                    saveHand: saveHand,
                    winnerColor: activePlayerColor,
                    claimVictory: claimVictory,
                    players: this.players,
                    progressCardsCloth: this.progressCardsCloth,
                    progressCardsCoin: this.progressCardsCoin,
                    progressCardsPaper: this.progressCardsPaper,
                    boatPosition: this.boatPosition,
                    data: {
                        dices: this.dices,
                        gamePhase: this.gamePhase,
                        activePlayer: this.activePlayer,
                        bePlayer: this.bePlayer,
                        players: this.players,
                        houses: this.houses,
                        cities: this.cities,
                        roads: this.roads,
                        knights: this.knights,
                        tiles: this.tiles,
                        boatPosition: this.boatPosition,
                        firstBoatRound: this.firstBoatRound,
                        playToPoints: this.playToPoints,
                        robber: this.robber,
                        pirate: this.pirate,
                        merchant: this.merchant,
                        longestRoad: this.longestRoad,
                        focusType: this.focusType,
                        focusMode: this.focusMode,
                        focusPiece: this.focusPiece,
                        progressCardsCloth: this.progressCardsCloth,
                        progressCardsCoin: this.progressCardsCoin,
                        progressCardsPaper: this.progressCardsPaper,
                        diceTracker: this.diceTracker,
                        reverseBuildOrder: this.reverseBuildOrder,
                        waitingForPlayers: this.waitingForPlayers,
                        inventorTiles: this.inventorTiles,
                        focusCard: this.focusCard,
                        commercialHarborOpponents: this.commercialHarborOpponents,
                        activeCard: this.activeCard,
                        rejectedTradeTypes: this.rejectedTradeTypes,
                        pickedAqueductCards: this.pickedAqueductCards,
                        aiTactic: this.aiTactic,
                    },
                })
                .then((response) => {
                    this.savingGame = false;
                }).catch((error) => {
                    if(this.logToConsole) console.log(error);
                    if(this.multiplayer) return location.reload();
                })
            },
            toggleGuides() {
                this.players.forEach(player => {
                    player.roadLength = this.playersLongest(player).length;
                });

                this.showGuides = !this.showGuides;
            },
            moveBoat(direction) {

                if(direction == 'back') {
                    this.boatPosition--;
                } else {
                    this.boatPosition++;
                }
            },
            async aiPlayTurn(loadGame = false) {
                if(! this.activePlayer.ai) return;

                setTimeout(async () => {
                    if(loadGame == false) {
                        // always play alchemist when you have it?
                        if(this.firstBoatRound == true || this.playerHandCardsCount(this.activePlayer) >= 5 || this.lateGame) {
                            // try to use alchemist if it exists
                            if(!await this.aiPlayProgressCard('alchemist', this.getAlchemistCardNeed())) {
                                this.clickDices();
                            }
                        } else {
                            this.clickDices();
                        }

                        await this.waitForDiceRollEvents();

                    }

                    // this.saveGame();

                    await this.aiDoTurnActions();

                }, this.randomNumber(0, this.aiActionSpeed));

                await this.waitForAllAnimations();

            },
            async aiDoTurnActions(firstLoop = true) {
                if(! this.activePlayer.ai) return;

                if(firstLoop) {
                    this.timer = 0;
                    this.tradedOnce = false;

                    this.rejectedTradeTypes.forEach(obj => {
                        obj.rejected = 0;
                    });
                }

                this.aiChaseAwayRobber();
                await this.waitForAllAnimations();
                this.aiChaseAwayRobber();
                await this.waitForAllAnimations();
                this.aiChaseAwayPirate();
                await this.waitForAllAnimations();
                await this.aiPlayWedding();
                await this.waitForAllAnimations();
                await this.aiPlayWedding();
                await this.waitForAllAnimations();

                if(firstLoop == true) {
                    await this.aiMoveBoat();
                    await this.waitForAllAnimations();
                    await this.aiPlaySpy();
                    await this.waitForAllAnimations();
                    await this.aiPlaySpy();
                    await this.waitForAllAnimations();
                    await this.aiPlayMasterMerchant();
                    await this.aiPlayProgressCard('inventor');
                    await this.aiPlayResourceMonopoly();
                    await this.aiPlayTradeMonopoly();
                    await this.waitForAllAnimations();
                    await this.aiPlayResourceMonopoly(true);
                    await this.waitForAllAnimations();


                    if(!this.firstBoatRound) {
                        if(this.playerHandCardsCount(this.leader) > 0) await this.aiPlayProgressCard('bishop');
                    }

                    // play always, only on deadly boat on first boat round
                    if(!this.firstBoatRound || this.boat.includes('deadly')) {
                        if(this.aiHasProgressCard('intrigue')) {
                            await this.aiPlayProgressCard('intrigue');
                        }
                    }

                    if(this.aiHasProgressCard('deseter')) await this.aiPlayDeseter();
                    await this.waitForAllAnimations();
                    if(this.aiHasProgressCard('deseter')) await this.aiPlayDeseter();

                    if(this.firstBoatRound || this.activePlayerHandCardsCount >= 5 || this.activePlayer.progressCards.length >= 4) {

                        if(this.aiHasProgressCard('irrigation')) {
                            await this.aiPlayProgressCard('irrigation');
                            await this.aiPlayProgressCard('irrigation');
                        }
                        if(this.aiHasProgressCard('mining')) {
                            await this.aiPlayProgressCard('mining');
                            await this.aiPlayProgressCard('mining');
                        }
                        if(this.aiHasProgressCard('medecine') &&
                            this.houses.filter(house => house.player == this.activePlayer).length != 0 &&
                            this.activePlayer.pieces.cities != 0
                        ) {
                            await this.aiPlayProgressCard('medecine');
                            await this.waitForAllAnimations();
                        }

                        if(this.aiHasProgressCard('road building')) {
                            await this.aiPlayRoadBuilding();
                            await this.waitForAllAnimations();
                        }
                    }
                    await this.aiPlaySaboteur();
                }

                await this.waitForAllAnimations();

                let activeKnightsNeeded = this.cities.length - this.allActiveKnightsCount;

                // outside firstLoop, will use card if knight is just built
                if(!(this.firstBoatRound && activeKnightsNeeded > 0 && this.boat.includes('deadly') && this.playersWithLeastActiveKnights.find(obj => obj.player == this.activePlayer))) {
                    if(this.aiHasProgressCard('engineer')) {
                        this.aiPlayProgressCard('engineer');
                    }
                }
                setTimeout(async () => {
                    if(this.firstBoatRound == true) {
                    // if(this.firstBoatRound || true) {

                        if(firstLoop == true) {
                            await this.aiUpgradeAllCommodities();
                            await this.aiTradeBankForPaperToThird();
                            await this.waitForAllAnimations();
                            await this.aiUpgradeAllCommodities();
                            await this.waitForAllAnimations();
                        }

                        // if(!this.boat.includes('critical') || true) {
                        if(!this.boat.includes('critical')) {
                            // try to upgrade to city without trading
                            await this.aiBuildTwoRoadsAndHouse();
                            await this.waitForAllAnimations();
                            await this.aiBuildRoadAndHouse();
                            await this.waitForAllAnimations();

                            // try to build road + house without trading
                            await this.aiUpgradeToCity();
                            await this.waitForAllAnimations();
                        }

                        if(this.aiHasProgressCard('merchant')) {
                            await this.aiPlayMerchant();
                        } else {
                            await this.aiPlayMerchantFleet();
                        }

                        let leastActiveCount = 0;
                        // least knights active, how many?
                        if(this.playersWithLeastActiveKnights.length) {
                            leastActiveCount = this.playersWithLeastActiveKnights[0].activeKnights;
                        }

                        let activePlayerActiveKnights = this.knights
                            .filter(knight => knight.player == this.activePlayer && knight.active)
                            .reduce((activeKnightsTotal, knight) => activeKnightsTotal + knight.level, 0);

                        // player has least knights and there is not enough
                        // if player has 0 knights, always try to build/activate at least one even if there is enough
                        if(
                            (this.playersWithLeastActiveKnights.find(obj => obj.player == this.activePlayer) &&
                            activeKnightsNeeded > 0) ||
                            activePlayerActiveKnights == 0
                        ) {
                            if(await this.aiActivateStrongestKnight() == 'knight needed') {
                                if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount > 0) {
                                    if(await this.aiBuildKnight() == false) {
                                        await this.aiPlaySmith();
                                        if(await this.aiUpgradeKnight() == false) {
                                            if(this.boat.includes('deadly')) {
                                                if(await this.aiTradeBankForKnight(true) == 'full trade failed') {
                                                    await this.aiPlayCommercialHarbor();
                                                    await this.waitForAllAnimations();
                                                    await this.aiUpgradeAllCommodities();
                                                    await this.waitForAllAnimations();

                                                    await this.aiTradeAndUpgradeAllToLevel1();
                                                    await this.waitForAllAnimations();
                                                }
                                            } else {
                                                await this.aiTradeBankForKnight();
                                                await this.waitForAllAnimations();
                                            }
                                        }
                                    }
                                    await this.aiActivateStrongestKnight();
                                } else {
                                    await this.aiPlaySmith();
                                    if(await this.aiUpgradeKnight() == false) {
                                        if(await this.aiBuildKnight() == false) {
                                            if(this.boat.includes('deadly')) {
                                                if(await this.aiTradeBankForKnight(true) == 'full trade failed') {
                                                    await this.aiPlayCommercialHarbor();
                                                    await this.waitForAllAnimations();
                                                    await this.aiUpgradeAllCommodities();
                                                    await this.waitForAllAnimations();

                                                    await this.aiTradeAndUpgradeAllToLevel1();
                                                    await this.waitForAllAnimations();
                                                }
                                            } else {
                                                await this.aiTradeBankForKnight();
                                                await this.waitForAllAnimations();
                                            }
                                        }
                                        await this.aiActivateStrongestKnight();
                                    }
                                }
                            } else if(['knight needed'].includes(await this.aiActivateStrongestKnight())) {
                                await this.aiActivateStrongestKnight();
                            } else if(['failed to activate'].includes(await this.aiActivateStrongestKnight())) {
                                if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0) await this.aiTradeWithBank('grain');
                                await this.waitForAllAnimations();
                                await this.aiActivateStrongestKnight();
                            } else {
                                // console.log(this.activePlayer.color + ' knight exists, no need to build now');
                                if(await this.aiActivateStrongestKnight() == 'failed to activate') {
                                    if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0) await this.aiTradeWithBank('grain');
                                    await this.waitForAllAnimations();
                                    await this.aiActivateStrongestKnight();
                                }
                            }

                        // player doesn't have least knights, but even if each player with least knights activates one each there is not enough
                        } else if(
                            !this.playersWithLeastActiveKnights.find(obj => obj.player == this.activePlayer) &&
                            activeKnightsNeeded > this.playersWithLeastActiveKnights.length
                        ) {
                            if(leastActiveCount+1 == activePlayerActiveKnights) {
                                if(await this.aiActivateStrongestKnight() == 'knight needed') {
                                    if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount > 0) {
                                        if(await this.aiBuildKnight() == false) {
                                            await this.aiPlaySmith();
                                            if(await this.aiUpgradeKnight() == false) {
                                                if(this.boat.includes('deadly')) {
                                                    if(await this.aiTradeBankForKnight(true) == 'full trade failed') {
                                                        await this.aiPlayCommercialHarbor();
                                                        await this.waitForAllAnimations();
                                                        await this.aiUpgradeAllCommodities();
                                                        await this.waitForAllAnimations();

                                                        await this.aiTradeAndUpgradeAllToLevel1();
                                                        await this.waitForAllAnimations();
                                                    }
                                                } else {
                                                    await this.aiTradeBankForKnight();
                                                    await this.waitForAllAnimations();
                                                }
                                            }
                                        }
                                        if(!this.aiHasProgressCard('warlord')) {
                                            if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0) await this.aiTradeWithBank('grain');
                                            await this.waitForAllAnimations();
                                            await this.aiActivateStrongestKnight();
                                            await this.waitForAllAnimations();
                                        }
                                    } else {
                                        await this.aiPlaySmith();
                                        if(await this.aiUpgradeKnight() == false) {
                                            if(await this.aiBuildKnight() == false) {
                                                if(this.boat.includes('deadly')) {
                                                    if(await this.aiTradeBankForKnight(true) == 'full trade failed') {
                                                        await this.aiPlayCommercialHarbor();
                                                        await this.waitForAllAnimations();
                                                        await this.aiUpgradeAllCommodities();
                                                        await this.waitForAllAnimations();

                                                        await this.aiTradeAndUpgradeAllToLevel1();
                                                        await this.waitForAllAnimations();
                                                    }
                                                } else {
                                                    await this.aiTradeBankForKnight();
                                                    await this.waitForAllAnimations();
                                                }
                                            }
                                            if(await this.aiActivateStrongestKnight() == 'failed to activate') {
                                                if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0) await this.aiTradeWithBank('grain');
                                                await this.waitForAllAnimations();
                                                await this.aiActivateStrongestKnight();
                                                await this.waitForAllAnimations();
                                            }
                                        }
                                    }
                                } else if(this.aiActivateStrongestKnight() == 'failed to activate') {
                                    if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0) await this.aiTradeWithBank('grain');
                                    await this.waitForAllAnimations();
                                    await this.aiActivateStrongestKnight();
                                    await this.waitForAllAnimations();
                                } else {
                                    // console.log(this.activePlayer.color + ' knight activated, no need to build now');
                                }
                            } else {
                                // console.log(this.activePlayer.color + ' doesnt have least and has enough of a knight lead :)');
                            }
                        // no need for player to build/activate more knights now
                        } else {
                            // console.log(this.activePlayer.color + ' no need to build/activate knight!')

                            if(this.aiHasProgressCard('medecine') &&
                                this.houses.filter(house => house.player == this.activePlayer).length != 0 &&
                                this.activePlayer.pieces.cities != 0
                            ) {
                                await this.aiTradeBankForCheapCity(true);
                                await this.waitForAllAnimations();
                                if(this.aiHasProgressCard('medecine')) {
                                    await this.aiPlayProgressCard('medecine');
                                    await this.waitForAllAnimations();
                                }
                            }

                            // try to upgrade to city without trading
                            await this.aiUpgradeToCity();
                            await this.waitForAllAnimations();
                            // try to build house without trading
                            // try to build road + house without trading
                            await this.aiBuildTwoRoadsAndHouse();
                            await this.waitForAllAnimations();
                            await this.aiBuildRoadAndHouse();
                            await this.waitForAllAnimations();
                            await this.aiBuildHouse();
                            await this.waitForAllAnimations();

                            await this.aiTradeAndUpgradeAllToLevel1();

                            if(await this.aiEnableCity() == false) {
                                await this.aiTradeBankForEnableCity(true);
                                await this.waitForAllAnimations();
                                await this.aiEnableCity();
                                await this.waitForAllAnimations();
                            }

                            // keep this one?
                            if(this.aiHasAvailableHouseSpot() == false) await this.aiBuildRoad();

                            // second round of ai actions
                            if(firstLoop == false) {

                                if(this.aiHasAvailableHouseSpot() == false) await this.aiBuildRoad();

                                if(this.activePlayerTooManyHandCards) await this.aiBuildCitywall();

                                // build an extra knight without activating it
                                if(this.activePlayerTooManyHandCards) await this.aiBuildKnight();

                                if(this.activePlayerTooManyHandCards) await this.aiUpgradeToCity();

                                if(this.activePlayerTooManyHandCards) {
                                    if(this.aiHasAvailableHouseSpot() == true) {
                                        await this.aiTradeBankForHouse();
                                        await this.waitForAllAnimations();
                                        await this.aiBuildHouse();
                                    } else {
                                        await this.aiTradeBankForCity();
                                        await this.waitForAllAnimations();
                                        await this.aiUpgradeToCity();
                                    }
                                }

                                // ai trade towards road

                                // ai try to trade with opponents

                                await this.aiTradeAndUpgradeAllToLevel1();
                            }
                        }

                        // about to get blocked by another players road & next spot is available for house
                        if(this.aiAboutToGetBlocked(this.activePlayer)) await this.aiBuildRoad();

                        await this.waitForAllAnimations();

                        if(this.aiHasProgressCard('crane')) await this.aiPlayCrane();

                        // console.log('try to build city without trading*');
                        // console.log('try to build house & road without trading*');

                        // close knight to fuck up leaders game?
                        /*
                        // player has huge knight lead, could possibly close one to destroy someones city
                        if(leastActiveCount+2 < activePlayerActiveKnights) {
                            // if boatPosition == 6 and player with highest potential is the one with least knights,
                            // and leader turn is as far away from you as possible
                            // and activePlayer can afford to close one knight
                            if(this.logToConsole) console.log('has knightLead of 2 compared to player with least')
                        }
                        */

                        if(firstLoop == true) {
                            this.aiDoTurnActions(false);
                        } else {
                            // too many cards, no knight, build anyway instead of throwing away good cards
                            if(this.activePlayerTooManyHandCards) {
                                await this.waitForAllAnimations();
                                await this.aiBuildTwoRoadsAndHouse();
                                await this.waitForAllAnimations();
                                await this.aiBuildRoadAndHouse();
                                await this.waitForAllAnimations();
                                await this.aiBuildHouse();
                                await this.waitForAllAnimations();
                                await this.aiUpgradeToCity();
                            }

                            await this.waitForAllAnimations();
                            await this.aiPlayDiplomat();
                            await this.waitForAllAnimations();

                            if(this.boat.includes('deadly')) {
                                // no active knights, try to activate one
                                if(!this.knights.filter(knight => knight.player == this.activePlayer && knight.active).length) {
                                    await this.aiActivateStrongestKnight();
                                    await this.aiPlayCommercialHarbor();
                                    await this.waitForAllAnimations();
                                    await this.aiUpgradeAllCommodities();
                                    await this.waitForAllAnimations();
                                }

                                await this.aiUpgradeToCity();
                                await this.aiTradeAndUpgradeAllToLevel1();
                                await this.waitForAllAnimations();
                                await this.aiBuildTwoRoadsAndHouse();
                                await this.waitForAllAnimations();
                                await this.aiBuildRoadAndHouse();
                                await this.waitForAllAnimations();
                                await this.aiBuildHouse();
                                if(this.aiHasAvailableHouseSpot() == false) await this.aiBuildRoad();
                            }
                            // if 8 cards in hand and grain, activate manually?
                            if(this.aiHasProgressCard('warlord')) {
                                if(
                                    this.activePlayerHandCardsCount-1 == this.activePlayerAllowedHandCardsCount &&
                                    this.activePlayer.handCards.find(obj => obj.type == 'grain').amount > 0
                                ) {
                                    await this.aiActivateStrongestKnight();
                                    await this.waitForAllAnimations();
                                } else {
                                    await this.aiPlayProgressCard('warlord');
                                    await this.waitForAllAnimations();
                                }
                            }
                            await this.waitForAllAnimations();
                            // console.log('DONE! ' + this.timer.toFixed(1) + 's');
                            if(! this.activePlayer.ai) return;
                            this.endTurn();
                        }
                    // not first boat round
                    } else {
                        // player has city
                        if(this.cities.find(city => city.player == this.activePlayer)) {
                            await this.aiPlaySmith();

                            await this.aiPlayDiplomat();
                            await this.waitForAllAnimations();

                            if(this.activePlayer.progress.find(progress => this.activePlayer.fightFor.includes(progress.type) && progress.level == 4)) {
                                await this.aiPlayCommercialHarbor();
                                await this.waitForAllAnimations();

                                if(this.aiHasProgressCard('merchant')) {
                                    await this.aiPlayMerchant();
                                } else {
                                    await this.aiPlayMerchantFleet();
                                }

                                await this.aiTradeBankForMetropolis();
                                await this.waitForAllAnimations();
                                await this.aiUpgradeAllCommodities();
                                await this.waitForAllAnimations();
                            }

                            if(this.activePlayer.progress.find(progress => this.activePlayer.fightFor.includes(progress.type) && progress.level == 3) && this.activePlayer.points >= this.playToPoints-8) {
                                await this.aiPlayCommercialHarbor();
                                await this.waitForAllAnimations();

                                if(this.aiHasProgressCard('merchant')) {
                                    await this.aiPlayMerchant();
                                } else {
                                    await this.aiPlayMerchantFleet();
                                }

                                await this.aiTradeBankForMetropolis();
                                await this.waitForAllAnimations();
                                await this.aiUpgradeAllCommodities();
                                await this.waitForAllAnimations();
                            }

                            if(this.longestRoad[0]) {
                                if(this.longestRoad[0].player == this.leader || this.longestRoad[0].player.points >= this.playToPoints-4) {
                                    await this.aiTryToBreakLongestRoad();
                                    await this.waitForAllAnimations();
                                }
                            }


                            // about to get blocked, try to build house quickly
                            // maybe not if boat is deadly?
                            if(this.aiHouseSpotAboutToGetBlocked() == true) {
                                if(await this.aiBuildHouse() == false) {
                                    await this.aiTradeForHouse();
                                    await this.waitForAllAnimations();
                                    await this.aiTradeBankForHouse(true);
                                    await this.waitForAllAnimations();
                                    await this.aiBuildHouse()
                                    await this.waitForAllAnimations();
                                }
                            }

                            if(firstLoop == true) await this.aiUpgradeAllCommodities();
                            await this.waitForAllAnimations();

                            if(this.aiHasProgressCard('crane')) await this.aiPlayCrane();
                            await this.waitForAllAnimations();

                            if(this.leaderAll.points >= this.playToPoints-9) {
                                if(this.opponentWithMostActiveKnights && firstLoop == true && this.boat.includes('deadly')) {
                                    // leader about to get knight point/progress card
                                    if(this.opponentWithMostActiveKnights.player == this.leader) {
                                        // activePlayer has same amount of knights, try to upgrade/activate one
                                        if(
                                            this.opponentWithMostActiveKnights.activeKnightsCount == activePlayerActiveKnights ||
                                            this.opponentWithMostActiveKnights.activeKnightsCount-1 == activePlayerActiveKnights
                                        ) {
                                            await this.aiPlaySmith();
                                            await this.aiUpgradeKnight();
                                            await this.aiBuildKnight();
                                            await this.aiActivateStrongestKnight();
                                            await this.aiActivateStrongestKnight();
                                        }
                                    }
                                }
                            }


                            // change to critical again?
                            if(!this.boat.includes('deadly')) {
                                await this.aiBuildTwoRoadsAndHouse();
                                await this.waitForAllAnimations();

                                await this.aiTradeBankForHouseAndBoat(true);
                                await this.waitForAllAnimations();

                                await this.aiBuildBoatAndHouse();
                                await this.waitForAllAnimations();

                                if(this.aiGetBuildBoatAndHouseOptions().length && firstLoop == false) {
                                    if(!await this.aiBuildBoat()) {
                                        await this.aiTradeBankForBoat();
                                        await this.waitForAllAnimations();
                                        await this.aiBuildBoat();
                                        await this.waitForAllAnimations();
                                        await this.aiBuildHouse();
                                        await this.waitForAllAnimations();
                                    } else {
                                        await this.aiBuildHouse();
                                        await this.waitForAllAnimations();
                                    }
                                }

                                await this.aiBuildRoadAndHouse();
                                await this.waitForAllAnimations();

                                await this.aiBuildHouse();
                                await this.waitForAllAnimations();

                                await this.aiUpgradeToCity();
                                await this.waitForAllAnimations();

                                if(this.aiHasProgressCard('merchant')) {
                                    await this.aiPlayMerchant();
                                } else {
                                    await this.aiPlayMerchantFleet();
                                }

                                if(this.aiHasProgressCard('medecine') &&
                                    this.houses.filter(house => house.player == this.activePlayer).length != 0 &&
                                    this.activePlayer.pieces.cities != 0
                                ) {
                                    await this.aiTradeBankForCheapCity(true);
                                    await this.waitForAllAnimations();
                                    if(this.aiHasProgressCard('medecine')) {
                                        await this.aiPlayProgressCard('medecine');
                                        await this.waitForAllAnimations();
                                    }
                                }

                                if(this.aiHasAvailableHouseSpot() == true) {
                                    await this.aiTradeForHouse();
                                    await this.waitForAllAnimations();
                                    await this.aiTradeBankForHouse(true);
                                    await this.waitForAllAnimations();
                                    await this.aiBuildHouse();
                                    await this.waitForAllAnimations();
                                } else {
                                    await this.aiTradeBankForHouseAndRoad(true)
                                    await this.waitForAllAnimations();
                                    await this.aiBuildRoadAndHouse();
                                    await this.waitForAllAnimations();

                                    await this.aiTradeForCity();
                                    await this.waitForAllAnimations();
                                    await this.aiTradeBankForCity(true);
                                    await this.waitForAllAnimations();
                                    await this.aiUpgradeToCity();
                                    await this.waitForAllAnimations();
                                }
                            }
                            await this.waitForAllAnimations();

                            if(firstLoop == true) {
                                await this.aiTradeBankForPaperToThird();
                                await this.waitForAllAnimations();
                                await this.aiUpgradeAllCommodities();
                            }
                            await this.waitForAllAnimations();

                            let leastActiveCount = 0;
                            // least knights active, how many?
                            if(this.playersWithLeastActiveKnights.length) {
                                leastActiveCount = this.playersWithLeastActiveKnights[0].activeKnights;
                            }

                            let activePlayerActiveKnights = this.knights
                                .filter(knight => knight.player == this.activePlayer && knight.active)
                                .reduce((activeKnightsTotal, knight) => activeKnightsTotal + knight.level, 0);

                            // player has least knights and there is not enough
                            // if player has 0 knights, always try to build/activate at least one even if there is enough
                            if(
                                (this.playersWithLeastActiveKnights.find(obj => obj.player == this.activePlayer) &&
                                activeKnightsNeeded > 0) ||
                                activePlayerActiveKnights == 0
                            ) {
                                if(await this.aiActivateStrongestKnight() == 'knight needed') {
                                    if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount > 0) {
                                        if(await this.aiBuildKnight() == false) {
                                            await this.aiPlaySmith();
                                            if(await this.aiUpgradeKnight() == false) {
                                                await this.aiTradeBankForKnight();
                                                await this.waitForAllAnimations();
                                            }
                                        }
                                        await this.aiActivateStrongestKnight();
                                    } else {
                                        await this.aiPlaySmith();
                                        if(await this.aiUpgradeKnight() == false) {
                                            if(await this.aiBuildKnight() == false) {
                                                await this.aiTradeBankForKnight();
                                                await this.waitForAllAnimations();
                                            }
                                            await this.aiActivateStrongestKnight();
                                        }
                                    }
                                } else if(['knight needed'].includes(await this.aiActivateStrongestKnight())) {
                                    await this.aiActivateStrongestKnight();
                                } else if(['failed to activate'].includes(await this.aiActivateStrongestKnight())) {
                                    if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0) {
                                        if(!this.lateGame || this.boat.includes('deadly')) {
                                            await this.aiTradeWithBank('grain');
                                        }
                                    }
                                    await this.waitForAllAnimations();
                                    await this.aiActivateStrongestKnight();
                                    await this.waitForAllAnimations();
                                } else {
                                    if(await this.aiActivateStrongestKnight() == 'failed to activate') {
                                        if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0) {
                                            if(!this.lateGame || this.boat.includes('deadly')) {
                                                await this.aiTradeWithBank('grain');
                                            }
                                        }
                                        await this.waitForAllAnimations();
                                        await this.aiActivateStrongestKnight();
                                        await this.waitForAllAnimations();
                                    }
                                }

                            // player doesn't have least knights, but even if each player with least knights activates one each there is not enough
                            } else if(
                                !this.playersWithLeastActiveKnights.find(obj => obj.player == this.activePlayer) &&
                                activeKnightsNeeded > this.playersWithLeastActiveKnights.length
                            ) {
                                if(leastActiveCount+1 == activePlayerActiveKnights) {
                                    if(await this.aiActivateStrongestKnight() == 'knight needed') {
                                        if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount > 0) {
                                            if(await this.aiBuildKnight() == false) {
                                                await this.aiPlaySmith();
                                                if(await this.aiUpgradeKnight() == false) {
                                                    await this.aiTradeBankForKnight();
                                                    await this.waitForAllAnimations();
                                                }
                                            }
                                            if(!this.aiHasProgressCard('warlord')) {
                                                if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0) {
                                                    if(!this.lateGame || this.boat.includes('deadly')) {
                                                        await this.aiTradeWithBank('grain');
                                                    }
                                                }
                                                await this.waitForAllAnimations();
                                                await this.aiActivateStrongestKnight();
                                                await this.waitForAllAnimations();
                                            }
                                        } else {
                                            await this.aiPlaySmith();
                                            if(await this.aiUpgradeKnight() == false) {
                                                if(await this.aiBuildKnight() == false) {
                                                    await this.aiTradeBankForKnight();
                                                    await this.waitForAllAnimations();
                                                }
                                                if(await this.aiActivateStrongestKnight() == 'failed to activate') {
                                                    if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0) {
                                                        if(!this.lateGame || this.boat.includes('deadly')) {
                                                            await this.aiTradeWithBank('grain');
                                                        }
                                                    }
                                                    await this.waitForAllAnimations();
                                                    await this.aiActivateStrongestKnight();
                                                    await this.waitForAllAnimations();
                                                }
                                            }
                                        }
                                    } else if(this.aiActivateStrongestKnight() == 'failed to activate') {
                                        if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount == 0) {
                                            if(!this.lateGame || this.boat.includes('deadly')) {
                                                await this.aiTradeWithBank('grain');
                                            }
                                        }
                                        await this.waitForAllAnimations();
                                        await this.aiActivateStrongestKnight();
                                        await this.waitForAllAnimations();
                                    } else {
                                        // console.log(this.activePlayer.color + ' knight activated, no need to build now...');
                                    }
                                } else {
                                    // console.log(this.activePlayer.color + ' doesnt have least and has enough of a knight lead...');
                                }
                            // no need for player to build/activate more knights now
                            } else {
                                // console.log(this.activePlayer.color + ' no need to build/activate knight...')

                                // second round of ai actions
                                if(firstLoop == false) {

                                    if(this.activePlayerTooManyHandCards) {
                                        if(this.activePlayer.progress.find(progress => this.activePlayer.fightFor.includes(progress.type) && [3, 4].includes(progress.level))) {
                                            await this.aiPlayCommercialHarbor();
                                            await this.waitForAllAnimations();

                                            await this.aiTradeBankForMetropolis();
                                            await this.waitForAllAnimations();
                                        }
                                    }

                                    // don't build extra roads if fighting for lvl5
                                    if(this.activePlayer.progress.find(progress => this.activePlayer.fightFor.includes(progress.type) && progress.level == 4)) {

                                    } else {
                                        if(this.activePlayerTooManyHandCards && this.aiHasAvailableHouseSpot() == false) await this.aiBuildBoat();
                                        if(this.activePlayerTooManyHandCards && this.aiHasAvailableHouseSpot() == false) await this.aiBuildBoat();
                                        if(this.activePlayerTooManyHandCards && this.aiHasAvailableHouseSpot() == false) await this.aiBuildRoad();
                                        if(this.activePlayerTooManyHandCards && this.aiHasAvailableHouseSpot() == false) await this.aiBuildRoad();
                                        if(this.aiHasProgressCard('deseter')) await this.aiPlayDeseter();
                                    }

                                    if(this.activePlayerTooManyHandCards) await this.aiBuildCitywall();

                                    // build an extra knight without activating it
                                    if(this.activePlayerTooManyHandCards) await this.aiBuildKnight();

                                    if(this.activePlayerTooManyHandCards) {
                                        if(this.aiHasAvailableHouseSpot() == true) {
                                            await this.aiTradeForHouse();
                                            await this.waitForAllAnimations();
                                            await this.aiTradeBankForHouse();
                                            await this.waitForAllAnimations();
                                            await this.aiBuildHouse();
                                        } else {
                                            await this.aiTradeForCity();
                                            await this.waitForAllAnimations();
                                            await this.aiTradeBankForCity();
                                            await this.waitForAllAnimations();
                                            await this.aiUpgradeToCity();
                                        }
                                    }

                                    if(this.aiHasAvailableHouseSpot() == false) {
                                        await this.aiTradeBankForRoad();
                                        // maybe not this one
                                        await this.aiBuildRoad();
                                        if(this.aiHasProgressCard('deseter')) await this.aiPlayDeseter();
                                    }
                                    await this.waitForAllAnimations();
                                }
                            }

                            await this.waitForAllAnimations();

                            await this.aiBuildTwoRoadsAndHouse();
                            await this.waitForAllAnimations();

                            await this.aiTradeBankForHouseAndBoat(true);
                            await this.waitForAllAnimations();

                            await this.aiBuildBoatAndHouse();
                            await this.waitForAllAnimations();

                            await this.aiBuildRoadAndHouse();
                            await this.waitForAllAnimations();

                            await this.aiBuildHouse();
                            await this.waitForAllAnimations();

                            await this.aiUpgradeToCity();
                            await this.waitForAllAnimations();

                            if(this.aiHasProgressCard('merchant')) {
                                await this.aiPlayMerchant();
                            } else {
                                await this.aiPlayMerchantFleet();
                            }

                            if(this.aiHasProgressCard('medecine') &&
                                this.houses.filter(house => house.player == this.activePlayer).length != 0 &&
                                this.activePlayer.pieces.cities != 0
                            ) {
                                await this.aiTradeBankForCheapCity(true);
                                await this.waitForAllAnimations();
                                if(this.aiHasProgressCard('medecine')) {
                                    await this.aiPlayProgressCard('medecine');
                                    await this.waitForAllAnimations();
                                }
                            }

                            if(this.aiHasAvailableHouseSpot() == true) {
                                await this.aiTradeForHouse();
                                await this.waitForAllAnimations();
                                await this.aiTradeBankForHouse(true);
                                await this.waitForAllAnimations();
                                await this.aiBuildHouse();
                                await this.waitForAllAnimations();
                            } else {
                                await this.aiTradeBankForHouseAndRoad(true)
                                await this.waitForAllAnimations();
                                await this.aiBuildRoadAndHouse();
                                await this.waitForAllAnimations();

                                await this.aiTradeBankForCity(true);
                                await this.waitForAllAnimations();
                                await this.aiUpgradeToCity();
                                await this.waitForAllAnimations();
                            }

                            if(await this.aiEnableCity() == false) {
                                await this.aiTradeBankForEnableCity(true);
                                await this.waitForAllAnimations();
                                await this.aiEnableCity();
                                await this.waitForAllAnimations();
                            }



                            await this.aiUpgradeAllCommodities();
                            await this.waitForAllAnimations();

                            if(firstLoop == true) {
                                await this.aiTradeAndUpgradeAllToLevel1();
                                await this.waitForAllAnimations();

                                // player is 8 points from winning
                                if(this.activePlayer.points >= this.playToPoints-8) {
                                    // player has 4 roads try to build fifth
                                    if(this.playersLongest(this.activePlayer).length == 4) {
                                        await this.aiBuildLongestRoad();
                                        await this.waitForAllAnimations();
                                        await this.aiBuildLongestBoat();
                                        await this.waitForAllAnimations();
                                    }

                                    if(this.playersLongest(this.activePlayer).length == 4) {
                                        await this.waitForAllAnimations();
                                        await this.aiBuildLongestBoat();
                                    }

                                    // someone has longest road
                                    if(this.longestRoad[0]) {
                                        // longest road is 7 roads or less
                                        if(this.longestRoad[0].length <= 7) {
                                            // fight for it
                                            if(!this.activePlayer.fightFor.includes('longest road')) {
                                                this.activePlayer.fightFor.push('longest road');
                                            }
                                        }
                                    } else {
                                        if(await this.aiBuildLongestRoad() == false) {
                                            await this.aiTradeBankForRoad();
                                            await this.waitForAllAnimations();
                                            await this.aiBuildLongestRoad();
                                            await this.waitForAllAnimations();
                                            await this.aiBuildLongestBoat();
                                            await this.waitForAllAnimations();
                                            await this.aiTradeBankForBoat();
                                            await this.aiBuildLongestBoat();
                                            await this.waitForAllAnimations();
                                        }
                                    }
                                }

                                // if(this.aiHasAvailableHouseSpot() == false && this.activePlayerTooManyHandCards) await this.aiBuildLongestRoad();
                                if(this.aiHasAvailableHouseSpot() == false && this.activePlayerTooManyHandCards) {
                                    await this.aiBuildBoat();
                                    await this.waitForAllAnimations();
                                    await this.aiBuildRoad();
                                    if(this.aiHasProgressCard('deseter')) await this.aiPlayDeseter();
                                }

                                this.aiDoTurnActions(false);
                            } else {
                                if(this.activePlayer.fightFor.includes('longest road')) {
                                    if(await this.aiBuildLongestRoad() == false) {
                                        await this.aiTradeBankForRoad();
                                        await this.aiBuildLongestRoad();
                                        await this.waitForAllAnimations();
                                        await this.aiBuildLongestBoat();
                                        await this.waitForAllAnimations();
                                        await this.aiTradeBankForBoat();
                                        await this.aiBuildLongestBoat();
                                    }
                                }

                                await this.aiTradeBankForPaperToThird();
                                await this.waitForAllAnimations();
                                await this.aiUpgradeAllCommodities();
                                if(this.activePlayerTooManyHandCards && this.activePlayer.pieces.houses != 0) await this.aiBuildKnight();
                                if(this.activePlayerTooManyHandCards && this.activePlayer.pieces.houses != 0) await this.aiUpgradeKnight();
                                await this.waitForAllAnimations();
                                if(this.aiHasProgressCard('warlord')) {
                                    if(
                                        this.activePlayerHandCardsCount-1 == this.activePlayerAllowedHandCardsCount &&
                                        this.activePlayer.handCards.find(obj => obj.type == 'grain').amount > 0
                                    ) {
                                        await this.aiActivateStrongestKnight();
                                        await this.waitForAllAnimations();
                                    } else {
                                        await this.aiPlayProgressCard('warlord');
                                        await this.waitForAllAnimations();
                                    }
                                }

                                // ?
                                if(this.activePlayerTooManyHandCards) await this.aiActivateStrongestKnight();

                                await this.aiBuildTwoRoads();
                                await this.waitForAllAnimations();
                                await this.aiBuildTwoBoats();
                                await this.waitForAllAnimations();

                                if(this.activePlayer.fightFor.includes('longest road')) {
                                    await this.aiBuildLongestRoad();
                                    await this.waitForAllAnimations();
                                    await this.aiBuildLongestBoat();
                                    await this.waitForAllAnimations();
                                }
                                if(this.activePlayer.fightFor.includes('longest road')) await this.aiBuildBoat();
                                await this.waitForAllAnimations();

                                if(this.activePlayerTooManyHandCards) await this.aiBuildCitywall();

                                if(this.activePlayerTooManyHandCards && this.activePlayer.progress.find(progress => this.activePlayer.fightFor.includes(progress.type) && progress.level == 3)) {
                                    await this.aiTradeBankForMetropolis();
                                    await this.waitForAllAnimations();
                                    await this.aiUpgradeAllCommodities();
                                    await this.waitForAllAnimations();
                                }

                                // ?
                                if(this.activePlayerTooManyHandCards) {
                                    if(this.aiHasAvailableHouseSpot() == true) {
                                        await this.aiTradeForHouse();
                                        await this.waitForAllAnimations();
                                        await this.aiTradeBankForHouse();
                                        await this.waitForAllAnimations();
                                        await this.aiBuildHouse();
                                    } else {
                                        await this.aiTradeForCity();
                                        await this.waitForAllAnimations();
                                        await this.aiTradeBankForCity();
                                        await this.waitForAllAnimations();
                                        await this.aiUpgradeToCity();
                                    }
                                }

                                if(!this.boat.includes('deadly')) await this.aiActivateRobberKnight();
                                if(!this.boat.includes('deadly') && this.activePlayerTooManyHandCards) await this.aiActivateRobberKnight(true);

                                if(this.activePlayerTooManyHandCards) {
                                    await this.aiTradeForHouse();
                                    await this.waitForAllAnimations();
                                    await this.aiTradeBankForHouse();
                                    await this.waitForAllAnimations();
                                    await this.aiBuildHouse();
                                    await this.waitForAllAnimations();
                                }

                                if(this.activePlayerTooManyHandCards) await this.aiBuildCitywall();

                                if(this.activePlayer.pieces.cities == 0 && this.activePlayer.pieces.houses == 0) {
                                    let leastUpgradedType = this.opponentProgress.sort((a, b) => a.level - b.level)[0].type;

                                    for(let i = 0; i < 5; i++) {
                                        this.aiTradeWithBank(leastUpgradedType);
                                        await this.waitForAllAnimations();
                                    }

                                    this.aiUpgradeAllCommodities();
                                }

                                await this.aiBuildBackupKnight();
                                await this.waitForAllAnimations();

                                if(this.activePlayer.pieces.houses == 0 && this.activePlayer.pieces.cities != 0) {
                                    this.aiTradeForCity();
                                    await this.waitForAllAnimations();
                                    this.aiTradeBankForCity();
                                    await this.waitForAllAnimations();
                                    this.aiUpgradeToCity();
                                }

                                if(this.lateGame && this.activePlayerTooManyHandCards) {
                                    this.aiActivateStrongestKnight();
                                    this.aiActivateStrongestKnight();
                                }

                                if(this.activePlayer.handCards.find(obj => obj.type == 'grain').amount > 0 || this.aiHasProgressCard('warlord')) {
                                    await this.aiMoveKnightFromHouseSpot();
                                    await this.waitForAllAnimations();
                                }

                                if(this.cities.find(city => city.player == this.activePlayer)) await this.aiPlayProgressCard('warlord');

                                if(this.boat.includes('deadly')) {
                                    // ai has no city to protect
                                    if(!this.cities.find(city => city.player == this.activePlayer && city.metropolis == false)) {
                                        if(this.cities.length >= this.allActiveKnightsCount-2) {
                                            await this.aiTryToCloseKnights();
                                            await this.waitForAllAnimations();
                                        }
                                    }
                                }

                                if(this.aiHasProgressCard('deseter')) await this.aiPlayDeseter();

                                if(this.lateGame && this.activePlayerTooManyHandCards) this.aiBuildHouse();
                                if(this.lateGame && this.activePlayerTooManyHandCards) await this.waitForAllAnimations();
                                if(this.lateGame && this.activePlayerTooManyHandCards) this.aiUpgradeToCity();
                                if(this.lateGame && this.activePlayerTooManyHandCards) await this.waitForAllAnimations();
                                if(this.lateGame && this.activePlayerTooManyHandCards) this.aiBuildBoat();
                                if(this.lateGame && this.activePlayerTooManyHandCards) await this.waitForAllAnimations();
                                if(this.lateGame && this.activePlayerTooManyHandCards) this.aiBuildRoad();
                                if(this.lateGame && this.activePlayerTooManyHandCards) await this.waitForAllAnimations();
                                if(this.lateGame && this.activePlayerTooManyHandCards) this.aiUpgradeKnight();
                                if(this.lateGame && this.activePlayerTooManyHandCards) await this.waitForAllAnimations();

                                await this.waitForAllAnimations();
                                // console.log('DONE ' + this.timer.toFixed(1) + 's');
                                if(! this.activePlayer.ai) return;
                                this.endTurn();
                            }
                        // player has no city
                        } else {
                            await this.aiPlayDiplomat();
                            await this.waitForAllAnimations();
                            if(this.aiHasProgressCard('medecine') &&
                                this.houses.filter(house => house.player == this.activePlayer).length != 0 &&
                                this.activePlayer.pieces.cities != 0
                            ) {
                                await this.aiPlayProgressCard('medecine');
                                await this.waitForAllAnimations();
                            }

                            // ai lost city, gets no ore, do some build house stuff
                            if(this.activePlayerOreTilesCount == 0 && firstLoop == true) {
                                await this.aiBuildTwoRoadsAndHouse();
                                await this.waitForAllAnimations();
                                await this.aiBuildRoadAndHouse();
                                await this.waitForAllAnimations();
                                if(await this.aiBuildHouse() == false) {
                                    await this.aiTradeForHouse();
                                    await this.waitForAllAnimations();
                                    await this.aiTradeBankForHouse(true);
                                    await this.waitForAllAnimations();
                                    await this.aiBuildHouse()
                                    await this.waitForAllAnimations();
                                }
                                await this.waitForAllAnimations();
                                if(this.aiHasAvailableHouseSpot() == false) await this.aiBuildRoad();
                            }

                            if(!this.boat.includes('deadly')) {
                                if(this.aiHasProgressCard('medecine') &&
                                    this.houses.filter(house => house.player == this.activePlayer).length != 0 &&
                                    this.activePlayer.pieces.cities != 0
                                ) {
                                    await this.aiTradeBankForCheapCity(true);
                                    await this.waitForAllAnimations();
                                    if(this.aiHasProgressCard('medecine')) {
                                        await this.aiPlayProgressCard('medecine');
                                        await this.waitForAllAnimations();
                                    }
                                }

                                await this.aiUpgradeToCity();
                                await this.waitForAllAnimations();
                            } else {
                                if(this.activePlayerTooManyHandCards) {
                                    await this.aiBuildHouse();
                                    await this.waitForAllAnimations();

                                    if(await this.aiBuildKnight() == false) {
                                        await this.aiTradeForCity();
                                        await this.waitForAllAnimations();
                                        await this.aiTradeBankForCity();
                                        await this.waitForAllAnimations();
                                        if(this.activePlayerTooManyHandCards) this.aiTradeBankForKnight();
                                        await this.waitForAllAnimations();
                                    }
                                }
                            }

                            await this.aiBuildTwoRoadsAndHouse();
                            await this.waitForAllAnimations();
                            await this.aiBuildRoadAndHouse();
                            await this.waitForAllAnimations();
                            await this.aiBuildHouse();
                            await this.waitForAllAnimations();

                            if(this.activePlayerTooManyHandCards) {
                                if(this.aiHasProgressCard('merchant')) {
                                    await this.aiPlayMerchant();
                                } else {
                                    await this.aiPlayMerchantFleet();
                                }

                                await this.aiTradeForCity();
                                await this.waitForAllAnimations();
                                await this.aiTradeBankForCity();
                                await this.waitForAllAnimations();
                                if(!this.boat.includes('deadly')) {
                                    await this.aiUpgradeToCity();
                                } else {
                                    // boat is deadly but ai can build city and activate a knight
                                    if(
                                        this.knights.find(knight => knight.player == this.activePlayer) &&
                                        this.canPay(this.activePlayer, [ { type: 'grain', amount: 3 }, { type: 'ore', amount: 3 } ])
                                    ) {
                                        await this.aiUpgradeToCity();
                                        await this.waitForAllAnimations();
                                        await this.aiActivateStrongestKnight();
                                        await this.waitForAllAnimations();
                                    }
                                }

                                if(this.aiHasAvailableHouseSpot() == false) await this.aiBuildRoad();

                                await this.waitForAllAnimations();
                            }
                            if(this.activePlayerTooManyHandCards) {
                                await this.aiTradeForCity();
                                await this.waitForAllAnimations();
                                await this.aiTradeBankForCity();
                                await this.waitForAllAnimations();
                            }

                            await this.waitForAllAnimations();
                            if(this.activePlayerTooManyHandCards && !this.knights.find(knight => knight.player == this.activePlayer)) await this.aiBuildKnight();
                            await this.waitForAllAnimations();
                            // if(this.activePlayerTooManyHandCards) await this.aiActivateStrongestKnight();

                            if(firstLoop == true) {
                                this.aiDoTurnActions(false);
                            } else {
                                if(this.aiHasAvailableHouseSpot() == false) await this.aiBuildRoad();
                                await this.waitForAllAnimations();

                                if(this.cities.find(city => city.player == this.activePlayer)) {
                                    if(this.aiHasProgressCard('deseter')) await this.aiPlayDeseter();
                                }

                                // city exist now, upgrade commodities before ending turn
                                if(this.cities.find(city => city.player == this.activePlayer)) await this.aiTradeAndUpgradeAllToLevel1();
                                if(this.cities.find(city => city.player == this.activePlayer)) await this.aiPlayProgressCard('warlord');
                                await this.waitForAllAnimations();

                                if(this.cities.find(city => city.player == this.activePlayer)) {
                                    await this.aiPlayCommercialHarbor();
                                    await this.waitForAllAnimations();

                                    await this.aiUpgradeAllCommodities();
                                    await this.waitForAllAnimations();
                                    await this.aiTradeAndUpgradeAllToLevel1();
                                    await this.waitForAllAnimations();
                                }
                                await this.waitForAllAnimations();

                                if(this.activePlayerTooManyHandCards) {
                                    await this.aiBuildBoat();
                                    await this.waitForAllAnimations();
                                }

                                // console.log('DONE... '  + this.timer.toFixed(1) + 's');
                                await this.waitForAllAnimations();

                                if(! this.activePlayer.ai) return;
                                this.endTurn();
                            }
                        }
                    }
                }, 250);
            },
            quickTradeGet(type, player) {
                if(this.activePlayer != player) {
                    if(this.bePlayer.offerAnswer != 'waiting') return;

                    if(this.bePlayer.modalType != 'make counter offer') {
                        this.makeCounterOffer(this.bePlayer)
                    }
                    return this.counterTradeGet(type, bePlayer);
                }

                if(this.focusMode == true && this.focusType != 'trade cards') return;
                if(this.dicesRolling == true && this.devMode == false) return;
                if(this.dicesRollAnimation == true && this.devMode == false) return;

                this.resetOffer();
                this.updateTradeCosts();

                if(this.activePlayer.modalMode != true) {
                    this.activePlayer.tradeGet.forEach(cardObj => cardObj.amount = 0);

                    if(type) this.activePlayer.tradeGet.find(cardObj => cardObj.type == type).amount = 1;
                    this.activePlayer.showOutgoingHandCards = false;
                    this.showModal('trade cards', this.activePlayer);
                    this.focusModeOn('trade cards', this.activePlayer);
                } else {
                    // max 6 cards per trade
                    if(this.activePlayer.tradeGet.reduce((numberOfCards, card) => numberOfCards + card.amount, 0) == 6) return;

                    this.activePlayer.tradeGet.find(cardObj => cardObj.type == type).amount++;
                }
                // this.focusModeOn('trade cards', this.activePlayer);

                this.activePlayer.offerOpponents = false;

                this.updateBankOffers();
            },
            quickTradeGive(type) {
                this.resetOffer();
                let cardTarget = this.activePlayer.handCards.find(cardObj => cardObj.type == type);

                // max 6 cards per trade
                if(this.activePlayer.handCards.reduce((numberOfCards, cardObj) => numberOfCards + cardObj.selected, 0) == 6) return;

                // if card available, select it to be ready for a trade
                if(cardTarget.selected < cardTarget.amount) {
                    cardTarget.slideOut++;
                    cardTarget.selected++;
                }

                this.activePlayer.offerOpponents = false;
                this.updateBankOffers();
            },
            updateBankOffers() {
                let handCardsCopy = JSON.parse(JSON.stringify(this.activePlayer.handCards));

                this.activePlayer.tradeGet.forEach(tradeObj => {
                    if(tradeObj.amount > 0) handCardsCopy = handCardsCopy.filter(cardObj => cardObj.type != tradeObj.type);
                })

                handCardsCopy.splice().reverse();

                let playerGive = [];

                handCardsCopy.forEach(cardObj => {
                    for(let i = 0; i < cardObj.selected; i++) {
                        playerGive.push(cardObj.type);
                    }
                });

                handCardsCopy.sort((a, b) => a.tradeCost - b.tradeCost)

                // number of cards player wants to get
                let tradeGetCount = this.tradeGetCount(this.activePlayer);

                // card types which player is not trying to get
                let availableTypesCount = handCardsCopy.length;

                this.bankTradeOffer = [];

                let getIndex = 0;
                handCardsCopy.forEach(cardObj => {
                    if(tradeGetCount == this.bankTradeOffer.length) return;

                    while(cardObj.amount >= cardObj.tradeCost) {
                        let giveCardCanTrade = false;
                        let target = null;

                        if(playerGive.length > getIndex) {

                            target = handCardsCopy.find(handCardObj => handCardObj.type == playerGive[getIndex]);

                            if(target.amount >= target.tradeCost) {
                                giveCardCanTrade = true;
                            }
                        }

                        if(giveCardCanTrade) {
                            target.amount -= cardObj.tradeCost;
                            this.bankTradeOffer.push( {type: target.type, tradeCost: target.tradeCost } )
                        } else {
                            cardObj.amount -= cardObj.tradeCost;
                            this.bankTradeOffer.push( {type: cardObj.type, tradeCost: cardObj.tradeCost } )
                        }

                        getIndex++;
                        if(tradeGetCount == this.bankTradeOffer.length) break;
                    }
                })

                // player can't afford the trade
                if(this.bankTradeOffer.length < tradeGetCount) this.bankTradeOffer = [];

                this.bankTradeOffer2 = [];
                this.bankTradeOffer3 = [];

                // if no trades available, if player is trying to get something else than one card or if player tries to give cards, don't show alternative trades
                if(!this.bankTradeOffer.length || tradeGetCount != 1 || playerGive.length > 0) return;

                handCardsCopy = handCardsCopy.filter(cardObj => cardObj.amount >= cardObj.tradeCost && cardObj.type != this.bankTradeOffer[0].type);

                if(!handCardsCopy.length) return;

                handCardsCopy = handCardsCopy.sort((a, b) => a.tradeCost - b.tradeCost)

                this.bankTradeOffer2.push( {type: handCardsCopy[0].type, tradeCost: handCardsCopy[0].tradeCost } )

                if(handCardsCopy.length > 1) {
                    this.bankTradeOffer3.push( {type: handCardsCopy[1].type, tradeCost: handCardsCopy[1].tradeCost } )
                }

            },
            async acceptBankTrade(alternativeTrade = 0) {
                let tradeCostString = '';
                let tradeGetString = '';
                if(!this.bankTradeOffer.length) return;
                this.activePlayer.showOutgoingHandCards = true;

                // don't let ai trade away commodities which they're fighting for
                if(this.activePlayer.ai) {
                    let fightForCardFound = false;
                    if(alternativeTrade == 0) {
                        this.bankTradeOffer.forEach(tradeObj => {
                            if(this.activePlayer.fightFor.includes(tradeObj.type)) fightForCardFound = true;
                        });
                    } else if(alternativeTrade == 2) {
                        this.bankTradeOffer2.forEach(tradeObj => {
                            if(this.activePlayer.fightFor.includes(tradeObj.type)) fightForCardFound = true;
                        });
                    } else if(alternativeTrade == 3) {
                        this.bankTradeOffer3.forEach(tradeObj => {
                            if(this.activePlayer.fightFor.includes(tradeObj.type)) fightForCardFound = true;
                        });
                    }

                    if(fightForCardFound == true) {
                        // this row is might not needed
                        this.closeModal(this.activePlayer, true);
                        this.focusModeOff(this.activePlayer);
                        return;
                    }
                }

                if(alternativeTrade == 0) {
                    this.bankTradeOffer.forEach(tradeObj => {
                        this.activePlayer.handCards.find(cardObj => cardObj.type == tradeObj.type).amount -= tradeObj.tradeCost;

                        for(let i = 0; i < tradeObj.tradeCost; i++) tradeCostString += ':' + tradeObj.type + ' ';
                    });
                } else if(alternativeTrade == 2) {
                    this.bankTradeOffer2.forEach(tradeObj => {
                        this.activePlayer.handCards.find(cardObj => cardObj.type == tradeObj.type).amount -= tradeObj.tradeCost;

                        for(let i = 0; i < tradeObj.tradeCost; i++) tradeCostString += ':' + tradeObj.type + ' ';
                    });
                } else if(alternativeTrade == 3) {
                    this.bankTradeOffer3.forEach(tradeObj => {
                        this.activePlayer.handCards.find(cardObj => cardObj.type == tradeObj.type).amount -= tradeObj.tradeCost;

                        for(let i = 0; i < tradeObj.tradeCost; i++) tradeCostString += ':' + tradeObj.type + ' ';
                    });
                }

                this.activePlayer.tradeGet.forEach(getObj => {
                    if(getObj.amount == 0) return;
                    this.activePlayer.handCards.find(cardObj => cardObj.type == getObj.type).slideIn += getObj.amount;
                    this.activePlayer.handCards.find(cardObj => cardObj.type == getObj.type).amount += getObj.amount;
                    this.paying = true;

                    for(let i = 0; i < getObj.amount; i++) tradeGetString += ':' + getObj.type + ' ';

                    setTimeout(() => {
                        this.activePlayer.handCards.find(cardObj => cardObj.type == getObj.type).slideIn -= getObj.amount;
                        this.paying = false;
                    }, 1000)
                })

                this.focusModeOff(this.activePlayer);

                this.log('<div>:activePlayer bank trade</div>' + tradeGetString + '<div>for</div>' + tradeCostString);

                await this.waitForAllAnimations();

                if(this.logToConsole) console.log('SAVE acceptBankTrade')
                if(! this.activePlayer.ai) this.saveGame();

            },
            counterTradeGet(type, player) {
                if(player.tradeGet.reduce((numberOfCards, card) => numberOfCards + card.amount, 0) == 6) return;

                this.aiTradeWaitMs = 0;

                player.tradeGet.find(cardObj => cardObj.type == type).amount++;
            },
            counterTradeGive(type, player) {
                let cardTarget = player.handCards.find(cardObj => cardObj.type == type);

                // max 6 cards per trade
                if(player.handCards.reduce((numberOfCards, cardObj) => numberOfCards + cardObj.selected, 0) == 6) return;

                this.aiTradeWaitMs = 0;

                // if card available, select it to be ready for a trade
                if(cardTarget.selected < cardTarget.amount) {
                    cardTarget.slideOut++;
                    cardTarget.selected++;
                }
            },
            submitCounterOffer(player) {
                if(this.tradeGetCount(player) == 0) return;
                if(this.selectedHandCardsCount(player) == 0) return;

                player.offerAnswer = 'accepted';
                this.closeModal(player, false);

                if(this.logToConsole) console.log('SAVE submitCounterOffer')
                if(! this.activePlayer.ai) this.saveHand(player);
            },
            makeOffer(player) {
                if(this.tradeGetCount(player) == 0) return;

                this.resetOffer();

                let opponents = this.players.filter(opponent => opponent != player);

                opponents.forEach(opponent => {
                    opponent.offerAnswer = 'waiting';

                    if(opponent.ai) {
                        if(this.multiplayer) return this.rejectTradeOffer(opponent);

                        setTimeout(() => {
                            let playerWants = player.tradeGet.find(obj => obj.amount > 0);

                            if(playerWants.length == 0) return this.rejectTradeOffer(opponent);

                            playerWants = playerWants.type;

                            if(this.tradeGetCount(player) != 1) return this.rejectTradeOffer(opponent, playerWants);

                            if(this.firstBoatRound) return this.rejectTradeOffer(opponent, playerWants);

                            if(this.playerHandCardsCount(opponent) <= 5 || this.playerHandCardsCount(opponent) >= 13) return this.rejectTradeOffer(opponent, playerWants);

                            let gettingCardsCount = this.activePlayer.handCards.reduce((total, obj) => total + obj.selected, 0);

                            if(gettingCardsCount == 0) this.rejectTradeOffer(opponent, playerWants);

                            this.aiGetHandCardsToThrow(opponent);

                            let neededCards = this.aiNeededCards(opponent).filter(type => type != playerWants).slice(0, 4);

                            let wantToTrade = false;

                            this.activePlayer.handCards.forEach(obj => {
                                if(obj.selected > 0 && neededCards.includes(obj.type)) {
                                    wantToTrade = true;
                                }
                            })

                            if(this.activePlayer == this.leaderAll && gettingCardsCount < 2) wantToTrade = false;

                            if(this.lateGame) wantToTrade = false;

                            if(this.tradeGiveCount(player) == 0 && this.tradeGetCount(player) == 1 && !this.lateGame) {

                                // don't make counter offer with the same card for give & get (ore vs ore)
                                if(this.activePlayer.tradeGet.find(obj => obj.amount > 0).type == neededCards[0]) return this.rejectTradeOffer(opponent);

                                if(['cloth', 'coin', 'paper'].includes(this.activePlayer.tradeGet.find(obj => obj.amount > 0).type)) return this.rejectTradeOffer(opponent);

                                if(this.makeCounterOffer(opponent) != false) {
                                    this.counterTradeGet(neededCards[0], opponent);
                                    this.submitCounterOffer(opponent);
                                } else {
                                    this.rejectTradeOffer(opponent);
                                }

                                return;
                            }

                            if(opponent.handCards.find(obj => obj.type == playerWants).throw > 0 && wantToTrade) {
                                // if leader, ask for one card more
                                if(this.activePlayer == this.leaderAll) {
                                    this.makeCounterOffer(opponent);
                                    this.counterTradeGet(neededCards[this.randomNumber(1,3)], opponent);
                                    this.submitCounterOffer(opponent);
                                } else {
                                    this.acceptTradeOffer(opponent);
                                }
                            } else {
                                this.rejectTradeOffer(opponent, playerWants);
                            }

                        }, this.randomNumber(400, 400+this.aiActionSpeed*8));

                    }
                });

                this.activePlayer.offerOpponents = true;

                if(this.logToConsole) console.log('SAVE makeOffer')
                this.saveGame();
            },
            cancelAcceptedOffer(player) {
                this.deselectPlayerHandCards(player);
                player.offerAnswer = 'rejected';

                if(this.logToConsole) console.log('SAVE cancelAcceptedOffer')
                if(! this.activePlayer.ai) this.saveGame();
            },
            resetOffer() {
                let opponents = this.players.filter(opponent => opponent != this.activePlayer);

                opponents.forEach(opponent => {
                    opponent.offerAnswer = null;

                    // slideDown animation needed here?
                    opponent.handCards.forEach(cardObj => {
                        cardObj.selected = 0;
                        setTimeout(() => {
                            cardObj.slideIn = 0;
                            cardObj.slideOut = 0;
                        }, 1000);
                    });
                });

                this.forceUpdate++;
                this.activePlayer.offerOpponents = false;
            },
            acceptTradeOffer(player) {
                // multiplayer game
                if(this.multiplayer) {
                    // human and loading data
                    if(!player.ai && this.loadGameDataCompleted == false) return;
                }

                player.showOutgoingHandCards = false;
                // check so player can give what asked for
                let canTrade = true;
                let tradeGetIsEmpty = true;

                this.activePlayer.tradeGet.forEach(tradeObj => {
                    let target = player.handCards.find(cardObj => cardObj.type == tradeObj.type);

                    if(target.amount < tradeObj.amount) canTrade = false;
                });

                this.activePlayer.handCards.forEach(cardObj => {
                    if(cardObj.selected > 0) tradeGetIsEmpty = false;
                });

                if(tradeGetIsEmpty) return this.makeCounterOffer(player);

                if(canTrade == false) return;

                // select the cards which user accepted to trade away (active player still has to confirm trade)
                this.activePlayer.tradeGet.forEach(tradeObj => {
                    player.handCards.find(cardObj => cardObj.type == tradeObj.type).selected = tradeObj.amount;
                    player.handCards.find(cardObj => cardObj.type == tradeObj.type).slideOut = tradeObj.amount;
                });

                this.activePlayer.handCards.forEach(cardObj => {
                    player.tradeGet.find(tradeObj => tradeObj.type == cardObj.type).amount = cardObj.selected;
                });

                player.offerAnswer = 'accepted';

                if(this.logToConsole) console.log('SAVE acceptTradeOffer');
                if(! this.activePlayer.ai) {
                    if(!player.ai) this.saveHand(player);
                }
            },
            makeTrade(opponent) {
                let tradeGiveString = '';
                let tradeGetString = '';

                if(!opponent) return;
                if(opponent.offerAnswer == 'waiting') return;

                let canTrade = true;

                this.activePlayer.handCards.forEach(cardObj => {

                    let target = opponent.tradeGet.find(tradeObj => cardObj.type == tradeObj.type);

                    if(target.amount > cardObj.amount) canTrade = false;
                });

                if(canTrade == false) return;

                this.activePlayer.showIncomingHandCards = true;
                this.activePlayer.showOutgoingHandCards = false;
                opponent.showIncomingHandCards = true;
                opponent.showOutgoingHandCards = false;

                opponent.handCards.forEach(tradeObj => {
                    let target = this.activePlayer.handCards.find(cardObj => cardObj.type == tradeObj.type);
                    let opponentTarget = opponent.handCards.find(cardObj => cardObj.type == tradeObj.type);

                    for(let i = 0; i < tradeObj.selected; i++) tradeGiveString += ':' + tradeObj.type + ' ';

                    target.amount += tradeObj.selected;
                    target.slideIn += tradeObj.selected;
                    opponentTarget.amount -= tradeObj.selected;
                });

                opponent.tradeGet.forEach(tradeObj => {
                    let target = this.activePlayer.handCards.find(cardObj => cardObj.type == tradeObj.type);
                    let opponentTarget = opponent.handCards.find(cardObj => cardObj.type == tradeObj.type);

                    for(let i = 0; i < tradeObj.amount; i++) tradeGetString += ':' + tradeObj.type + ' ';

                    target.seleced -= tradeObj.amount;
                    target.amount -= tradeObj.amount;

                    opponentTarget.amount += tradeObj.amount;
                    opponentTarget.slideIn += tradeObj.amount;

                    tradeObj.amount = 0;
                })

                this.rejectedTradeTypes.forEach(obj => {
                    obj.rejected = 0;
                });

                this.log('<div>Trade: :activePlayer got</div>' + tradeGiveString + '<div> :playerName got</div>' + tradeGetString, opponent);

                if(this.logToConsole) console.log('SAVE makeTrade');
                if(! this.activePlayer.ai) this.saveGame();

                this.closeModal(this.activePlayer);
                this.focusModeOff(this.activePlayer);
                this.resetOffer();
            },
            makeCounterOffer(player) {
                player.showOutgoingHandCards = false;
                // this.showModal('make counter offer', player);
                this.showCounterOfferModal = true;
                this.aiTradeWaitMs = 0;
                player.tradeGet.forEach(tradeObj => {
                    let target = this.activePlayer.handCards.find(cardObj => cardObj.type == tradeObj.type);

                    tradeObj.amount = target.selected;
                });

                let canTrade = true;

                this.activePlayer.tradeGet.forEach(tradeObj => {
                    let target = player.handCards.find(cardObj => cardObj.type == tradeObj.type);

                    if(target.amount < tradeObj.amount) canTrade = false;
                });

                if(canTrade == true) {
                    this.activePlayer.tradeGet.forEach(tradeObj => {
                        player.handCards.find(cardObj => cardObj.type == tradeObj.type).selected = tradeObj.amount;
                        player.handCards.find(cardObj => cardObj.type == tradeObj.type).slideOut = tradeObj.amount;
                    });
                } else {
                    return false;
                }

                // if(this.logToConsole) console.log('SAVE makeCounterOffer');
                // if(! this.activePlayer.ai) this.saveGame();
            },
            rejectTradeOffer(player, type = null) {
                // multiplayer game
                if(this.multiplayer) {
                    // human and loading data
                    if(!player.ai && this.loadGameDataCompleted == false) return;
                }

                // not sure about this one
                if(player.offerAnswer != 'waiting') return;

                let get = this.activePlayer.tradeGet.filter(obj => obj.amount > 0);

                // when player rejects AI, register it too
                if(get.length) {
                    if(type == null) {
                        type = get[0].type;
                    }
                }

                if(type) {
                    this.rejectedTradeTypes.find(obj => obj.type == type).rejected += 1;
                }
                player.offerAnswer = 'rejected';

                if(this.logToConsole) console.log('SAVE rejectTradeOffer');
                if(!player.ai) this.savePlayer(player);
            },
            async chooseAqueductResource(player, choise, goldAmount, log = true) {
                if(this.loadGameDataCompleted == false) return;

                if(player.ai) {
                    // maybe goldAmount == 1
                    if(goldAmount <= 1) {
                        if(player.goldmineAmount > 0) player.goldmineAmount--;
                        this.focusModeOff(player);
                    }
                } else {
                    this.focusModeOff(player);
                }

                player.handCards.find(cardObj => cardObj.type == choise).amount++;
                player.handCards.find(cardObj => cardObj.type == choise).slideIn++;

                if(this.pickedAqueductCards.length) {
                    this.pickedAqueductCards[0].find(obj => obj.type == choise).amount++;

                    if(log) this.log('<div>:playerName picked aqueduct resource</div> :' + choise, player)
                }

                // this.saveGame();

                setTimeout(() => {
                    player.handCards.find(cardObj => cardObj.type == choise).slideIn--;
                    console.log('SAVE aqueduct')
                    if(player.ai) {
                        if(goldAmount <= 1) this.savePlayer(player);
                    } else {
                        this.savePlayer(player);
                    }
                }, 1000);

                if(player.ai) {
                    if(goldAmount != 1) {
                        player.goldmineAmount--;
                        return await this.aiSelectAqueductResource(player, goldAmount-1, false);
                    }
                }

                await this.waitForAllAnimations();

            },
            async chooseGoldmineResource(player, choise) {

                let goldmineChoisesString = '';

                if(player.ai) {
                    if(player.waitingForMe == false) return this.focusModeOff(player);
                }

                if(player.goldmineAmount <= -1) {
                    player.goldmineAmount = 0;
                    player.goldmineChoises.forEach(choise => {
                        choise.amount = 0;
                    });
                    return this.focusModeOff(player);
                }

                if(this.loadGameDataCompleted == false) return;

                this.selectingGoldMine = true;
                player.goldmineAmount--;
                player.goldmineChoises.find(cardObj => cardObj.type == choise).amount++;

                this.updateLocalStorage();

                if(player.goldmineAmount == 0) {
                    this.focusModeOff(player);

                    player.goldmineChoises.forEach(choise => {
                        player.handCards.find(cardObj => cardObj.type == choise.type).amount += choise.amount;
                        player.handCards.find(cardObj => cardObj.type == choise.type).slideIn += choise.amount;

                        for(let i = 0; i < choise.amount; i++) goldmineChoisesString += ':' + choise.type + ' ';

                        setTimeout(() => {
                            player.handCards.find(cardObj => cardObj.type == choise.type).slideIn -= choise.amount;
                            choise.amount = 0;

                            player.waitingForMe = false;

                        }, 1000);
                    });

                    setTimeout(async () => {
                        if(player.ai) {
                            this.savePlayer(player);
                        } else {
                            this.saveHand(player);
                        }
                        this.selectingGoldMine = false;

                        this.log('<div>Goldmine: :playerName selected</div>' + goldmineChoisesString, player);
                    }, 1000);

                }

                await this.waitForAllAnimations();
            },
            playCommercialHarbor() {
                let resourceCount = this.resourceCount(this.activePlayer);

                if(resourceCount == 0) {
                    return;
                }

                this.showModal('commercial harbor select resource', this.activePlayer);
                this.focusModeOn('commercial harbor select resource', this.activePlayer);

                if(this.logToConsole) console.log('SAVE playCommercialHarbor 2');
                this.saveGame();
            },
            selectedHarborResource(type) {
                this.activePlayer.showOutgoingHandCards = false;

                // clear commodity animations
                this.activePlayer.handCards.forEach(cardObj => {
                    if(cardObj.group == 'commodity') {
                        cardObj.slideIn = 0;
                    }
                })

                this.focusCard = type;


                this.closeModal(this.activePlayer);
                this.focusModeOff(this.activePlayer);

                this.activePlayer.handCards.find(handCardObj => handCardObj.type == this.focusCard).slideOut = 1;

                this.activePlayer.handCards.find(handCardObj => handCardObj.type == this.focusCard).selected++;

                if(this.commercialHarborOpponents.length == 1) {
                    return this.commercialHarborAgainstOpponent(this.selectPlayer(this.commercialHarborOpponents[0]));
                }

                this.commercialHarborOpponents.forEach(color => {
                    let opponent = this.selectPlayer(color);

                    opponent.highlightMyPoints = true;
                    opponent.highlightMyHandCards = true;

                    this.buildings.forEach(building => {
                        if(building.player.color == opponent.color) {
                            this.highlightSpot(building.key, this.activePlayer);
                        }
                    });
                });

                this.focusModeOn('commercial harbor select opponent', this.activePlayer)
            },
            selectedHarborCommodity(player, type) {
                this.activePlayer.showIncomingHandCards = false;
                player.showIncomingHandCards = false;
                player.showOutgoingHandCards = false;

                this.activePlayer.handCards.find(handCardObj => handCardObj.type == this.focusCard).amount--;
                this.activePlayer.handCards.find(handCardObj => handCardObj.type == this.focusCard).selected--;
                this.activePlayer.handCards.find(handCardObj => handCardObj.type == this.focusCard).slideOut--;
                this.activePlayer.handCards.find(handCardObj => handCardObj.type == type).amount++;
                this.activePlayer.handCards.find(handCardObj => handCardObj.type == type).slideIn++;

                player.handCards.find(handCardObj => handCardObj.type == this.focusCard).amount++;
                player.handCards.find(handCardObj => handCardObj.type == this.focusCard).slideIn++;
                player.handCards.find(handCardObj => handCardObj.type == type).amount--;
                player.handCards.find(handCardObj => handCardObj.type == type).selected--;

                this.log(':activePlayer and :playerName exchanged resource against commodity', player);

                setTimeout(() => {
                    player.handCards.find(handCardObj => handCardObj.type == this.focusCard).slideIn--;
                    this.activePlayer.handCards.find(handCardObj => handCardObj.type == type).slideIn--;
                }, 1000)

                // abc
                this.focusModeOff(player);

                if(this.commercialHarborOpponents.length) {
                    this.playCommercialHarbor();
                }


                // setTimeout(() => {
                 //    if(this.logToConsole) console.log('SAVE selectedHarborCommodity 2')
                //     this.saveGame();
                // }, 1000)
            },
            commercialHarborAgainstOpponent(player) {

                this.commercialHarborOpponents = this.commercialHarborOpponents.filter(opponent => opponent != player.color && this.playerHandCardsCount(this.selectPlayer(opponent)) != 0);

                let commodityCount = this.commodityCount(player);

                if(commodityCount == 0) {
                    this.log(':playerName had 0 commodity cards', player);

                    this.activePlayer.handCards.find(handCardObj => handCardObj.type == this.focusCard).slideIn++;
                    this.activePlayer.handCards.find(handCardObj => handCardObj.type == this.focusCard).slideOut--;

                    setTimeout(() => {
                        this.activePlayer.handCards.find(handCardObj => handCardObj.type == this.focusCard).slideIn--;
                        this.activePlayer.handCards.find(handCardObj => handCardObj.type == this.focusCard).selected--;
                    }, 1000)

                    if(this.commercialHarborOpponents.length != 0) {
                        return this.playCommercialHarbor();
                    } else {
                        return this.focusModeOff(this.activePlayer);
                    }
                } else {

                    this.log('Waiting for :playerName to choose a commodity card', player, true);
                    this.focusModeOn('commercial harbor select commodity', player)
                    this.showModal('commercial harbor select commodity', player)

                    if(this.logToConsole) console.log('SAVE commercial harbor select commodity');
                    this.saveGame();
                }
            },
            tradeGetCount(player) {
                let tradeGetCount = player.tradeGet.reduce((numberOfCards, card) => {
                    return numberOfCards + card.amount;
                }, 0);

                return tradeGetCount;
            },
            tradeGiveCount(player) {
                let tradeGiveCount = player.handCards.reduce((numberOfCards, card) => {
                    return numberOfCards + card.selected;
                }, 0);

                return tradeGiveCount;
            },
            selectPlayer(color) {
                return this.players.find(player => player.color == color ? player : null);
            },
            detectIsland(tile) {
                // spread to all neighbour tiles which is land
                this.surroundingTiles(tile)
                    .filter(tile => tile && tile.group == 'land')
                    .forEach(tile => {
                        if(!this.islandTiles.includes(tile)) {
                            this.islandTiles.push(tile);
                            this.detectIsland(tile);
                        }
                })
            },
            buildingFoundOnNewIsland(tile, spotPosition) {
                this.islandTiles = [];
                let landTile = this.spotTiles(tile, spotPosition).find(spotTile => spotTile && spotTile.group == 'land');

                if(landTile) {
                    this.islandTiles.push(landTile);
                    this.detectIsland(landTile);
                }

                let playerBuildingFound = false;

                this.buildings.filter(building => building.player == this.activePlayer).forEach(building => {
                    if(!building) return;

                    if(this.spotTiles(building.tile, building.position)
                        .filter(buildingTile => buildingTile && buildingTile.group == 'land')
                        .find(buildingTile => this.islandTiles.includes(buildingTile))) {
                            playerBuildingFound = true;
                        }
                });

                return playerBuildingFound;
            },
            spotClicked(tile, spotPosition) {

                if(['build initial house', 'build initial city'].includes(this.focusType)) {
                    let spotContent = this.spot(tile, spotPosition).content;
                    if(spotContent) {
                        if(['city', 'house'].includes(spotContent.type) && spotContent.player.color == this.activePlayer.color) {

                        } else {
                            if(this.buildingDistance(tile, spotPosition) == false) return this.giveFeedback("Can't build next to another building.");
                        }
                    }
                    if(this.spotTiles(tile, spotPosition).filter(spotTile => spotTile && spotTile.group == 'land').length == 0) return;
                    if(this.spotTiles(tile, spotPosition).find(spotTile => spotTile && (spotTile.hidden || spotTile.noInitialBuild))) return;
                }

                // this.spotSpots(tile, spotPosition);

                if(this.focusMode == true) {
                    this.clickHighlighted(tile, spotPosition, this.bePlayer);
                } else {
                    if(this.gamePhase == 'regular' && this.devMode == false) {
                        if(this.dicesRolling == true) return;
                        if(this.dicesRollAnimation == true) return;
                    }

                    if(this.activePlayer != this.bePlayer) return;

                    this.buildingDistanceActions = this.buildingDistance(tile, spotPosition);

                    this.spotActions(tile, spotPosition);
                }

                this.forceUpdate++;
            },
            commodityUpgradeClicked(player, type) {
                if(this.focusMode == true) return false;
                if(this.activePlayer != player) return false;
                if(this.dicesRolling == true && this.devMode == false) return false;
                if(this.dicesRollAnimation == true && this.devMode == false) return false;

                this.commodityUpgrade(player, type, this.buildEverythingForFree);
            },
            async commodityUpgrade(player, type, free = false, crane = false) {

                let playerCities = this.cities.filter(city => city.player == player);

                if(!playerCities.length) {
                    this.giveFeedback("You can't upgrade commodities without a city.");
                    return false;
                }

                let progress = player.progress.find(progress => progress.type == type);

                if(progress.level == 5) return false;

                // wait for paying animation
                if(this.paying == true) return false;

                let cost = 1;

                if(crane) {
                    cost = 0;
                }

                if(!free) {
                    if(!this.canPay(player, [ { type: progress.type, amount: progress.level+cost } ])) {
                        this.giveFeedback("You can't afford this upgrade.");
                        return false;
                    }
                }

                // cloth lvl3 gives 2:1
                this.updateTradeCosts();

                if(progress.level >= 3) {

                    let metropolisTaken = false;
                    this.players.forEach(player => {

                        let playerWithMetropolis = player.progress.find(opponentProgress => opponentProgress.type == type && opponentProgress.level >= progress.level+1 && opponentProgress.metropolis == true);

                        if(playerWithMetropolis) {
                            metropolisTaken = true;
                        }

                    });

                    if(!metropolisTaken) {

                        let citiesCanTakeMetropolis = this.cities.filter(city => city.player.color == player.color && city.disabled == false && city.metropolis == false);

                        let stealMetropolis = this.cities.find(city => city.metropolisType == type && city.player != this.activePlayer);
                        let hasMetropolisAlready = this.cities.find(city => city.metropolisType == type && city.player == this.activePlayer);

                        if(!hasMetropolisAlready) {
                            // if player can't put metropolis on any city, pay their cards
                            if(citiesCanTakeMetropolis.length == 0) {
                                // if player doesn't have metropolis herself already
                                this.updatePlayersPoints();
                                if(!stealMetropolis) {
                                    this.giveFeedback("No city available for metropolis.");

                                    if(this.craneOptionsLength == 1 && crane)  {
                                        if(this.activePlayer.progressCards.filter(obj => obj.label == 'crane').length == 1) {
                                            this.activePlayer.progressCards.unshift({ deck: 'paper', label: 'crane', active: false, });
                                        }

                                        if(this.progressCardsPaper[this.progressCardsPaper.length-1] == 'crane') {
                                            this.progressCardsPaper.pop();
                                        }

                                        return;
                                    }
                                    // crane failed, give it back to activePlayer
                                    if(crane) {
                                        if(! this.hasProgressCard('crane')) {
                                            this.activePlayer.progressCards.unshift({ deck: 'paper', label: 'crane', active: false, });
                                        }

                                        if(this.progressCardsPaper[this.progressCardsPaper.length-1] == 'crane') {
                                            this.progressCardsPaper.pop();
                                        }
                                    }

                                    return false;
                                }
                                if(stealMetropolis.player != this.activePlayer) {
                                    this.giveFeedback("No city available for metropolis.");

                                    // crane failed, give it back to activePlayer
                                    if(crane) {
                                        if(! this.hasProgressCard('crane')) {
                                            this.activePlayer.progressCards.unshift({ deck: 'paper', label: 'crane', active: false, });
                                        }

                                        if(this.progressCardsPaper[this.progressCardsPaper.length-1] == 'crane') {
                                            this.progressCardsPaper.pop();
                                        }
                                    }

                                    return false;
                                }
                            // if only one city can take it, put the metropolis on that city
                            } else if(citiesCanTakeMetropolis.length == 1) {
                                if(stealMetropolis) {
                                    stealMetropolis.metropolis = false;
                                    stealMetropolis.metropolisType = null;
                                }

                                citiesCanTakeMetropolis[0].metropolis = true;
                                citiesCanTakeMetropolis[0].metropolisType = progress.type;
                                let progressLevel = progress.level+1;
                                this.log(':metropolis :playerName upgraded ' + progress.type + ' to level ' + progressLevel + '.<br>', this.activePlayer);
                                this.updatePlayersPoints();

                                if(this.logToConsole) console.log('SAVE upgradeProgress');
                                this.saveGame();
                            // highlight all cities where player can place the metropolis
                            } else {
                                if(stealMetropolis) {
                                    stealMetropolis.metropolis = false;
                                    stealMetropolis.metropolisType = null;
                                }

                                citiesCanTakeMetropolis.forEach(city => {

                                    let spot = this.spot(city.tile, city.position);
                                    this.highlightSpot(spot.key, this.activePlayer);

                                    this.focusModeOn('add metropolis', this.activePlayer);
                                    this.type = progress.type;
                                });
                                let progressLevel = progress.level+1;
                                this.log(':metropolis :playerName upgraded ' + progress.type + ' to level ' + progressLevel + '.<br>', this.activePlayer);

                                if(this.logToConsole) console.log('SAVE upgradeProgress');
                                this.saveGame();
                            }

                            this.players.forEach(player => {
                                player.progress.find(opponentProgress => opponentProgress.type == type).metropolis = false;
                            });
                        }

                        progress.metropolis = true;
                        this.updatePlayersPoints();
                        this.updateFightFor(progress.type);

                    }

                    if(this.pay(player, [ { type: progress.type, amount: progress.level+cost } ])) {
                        progress.level++;

                        this.updateFightFor(progress.type);

                        if(this.logToConsole) console.log('SAVE upgradeProgress');
                        this.saveGame();
                    }

                    this.updateMetropolisTopLevel();
                    this.updatePlayersPoints();
                } else {
                    if(this.pay(player, [ { type: progress.type, amount: progress.level+cost } ])) {
                        progress.level++;
                        if(progress.level == 3) {

                            let message = null;

                            if(progress.type == 'cloth') {
                                message = ':offset :playerName can trade commodity cards 2:1 for the rest of the game.';
                            } else if(progress.type == 'coin') {
                                message = ':offset :playerName can upgrade knights to level 3 now.';
                            } else if(progress.type == 'paper') {
                                message = ":offset When any dice roll but 7 doesn't generate cards,<br>:offset :playerName will select a free resource.";
                            }
                            this.log(':' + progress.type + ' :playerName upgraded ' + progress.type + ' to level 3.<br>' + message, this.activePlayer);

                            this.updateFightFor(progress.type);
                        }

                        if(this.logToConsole) console.log('SAVE upgradeProgress');
                        this.saveGame();
                    }
                }
                this.updatePlayersPoints();

                await this.waitForAllAnimations();
                return true;
            },
            updateFightFor(progressType) {
                let progressTarget = this.activePlayer.progress.find(progress => progress.type == progressType);

                let upgradedToMax = false;
                this.players.forEach(player => {
                    let target = player.progress.find(playerProgress => playerProgress.type == progressType);
                    if(target.level == 5) upgradedToMax = true;
                })

                if(!upgradedToMax && progressTarget.level == 3) {
                    if(!this.activePlayer.fightFor.includes(progressType)) {
                        if(progressType == 'paper') {
                            let lumberDots = 0;
                            this.cities
                                .filter(city => city.player == this.activePlayer)
                                .forEach(city => {
                                    this.spotTiles(city.tile, city.position).forEach(tile => {
                                        if(tile.type == 'lumber') {
                                            lumberDots += tile.number.dots;
                                        }
                                    });
                                })

                            if(lumberDots >= 4) {
                                if(this.metropolisTopLevel.paper != 5) {
                                    if(!this.activePlayer.fightFor.includes(progressType) && !this.activePlayer.giveUp.includes(progressType)) this.activePlayer.fightFor.push(progressType);
                                }
                            }
                        } else {
                            if(this.metropolisTopLevel[progressType] != 5) {
                                if(!this.activePlayer.fightFor.includes(progressType) && !this.activePlayer.giveUp.includes(progressType)) this.activePlayer.fightFor.push(progressType);
                            }
                        }
                    }
                    this.activePlayer.giveUp = this.activePlayer.giveUp.filter(obj => obj != progressType);
                }

                // when upgrading to lvl 5, everyone can give up
                if(progressTarget.level == 4) {
                    let opponentWithLevel4 = false;
                    this.players
                        .filter(player => player != this.activePlayer)
                        .forEach(player => {
                        let target = player.progress.find(playerProgress => playerProgress.type == progressType);

                        if(target.level <= 1) {
                            if(!player.giveUp.includes(progressType)) player.giveUp.push(progressType);
                        }

                        if(target.level == 4) {
                            if(!player.fightFor.includes(progressType) && !player.giveUp.includes(progressType)) {
                                player.fightFor.push(progressType);
                                this.aiChat(["time to fight!", "good luck stealing my metropolis", ":("], player, true, 20);
                            }
                            opponentWithLevel4 = true;
                        }
                    });

                    if(!opponentWithLevel4) {
                        this.activePlayer.fightFor = this.activePlayer.fightFor.filter(obj => obj != progressType);
                    }
                }

                // when upgrading to lvl 5, everyone can give up
                if(progressTarget.level == 5) {
                    this.players.forEach(player => {
                        player.fightFor = player.fightFor.filter(obj => obj != progressType);

                        if(!player.giveUp.includes(progressType)) player.giveUp.push(progressType);
                    });
                }
            },
            updateMetropolisTopLevel() {
                let progressCloth = 0;
                let progressCoin = 0;
                let progressPaper = 0;

                this.players.forEach(player => {
                    player.progress.forEach(progress => {
                        if(progress.type == 'cloth') {
                            if(progress.level > progressCloth) progressCloth = progress.level;
                        } else if(progress.type == 'coin') {
                            if(progress.level > progressCoin) progressCoin = progress.level;
                        } else if(progress.type == 'paper') {
                            if(progress.level > progressPaper) progressPaper = progress.level;
                        }
                    });
                });

                this.metropolisTopLevel = {
                    'cloth': progressCloth,
                    'coin': progressCoin,
                    'paper': progressPaper,
                };

                // basically same as metropolisTopLevel, don't want to break old code so left it
                this.opponentProgress = [
                    { type: 'cloth', level: progressCloth },
                    { type: 'coin', level: progressCoin },
                    { type: 'paper', level: progressPaper },
                ]

                this.forceUpdate++;
            },
            closeActionMenus() {
                this.actionConfirmed = false;

                this.actionTiles.forEach(tile => {
                    tile.spots.forEach(spot => {
                        spot.actions = [];
                    });
                    tile.roadSpots.forEach(roadSpot => {
                        if(!roadSpot) return;
                        roadSpot.showMenu = false;
                    });
                });

                this.forceUpdate++;
            },
            spotActions(tile, spotPosition) {

                let spot = tile.spots[spotPosition];

                let spotContent = this.spot(tile, spotPosition).content;

                if(!spot.actions.length) {

                    this.closeActionMenus();

                    if(spotContent.type != null) {

                        if(spotContent.player.color != this.activePlayer.color) {
                            if(!this.ignoreColors) {
                                return;
                            }
                        }

                        if(spotContent.type == 'house') {
                            spot.actions.push('upgrade to city');
                        } else if(spotContent.type == 'city') {

                            if(spotContent.citywall == false) {
                                spot.actions.push('build citywall');
                            }

                            if(spotContent.disabled == false) {
                                if(spotContent.metropolis == false) {
                                    // spot.actions.push('disable city');
                                }
                            } else {
                                spot.actions.push('enable city');
                            }

                            // if(spotContent.metropolis == false && spotContent.disabled == false) {
                            //     spot.actions.push('add metropolis');
                            // }

                        } else if(spotContent.type == 'knight') {

                            if(spotContent.active == false) {
                                spot.actions.push('activate knight');
                            }

                            if(spotContent.level != 3) {
                                spot.actions.push('upgrade knight')
                            }

                            if(spotContent.active == true) {
                                // if robber is on 1 of the 3 tiles around the knight
                                if(this.spotTiles(tile, spotPosition).find(tile => tile == this.robber.tile)) {

                                    // also check that the knight was not activated on this turn
                                    spot.actions.push('chase away robber');
                                }

                                if(this.spotTiles(tile, spotPosition).find(tile => tile == this.pirate.tile)) {

                                    // also check that the knight was not activated on this turn
                                    spot.actions.push('chase away pirate');
                                }
                                spot.actions.push('move knight');

                                // if(spotContent.activatedThisTurn == false || this.devMode == true) { }
                            }
                        }
                    } else {

                        // before giving build house/knight options make sure player has atleast one road of their color connecting to the spot
                        if(this.spotRoads(tile, spotPosition).find(road => road && road.player.color == this.activePlayer.color)) {
                            // if no house too close, give house option
                            spot.actions.push('build house');
                            // if(this.buildingDistance(tile, spotPosition)) { }

                            spot.actions.push('build knight');
                        }
                    }

                } else {
                    spot.actions = [];
                }
            },
            clickHighlighted(tile, spotPosition, player) {

                let spot = this.spot(tile, spotPosition);
                // -build initial house
                if(this.focusType == 'build initial house') {
                    if(this.spotTiles(tile, spotPosition).filter(spotTile => spotTile && spotTile.group == 'land').length == 0) return;

                    if(player != this.activePlayer) return;

                    if(spot.content.player == player && spot.content.type == 'city') {

                        if(!this.activePlayer.highlightedSpots.includes(spot.key)) return;

                        let target = this.cities.find(city => city.tile == tile && city.position == spotPosition);

                        this.cities = this.cities.filter(city => city != target);

                        player.pieces.cities++;
                        this.focusModeOff(player);
                        // this.log('Waiting for :playerName to build the initial city', this.activePlayer, true);
                        this.focusModeOn('build initial city', player);

                        if(this.logToConsole) console.log('Save build initial city 1')
                        this.saveGame();
                        return;
                    }

                    // filter out own houses if own house clicked on initial build
                    // if(spot.content.player == player && spot.content.type == 'house') {
                    //     this.houses = this.houses.filter(house => house.player != this.activePlayer);
                    // }

                    if(this.buildHouse(tile, spotPosition, player, true) == false) return;

                    // last player
                    if(player.buildOrder != this.players.length-1) {

                        this.highlightedRoadSpots = [];

                        this.focusModeOff(this.activePlayer);

                    }

                    this.spotRoadsAll(tile, spotPosition).forEach(roadSpot => {
                        if(!roadSpot) return;

                        if(!this.seafaresExpansion) {
                            // can't build road in water
                            if(!this.roadTiles(roadSpot.tile, roadSpot.roadSpot.position).find(roadTile => roadTile.group == 'land')) return;
                        }

                        this.highlightedRoadSpots.push(roadSpot.roadSpot.key);
                    });

                    this.highlightSpot(spot.key, this.activePlayer);

                    if(player.buildOrder == this.players.length-1) {
                        if(!this.cities.find(city => city.player == player)) {
                            // this.log('Waiting for :playerName to build the initial city', this.activePlayer, true);
                            this.focusModeOn('build initial city', player);

                            if(this.logToConsole) console.log('Save build initial city 2')
                            this.saveGame();
                            return;
                        }
                    }

                    // this.log('Waiting for :playerName to build a road', player, true);
                    this.focusModeOn('build initial road', player);

                    if(this.logToConsole) console.log('SAVE initial road 1');
                    this.saveGame();
                    return;
                // -build initial city
                } else if(this.focusType == 'build initial city') {
                    if(this.spotTiles(tile, spotPosition).filter(spotTile => spotTile && spotTile.group == 'land').length == 0) return;

                    if(player != this.activePlayer) return;

                    if(spot.content.player == player && spot.content.type == 'house' && player.buildOrder == this.players.length-1) {

                        if(!this.activePlayer.highlightedSpots.includes(spot.key)) return;

                        let target = this.houses.find(house => house.tile == tile && house.position == spotPosition);

                        this.houses = this.houses.filter(house => house != target);

                        player.pieces.houses++;
                        this.focusModeOff(player);
                        // this.log('Waiting for :playerName to build the initial house', this.activePlayer, true);
                        this.focusModeOn('build initial house', player);

                        if(this.logToConsole) console.log('SAVE build initial house 1')
                        this.saveGame();
                        return;
                    }

                    if(this.buildCity(tile, spotPosition, player, true) == false) return;

                    this.highlightedRoadSpots = [];

                    this.focusModeOff(this.activePlayer);

                    let houseFound = this.houses.find(house => house.player == this.activePlayer);

                    let piecesCheck = player.pieces.roads == 15;
                    if(this.seafaresExpansion) piecesCheck = player.pieces.roads+player.pieces.boats == 30;

                    if(houseFound && piecesCheck) {
                        this.spotRoadsAll(houseFound.tile, houseFound.position).forEach(roadSpot => {
                            if(!roadSpot) return;
                            if(!this.seafaresExpansion) {
                                // can't build road in water
                                if(!this.roadTiles(roadSpot.tile, roadSpot.roadSpot.position).find(roadTile => roadTile.group == 'land')) return;
                            }

                            this.highlightedRoadSpots.push(roadSpot.roadSpot.key);
                        });

                        this.highlightSpot(houseFound.key, this.activePlayer);
                    }

                    this.spotRoadsAll(tile, spotPosition).forEach(roadSpot => {
                        if(!roadSpot) return;

                        if(!this.seafaresExpansion) {
                            // can't build road in water
                            if(!this.roadTiles(roadSpot.tile, roadSpot.roadSpot.position).find(roadTile => roadTile.group == 'land')) return;
                        }

                        this.highlightedRoadSpots.push(roadSpot.roadSpot.key);
                    });

                    this.highlightSpot(spot.key, this.activePlayer);

                    // last player
                    if(player.buildOrder == this.players.length-1) {
                        if(!this.houses.find(house => house.player == player)) {
                            // this.log('Waiting for :playerName to build the initial house', this.activePlayer, true);
                            this.focusModeOn('build initial house', player);

                            if(this.logToConsole) console.log('SAVE build initial house 2')
                            this.saveGame();
                            return;
                        }
                    }

                    // this.log('Waiting for :playerName to build a road', player, true);
                    this.focusModeOn('build initial road', player);

                    if(this.logToConsole) console.log('SAVE initial road 2');
                    this.saveGame();
                    return;
                // -build initial road
                } else if(this.focusType == 'build initial road') {
                    if(player != this.activePlayer) return;

                    let targetHouse = this.houses.find(house => house.tile == tile && house.position == spotPosition && house.player == player);
                    let targetCity = this.cities.find(city => city.tile == tile && city.position == spotPosition && city.player == player);

                    if(targetHouse) {
                        if(!this.activePlayer.highlightedSpots.includes(spot.key)) return;

                        this.houses = this.houses.filter(house => house != targetHouse);
                        player.pieces.houses++;
                    } else if(targetCity) {
                        if(!this.activePlayer.highlightedSpots.includes(spot.key)) return;

                        this.cities = this.cities.filter(city => city != targetCity);
                        player.pieces.cities++;
                    } else {
                        return;
                    }

                    this.focusModeOff(this.activePlayer);

                    let piecesCheck = this.activePlayer.pieces.roads == 15;
                    if(this.seafaresExpansion) piecesCheck = this.activePlayer.pieces.roads+this.activePlayer.pieces.boats == 30;

                    if(piecesCheck) {
                        let houseFound = this.houses.find(house => house.player == this.activePlayer);
                        let cityFound = this.cities.find(city => city.player == this.activePlayer);

                        if(houseFound) {
                            this.spotRoadsAll(houseFound.tile, houseFound.position).forEach(roadSpot => {
                                if(!roadSpot) return;
                                // can't build road in water
                                if(!this.roadTiles(roadSpot.tile, roadSpot.roadSpot.position).find(roadTile => roadTile.group == 'land')) return;

                                this.highlightedRoadSpots.push(roadSpot.roadSpot.key);
                            });
                            this.highlightSpot(houseFound.key, this.activePlayer);
                        } else if(cityFound) {
                            this.spotRoadsAll(cityFound.tile, cityFound.position).forEach(roadSpot => {
                                if(!roadSpot) return;
                                // can't build road in water
                                if(!this.roadTiles(roadSpot.tile, roadSpot.roadSpot.position).find(roadTile => roadTile.group == 'land')) return;

                                this.highlightedRoadSpots.push(roadSpot.roadSpot.key);
                            });
                            this.highlightSpot(cityFound.key, this.activePlayer);
                        }
                    }

                    if(targetHouse) {
                        // this.log('Waiting for :playerName to build the initial house', this.activePlayer, true);
                        this.focusModeOn('build initial house', this.activePlayer);

                        if(this.logToConsole) console.log('SAVE build initial house 3')
                        this.saveGame();
                    } else if(targetCity) {
                        // this.log('Waiting for :playerName to build the initial city', this.activePlayer, true);
                        this.focusModeOn('build initial city', this.activePlayer);
                    }

                    if(this.logToConsole) console.log('Save INITIAL build initial road')
                    this.saveGame();
                    return;
                }

                if(!player.highlightedSpots.includes(spot.key)) return;

                if(this.focusType == 'rob player') {
                    spot.content.player.showOutgoingHandCards = false;
                    this.stealHandCard(spot.content.player.color);

                } else if(this.focusType == 'add metropolis') {

                    spot.content.metropolis = true;
                    spot.content.metropolisType = this.type;

                    this.updatePlayersPoints();

                } else if(this.focusType == 'select city to destroy') {

                    if(this.loadGameDataCompleted == false) return;

                    this.cities = this.cities.filter(city => city != spot.content);

                    if(spot.content.citywall) {
                        player.pieces.citywalls++;
                    }

                    player.pieces.cities++;
                    this.buildHouse(tile, spotPosition, player, true, false);

                    return this.focusModeOff(player);

                } else if(this.focusType == 'disable city') {

                    spot.content.disabled = true;

                    this.saveGame();

                } else if(this.focusType == 'build citywall') {
                    if(this.activePlayer.pieces.citywalls == 0) return;

                    spot.content.citywall = true;

                    this.activePlayer.pieces.citywalls--;

                } else if(this.focusType == 'upgrade cheap city') {
                    this.upgradeToCheapCity(spot.tile, spot.position, this.activePlayer);

                } else if(this.focusType == 'steal progress card') {
                    player.highlightedSpots = [];

                    this.highlightedPlayersOff();

                    this.log(':activePlayer played spy against :playerName (steal progress card)', spot.content.player);
                    if(!spot.content.player.ai) {
                        this.aiChat(["interesting cards", "heheheh", "always a pleasure to steal", "spy is my favorite card!"], this.activePlayer, true, 15);
                    }
                    return this.showModal('steal a progress card', this.activePlayer, spot.content.player);
                } else if(this.focusType == 'commercial harbor select opponent') {

                    this.focusModeOff(this.activePlayer);
                    this.highlightedPlayersOff();

                    return this.commercialHarborAgainstOpponent(spot.content.player);

                } else if(this.focusType == 'steal 2 hand cards') {
                    player.highlightedSpots = [];
                    this.highlightedPlayersOff();

                    this.log(':activePlayer played master merchant against :playerName', spot.content.player);
                    this.log(':activePlayer played master merchant against :playerName', spot.content.player, true);

                    if(!spot.content.player.ai) {
                        this.aiChat(["interesting cards", "heheheh", spot.content.player.name + " has some good stuff", "hmmm..."], this.activePlayer, true, 15);
                    }

                    spot.content.player.showOutgoingHandCards = false;

                    spot.content.player.handCards.forEach(cardObj => {
                        cardObj.selected = 0;
                        cardObj.slideIn = 0;
                        cardObj.slideOut = 0;
                    });

                    return this.showModal('select 2 hand cards', this.activePlayer, spot.content.player);
                } else if(this.focusType == 'knight to chase away') {

                    this.focusModeOff(this.activePlayer);

                    this.focusPiece = spot.content;

                    this.log(':activePlayer played intrigue against :playerName', spot.content.player);
                    this.log(':activePlayer played intrigue against :playerName', spot.content.player, true);
                    this.focusModeOn('move chased knight', spot.content.player);

                    this.focusPiece.tile = spot.tile;
                    this.checkedSpots = [];
                    player.highlightedSpots = [];
                    this.highlightMoveKnightSpots(tile, spotPosition, spot.content.player, true);
                    this.chaseAwayKnight(tile, spotPosition, spot.content.player, true);

                    if(this.logToConsole) console.log('SAVE move chased knight 1')
                    this.saveGame();

                    return;
                } else if(this.focusType == 'steal knight') {

                    this.log(':activePlayer played deseter against :playerName (steal knight)', spot.content.player);
                    if(!spot.content.player.ai) this.aiChat(["heheheh", "always nice to steal a knight"], this.activePlayer, true, 15);

                    this.focusModeOff(this.activePlayer);

                    this.highlightedPlayersOff();

                    player.highlightedSpots = [];

                    let playersKnights = this.knights.filter(knight => knight.player == spot.content.player);

                    if(playersKnights.length == 1) {

                        this.focusPiece = playersKnights[0];

                        this.focusPiece.player.pieces.knights.push(playersKnights[0].level);

                        this.knights = this.knights.filter(knight => knight.player != spot.content.player);

                        this.placeStolenKnight(this.activePlayer);
                        return;
                    } else {
                        this.focusModeOff(this.activePlayer);

                        playersKnights.forEach(knight => {

                            let spot = this.spot(knight.tile, knight.position);
                            this.highlightSpot(spot.key, spot.content.player);
                        });

                        // this.log('Waiting for :playerName to choose knight to give away', spot.content.player, true);
                        this.focusModeOn('select knight to give away', spot.content.player);

                        if(this.logToConsole) console.log('SAVE select knight to give away 1')
                        this.saveGame();
                    }

                    return;
                } else if(this.focusType == 'select knight to upgrade' || this.focusType == 'upgrade second knight') {

                    this.upgradeKnight(tile, spotPosition, this.activePlayer, true);

                    if(this.focusType == 'upgrade second knight') {
                        return this.focusModeOff(this.activePlayer);
                    }

                    this.focusModeOff(this.activePlayer);

                    this.highlightCanUpgradeKnights(this.activePlayer, spot.key);

                    if(player.highlightedSpots.length) {
                        if(!this.activePlayer.ai) this.showModal('upgrade second knight', this.activePlayer)
                        return this.focusModeOn('upgrade second knight', this.activePlayer)
                    }

                } else if(this.focusType == 'select knight to give away') {
                    this.focusPiece = spot.content;

                    let player = this.selectPlayer(this.focusPiece.player.color);

                    // knight back to opponents pieces
                    player.pieces.knights.push(spot.content.level);
                    // this.focusPiece.player.pieces.knights.push(spot.content.level);

                    this.knights = this.knights.filter(knight => knight != spot.content);

                    this.focusModeOff(spot.content.player);

                    this.placeStolenKnight(this.activePlayer);

                    this.saveGame();

                    return;

                } else if(this.focusType == 'place stolen knight') {
                    this.focusPiece.player = this.activePlayer;
                    this.focusPiece.tile = tile;
                    this.focusPiece.position = spotPosition;

                    this.knights.push(this.focusPiece);
                    this.scanLongestRoad(true, this.activePlayer);
                } else if(['move knight', 'move chased knight', 'try to move knight', 'move knight to break longest'].includes(this.focusType)) {

                    // if breaking the longest road
                    let breakingLongest = false;
                    let longestPlayer = null;

                    this.spotRoads(tile, spotPosition).forEach(spotRoad => {
                        if(! spotRoad) return;
                        this.longestRoad.forEach(longest => {
                            if(longest.key == spotRoad.key) {
                                breakingLongest = true;
                                longestPlayer = spotRoad.player
                            }
                        })
                    });

                    if(this.longestRoad.player != player) {
                        if(longestPlayer != null) {
                            this.scanLongestRoad(breakingLongest, longestPlayer);
                        }
                    }

                    // if player is not clicking on the knight she was about to move
                    if(spot.content != this.focusPiece) {

                        // chasing away opponents knight
                        if(spot.content.type == 'knight') {

                            this.focusPiece.active = false;

                            // chasing knight should not block it's starting position
                            // this almost never happens but I found a rare bug and added this
                            this.focusPiece.tile = null;

                            // this.activePlayer = spot.content.player;
                            this.focusModeOff(this.focusPiece.player);

                            this.checkedSpots = [];
                            this.highlightedOff(this.activePlayer);

                            // don't remove this
                            this.highlightMoveKnightSpots(tile, spotPosition, spot.content.player, true);
                            this.chaseAwayKnight(tile, spotPosition, spot.content.player, true);

                            return;

                        // moving knight to empty spot
                        } else {
                            this.focusPiece.tile = tile;
                            this.focusPiece.position = spotPosition;

                            if(this.focusType != 'move chased knight') this.focusPiece.active = false;

                            this.waitingForPlayers = this.waitingForPlayers.filter(color => color != this.focusPiece.player.color);
                            this.focusPiece.player.waitingForMe = false;

                            this.scanLongestRoad();

                            let breakingLongest = false;
                            let longestPlayer = null;

                            this.spotRoads(tile, spotPosition).forEach(spotRoad => {
                                if(! spotRoad) return;
                                this.longestRoad.forEach(longest => {
                                    if(longest.key == spotRoad.key) {
                                        breakingLongest = true;
                                        longestPlayer = spotRoad.player
                                    }
                                })
                            });

                            if(this.longestRoad.player != player) {
                                if(longestPlayer != null) {
                                    this.scanLongestRoad(breakingLongest, longestPlayer);
                                }
                            }

                            // new code
                            if(this.bePlayer != this.activePlayer) this.knights.push(this.focusPiece);

                        }
                    }

                    this.checkedSpots = [];
                }

                this.focusModeOff(player);
                this.highlightedOff(player);
            },
            aiGetCanUpgradeKnights(player) {
                let coinProgress = player.progress.find(progress => progress.type == 'coin').level;
                let maxUpgradeLevel = 2;

                if(coinProgress >= 3) maxUpgradeLevel = 3;

                let playerKnights = this.knights.filter(knight => knight.player.color == player.color);

                let knightsPlayerCanUpgrade = [];

                playerKnights.forEach(knight => {
                    if(player.pieces.knights.includes(knight.level+1) && knight.level+1 <= maxUpgradeLevel && knight.upgradedThisTurn == false) {
                        let spot = this.spot(knight.tile, knight.position);
                        knightsPlayerCanUpgrade.push(spot);
                    }
                })

                knightsPlayerCanUpgrade
                    .sort((a, b) => b.level - a.level)
                    .sort((a, b) => b.content.active - a.content.active)

                return knightsPlayerCanUpgrade;
            },
            highlightCanUpgradeKnights(player, alreadyUpgradedKey) {
                player.highlightedSpots = [];

                let coinProgress = player.progress.find(progress => progress.type == 'coin').level;
                let maxUpgradeLevel = 2;

                if(coinProgress >= 3) maxUpgradeLevel = 3;

                let playerKnights = this.knights.filter(knight => knight.player.color == player.color);

                let knightsPlayerCanUpgrade = [];
                player.highlightedSpots = [];

                playerKnights.forEach(knight => {
                    if(player.pieces.knights.includes(knight.level+1) && knight.level+1 <= maxUpgradeLevel && knight.upgradedThisTurn == false) {
                        let spot = this.spot(knight.tile, knight.position);
                        this.highlightSpot(spot.key, this.activePlayer);
                    }
                })

                if(alreadyUpgradedKey) {
                    player.highlightedSpots = player.highlightedSpots.filter(spotKey => spotKey != alreadyUpgradedKey);
                }
            },
            placeStolenKnight(player) {
                let playerRoads = this.roads.filter(road => road.player == this.activePlayer);

                playerRoads.forEach(road => {
                    this.roadSpots(road.tile, road.roadSpot).forEach(spot => {
                        if(!spot) return;
                        if(spot.content.type == undefined) {

                            this.highlightSpot(spot.key, this.activePlayer);
                        }
                    });
                })

                // nowhere to place the stolen knight
                if(player.highlightedSpots.length == 0) return;

                let findWeakerKnight = 0;

                for(let i = 0; i < this.focusPiece.level; i++) {
                    if(!this.activePlayer.pieces.knights.includes(this.focusPiece.level-i)) {
                        findWeakerKnight++;
                    } else {
                        break;
                    }
                }

                this.focusPiece.level = this.focusPiece.level-findWeakerKnight;

                if(!this.focusPiece.level > 0) return this.focusModeOff(this.activePlayer);

                // remove knight from players pieces
                let knightIndex = this.activePlayer.pieces.knights.indexOf(this.focusPiece.level);
                this.activePlayer.pieces.knights.splice(knightIndex, 1);

                // only one place to place the stolen knight
                if(player.highlightedSpots.length == 1) {
                    let lastHighlightedSpot = this.spotByKey(player.highlightedSpots[0]);

                    this.focusPiece.tile = lastHighlightedSpot.tile;
                    this.focusPiece.position = lastHighlightedSpot.position;
                    this.focusPiece.player = this.activePlayer;
                    this.knights.push(this.focusPiece);
                    this.focusModeOff(this.activePlayer);

                    this.saveGame();
                    return;
                }

                // this.log('Waiting for :playerName to place the stolen knight', this.activePlayer, true);
                this.focusModeOn('place stolen knight', this.activePlayer);

                if(this.logToConsole) console.log('SAVE place stolen knight')
                this.saveGame();
            },
            async clickHighlightedRoad(tile, roadSpotPosition) {

                if(this.activePlayer.modalType == 'build road or boat') return;

                let roadSpot = this.roadSpot(tile, roadSpotPosition);

                if(!this.highlightedRoadSpots.includes(roadSpot.key)) return;
                if(['build initial road', 'build initial house', 'build initial city'].includes(this.focusType)) {

                    // both tiles are water, build boat
                    if(this.seafaresExpansion && this.roadTiles(tile, roadSpotPosition).filter(roadTile => roadTile && ['harbor', 'water'].includes(roadTile.group)).length == 2) {
                        this.buildBoat(tile, roadSpotPosition, this.activePlayer, true, false);
                    // one tile is water, give alternative to build boat
                    } else if(this.seafaresExpansion && this.roadTiles(tile, roadSpotPosition).find(roadTile => roadTile && ['harbor', 'water'].includes(roadTile.group))) {

                        if(!this.activePlayer.ai) {
                            this.focusChoise = null;
                            this.showModal('build road or boat', this.activePlayer);
                            // this.focusModeOn('build road or boat', this.activePlayer);
                            await this.waitForFocusChoise();

                            if(this.focusChoise == 'boat') {
                                this.buildBoat(tile, roadSpotPosition, this.activePlayer, true, false);
                            } else {
                                this.buildRoad(tile, roadSpotPosition, this.activePlayer, true);
                            }

                            if(this.activePlayer.buildOrder != this.players.length-1) {
                                // this.focusModeOff(this.activePlayer);
                            }
                        // ai road/boat choise on initial build
                        } else {
                            if(['Fifth element'].includes(this.aiTactic)) {
                                this.buildBoat(tile, roadSpotPosition, this.activePlayer, true);
                            } else {
                                this.buildRoad(tile, roadSpotPosition, this.activePlayer, true);
                            }
                        }
                    // road as default
                    } else {
                        this.buildRoad(tile, roadSpotPosition, this.activePlayer, true);
                    }


                    // second last player
                    if(this.activePlayer.buildOrder == this.players.length-2) {
                        this.focusModeOff(this.activePlayer);
                        this.endTurn(this.reverseBuildOrder);
                        // this.log('Waiting for :playerName to build the initial city', this.activePlayer, true);
                        this.focusModeOn('build initial city', this.activePlayer);

                        if(this.logToConsole) console.log('Save build initial city 5')
                        this.saveGame();
                        if(this.bePlayerOnEndTurn) this.becomePlayer(this.activePlayer.color);
                    // last player
                    } else if(this.activePlayer.buildOrder == this.players.length-1) {
                        let playerRoads = this.roads.filter(road => road.player == this.activePlayer);

                        if(playerRoads.length < 2) {
                            this.roadSpots(tile, roadSpotPosition).forEach(spot => {
                                if(spot.content.type != null) {
                                    this.activePlayer.highlightedSpots = this.activePlayer.highlightedSpots.filter(spotKey => spotKey != spot.key);

                                    this.spotRoadsAll(spot.tile, spot.position).forEach(roadSpot => {
                                        if(!roadSpot) return;
                                        this.highlightedRoadSpots = this.highlightedRoadSpots.filter(key => key != roadSpot.roadSpot.key);
                                    });
                                }
                            })
                        } else {

                            this.reverseBuildOrder = true;
                            this.focusModeOff(this.activePlayer);
                            this.endTurn(this.reverseBuildOrder);
                            // this.log('Waiting for :playerName to build the initial city', this.activePlayer, true);
                            this.focusModeOn('build initial city', this.activePlayer);

                            if(this.logToConsole) console.log('Save build initial city 6')
                            this.saveGame();
                            if(this.bePlayerOnEndTurn) this.becomePlayer(this.activePlayer.color);
                        }
                    } else {
                        if(this.activePlayer.buildOrder == 0 && this.reverseBuildOrder) {
                            this.focusModeOff(this.activePlayer);
                            this.reverseBuildOrder = false;
                            this.gamePhase = 'regular';
                            this.log('Game on! First player to ' + this.playToPoints + ' points is the winner!', this.activePlayer);

                            this.players.find(player => player.color == this.bePlayer.color).ai = 0;

                            this.diceTracker.forEach(obj => obj.count = 0);
                            this.giveFeedback(this.playToPoints + " points to win, good luck!", 4000);

                            this.players
                                .filter(player => player.ai)
                                .forEach(player => {
                                this.aiChat(["gl hf", "gl", "hi"], player, true, 50);

                                if(player != this.leaderAll) this.aiChat([this.leaderAll.name + " will probably win", this.leaderAll.color + " seems strong"], player, true, 5);
                            })

                            this.updateTradeCosts();

                            // wait for a while so animations looks nice
                            setTimeout(async () => {
                                this.cities.forEach(city => {
                                    this.spotTiles(city.tile, city.position).forEach(tile => {
                                        if(tile.group == 'land' && tile.type != 'desert' && tile.type != 'goldmine') {
                                            city.player.handCards.find(cardObj => cardObj.type == tile.type).slideIn++;
                                            city.player.handCards.find(cardObj => cardObj.type == tile.type).amount++;
                                            this.incoming = true;

                                            setTimeout(() => {
                                                city.player.handCards.find(cardObj => cardObj.type == tile.type).slideIn--;
                                                this.incoming = false;
                                            }, 1000);
                                        } else if(tile.group == 'land' && tile.type != 'desert' && tile.type == 'goldmine') {
                                            city.player.goldmineAmount++;
                                        }
                                    });
                                })

                                await this.goldmineGeneratedHandCards();

                                this.updatePlayersPoints();

                                // // game on, ai play turn
                                if(this.logToConsole) console.log('SAVE gameOn');
                                this.saveGame(false, true);

                                // not sure about this one
                                if(this.activePlayer.ai) {
                                    if(this.logToConsole) console.log('AI play turn!')
                                    await this.aiPlayTurn();
                                }
                            }, 1000)
                        } else {
                            this.focusModeOff(this.activePlayer);
                            this.endTurn(this.reverseBuildOrder);
                            if(this.reverseBuildOrder) {
                                // this.log('Waiting for :playerName to build the initial city', this.activePlayer, true);
                                this.focusModeOn('build initial city', this.activePlayer);

                                if(this.logToConsole) console.log('Save build initial city 7')
                                this.saveGame();
                            } else {
                                // this.log('Waiting for :playerName to build the initial house', this.activePlayer, true);
                                this.focusModeOn('build initial house', this.activePlayer);

                                if(this.logToConsole) console.log('SAVE build initial house 4')
                                this.saveGame();
                            }
                            if(this.bePlayerOnEndTurn) this.becomePlayer(this.activePlayer.color);
                        }
                    }

                }
                if(this.focusType == 'build 1 boat') {
                    this.buildBoat(tile, roadSpotPosition, this.activePlayer, true);

                    // build the second road
                    if(this.focusType == 'build 1 boat') {
                        this.focusModeOff(this.activePlayer);
                        // if goldmines found under hidden tiles
                        // return await this.goldmineGeneratedHandCards();
                    }

                }

                if(['build 2 roads', 'build 1 road', 'place 1 road'].includes(this.focusType)) {
                    if(this.seafaresExpansion && this.canBuildRoad(tile, roadSpotPosition, this.activePlayer) && this.canBuildBoat(tile, roadSpotPosition, this.activePlayer)) {

                        this.focusChoise = null;
                        if(!this.activePlayer.ai && this.focusType != 'place 1 road') {
                            this.showModal('build road or boat', this.activePlayer);

                            await this.waitForFocusChoise();
                        } else {
                            this.focusChoise == 'road';
                        }


                        if(this.focusChoise == 'boat') {
                            this.buildBoat(tile, roadSpotPosition, this.activePlayer, true);
                        } else {
                            this.buildRoad(tile, roadSpotPosition, this.activePlayer, true);
                        }

                    } else if(this.canBuildRoad(tile, roadSpotPosition, this.activePlayer)) {
                        this.buildRoad(tile, roadSpotPosition, this.activePlayer, true);
                    } else if(this.canBuildBoat(tile, roadSpotPosition, this.activePlayer)) {
                        this.buildBoat(tile, roadSpotPosition, this.activePlayer, true);
                    }

                    // no roads left
                    if(this.activePlayer.pieces.roads == 0 && this.activePlayer.pieces.boats == 0) return this.focusModeOff(this.activePlayer);

                    // build the second road
                    if(['build 1 road', 'place 1 road'].includes(this.focusType)) {
                        this.focusModeOff(this.activePlayer);
                        // if goldmines found under hidden tiles
                        // return await this.goldmineGeneratedHandCards();

                        if(this.logToConsole) console.log('SAVE build 1 road')
                        return this.saveGame();
                    }

                    this.focusType = 'build 1 road';

                    this.highlightBuildRoadSpots(this.activePlayer);
                    if(this.seafaresExpansion) this.highlightBuildBoatSpots(this.activePlayer, true);
                } else if(this.focusType == 'move boat') {
                    this.buildBoat(tile, roadSpotPosition, this.activePlayer, true, false);
                    // if not placing the boat back on the original position
                    if(!(this.focusPiece.tile == tile && this.focusPiece.roadSpot == roadSpotPosition)) {

                        this.activePlayer.boatMovedThisTurn = true;

                        // this might be wrong
                        this.scanLongestRoad(true, this.activePlayer);
                    }

                    this.focusModeOff(this.activePlayer);

                    // await this.goldmineGeneratedHandCards();
                } else if(this.focusType == 'rob player') {
                    let clickedRoad = this.roads.find(road => road.key == roadSpot.key);

                    clickedRoad.player.showOutgoingHandCards = false;
                    this.stealHandCard(clickedRoad.player.color);
                    return this.focusModeOff(this.activePlayer);
                } else if(this.focusType == 'remove road') {
                    let clickedRoad = this.roads.find(road => road.key == roadSpot.key);

                    if(clickedRoad.player.color != this.activePlayer.color) {

                        if(clickedRoad.boat) {
                            clickedRoad.player.pieces.boats++;
                        } else {
                            clickedRoad.player.pieces.roads++;
                        }

                        this.roads = this.roads.filter(road => road != clickedRoad);
                        if(clickedRoad.player.color == this.activePlayer.color) {
                            this.log(':activePlayer played diplomat against :playerName (move road)', clickedRoad.player);
                        } else {
                            this.log(':activePlayer played diplomat against :playerName (remove road)', clickedRoad.player);
                        }
                        this.focusModeOff(this.activePlayer);
                    } else {

                        if(clickedRoad.boat) {
                            this.roads = this.roads.filter(road => road != clickedRoad);

                            this.highlightedRoadSpots = [];
                            this.highlightBuildBoatSpots(this.activePlayer);

                            this.activePlayer.pieces.boats++;
                            this.log(':activePlayer played diplomat against :playerName', clickedRoad.player);
                            // this.log('Waiting for :activePlayer to place a boat', clickedRoad.player, true);
                            this.focusType = 'build 1 boat';
                        } else {
                            this.roads = this.roads.filter(road => road != clickedRoad);

                            this.highlightedRoadSpots = [];
                            this.highlightBuildRoadSpots(this.activePlayer);

                            this.activePlayer.pieces.roads++;
                            this.log(':activePlayer played diplomat against :playerName', clickedRoad.player);
                            // this.log('Waiting for :activePlayer to place a road', clickedRoad.player, true);
                            this.focusType = 'place 1 road';
                        }
                    }

                    if(this.longestRoad.includes(clickedRoad)) {
                        this.scanLongestRoad(true, clickedRoad.player);
                    }
                }
            },
            spotActionClicked(tile, spotPosition, action) {

                // if(this.actionConfirmed != action) {
                //     return this.actionConfirmed = action;
                // }

                this.closeActionMenus();

                this.build(tile, spotPosition, action, this.activePlayer);

                this.forceUpdate++;
            },
            isBoatEnd(road) {
                let isEnd = false;
                this.roadSpots(road.tile, road.roadSpot).forEach(spot => {
                    if(!spot) return;

                    if(spot.content.type == undefined || spot.content.player.color != this.activePlayer.color) {
                        let playerRoads = this.spotRoads(spot.tile, spot.position).filter(spotRoad => spotRoad && spotRoad.boat && spotRoad.player.color == this.activePlayer.color);

                        if(playerRoads.length == 1) {
                            isEnd = true;
                        }
                    }
                })

                return isEnd;
            },
            roadSpotClicked(tile, roadSpotPosition) {
                if(this.gamePhase == 'regular' && this.devMode == false) {
                    if(this.dicesRolling == true) return;
                    if(this.dicesRollAnimation == true) return;
                }

                if(this.focusMode == true) {
                    this.clickHighlightedRoad(tile, roadSpotPosition);
                } else {
                    let clickedRoadSpot = tile.roadSpots[roadSpotPosition];

                    // this.roads.forEach(road => console.log(road));

                    let canPickUpBoat = this.roads.find(road =>
                        road &&
                        road.tile == tile &&
                        road.roadSpot == roadSpotPosition &&
                        road.player == this.activePlayer &&
                        road.builtThisTurn == false &&
                        this.isBoatEnd(road) &&
                        !this.roadTiles(road.tile, road.roadSpot).find(roadTile => roadTile == this.pirate.tile) &&
                        road.boat
                    );

                    if((canPickUpBoat && this.activePlayer.boatMovedThisTurn == false)) {
                        // pick up boat
                        this.focusPiece = canPickUpBoat;
                        this.activePlayer.pieces.boats++;
                        this.roads = this.roads.filter(road => road != canPickUpBoat);

                        this.focusModeOn('move boat', this.activePlayer);
                        return this.highlightBuildBoatSpots(this.activePlayer);
                    }

                    if(!this.canBuildRoad(tile, roadSpotPosition, this.activePlayer) && !this.canBuildBoat(tile, roadSpotPosition, this.activePlayer)) {
                        this.closeActionMenus();
                        return;
                    }


                    // toggle road menu, close all other menus before opening it
                    if(clickedRoadSpot.showMenu != true) {
                        if(this.activePlayer != this.bePlayer) return;
                        this.closeActionMenus();
                        clickedRoadSpot.showMenu = true;
                        this.lastClickedRoadSpotTiles = this.roadTiles(tile, roadSpotPosition);

                        // own connecting roads/boats when building new road/boat, for correct menus
                        this.lastClickedRoadSpotConnectingRoads = [];
                        this.lastClickedRoadSpotConnectingSpots = [];

                        this.roadSpots(tile, roadSpotPosition).forEach(spot => {
                            if(!spot) return;
                            this.lastClickedRoadSpotConnectingSpots.push(spot);
                            this.spotRoads(spot.tile, spot.position).forEach(road => {
                                // found road of players color
                                if(road && road.player.color == this.activePlayer.color) {
                                    this.lastClickedRoadSpotConnectingRoads.push(road);
                                }
                            });
                        });

                    } else {
                        clickedRoadSpot.showMenu = false;
                    }

                    this.forceUpdate++;
                }
            },
            async roadSpotActionClicked(tile, roadSpotPosition) {
                // if(this.actionConfirmed != 'road') {
                //     return this.actionConfirmed = 'road';
                // }

                this.closeActionMenus();

                await this.buildRoad(tile, roadSpotPosition, this.activePlayer);

                // await this.goldmineGeneratedHandCards();

                this.forceUpdate++;
            },
            async boatSpotActionClicked(tile, roadSpotPosition) {
                // if(this.actionConfirmed != 'boat') {
                //     return this.actionConfirmed = 'boat';
                // }

                this.closeActionMenus();

                await this.buildBoat(tile, roadSpotPosition, this.activePlayer);

                // await this.goldmineGeneratedHandCards();

                this.forceUpdate++;
            },
            build(tile, spotPosition, action, player, free = this.buildEverythingForFree) {
                let spotContent = this.spot(tile, spotPosition).content;

                // at least one of the surrounding tiles has to be land
                if(!this.spotTiles(tile, spotPosition).filter(tile => tile.group == 'land').length) return;

                // if spot where you are building is not empty
                if(spotContent.type != null) {

                    // if not your color
                    if(spotContent.player.color != player.color) {
                        if(!this.ignoreColors) return;
                    }

                    if(spotContent.type == 'house') {
                        if(action == 'upgrade to city') {
                            this.upgradeToCity(tile, spotPosition);
                        }
                    }

                    if(spotContent.type == 'city') {

                        if(action == 'build citywall') {
                            this.buildCitywall(tile, spotPosition);
                        }

                        if(action == 'disable city') {
                            if(spotContent.metropolis == false) {
                                spotContent.disabled = true;

                                this.saveGame();
                            }
                        }

                        if(action == 'enable city') {

                            if(!this.pay(player, [ { type: 'lumber', amount: 1 }, { type: 'ore', amount: 1 } ]) && !free) return this.giveFeedback("You can't afford to enable your city.");

                            spotContent.disabled = false;

                            this.saveGame();
                        }

                        if(action == 'add metropolis') {
                            spotContent.metropolis = true;
                        }
                    }

                    if(spotContent.type == 'knight') {
                        if(action == 'activate knight') {

                            this.activateKnight(tile, spotPosition);

                        } else if(action == 'upgrade knight') {

                            this.upgradeKnight(tile, spotPosition, this.activePlayer);

                        } else if(action == 'chase away robber') {
                            if(spotContent.activatedThisTurn == true) return this.giveFeedback("Knight is activated this turn.");

                            spotContent.active = false;

                            this.focusModeOn('move robber only', this.bePlayer);

                            if(this.logToConsole) console.log('SAVE moveRobberOnly');
                            this.saveGame(false, true);
                        } else if(action == 'chase away pirate') {
                            if(spotContent.activatedThisTurn == true) return this.giveFeedback("Knight is activated this turn.");

                            spotContent.active = false;

                            this.focusModeOn('move pirate', this.bePlayer);
                        } else if(['move knight', 'try to move knight', 'move knight to break longest'].includes(action)) {
                            if(spotContent.activatedThisTurn == true && this.devMode == false) return this.giveFeedback("Knight is activated this turn.");

                            let spot = this.spot(tile, spotPosition);

                            let theKey = 'k' + tile.row + '' + tile.position + '' + spotPosition;
                            this.highlightSpot(theKey, this.activePlayer);

                            this.focusPiece = spotContent;
                            this.checkedSpots = [];

                            this.highlightMoveKnightSpots(tile, spotPosition, this.activePlayer);

                            this.focusModeOn(action, this.focusPiece.player);
                        }
                    }
                } else {

                    if(action == 'build knight') {

                        this.buildKnight(tile, spotPosition, player);

                    } else if(action == 'build house') {

                        this.buildHouse(tile, spotPosition, player);

                    }
                }

                this.updateTradeCosts();
                this.updatePlayersPoints();
            },
            buildKnight(tile, spotPosition, player, free = this.buildEverythingForFree) {

                if(this.focusMode == true) return;

                // check so knight has connecting road in players color, this check is needed for houses when first building-phase is over
                if(!this.spotRoads(tile, spotPosition).filter(road => road && road.player.color == player.color).length) return;

                let knightIndex = player.pieces.knights.indexOf(1);

                if(knightIndex == -1) {
                    this.giveFeedback("No level 1 knights left.");
                    return false;
                }

                if(!this.pay(player, [ { type: 'wool', amount: 1 }, { type: 'ore', amount: 1 } ]) && !free) return this.giveFeedback("You can't afford a knight.");

                this.knights.push({ tile: tile, position: spotPosition, player: player, type: 'knight', level: 1, active: false, activatedThisTurn: false, upgradedThisTurn: false });

                player.pieces.knights.splice(knightIndex, 1)

                let breakingLongest = false;
                let longestPlayer = null;

                this.spotRoads(tile, spotPosition).forEach(spotRoad => {
                    if(! spotRoad) return;
                    this.longestRoad.forEach(longest => {
                        if(longest.key == spotRoad.key) {
                            breakingLongest = true;
                            longestPlayer = spotRoad.player
                        }
                    })
                });

                if(this.longestRoad.player != player) {
                    if(longestPlayer != null) {
                        this.scanLongestRoad(breakingLongest, longestPlayer);
                    }
                }

                if(this.logToConsole) console.log('SAVE buildKnight');
                // this.saveGame();
            },
            async buildHouse(tile, spotPosition, player, free = this.buildEverythingForFree, animate = true) {
                if(!this.buildingDistance(tile, spotPosition)) {
                    this.giveFeedback("Can't build next to another building.");
                    return false;
                }

                if(this.spot(tile, spotPosition).content.type != undefined) return false;

                // can't build in the water
                if(!this.spotTiles(tile, spotPosition).find(houseTile => houseTile.group == 'land')) return false;

                if(!free) {
                    // this inside free because war can destroy city when 0 houses left
                    if(player.pieces.houses <= 0) return this.giveFeedback("You have 0 houses left.");

                    if(!this.pay(player, [ { type: 'lumber', amount: 1 }, { type: 'brick', amount: 1 }, { type: 'grain', amount: 1 }, { type: 'wool', amount: 1 } ])) return this.giveFeedback("You can't afford a house.");
                }

                this.buildingAnimation = true;

                let extraIslandPoint = false;

                // connecting own boat found
                if(this.spotRoads(tile, spotPosition).find(spotRoad => spotRoad && spotRoad.player == this.activePlayer && spotRoad.boat)) {
                    // no house found on the island
                    if(!this.buildingFoundOnNewIsland(tile, spotPosition)) {
                        if(player.ai) {
                            this.aiChat(["yay!", "yess"], player, true, 10);
                        }
                        player.pointCards.push({ label: 'island', points: 1 });
                        extraIslandPoint = true;
                    }
                }

                this.houses.push({ tile: tile, position: spotPosition, player: player, type: 'house', group: 'building', buildAnimation: animate, key: this.spot(tile, spotPosition).key, 'extraIslandPoint': extraIslandPoint });

                setTimeout(() => {
                    if(this.houses.find(house => house.key == this.spot(tile, spotPosition).key)) {
                        this.houses.find(house => house.key == this.spot(tile, spotPosition).key).buildAnimation = false;
                    }
                    this.buildingAnimation = false;
                }, 600);

                player.pieces.houses--;
                if(player.pieces.houses == 0) {
                    this.log(':playerName has 0 houses left', this.activePlayer);
                }

                let breakingLongest = false;

                // if breaking the longest road
                this.spotRoads(tile, spotPosition).forEach(spotRoad => {
                    if(! spotRoad) return;

                    this.longestRoad.forEach(longest => {
                        if(longest.key == spotRoad.key) breakingLongest = true;
                    })
                    // if(this.longestRoad.includes(spotRoad)) {
                    //     breakingLongest = true;
                    // }
                });

                this.scanLongestRoad(breakingLongest, this.activePlayer);

                this.updateTradeCosts();

                if(this.logToConsole) console.log('SAVE BuildHouse');
                if(this.focusType != 'select city to destroy') {
                    this.saveGame();
                }
            },
            buildCity(tile, spotPosition, player, free = this.buildEverythingForFree) {
                if(!this.buildingDistance(tile, spotPosition)) {
                    this.giveFeedback("Can't build next to another building.");
                    return false;
                }

                if(this.spot(tile, spotPosition).content.type != undefined) return false;

                if(!this.spotTiles(tile, spotPosition).find(cityTile => cityTile.group == 'land')) return false;

                if(player.pieces.cities == 0) return this.giveFeedback("You have 0 cities left.");

                if(!this.pay(player, [ { type: 'grain', amount: 2 }, { type: 'ore', amount: 3 } ]) && !free) return this.giveFeedback("You can't afford a city.");

                this.buildingAnimation = true;

                this.cities.push({ tile: tile, position: spotPosition, player: player, type: 'city', group: 'building', citywall: false, disabled: false, metropolis: false, buildAnimation: true, key: this.spot(tile, spotPosition).key });

                setTimeout(() => {
                    if(this.cities.find(city => city.key == this.spot(tile, spotPosition).key)) {
                        this.cities.find(city => city.key == this.spot(tile, spotPosition).key).buildAnimation = false;
                    }
                    this.buildingAnimation = false;
                }, 600);

                player.pieces.cities--;
                if(player.pieces.cities == 0) {
                    this.log(':playerName has 0 cities left', this.activePlayer);
                }

                if(this.logToConsole) console.log('SAVE BuildCity');
                this.saveGame();
            },
            upgradeToCity(tile, spotPosition, free = this.buildEverythingForFree, animate = false) {
                let spotContent = this.spot(tile, spotPosition).content;

                if(this.activePlayer.pieces.cities == 0) return this.giveFeedback("You have 0 cities left.");

                if(!this.pay(this.activePlayer, [ { type: 'grain', amount: 2 }, { type: 'ore', amount: 3 } ]) && !free) return this.giveFeedback("You can't afford a city.");

                let houseIndex = this.houses.findIndex(house => { return house.tile == tile && house.position == spotPosition})

                this.houses.splice(houseIndex, 1);

                this.activePlayer.pieces.houses++;

                this.buildingAnimation = true;

                this.cities.push({ tile: tile, position: spotPosition, player: this.activePlayer, type: 'city', group: 'building', citywall: false, disabled: false, metropolis: false, buildAnimation: animate, key: this.spot(tile, spotPosition).key });

                setTimeout(() => {
                    if(this.cities.find(city => city.key == this.spot(tile, spotPosition).key)) {
                        this.cities.find(city => city.key == this.spot(tile, spotPosition).key).buildAnimation = false;
                    }
                    this.buildingAnimation = false;
                }, 600);

                this.activePlayer.pieces.cities--;
                if(this.activePlayer.pieces.cities == 0) {
                    this.log(':playerName has 0 cities left', this.activePlayer);
                }

                if(this.logToConsole) console.log('SAVE upgradeCity');
                this.saveGame();
            },
            upgradeToCheapCity(tile, spotPosition) {
                let spotContent = this.spot(tile, spotPosition).content;

                let houseIndex = this.houses.findIndex(house => { return house.tile == tile && house.position == spotPosition})

                this.houses.splice(houseIndex, 1);

                this.activePlayer.pieces.houses++;

                this.buildingAnimation = true;

                this.cities.push({ tile: tile, position: spotPosition, player: this.activePlayer, type: 'city', group: 'building', citywall: false, disabled: false, metropolis: false, buildAnimation: false, key: this.spot(tile, spotPosition).key });

                setTimeout(() => {
                    if(this.cities.find(city => city.key == this.spot(tile, spotPosition).key)) {
                        this.cities.find(city => city.key == this.spot(tile, spotPosition).key).buildAnimation = false;
                    }
                    this.buildingAnimation = false;
                }, 600);

                this.activePlayer.pieces.cities--;
                if(this.activePlayer.pieces.cities == 0) {
                    this.log(':playerName has 0 cities left', this.activePlayer);
                }

                this.updatePlayersPoints();

                if(this.logToConsole) console.log('SAVE upgradeCheapCity');
                this.saveGame();
            },
            activateKnight(tile, spotPosition, free = this.buildEverythingForFree) {

                let spotContent = this.spot(tile, spotPosition).content;

                if(spotContent.type != 'knight') return false;

                if(!this.pay(this.activePlayer, [ { type: 'grain', amount: 1 } ]) && !free) {
                    this.giveFeedback("You can't afford activating a knight.");
                    return false;
                }

                spotContent.active = true;
                spotContent.activatedThisTurn = true;

                if(this.firstBoatRound && this.activePlayer.ai) {
                    this.aiChat(["yes! :)", "feels good man"], this.activePlayer, true, 5);
                }

                if(this.logToConsole) console.log('SAVE activateKnight');
                this.saveGame();
            },
            upgradeKnight(tile, spotPosition, player, free = this.buildEverythingForFree) {

                let spotContent = this.spot(tile, spotPosition).content;

                if(spotContent.upgradedThisTurn == true && this.devMode == false) {
                    this.giveFeedback("Knight was already upgraded this turn.");
                    return false;
                }

                let upgrade_from = spotContent.level;

                if(upgrade_from == 3) return false;

                if(upgrade_from == 2) {
                    if(player.progress.find(progress => progress.type == 'coin').level < 3 && this.devMode == false) {
                        this.giveFeedback("Upgrade coin to level 3 first.")
                        return false;
                    }
                }

                // check so player has knight which is 1 level stronger
                let upgrade_to = player.pieces.knights.indexOf(upgrade_from+1);

                // player didn't have
                if(upgrade_to == -1) return this.giveFeedback("No level " + upgrade_from+1 + " knights available.");

                if(!free) {
                    if(!this.pay(player, [ { type: 'wool', amount: 1 }, { type: 'ore', amount: 1 } ])) return this.giveFeedback("You can't afford a knight upgrade.");
                }

                // delete it from player, build it
                player.pieces.knights.splice(upgrade_to, 1);

                // give the weaker knight back to player
                player.pieces.knights.push(upgrade_from);

                spotContent.level += 1;
                spotContent.upgradedThisTurn = true;

                if(this.logToConsole) console.log('SAVE upgradeKnight');
                this.saveGame();
            },
            getBuildRoadSpots(player) {
                let buildRoadSpots = [];

                let playerRoads = this.roads.filter(road => road.player.color == player.color);

                playerRoads
                    .filter(playerRoad => !playerRoad.boat)
                    .forEach(playerRoad => {
                    this.roadSpots(playerRoad.tile, playerRoad.roadSpot).forEach(spot => {
                        // if blocked by opponent
                        if(spot.content.type != undefined) {
                            if(spot.content.player.color != playerRoad.player.color) return;
                        }

                        this.spotRoadsAll(spot.tile, spot.position).forEach(roadSpot => {
                            if(!roadSpot) return;

                            // road exists
                            if(this.roads.filter(builtRoad => builtRoad.key == roadSpot.roadSpot.key).length) return;

                            // can't build in the water
                            if(!this.roadTiles(roadSpot.tile, roadSpot.roadSpot.position).find(roadTile => roadTile.group == 'land')) return;

                            if(!buildRoadSpots.filter(buildRoadSpot => buildRoadSpot.roadSpot.key == roadSpot.roadSpot.key).length) {
                                buildRoadSpots.push(roadSpot);
                            }
                        });
                    });
                });

                let playerBuildings = this.buildings.filter(building => building.player == player);

                playerBuildings.forEach(building => {
                    this.spotRoadsAll(building.tile, building.position).forEach(roadSpot => {
                        if(!roadSpot) return;

                        if(this.roads.filter(builtRoad => builtRoad.key == roadSpot.roadSpot.key).length) return;

                        if(!this.roadTiles(roadSpot.tile, roadSpot.roadSpot.position).find(roadTile => roadTile.group == 'land')) return;

                        // this.highlightedRoadSpots.push(roadSpot.roadSpot.key);
                        if(!buildRoadSpots.filter(buildRoadSpot => buildRoadSpot.roadSpot.key == roadSpot.roadSpot.key).length) {
                            buildRoadSpots.push(roadSpot);
                        }
                    })
                })

                return buildRoadSpots;
            },
            getBuildBoatSpots(player) {
                let buildRoadSpots = [];

                let playerRoads = this.roads.filter(road => road.player.color == player.color && road.boat);

                playerRoads.forEach(playerRoad => {
                    this.roadSpots(playerRoad.tile, playerRoad.roadSpot).forEach(spot => {
                        // if blocked by opponent
                        if(spot.content.type != undefined) {
                            if(spot.content.player.color != playerRoad.player.color) return;
                        }

                        this.spotRoadsAll(spot.tile, spot.position).forEach(roadSpot => {
                            if(!roadSpot) return;
                            if(!roadSpot.tile) return;

                            // road exists
                            if(this.roads.filter(builtRoad => builtRoad.key == roadSpot.roadSpot.key).length) return;

                            // can't build on land
                            if(!this.roadTiles(roadSpot.tile, roadSpot.roadSpot.position).find(roadTile => roadTile && ['harbor', 'water'].includes(roadTile.group))) return;

                            if(!this.canBuildBoat(roadSpot.tile, roadSpot.roadSpot.position, this.activePlayer)) return;

                            if(!buildRoadSpots.filter(buildRoadSpot => buildRoadSpot.roadSpot.key == roadSpot.roadSpot.key).length) {
                                buildRoadSpots.push(roadSpot);
                            }
                        });
                    });
                });

                let playerBuildings = this.buildings.filter(building => building.player == player);

                playerBuildings.forEach(building => {
                    this.spotRoadsAll(building.tile, building.position).forEach(roadSpot => {
                        if(!roadSpot) return;

                        if(this.roads.filter(builtRoad => builtRoad.key == roadSpot.roadSpot.key).length) return;

                        if(!this.roadTiles(roadSpot.tile, roadSpot.roadSpot.position).find(roadTile => ['harbor', 'water'].includes(roadTile.group))) return;

                        if(!this.canBuildBoat(roadSpot.tile, roadSpot.roadSpot.position, this.activePlayer)) return;

                        // this.highlightedRoadSpots.push(roadSpot.roadSpot.key);
                        if(!buildRoadSpots.filter(buildRoadSpot => buildRoadSpot.roadSpot.key == roadSpot.roadSpot.key).length) {
                            buildRoadSpots.push(roadSpot);
                        }
                    })
                })

                return buildRoadSpots;
            },
            highlightBuildRoadSpots(player) {
                this.highlightedRoadSpots = [];

                this.getBuildRoadSpots(player).forEach(road => this.highlightedRoadSpots.push(road.roadSpot.key))
            },
            highlightBuildBoatSpots(player, dontResetHighlighted = false) {
                // reset as default
                if(!dontResetHighlighted) {
                    this.highlightedRoadSpots = [];
                }

                this.getBuildBoatSpots(player).forEach(road => this.highlightedRoadSpots.push(road.roadSpot.key))

            },
            highlightMoveKnightSpots(tile, spotPosition, player, chasedAway = false) {
                // find all the players roads from that spot
                let playerSpotRoads = this.spotRoads(tile, spotPosition).filter(road => road && road.player.color == player.color);

                if(!playerSpotRoads.length) return;

                // loop the players roads
                playerSpotRoads.forEach(road => {

                    // loop the roads spots
                    this.roadSpots(road.tile, road.roadSpot).forEach(spot => {
                        let theSpotKey = 'k' + spot.tile.row + '' + spot.tile.position + '' + spot.position;

                        let buildingFound = this.buildings.find(building => building.key == theSpotKey);
                        let knightFound = this.knights.find(knight => knight.tile == spot.tile && knight.position == spot.position);

                        if(!this.checkedSpots.includes(theSpotKey)) {

                            // if opponents building is blocking, stop searching
                            if(buildingFound && spot.content.player.color != player.color) return;

                            // opponents knight found
                            if(knightFound && spot.content.player.color != player.color) {
                                // if stronger knight, stop searching, don't highlight it
                                // if knight is chased away, only highlight empty spots
                                if(spot.content.level >= this.focusPiece.level || chasedAway == true) {
                                    return;
                                // if weaker knight, highligt it and then stop searching
                                // you can chase it away, but you can't ignore it and just move past it
                                } else {
                                    this.highlightSpot(theSpotKey, player);
                                    return;
                                }
                            }

                            // Add spot key to the checkedSpots array
                            this.checkedSpots.push(spot.key);

                            // only highlight spots without content
                            if(!buildingFound && !knightFound) {
                                this.highlightSpot(spot.key, player);
                            }

                            // spot was not checked, call self function and do everyting for this spot
                            this.highlightMoveKnightSpots(spot.tile, spot.position, player, chasedAway);
                        }
                    })
                })
            },
            chaseAwayKnight(tile, spotPosition, player, chasedAway = false) {
                let spot = this.spot(tile, spotPosition);

                if(player.highlightedSpots.length == 0) {

                    let target = this.knights.find(knight => knight.tile == tile && knight.position == spotPosition);
                    player.pieces.knights.push(target.level);

                    this.knights = this.knights.filter(knight => knight != target);

                    this.focusPiece.tile = tile;
                    this.focusPiece.position = spotPosition;
                    this.focusPiece.active = false;
                    this.focusModeOff(this.focusPiece.player);
                    this.scanLongestRoad();
                    return;
                } else if(player.highlightedSpots.length == 1) {

                    this.focusPiece.tile = tile;
                    this.focusPiece.position = spotPosition;

                    let lastHighlightedSpot = this.spotByKey(player.highlightedSpots[0]);

                    spot.content.tile = lastHighlightedSpot.tile;
                    spot.content.position = lastHighlightedSpot.position;

                    this.focusModeOff(this.focusPiece.player);

                    this.scanLongestRoad();
                    return;
                } else {

                    this.focusModeOn('move chased knight', spot.content.player);

                    this.focusPiece.tile = tile;
                    this.focusPiece.position = spotPosition;

                    this.focusPiece = spot.content;

                    if(! this.waitingForPlayers.includes(spot.content.player.color)) {
                        this.waitingForPlayers.push(spot.content.player.color);
                    }
                    spot.content.player.waitingForMe = true;

                    this.checkedSpots = [];
                    this.highlightedOff(this.activePlayer);

                    this.highlightMoveKnightSpots(spot.tile, spot.content.position, spot.content.player, true);

                    spot.content.position = null;

                    if(this.logToConsole) console.log('SAVE move chased knight 2')
                    this.saveGame();

                    // this.scanLongestRoad();

                }
            },
            buildCitywall(tile, spotPosition, free = this.buildEverythingForFree) {

                let spotContent = this.spot(tile, spotPosition).content;

                if(this.activePlayer.pieces.citywalls == 0) return this.giveFeedback("You have 0 citywalls left.");

                // spot can't be empty
                if(spotContent.type != null) {

                    // if spot is not city
                    if(spotContent.type != 'city') return;

                    if(this.pay(this.activePlayer, [ { type: 'brick', amount: 2 } ]) || free == true) {
                        spotContent.citywall = true;

                        this.activePlayer.pieces.citywalls--;
                    }
                }

                if(! this.gamePhase != 'initial build') {
                    if(this.logToConsole) console.log('SAVE buildCitywall');
                    if(! this.activePlayer.ai) this.saveGame();
                }
            },
            async buildRoad(tile, roadSpot, player, free = this.buildEverythingForFree) {

                if(!this.canBuildRoad(tile, roadSpot, player)) return;

                if(player.pieces.roads == 0) return this.giveFeedback("You have 0 roads left.");

                if(!free) {
                    if(!this.pay(player, [ { type: 'lumber', amount: 1 }, { type: 'brick', amount: 1 } ])) return this.giveFeedback("You can't afford a road.");
                }

                this.roads.push({ tile: tile, roadSpot: roadSpot, player: player, buildAnimation: true, key: this.generateKey(tile, roadSpot), boat: false, builtThisTurn: true, });

                this.roadSpots(tile, roadSpot).forEach(spot => {
                    if(!spot) return;
                    this.spotTiles(spot.tile, spot.position).forEach(spotTile => {
                        if(!spotTile) return;
                        if(spotTile.hidden) {
                            spotTile.hidden = false;

                            if([2, 12].includes(spotTile.number.value)) {
                                spotTile.number.dots = 1;
                                spotTile.number.exponentialDots = 0.01;
                            } else if([3, 11].includes(spotTile.number.value)) {
                                spotTile.number.dots = 2;
                                spotTile.number.exponentialDots = 1.30;
                            } else if([4, 10].includes(spotTile.number.value)) {
                                spotTile.number.dots = 3;
                                spotTile.number.exponentialDots = 3.70;
                            } else if([5, 9].includes(spotTile.number.value)) {
                                spotTile.number.dots = 4;
                                spotTile.number.exponentialDots = 5.10;
                            } else if([6, 8].includes(spotTile.number.value)) {
                                spotTile.number.dots = 5;
                                spotTile.number.exponentialDots = 7.20;
                            }

                            if(!['goldmine', 'desert'].includes(spotTile.type)) {
                                this.activePlayer.handCards.find(handCardObj => handCardObj.type == spotTile.type).amount++;
                                this.activePlayer.handCards.find(handCardObj => handCardObj.type == spotTile.type).slideIn++;

                                setTimeout(() => {
                                    this.activePlayer.handCards.find(handCardObj => handCardObj.type == spotTile.type).slideIn--;
                                }, 1000)
                            } else if(spotTile.type == 'goldmine') {
                                this.activePlayer.goldmineAmount++;
                            }
                        }
                    });
                });

                setTimeout(() => {
                    if(this.roads.find(road => road.key == this.generateKey(tile, roadSpot))) {
                        this.roads.find(road => road.key == this.generateKey(tile, roadSpot)).buildAnimation = false;
                    }
                }, 1000);

                player.pieces.roads--;
                if(player.pieces.roads == 0) {
                    this.log(':playerName has 0 roads left', this.activePlayer);
                }

                this.scanLongestRoad();

                if(this.gamePhase == 'regular' && !this.focusMode) {
                    await this.goldmineGeneratedHandCards();
                }

                if(this.gamePhase == 'regular' && ['build 1 road', 'place 1 road'].includes(this.focusType)) {
                    setTimeout(async () => {
                        await this.goldmineGeneratedHandCards();
                    }, 1000);
                }
                if(this.gamePhase == 'initial build') this.updateTradeCosts();

                if(this.gamePhase == 'initial build') {
                    setTimeout(async () => {
                        if(this.logToConsole) console.log('SAVE buildRoad initial');
                        if(! this.activePlayer.ai) this.saveGame();
                    }, 1000);

                } else {
                    if(! ['build 1 road', 'build 2 roads'].includes(this.focusType)) {
                        if(this.logToConsole) console.log('SAVE buildRoad');
                        this.saveGame();

                    }
                }
            },
            async buildBoat(tile, roadSpot, player, free = this.buildEverythingForFree, builtThisTurn = true) {

                if(!this.canBuildBoat(tile, roadSpot, player)) return;

                if(player.pieces.boats == 0) return this.giveFeedback("You have 0 boats left.");

                if(!free) {
                    if(!this.pay(player, [ { type: 'lumber', amount: 1 }, { type: 'wool', amount: 1 } ])) return this.giveFeedback("You can't afford a boat.");
                }

                if(this.devMode) builtThisTurn = false;

                this.roads.push({ tile: tile, roadSpot: roadSpot, player: player, buildAnimation: true, key: this.generateKey(tile, roadSpot), boat: true, builtThisTurn: builtThisTurn, });

                this.roadSpots(tile, roadSpot).forEach(spot => {
                    if(!spot) return;
                    this.spotTiles(spot.tile, spot.position).forEach(spotTile => {
                        if(!spotTile) return;
                        if(spotTile.hidden) {
                            spotTile.hidden = false;

                            if([2, 12].includes(spotTile.number.value)) {
                                spotTile.number.dots = 1;
                                spotTile.number.exponentialDots = 0.01;
                            } else if([3, 11].includes(spotTile.number.value)) {
                                spotTile.number.dots = 2;
                                spotTile.number.exponentialDots = 1.30;
                            } else if([4, 10].includes(spotTile.number.value)) {
                                spotTile.number.dots = 3;
                                spotTile.number.exponentialDots = 3.70;
                            } else if([5, 9].includes(spotTile.number.value)) {
                                spotTile.number.dots = 4;
                                spotTile.number.exponentialDots = 5.10;
                            } else if([6, 8].includes(spotTile.number.value)) {
                                spotTile.number.dots = 5;
                                spotTile.number.exponentialDots = 7.20;
                            }

                            if(!['goldmine', 'desert'].includes(spotTile.type)) {
                                this.activePlayer.handCards.find(handCardObj => handCardObj.type == spotTile.type).amount++;
                                this.activePlayer.handCards.find(handCardObj => handCardObj.type == spotTile.type).slideIn++;

                                setTimeout(() => {
                                    this.activePlayer.handCards.find(handCardObj => handCardObj.type == spotTile.type).slideIn--;
                                }, 1000)
                            } else if(spotTile.type == 'goldmine') {
                                this.activePlayer.goldmineAmount++;
                            }
                        }
                    });
                });

                setTimeout(() => {
                    if(this.roads.find(road => road.key == this.generateKey(tile, roadSpot))) {
                        this.roads.find(road => road.key == this.generateKey(tile, roadSpot)).buildAnimation = false;
                    }
                }, 1000);

                player.pieces.boats--;
                if(player.pieces.boats == 0) {
                    this.log(':playerName has 0 boats left', this.activePlayer);
                }

                this.scanLongestRoad();

                if(this.gamePhase == 'regular' && !this.focusMode) {
                    await this.goldmineGeneratedHandCards();
                }
                if(this.gamePhase == 'regular' && ['build 1 boat', 'place 1 road'].includes(this.focusType)) {
                    setTimeout(async () => {
                        await this.goldmineGeneratedHandCards();
                    }, 1000);
                }
            },
            playersLongest(player) {
                let results = [];

                this.roads.filter(road => road.player == player).forEach(road => {
                    // make the longest-road check from one of the 2 spots connected to the road
                    let firstRoadSpot = this.roadSpots(road.tile, road.roadSpot)[0];

                    // empty the longest result before checking
                    this.playersLongestRoad = [];

                    // check all the possible paths
                    this.roadLengthFromSpot(firstRoadSpot.tile, firstRoadSpot.position, road.player);

                    // store the best result for later comparison with opponents
                    results.push(this.playersLongestRoad)
                });

                results.sort((a, b) => b.length - a.length);

                if(!results.length) return 0;

                return results[0];
            },
            canBuildRoad(tile, roadSpot, player) {

                let canBuild = false;

                // spot where you try to build must be empty
                if(this.roads.find(road => road.tile == tile && road.roadSpot == roadSpot)) return;

                // at least one of the neighbour tiles has to be land
                if(!this.roadTiles(tile, roadSpot).filter(tile => tile.group == 'land').length) return;

                // get the 2 connecting spots for the road you are about to build
                let roadSpots = this.roadSpots(tile, roadSpot);

                roadSpots.forEach(spot => {

                    let content = this.spot(spot.tile, spot.position).content;

                    // spot is not empty
                    if(content.type != null) {
                        // spot contains house/city or knight of players color
                        if(content.player.color == player.color) {
                            canBuild = true;
                        }
                    // spot is not blocked by other players house/city or knight
                    } else {
                        this.spotRoads(spot.tile, spot.position).forEach(road => {
                            // found road of players color
                            if(road && road.player.color == player.color && !road.boat) {
                                canBuild = true;
                            }
                        });
                    }
                });

                return canBuild;
            },
            canBuildBoat(tile, roadSpot, player) {

                let canBuild = false;

                // spot where you try to build must be empty
                if(this.roads.find(road => road.tile == tile && road.roadSpot == roadSpot)) return;

                // at least one of the neighbour tiles has to be water
                if(!this.roadTiles(tile, roadSpot).filter(tile => ['harbor', 'water'].includes(tile.group)).length) return;

                if(this.roadTiles(tile, roadSpot).filter(tile => tile == this.pirate.tile).length) return;

                // get the 2 connecting spots for the road you are about to build
                let roadSpots = this.roadSpots(tile, roadSpot);

                roadSpots.forEach(spot => {

                    let content = this.spot(spot.tile, spot.position).content;

                    // spot is not empty
                    if(content.type != null) {
                        // spot contains house/city or knight of players color

                        // has to be building (or connecting boat)
                        if(content.player.color == player.color && (content.group == 'building')) {
                            canBuild = true;
                        }
                    // spot is not blocked by other players house/city or knight
                    }

                    this.spotRoads(spot.tile, spot.position).forEach(road => {
                        // found boat of players color
                        if(road && road.player.color == player.color && road.boat == true) {
                            canBuild = true;
                        }
                    });

                });

                return canBuild;
            },
            buildingDistance(tile, spotPosition, allowOwnSpotContent = false) {
                // now check 3 closest spots, position-1, position+1 and nextActionTile.connectingSpotPosition
                // check if their group is 'building'
                // 'building' contains 'house' or 'city' but not 'knight'

                // make sure clicked spot is empty too
                if(allowOwnSpotContent == false) {
                    if(this.spot(tile, spotPosition).content.type != undefined) return false;
                }

                let nextSpotsContent = this.spot(tile, this.nextSpot(spotPosition)).content;

                if(nextSpotsContent) {
                    if(nextSpotsContent.group == 'building') {
                        return false;
                    }
                    if(['house', 'city'].includes(nextSpotsContent.type)) {
                        return false;
                    }
                }

                let previousSpotsContent = this.spot(tile, this.previousSpot(spotPosition)).content;

                if(previousSpotsContent) {
                    if(previousSpotsContent.group == 'building') {
                        return false;
                    }
                    if(['house', 'city'].includes(previousSpotsContent.type)) {
                        return false;
                    }
                }

                let nextActionTile = this.actionTileBySpotPosition(tile, spotPosition);

                if(nextActionTile && nextActionTile.actionTile) {

                    let nextActionTileSpotsContent = this.spot(nextActionTile, nextActionTile.connectingSpotPosition).content;

                    if(nextActionTileSpotsContent) {
                        if(nextActionTileSpotsContent.group == 'building') {
                            return false;
                        }
                        if(['house', 'city'].includes(nextActionTileSpotsContent.type)) {
                            return false;
                        }
                    }
                }

                return true;
            },
            becomePlayer(color) {
                if(this.gamePhase == 'game over') return;

                let player = this.selectPlayer(color);

                // dev now
                // this.activePlayer.offerOpponents = true;
                if(this.focusType != 'trade cards') {
                    // if(this.waitingForPlayers.includes(color) == false) {
                    //     if(this.focusMode == true) return this.playerPointsOrCardsClicked(player);
                    // }
                    if(this.selectPlayer(color).waitingForMe == false) {
                        if(this.focusMode == true) return this.playerPointsOrCardsClicked(player);
                    }
                }

                this.bePlayer = this.selectPlayer(color);
                this.mainPlayer = color;

                // reorder the opponents
                while(this.opponents.indexOf(this.bePlayer) != 0) {
                    this.opponents.push(this.opponents[0]);
                    this.opponents.shift();
                }

                this.forceUpdate++;
            },
            setActivePlayer(player) {
                if(this.focusMode == true) return this.playerPointsOrCardsClicked(player);

                this.dices.whiteDiceRolling = true;
                this.dices.redDiceRolling = true;
                this.dices.boatDiceRolling = true;

                this.dicesRolling = true;

                this.activePlayer = player;
                this.mainPlayer = player.color;

                let playerIndex = this.players.findIndex(pl => pl.color == player.color);

                this.activePlayer.turnsBeforeYou = 0;

                for(let i = 0; i < this.players.length; i++) {
                    if(playerIndex+i >= this.players.length) {
                        this.players[playerIndex+i-this.players.length].turnsBeforeYou = i;
                    } else {
                        this.players[playerIndex+i].turnsBeforeYou = i;
                    }
                }
            },
            playerPointsOrCardsClicked(player) {
                if(this.devMode && !['steal knight', 'steal progress card'].includes(this.focusType)) {
                    // this.activePlayer = player;
                }

                if(this.focusType == 'steal progress card') {
                    player.highlightedSpots = [];

                    this.highlightedPlayersOff();

                    this.log(':activePlayer played spy against :playerName', player);
                    return this.showModal('steal a progress card', this.activePlayer, player);
                } else if(this.focusType == 'commercial harbor select opponent') {

                    this.focusModeOff(this.activePlayer);
                    this.highlightedPlayersOff();

                    return this.commercialHarborAgainstOpponent(player);

                } else if(this.focusType == 'steal 2 hand cards') {
                    player.highlightedSpots = [];
                    this.highlightedPlayersOff();

                    player.showOutgoingHandCards = false;

                    return this.showModal('select 2 hand cards', this.activePlayer, player);
                } else if(this.focusType == 'steal knight') {

                    this.focusModeOff(this.activePlayer);

                    this.highlightedPlayersOff();

                    player.highlightedSpots = [];

                    let playersKnights = this.knights.filter(knight => knight.player == player);

                    if(playersKnights.length == 1) {

                        this.focusPiece = playersKnights[0];

                        this.focusPiece.player.pieces.knights.push(playersKnights[0].level);

                        this.knights = this.knights.filter(knight => knight.player != player);

                        this.placeStolenKnight(this.activePlayer);
                        return;
                    } else {
                        this.focusModeOff(this.activePlayer);

                        playersKnights.forEach(knight => {

                            let spot = this.spot(knight.tile, knight.position);
                            this.highlightSpot(spot.key, player);
                        });

                        // this.log('Waiting for :playerName to choose knight to give away', player);
                        this.focusModeOn('select knight to give away', player);

                        if(this.logToConsole) console.log('SAVE select knight to give away 2')
                        this.saveGame();
                    }

                    return;
                }
            },
            activeCardOff() {
                this.activeCard = null;
                setTimeout(() => {
                    this.showInfo = null
                }, 300)
            },
            toggleShowInfo(card) {
                this.showInfo ? this.showInfo = null : this.showInfo = card;
            },
            allToggleShowInfo(card) {
                if(card == this.allShowInfo) {
                    this.allShowInfo = null;
                } else {
                    this.allShowInfo = card;
                }
            },
            playProgressCard(player, card, index, choise = null) {

                if(this.paying) return;

                if(this.focusType == 'throw away a progress card') {
                    if(index == null) return;

                    if(! player.waitingForMe) return;

                    if(this.activeCard == card) {
                        player.progressCards.splice(index, 1);

                        this.log(':progress_' + card.deck + ' :playerName threw away a progress card', player);

                        this.progressCardBack(card.label, card.deck);
                        // progress card back to deck
                        // if(card.deck == 'cloth') {
                        //     this.progressCardsCloth.push(card.label);
                        // } else if(card.deck == 'coin') {
                        //     this.progressCardsCoin.push(card.label);
                        // } else if(card.deck == 'paper') {
                        //     this.progressCardsPaper.push(card.label);
                        // }

                        this.focusModeOff(player);

                        if(this.logToConsole) console.log('SAVE throwPrgrCard!!!')
                        this.savePlayer(player);
                    } else {
                        setTimeout(() => {
                            this.showInfo = null;
                        }, 300)

                        this.activeCard = card;
                    }
                }

                if(this.dicesRollAnimation == true && this.devMode == false) return;

                if(this.dicesRolling == true && this.devMode == false && card.label != 'alchemist') return;

                if(card.label != 'alchemist') {
                    if(this.focusMode == true && choise == null && this.focusType != 'steal progress card') return;
                }

                // can't click on opponents progress cards if not using spy
                if(player != this.activePlayer && this.focusType != 'steal progress card') return;

                if(this.focusType == 'steal progress card') {
                    if(player == this.activePlayer) return;
                }

                if(this.activeCard == card) {

                    // List all cards which needs to remember card type for modal later
                    if(!['resource monopoly', 'trade monopoly', 'crane', 'merchant fleet'].includes(card.label)) {
                        setTimeout(() => {
                            this.activeCardOff();
                        }, 300);
                    }

                    // abc
                    // if playing opponents progress-card, steal it, don't play it
                    // steal with spy card
                    if(player != this.activePlayer) {
                        let stolenProgressCard = this.modalPlayer.progressCards[index];
                        this.activePlayer.progressCards.push(stolenProgressCard);

                        if(!player.ai) this.log(':playerName stole your ' + stolenProgressCard.label + ' (only visible for you)', this.activePlayer, false, null, player.color);

                        this.modalPlayer.progressCards.splice(index, 1);
                        this.focusModeOff(this.activePlayer);

                        return;
                    }

                    if(card.label == 'merchant') {
                        // cant play merchant on map with only goldmines
                        let resourceTileFound = false;

                        this.buildings
                            .filter(building => building.player == this.activePlayer)
                            .forEach(building => {
                                if(this.spotTiles(building.tile, building.position).find(tile => ['lumber', 'brick', 'wool', 'grain', 'ore'].includes(tile.type))) resourceTileFound = true;
                        });

                        if(resourceTileFound == false) return this.activeCardOff();

                        this.focusModeOn('move merchant', player);
                        this.log(':playerName played ' + card.label, this.activePlayer);

                        // console.log('SAVE move merchant');
                        // this.saveGame();
                    } else if(card.label == 'saboteur') {
                        let opponentCities = this.cities.filter(city => city.player.color != player.color && city.metropolis == false && city.disabled == false);

                        if(opponentCities.length == 0) {
                            // pull progressCard down
                            return this.giveFeedback("No cities available.");
                        } else if(opponentCities.length == 1) {
                            opponentCities[0].disabled = true;
                            this.saveGame();
                        } else {
                            opponentCities.forEach(city => {

                                let spot = this.spot(city.tile, city.position);
                                let theKey = 'k' + city.tile.row + '' + city.tile.position + '' + city.position;
                                this.highlightSpot(theKey, this.activePlayer);
                                // this.highlightSpot(spot.key, this.activePlayer);
                            });
                            this.focusModeOn('disable city', player);
                        }
                        this.log(':playerName played ' + card.label + ' (disable city)', this.activePlayer);
                    } else if(card.label == 'merchant fleet') {
                        if(!choise) {
                            this.showModal('select merchant fleet', this.activePlayer);
                            this.focusModeOn('select merchant fleet', this.activePlayer);
                        } else {
                            this.activePlayer.tempHarbors.push(choise);
                            this.updateTradeCosts();
                            this.focusModeOff(this.activePlayer);
                            this.log('<div>:playerName played ' + card.label + ' and will trade</div> :' + choise + '<div>2:1 this turn</div>', this.activePlayer);
                        }
                    } else if(card.label == 'engineer') {
                        let ownCitiesWithoutCitywall = this.cities.filter(city => city.player.color == player.color && city.citywall == false);

                        if(ownCitiesWithoutCitywall.length == 0 || player.pieces.citywalls == 0) {
                            if(player.pieces.citywalls == 0) {
                                return this.giveFeedback("No citywalls left.");
                            } else {
                                return this.giveFeedback("No cities available.");
                            }
                        } else if(ownCitiesWithoutCitywall.length == 1) {
                            ownCitiesWithoutCitywall[0].citywall = true;
                            player.pieces.citywalls--;

                            this.saveGame();
                        } else {
                            ownCitiesWithoutCitywall.forEach(city => {

                                let spot = this.spot(city.tile, city.position);
                                this.highlightSpot(spot.key, this.activePlayer);
                            });
                            this.focusModeOn('build citywall', player);
                        }

                        this.log(':playerName played ' + card.label + ' (free citywall)', this.activePlayer);

                    } else if(card.label == 'mining' || card.label == 'irrigation') {
                        let oreTiles = [];
                        let grainTiles = [];

                        let playerBuildings = this.buildings.filter(building => building.player.color == player.color)
                        playerBuildings.forEach(building => {
                            this.spotTiles(building.tile, building.position).forEach(buildingTile => {
                                if(buildingTile.type == 'ore') {
                                    if(!oreTiles.includes(buildingTile)) {
                                        oreTiles.push(buildingTile);
                                    }
                                } else if(buildingTile.type == 'grain') {
                                    if(!grainTiles.includes(buildingTile)) {
                                        grainTiles.push(buildingTile);
                                    }
                                }
                            })
                        })

                        if(card.label == 'mining') {
                            if(oreTiles.length == 0) return this.giveFeedback("You don't have any ore tiles.");

                            let handOre = player.handCards.find(cardObj => cardObj.type == 'ore');

                            handOre.amount += oreTiles.length*2;
                            handOre.slideIn += oreTiles.length*2;

                            this.incoming = true;
                            setTimeout(() => {
                                handOre.slideIn -= oreTiles.length*2;

                                if(this.logToConsole) console.log('SAVE mining');
                                if(! this.activePlayer.ai) this.saveGame();
                            }, 1000);

                            setTimeout(() => {
                                this.incoming = false;
                            }, 2000);

                            let oreString = '';
                            for(let i = 0; i < oreTiles.length*2; i++) oreString += ':ore ';

                            this.log('<div>:playerName played ' + card.label + '</div>' + oreString, this.activePlayer);
                        } else if(card.label == 'irrigation') {
                            if(grainTiles.length == 0) return this.giveFeedback("You don't have any grain tiles.");

                            let handGrain = player.handCards.find(cardObj => cardObj.type == 'grain');

                            handGrain.amount += grainTiles.length*2;
                            handGrain.slideIn += grainTiles.length*2;

                            this.incoming = true;
                            setTimeout(() => {
                                handGrain.slideIn -= grainTiles.length*2;

                                if(this.logToConsole) console.log('SAVE irrigation');
                                if(! this.activePlayer.ai) this.saveGame();
                            }, 1000);

                            setTimeout(() => {
                                this.incoming = false;
                            }, 2000);
                            let grainString = '';
                            for(let i = 0; i < grainTiles.length*2; i++) grainString += ':grain ';

                            this.log('<div>:playerName played ' + card.label + '</div>' + grainString, this.activePlayer);

                            // this.log(':playerName played ' + card.label + ' and got ' + grainTiles.length*2 + ' grain cards', this.activePlayer);
                        }
                    } else if(card.label == 'warlord') {
                        let playerKnights = this.knights.filter(knight => knight.player.color == player.color && knight.active == false);

                        if(!playerKnights.length) return this.giveFeedback("You don't have any inactive knights.");

                        playerKnights.forEach(knight => {
                            knight.active = true;
                            knight.activatedThisTurn = true;
                        });
                        this.log(':playerName played ' + card.label + ' (activate all knights)', this.activePlayer);

                        this.saveGame();
                    } else if(card.label == 'bishop') {
                        if(this.firstBoatRound) return this.giveFeedback("Can't move robber on first boat round.");
                        this.focusModeOn('move robber bishop', this.activePlayer);
                        this.log(':playerName played ' + card.label + ' (move robber)', this.activePlayer);

                        this.saveGame();
                    } else if(card.label == 'inventor') {

                        // because some of my kids made a map with only 8:s...
                        if(this.tiles.filter(tile => !tile.hidden && [3, 4, 5, 9, 10, 11].includes(tile.number.value)).length < 2) return;

                        this.focusModeOn('switch number chips', this.activePlayer);
                        this.log(':playerName played ' + card.label + ' (switch numbers)', this.activePlayer);
                    } else if(card.label == 'medecine') {
                        let playerHouses = this.houses.filter(house => house.player.color == player.color);

                        if(!playerHouses.length) return this.giveFeedback("No house available.");

                        if(player.pieces.cities == 0) return this.giveFeedback("No cities left.");

                        if(!this.pay(this.activePlayer, [ { type: 'grain', amount: 1 }, { type: 'ore', amount: 2 } ])) return this.giveFeedback("You are too poor even to use medecine.");

                        if(playerHouses.length == 1) {
                            this.upgradeToCheapCity(playerHouses[0].tile, playerHouses[0].position, player);
                        } else {
                            playerHouses.forEach(house => {

                                let spot = this.spot(house.tile, house.position);
                                this.highlightSpot(spot.key, this.activePlayer);
                            });

                            this.focusModeOn('upgrade cheap city', player);
                        }
                        this.log(':playerName played ' + card.label + ' (cheap city)', this.activePlayer);
                    } else if(card.label == 'commercial harbor') {

                        this.commercialHarborOpponents = this.players.filter(player => player != this.activePlayer && this.playerHandCardsCount(player) != 0).map(player => player.color);

                        this.playCommercialHarbor();
                        this.log(':playerName played ' + card.label + ' (resources vs commodities)', this.activePlayer);
                    } else if(card.label == 'alchemist') {
                        if(this.dicesRolling == false) return this.giveFeedback("You have to play Alchemist before rolling the dices.");

                        this.focusModeOn('select dice number', player);
                        this.showModal('select dice number', player);
                    } else if(card.label == 'crane') {

                        let craneOptions = this.getCraneOptions(player);

                        if(craneOptions.length == 0) {
                            this.giveFeedback("No crane options available.");
                            return this.activeCardOff();
                        }

                        // cant play crane if no city built
                        if(!this.cities.find(city => city.player == this.activePlayer)) {
                            this.giveFeedback("You can't play crane without a city.");
                            return this.activeCardOff();
                        }

                        this.craneOptionsLength = craneOptions.length;

                        if(choise == null) {
                            if(craneOptions.length == 1) {
                                if(this.commodityUpgrade(player, craneOptions[0], false, true) == false) return this.giveFeedback("Crane failed, city for metropolis needed.");
                                this.log(':' + craneOptions[0] + ':playerName played crane', this.activePlayer);
                                this.commodityUpgrade(player, craneOptions[0], false, true)
                            } else {
                                this.showModal('select commodity upgrade', this.activePlayer);
                                this.focusModeOn('select commodity upgrade', this.activePlayer);
                            }

                        } else {

                            if(craneOptions.includes(choise)) {
                                // focus mode off
                                this.focusModeOff(this.activePlayer);

                                // crane worked, focusModeOn to place metropolis for example.
                                if(this.commodityUpgrade(player, choise, false, true) != false) {
                                    this.log(':' + choise + ':playerName played crane', this.activePlayer);
                                // tried to take metropolis with disabled city for example.
                                } else {
                                    this.showModal('select commodity upgrade', this.activePlayer);
                                    this.focusModeOn('select commodity upgrade', this.activePlayer);
                                    this.activeCard = { deck: 'paper', label: 'crane' };
                                }
                            }
                        }
                    } else if(card.label == 'smith') {

                        this.highlightCanUpgradeKnights(player)

                        if(!player.highlightedSpots.length) return this.giveFeedback("No knights available.");

                        this.log(':playerName played ' + card.label + ' (upgrade knight/knights)', this.activePlayer);

                        if(player.highlightedSpots.length == 1) {
                            let knightSpot = this.spotByKey(player.highlightedSpots[0]);
                            this.upgradeKnight(knightSpot.tile, knightSpot.position, player, true);

                            this.highlightCanUpgradeKnights(player, knightSpot.key);

                            // one knight to upgrade after one was auto-upgraded
                            if(player.highlightedSpots.length == 1) {
                                let knightSpot = this.spotByKey(player.highlightedSpots[0]);
                                this.showModal('upgrade second knight', this.activePlayer);
                                return this.focusModeOn('upgrade second knight', this.activePlayer);
                            }

                        } else {
                            this.focusModeOn('select knight to upgrade', player);
                        }

                    } else if(card.label == 'wedding') {
                        let opponentsWithMorePoints = this.players.filter(opponent => opponent.points > player.points);

                        if(!opponentsWithMorePoints.length) return this.giveFeedback("No opponents with more points than you.");

                        let opponentsWithMorePointsAndHasHandCards = opponentsWithMorePoints.filter(opponent => opponent.handCards.reduce((numberOfCards, card) => numberOfCards + card.amount, 0) > 0);

                        if(!opponentsWithMorePointsAndHasHandCards.length) return this.giveFeedback("Opponents with more points has 0 hand cards.");

                        this.log(':playerName played ' + card.label, this.activePlayer);

                        opponentsWithMorePointsAndHasHandCards.forEach(opponent => {

                            opponent.showOutgoingHandCards = false;
                            this.activePlayer.showIncomingHandCards = false;

                            // if 1 or 2 cards, take them automatically
                            if(opponent.handCards.reduce((numberOfCards, card) => numberOfCards + card.amount, 0) <= 2) {
                                this.activePlayer.showIncomingHandCards = false;
                                opponent.handCards.forEach(handCardObj => {
                                    if(handCardObj.amount != 0) {
                                        setTimeout(() => {
                                            this.activePlayer.handCards.find(activeHandCardObj => activeHandCardObj.type == handCardObj.type).amount += handCardObj.amount;
                                            this.activePlayer.handCards.find(activeHandCardObj => activeHandCardObj.type == handCardObj.type).slideIn += handCardObj.amount;

                                            handCardObj.slideOut = handCardObj.amount;
                                            handCardObj.amount = 0;
                                            setTimeout(() => {
                                                this.activePlayer.handCards.forEach(cardObj => {
                                                    cardObj.slideIn = 0;
                                                })
                                                handCardObj.slideOut = 0;
                                                this.activePlayer.showIncomingHandCards = true;
                                            }, 1000)

                                        }, 350)
                                    }
                                })
                            } else {
                                this.focusModeOn('give 2 hand cards', opponent);

                                if(!opponent.ai) this.showModal('give 2 hand cards', opponent);

                                if(this.logToConsole) console.log('SAVE wedding');
                                this.saveGame();
                            }
                        });
                    } else if(card.label == 'master merchant') {

                        let opponentsWithMorePoints = this.players.filter(opponent => opponent.points > player.points);

                        if(!opponentsWithMorePoints.length) return this.giveFeedback("No opponent with more points.");

                        let opponentsWithMorePointsAndHasHandCards = opponentsWithMorePoints.filter(opponent => opponent.handCards.reduce((numberOfCards, card) => numberOfCards + card.amount, 0) > 0);

                        if(!opponentsWithMorePointsAndHasHandCards.length) return this.giveFeedback("Opponent with more points has 0 hand cards.");

                        if(opponentsWithMorePointsAndHasHandCards.length == 1) {
                            this.focusModeOn('steal 2 hand cards', player);

                            opponentsWithMorePointsAndHasHandCards[0].handCards.forEach(cardObj => {
                                cardObj.selected = 0;
                                cardObj.slideIn = 0;
                                cardObj.slideOut = 0;
                            });

                            opponentsWithMorePointsAndHasHandCards[0].showOutgoingHandCards = false;

                            this.log(':activePlayer played ' + card.label + ' and stole 2 hand cards from :playerName', opponentsWithMorePointsAndHasHandCards[0]);
                            this.showModal('select 2 hand cards', this.activePlayer, opponentsWithMorePointsAndHasHandCards[0]);

                        } else {
                            opponentsWithMorePointsAndHasHandCards.forEach(opponent => {
                                opponent.highlightMyPoints = true;
                                opponent.highlightMyHandCards = true;

                                this.buildings.forEach(building => {
                                    if(building.player.color == opponent.color) {
                                        this.highlightSpot(building.key, this.activePlayer);
                                    }
                                });

                                this.focusModeOn('steal 2 hand cards', player);
                            });
                        }

                    } else if(card.label == 'deseter') {
                        let opponentKnights = this.knights.filter(knight => knight.player.color != player.color);

                        if(opponentKnights.length == 0) return  this.giveFeedback("No knights available.");

                        opponentKnights.forEach(knight => {
                            knight.player.highlightMyPoints = true;

                            this.buildings.forEach(building => {
                                if(building.player.color == knight.player.color) {

                                    let spot = this.spot(building.tile, building.position);
                                    this.highlightSpot(spot.key, this.activePlayer);
                                }
                            })
                        })

                        this.focusModeOn('steal knight', player);
                    } else if(card.label == 'diplomat') {

                        this.players.forEach(player => {
                            this.roads.forEach(road => {
                                this.roadSpots(road.tile, road.roadSpot).forEach(spot => {
                                    if(!spot) return;
                                    if(spot.content.type == undefined || spot.content.player.color != player.color) {
                                        let playerRoads = this.spotRoads(spot.tile, spot.position).filter(spotRoad => spotRoad && spotRoad.player.color == player.color);

                                        if(playerRoads.length == 1) {
                                            this.highlightedRoadSpots.push(playerRoads[0].key);
                                        }
                                    }
                                })
                            });
                        });

                        if(this.highlightedRoadSpots.length == 0) return this.giveFeedback("No roads available.");

                        if(this.highlightedRoadSpots.length == 1) {

                            let targetRoad = this.roads.find(road => road.key == this.highlightedRoadSpots[0]);

                            if(targetRoad.player != this.activePlayer) {
                                this.log(':activePlayer played diplomat against :playerName', targetRoad.player);

                                if(targetRoad.boat) {
                                    targetRoad.player.pieces.boats++;
                                } else {
                                    targetRoad.player.pieces.roads++;
                                }

                                this.roads = this.roads.filter(road => road != targetRoad);

                                if(this.longestRoad.includes(targetRoad)) {
                                    this.scanLongestRoad(true, targetRoad.player);
                                }

                                this.focusModeOff(this.activePlayer);
                            } else {
                                this.roads = this.roads.filter(road => road != targetRoad);
                                this.log(':activePlayer played diplomat against :playerName', targetRoad.player);

                                this.highlightedRoadSpots = [];
                                this.highlightBuildRoadSpots(this.activePlayer);

                                if(targetRoad.boat) {
                                    this.activePlayer.pieces.boats++;
                                } else {
                                    this.activePlayer.pieces.roads++;
                                }

                                if(this.longestRoad.includes(targetRoad)) {
                                    this.scanLongestRoad(true, targetRoad.player);
                                }

                                // this.log('Waiting for :activePlayer to place a road', targetRoad.player, true);

                                if(targetRoad.boat) {
                                    this.focusModeOn('build 1 boat', this.activePlayer);
                                } else {
                                    this.focusModeOn('place 1 road', this.activePlayer);
                                }
                            }

                        } else {
                            this.log(':playerName played ' + card.label, this.activePlayer);
                            this.focusModeOn('remove road', player);
                        }

                    } else if(card.label == 'spy') {
                        let opponentsWithProgressCards = this.players.filter(opponent => opponent.color != player.color && opponent.progressCards.length > 0);

                        if(!opponentsWithProgressCards.length) return this.giveFeedback("Opponents has 0 progress cards.");

                        if(opponentsWithProgressCards.length == 1) {
                            this.focusModeOn('steal progress card', player);

                            this.log(':activePlayer played ' + card.label + ' against :playerName', opponentsWithProgressCards[0]);
                            this.showModal('steal a progress card', this.activePlayer, opponentsWithProgressCards[0]);

                        } else {
                            opponentsWithProgressCards.forEach(opponent => {
                                opponent.highlightMyPoints = true;
                                opponent.highlightMyProgressCards = true;

                                this.buildings.forEach(building => {
                                    if(building.player.color == opponent.color) {

                                        let spot = this.spot(building.tile, building.position);
                                        this.highlightSpot(spot.key, this.activePlayer);
                                    }
                                })

                                this.focusModeOn('steal progress card', player);

                                return;
                            });
                        }
                    } else if(card.label == 'road building') {
                        if(this.seafaresExpansion) {
                            if(player.pieces.roads + player.pieces.boats == 0) return this.giveFeedback("No roads or boats left.");
                        } else {
                            if(player.pieces.roads == 0) return  this.giveFeedback("No roads left.");
                        }

                        this.log(':playerName played ' + card.label + ' (2 roads for free)', this.activePlayer);

                        this.focusModeOn('build 2 roads', player);

                        this.highlightBuildRoadSpots(player);
                        if(this.seafaresExpansion) this.highlightBuildBoatSpots(player, true);
                    } else if(card.label == 'intrigue') {
                        let playerRoads = this.roads.filter(road => road.player.color == player.color);
                        let contentAlongPlayerRoad = [];

                        playerRoads.forEach(road => {

                            this.roadSpots(road.tile, road.roadSpot).forEach(roadSpot => {
                                if(!roadSpot) return;

                                if(roadSpot.content.type == 'knight' && roadSpot.content.player.color != player.color) {
                                    if(!contentAlongPlayerRoad.includes(roadSpot)) {
                                        contentAlongPlayerRoad.push(roadSpot)
                                    }
                                }
                            });

                        });

                        if(contentAlongPlayerRoad.length == 0) return this.giveFeedback("No opponent knight along your road.");

                        if(contentAlongPlayerRoad.length == 1) {

                            this.focusPiece = contentAlongPlayerRoad[0].content;

                            this.log(':activePlayer played intrigue against :playerName (chase away knight)', contentAlongPlayerRoad[0].content.player);
                            this.log(':activePlayer played intrigue against :playerName (chase away knight)', contentAlongPlayerRoad[0].content.player, true);
                            this.focusModeOn('move chased knight', contentAlongPlayerRoad[0].content.player);

                            this.focusPiece.tile = contentAlongPlayerRoad[0].tile;
                            this.checkedSpots = [];
                            player.highlightedSpots = [];
                            this.highlightMoveKnightSpots(contentAlongPlayerRoad[0].tile, contentAlongPlayerRoad[0].position, contentAlongPlayerRoad[0].content.player, true);
                            this.chaseAwayKnight(contentAlongPlayerRoad[0].tile, contentAlongPlayerRoad[0].position, contentAlongPlayerRoad[0].content.player, true);
                            this.scanLongestRoad();

                            if(this.logToConsole) console.log('SAVE move chased knight 3')
                            this.saveGame();
                        } else {
                            contentAlongPlayerRoad.forEach(knight => {

                                let spot = this.spot(knight.tile, knight.position);
                                this.highlightSpot(spot.key, this.activePlayer);
                            })

                            this.focusModeOn('knight to chase away', player);
                        }
                    } else if(card.label == 'trade monopoly') {

                        if(choise == null) {
                            this.showModal('select a commodity', this.activePlayer);
                            this.focusModeOn('select a commodity', this.activePlayer);

                            if(this.logToConsole) console.log('SAVE select a commodity');
                            this.saveGame();
                        } else {

                            this.paying = true;
                            setTimeout(() => {
                                this.paying = false;
                            }, 700)

                            this.log(':' + choise + ' :playerName played ' + card.label, this.activePlayer);
                            let opponents = this.players.filter(player => player != this.activePlayer);

                            let commodityString = '';

                            opponents.forEach(opponent => {
                                let opponentCardObj = opponent.handCards.find(cardObj => cardObj.type == choise);
                                let activePlayerCardObj = this.activePlayer.handCards.find(card => card.type == choise);

                                if(opponentCardObj.amount > 0) {
                                    opponentCardObj.slideOut++;

                                    activePlayerCardObj.amount++;
                                    activePlayerCardObj.slideIn++;

                                    commodityString += ':' + choise + ' ';

                                    setTimeout(() => {
                                        opponentCardObj.amount--;
                                        opponentCardObj.slideOut--;
                                    }, 700)

                                    setTimeout(() => {
                                        activePlayerCardObj.slideIn--;
                                        opponentCardObj.slideOut--;
                                    }, 1000)
                                }
                            })

                            if(commodityString == '') {
                                this.log(':' + choise + ' :playerName played ' + card.label + ' (got 0 cards)', this.activePlayer);
                            } else {
                                this.log('<div>:playerName played ' + card.label + '</div>' + commodityString, this.activePlayer);
                            }

                            this.closeModal(this.activePlayer);
                            this.focusModeOff(this.activePlayer);

                            setTimeout(() => {
                                if(this.logToConsole) console.log('SAVE trade monopoly')
                                this.saveGame();
                            }, 700);
                        }
                    } else if(card.label == 'resource monopoly') {
                        if(choise == null) {
                            this.showModal('select a resource', this.activePlayer);
                            this.focusModeOn('select a resource', this.activePlayer);
                        } else {
                            let opponents = this.players.filter(player => player != this.activePlayer);

                            this.paying = true;
                            setTimeout(() => {
                                this.paying = false;
                            }, 700)

                            let resourceString = '';

                            opponents.forEach(opponent => {
                                let opponentCardObj = opponent.handCards.find(cardObj => cardObj.type == choise);
                                let activePlayerCardObj = this.activePlayer.handCards.find(card => card.type == choise);

                                let amountCopy = opponentCardObj.amount;
                                for(let i = 0; i < 2; i++) {
                                    if(amountCopy > 0) {
                                        amountCopy--;
                                        opponentCardObj.slideOut++;

                                        activePlayerCardObj.amount++;
                                        activePlayerCardObj.slideIn++;

                                        resourceString += ':' + choise + ' ';

                                        setTimeout(() => {
                                            opponentCardObj.amount--;
                                            opponentCardObj.slideOut--;
                                        }, 700)

                                        setTimeout(() => {
                                            activePlayerCardObj.slideIn--;
                                        }, 1000)
                                    }
                                }
                            })

                            if(resourceString == '') {
                                this.log(':' + choise + ' :playerName played ' + card.label + ' (got 0 cards)', this.activePlayer);
                            } else {
                                this.log('<div>:playerName played ' + card.label + '</div>' + resourceString, this.activePlayer);
                            }

                            this.closeModal(this.activePlayer);
                            this.focusModeOff(this.activePlayer, false);

                            setTimeout(() => {
                                if(this.logToConsole) console.log('SAVE resource monopoly')
                                this.saveGame();
                            }, 700);
                        }
                    }

                    if(index == null) return;
                    player.progressCards.splice(index, 1);

                    this.progressCardBack(card.label, card.deck);
                    // progress card back to deck
                    // if(card.deck == 'cloth') {
                    //     this.progressCardsCloth.push(card.label);
                    // } else if(card.deck == 'coin') {
                    //     this.progressCardsCoin.push(card.label);
                    // } else if(card.deck == 'paper') {
                    //     this.progressCardsPaper.push(card.label);
                    // }
                } else {
                    setTimeout(() => {
                        this.showInfo = null;
                    }, 300)

                    this.activeCard = card;
                }
            },
            getCraneOptions(player) {
                let craneOptions = [];

                player.progress.forEach(progress => {
                    let handCardObj = player.handCards.find(handCardObj => handCardObj.type == progress.type)

                    if(progress.level <= handCardObj.amount && progress.level != 5) {
                        craneOptions.push(progress.type);
                    }
                });

                // default order: paper, cloth, coin
                // better might be: cloth, paper, coin
                craneOptions.sort(craneOption => {
                    if(craneOption == 'cloth') return -1;
                    if(craneOption == 'paper') return -1;
                    if(craneOption == 'coin') return 1;
                });

                return craneOptions;
            },
            tileClicked(tile, player) {

                // this.focusModeOn('move pirate', this.activePlayer);

                // this.knights.forEach(knight => {
                //     knight.activatedThisTurn = false;
                //     knight.upgradedThisTurn = false;
                // });

                this.closeActionMenus();

                if(player != this.activePlayer) return;

                if(tile.hidden) return;

                if(this.focusMode == true) {
                    if(this.focusType == 'move robber') {
                        if(this.robber.tile == tile) return;

                        if(this.pirate.tile == tile) return;

                        if(this.activePlayer != player) return;

                        if(!this.seafaresExpansion) {
                            if(tile.group != 'land') return;
                        }

                        this.moveRobber(tile, player);
                    } else if(this.focusType == 'move robber only') {
                        if(this.robber.tile == tile) return;

                        if(this.activePlayer != player) return;

                        if(['water', 'harbor'].includes(tile.group)) return;

                        this.moveRobber(tile, player);
                    } else if(this.focusType == 'move pirate') {
                        if(this.pirate.tile == tile) return;

                        if(this.activePlayer != player) return;

                        if(!['water', 'harbor'].includes(tile.group)) return;

                        this.moveRobber(tile, player);
                    } else if(this.focusType == 'move robber bishop') {
                        if(this.robber.tile == tile) return;

                        if(this.pirate.tile == tile) return;

                        if(this.activePlayer != player) return;

                        if(!this.seafaresExpansion) {
                            if(tile.group != 'land') return;
                        }

                        this.moveRobber(tile, player, true);

                        this.focusModeOff(this.activePlayer);
                    } else if(this.focusType == 'move merchant') {
                        if(tile.type == 'goldmine') return;
                        this.moveMerchant(tile, player);
                    } else if(this.focusType == 'switch number chips') {
                        if(tile.group != 'land' || tile.type == 'desert') return;

                        if(!this.inventorTiles.includes(tile)) {
                            if(![6,8,2,12].includes(tile.number.value)) {
                                this.inventorTiles.push(tile);
                            }
                        } else {
                            this.inventorTiles.pop();
                        }

                        let delay = 0;
                        if(player.ai) delay = this.randomNumber(500, this.aiActionSpeed*8);

                        if(this.inventorTiles.length == 2) {
                            setTimeout(() => {
                                let savedNumber = this.inventorTiles[0].number;
                                this.inventorTiles[0].number = this.inventorTiles[1].number;
                                this.inventorTiles[1].number = savedNumber;
                                // log inventor
                                this.log('<div>:activePlayer played inventor </div><div style="margin-right: 0px !important">:' + this.inventorTiles[0].type + '</div><div>(' + this.inventorTiles[1].number.value + ')</div><div style="margin-right: 0px !important">:' + this.inventorTiles[1].type + '</div><div>(' + this.inventorTiles[0].number.value + ')</div>', this.activePlayer, false, null);

                                this.inventorTiles = [];
                                this.focusModeOff(player);
                            }, delay);
                        } else {
                            if(this.logToConsole) console.log('SAVE inventor');
                            if(! this.activePlayer.ai) this.saveGame();
                        }
                    }
                } else if(this.rollDicesOnTileClick) {
                    this.rollDice(tile.number.value);
                }
            },
            moveRobber(tile, player, bishop = false) {
                if(this.activePlayer != player) return;

                if(tile && tile.group != 'land' && tile.type != undefined) {
                    this.pirate.tile = tile;

                    let uniqueRobbedPlayers = [];
                    // loop all opponent boats
                    this.roads
                        .filter(road => road && road.player != player && road.boat)
                        .forEach(road => {
                            // one of boats tiles matches with pirate
                            if(this.roadTiles(road.tile, road.roadSpot).find(roadTile => roadTile == tile)) {
                                // only highlight boats if player has hand cards
                                if(this.playerHandCardsCount(road.player) != 0) {
                                    this.highlightedRoadSpots.push(road.key);

                                    if(!uniqueRobbedPlayers.includes(road.player)) {
                                        uniqueRobbedPlayers.push(road.player);
                                    }
                                }
                            }
                        });

                        if(uniqueRobbedPlayers.length == 1) {
                            this.selectPlayer(uniqueRobbedPlayers[0].color).showOutgoingHandCards = false;
                            this.stealHandCard(uniqueRobbedPlayers[0].color);
                            return this.focusModeOff(player);
                        } else if(uniqueRobbedPlayers.length == 0) {
                            return this.focusModeOff(player);
                        } else {
                            uniqueRobbedPlayers.sort((a, b) => b.potential - a.potential);

                            this.selectPlayer(uniqueRobbedPlayers[0].color).showOutgoingHandCards = false;
                            // highlight both of same players buildings instead of just one of them (allRobbedPlayers instead of uniqueRobbedPlayers)
                            if(this.activePlayer.ai) return this.stealHandCard(uniqueRobbedPlayers[0].color);

                            return this.focusModeOn('rob player', player);
                        }
                }

                // robber can not be placed in water or on undefined tiles
                if(tile && tile.group != 'land' || tile.type == undefined) return;

                this.activePlayer.showIncomingHandCards = false;

                this.robber.tile = tile;

                // move merchant to empty desert if robber is moved to desert
                if(this.merchant.tile == tile && tile.type == 'desert') {
                    let desertTiles = this.tiles.filter(tile => tile.type == 'desert');

                    if(desertTiles.length) {
                        desertTiles.forEach(desertTile => {
                            if(desertTile != tile) {
                                this.merchant.tile = desertTile;
                            }
                        })
                    }
                }

                this.highlightedOff(this.activePlayer);

                let uniqueRobbedPlayers = [];
                let allRobbedPlayers = [];

                this.actionTiles.forEach(actionTile => {
                    actionTile.spots.forEach((spot, spotPosition) => {

                        let spotContent = this.spot(actionTile, spotPosition).content;

                        if(spotContent.group == 'building') {
                            this.spotTiles(actionTile, spotPosition).forEach(spotTile => {
                                if(spotTile == tile) {
                                    if(spotContent.player.color != player.color) {

                                        spotContent.player.handCards.find(card => card.amount > 0)

                                        // player has over 0 cards
                                        if(spotContent.player.handCards.find(card => card.amount > 0)) {
                                            allRobbedPlayers.push(spotContent);

                                            if(!uniqueRobbedPlayers.includes(spotContent.player)) {
                                                uniqueRobbedPlayers.push(spotContent.player);
                                            }
                                        }

                                        this.forceUpdate++;
                                    }
                                }
                            });
                        }
                    });
                });
                if(!bishop) {
                    // steal from the player with most points
                    uniqueRobbedPlayers.sort((a, b) => b.potential - a.potential);

                    if(uniqueRobbedPlayers.length == 1) {
                        this.selectPlayer(uniqueRobbedPlayers[0].color).showOutgoingHandCards = false;
                        this.stealHandCard(uniqueRobbedPlayers[0].color);
                        this.focusModeOff(player);
                    } else if(uniqueRobbedPlayers.length == 0) {
                        this.focusModeOff(player);
                    } else {
                        this.selectPlayer(uniqueRobbedPlayers[0].color).showOutgoingHandCards = false;
                        // highlight both of same players buildings instead of just one of them (allRobbedPlayers instead of uniqueRobbedPlayers)
                        if(this.activePlayer.ai) return this.stealHandCard(uniqueRobbedPlayers[0].color);

                        this.buildings.forEach(building => {
                            if(building.player != this.activePlayer && this.playerHandCardsCount(building.player) > 0) {
                                if(this.spotTiles(building.tile, building.position).find(buildingTile => buildingTile.row == this.robber.tile.row && buildingTile.position == this.robber.tile.position)) {
                                    let theKey = 'k' + building.tile.row + '' + building.tile.position + '' + building.position;
                                    this.highlightSpot(theKey, this.activePlayer);
                                }
                            }
                        });

                        this.focusModeOn('rob player', player);
                    }
                } else {
                    uniqueRobbedPlayers.forEach(uniquePlayer => {
                        // uniquePlayer.showOutgoingHandCards = false;
                        this.selectPlayer(uniqueRobbedPlayers[0].color).showOutgoingHandCards = false;
                        this.stealHandCard(uniquePlayer.color);
                    });
                }
            },
            moveMerchant(tile, player, focusModeOff = true) {

                if(tile.group != 'land') return;

                if(tile.type == 'desert') return;

                let merchantPlaced = false;

                this.actionTiles.forEach(actionTile => {
                    actionTile.spots.forEach((spot, spotPosition) => {

                        let spotContent = this.spot(actionTile, spotPosition).content;

                        if(spotContent.group == 'building') {
                            this.spotTiles(actionTile, spotPosition).forEach(spotTile => {
                                if(spotTile == tile) {
                                    // tile has at least one building of players color
                                    if(spotContent.player.color == player.color) {

                                        merchantPlaced = true;

                                        // this gives the 2:1 type
                                        this.merchant.tile = tile;
                                        // this gives the 2:1 to the correct player and also the points/merchant card
                                        this.merchant.player = player;
                                        // this is for the visual placement of the merchant, center of a spot on a actionTile, then moved with offset towards the correct tile
                                        this.merchant.spot = this.spot(actionTile, spotPosition);

                                        // if merchant is placed on the actionTile, always bring the merchant position in towards the actionTile center
                                        if(tile.actionTile) {
                                            this.merchant.spotOffset = this.nextSpot(spotPosition, 3);
                                        // else, check which of the 2 remaining neighbours has the merchant
                                        } else {

                                            // tiles around the merchants spot
                                            let spotTiles = this.spotTiles(this.merchant.spot.tile, this.merchant.spot.position);

                                            let neihgbourIndex =  spotTiles.findIndex(spotTile => spotTile == this.merchant.tile);

                                            // if first neighbour has it offset will always be previous spot
                                            if(neihgbourIndex == 1) {
                                                this.merchant.spotOffset = this.previousSpot(spotPosition);
                                            // if second spot, offset will always be next spot
                                            } else {
                                                this.merchant.spotOffset = this.nextSpot(spotPosition);
                                            }
                                        }
                                    }
                                }
                            });
                        }
                    });
                });

                if(!merchantPlaced) return;

                this.updateTradeCosts();
                this.updatePlayersPoints();
                if(focusModeOff) {
                    this.focusModeOff(player);
                }

                // console.log('merch moved!!')
                // if(this.logToConsole) console.log('SAVE moveMerchant');
                // if(! this.activePlayer.ai) this.saveGame();
            },
            highlightedOff(player) {
                player.highlightedSpots = [];

                this.forceUpdate++;
            },
            showModal(type, player, modalPlayer) {
                if(!['upgrade second knight', 'build road or boat'].includes(type)) {
                    player.highlightedSpots = [];
                    this.highlightedRoadSpots = [];
                }

                player.modalMode = true;
                player.modalType = type;

                if(!modalPlayer) return;
                this.modalPlayer = modalPlayer;
            },
            closeModal(player, deselectHandCards = true) {

                if(['trade cards'].includes(player.modalType) || deselectHandCards) {
                    this.deselectPlayerHandCards(player);

                    if(['trade cards'].includes(player.modalType)) {

                        // not sure about these
                        this.focusMode = false;
                        this.focusType = null;

                        this.resetOffer();
                    }
                }

                let modalTypeWas = player.modalType;

                player.modalMode = false;
                player.modalType = null;
                player.hideModal = false;

                this.showCounterOfferModal = false;
            },
            async focusModeOn(type, player) {
                player.idleTimer = 0;

                this.closeActionMenus();

                this.focusMode = true;
                this.focusType = type;

                if(! this.waitingForPlayers.includes(player.color)) {
                    this.waitingForPlayers.push(player.color);
                }
                player.waitingForMe = true;

                // AI stuff below
                if(!player.ai) return;

                if(this.focusType == 'build initial house') {
                    setTimeout(() => {
                        this.aiBuildInitialHouse(player);
                    }, this.aiBuildSpeed);
                } else if(this.focusType == 'build initial city') {
                    // if map sucks, last player builds house first
                    // default expansion specific code
                    if(this.tenSpotsAverage < 10.80 || this.tileTypeDots['grain'] <= 16 || this.tileTypeDots['ore'] <= 13) {
                        if(!this.houses.find(house => house.player == player)) {
                            return this.focusModeOn('build initial house', player);
                        }
                    }
                    setTimeout(() => {
                        this.aiBuildInitialCity(player);
                    }, this.aiBuildSpeed);
                } else if(this.focusType == 'build initial road') {
                    setTimeout(() => {
                        this.aiBuildInitialRoad(player);
                        this.saveGame();
                    }, this.aiBuildSpeed);
                    // last player builds, swap house/city positions
                    if(player.buildOrder == this.players.length-1) {
                        let playerHouse = this.houses.find(house => house.player == player);
                        let playerCity = this.cities.find(city => city.player == player);
                        if(playerHouse && playerCity) {

                            let houseTiles = this.spotTiles(playerHouse.tile, playerHouse.position);
                            let cityTiles = this.spotTiles(playerCity.tile, playerCity.position);

                            // if city has more resource tiles, don't swap
                            if(houseTiles.filter(tile => tile.group == 'land' && tile.type != 'desert').length < cityTiles.filter(tile => tile.group == 'land' && tile.type != 'desert').length) return;

                            // less lumber/wool/ore, don't swap
                            if(houseTiles.filter(tile => ['lumber', 'wool', 'ore'].includes(tile.type)).length < cityTiles.filter(tile => ['lumber', 'wool', 'ore'].includes(tile.type)).length) return;

                            if(houseTiles.filter(tile => ['lumber', 'wool', 'ore'].includes(tile.type)).length == cityTiles.filter(tile => ['lumber', 'wool', 'ore'].includes(tile.type)).length) {
                                // same amount of lumber/wool/ore from both, not better total of dot's, dont swap
                                if(houseTiles.reduce((dotsTotal, tile) => dotsTotal + tile.number.dots, 0) < cityTiles.reduce((dotsTotal, tile) => dotsTotal + tile.number.dots, 0)) return;
                            }

                            let houseCopy = Object.assign({}, playerHouse);
                            let cityCopy = Object.assign({}, playerCity);
                            this.houses.find(house => house.player == player).tile = cityCopy.tile;
                            this.houses.find(house => house.player == player).position = cityCopy.position;
                            this.cities.find(city => city.player == player).tile = houseCopy.tile;
                            this.cities.find(city => city.player == player).position = houseCopy.position;
                        }
                    }
                }

                if(this.focusType == 'move robber') {
                    setTimeout(async () => {
                        await this.aiMoveRobber();
                    }, this.randomNumber(250, 250+this.aiActionSpeed*3));
                } else if(this.focusType == 'move robber only') {
                     setTimeout(async () => {
                        await this.aiMoveRobber();
                    }, this.randomNumber(250, 250+this.aiActionSpeed*3));
                } else if(this.focusType == 'move pirate') {
                    setTimeout(async () => {
                        await this.aiMovePirate();
                    }, this.randomNumber(250, 250+this.aiActionSpeed*3));
                } else if(this.focusType == 'move robber bishop') {
                    setTimeout(async () => {
                        await this.aiMoveRobberBishop();
                    }, this.randomNumber(250, 250+this.aiActionSpeed*3));
                } else if(['build 1 road', 'place 1 road'].includes(this.focusType)) {
                    setTimeout(async () => {
                        let choise = this.aiGetBuildRoadOptions()[0];
                        this.clickHighlightedRoad(choise.tile, choise.roadSpot.position);
                    }, this.randomNumber(250, 250+this.aiActionSpeed*3));
                } else if(['build 1 boat', 'move boat'].includes(this.focusType)) {
                    let choise = this.aiGetBuildBoatOptions(true, true)[0];
                    setTimeout(async () => {
                        this.clickHighlightedRoad(choise.tile, choise.roadSpot.position);
                    }, this.randomNumber(250, 250+this.aiActionSpeed*3));
                } else if(this.focusType == 'throw away cards') {
                    let delay = this.randomNumber(1000, this.aiActionSpeed*10);
                    if(this.multiplayer) delay = 0;

                    setTimeout(() => {
                        this.aiDiscardHalfHand(player);
                    }, delay);
                // this function is a bit weird, last one added to stealOrder gets the highest prio
                } else if(this.focusType == 'steal 2 hand cards') {
                    this.modalPlayer.showOutgoingHandCards = false;
                    this.activePlayer.showIncomingHandCards = false;

                    setTimeout(() => {

                        this.paying = true;
                        setTimeout(() => {
                            this.paying = false;
                        }, 700);

                        let stealOrder = ['grain', 'paper', 'cloth', 'ore', 'ore', 'grain', 'coin', 'paper', 'brick', 'brick', 'coin', 'cloth', 'lumber', 'lumber', 'wool', 'wool'];

                        let target;
                        let need;

                        // hardest recourses to get, add one of each
                        this.activePlayerDots.filter(obj => !['goldmine'].includes(obj.type)).sort((a, b) => b.dots - a.dots).forEach(obj => {
                            stealOrder.unshift(obj.type);
                        })

                        if(['house', 'boat and house'].includes(this.aiSaveCardsFor(this.activePlayer))) {
                            this.activePlayerDots.sort((a, b) => b.dots - a.dots)
                                .filter(obj => !['ore', 'goldmine'].includes(obj.type))
                                .forEach(obj => {
                                    if(this.activePlayer.handCards.find(cardObj => cardObj.type == obj.type).amount == 0) stealOrder.unshift(obj.type);
                            })
                        }

                        if(this.aiSaveCardsFor(this.activePlayer) == 'knight') {
                            this.activePlayerDots.sort((a, b) => b.dots - a.dots)
                                .filter(obj => !['brick', 'lumber', 'goldmine'].includes(obj.type))
                                .forEach(obj => {
                                    if(this.activePlayer.handCards.find(cardObj => cardObj.type == obj.type).amount == 0) stealOrder.unshift(obj.type);
                            })
                        }

                        if(this.aiSaveCardsFor(this.activePlayer) == 'activate knight') {
                            stealOrder.unshift('grain');
                        }

                        // player has no city, try to pick city-cards
                        if(!this.cities.find(city => city.player == this.activePlayer)) {
                            if(this.activePlayerDots.find(obj => obj.type == 'grain').dots > this.activePlayerDots.find(obj => obj.type == 'ore').dots) {
                                target = this.activePlayer.handCards.find(obj => obj.type == 'grain');
                                for(let i = 0; i < 2-target.amount; i++) stealOrder.unshift(target.type);
                                target = this.activePlayer.handCards.find(obj => obj.type == 'ore');
                                for(let i = 0; i < 3-target.amount; i++) stealOrder.unshift(target.type);
                            } else {
                                target = this.activePlayer.handCards.find(obj => obj.type == 'ore');
                                for(let i = 0; i < 3-target.amount; i++) stealOrder.unshift(target.type);
                                target = this.activePlayer.handCards.find(obj => obj.type == 'grain');
                                for(let i = 0; i < 2-target.amount; i++) stealOrder.unshift(target.type);
                            }
                        } else {
                            this.activePlayer.progress.forEach(progress => {
                                if(progress.level == 0 && progress.type == 'coin') stealOrder.unshift(progress.type);
                            });
                            this.activePlayer.progress.forEach(progress => {
                                if(progress.level == 0 && progress.type != 'coin') stealOrder.unshift(progress.type);
                            });
                        }

                        let stolenCards = 0;
                        let logString = '';

                        if(this.playerHandCardsCount(this.modalPlayer) == 0) this.focusModeOff(this.activePlayer);

                        stealOrder.forEach(type => {
                            let target = this.modalPlayer.handCards.find(obj => obj.type == type);
                            if(!target) return;
                            if(target.stolen < target.amount && target.amount > 0 && stolenCards != 2 && this.playerHandCardsCount(this.modalPlayer) != 0) {
                                target.stolen++;

                                let stealingFromPlayer = this.selectPlayer(this.modalPlayer.color);
                                stealingFromPlayer.handCards.find(obj => obj.type == type).slideOut++;

                                this.activePlayer.handCards.find(obj => obj.type == type).amount++;
                                this.activePlayer.handCards.find(obj => obj.type == type).slideIn++;

                                logString += ':' + type + ' ';

                                stolenCards++;

                                setTimeout(() => {
                                    this.activePlayer.handCards.find(obj => obj.type == type).slideIn--;

                                    stealingFromPlayer.handCards.find(obj => obj.type == type).slideOut--;
                                    stealingFromPlayer.handCards.find(obj => obj.type == type).amount--;
                                    target.stolen--;
                                }, 700);

                                setTimeout(() => {
                                    this.log('<div>:activePlayer played master merchant against you</div>' + logString + ':break<div>(only visible for you)</div>', this.activePlayer, false, null, stealingFromPlayer.color);
                                }, 700);

                            }
                        });

                        setTimeout(() => {
                            if(this.logToConsole) console.log('SAVE master merchant ai');
                            this.saveGame();
                        }, 700);

                        this.focusModeOff(this.activePlayer);
                    }, this.randomNumber(1000, 250+this.aiActionSpeed*8));
                } else if(this.focusType == 'commercial harbor select resource') {
                    let handCardsCopy = JSON.parse(JSON.stringify(this.activePlayer.handCards));

                    handCardsCopy = handCardsCopy
                        .filter(obj => !['paper', 'coin', 'cloth'].includes(obj.type))
                        .filter(obj => obj.amount > 0)
                        .sort((a, b) => this.activePlayerDots.find(obj => obj.type == b.type).dots - this.activePlayerDots.find(obj => obj.type == a.type).dots)
                        .sort((a, b) => b.tradeCost - a.tradeCost)
                        .sort((a, b) => b.amount - a.amount);

                    this.selectedHarborResource(handCardsCopy[0].type);

                } else if(this.focusType == 'commercial harbor select opponent') {
                    setTimeout(() => {
                        this.playerPointsOrCardsClicked(this.commercialHarborOpponents[0]);
                    }, this.randomNumber(250, 250+this.aiActionSpeed*5));
                } else if(this.focusType == 'commercial harbor select commodity') {
                    let handCardsCopy = JSON.parse(JSON.stringify(player.handCards));

                    let defaultOrder = [
                        { type: 'coin', score: 30 },
                        { type: 'cloth', score: 20 },
                        { type: 'paper', score: 10 },
                    ];

                    defaultOrder.forEach(obj => {
                        if(player.fightFor.includes(obj.type)) {
                            obj.score -= 100;
                        }
                        if(player.giveUp.includes(obj.type)) {
                            obj.score += 100;
                        }

                        // avoid giving away commodity if lvl 0
                        let target = player.progress.find(progress => progress.type == obj.type);
                        if(target.level == 0) obj.score -= 30;
                    })

                    handCardsCopy = handCardsCopy
                        .filter(obj => ['paper', 'coin', 'cloth'].includes(obj.type))
                        .filter(obj => obj.amount > 0)
                        .sort((a, b) => defaultOrder.find(obj => obj.type == b.type).score - defaultOrder.find(obj => obj.type == a.type).score)

                    setTimeout(() => {
                        this.selectedHarborCommodity(player, handCardsCopy[0].type);
                    }, this.randomNumber(250, 250+this.aiActionSpeed*5));

                } else if(this.focusType == 'give 2 hand cards') {
                    this.aiGetHandCardsToSave(player);

                    player.showOutgoingHandCards = false;
                    this.activePlayer.showIncomingHandCards = false;

                    let giveOrder = ['wool', 'wool', 'lumber', 'lumber', 'brick', 'ore', 'grain', 'coin', 'cloth', 'paper', 'brick', 'ore', 'grain', 'coin', 'cloth', 'paper'];
                    let givenCards = 0;

                    if(this.playerHandCardsCount(player) == 0) this.focusModeOff(player);

                    setTimeout(() => {
                        giveOrder.forEach(type => {
                            let target = player.handCards.find(obj => obj.type == type);
                            if(givenCards != 2) {
                                if(!target) return;
                                if(target.stolen < target.amount && target.amount > 0 && target.amount > target.save && this.playerHandCardsCount(player) != 0) {
                                    target.stolen++;

                                    player.handCards.find(obj => obj.type == type).amount--;
                                    this.activePlayer.handCards.find(obj => obj.type == type).amount++;
                                    this.activePlayer.handCards.find(obj => obj.type == type).slideIn++;

                                    givenCards++;

                                    setTimeout(() => {
                                        this.activePlayer.handCards.find(obj => obj.type == type).slideIn--;
                                        target.stolen--;
                                    }, 700);
                                }
                            }
                        });

                        this.focusModeOff(player);
                    }, this.randomNumber(250, 250+this.aiActionSpeed*8));
                } else if(this.focusType == 'add metropolis') {
                    setTimeout(() => {
                        let citiesCanTakeMetropolis = this.cities.filter(city => city.player.color == player.color && city.disabled == false && city.metropolis == false);

                        citiesCanTakeMetropolis.sort((a, b) => this.spotDots(b.tile, b.position) - this.spotDots(a.tile, a.position));

                        this.clickHighlighted(citiesCanTakeMetropolis[0].tile, citiesCanTakeMetropolis[0].position, player);
                        if(this.activePlayer.ai) {
                            this.aiChat(["yess!", ";)", "Ahh, feels good"], this.activePlayer, true, 10);
                        }
                    }, this.randomNumber(250, 250+this.aiActionSpeed*3));
                } else if(this.focusType == 'upgrade cheap city') {

                    let playerHouses = this.houses.filter(house => house.player.color == player.color);

                    if(!playerHouses.length) return;

                    if(player.pieces.cities == 0) return;

                    if(!this.canPay(this.activePlayer, [ { type: 'grain', amount: 1 }, { type: 'ore', amount: 2 } ])) return;

                    playerHouses.sort((a, b) => this.spotDots(b.tile, b.position) - this.spotDots(a.tile, a.position));

                    this.upgradeToCheapCity(playerHouses[0].tile, playerHouses[0].position, this.activePlayer);

                    this.focusModeOff(this.activePlayer);

                } else if(this.focusType == 'select city to destroy') {
                    setTimeout(() => {
                        let playerCities = this.cities.filter(city => city.player == player && city.metropolis == false);

                        playerCities.forEach(city => {
                            city.score = this.spotDots(city.tile, city.position);
                            if(city.disabled == true) city.score -= 3;

                            // city gives dev-cards
                            this.spotTiles(city.tile, city.position).forEach(tile => {
                                if(['wool', 'ore', 'lumber'].includes(tile.type)) city.score += 1.5;
                            });
                        });

                        playerCities.sort((a, b) => a.score - b.score);

                        if(playerCities.length) {
                            this.clickHighlighted(playerCities[0].tile, playerCities[0].position, player);
                        }
                    }, this.randomNumber(250, 250+this.aiActionSpeed*3));
                } else if(this.focusType == 'disable city') {
                    /*
                    setTimeout(() => {
                        let opponentCities = this.cities.filter(city => city.player.color != player.color && city.metropolis == false && city.disabled == false);

                        opponentCities.sort((a, b) => b.player.potential - a.player.potential);

                        opponentCities = opponentCities.filter(obj => obj.player == opponentCities[0].player);

                        opponentCities.sort((a, b) => this.spotDots(b.tile, b.position) - this.spotDots(a.tile, a.position));

                        this.clickHighlighted(opponentCities[0].tile, opponentCities[0].position, this.activePlayer);
                    }, this.randomNumber(250, 250+this.aiActionSpeed*3));
                    */
                } else if(this.focusType == 'build citywall') {
                    let ownCitiesWithoutCitywall = this.cities.filter(city => city.player.color == player.color && city.citywall == false);

                    ownCitiesWithoutCitywall.sort((a, b) => this.spotDots(b.tile, b.position) - this.spotDots(a.tile, a.position));

                    this.clickHighlighted(ownCitiesWithoutCitywall[0].tile, ownCitiesWithoutCitywall[0].position, this.activePlayer);
                } else if(this.focusType == 'select aqueduct resource') {
                    let delay = this.randomNumber(250, 250+this.aiActionSpeed*3);
                    if(this.multiplayer) delay = 0;

                    setTimeout(async () => {
                        await this.aiSelectAqueductResource(player);
                    }, delay);
                } else if(this.focusType == 'select goldmine resources') {
                    setTimeout(async () => {
                        await this.aiSelectAqueductResource(player, player.goldmineAmount, false);
                    }, this.randomNumber(250, 250+this.aiActionSpeed*3));
                } else if(this.focusType == 'remove road') {
                    let selected = this.aiGetDiplomatOptions()[0];

                    setTimeout(async () => {
                        this.clickHighlightedRoad(selected.tile, selected.roadSpot, this.activePlayer);

                        if(selected.boat) {
                            if(selected.player == this.activePlayer) this.focusModeOn('build 1 boat', this.activePlayer)
                        } else {
                            if(selected.player == this.activePlayer) this.focusModeOn('place 1 road', this.activePlayer)
                        }

                    }, this.randomNumber(250, 250+this.aiActionSpeed*3));

                } else if(this.focusType == 'steal progress card') {
                    setTimeout(async () => {
                        await this.aiStealProgressCard();
                    }, this.randomNumber(500, 500+this.aiActionSpeed*8));
                } else if(this.focusType == 'throw away a progress card') {
                    await this.aiThrowAwayProgressCard(player);
                } else if(this.focusType == 'select a progress card') {
                    setTimeout(() => {
                        // select cloth at end of game if no merchant in hand
                        if(this.lateGame && !this.aiHasProgressCard('merchant', player)) {
                            if(this.multiplayer) {
                                this.givePlayerProgressCard2(player, 'cloth');
                            } else {
                                this.givePlayerProgressCard(player, 'cloth');
                            }
                        } else {
                            if(this.multiplayer) {
                                this.givePlayerProgressCard2(player, 'paper');
                            } else {
                                this.givePlayerProgressCard(player, 'paper');
                            }
                        }
                        this.focusModeOff(player);
                    }, this.randomNumber(0, 750));
                } else if(this.focusType == 'select knight to upgrade' || this.focusType == 'upgrade second knight') {
                    let options = [];

                    this.activePlayer.highlightedSpots.forEach(key => {
                        options.push(this.spotByKey(key));
                    })

                    options.sort((a, b) => b.level - a.level);

                    options = options
                        .sort((a, b) => this.spotDots(b.tile, b.position) - this.spotDots(a.tile, a.position))
                        .sort((a, b) => b.active - a.active)
                        .sort((a, b) => this.buildingDistance(a.tile, a.position, true) - this.buildingDistance(b.tile, b.position, true));

                    setTimeout(() => {
                        this.clickHighlighted(options[0].tile, options[0].position, player);
                            if(this.focusType != 'upgrade second knight') {
                        }
                    }, this.randomNumber(250, 250+this.aiActionSpeed*3));
                } else if(this.focusType == 'select knight to give away') {
                    let options = this.knights.filter(knight => knight.player == player);

                    options.sort((a, b) => a.level - b.level);

                    /**
                     * sorted by:
                     * tile numbers
                     * active or not
                     * blocking house-spot or not
                     */
                    options = options
                        .filter(option => option.level == options[0].level)
                        .sort((a, b) => this.spotDots(b.tile, b.position) - this.spotDots(a.tile, a.position))
                        .sort((a, b) => a.active - b.active)
                        .sort((a, b) => this.buildingDistance(b.tile, b.position, true) - this.buildingDistance(a.tile, a.position, true));

                    setTimeout(() => {
                        this.clickHighlighted(options[0].tile, options[0].position, player);
                    }, this.randomNumber(250, 250+this.aiActionSpeed*3));
                } else if(this.focusType == 'place stolen knight') {

                    let options = [];

                    this.activePlayer.highlightedSpots.forEach(key => {
                        let targetSpot = this.spotByKey(key);

                        options.push(targetSpot);
                    })

                    if(!options.length) return;

                    options.sort((a, b) => this.buildingDistance(a.tile, a.position) - this.buildingDistance(b.tile, b.position));

                    options.sort((a, b) => b.exponentialDots - b.exponentialDots);

                    let randomTime = this.randomNumber(250, 250+this.aiActionSpeed*3);

                    setTimeout(() => {
                        this.clickHighlighted(options[0].tile, options[0].position, this.activePlayer);
                    }, randomTime);

                    setTimeout(() => {
                        this.scanLongestRoad();
                    }, randomTime+500);


                } else if(this.focusType == 'knight to chase away') {

                    let options = [];

                    this.activePlayer.highlightedSpots.forEach(key => {
                        let targetSpot = this.spotByKey(key);

                        let ownRoads = this.spotRoads(targetSpot.tile, targetSpot.position).find(road => road && road.player.color == this.activePlayer.color).length;

                        options.push({ spot: targetSpot, ownRoads: ownRoads });
                    })

                    if(!options.length) return;

                    options.sort((a, b) => b.ownRoads - a.ownRoads);

                    this.clickHighlighted(options[0].spot.tile, options[0].spot.position, this.activePlayer);
                    this.focusModeOff(player);
                // chase away knight, ai just places it on a spot where can't build house
                // this might be used if ai just wants to move knight?
                // zzz
                } else if(['move knight', 'move chased knight', 'try to move knight', 'move knight to break longest'].includes(this.focusType)) {

                    let options = [];

                    player.highlightedSpots.forEach(key => {
                        let targetSpot = this.spotByKey(key);
                        let score = 0;

                        // good spot because of house-distance
                        if(!this.buildingDistance(targetSpot.tile, targetSpot.position)) score += 10;

                        if(this.focusType == 'try to move knight') {
                            if(this.buildingDistance(targetSpot.tile, targetSpot.position)) score -= 10;
                        }

                        // opponent road found
                        if(this.spotRoads(targetSpot.tile, targetSpot.position).find(road => road && road.player != this.activePlayer)) score += 20;

                        // move next to robber
                        if(this.spotTiles(targetSpot.tile, targetSpot.position).find(tile => this.robber.tile == tile)) score += 5;

                        // prefer own road-ends
                        if(this.spotRoads(targetSpot.tile, targetSpot.position).filter(road => road && road.player == this.activePlayer).length == 1) score += 1;
                        // opponent knight found
                        if(targetSpot.content.type != undefined && targetSpot.content.player != this.activePlayer) score += 30;

                        let spotRoadKeys = [];
                        this.spotRoadsAll(targetSpot.tile, targetSpot.position).forEach(roadSpot =>  {
                            if(!roadSpot) return;

                            spotRoadKeys.push(roadSpot.roadSpot.key)
                        });

                        // break longest if possible
                        if(this.longestRoad.length) {
                            if(this.longestRoad.find(longestRoad => spotRoadKeys.includes(longestRoad.key))) score += 200;
                        }

                        options.push({ spot: targetSpot, score: score });
                    })

                    options.sort((a, b) => b.score - a.score);

                    if(this.focusType == 'try to move knight') {
                        let focusKey = 'k' + this.focusPiece.tile.row + '' + this.focusPiece.tile.position + '' + this.focusPiece.position;
                        options = options.filter(option => option.score > 5 && option.spot.key != focusKey)
                    }

                    if(this.focusType == 'move knight to break longest') options = options.filter(option => option.score > 100);

                    if(!options.length) return this.focusModeOff(this.activePlayer);

                    setTimeout(() => {
                        this.clickHighlighted(options[0].spot.tile, options[0].spot.position, player);
                        this.scanLongestRoad();
                    }, this.randomNumber(250, 250+this.aiActionSpeed*3));
                } else if(this.focusType == 'switch number chips') {

                    setTimeout(() => {

                        let options = [];

                        this.buildings
                            .forEach(building => {
                                this.spotTiles(building.tile, building.position).forEach(tile => {
                                    if(tile.type == 'desert' || tile.group != 'land') return;
                                    if([2, 6, 8, 12].includes(tile.number.value)) return;

                                    let target = options.find(obj => obj.tile == tile);
                                    let playerScore = 0;
                                    let opponentScore = 0;

                                    if(building.player == this.activePlayer) {
                                        playerScore = 1;
                                    } else {
                                        opponentScore = 1;
                                    }

                                    if(building.type == 'city') {
                                        if(building.player == this.activePlayer) {
                                            playerScore = 2;
                                        } else {
                                            opponentScore = 2;
                                        }
                                    }

                                    if(target) {
                                        target.players.push(building.player);
                                        target.playerScore += playerScore - (opponentScore/10);
                                        target.opponentScore += opponentScore + (building.player.potential/10);
                                    } else {
                                        options.push({ tile: tile, players: [building.player], playerScore: playerScore - (opponentScore/10), opponentScore: opponentScore + (building.player.potential/10), });
                                    }
                                });
                            });

                        if(options.length == 0) return;

                        options.sort((a, b) => a.opponentScore - b.opponentScore);
                        // moved this donw a step, ai should use inventor on tile with most own buildings.
                        options.sort((a, b) => b.playerScore - a.playerScore);
                        options.sort((a, b) => a.tile.number.dots - b.tile.number.dots);

                        let playersOptions = options.filter(obj => obj.players.includes(this.activePlayer));

                        let opponentOptions = options.filter(obj => !obj.players.includes(this.activePlayer));

                        opponentOptions.sort((a, b) => b.playerScore - a.playerScore);
                        opponentOptions.sort((a, b) => b.opponentScore - a.opponentScore);
                        opponentOptions.sort((a, b) => b.tile.number.dots - a.tile.number.dots);

                        this.tileClicked(opponentOptions[0].tile, this.activePlayer);

                        setTimeout(() => {
                            // players worst nr is 5 or 9
                            if(playersOptions[0].tile.number.dots >= 4) {

                                // find free 11, 3 from the map
                                let badFreeNumbers = this.tiles.filter(tile =>
                                    tile.group == 'land' &&
                                    tile.type != 'desert' &&
                                    tile.number.dots == 2 &&
                                    !options.find(obj => obj.tile == tile)
                                );

                                // no free 11 or 3 exists, fallback to pick one anyway
                                if(!badFreeNumbers.length) {
                                    badFreeNumbers = this.tiles.filter(tile =>
                                        tile.group == 'land' &&
                                        tile.type != 'desert' &&
                                        tile.number.dots == 2
                                    );
                                }

                                // weird bug on Mios map
                                if(badFreeNumbers.length) {
                                    this.tileClicked(badFreeNumbers[0], this.activePlayer);
                                } else {
                                    this.tileClicked(this.tiles.filter(tile => !tile.hidden && [3, 4, 5, 9, 10, 11].includes(tile.number.value))[0], this.activePlayer);
                                }
                            } else {
                                // weird bug on Mios map
                                if(playersOptions.length) {
                                    this.tileClicked(playersOptions[0].tile, this.activePlayer);
                                } else {
                                    this.tileClicked(this.tiles.filter(tile => !tile.hidden && [3, 4, 5, 9, 10, 11].includes(tile.number.value))[0], this.activePlayer);
                                }
                            }
                        }, this.randomNumber(500, this.aiActionSpeed*7));



                    }, this.randomNumber(250, 250+this.aiActionSpeed*4));
                }
            },
            spotDots(tile, position) {
                return this.spotTiles(tile, position)
                    .filter(tile => tile.group == 'land', tile.type != 'desert')
                    .reduce((total, tile) => total + tile.number.dots, 0);
            },
            focusModeOff(player, saveGame = true) {
                let focusTypeWas = JSON.parse(JSON.stringify(this.focusType));

                this.closeModal(player);
                this.activeCardOff();

                this.highlightedRoadSpots = [];
                player.highlightedSpots = [];
                // stop waiting for player who turned focusModeOff
                this.waitingForPlayers = this.waitingForPlayers.filter(color => color != player.color);
                player.waitingForMe = false;


                let waitingForSomeone = false;
                this.players.forEach(player => {
                    if(player.waitingForMe == true) waitingForSomeone = true;
                });

                if(waitingForSomeone == false) {
                    this.focusMode = false;
                    this.focusType = null;
                }

                this.players.forEach(opponent => {
                    opponent.offerOpponents = false;
                    opponent.offerAnswer = null;
                })

                // setTimeout(() => {
                //     // player.showOutgoingHandCards = true;

                //     this.players.forEach(player => {
                //         // player.showIncomingHandCards = true;
                //     });
                // }, 1500)

                if(!this.multiplayer) return;

                if(saveGame == false) return;

                if([
                    'commercial harbor select resource',
                    'commercial harbor select opponent',
                    'select knight to give away',
                    'build 1 road',
                    'select goldmine resources',
                    'move knight'
                ].includes(focusTypeWas)) return;

                if(this.gamePhase == 'regular') {
                    if(this.logToConsole) console.log('focusTypeWas');
                    if(this.logToConsole) console.log(focusTypeWas);

                    if(this.logToConsole) console.log('this.focusType')
                    if(this.logToConsole) console.log(this.focusType)

                    if([
                        'select aqueduct resource',
                        'select a progress card',
                        'select city to destroy',
                        'move merchant',
                        'select 2 hand cards',
                        'commercial harbor select commodity',
                    ].includes(focusTypeWas)) {

                        if(this.logToConsole) console.log('cool');
                        if(this.logToConsole) console.log('SAVE focusModeOff...');

                        if('select a progress card' == focusTypeWas) {
                            // ugly fix but might work anyway
                            this.dicesRolling = false;
                            this.dicesRollAnimation = false;

                            this.savePlayer(player);
                        } else {
                            this.saveGame();
                        }
                    } else {
                        if(focusTypeWas == 'throw away cards') {
                            if(! player.ai) console.log('SAVE focusModeOff');
                            if(! player.ai) this.savePlayer();
                        } else {
                            this.saveGame();
                        }
                    }
                }

            },
            highlightedPlayersOff() {
                this.players.forEach(player => {
                    player.highlightMyPoints = false;
                    player.highlightMyHandCards = false;
                    player.highlightMyProgressCards = false;
                });
            },
            // just for dev
            rollDice(tile_number) {
                if(tile_number < 7 && tile_number != null) {
                    this.rollDices(100, 1, tile_number-1, 'coin');
                } else if(tile_number > 7) {
                    this.rollDices(100, 6, tile_number-6, 'coin');
                } else if(tile_number == null) {
                    this.rollDices(100, 1, 6, 'coin');
                }
            },
            clickOwnDices(player, whiteNumber = null, redNumber = null) {

                if(player.dices.dicesRollAnimation == true) return;

                if(player.dices.whiteDiceRoll > 0 || player.dices.redDiceRoll > 0) return console.log('already rolled');

                // can't roll again before animation is done
                if(player.dices.dicesRollAnimation == false) {
                    player.dices.dicesRollAnimation = true;
                    setTimeout(() => {

                        player.dices.whiteDiceRoll = this.randomNumber(1, 6);
                        if(whiteNumber) {
                           player.dices.whiteDiceRoll = whiteNumber;
                        }

                        // if(player.color == 'red' || player.color == 'blue') player.dices.whiteDiceRoll = 4;
                        player.dices.whiteDiceRolling = false;

                        setTimeout(() => {
                            player.dices.redDiceRoll = this.randomNumber(1, 6);
                            if(redNumber) {
                                player.dices.redDiceRoll = redNumber;
                            }
                            // if(player.color == 'red' || player.color == 'blue') player.dices.redDiceRoll = 5;

                            player.dices.redDiceRolling = false;

                            let roll = Number(player.dices.whiteDiceRoll) + Number(player.dices.redDiceRoll);

                            // if(this.players.filter(player => !player.ai).length == 1) this.log(':playerName rolled ' + roll, player);

                            this.checkHighestRoll();

                            this.savePlayer(player);

                        }, this.randomNumber(500, 500*2));

                    }, this.randomNumber(500, 500*2));
                }
            },
            checkHighestRoll() {
                let waitingForRoll = false;
                let rollsResult = [];

                this.players.forEach(player => {
                    if(player.dices.whiteDiceRoll == null || player.dices.redDiceRoll == null) {
                        waitingForRoll = true;
                    } else {
                        if(player.dices.canBeStartingPlayer == true) {
                            rollsResult.push({ player: player, roll: player.dices.whiteDiceRoll+player.dices.redDiceRoll })
                        }
                    }
                });

                if(waitingForRoll) return;

                rollsResult.sort((a, b) => b.roll - a.roll);

                let losers = rollsResult.filter(result => result.roll < rollsResult[0].roll);

                losers.forEach(loser => {
                    loser.player.dices.canBeStartingPlayer = false;
                });


                rollsResult = rollsResult.filter(result => result.roll == rollsResult[0].roll);

                if(rollsResult.length == 1 || true) {

                    let randomPlayer = this.getRandom(this.players);

                    // if(this.players.filter(player => !player.ai).length == 1) this.log(':playerName rolled ' + rollsResult[0].roll, rollsResult[0].player);
                    // this.log(':playerName is the starting player', rollsResult[0].player, true);
                    this.log(':playerName is the starting player', randomPlayer, true);

                    setTimeout(() => {
                        this.showAllStatuses = false;

                        this.players.forEach(player => {
                            // if(player != rollsResult[0].player) player.dices.fadeAway = true;
                            if(player != randomPlayer) player.dices.fadeAway = true;
                        })

                        // this.activePlayer = rollsResult[0].player;
                        // this.setActivePlayer(rollsResult[0].player)
                        this.setActivePlayer(randomPlayer)

                        // which player builds first, which builds last
                        this.players.forEach(player => {
                            player.buildOrder = player.turnsBeforeYou;
                        })

                        if(this.autoBuild == 1) this.players.find(player => player.color == this.bePlayer.color).ai = 1;

                        // if(this.bePlayerOnEndTurn) this.becomePlayer(rollsResult[0].player.color);
                        if(this.bePlayerOnEndTurn) this.becomePlayer(randomPlayer.color);
                        this.focusModeOn('build initial house', this.activePlayer);

                        // if(this.$route.params.gameId == null) this.gamePhase = 'initial build';

                        this.gamePhase = 'initial build';

                        this.saveGame();

                        this.forceUpdate++;

                        this.gameReady();
                    }, 1200);
                } else {
                    setTimeout(() => {
                        this.players.forEach(player => {
                            if(player.dices.canBeStartingPlayer == true) {
                                player.dices.whiteDiceRolling = true;
                                player.dices.redDiceRolling = true;
                                player.dices.whiteDiceRoll = null;
                                player.dices.redDiceRoll = null;
                                player.dices.dicesRolling = true;
                                player.dices.dicesRollAnimation = false;
                            } else {
                                player.dices.fadeAway = true;
                            }
                        });

                        // ai roll own dices again
                        this.players.forEach(player => {
                            if(player.ai) {
                                setTimeout(() => {
                                    this.clickOwnDices(player);
                                }, this.randomNumber(0, this.aiActionSpeed));
                            }
                        });

                        if(this.logToConsole) console.log('SAVE rolldices');
                        this.saveGame();
                    }, 1200);
                }
            },
            async clickDices(rollSpeed = 500, whiteDice, redDice, boatDice) {
                await this.waitForAllAnimations();

                if(this.dicesRolling == false) {
                    // this.dices.whiteDiceRolling = true;
                    // this.dices.redDiceRolling = true;
                    // this.dices.boatDiceRolling = true;

                    // this.dicesRolling = true;

                    if(this.activePlayer != this.bePlayer) {
                        // not sure about this
                        // this.endTurn();
                    }

                // stop rolling dices from rolling
                } else {
                    this.rollDices(rollSpeed, whiteDice, redDice, boatDice)
                }
            },
            async endTurnClicked() {
                if(this.multiplayer) {
                    if(this.savingGame) return;
                    if(this.loadGameDataCompleted == false) return;
                }

                if(this.bePlayer != this.activePlayer) return;
                if(this.focusMode == true) return;

                await this.waitForAllPlayers();

                if(this.endingTurn == false) {
                    this.endTurn();
                    this.endingTurn = true;
                    setTimeout(() => {
                        this.endingTurn = false;
                    }, 1000)
                }
            },
            async endTurn(backwards) {

                if(this.gamePhase == 'regular') {
                    this.players.forEach(player => {
                        player.handCards.forEach(cardObj => {
                            cardObj.slideOut = 0;
                            cardObj.slideIn = 0;
                        });
                    });

                    if(this.activePlayerTooManyHandCards) {
                        this.players
                            .filter(player => player.ai)
                            .forEach(player => {

                            this.aiChat(["Good luck keeping those cards " + this.activePlayer.name, "Please roll 7"], player, true, 5);
                        })
                    }
                }


                this.players.forEach(player => {
                    this.closeModal(player);
                    player.boatMovedThisTurn = false;
                });

                // this.showAllStatuses = false;
                this.closeActionMenus();

                // if(this.autopilot) {
                //     this.players.find(player => player.color == this.bePlayer.color).ai = 1;
                // } else {
                //     this.players.find(player => player.color == this.bePlayer.color).ai = 0;
                // }

                // Claim victory
                if(this.activePlayer.points >= this.playToPoints) {

                    this.players.forEach(player => {
                        this.showModal('scoreboard', player);
                        this.focusModeOn('game over', player);

                        if(player.ai) this.aiChat(["gg", "gg wp"], player, true, 70);

                        if(this.activePlayer.ai) this.aiChat(["ez"], this.activePlayer, true, 10);
                    })
                    this.gamePhase = 'game over';
                    return this.saveGame(true, true);
                }

                this.knights.forEach(knight => {
                    knight.activatedThisTurn = false;
                    knight.upgradedThisTurn = false;
                });

                this.roads.forEach(road => {
                    road.builtThisTurn = false;
                });

                this.hasTooManyProgressCards(this.activePlayer, true);

                this.players.forEach(player => {
                    this.hasTooManyProgressCards(player);
                });

                if(this.gamePhase == 'regular') await this.waitForAllPlayers();

                this.dices.whiteDiceRolling = true;
                this.dices.redDiceRolling = true;
                this.dices.boatDiceRolling = true;

                this.dicesRolling = true;

                if(this.boatPosition >= 7) this.boatPosition = 0;

                this.activePlayer.tempHarbors = [];

                let activePlayerIndex = this.players.findIndex(player => player == this.activePlayer);

                if(!backwards) {

                    if(activePlayerIndex+1 == this.players.length) {
                        this.activePlayer = this.players[0]
                        // this.setActivePlayer(this.players[0]);
                        if(this.bePlayerOnEndTurn == true) this.becomePlayer(this.players[0].color)
                    } else {
                        this.activePlayer = this.players[activePlayerIndex+1]
                        // this.setActivePlayer(this.players[activePlayerIndex+1]);
                        if(this.bePlayerOnEndTurn == true) this.becomePlayer(this.players[activePlayerIndex+1].color)
                    }
                } else {
                    if(activePlayerIndex == 0) {
                        this.activePlayer = this.players[this.players.length-1]
                        // this.setActivePlayer(this.players[this.players.length-1]);
                    } else {
                        this.activePlayer = this.players[activePlayerIndex-1]
                        // this.setActivePlayer(this.players[activePlayerIndex-1]);
                    }
                }
                if(this.gamePhase == 'regular') {
                    // this.log('Waiting for :playerName to roll the dices', this.activePlayer, true);

                    if(this.activePlayer.ai) {
                        if(this.isHumanBeforeAi()) {
                            await this.aiPlayTurn();
                        }
                    }
                }

                this.scrollTo = this.activePlayer.color;

                this.activePlayer.idleTimer = 0;

                if(this.gamePhase == 'regular') {
                    if(this.logToConsole) console.log('SAVE endTurn');

                    // don't claim victory, but save even if single player game
                    this.saveGame();
                }
            },
            rollDices(rollSpeed, whiteDice, redDice, boatDice, forceRoll = false) {

                if(this.focusMode == true) return;

                if(forceRoll == false) {
                    if(this.dicesRollAnimation == true) return;
                }


                if(this.gamePhase == 'regular') {
                    this.pickedAqueductCards.unshift([{ type: 'lumber', amount: 0 }, { type: 'brick', amount: 0 }, { type: 'wool', amount: 0 }, { type: 'grain', amount: 0 }, { type: 'ore', amount: 0 }]);

                    if(this.pickedAqueductCards.length > this.players.length-1) this.pickedAqueductCards.pop();
                }

                this.diceRollEventsCompleted = false;

                // this.activePlayer.showOutgoingHandCards = true;
                // this.activePlayer.showIncomingHandCards = true;

                this.players.forEach(player => {
                    player.showOutgoingHandCards = true;
                    player.showIncomingHandCards = true;
                });

                this.dices.whiteDiceRolling = true;
                this.dices.redDiceRolling = true;
                this.dices.boatDiceRolling = true;

                this.dicesRolling = true;

                setTimeout(() => {

                    // can't roll again before animation is done
                    if(this.dicesRollAnimation == false) {
                        this.dicesRollAnimation = true;

                        this.dices.whiteDiceRoll = this.randomNumber(1, 6);
                        this.dices.whiteDiceRolling = false;

                        if(whiteDice) {
                            this.dices.whiteDiceRoll = whiteDice;
                        }

                        setTimeout(() => {
                            this.dices.redDiceRoll = this.randomNumber(1, 6);

                            if(redDice) {
                                this.dices.redDiceRoll = redDice;
                            }

                            this.dices.redDiceRolling = false;
                        }, this.randomNumber(rollSpeed, rollSpeed*2));

                        let boatInt = this.randomNumber(0, 5);

                        setTimeout(async () => {

                            this.dices.boatDiceRoll = this.boatDice[boatInt];
                            this.dices.boatDiceRolling = false;

                            let rollTotal = Number(this.dices.whiteDiceRoll) + Number(this.dices.redDiceRoll);
                            if(!whiteDice) this.log(':playerName rolled ' + rollTotal, this.activePlayer, false, rollTotal);

                            if(boatDice == 'boat') {
                                this.dices.boatDiceRoll = this.boatDice[1];
                            }
                            if(boatDice == 'cloth') {
                                this.dices.boatDiceRoll = this.boatDice[0];
                            }

                            if(boatDice == 'coin') {
                                this.dices.boatDiceRoll = this.boatDice[2];
                            }

                            if(boatDice == 'paper') {
                                this.dices.boatDiceRoll = this.boatDice[4];
                            }

                            if(this.dices.boatDiceRoll.type == 'boat') {
                                this.boatPosition++;

                                // mirror the dice just for fun (background position)
                                if([3, 4, 5].indexOf(this.boatPosition) != -1) {
                                    this.dices.boatDiceBackgroundOffset = 108;
                                    this.dices.boatDiceBackgroundOffset2 = 85;
                                } else {
                                    this.dices.boatDiceBackgroundOffset = 0;
                                    this.dices.boatDiceBackgroundOffset2 = 0;
                                }

                                // war
                                if(this.boatPosition == 7 && this.cities.length) {

                                    // this.showAllStatuses = true;

                                    this.firstBoatRound = false;

                                    // victory
                                    let playersActiveKnights = [];
                                    let playersWithCitiesActiveKnights = [];

                                    this.players.forEach(player => {
                                        let activeKnightsTotal = this.knights
                                            .filter(knight => knight.player == player && knight.active == true)
                                            .reduce((activeKnightsTotal, knight) => activeKnightsTotal + knight.level, 0);

                                        playersActiveKnights.push({ player: player, activeKnights: activeKnightsTotal });

                                        if(this.cities.find(city => city.player == player && city.metropolis == false)) {
                                            playersWithCitiesActiveKnights.push({ player: player, activeKnights: activeKnightsTotal });
                                        }
                                    });

                                    if(this.allActiveKnightsCount >= this.cities.length) {

                                        playersActiveKnights.sort((a, b) => b.activeKnights - a.activeKnights);

                                        let rewardPlayers = playersActiveKnights.filter(player => player.activeKnights == playersActiveKnights[0].activeKnights);

                                        if(rewardPlayers.length == 1) {
                                            // multiplayer close knights
                                            this.knights.forEach(knight => {
                                                knight.active = false;
                                            });

                                            this.log(':war War! :playerName got a Victory point', rewardPlayers[0].player);
                                            if(rewardPlayers[0].player.ai) {
                                                this.aiChat(["woohoo!", "yess", "I am good at this"], rewardPlayers[0].player, true, 10);
                                            }
                                            rewardPlayers[0].player.pointCards.push({ label: 'war', points: 1 });
                                            this.updatePlayersPoints();
                                        } else {
                                            // multiplayer close knights
                                            this.knights.forEach(knight => {
                                                knight.active = false;
                                            });

                                            let logMessage = '';
                                            rewardPlayers.forEach(rewardPlayer => {
                                                logMessage += ':war War! <span class="text ' + rewardPlayer.player.color + '">' + rewardPlayer.player.name + '</span> got a Progress card<br>';

                                                this.focusModeOn('select a progress card', rewardPlayer.player);
                                                this.showModal('select a progress card', rewardPlayer.player);
                                            });
                                            this.log(logMessage);
                                            this.saveGame();

                                            // if player has too many progress cards, remove one of them
                                            rewardPlayers.forEach(rewardPlayer => {
                                                this.hasTooManyProgressCards(rewardPlayer.player);
                                            });
                                            this.saveGame();

                                            await this.waitForAllPlayers();

                                            this.saveGame();
                                        }
                                    // destroy cities
                                    } else {

                                        playersWithCitiesActiveKnights.sort((a, b) => a.activeKnights - b.activeKnights);

                                        let punishPlayers = playersWithCitiesActiveKnights.filter(player => player.activeKnights == playersWithCitiesActiveKnights[0].activeKnights);
                                        let logMessage = '';
                                        punishPlayers.forEach(punishPlayer => {
                                            logMessage += ':war War! <span class="text ' + punishPlayer.player.color + '">' + punishPlayer.player.name + '</span> lost a City<br>';
                                            // this.log(':war War! :playerName lost a City', punishPlayer.player);
                                            this.destroyCity(punishPlayer.player);
                                        })
                                        this.log(logMessage);

                                        // multiplayer close knights
                                        this.knights.forEach(knight => {
                                            knight.active = false;
                                        });

                                        if(this.logToConsole) console.log('saveDestroyCities');
                                        this.saveGame();

                                        await this.waitForAllPlayers();
                                    }

                                    this.knights.forEach(knight => {
                                        knight.active = false;
                                    });

                                    if(this.logToConsole) console.log('SAVE close all knights')
                                    // this.saveGame();

                                    // just some delay to make sure everyone saw what happened during war
                                    this.focusModeOn('delay', this.activePlayer);
                                    setTimeout(() => {
                                        this.focusModeOff(this.activePlayer);

                                        this.saveGame();
                                    }, 3000);
                                    await this.waitForAllPlayers();
                                    this.boatPosition = 0;
                                    this.saveGame(false, true);

                                } else if(this.boatPosition == 7 && this.cities.length == 0) {
                                    this.log('No cities = no war');

                                    setTimeout(() => {
                                        this.boatPosition = 0;

                                        if(this.logToConsole) console.log('SAVE no war')
                                        this.saveGame(false, true);
                                    }, 2000);
                                }
                            }

                            setTimeout(async () => {
                                this.diceGeneratedProgressCards(this.dices.whiteDiceRoll, this.dices.redDiceRoll, this.boatDice[boatInt].type);
                                await this.waitForAllPlayers();

                                this.diceTracker.find(obj => obj.number == this.dices.whiteDiceRoll + this.dices.redDiceRoll).count++;

                                // test to save these a bit earlier
                                this.dicesRolling = false;
                                this.dicesRollAnimation = false;

                                // abc
                                // if(this.dices.whiteDiceRoll + this.dices.redDiceRoll == 7 || true) {
                                if(this.dices.whiteDiceRoll + this.dices.redDiceRoll == 7) {

                                    this.players
                                        .filter(player => !player.ai)
                                        .forEach(player => {
                                        player.handCards.forEach(cardObj => {
                                            cardObj.slideIn = 0;
                                            cardObj.slideOut = 0;
                                            cardObj.selected = 0;
                                            cardObj.save = 0;
                                            cardObj.throw = 0;
                                        });
                                    });

                                    // reset goldmine choises
                                    // this.players
                                    //     .filter(player => !player.ai)
                                    //     .forEach(player => {
                                    //     player.goldmineChoises.forEach(cardObj => {
                                    //         cardObj.amount = 0;
                                    //     });
                                    // });

                                    this.updateLocalStorage();

                                    let throwAwayCards = false;

                                    this.players.forEach(player => {
                                        player.showOutgoingHandCards = false;

                                        let cardsCount = player.handCards.reduce((numberOfCards, card) => numberOfCards + card.amount, 0);

                                        let citywallHandCards = this.cities.filter(city => city.player.color == player.color && city.citywall == true).length * 2;

                                        if(cardsCount > (7 + citywallHandCards) && this.ignoreThrowAwayCards == false) {
                                            throwAwayCards = true;
                                            // this.log('Waiting for :playerName to throw away cards', player, true);
                                            if(player.ai) this.aiChat(["#@*!!", "@!!#*", "#!@7S!!!", "crap...", "bad luck..."], player, true, 10);
                                            player.showOutgoingHandCards = false;
                                            this.focusModeOn('throw away cards', player);
                                            this.showModal('throw away cards', player);

                                            this.bePlayerToLocalStorage();
                                        }
                                    })

                                    if(this.logToConsole) console.log('SAVE rolled 7');
                                    this.saveGame();

                                    if(throwAwayCards) await this.waitForAllPlayers();

                                    if(!this.firstBoatRound) {
                                        // this.log('Waiting for :playerName to move the robber', this.activePlayer, true);
                                        await this.waitForAllAnimations();
                                        this.focusModeOn('move robber', this.activePlayer);

                                        if(this.logToConsole) console.log('SAVE moveRobber');
                                        // if(! this.activePlayer.ai) this.saveGame(false, true);
                                        this.saveGame(false, true);

                                        await this.waitForAllAnimations();
                                        await this.waitForAllPlayers();
                                    }
                                } else {
                                    await this.diceGeneratedHandCards(this.dices.whiteDiceRoll, this.dices.redDiceRoll, this.dices.boatDiceRoll.type);
                                    await this.waitForAllPlayers();

                                    await this.goldmineGeneratedHandCards();
                                    await this.waitForAllPlayers();
                                }

                                this.dicesRolling = false;
                                this.dicesRollAnimation = false;
                                this.diceRollEventsCompleted = true;

                                if(this.logToConsole) console.log('SAVE singlePlayer');
                                this.saveGame(false, true);
                            }, 600);
                        }, this.randomNumber(rollSpeed*2, rollSpeed*5));
                    }
                }, rollSpeed);

                this.updatePlayersPoints();
            },
            destroyCity(player) {

                let playerCities = this.cities.filter(city => city.player == player && city.metropolis == false);

                if(playerCities.length == 1) {
                    this.cities = this.cities.filter(city => city != playerCities[0]);

                    if(playerCities[0].citywall) {
                        player.pieces.citywalls++;
                    }

                    player.pieces.cities++;
                    this.buildHouse(playerCities[0].tile, playerCities[0].position, player, true, false);
                } else {
                    playerCities.forEach(city => {
                        let spot = this.spot(city.tile, city.position);
                        this.highlightSpot(spot.key, city.player);
                    });
                    this.focusModeOn('select city to destroy', player)

                    if(player.ai) {
                        this.aiChat(["#@*!!", "@!!#*", "#!@7S!!!", "horrible dice...", "ouch...", "I am going to loose tonights game"], player, true, 30);
                    } else {
                        this.players
                            .filter(ai => player.ai)
                            .forEach(ai => {
                                this.aiChat(["bad luck " + player.name + ". You can do this!", "ouch..."], ai, true, 10);
                            })
                    }
                }
            },
            waitForTradeResponses() {
                let t = this;
                this.aiTradeWaitMs = 0;

                return new Promise(function(resolve, reject) {

                    let check = setInterval(() => {
                        // if no answer, stop waiting after a while
                        if(!t.players.filter(player => player != t.activePlayer).find(player => player.offerAnswer == 'waiting') || t.aiTradeWaitMs > 12000) {
                            clearInterval(check);
                            resolve(true);
                        }
                        t.aiTradeWaitMs += 200
                    }, 200);
                });
            },
            waitForPickProgressCard() {
                let t = this;

                return new Promise(function(resolve, reject) {

                    let check = setInterval(() => {
                        if(t.loadingPrgrCard == false) {
                            clearInterval(check);
                            resolve(true);
                        }
                    }, 200);
                });
            },
            waitForFocusChoise() {
                let t = this;

                return new Promise(function(resolve, reject) {

                    let check = setInterval(() => {
                        if(t.focusChoise != null) {
                            clearInterval(check);
                            resolve(true);
                        }
                    }, 200);
                });
            },
            waitForAllPlayers() {
                let t = this;
                return new Promise(function(resolve, reject) {

                    let check = setInterval(() => {
                        if(t.players.filter(player => player.waitingForMe == true).length == 0) {
                            clearInterval(check);
                            resolve(true);
                        }
                    }, 200);
                });

                // return new Promise(function(resolve, reject) {

                //     let check = setInterval(() => {
                //         if(t.waitingForPlayers.length == 0) {
                //             clearInterval(check);
                //             resolve(true);
                //         }
                //     }, 200);
                // });
            },
            waitForAllAnimations() {
                let t = this;

                return new Promise(function(resolve, reject) {

                    let check = setInterval(() => {
                        if(t.paying == false && t.incoming == false && t.focusMode == false && t.buildingAnimation == false && t.delay == false) {
                            clearInterval(check);
                            resolve(true);
                        }
                    }, 100);
                });
            },
            waitForDiceRollEvents() {
                let t = this;

                return new Promise(function(resolve, reject) {

                    let check = setInterval(() => {
                        if(t.diceRollEventsCompleted == true) {
                            clearInterval(check);
                            resolve(true);
                        }
                    }, 200);
                });
            },
            waitForLoadGameData() {
                let t = this;

                return new Promise(function(resolve, reject) {

                    let check = setInterval(() => {
                        if(t.loadGameDataCompleted == true) {
                            clearInterval(check);
                            resolve(true);
                        }
                    }, 200);
                });
            },
            waitForSaveGameData() {
                let t = this;

                return new Promise(function(resolve, reject) {

                    let check = setInterval(() => {
                        if(t.savingGame == false) {
                            clearInterval(check);
                            resolve(true);
                        }
                    }, 200);
                });
            },
            waitForScanLongest() {
                let t = this;

                return new Promise(function(resolve, reject) {
                    let check = setInterval(() => {
                        if(t.scanningLongest == false) {
                            clearInterval(check);
                            resolve(true);
                        }
                    }, 200);
                });
            },
            pay(player, payingCards) {
                let canPay = true;

                payingCards.forEach(payingCard => {
                    if(player.handCards.find(handCard => handCard.type == payingCard.type).amount < payingCard.amount) canPay = false;
                });

                if(!canPay) return false;
                this.paying = true;

                payingCards.forEach(payingCard => {
                    player.handCards.find(handCard => handCard.type == payingCard.type).slideOut += payingCard.amount;

                    setTimeout(() => {
                        player.handCards.find(handCard => handCard.type == payingCard.type).amount -= payingCard.amount;
                        player.handCards.find(handCard => handCard.type == payingCard.type).slideOut -= payingCard.amount;
                    }, 700)


                });

                setTimeout(() => {
                    this.paying = false;
                }, 1200);
                // }, 700);
                setTimeout(() => {
                    if(! this.activePlayer.ai) this.saveGame();
                }, 700);
                return true;
            },
            canPay(player, payingCards) {
                let canPay = true;

                payingCards.forEach(payingCard => {
                    if(player.handCards.find(handCard => handCard.type == payingCard.type).amount < payingCard.amount) canPay = false;
                });

                if(!canPay) {
                    return false;
                } else {
                    return true;
                }
            },
            randomNumber(min, max) {
                return Math.ceil(Math.random()*(max-(min-1)))+(min-1);
            },
            getRandom(array) {
                return array[Math.floor(Math.random() * array.length)];
            },
            updateTradeCosts() {
                this.players.forEach(player => {
                    // reset all trade-values to 4
                    player.handCards.forEach(cardObj => {
                        cardObj.tradeCost = 4;
                    });

                    if(this.merchant.player == player) {
                        if(this.merchant.tile.type != 'desert') {
                            player.handCards.find(cardObj => cardObj.type == this.merchant.tile.type).tradeCost = 2;
                        }
                    }

                    if(player.tempHarbors.length) {
                        player.tempHarbors.forEach(tempHarbor => {
                            player.handCards.find(cardObj => cardObj.type == tempHarbor).tradeCost = 2;
                        });
                    }

                    if(player.progress.find(progress => progress.type == 'cloth').level >= 3) {
                        player.handCards.forEach(cardObj => {
                            if(cardObj.group == 'commodity') cardObj.tradeCost = 2;
                        });
                    }
                })

                this.allHarborKeys = [];

                this.buildings.forEach(building => {
                    let buildingHarbors = this.harborsBySpotPosition(building.tile, building.position);

                    if(buildingHarbors.length) {
                        buildingHarbors.forEach(harbor => {
                            this.allHarborKeys.push(harbor.key);
                            if(harbor.type != 'harbor-all') {
                                // 2:1
                                let harborCardType = this.harborCardType(harbor.type);

                                building.player.handCards.find(cardObj => cardObj.type == harborCardType).tradeCost = 2;
                            // 3:1
                            } else {
                                building.player.handCards.forEach(cardObj => {
                                    if(cardObj.tradeCost == 4) cardObj.tradeCost = 3;
                                })
                            }
                        });
                    }
                });
            },
            updatePlayersPoints() {
                this.players.forEach(player => {

                    let cityPoints = this.cities
                        .filter(city => city.player.color == player.color)
                        .reduce((playerCitiesTotal, city) => playerCitiesTotal + (city.metropolis ? 4 : 2), 0);

                    let housePoints = this.houses
                        .filter(house => house.player.color == player.color)
                        .reduce((playerHousesTotal, house) => playerHousesTotal + 1, 0);

                    // these points is not longest/merchant, but war-points and paper/coin point from progress-cards
                    let pointCards = player.pointCards
                        .reduce((totalPoints, card) => totalPoints + card.points, 0);

                    let longestPoints = 0;
                    if(this.longestRoad.length && this.longestRoad[0].player == player) longestPoints = 2;

                    let merchantPoints = 0;
                    if(this.merchant.player == player) merchantPoints = 1;

                    player.points = cityPoints + housePoints + pointCards + longestPoints + merchantPoints;

                    // player potential
                    player.potential = player.points;

                    let hasCity = false;

                    // numbers affecting potential
                    this.buildings.filter(building => building.player == player).forEach(building => {
                        this.spotTiles(building.tile, building.position).forEach(tile => {
                            if(tile.group == 'land' && tile.type != 'desert') {
                                player.potential += tile.number.exponentialDots / 25;
                            }
                        })
                        let harbors = this.harborsBySpotPosition(building.tile, building.position);
                        player.potential += harbors.length*0.2;

                        if(building.type == 'city') {
                            hasCity = true;
                            // each city has some extra value, generates tons of resources
                            player.potential += 0.3;
                        }
                    })

                    // commodity upgrades affecting potential
                    player.progress.forEach(progress => {
                        player.potential += progress.level / 10;

                        let aqueductPotential = true;

                        if(this.lateGame) aqueductPotential = false;

                        if(this.leaderAll.points >= this.playToPoints-5) aqueductPotential = false;

                        if(aqueductPotential) {
                            // player has aqueduct
                            if(progress.type == 'paper' && progress.level >= 3) player.potential += 1;
                        }
                    });

                    // player has no city
                    if(!hasCity) {
                        player.potential -= 1;
                        player.potential *= 0.8;
                    }

                    // higher potential if activated knight, saboteur more likely to be played against this opponent
                    if(this.firstBoatRound == true) {
                        let target = this.knights.find(knight => knight.player == player);
                        if(target) {
                            if(target.active) {
                                player.potential += 0.8;
                            } else {
                                player.potential += 0.2;
                            }
                        }
                    }

                    let longestPotential = true;

                    if(this.lateGame) longestPotential = false;

                    if(this.longestRoad.length && this.longestRoad[0].player == this.leaderAll) longestPotential = false;

                    if(longestPotential) {
                        // longest road is points which you can loose easily
                        if(this.longestRoad.length && this.longestRoad[0].player == player) player.potential -= 1.5;
                    }

                    // player is close to winning
                    if(player.points >= this.playToPoints-2) player.potential += 10;

                    // player missing resource(not brick), and has no harbors = lower potential
                    if(this.playerTileTypes(player).length < 5 && this.playerTileTypes(player).includes('brick')) {
                        // && player has no harbors
                        if(player.handCards.filter(cardObj => cardObj.tradeCost < 4).length) {
                            player.potential *= 0.95;
                        }
                    }
                });
            },
            harborCardType(harbor) {
                if(harbor == 'harbor-lumber') {
                    return 'lumber';
                } else if(harbor == 'harbor-brick') {
                    return 'brick';
                } else if(harbor == 'harbor-wool') {
                    return 'wool';
                } else if(harbor == 'harbor-grain') {
                    return 'grain';
                } else if(harbor == 'harbor-ore') {
                    return 'ore';
                }
            },
            selectAlchemist(number, type) {
                if(type == 'white') {
                    if(this.alchemistWhite == number) {
                        this.alchemistWhite = null;
                    } else {
                        this.alchemistWhite = number;
                    }
                } else {
                    if(this.alchemistRed == number) {
                        this.alchemistRed = null;
                    } else {
                        this.alchemistRed = number;
                    }
                }

                if(this.alchemistWhite != null && this.alchemistRed != null) {
                    this.closeModal(this.activePlayer);
                    this.focusModeOff(this.activePlayer);

                    this.rollDices(500, this.alchemistWhite, this.alchemistRed);

                    let rollTotal = Number(this.alchemistWhite) + Number(this.alchemistRed);
                    this.log(':playerName rolled ' + rollTotal + ' with Alchemist', this.activePlayer, false, rollTotal);

                    setTimeout(() => {
                        this.alchemistWhite = null;
                        this.alchemistRed = null;
                    }, 1000)
                }

            },
            giveFeedback(text, duration = 2500) {
                if(this.activePlayer != this.bePlayer) return;

                if(this.feedback != null) clearTimeout(this.hideFeedback);

                this.feedback = text;

                this.hideFeedback = setTimeout(() => {
                    this.feedback = null;
                }, duration);
            },
            aiChat(text = [], player = this.bePlayer, timer = false, percent = 100, duration = 2500) {
                if(! player.ai) return;

                this.chat(text, player, timer, percent, duration);
            },
            chat(text = [], player = this.bePlayer, timer = false, percent = 100, duration = 2500) {
                if(text[0] == '' || text[0] == null) return;
                text = this.getRandom(text);

                if(Math.ceil(Math.random()*100) < percent) {
                    if(player.name == 'Eevee') text = 'mjau, ' + text;

                    // post chat
                    axios.post('/v1/send-message', {
                        data: { gameId: this.$route.params.gameId, text: text, player: player }
                    }).then((response) => {
                        // ...
                    }).catch((error) => {
                        if(this.logToConsole) console.log(error);
                    })
                }
            },
            log(text, player = this.activePlayer, waiting = false, roll = null, visibleFor = null) {
                let status = text.replaceAll(':playerName', '<span class="text '+ player.color +'">' + player.name + '</span>');
                status = status.replaceAll(':activePlayer', '<span class="text '+ this.activePlayer.color +'">' + this.activePlayer.name + '</span>');
                status = status.replaceAll(':lumber', '<span class="symbol lumber"></span>');
                status = status.replaceAll(':brick', '<span class="symbol brick"></span>');
                status = status.replaceAll(':wool', '<span class="symbol wool"></span>');
                status = status.replaceAll(':goldmine', '<span class="symbol goldmine"></span>');
                status = status.replaceAll(':grain', '<span class="symbol grain"></span>');
                status = status.replaceAll(':ore', '<span class="symbol ore"></span>');
                status = status.replaceAll(':cloth', '<span class="symbol cloth"></span>');
                status = status.replaceAll(':coin', '<span class="symbol coin"></span>');
                status = status.replaceAll(':paper', '<span class="symbol paper"></span>');
                status = status.replaceAll(':war', '<span class="symbol war"></span>');
                status = status.replaceAll(':metropolis', '<span class="symbol metropolis"></span>');
                status = status.replaceAll(':progress_cloth', '<span class="symbol cloth-progress"></span>');
                status = status.replaceAll(':progress_coin', '<span class="symbol coin-progress"></span>');
                status = status.replaceAll(':progress_paper', '<span class="symbol paper-progress"></span>');
                status = status.replaceAll(':offset', '<span class="symbol none"></span>');
                status = status.replaceAll(':break', '<br>');

                // multiplayer
                if(this.multiplayer) {
                    // post log
                    axios.post('/v1/send-status', {
                        data: { gameId: this.$route.params.gameId, text: status, player: player, waiting: waiting, roll: roll, visibleFor: visibleFor }
                    }).then((response) => {
                        // ...
                    }).catch((error) => {
                        if(this.logToConsole) console.log(error);
                    });
                // singleplayer
                } else {
                    if(! this.flatGraphics) {
                        status = status.replaceAll('<span class="symbol lumber"></span>', '<span class="symbol symbol-statuses lumber-2"></span>');
                        status = status.replaceAll('<span class="symbol brick"></span>', '<span class="symbol symbol-statuses brick-2"></span>');
                        status = status.replaceAll('<span class="symbol wool"></span>', '<span class="symbol symbol-statuses wool-2"></span>');
                        status = status.replaceAll('<span class="symbol grain"></span>', '<span class="symbol symbol-statuses grain-2"></span>');
                        status = status.replaceAll('<span class="symbol ore"></span>', '<span class="symbol symbol-statuses ore-2"></span>');
                        status = status.replaceAll('<span class="symbol cloth"></span>', '<span class="symbol symbol-statuses cloth-2"></span>');
                        status = status.replaceAll('<span class="symbol coin"></span>', '<span class="symbol symbol-statuses coin-2"></span>');
                        status = status.replaceAll('<span class="symbol paper"></span>', '<span class="symbol symbol-statuses paper-2"></span>');
                        status = status.replaceAll('<span class="symbol goldmine"></span>', '<span class="symbol symbol-statuses goldmine-2"></span>');
                        status = status.replaceAll('<span class="symbol none"></span>', '<span class="symbol-offset-unflat"></span>');
                    }

                    if(!waiting) {
                        if(visibleFor == null || visibleFor == this.bePlayer.color) {
                            this.statuses.push({ label: status, player: player, roll: roll });
                        }
                    }

                    if(this.statuses.length > 6) this.statuses.shift();
                }
            },
            // cred: Viljami Laurila
            replaceRandomResourceTiles(amount = 5, noDesert = false, goldmines = false)
            {
                // Tiles table
                let tilesTable = [
                    'lumber', 'wool', 'grain', 'brick', 'ore', 'desert',
                    'lumber', 'wool', 'grain', 'brick', 'ore',
                    'lumber', 'wool', 'grain', 'brick', 'ore',
                    'lumber', 'wool', 'grain',
                ];

                if(noDesert) {
                    tilesTable = [
                        'lumber', 'wool', 'grain', 'brick', 'ore',
                        'lumber', 'wool', 'grain', 'brick', 'ore',
                        'lumber', 'wool', 'grain', 'brick', 'ore',
                        'lumber', 'wool', 'grain',
                    ];
                }

                if(goldmines) {
                    tilesTable = [
                        'lumber', 'wool', 'grain', 'brick', 'ore', 'goldmine',
                        'lumber', 'wool', 'grain', 'brick', 'ore',
                        'lumber', 'wool', 'grain', 'brick', 'ore',
                        'lumber', 'wool', 'grain',
                    ];
                }

                // Iterate amount times
                return Array(amount)
                    .fill()
                    .map((emptyTile, index) => {

                        // Return tile based on index
                        // % starts again from next round
                        return tilesTable[ index % tilesTable.length ];
                    })
                    .sort((a, b) => {

                        // Randomize
                        return Math.random() > 0.5 ? -1 : 1;
                    });
            },
            replaceRandomNumberTiles(amount = 5)
            {
                // Tiles table
                const tilesTable = [
                    9, 8, 11, 10, 5, 6, 3, 4,
                    9, 8, 11, 10, 12, 5, 6, 3, 4, 2,
                    9, 10, 11, 5, 4, 3,
                    10, 4,
                    12, 2,
                ];

                // Iterate amount times
                return Array(amount)
                    .fill()
                    .map((emptyTile, index) => {

                        // Return tile based on index
                        // % starts again from next round
                        return tilesTable[ index % tilesTable.length ];
                    })
                    .sort((a, b) => {

                        // Randomize
                        return Math.random() > 0.5 ? -1 : 1;
                    });
            },
            replaceRandomHarborTiles(amount = 5)
            {
                // Tiles table
                const tilesTable = [
                    'harbor-wool', 'harbor-lumber', 'harbor-grain', 'harbor-brick', 'harbor-ore',
                ];

                // Iterate amount times
                return Array(amount)
                    .fill()
                    .map((emptyTile, index) => {

                        // Return tile based on index
                        // % starts again from next round
                        return tilesTable[ index % tilesTable.length ];
                    })
                    .sort((a, b) => {

                        // Randomize
                        return Math.random() > 0.5 ? -1 : 1;
                    });
            },
            shuffle(array) {
                var currentIndex = array.length, temporaryValue, randomIndex;

                // While there remain elements to shuffle...
                while (0 !== currentIndex) {

                    // Pick a remaining element...
                    randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex -= 1;

                    // And swap it with the current element.
                    temporaryValue = array[currentIndex];
                    array[currentIndex] = array[randomIndex];
                    array[randomIndex] = temporaryValue;
                }

                return array;
            },
        },
        computed: {
            diceTrackerMax() {
                return this.diceTracker.filter(() => true).sort(function(a, b) {
                    return b.count - a.count;
                })[0];
            },
            diceTrackerPopular() {
                let expected = [
                    { number: 2, probability: 0.0278},
                    { number: 12, probability: 0.0278},
                    { number: 3, probability: 0.0556},
                    { number: 11, probability: 0.0556},
                    { number: 4, probability: 0.0833},
                    { number: 10, probability: 0.0833},
                    { number: 5, probability: 0.111},
                    { number: 9, probability: 0.111},
                    { number: 6, probability: 0.139},
                    { number: 8, probability: 0.139},
                    { number: 8, probability: 0.167},
                ]
                return this.diceTracker.filter(obj => obj.number != 7).sort(function(a, b) {
                    return b.count/expected.find(obj => obj.number == b.number).probability - a.count/expected.find(obj => obj.number == a.number).probability;
                });
            },
            orderedOpponents: function () {
                let opponents = this.opponents;
                if(opponents.length) {
                    opponents.sort((a, b) => a.position - b.position);

                    opponents.sort((a, b) => {
                        if(this.bePlayer) {
                            if(a.position < this.bePlayer.position) {
                                return 1;
                            } else if(b.position < this.bePlayer.position) {
                                return -1;
                            }
                        } else {
                            return 1;
                        }
                    });
                }

                return opponents;
            },
            boat() {
                if(this.boatPosition == 7) {
                    return [];
                } else if(this.boatPosition >= 5) {
                    return ['critical', 'deadly'];
                } else if(this.boatPosition >= 7 - this.players.length) {
                    return ['critical'];
                } else {
                    return [];
                }
            },
            leader() {
                let playersCopy = JSON.parse(JSON.stringify(this.players));
                let leaderColor =  playersCopy
                    .filter(player => player.color != this.activePlayer.color)
                    .sort((a, b) => b.potential - a.potential)[0].color;
                return this.selectPlayer(leaderColor);
            },
            leaderAll() {
                let playersCopy = JSON.parse(JSON.stringify(this.players));
                let leaderColor = playersCopy.sort((a, b) => b.potential - a.potential)[0].color;
                return this.selectPlayer(leaderColor);
            },
            lateGame() {
                if(this.leaderAll.points >= this.playToPoints-4) {
                    return true;
                } else {
                    return false;
                }
            },
            allActiveKnightsCount() {
                return this.knights
                    .filter(knight => knight.active)
                    .reduce((activeKnightsTotal, knight) => activeKnightsTotal + knight.level, 0);
            },
            getHighlightedRoadSpots() {
                return this.waitingForPlayers.find(color => color == this.bePlayer.color) ? this.highlightedRoadSpots : [];
            },
            buildings() {
                return [...this.houses, ...this.cities];
            },
            allowedHandCardsBePlayer() {
                return 7 + this.cities.filter(city => city.player == this.bePlayer && city.citywall == true).length * 2;
            },
            activePlayerAllowedHandCardsCount() {
                return 7 + this.cities.filter(city => city.player == this.activePlayer && city.citywall == true).length * 2;
            },
            activePlayerHandCardsCount() {
                return this.activePlayer.handCards.reduce((numberOfCards, card) => numberOfCards + card.amount, 0);
            },
            activePlayerCommodityCardsCount() {
                return this.activePlayer.handCards
                    .filter(obj => ['cloth', 'coin', 'paper'].includes(obj.type))
                    .reduce((numberOfCards, card) => numberOfCards + card.amount, 0);
            },
            activePlayerAllowedHandCardsCount() {
                return 7 + this.cities.filter(city => city.player == this.activePlayer && city.citywall == true).length * 2;
            },
            activePlayerTooManyHandCards() {
                return this.activePlayerHandCardsCount > this.activePlayerAllowedHandCardsCount;
            },
            activePlayerCheapestTradeTypes() {
                let handCardsCopy = JSON.parse(JSON.stringify(this.activePlayer.handCards));

                handCardsCopy = handCardsCopy.sort((a,b) => a.tradeCost - b.tradeCost);

                let theRest = handCardsCopy.filter(obj => obj.tradeCost != handCardsCopy[0].tradeCost);

                handCardsCopy = handCardsCopy.filter(obj => obj.tradeCost == handCardsCopy[0].tradeCost);

                handCardsCopy = handCardsCopy.sort((a,b) => a.cardValue - b.cardValue);

                let result = [];

                handCardsCopy.forEach(obj => {
                    result.push(obj.type);
                });

                theRest.forEach(obj => {
                    result.push(obj.type);
                });

                // don't trade away commodities which you're fighting for
                result = result.filter(obj => !this.activePlayer.fightFor.includes(obj.type));

                return result;
            },
            activePlayerDots() {
                let tileTypeDots = [
                    { type: 'lumber', dots: 0, },
                    { type: 'brick', dots: 0, },
                    { type: 'wool', dots: 0, },
                    { type: 'grain', dots: 0, },
                    { type: 'ore', dots: 0, },
                    { type: 'goldmine', dots: 0, },
                ];

                // check which one of the missing cards is hardest for player to get
                this.buildings.filter(building => building.player == this.activePlayer).forEach(building => {
                    this.spotTiles(building.tile, building.position).forEach(tile => {
                        if(tile.group != 'land' || tile.type == 'desert') return;

                        tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.dots;
                        if(building.type == 'city' && building.disabled == false && ['brick', 'grain'].includes(tile.type)) {
                            tileTypeDots.find(obj => obj.type == tile.type).dots += tile.number.dots;
                        }
                    });
                });

                return tileTypeDots;
            },
            miningIrrigationCount() {
                let uniqueOreTiles = [];
                let uniqueGrainTiles = [];

                this.buildings
                    .filter(building => building.player == this.bePlayer)
                    .forEach(building => {
                        this.spotTiles(building.tile, building.position).forEach(tile => {

                        if(tile.type == 'ore') {
                            let oreTarget = uniqueOreTiles.find(oreTile => oreTile == tile)

                            if(!oreTarget) {
                                uniqueOreTiles.push(tile);
                            }
                        }

                        if(tile.type == 'grain') {
                            let grainTarget = uniqueGrainTiles.find(grainTile => grainTile == tile)

                            if(!grainTarget) {
                                uniqueGrainTiles.push(tile);
                            }
                        }
                    })
                })

                return { mining: uniqueOreTiles.length*2, irrigation: uniqueGrainTiles.length*2 };
            },
            activePlayerOreTilesCount() {
                let uniqueOreTiles = [];

                this.buildings
                    .filter(building => building.player == this.activePlayer)
                    .forEach(building => {
                        this.spotTiles(building.tile, building.position).forEach(tile => {

                        if(tile.type == 'ore') {
                            let oreTarget = uniqueOreTiles.find(oreTile => oreTile == tile)

                            if(!oreTarget) {
                                uniqueOreTiles.push(tile);
                            }
                        }
                    })
                })

                return uniqueOreTiles.length;
            },
            playersByPoints() {
                let playersCopy = JSON.parse(JSON.stringify(this.players));

                playersCopy.sort((a, b) => {
                    if(this.activePlayer.color == a.color) return -1;
                });

                return playersCopy
                    .sort((a, b) => b.points - a.points);
            },
            tileTypeDots() {
                let tiles = this.tiles.filter(tile => tile.group == 'land' && tile.type != 'desert');

                return {
                    lumber: tiles.filter(tile => tile.type == 'lumber').reduce((dotsTotal, tile) => dotsTotal + tile.number.dots, 0),
                    brick: tiles.filter(tile => tile.type == 'brick').reduce((dotsTotal, tile) => dotsTotal + tile.number.dots, 0),
                    wool: tiles.filter(tile => tile.type == 'wool').reduce((dotsTotal, tile) => dotsTotal + tile.number.dots, 0),
                    grain: tiles.filter(tile => tile.type == 'grain').reduce((dotsTotal, tile) => dotsTotal + tile.number.dots, 0),
                    ore: tiles.filter(tile => tile.type == 'ore').reduce((dotsTotal, tile) => dotsTotal + tile.number.dots, 0),
                };
            },
            tileTypeDotsAverage() {
                let tiles = this.tiles.filter(tile => tile.group == 'land' && tile.type != 'desert');

                let averages = [
                    { type: 'lumber', average: (tiles.filter(tile => tile.type == 'lumber').reduce((dotsTotal, tile) => dotsTotal + tile.number.dots, 0) / tiles.filter(tile => tile.type == 'lumber').length).toFixed(2), },
                    { type: 'brick', average: (tiles.filter(tile => tile.type == 'brick').reduce((dotsTotal, tile) => dotsTotal + tile.number.dots, 0) / tiles.filter(tile => tile.type == 'brick').length).toFixed(2), },
                    { type: 'wool', average: (tiles.filter(tile => tile.type == 'wool').reduce((dotsTotal, tile) => dotsTotal + tile.number.dots, 0) / tiles.filter(tile => tile.type == 'wool').length).toFixed(2), },
                    { type: 'grain', average: (tiles.filter(tile => tile.type == 'grain').reduce((dotsTotal, tile) => dotsTotal + tile.number.dots, 0) / tiles.filter(tile => tile.type == 'grain').length).toFixed(2), },
                    { type: 'ore', average: (tiles.filter(tile => tile.type == 'ore').reduce((dotsTotal, tile) => dotsTotal + tile.number.dots, 0) / tiles.filter(tile => tile.type == 'ore').length).toFixed(2), },
                ];

                averages = averages.sort((a, b) => a.average - b.average);

                return averages;
            },
            playersWithLeastActiveKnights() {
                let result = [];

                let activeKnightsCount = 0;
                this.players.forEach(player => {

                    activeKnightsCount = this.knights
                        .filter(knight => knight.player == player && knight.active)
                        .reduce((activeKnightsTotal, knight) => activeKnightsTotal + knight.level, 0);

                    if(this.cities.find(city => city.player == player && city.metropolis == false)) {
                        result.push({ player: player, activeKnights: activeKnightsCount });
                    }
                });

                // sort by least active to most active
                result.sort((a, b) => a.activeKnights - b.activeKnights);

                // filter away every player who has more knights than the player with least
                result = result.filter(res => res.activeKnights == result[0].activeKnights);

                return result;
            },
            playersByMostActiveKnights() {
                let result = [];

                this.players.forEach(player => {
                    let activeKnightsCount = this.knights
                        .filter(knight => knight.player == player && knight.active)
                        .reduce((activeKnightsTotal, knight) => activeKnightsTotal + knight.level, 0);

                    if(this.cities.find(city => city.player == player)) {
                        result.push({ player: player, activeKnights: activeKnightsCount });
                    }
                });

                // sort by least active to most active
                result.sort((a, b) => b.activeKnights - a.activeKnights);

                return result;
            },
            opponentWithMostActiveKnights() {
                // .player
                // .activeKnightsCount
                return this.playersByMostActiveKnights.filter(obj => obj.player != this.activePlayer)[0];
            },
            activePlayerActiveKnights() {
                return this.knights
                    .filter(knight => knight.player == this.activePlayer && knight.active)
                    .reduce((activeKnightsTotal, knight) => activeKnightsTotal + knight.level, 0);
            },
        },
        components: {
            Tile,
            MainPlayer,
            Opponent,
            ProgressCard,
            ProgressCardInfo,
            HandCards,
            HandCard,
            TradeOffer,
            Knight,
            City,
            Avatar,
        }
    }
</script>

<style>

</style>
