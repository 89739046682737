<template>
    <div @click="makeTrade(player)">
        <div class="get-trade-selected-wrapper">
            <div class="icon-wrapper">
                <div class="animated-chevrons-wrapper">
                    <i class="fa fa-chevron-down animated-down"></i>
                </div>
            </div>
            <div class="player-trade-color background" :class="player.color" v-if="!counterTrade"></div>
            <div class="player-trade-color background" :class="activePlayer.color" v-else></div>
            <small class="waiting-for-answer" v-if="player.offerAnswer == 'waiting' && player != activePlayer && !counterTrade">Waiting<span v-for="dot in numberOfDots" :key="dot">.</span></small>
            <span v-for="(cardObj, index) in player.tradeGet" :key="index+20" :class="{ 'get-reversed': reversed }" v-show="player.offerAnswer != 'waiting' || bePlayer == player">
                <div v-for="(card, index) in cardObj['amount']"
                    :key="index+30"
                    class="hand-card-small basic"
                    :class="[
                        cardObj.type,
                        {'commodity': cardObj.group == 'commodity'},
                        {'hand-card-small-2': ! flatGraphics },
                    ]"
                    @click="amount(cardObj)"
                ></div>
            </span>
        </div>
        <div class="clear-both"></div>
        <div class="give-trade-selected-wrapper">
            <div class="icon-wrapper">
                <div class="animated-chevrons-wrapper">
                    <i class="fa fa-chevron-up animated-up"></i>
                </div>
            </div>
            <span v-for="(cardObj, index) in player.handCards.slice().reverse()" :key="index+40" :class="{ 'give-reversed': reversed }" v-show="player.offerAnswer != 'waiting' || bePlayer == player">
                <div v-for="(card, index) in cardObj['selected']"
                    :key="index+50"
                    class="hand-card-small basic"
                    :class="[
                        cardObj.type,
                        {'commodity': cardObj.group == 'commodity'},
                        {'hand-card-small-2': ! flatGraphics },
                    ]"
                    @click="unSelect(cardObj)"
                ></div>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['player', 'reversed', 'activePlayer', 'bePlayer', 'counterTrade', 'canClickCards', 'flatGraphics'],
    data() {
        return {
            numberOfDots: 0,
        }
    },
    beforeMount() {
        setInterval(() => {
            if(this.numberOfDots == 3) this.numberOfDots = -1;

            this.numberOfDots++;
        }, 400)
    },
    methods: {
        makeTrade(player) {
            this.$emit('makeTrade', player);

            this.$forceUpdate();
        },
        unSelect(cardObj) {
            this.$emit('resetOffer');

            if(this.canClickCards == false) {
                this.makeTrade(this.player);
                return;
            }
            cardObj.selected--;
            cardObj.slideOut--;
            this.$emit('updateBankOffers');
        },
        amount(cardObj) {
            this.$emit('resetOffer');

            if(this.canClickCards == false) {
                this.makeTrade(this.player);
                return;
            }
            cardObj.amount--;
            this.$emit('updateBankOffers');
        },
    },
}
</script>

<style>
.waiting-for-answer {
    top: 40px;
    left: 8px;
    position: absolute;
    color: #AC9A76;
}
.get-reversed {
    position: relative;
    top: 56px;
}
.give-reversed {
    position: relative;
    top: -56px;
}
</style>
