<template>
    <div class="progress-card-wrapper">
        <div
            class="progress-card-actions"
            :class="card.deck"
        >
            <div class="w-100 text-center" @click="activeCardOff()">
                <div class="icon-wrapper">
                    <i class="fa fa-chevron-down" style="padding-top: 5px;"></i>
                </div>
            </div>
            <div class="w-100 text-center" @click="toggleShowInfo(card)">
                <div class="icon-wrapper"><i class="fa fa-info-circle"></i></div>
            </div>
        </div>
        <div
            class="progress-card front"
            :class="[
                card.deck,
                {'progress-card-active': activeCard == card},
                {'progress-card-highlighted': modalPlayer == player && focusType == 'steal progress card' && index == spyIndex }
            ]"
        >
            <div class="progress-card-content" :class="{ 'progress-card-show-info': showInfo == card }">
                <div class="progress-card-main" @click="playProgressCard(player, card, index)">
                    <p class="label">{{ card.label }}</p>
                    <div class="image-wrapper">
                        <div class="progress-image"
                            :class="card.label.replace(/\s+/g, '-')"
                            v-if="flatGraphics"
                        ></div>

                        <div class="progress-image"
                            :class="card.label.replace(/\s+/g, '-')+'-2'"
                            v-if="! flatGraphics"
                        ></div>

                        <div v-if="card.label == 'warlord'">
                            <Knight3D :color="player.color" :level="3" :active="true" style="top: 27px; left: 12px;" />
                            <Knight3D :color="player.color" :level="2" :active="true" style="top: 21px; left: 41px;" />
                            <Knight3D :color="player.color" :level="1" :active="true" :size="'xl'" style="top: 36px; left: 29px;" />
                        </div>
                        <div v-else-if="card.label == 'smith'">
                            <Knight3D :color="player.color" :level="2" :active="false" :size="'xl'" style="top: 34px; left: 10px;" />
                            <Knight3D :color="player.color" :level="2" :active="false" :size="'xl'" style="top: 26px; left: 40px;" />
                        </div>
                        <div v-else-if="card.label == 'road building'">
                            <Roads3D :color="player.color" style="top: 29px; left: 9px;" />
                        </div>
                        <div v-else-if="card.label == 'medecine'">
                            <City
                                :color="player.color"
                                style="top: 27px; left: 16px;"
                            />
                        </div>
                        <div v-else-if="card.label == 'engineer'">
                            <Citywall3D :color="player.color" style="top: 26px; left: 15px; width: 46px;"/>
                        </div>
                        <div v-else-if="card.label == 'deseter'">
                            <Knight3D :color="'neutral'" :level="1" :active="true" style="top: 19px; left: 22px;" />
                            <Knight3D :color="player.color" :level="1" :active="true" :size="'xl'" style="top: 34px; left: 28px" />
                        </div>
                        <div v-else-if="card.label == 'intrigue'">
                            <Knight3D :color="'neutral'" :level="1" :active="true" :size="'xl'" style="top: 27px; left: 9px;" />
                            <Road3D :color="player.color" style="top: 35px; left: 32px; width: 38px;" />
                        </div>
                        <div v-else-if="card.label == 'irrigation'">
                            <span class="mining-irrigation-count">{{ miningIrrigationCount.irrigation }}</span>
                        </div>
                        <div v-else-if="card.label == 'mining'">
                            <span class="mining-irrigation-count">{{ miningIrrigationCount.mining }}</span>
                        </div>
                    </div>
                </div>
            <div class="progress-card-info" :class="card.deck">
                <p>{{ progressCardDescriptions[card.label] }}
                    <span v-if="card.label == 'irrigation'">Right now it would give you {{ miningIrrigationCount.irrigation }} grain cards.</span>
                    <span v-if="card.label == 'mining'">Right now it would give you {{ miningIrrigationCount.mining }} ore cards.</span>
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import City from './pieces/City';
import Knight3D from './pieces/Knight3D';
import Citywall3D from './pieces/Citywall3D';
import Roads3D from './pieces/Roads3D';
import Road3D from './pieces/Road3D';

export default {
    props: ['card', 'index', 'activeCard', 'player', 'focusType', 'spyIndex', 'modalPlayer', 'showInfo', 'floatLeft', 'miningIrrigationCount', 'flatGraphics'],
    data() {
        return {
            progressCardDescriptions: {
                "knight": "Move the robber. Player with most(min 3) open knight cards gets the largest army.",
                "monopoly": "Select a resource, opponents must give you all of that kind.",
                "year of plenty": "Pick 2 resource cards from the bank.",
                "point": "1 Point. One or multiple point cards can be played on your turn.",
                "resource monopoly": "Pick a resource, get max 2 from each opponent.",
                "merchant": "Pick one of your tiles, now you can trade that resource 2:1. Having the merchant gives 1 point.",
                "master merchant": "Pick a opponent with more points than you. See opponents hand cards and steal 2 of them.",
                "merchant fleet": "Pick any hand card, trade it 2:1 for the rest of your turn.",
                "commercial harbor": "Give a resource to each opponent you want, get a commodity back.",
                "trade monopoly": "Pick a commodity, get one from each player.",
                "deseter": "Pick a opponent, the opponent has to give you one of their knights.",
                "spy": "Pick a opponent, see the progress cards and steal one of them.",
                "diplomat": "Remove opponents road or move your own road. Roads protected by knights can't be removed.",
                "warlord": "Activate all your knights.",
                "saboteur": "Flip opponents city. It still counts as a city but produces cards like a house.",
                "intrigue": "Force opponents knight connected to your road to move away.",
                "bishop": "Move the robber, steal 1 card from every player you rob.",
                "wedding": "Get 2 cards from each opponent with more points than you.",
                "inventor": "Switch 2 number chips. Not allowed: 6, 8, 2, 12.",
                "medecine": "Build a city for 1 grain and 2 ore.",
                "mining": "Get 2 ore resources from each ore tile your buildings touch.",
                "road building": "Build 2 roads for free.",
                "smith": "Upgrade max 2 knights for free.",
                "irrigation": "Get 2 grain resources from each grain tile your buildings touch.",
                "crane": "Upgrade a commodity for one card less than normally.",
                "alchemist": "Select white and red dice numbers, then roll boat dice.",
                "engineer": "Build a citywall for free.",
            },
        }
    },
    methods: {
        activeCardOff() {
            this.$emit('activeCardOff');

            this.$forceUpdate();
        },
        playProgressCard(player, card, index) {
            this.$emit('playProgressCard', player, card, index);

            this.$forceUpdate();
        },
        toggleShowInfo(card) {
            this.$emit('toggleShowInfo', card);

            this.$forceUpdate();
        },
    },
    components: {
        City,
        Knight3D,
        Citywall3D,
        Roads3D,
        Road3D,
    }
}
</script>

<style>

</style>
