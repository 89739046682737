import Gotland from "./components/Gotland"
import Basic from "./components/Basic"
import MapEditor from "./components/map/editor"

export default [
    {
        path: '/games/:gameId',
        name: 'games',
        component: Gotland
    },
    {
        path: '/basic/games/:gameId',
        name: 'basic games',
        component: Basic
    },
    {
        path: '/map-editor',
        name: 'map-editor',
        component: MapEditor
    },
]