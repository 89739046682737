<template>
    <div class="roads">
        <svg width="63" height="30" viewBox="0 0 47 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.4391 8.53286L3.49442 18.1517L0.414963 16.8441L0 12.5666L19.5033 4.60996L19.9182 1.35196L20.901 0L47 5.09755L46.8034 6.71547L46.0172 10.5497L24.3518 6.20572L24.4391 8.53286Z" :fill="playerColors[color]" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.49442 18.0339L24.4391 8.55525L24.3518 6.26203L46.0172 10.5427L46.8034 6.76436L46.8077 6.73006L19.9138 1.51306L19.5033 4.68954L19.4166 4.72439L19.4596 5.32293L21.9493 5.80342L3.05762 13.7532L3.45234 18.0163L3.49442 18.0339Z" fill="black" fill-opacity="0.1"/>
        </svg>
    </div>
</template>

<script>

import colors from '../../helpers/colors.js'

export default {
    mixins: [
        colors,
    ],
    props: {
        color: String,
    },
    data() {
        return {

        }
    }
}
</script>

<style>

</style>
