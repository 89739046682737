<template>
    <div class="house" :class="[{ 'highlighted': highlighted }, { 'build-house-animation': animate },]">
        <svg width="28" height="28" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4803 29.9306L16.8494 30.0758L17.2176 29.9284L30.3714 24.6663L31.0128 24.4098L30.9998 23.7191L30.7768 11.8527C30.7659 11.2746 30.4846 10.7473 30.0358 10.4062L29.4307 11.2023L30.0358 10.4062L24.2708 6.02425C24.0596 5.86373 23.825 5.75352 23.5836 5.69091L16.7196 3.25033L16.7095 3.24675L16.6994 3.24338L10.2489 1.10422C9.4299 0.826318 8.49287 1.11525 8.00108 1.85939L2.16573 10.689L1.98884 10.9567L2.00068 11.2773L2.46222 23.7748L2.48638 24.429L3.09565 24.6685L16.4803 29.9306Z" :fill="playerColors[color]" stroke="#383832" stroke-width="2.5"/>
            <path d="M16.3941 16.5454L16.8461 29L29.9999 23.7379L29.7769 11.8715C29.772 11.6106 29.6451 11.3653 29.4306 11.2023L23.6656 6.8204C23.231 6.49007 22.5898 6.59754 22.3004 7.04923L16.5281 16.0592C16.4344 16.2053 16.3879 16.3744 16.3941 16.5454Z" fill="black" fill-opacity="0.1"/>
            <path v-if="extraIslandPoint" fill-rule="evenodd" clip-rule="evenodd" d="M3.3562 21.2234L16.9875 26.5L29.942 21.3182L29.9875 23.7379L16.8337 29L3.44906 23.7379L3.3562 21.2234Z" fill="url(#paint0_linear)"/>
            <defs>
            <linearGradient id="paint0_linear" x1="15.5" y1="13" x2="23" y2="35.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E2C45A" stop-opacity="0.46"/>
            <stop offset="1" stop-color="#F3E13B"/>
            </linearGradient>
            </defs>
        </svg>
    </div>
</template>

<script>

import colors from '../../helpers/colors.js'

export default {
    mixins: [
        colors,
    ],
    props: {
        color: String,
        animate: Boolean,
        highlighted: Boolean,
        extraIslandPoint: Boolean,
    },
}
</script>

<style>

</style>
