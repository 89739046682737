var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.forceUpdate)?_c('span'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"action-spot-wrapper"},[(_vm.tile.spots[_vm.spotPosition].actions.length)?_c('div',{staticClass:"actions-wrapper"},_vm._l((_vm.tile.spots[_vm.spotPosition].actions),function(action){return _c('div',{key:action,on:{"click":function($event){return _vm.spotActionClicked(_vm.tile, _vm.spotPosition, action)}}},[(action == 'build house')?_c('div',{staticClass:"action",class:[{ 'action-confirmed': _vm.actionConfirmed == 'build house' }]},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"house",class:[
                                _vm.activePlayer.color,
                                { 'opacity-30': _vm.activePlayer.pieces.houses == 0 },
                                { 'opacity-30': _vm.buildingDistanceActions == false } ]}),_vm._v(" "),_c('div',{staticClass:"items-left",staticStyle:{"left":"30px","top":"-1px"}},[_vm._v(_vm._s(_vm.activePlayer.pieces.houses))])]),_vm._v(" "),(_vm.flatGraphics)?_c('div',{staticClass:"action-cost"},[_c('div',{staticClass:"symbol lumber"}),_vm._v(" "),_c('div',{staticClass:"symbol brick"}),_vm._v(" "),_c('div',{staticClass:"symbol grain"}),_vm._v(" "),_c('div',{staticClass:"symbol wool"})]):_c('div',{staticClass:"action-cost-2"},[_c('div',{staticClass:"symbol-2 lumber-2"}),_vm._v(" "),_c('div',{staticClass:"symbol-2 brick-2"}),_vm._v(" "),_c('div',{staticClass:"symbol-2 grain-2"}),_vm._v(" "),_c('div',{staticClass:"symbol-2 wool-2"})])]):(action == 'build knight')?_c('div',{staticClass:"action",class:[{ 'action-confirmed': _vm.actionConfirmed == 'build knight' }]},[_c('div',{staticClass:"item"},[_c('Knight3D',{staticClass:"knight-item",class:[
                            { 'opacity-30': _vm.activePlayer.pieces.knights.filter(function (knight) { return knight == 1; }).length == 0 } ],attrs:{"color":_vm.activePlayer.color,"level":1,"active":false}}),_vm._v(" "),_c('div',{staticClass:"items-left",staticStyle:{"left":"30px","top":"-1px"}},[_vm._v(_vm._s(_vm.activePlayer.pieces.knights.filter(function (knight) { return knight == 1; }).length))])],1),_vm._v(" "),(_vm.flatGraphics)?_c('div',{staticClass:"action-cost"},[_c('div',{staticClass:"symbol wool"}),_vm._v(" "),_c('div',{staticClass:"symbol ore"})]):_c('div',{staticClass:"action-cost-2"},[_c('div',{staticClass:"symbol-2 wool-2"}),_vm._v(" "),_c('div',{staticClass:"symbol-2 ore-2"})])]):(action == 'upgrade to city')?_c('div',{staticClass:"action",class:[{ 'action-confirmed': _vm.actionConfirmed == 'upgrade to city' }]},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"city",class:[
                                _vm.house.player.color ]})]),_vm._v(" "),_c('div',{staticClass:"items-left",staticStyle:{"left":"38px","top":"2px"}},[_vm._v(_vm._s(_vm.activePlayer.pieces.cities))]),_vm._v(" "),(_vm.flatGraphics)?_c('div',{staticClass:"action-cost"},[_c('div',{staticClass:"symbol grain"}),_vm._v(" "),_c('div',{staticClass:"symbol grain"}),_vm._v(" "),_c('div',{staticClass:"symbol ore"}),_vm._v(" "),_c('div',{staticClass:"symbol ore"}),_vm._v(" "),_c('div',{staticClass:"symbol ore"})]):_c('div',{staticClass:"action-cost-2"},[_c('div',{staticClass:"symbol-2 grain-2"}),_vm._v(" "),_c('div',{staticClass:"symbol-2 grain-2"}),_vm._v(" "),_c('div',{staticClass:"symbol-2 ore-2"}),_vm._v(" "),_c('div',{staticClass:"symbol-2 ore-2"}),_vm._v(" "),_c('div',{staticClass:"symbol-2 ore-2"})])]):(action == 'activate knight')?_c('div',{staticClass:"action",class:[{ 'action-confirmed': _vm.actionConfirmed == 'activate knight' }]},[_c('div',{staticClass:"item"},[_c('Knight3D',{staticClass:"knight-item",attrs:{"color":_vm.activePlayer.color,"level":_vm.knight.level,"active":true}})],1),_vm._v(" "),(_vm.flatGraphics)?_c('div',{staticClass:"action-cost"},[_c('div',{staticClass:"symbol grain"})]):_c('div',{staticClass:"action-cost-2"},[_c('div',{staticClass:"symbol-2 grain-2"})])]):(action == 'upgrade knight')?_c('div',{staticClass:"action",class:[{ 'action-confirmed': _vm.actionConfirmed == 'upgrade knight' }]},[_c('div',{staticClass:"item"},[_c('Knight3D',{staticClass:"knight-item",class:[
                            { 'opacity-30': _vm.knight.upgradedThisTurn },
                            { 'opacity-30': _vm.knight.level == 2 && _vm.knight.player.progress.find(function (prgr) { return prgr.type == 'coin'; }).level < 3 },
                            { 'opacity-30': _vm.activePlayer.pieces.knights.filter(function (playerKnight) { return playerKnight == _vm.knight.level+1; }).length == 0 } ],attrs:{"color":_vm.knight.player.color,"level":_vm.knight.level+1,"active":_vm.knight.active}})],1),_vm._v(" "),_c('div',{staticClass:"items-left",staticStyle:{"left":"36px","top":"6px"}},[_vm._v(_vm._s(_vm.activePlayer.pieces.knights.filter(function (playerKnight) { return playerKnight == _vm.knight.level+1; }).length))]),_vm._v(" "),(_vm.flatGraphics)?_c('div',{staticClass:"action-cost"},[_c('div',{staticClass:"symbol wool"}),_vm._v(" "),_c('div',{staticClass:"symbol ore"})]):_c('div',{staticClass:"action-cost-2"},[_c('div',{staticClass:"symbol-2 wool-2"}),_vm._v(" "),_c('div',{staticClass:"symbol-2 ore-2"})])]):(action == 'chase away robber')?_c('div',{staticClass:"action",class:[{ 'action-confirmed': _vm.actionConfirmed == 'chase away robber' }]},[_c('div',{staticClass:"item item-right"},[_c('Knight3D',{staticClass:"knight-item",class:[
                            { 'opacity-30': _vm.knight.activatedThisTurn } ],attrs:{"color":_vm.knight.player.color,"level":_vm.knight.level,"active":!_vm.knight.active}}),_vm._v(" "),_c('p',{class:{ 'opacity-30': _vm.knight.activatedThisTurn }},[_vm._v("chase away robber")])],1)]):(action == 'chase away pirate')?_c('div',{staticClass:"action",class:[{ 'action-confirmed': _vm.actionConfirmed == 'chase away pirate' }]},[_c('div',{staticClass:"item item-right"},[_c('Knight3D',{staticClass:"knight-item",class:[
                            { 'opacity-30': _vm.knight.activatedThisTurn } ],attrs:{"color":_vm.knight.player.color,"level":_vm.knight.level,"active":!_vm.knight.active}}),_vm._v(" "),_c('p',{class:{ 'opacity-30': _vm.knight.activatedThisTurn }},[_vm._v("chase away pirate")])],1)]):(action == 'move knight')?_c('div',{staticClass:"action",class:[{ 'action-confirmed': _vm.actionConfirmed == 'move knight' }]},[_c('div',{staticClass:"item item-right"},[_c('Knight3D',{staticClass:"knight-item",class:[
                            { 'opacity-30': _vm.knight.activatedThisTurn } ],attrs:{"color":_vm.knight.player.color,"level":_vm.knight.level,"active":!_vm.knight.active}}),_vm._v(" "),_c('p',{class:{ 'opacity-30': _vm.knight.activatedThisTurn }},[_vm._v("Move knight")])],1)]):(action == 'enable city')?_c('div',{staticClass:"action",class:[{ 'action-confirmed': _vm.actionConfirmed == 'enable city' }]},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"city",class:[
                                _vm.city.player.color,
                                { 'citywall': _vm.city.citywall },
                                { 'metropolis': _vm.city.metropolis },
                                { 'city_disabled': false } ]})]),_vm._v(" "),_c('div',[(_vm.flatGraphics)?_c('div',{staticClass:"action-cost"},[_c('div',{staticClass:"symbol lumber"}),_vm._v(" "),_c('div',{staticClass:"symbol ore"})]):_c('div',{staticClass:"action-cost-2"},[_c('div',{staticClass:"symbol-2 lumber-2"}),_vm._v(" "),_c('div',{staticClass:"symbol-2 ore-2"})])])]):(action == 'build citywall')?_c('div',{staticClass:"action",class:[{ 'action-confirmed': _vm.actionConfirmed == 'build citywall' }]},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"city",class:[
                                _vm.city.player.color,
                                { 'citywall': _vm.city.citywall },
                                { 'metropolis': _vm.city.metropolis },
                                { 'city_disabled': _vm.city.disabled } ]})]),_vm._v(" "),_c('div',{staticClass:"items-left",staticStyle:{"left":"42px","top":"2px"}},[_vm._v(_vm._s(_vm.activePlayer.pieces.citywalls))]),_vm._v(" "),(_vm.flatGraphics)?_c('div',{staticClass:"action-cost"},[_c('div',{staticClass:"symbol brick"}),_vm._v(" "),_c('div',{staticClass:"symbol brick"})]):_c('div',{staticClass:"action-cost-2"},[_c('div',{staticClass:"symbol-2 brick-2"}),_vm._v(" "),_c('div',{staticClass:"symbol-2 brick-2"})])]):(action == 'disable city')?_c('div',[_c('div',{staticClass:"item"},[_c('div',{staticClass:"city",class:[
                                _vm.city.player.color,
                                { 'citywall': _vm.city.citywall },
                                { 'metropolis': _vm.city.metropolis },
                                { 'city_disabled': _vm.city.disabled } ]})]),_vm._v(" "),_c('div',{staticClass:"action-cost"})]):_vm._e()])}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }