<template>
    <div :class="[
        { 'highlighted-road-spot': highlighted && !boat },
        { 'highlighted-boat-spot': highlighted && boat },
        { 'road': !boat },
        { 'boat': boat },
        { 'build-road-animation': animation },
    ]">
        <svg width="21" height="40" viewBox="0 0 21 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <!-- road -->
            <template v-if="!boat && !longest">
                <rect x="8" y="1" width="6" height="38" :fill="playerColors[color]" stroke="#383832" :stroke-width="highlighted ? 2.5 : 2"/>
            </template>

            <!-- longest road  -->
            <template v-if="!boat && longest">
                <path d="M7.82837 16.1716V1H13.8284V16.272V16.6862L14.1213 16.9791L17.1421 20L14.1213 23.0209L13.8284 23.3138V23.728V39H7.82837V23.8284V23.4142L7.53548 23.1213L4.41421 20L7.53548 16.8787L7.82837 16.5858V16.1716Z" :fill="playerColors[color]" stroke="#383832" :stroke-width="highlighted ? 2.5 : 2"/>
            </template>

            <!-- longest boat -->
            <template v-if="boat && longest">
                <path d="M4.19995 29V30H5.19995H16.2H17.2V29V21.8837L18.7432 20.169L19.3779 19.4638L18.7071 18.7929L17.2 17.2858V10V9H16.2H5.19995H4.19995V10V17.2546L2.53099 18.7567L1.74754 19.4618L2.49284 20.2071L4.19995 21.9142V29Z" :fill="playerColors[color]" stroke="#383832" :stroke-width="highlighted ? 2.5 : 2"/>
            </template>

            <!-- boat -->
            <template v-if="boat && !longest">
                <rect x="4.19995" y="9" width="13" height="21" :fill="playerColors[color]" stroke="#383832" :stroke-width="highlighted ? 2.5 : 2"/>
            </template>
        </svg>
    </div>

</template>

<script>
import colors from '../../helpers/colors.js'

export default {
    mixins: [
        colors,
    ],
    props: {
        color: String,
        boat: Boolean,
        longest: Object,
        highlighted: Boolean,
        animation: Boolean,
    },
}
</script>

<style>

</style>
