export default {
    methods: {
        scanLongestRoad(brokeLongest = false, playerBroken) {
            this.scanningLongest = true;

            let currentOwner = null;
            if(this.longestRoad.length) {
                currentOwner = this.longestRoad[0].player
            }

            // when house/knight is built/moved into the middle of the longest road, reset longest and make a new check
            if(brokeLongest) {
                this.longestRoad = [];
            }

            // all players results in the same array, not only the best result, but the best result from each road
            let allLongestResult = [];

            // sort roads so correct player gets the longest road if both has the same length
            this.roads.sort((a, b) => a.player.turnsBeforeYou - b.player.turnsBeforeYou);

            // if longest owners road is broken, owner should get it if other players has same length
            this.roads.sort((a, b) => {
                if(playerBroken) {
                    // 2. if the activePlayer has the same length as other oppoennt active player gets it
                    if(playerBroken.color == a.player.color) return -1;
                    // 1. if current owner has same length as leader, current owner gets to keep the longest road
                    if(currentOwner && currentOwner.color == a.player.color) return -1;
                }
            });

            // Loop all built roads
            this.roads.forEach(road => {
                // make the longest-road check from one of the 2 spots connected to the road
                let firstRoadSpot = this.roadSpots(road.tile, road.roadSpot)[0];

                // empty the longest result before checking
                this.playersLongestRoad = [];

                // check all the possible paths
                this.roadLengthFromSpot(firstRoadSpot.tile, firstRoadSpot.position, road.player);

                // store the best result for later comparison with opponents
                allLongestResult.push(this.playersLongestRoad)
            });

            // get the index of the longest result
            let longestRoadIndex = allLongestResult
                .map((element, index) => index)
                .sort((index, x) => allLongestResult[x].length - allLongestResult[index].length)[0];

            if(allLongestResult.length == 0) return;

            // if the longest road is under 5 roads
            if(allLongestResult[longestRoadIndex].length < 5) {
                this.updatePlayersPoints();
                this.scanningLongest = false;
                return;
                return this.saveGame(false, true);
            }

            // if the built road is not longer than the existing owners longest
            if(allLongestResult[longestRoadIndex].length <= this.longestRoad.length) {
                this.updatePlayersPoints();
                this.scanningLongest = false;
                return;
                return this.saveGame(false, true);
            }

            // assign new longest road
            this.longestRoad = allLongestResult[longestRoadIndex];

            // when new a player gets the longest road, log it
            if(this.longestRoad.length) {

                if(!this.longestRoad[0].player.fightFor.includes('longest road')) {
                    this.longestRoad[0].player.fightFor.push('longest road');
                }

                if(currentOwner != this.longestRoad[0].player) {
                    if(this.longestRoad[0].player.ai) {
                        this.aiChat(["heheheh!", "woohoo!", "I'm pretty good!"], this.longestRoad[0].player, true, 15);
                    }
                    this.log(':playerName got the longest road (' + this.longestRoad.length + ' roads)', this.longestRoad[0].player);

                    this.updatePlayersPoints();
                    // this.saveGame(false, true);
                } else {
                    this.log(':playerName has the longest road (' + this.longestRoad.length + ' roads)', this.longestRoad[0].player);

                    this.updatePlayersPoints();
                    // this.saveGame(false, true);
                }
            }

            this.scanningLongest = false;
            this.updatePlayersPoints();
            // this.saveGame(false, true);
        },
        roadLengthFromSpot(tile, spotPosition, player) {

            let playerSpotRoads = this.spotRoads(tile, spotPosition).filter(road => road && road.player.color == player.color);

            let activeSpot = this.spot(tile, spotPosition);

            if(playerSpotRoads.length == 0) return;

            if(playerSpotRoads.length == 1) {

                // false/true = reversed, for changing path in the first intersection
                this.roadPaths(tile, spotPosition, player, playerSpotRoads[0], false);
                this.roadPaths(tile, spotPosition, player, playerSpotRoads[0], true);

                // index for the best result
                let longestResultIndex = this.longestRoadCheckResult
                    .map((element, index) => index)
                    .sort((index, x) => this.longestRoadCheckResult[x].length - this.longestRoadCheckResult[index].length)[0];

                // only store the best result
                if(this.longestRoadCheckResult[longestResultIndex].length > this.playersLongestRoad.length) {
                    this.playersLongestRoad = this.longestRoadCheckResult[longestResultIndex];
                }

                this.checkedRoads = [];
                this.longestRoadCheckResult = [];
                this.changePath = [];

            } else {
                playerSpotRoads.forEach(activeRoad => {

                    playerSpotRoads.forEach(otherDirectionRoad => {

                        if(activeRoad != otherDirectionRoad) {
                            // Math.random() >= 0.5 is for reversed paths, turning in intersections

                            if(activeSpot.content && activeSpot.content.group == 'building') {
                                this.roadPaths(tile, spotPosition, player, activeRoad, Math.random() >= 0.5, otherDirectionRoad);
                            } else {
                                // boat & road, no building at starting point
                                if((activeRoad.boat && !otherDirectionRoad.boat) || (!activeRoad.boat && otherDirectionRoad.boat)) {
                                    this.roadPaths(tile, spotPosition, player, activeRoad, Math.random() >= 0.5);
                                } else {
                                    this.roadPaths(tile, spotPosition, player, activeRoad, Math.random() >= 0.5, otherDirectionRoad);
                                }
                            }

                        }
                    });

                    let longestResultIndex = this.longestRoadCheckResult
                        .map((element, index) => index)
                        .sort((index, x) => this.longestRoadCheckResult[x].length - this.longestRoadCheckResult[index].length)[0];

                    if(this.longestRoadCheckResult[longestResultIndex].length > this.playersLongestRoad.length) {
                        this.playersLongestRoad = this.longestRoadCheckResult[longestResultIndex];
                    }

                    this.checkedRoads = [];
                    this.longestRoadCheckResult = [];
                    this.changePath = [];
                });
            }
        },
        roadPaths(tile, spotPosition, player, activeRoad, reversed, otherDirectionRoad = null) {
            this.checkedRoads = [];

            this.lastIntersection = null;
            this.turnToRoad = null;

            this.roadToRoad(tile, spotPosition, player, activeRoad, reversed);

            if(otherDirectionRoad) {
                this.roadToRoad(tile, spotPosition, player, otherDirectionRoad, true);
                this.roadToRoad(tile, spotPosition, player, otherDirectionRoad, false);
            }

            // if intersection was found, make another check
            if(this.lastIntersection != null) {

                // store path so future checks will use it instead
                this.changePath.push({ spotKey: this.lastIntersection.key, road: this.turnToRoad });

                    this.longestRoadCheckResult.push(this.checkedRoads);

                    this.roadPaths(tile, spotPosition, player, activeRoad, reversed);

                    if(otherDirectionRoad) {
                        this.roadToRoad(tile, spotPosition, player, otherDirectionRoad, true);
                        this.roadToRoad(tile, spotPosition, player, otherDirectionRoad, false);
                    }

                // this.checkedRoads; this.roadPaths(tile, spotPosition, player, activeRoad, reversed);
                // setTimeout(() => {
                // }, 300)
            } else {
                this.longestRoadCheckResult.push(this.checkedRoads);
            }

        },
        // if coming back to own intersection, set next road to the old road again
        roadToRoad(tile, spotPosition, player, activeRoad, reversed = false) {


            let activeSpot = this.spot(tile, spotPosition);

            if(activeSpot.content.player) {
                if(activeSpot.content.player.color != player.color) {
                    return;
                }
            }

            if(!this.checkedRoads.includes(activeRoad)) {
                this.checkedRoads.push(activeRoad);
            } else {
                return;
            }

            let nextSpot = this.roadSpots(activeRoad.tile, activeRoad.roadSpot).find(spot => spot && spot.key != activeSpot.key);
            if(!nextSpot) return;

            let playerSpotRoads = this.spotRoads(nextSpot.tile, nextSpot.position).filter(road => road && road.player.color == player.color);

            let nextRoad;

            let newRoute = this.changePath.find(obj => obj.spotKey == nextSpot.key);

            if(newRoute) {
                nextRoad = newRoute.road;
            } else {

                let spotRoads = this.spotRoads(nextSpot.tile, nextSpot.position);

                // reversed checks the other road first when coming to first intersection
                if(!reversed) {
                    nextRoad = spotRoads.find(road => road && road.player.color == player.color && road.key != activeRoad.key);
                } else {
                    nextRoad = spotRoads.reverse().find(road => road && road.player.color == player.color && road.key != activeRoad.key);
                }

            }

            if(!nextRoad) return;

            if(nextSpot.content && nextSpot.content.group == 'building') {

            } else {
                // no house & city between road & boat
                if((activeRoad.boat && !nextRoad.boat) || (!activeRoad.boat && nextRoad.boat)) {
                    return;
                }
            }

            // intersection found
            if(playerSpotRoads.length == 3) {

                // if intersection is not added already
                if(!this.changePath.find(obj => obj.spotKey == nextSpot.key)) {

                    let newRoad = playerSpotRoads.find(road => road != activeRoad && road != nextRoad);

                    // when newRoad is already checked, you have reached the same intersection again
                    if(!this.checkedRoads.includes(newRoad)) {
                        this.lastIntersection = this.spot(nextSpot.tile, nextSpot.position);
                        this.turnToRoad = newRoad;
                    }

                }
            }

            if(this.checkedRoads.includes(nextRoad)) return;

            if(!nextRoad) return;

            this.roadToRoad(nextSpot.tile, nextSpot.position, player, nextRoad, reversed);
        },
    }
 }
