export default {
    methods: {
        generateProgressCards(fiveToSixPlayers = false) {
            for(let i = 0; i < 14; i++) {
                this.progressCardsBasic.push('knight');
            }

            for(let i = 0; i < 5; i++) {
                this.progressCardsBasic.push('point');
            }

            if(fiveToSixPlayers) {
                for(let i = 0; i < 6; i++) {
                    this.progressCardsBasic.push('knight');
                }
                this.progressCardsBasic.push('monopoly');
                this.progressCardsBasic.push('road building');
                this.progressCardsBasic.push('year of plenty');
            }

            for(let i = 0; i < 2; i++) {
                this.progressCardsBasic.push('monopoly');
                this.progressCardsBasic.push('road building');
                this.progressCardsBasic.push('year of plenty');

                // cloth 2
                this.progressCardsCloth.push('resource monopoly');
                this.progressCardsCloth.push('merchant');
                this.progressCardsCloth.push('master merchant');
                this.progressCardsCloth.push('merchant fleet');
                this.progressCardsCloth.push('commercial harbor');
                this.progressCardsCloth.push('trade monopoly');

                // coin 2
                this.progressCardsCoin.push('deseter');
                this.progressCardsCoin.push('spy');
                this.progressCardsCoin.push('diplomat');
                this.progressCardsCoin.push('warlord');
                this.progressCardsCoin.push('saboteur');
                this.progressCardsCoin.push('intrigue');
                this.progressCardsCoin.push('bishop');
                this.progressCardsCoin.push('wedding');

                // paper 2
                this.progressCardsPaper.push('inventor');
                this.progressCardsPaper.push('medecine');
                this.progressCardsPaper.push('mining');
                this.progressCardsPaper.push('road building');
                this.progressCardsPaper.push('smith');
                this.progressCardsPaper.push('irrigation');
                this.progressCardsPaper.push('crane');
                this.progressCardsPaper.push('alchemist');
            }

            // cloth +
            this.progressCardsCloth.push('resource monopoly');
            this.progressCardsCloth.push('resource monopoly');
            this.progressCardsCloth.push('merchant');
            this.progressCardsCloth.push('merchant');
            this.progressCardsCloth.push('merchant');
            this.progressCardsCloth.push('merchant');

            // coin +
            this.progressCardsCoin.push('spy');
            this.progressCardsCoin.push('point');

            // paper +
            this.progressCardsPaper.push('engineer');
            this.progressCardsPaper.push('point');

            this.shuffle(this.progressCardsCloth);
            this.shuffle(this.progressCardsCoin);
            this.shuffle(this.progressCardsPaper);
            this.shuffle(this.progressCardsBasic);
        },
    }
 }
